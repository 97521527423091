import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSearchWarrantyTasksBaseVariables, FirstSearchWarrantyTasksBaseVariables, CountSearchWarrantyTasksBaseVariables, FindSearchWarrantyTasksBaseVariables, SearchSearchWarrantyTasksBaseVariables, ExportSchemaSearchWarrantyTasksBaseVariables, ExportDataSearchWarrantyTasksBaseVariables, CustomQuerySearchWarrantyTasksBaseVariables, CustomQueryIdSearchWarrantyTasksBaseVariables, UsedSearchWarrantyTasksBaseVariables, ExistSearchWarrantyTasksBaseVariables, InsertSearchWarrantyTasksBaseVariables, UpdateSearchWarrantyTasksBaseVariables, ImportDataSearchWarrantyTasksBaseVariables, UpdateManySearchWarrantyTasksBaseVariables, DeleteSearchWarrantyTasksBaseVariables } from '../gqls'
import { GetSearchWarrantyTasksDocument, FirstSearchWarrantyTasksDocument, CountSearchWarrantyTasksDocument, FindSearchWarrantyTasksDocument, SearchSearchWarrantyTasksDocument, ExportSchemaSearchWarrantyTasksDocument, ExportDataSearchWarrantyTasksDocument, CustomQuerySearchWarrantyTasksDocument, CustomQueryIdSearchWarrantyTasksDocument, UsedSearchWarrantyTasksDocument, ExistSearchWarrantyTasksDocument, InsertSearchWarrantyTasksDocument, UpdateSearchWarrantyTasksDocument, ImportDataSearchWarrantyTasksDocument, UpdateManySearchWarrantyTasksDocument, DeleteSearchWarrantyTasksDocument } from '../gqls'
import { ServiceSingleResultOfSearchWarrantyTask, QueryContextOfSearchWarrantyTask, FilterOfSearchWarrantyTask, ServiceSingleResultOfInt64, ServiceListResultOfSearchWarrantyTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, SearchWarrantyTaskInput, FieldUpdateOperatorOfSearchWarrantyTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SearchWarrantyTaskBaseService {
    
public modelName = 'searchWarrantyTask';
public modelPluralName = 'searchWarrantyTasks';

	private getSearchWarrantyTasksQuery: GetSearchWarrantyTasksDocument;
	private firstSearchWarrantyTasksQuery: FirstSearchWarrantyTasksDocument;
	private countSearchWarrantyTasksQuery: CountSearchWarrantyTasksDocument;
	private findSearchWarrantyTasksQuery: FindSearchWarrantyTasksDocument;
	private searchSearchWarrantyTasksQuery: SearchSearchWarrantyTasksDocument;
	private exportSchemaSearchWarrantyTasksQuery: ExportSchemaSearchWarrantyTasksDocument;
	private exportDataSearchWarrantyTasksQuery: ExportDataSearchWarrantyTasksDocument;
	private customQuerySearchWarrantyTasksQuery: CustomQuerySearchWarrantyTasksDocument;
	private customQueryIdSearchWarrantyTasksQuery: CustomQueryIdSearchWarrantyTasksDocument;
	private usedSearchWarrantyTasksQuery: UsedSearchWarrantyTasksDocument;
	private existSearchWarrantyTasksQuery: ExistSearchWarrantyTasksDocument;
	private insertSearchWarrantyTasksMutation: InsertSearchWarrantyTasksDocument;
	private updateSearchWarrantyTasksMutation: UpdateSearchWarrantyTasksDocument;
	private importDataSearchWarrantyTasksMutation: ImportDataSearchWarrantyTasksDocument;
	private updateManySearchWarrantyTasksMutation: UpdateManySearchWarrantyTasksDocument;
	private deleteSearchWarrantyTasksMutation: DeleteSearchWarrantyTasksDocument;

	constructor(private injector: Injector) {
		this.getSearchWarrantyTasksQuery = this.injector.get(GetSearchWarrantyTasksDocument);
		this.firstSearchWarrantyTasksQuery = this.injector.get(FirstSearchWarrantyTasksDocument);
		this.countSearchWarrantyTasksQuery = this.injector.get(CountSearchWarrantyTasksDocument);
		this.findSearchWarrantyTasksQuery = this.injector.get(FindSearchWarrantyTasksDocument);
		this.searchSearchWarrantyTasksQuery = this.injector.get(SearchSearchWarrantyTasksDocument);
		this.exportSchemaSearchWarrantyTasksQuery = this.injector.get(ExportSchemaSearchWarrantyTasksDocument);
		this.exportDataSearchWarrantyTasksQuery = this.injector.get(ExportDataSearchWarrantyTasksDocument);
		this.customQuerySearchWarrantyTasksQuery = this.injector.get(CustomQuerySearchWarrantyTasksDocument);
		this.customQueryIdSearchWarrantyTasksQuery = this.injector.get(CustomQueryIdSearchWarrantyTasksDocument);
		this.usedSearchWarrantyTasksQuery = this.injector.get(UsedSearchWarrantyTasksDocument);
		this.existSearchWarrantyTasksQuery = this.injector.get(ExistSearchWarrantyTasksDocument);
		this.insertSearchWarrantyTasksMutation = this.injector.get(InsertSearchWarrantyTasksDocument);
		this.updateSearchWarrantyTasksMutation = this.injector.get(UpdateSearchWarrantyTasksDocument);
		this.importDataSearchWarrantyTasksMutation = this.injector.get(ImportDataSearchWarrantyTasksDocument);
		this.updateManySearchWarrantyTasksMutation = this.injector.get(UpdateManySearchWarrantyTasksDocument);
		this.deleteSearchWarrantyTasksMutation = this.injector.get(DeleteSearchWarrantyTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSearchWarrantyTask> {
    
    		let variables: GetSearchWarrantyTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.get));
            }
            else{
                let result:ServiceSingleResultOfSearchWarrantyTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSearchWarrantyTask,
		@Args('filter?') filter?: FilterOfSearchWarrantyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSearchWarrantyTask> {
    
    		let variables: FirstSearchWarrantyTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSearchWarrantyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSearchWarrantyTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSearchWarrantyTask,
		@Args('filter?') filter?: FilterOfSearchWarrantyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSearchWarrantyTask> {
    
    		let variables: FindSearchWarrantyTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSearchWarrantyTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSearchWarrantyTask> {
    
    		let variables: SearchSearchWarrantyTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSearchWarrantyTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSearchWarrantyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSearchWarrantyTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSearchWarrantyTask,
		@Args('filter?') filter?: FilterOfSearchWarrantyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSearchWarrantyTask> {
    
    		let variables: CustomQuerySearchWarrantyTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSearchWarrantyTask,
		@Args('filter?') filter?: FilterOfSearchWarrantyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSearchWarrantyTask> {
    
    		let variables: CustomQueryIdSearchWarrantyTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSearchWarrantyTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSearchWarrantyTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSearchWarrantyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SearchWarrantyTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSearchWarrantyTask> {
    
    		let variables: InsertSearchWarrantyTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertSearchWarrantyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSearchWarrantyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSearchWarrantyTask> {
    
    		let variables: UpdateSearchWarrantyTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSearchWarrantyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSearchWarrantyTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSearchWarrantyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSearchWarrantyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySearchWarrantyTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySearchWarrantyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSearchWarrantyTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSearchWarrantyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.searchWarrantyTasks.delete));
    	}
    
}