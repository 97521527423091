<mat-expansion-panel
  [expanded]="expanded != undefined ? expanded : true"
  class="work-item-section-container"
>
  <mat-expansion-panel-header
    *ngIf="title != undefined && title != ''"
    collapsedHeight="36px"
    expandedHeight="36px"
    class="work-item-section-header"
    [ngClass]="{
      'cl-group-form-designer-hover cl-action-form-designer-hover':
        designerMode,
    }"
  >
    <div *ngIf="designerMode" class="cl-action-form-designer">
      <div (click)="formAction($event, getAction.ADD_UP)">
        <i class="fa-solid fa-layer-plus"></i>
        <p>{{ 'entities/formDesigner/group' | translate }}</p>
      </div>
      <div (click)="formAction($event, getAction.ADD_IN)">
        <i class="fa-solid fa-plus"></i>
      </div>
      <div
        (click)="formAction($event, getAction.EDIT)"
        class="cl-edit-form-designer"
      >
        <i class="fa-solid fa-pen"></i>
      </div>
      <div
        (click)="formAction($event, getAction.REMOVE)"
        class="cl-delete-form-designer"
      >
        <i class="fa-solid fa-trash-xmark"></i>
      </div>
    </div>
    <mat-panel-title>{{ title }}</mat-panel-title>
  </mat-expansion-panel-header>

  <div class="work-item-section-body">
    <div
      *ngIf="designerMode === true"
      class="cl-group-fill-form-designer"
    ></div>
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>
