<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loadPanelShow"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>

<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content style="border-left: 0px !important">
      <cl-work-page-header-content-body>
        <cl-toolbar
          class="action"
          *ngIf="(canAdd || canEdit || canDelete) && !readOnly"
        >
          <cl-toolbar-items>
            <cl-toolbar-item-dropdown-button
              *ngIf="canAdd"
              [text]="'new' | translate"
              icon="fas fa-plus"
              [source]="buttonsSource"
              keyExpr="name"
              displayExpr="value"
              [splitButton]="true"
              (onButtonClick)="onButtonClick($event)"
              [dropDownOptions]="{
                disabled: selectedKeys?.length !== 1 || readOnly,
                width: 200,
              }"
            >
            </cl-toolbar-item-dropdown-button>
            <cl-toolbar-item-button
              *ngIf="canEdit"
              [text]="'edit' | translate"
              icon="fas fa-pencil-alt"
              [disabled]="selectedKeys?.length !== 1 || readOnly"
              (onClick)="onCustomCommand($event, 'editTask1')"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              *ngIf="canDelete"
              [text]="'remove' | translate"
              [disabled]="selectedKeys?.length === 0 || readOnly"
              (onClick)="onCustomCommand($event, 'deleteTask1')"
              icon="fas fa-trash-alt"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              *ngIf="canAdd && canAddTaskSequence"
              [text]="'ganttAddTaskSequence' | translate"
              [disabled]="this.readOnly || readOnly"
              (onClick)="onCustomCommand($event, 'addTaskSequence1')"
            >
            </cl-toolbar-item-button>
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-content>
    <clc-list
      [source]="currentSource"
      [type]="'Tree'"
      [columns]="columns"
      [keyExpr]="'id'"
      [multiple]="false"
      [parentIdExpr]="'parentId'"
      [recursive]="false"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      [modelState]="state"
      (onRightClick)="onRightClick($event)"
      [layoutKey]="layoutKey"
      [isLayoutForTreeList]="isLayoutForTreeList"
      [exportFileName]="exportFileName"
      (rowDblClick)="handleRowDblClick($event)"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>

<clc-work-sub-form
  [rawModel]="rawModel"
  [rules]="rules"
  [parentModel]="state"
  [(isPopupOpen)]="isPopupOpen"
  [service]="service"
  (onSaved)="saved($event)"
  [fieldName]="fieldName"
  [key]="key"
  [(title)]="title"
></clc-work-sub-form>

<dx-popup
  [width]="800"
  [height]="'80%'"
  [showTitle]="false"
  #popup
  class="add-item-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="popupVisible"
>
  <clc-select-list
    [title]="'globals/addToTaskSequence' | translate"
    [buttonText]="'globals/select' | translate"
    [source]="taskSequenceSource"
    [route]="'/administration/service-desk/task-sequence'"
    [columns]="taskSequenceTypeColumns"
    (onClosed)="popupVisible = false"
    (onSelect)="onSelectSequenceTask($event)"
    [multiple]="false"
  >
  </clc-select-list>
</dx-popup>
