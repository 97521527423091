<div class="cl-visible-selectable-link-entity">
  <dx-data-grid
    class="grid"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowUpdated)="onRowUpdated($event)"
    [dataSource]="source"
    [selection]="{
      mode: 'none',
      showCheckBoxesMode: 'none',
    }"
    [hoverStateEnabled]="true"
    [remoteOperations]="{
      paging: true,
      filtering: true,
      sorting: true,
      grouping: false,
      groupPaging: false,
      summary: false,
    }"
    [editing]="{
      allowUpdating: true,
      mode: 'cell',
    }"
    height="100%"
    [showBorders]="false"
    [showRowLines]="false"
    [columnAutoWidth]="true"
    [searchPanel]="{
      visible: false,
      width: 200,
      placeholder: 'globals/search' | translate,
    }"
    [columnChooser]="{
      enabled: false,
    }"
    [paging]="{ pageSize: 10000000 }"
    [pager]="{
      showPageSizeSelector: false,
      showNavigationButtons: false,
      allowedPageSizes: [10000000],
      showInfo: false,
      visible: false,
    }"
    [groupPanel]="{
      visible: false,
    }"
    [grouping]="{
      enabled: false,
      contextMenuEnabled: false,
    }"
    [sorting]="{
      mode: 'multiple',
    }"
    [export]="{
      enabled: false,
      allowExportSelectedData: true,
      fileName: 'One by ClariLog',
    }"
    [filterRow]="{
      visible: true,
    }"
    [filterPanel]="{
      visible:
        options['filterPanel'] == undefined ? true : options['filterPanel'],
    }"
  >
    <dxo-summary>
      <dxi-total-item
        column="id"
        summaryType="count"
        displayFormat="{0} item(s)"
      >
      </dxi-total-item>
    </dxo-summary>
    <dxo-scrolling useNative="true" mode="standard"></dxo-scrolling>
    <dxi-column
      *ngFor="let column of options.columns"
      [dataField]="column.field"
      [caption]="column.label"
      [groupIndex]="column.groupIndex"
      [name]="column.field + column.label"
      [allowSorting]="column.allowSorting"
      [allowFiltering]="column.allowFiltering"
      [sortIndex]="column.sortIndex"
      [sortOrder]="column.sortOrder"
      [allowGrouping]="column.allowGrouping"
      [width]="column.width"
      [allowEditing]="
        column.allowEditing != undefined ? column.allowEditing : false
      "
      [cellTemplate]="
        column.editCellTemplate != undefined ? 'tagBoxCellTemplate' : null
      "
      [editCellTemplate]="
        column.editCellTemplate != undefined ? column.editCellTemplate : null
      "
    >
      <dxo-lookup
        *ngIf="column.lookup != undefined"
        [dataSource]="column.lookup.source"
        [valueExpr]="column.lookup.valueExpr"
        [displayExpr]="column.lookup.displayExpr"
      >
      </dxo-lookup>

      <div
        *dxTemplate="let cellInfo of 'tagBoxEditCellTemplate'"
        style="padding-top: 10px; padding-bottom: 10px"
      >
        <clc-debug [value]="cellInfo"></clc-debug>
        <dx-tag-box
          [value]="cellInfo.value"
          [dataSource]="cellInfo?.column?.lookup?.dataSource"
          [showClearButton]="true"
          [valueExpr]="'id'"
          [showSelectionControls]="true"
          [displayExpr]="'name'"
          [acceptCustomValue]="false"
          [multiline]="true"
          (onSelectionChanged)="selectionTemplateChanged(cellInfo, $event)"
        >
        </dx-tag-box>
      </div>
      <div *dxTemplate="let cellInfo of 'tagBoxCellTemplate'">
        <dx-tag-box
          [value]="cellInfo.value"
          [disabled]="true"
          [readOnly]="true"
          [dataSource]="cellInfo?.column?.lookup?.dataSource"
          [showClearButton]="true"
          placeholder=""
          [valueExpr]="cellInfo?.column?.lookup?.valueExpr"
          [showSelectionControls]="true"
          [displayExpr]="cellInfo?.column?.lookup?.displayExpr"
          [acceptCustomValue]="false"
          [multiline]="true"
        >
        </dx-tag-box>
      </div>
    </dxi-column>

    <div
      *dxTemplate="let data of 'underlineTemplate'"
      style="display: inline-block"
    >
      <a
        href=""
        (auxclick)="underline($event, data); (false)"
        (click)="underline($event, data); (false)"
        >{{ data.value }}</a
      >
    </div>

    <ng-container
      *ngFor="
        let column of options.columns | filter: 'template' : undefined : true
      "
    >
      <div *dxTemplate="let data of column.template">
        <ng-template
          *ngTemplateOutlet="
            templateService.get(column.template);
            context: { $implicit: data }
          "
        >
        </ng-template>
      </div>
    </ng-container>
  </dx-data-grid>
</div>
