import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { Observable } from 'rxjs';
import { GqlField, GqlSubField } from '../helpers';
import { EmailConnectorBaseService } from '../service-bases';
import {
  FilterOfEmailConnector,
  QueryContextOfEmailConnector,
  ServiceListResultOfEmailConnector,
} from '../types';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { EmailConnectorTypeEnum } from '@clarilog/core/services2/graphql/generated-types/types';
import { CoreLicenseReader } from '@clarilog/core/services2/license-reader/license-reader';
import { alert } from 'devextreme/ui/dialog';
import { CoreFeatureService } from '@clarilog/shared2/services/features/feature.service';

@Injectable({ providedIn: 'root' })
@Authorize('administration.email-setting')
export class EmailConnectorCoreService extends EmailConnectorBaseService {
  constructor(
    injector: Injector,
    public licenseReader: CoreLicenseReader,
    private featureService: CoreFeatureService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  emailConnectorEnumType(): any[] {
    return [
      {
        id: 'SMTP',
        value: 'SMTP',
        name: TranslateService.get('enums/emailConnectorTypeEnum/SMTP'),
      },
      {
        id: 'ONE',
        value: 'ONE',
        name: TranslateService.get('enums/emailConnectorTypeEnum/ONE'),
      },
      {
        id: 'OFFICE_365',
        value: 'OFFICE_365',
        name: TranslateService.get('enums/emailConnectorTypeEnum/OFFICE_365'),
      },
      {
        id: 'OBSERVER',
        value: 'OBSERVER',
        name: TranslateService.get('enums/emailConnectorTypeEnum/OBSERVER'),
      },
    ];
  }

  /** Vérification inbox */
  async licenceInbox() {
    let total = await this.count(
      ModelFieldCompilerService.createServiceSingleResultScalar(),
      {
        type: { eq: EmailConnectorTypeEnum.One },
      },
    ).toPromise();
    if (this.licenseReader.max(total.data, (l) => l.features.mailBoxNumber)) {
      alert(
        TranslateService.get('globals/licenceInboxMax'),
        TranslateService.get('globals/warning'),
      );
      return false;
    } else {
      return true;
    }
  }

  /** Licence Mailbox observer */
  async licenceInboundMob() {
    let canMOB = await this.featureService.hasMOB();

    if (canMOB) {
      let total = await this.count(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        {
          type: { eq: EmailConnectorTypeEnum.Observer },
        },
      ).toPromise();
      if (
        this.licenseReader.max(
          total.data,
          (l) => l.features.inBoundMailBoxObserverNumber,
        )
      ) {
        alert(
          TranslateService.get('globals/licenceInMobMax'),
          TranslateService.get('globals/warning'),
        );
        return false;
      } else {
        return true;
      }
    } else {
      alert(
        TranslateService.get('globals/licenceNoMOB'),
        TranslateService.get('globals/warning'),
      );
      return false;
    }
  }

  /** Licence Mailbox observer */
  async licenceOutboundMob(currentId) {
    let filter: FilterOfEmailConnector = {
      and: [
        {
          type: { ne: EmailConnectorTypeEnum.Observer },
        },
        {
          clarilogServerId: { ne: null },
        },
      ],
    };

    if (currentId != undefined) {
      filter.and.push({
        id: { ne: currentId },
      });
    }

    let total = await this.count(
      ModelFieldCompilerService.createServiceSingleResultScalar(),

      filter,
    ).toPromise();
    if (
      this.licenseReader.max(
        total.data,
        (l) => l.features.outBoundMailBoxObserverNumber,
      )
    ) {
      alert(
        TranslateService.get('globals/licenceOutMobMax'),
        TranslateService.get('globals/warning'),
      );
      return false;
    } else {
      return true;
    }
  }

  @InjectArgs
  public findInput(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfEmailConnector,
    @Args('filter?') filter?: FilterOfEmailConnector,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailConnector> {
    if (filter == undefined) {
      filter = {};
    }
    filter.receive = { eq: true };
    return this.find(fields, options, filter, extendedVariables);
  }

  public findDefaultConnectorId() {
    let fields = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create('name', [
          GqlField.create(this.translatedFieldHelperService.getTranslateKey()),
        ]),
        GqlField.create('server'),
      ]),
    ];

    let defaultConnector = this.findDefaultConnector(fields).toPromise();

    return defaultConnector.then((res) => {
      return res?.data?.id;
    });
  }
}
