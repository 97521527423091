import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: ['fr-FR', 'en-US', 'de-DE', 'nl-NL', 'it-IT'],
    },
  ],
})
export class GlobalizeModule {}
