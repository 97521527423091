import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { camelCase } from 'lodash';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
// import { LicenseService } from '../../modules/organizations/modules/licenses/shared/services/license.service';
import { CorePolicyValidator } from '../services2/authorization/authorization-policy-builder.service';
import { InitializationCoreService } from '../services2/initialization/initialization.service';
import { CoreLicenseReader } from '../services2/license-reader/license-reader';

/** Représente la classe d'accès au route. */
@Injectable({
  providedIn: 'root',
})
// @Injectable()
export class AuthCoreGuard {
  constructor(
    private initializationService: InitializationCoreService,
    private licenseReader: CoreLicenseReader,
    private router: Router,
    private policyValidator: CorePolicyValidator,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    let license = this.licenseReader.currentLicense;
    // Test si la feature est accessible.
    let date = undefined;
    // verification du mode en fonction de la policies
    localStorage.setItem('displayMode', 'operator');
    if (route.data?.policies != undefined && route.data?.policies.length > 0) {
      let hm = route.data.policies.filter(
        (f) => f.indexOf('help-desk-user') >= 0,
      );
      if (hm != undefined && hm.length > 0) {
        localStorage.setItem('displayMode', 'helpme');
      }
    }

    if (route.data.feature != undefined) {
      date = license.features[camelCase(route.data.feature) + 'ExpireAt'];
    }
    if (
      (license != undefined &&
        license.expireAt != undefined &&
        new Date(license.expireAt) < new Date()) ||
      (route.data.feature !== undefined &&
        (license.features[camelCase(route.data.feature)] === false ||
          (date != undefined && new Date(date) < new Date())))
    ) {
      this.router.navigateByUrl('/no-license');
      return false;
    }
    if (
      route.data.policies != undefined &&
      this.policyValidator.validate(
        route.data.policies,
        route.data.policyOperator || 'and',
      ) === false
    ) {
      // redirect to some view explaining what happened
      this.router.navigateByUrl('/unauthorized');
      return false;
    }
    return this.initializationService.isLoaded$.pipe(filter((b) => b));
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (
      childRoute.data.policies != undefined &&
      this.policyValidator.validate(
        childRoute.data.policies,
        childRoute.data.policyOperator || 'and',
      ) === false
    ) {
      // redirect to some view explaining what happened
      this.router.navigateByUrl('/unauthorized');
      return false;
    }
    return this.initializationService.isLoaded$.pipe(filter((b) => b));
  }
}
