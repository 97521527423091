<div *ngIf="printerName != undefined">
  {{ 'entities/labelPrinter/printer' | translate }} : {{ printerName }}
</div>

<dx-load-indicator
  id="small-indicator"
  height="40"
  width="40"
  [visible]="loadingVisible && !printMode"
></dx-load-indicator>

<div id="preview" *ngIf="!loadingVisible">
  <i
    *ngIf="image == undefined"
    class="fal fa-file-times cl-element-error-icon"
  ></i>
  <img class="cl-preview-label" [src]="image" />
</div>
<div class="cl-error-bpac">
  <div
    *ngFor="let error of errors; index as i; first as isFirst"
    class="cl-extension"
  >
    <dx-button
      *ngIf="error.url != undefined"
      stylingMode="outlined"
      icon="download"
      type="danger"
      (onClick)="downloadExtension(error.url)"
      [text]="error.message"
    >
    </dx-button>
    <span *ngIf="error.message != undefined && error.url == undefined">{{
      error.message
    }}</span>
  </div>
</div>
<dx-button
  *ngIf="this.image != undefined && this.image != ''"
  stylingMode="contained"
  [text]="'globals/printForm' | translate"
  type="normal"
  icon="print"
  (onClick)="print($event)"
  [disabled]="disabledPrintButton"
  [visible]="showPrint"
>
</dx-button>
