import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonCoreService } from '@clarilog/core/services2/graphql/generated-types/services/common.service';
import { QueryBuilderCoreService } from '@clarilog/core/services2/graphql/generated-types/services/query-builder.service';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { TemplatesService } from '../templates/templates.service';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-field-chooser',
  templateUrl: './field-chooser.component.html',
  styleUrls: ['./field-chooser.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFieldChooserComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CoreFieldChooserComponent),
      multi: true,
    },
  ],
})
// ATTENTION il y a une erreur sur ce composant
/// ERROR TypeError: Cannot read property 'forEach' of undefined
/// c'est connu et ça vient de devextreme
/// https://supportcenter.devexpress.com/ticket/details/t959818/error-editing-devextreme-data-grid-forked-codesandbox
///
///
///
export class CoreFieldChooserComponent implements ControlValueAccessor, OnInit {
  /** Sauvegarde les valeurs. */
  _values: string;
  selectedValue: string;

  /** Obtient ou définit les options du composant */
  @Input() options: any;

  @Input() fieldType: any;
  /** Represente le modelState du formulaire */
  @Input() modelState: ModelState;
  /** Permet d'exclure des champs */
  @Input() useRemoveFieldsArray: boolean = true;

  /** Represente la methode pour ajouter des champs */
  @Input() addFields: ModelFnContext;

  /** Represente la methode pour selectionner/filter les champs */
  @Input() selectFields: ModelFnContext;

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Obtient ou définit la source de données. */
  source: any;
  /** Obtient ou définit toutes les column */
  columns: any[];
  /** Obtient le type du model */
  type: string;
  /** Tableau des index sélectionnées */
  initializeValue: boolean = false;
  /** Affiche la colonne chooseur */
  showColumnChooser: boolean = false;
  /** Définit le liste des colonne affiché */
  columnVisible: string[] = [];

  /** Choix des colonne */
  selectedColumn: any;

  /** Active ou désactive le bouton de sélection */
  disabledButton: boolean = true;

  /** Active ou désactive le loader */
  loadWait: boolean = true;

  /** Obtient l'erreur  */
  error: string = '*';

  isInitiliazed: boolean = false;
  constructor(
    public templateService: TemplatesService,
    public queryBuilderService: QueryBuilderCoreService,
    private commonService: CommonCoreService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
  ) {}

  /** Obtient ou définit les valeurs. */
  get values(): string {
    return this._values;
  }
  set values(values: string) {
    this._values = values;
    if (this.initializeValue === false) {
      this.onChange(this._values);
      this.onTouched();
    }
  }

  /** @inheritdoc */
  writeValue(values: string): void {
    // ne gere pas les changement de sélection

    this.initializeValue = true;
    this.values = values;
    if (values != undefined) {
      if (this.columns != undefined) {
        this.selectedColumn = this.columns.find((x) => x.dataField === values);
      }
    }
    this.initializeValue = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {}
  /** @inheritdoc */
  async ngOnInit() {
    const typeByUrl = this.route.snapshot.queryParamMap.get('type');
    if (this.type == '' || this.type == undefined) {
      this.type = this.modelState.sharedContext.params.get('type');
    }

    if ((this.type == '' || this.type == undefined) && typeByUrl != undefined) {
      this.type = typeByUrl;
    }

    let defineEntitype = this.modelState.sharedContext.params.get('forceType');
    if (defineEntitype != undefined) {
      this.type = defineEntitype;
    }

    this.commonService
      .allFields(
        this.commonService.gqlField(),
        this.type.charAt(0).toUpperCase() + this.type.substring(1),
      )
      .subscribe(async (res) => {
        //this.fields = res;
        this.columns = await this.commonService.createFields(
          this.type,
          res,
          true,
          true,
          undefined,
          0,
          undefined,
        );
        let originalColumns = this.columns;
        // Exclusion des champs dénormalisé
        let filedWithService = this.columns.filter(
          (f) => f.lookup != undefined,
        );
        this.columns = this.columns.filter(
          (d) => filedWithService.filter((f) => f.id == d.parentId).length == 0,
        );

        // N'affiche pas les champs de type GUID
        // Si besoin de la donnée, il faut utilisé est vérifier la configuration de la dénormalisation
        let excludeColumns = this.columns.filter(
          (f) => (f.dataType == 'Guid' || f.dataType == 'ID') && !f.hasChildren,
        );

        this.columns = this.columns.filter(
          (x) => excludeColumns.filter((f) => f.id == x.id).length == 0,
        );
        if (!(this.useRemoveFieldsArray === false)) {
          let excludesFields = this.removeFields(this.type);
          this.columns = this.columns.filter(
            (fields) => !excludesFields.includes(fields.dataField),
          );
        }

        if (this.addFields != undefined) {
          let namefields: any;
          namefields = this.addFields.fnCall();
          let fieldsToadd = originalColumns.filter((fields) =>
            namefields.includes(fields.dataField),
          );
          this.columns = this.columns.concat(fieldsToadd);
        }

        if (this.selectFields != undefined) {
          let selectfields: any = this.selectFields.fnCall();
          if (selectfields.length > 0) {
            this.columns = this.columns.filter((fields) =>
              selectfields.includes(fields.dataField),
            );
          }
        }

        //Renonner le champs "Tags" en "Tags (Ajout)"
        this.forceRename(this.columns);

        if (this.fieldType === 'date') {
          this.columns = this.columns.filter(
            (x) =>
              x != undefined &&
              x.dataType != undefined &&
              x.dataType.includes != undefined &&
              x.dataType.includes('date'),
          );
        }

        // Apply template de la grille
        if (this.values != undefined) {
          this.selectedColumn = this.columns.find(
            (x) => x.dataField === this.values,
          );
          this.selectedValue = this.selectedColumn.caption;
          this.columnVisible.push(this.selectedColumn['id']);
          //let columns = [];
          // Traduction

          // this.component.state({
          //   columns: columns,
          // });
          this.changeDetector.detectChanges();

          this.initializeValue = false;
        } else {
          // let nameColumn = this.columns.filter((f) => f.dataField == 'name');
          // if (nameColumn.length > 0) {
          //   this.addColumns(nameColumn[0]);
          // }
        }
        this.loadWait = false;
        this.isInitiliazed = true;
      });
  }

  /** Vaidation */
  validate({ value }: UntypedFormControl) {
    let isNotValid = false;
    if (this.values == undefined) {
      isNotValid = true;
    } else {
      let col = this.values;
      if (Array.isArray(this.values) === true) {
        return;
      }
      if (col == undefined || col.trim().length == 0) {
        isNotValid = true;
      }
    }

    if (isNotValid) {
      this.error = TranslateService.get('globals/fieldOnChooser');
    } else {
      this.error = '';
    }
    return isNotValid;
  }

  /* Changement de sélection */
  onSelectionChanged(e) {
    this.selectedColumn = e.component.getSelectedRowsData('all')[0];
    if (this.selectedColumn != undefined) {
      let node = e.component.getNodeByKey(this.selectedColumn.id);
      this.disabledButton = node.hasChildren;
    } else {
      this.disabledButton = true;
    }
  }

  onHidden(e) {
    if (this.selectedColumn != undefined) {
      this.selectedValue = this.selectedColumn.caption;
      this.values = this.selectedColumn.dataField;
    }
  }

  /** Valide le choix des colonne */
  ok(e) {
    this.showColumnChooser = false;
  }

  /** Annule le choix des colonne */
  cancel(e) {
    this.showColumnChooser = false;
  }

  /**Listes des champs à exclures que dans 'propertyName' */
  removeFields(type: string): Array<string> {
    let fields = [
      'userMakeRequestId',
      'reminder',
      'attributes',
      'description',

      'created',
      'closedTicketDate',
      'endTreatmentDate',

      'privateResolutionComment',
      'elaspedTimeHour',
      'elaspedTime',

      'planned',
      'respondBefore',
      'resolveBefore',
      'callbackNumber',

      'channelId',
      'ticketNumber',
      'slaId',

      'takeChargeDate',
      'title',

      'ticketId',
      'end',
    ];

    let additionnalField = [];
    switch (type) {
      case 'Problem':
        additionnalField = [
          'cause',
          'locationId',
          'incidentModelId',
          'statusReasonId',
          'levelSupport',
          'organizationalUnitId',
          'originId',
          'concernedProjectId',
          'workaround',
          'solution',
          'userAffectedId',
          'attributes.created.username',
          'attributes.deleted.username',
          'attributes.updated.username',
        ];
        fields = fields.concat(additionnalField);
        break;
    }

    return fields;
  }

  /**Liste de propriété à renommer  */
  forceRename(colonne: Array<any>) {
    let listRename = {
      tags: 'entities/ticket/tagsAdd',
    };
    for (let [key, value] of Object.entries(listRename)) {
      let columns = colonne.find((x) => x.dataField == key);
      if (columns != undefined) columns.caption = TranslateService.get(value);
    }
  }
}
