import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
} from '@angular/core';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { CoreModelCompilerService } from '@clarilog/shared2';
import model from './model.json';
import { MyOrganizationCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { EnvironmentService } from '@clarilog/core';
import { AuthentificationService } from '@clarilog/core';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

@Component({
  selector: 'cl-my-organizations-mobile',
  templateUrl: './my-organizations-mobile.component.html',
  styleUrls: ['./my-organizations-mobile.component.scss'],
})
export class MyOrganizationsMobileComponent implements OnInit {
  modelState: ModelState;
  layoutState: ModelState;
  _localStorageService: LocalStorageService;

  constructor(
    private authentificationService: AuthentificationService,
    modelCompilerService: CoreModelCompilerService,
    injector: Injector,
    myOrganizationService: MyOrganizationCoreService,
    private envService: EnvironmentService,
    private localStorageService: LocalStorageService,
  ) {
    this.layoutState = new ModelState(injector);
    this._localStorageService = localStorageService;
    modelCompilerService.compile(model).subscribe((res) => {
      this.modelState = res;
    });
  }

  generateUrl(value: string): string {
    this.authentificationService.userInfo;
    let operatorCheck: boolean;
    this.localStorageService.user.isOperator().subscribe((result) => {
      operatorCheck = result;
    }).unsubscribe;
    if (operatorCheck) {
      return `${window.location.protocol}//${value}.${this.envService.baseURL}:${window.location.port}/mobile`;
    } else {
      return `${window.location.protocol}//${value}.${this.envService.baseURL}:${window.location.port}/mobile/user/home`;
    }
  }
  onClick(e) {
    window.location.replace(this.generateUrl(e.data.url));
  }
  /**
   * Permet de se déconnecter
   *
   * @memberof OrganizationListComponent
   */
  public disconnect() {
    this.authentificationService.logout();
  }

  ngOnInit(): void {}
}
