import { Component, Input } from '@angular/core';
import { TranslateService } from '@clarilog/shared/services';

/** Représente un composant d'affichage de message dans le menu tous en haut */
@Component({
  selector: 'cl-layout-message-item',
  templateUrl: './layout-message-item.component.html',
  styleUrls: ['./layout-message-item.component.scss'],
})
export class LayoutMessageItemComponent {
  private _messages: string[] = [];
  @Input() type: string;
  @Input() messageKey: string[] = [];

  isViewHelpMe: boolean = false;
  systemNotification: boolean = false;
  showPopup = false;

  @Input()
  set messages(value: string[]) {
    this._messages = value;

    //on enregistre en cache les infos
    const previousMessageKey = JSON.parse(localStorage.getItem('errorPopupMessageKey') || '[]');
    const currentCount = value.length;
    //Si il ne trouve pas hasShown et le currentCount supérieur a celui enregistré si il le trouve

    if (!this.showPopup && currentCount > 0 && !this.arraysEqual(previousMessageKey, this.messageKey)) {
      this.showPopup = true;
    }
    localStorage.setItem('errorPopupMessageKey', JSON.stringify(this.messageKey));

  }
  get messages(): string[] {
    return this._messages;
  }

  /** Propriété du buton */
  okButton: any;
  constructor(
  ) {
    this.okButton = {
      width: 300,
      text: TranslateService.get('ok'),
      type: 'default',
      stylingMode: 'contained',
      onClick: () => {
        this.showPopup = false;
      },
    };
  }

  // Ne pas afficher les messages d'erreurs pour la partie HelpDesk
  ngOnInit() {
    this.isViewHelpMe =
      localStorage.getItem('hasHelpDeskUser') == 'true' ? true : false;
  }

  ngDoCheck(): void {
    // console.log(localStorage);
  }

  /** Evenement de click */
  onMessageClick() {
    this.showPopup = true;
  }

  // on compare les deux array

  private arraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((msg, index) => msg === arr2[index]);
  }
}
