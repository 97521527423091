import { Authorize } from '@clarilog/core/services/graphql';
import { WorkflowRevocationBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-workflow')
export class WorkflowRevocationCoreService extends WorkflowRevocationBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
