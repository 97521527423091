import {
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AuthentificationService,
  EnvironmentService,
  LocalizeService,
} from '@clarilog/core';
import { TranslateService } from '@clarilog/shared2/services';
import { DxListComponent, DxTextBoxComponent } from 'devextreme-angular';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CoreGlobalSearchService } from './global-search.service';
import feedBackModel from './models/feedback-model.json';
import { CoreFeatureService } from '@clarilog/shared2/services/features/feature.service';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import moment from 'moment';
import {
  ApplicationAzureAdCoreService,
  AssetCoreService,
  ChatBotCoreService,
  FeedbackCoreService,
  LicenseCoreService,
  MyOrganizationCoreService,
  NotificationCoreService,
  UserCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CoreModelCompilerService } from '@clarilog/shared2/services/compiler/model-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import {
  ModelFieldCompilerService,
  WorkItem,
  LayoutMessageItemComponent,
  BreadcrumbService,
  TranslatedFieldHelperService,
} from '@clarilog/shared2';
import {
  CoreAuthorizationSettings,
  CorePolicyValidator,
} from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import { CoreLicenseReader } from '@clarilog/core/services2/license-reader/license-reader';
import { WorkItemsHelper } from '@clarilog/shared2/components/list/work-items/work-items-helpers';
import pluralize from 'pluralize';
import { EnumCoreService } from '@clarilog/core/services2/graphql/generated-types/enum-service';
import { DOCUMENT } from '@angular/common';

/** Object pour la liste des items à rechercher */
export class itemSearch {
  title: string;
  id: string;
  icon: string;
  route: string;
}

@Component({
  selector: 'clc-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  providers: [CoreFeatureService],
})
export class CoreContentLayoutComponent implements OnInit, OnDestroy {
  /** Affichae la consultation du kb */
  showViewer: boolean = false;
  /** Obtient l'id de la ligne sélectionné */
  idEntity: string;

  selected: any;
  langs: any;
  breadcrumbs: any;
  isOpened = false;
  features: WorkItem[];
  currentItem: any;
  currentLang: string;
  pin: boolean = false;
  toggle: boolean = false;
  /** Affiche le popup du feedback */
  showFeedback: boolean = false;
  /** Model pour le feedback */
  modelFeedback: ModelState;

  menuItems = [];
  /** Permet d'afficher les champs de recherche */
  showFieldSearch: boolean = true;
  /** Permet d'afficher le choix des items à rechercher */
  showItemSearch: boolean = false;
  /** Liste des items pouvant être recherché */
  itemsSearch: itemSearch[];
  /** Liste des items sélectionné pouvant être recherché */
  selectedItemsSearch: string[];
  /** DataSource globale de recherche */
  sourceSearch: any;
  /** Variable des éléments cible de positionnement */
  itemSearchButtonElement: any;
  itemSearchTextElement: any;
  /** Affiche le résultat de la recherche */
  showResultSearch: boolean = false;
  /** surveillance du keyUp */
  keyUp = new Subject<any>();
  /** La souscription du observable. */
  subscription: Subscription;
  /** Variable de recherche */
  textSearchValue: string;
  previousTextSearchValue: string;

  /** Permet de savoir si le textbox a le focus */
  searchTextBoxFocused: boolean = false;
  showChooserSearch: boolean = true;

  /**Permet de définir le nagivateur de l'utilisateur */
  browserUser: { name: string; version: string };

  /**Permet d'affciher le message d'incompatibilité avec un navigateur */
  isBrowserNotCompatible: boolean = true;

  routerSubsccriber: Subscription;
  @ViewChild('searchResultList')
  searchResultList: DxListComponent;
  @ViewChild('searchText') searchText: DxTextBoxComponent;
  @ViewChild(LayoutMessageItemComponent)
  layoutMessageItemComponent: LayoutMessageItemComponent;

  options = {
    onItemClick: this.itemMenuClick.bind(this),
    items: this.menuItems,
  };

  isDev: boolean = false;

  /** Permet de savoir si on est en mode vue Help-Me */
  viewHelpMe: boolean = false;

  canViewNotification: boolean = true;
  state: ModelState;

  customFeatureEnum: any;
  customFeatures: any;

  constructor(
    private authentificationService: AuthentificationService,
    private authorizationService: AuthorizationCoreService,
    public assetService: AssetCoreService,
    private service: CoreFeatureService,
    public breadcrumbService: BreadcrumbService,
    public router: Router,
    private injector: Injector,
    private localizeService: LocalizeService,
    private userService: UserCoreService,
    private envService: EnvironmentService,
    private changeDetectorRef: ChangeDetectorRef,
    private globalSearchService: CoreGlobalSearchService,
    public feedbackService: FeedbackCoreService,
    private modelCompilerService: CoreModelCompilerService,
    private licenseService: LicenseCoreService,
    public licenseReader: CoreLicenseReader,
    public translatedFieldHelperService: TranslatedFieldHelperService,
    private myOrganizationService: MyOrganizationCoreService,
    private policyValidator: CorePolicyValidator,
    private authorizationSettings: CoreAuthorizationSettings,
    private notificationService: NotificationCoreService,
    private workItemHelper: WorkItemsHelper,
    private chatBotService: ChatBotCoreService,
    private _render2: Renderer2,
    public applicationAzureAdService: ApplicationAzureAdCoreService,
    @Inject(DOCUMENT) private _document: Document,
    enumTypeService: EnumCoreService,
  ) {
    this.customFeatureEnum = enumTypeService.customFeatureType();
    this.customFeatures = this.authorizationService.user.getCustomFeature();
    if (envService.isDev === true) {
      this.isDev = true;
    }
    this.state = new ModelState(injector);

    notificationService
      .canViewNotificationComponent(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      )
      .subscribe((res) => {
        if (res.data === true) {
          this.canViewNotification == true;
        } else {
          this.canViewNotification = false;
        }
      });

    this.displayMesssage();
    this.subscription = this.keyUp
      .pipe(
        debounceTime(600),
        map((e) => ({ event: e.event, text: e.component.option('text') })),
      )
      .subscribe(this.onSearchTextChanged.bind(this));

    //  this.getBrowserUser();
  }

  loadLicenceAlert() {
    // Si help me on ne fait pas
    if (this.viewHelpMe === true) return;

    this.licenseService
      .get(
        [
          GqlSubField.create('data', [
            GqlField.create('expireAt'),
            GqlField.create('remainingAssetNumber'),
            GqlField.create('managedAssetNumber'),
            GqlField.create('remainingUserHelpDeskNumber'),
          ]),
        ],
        this.authorizationService.user.getClaim('tenantId'),
      )
      .subscribe(
        (lic) => {
          if (lic.data.expireAt != undefined) {
            let today = moment().startOf('day');
            let expireDate = moment(lic.data.expireAt);
            let timeSpan = expireDate.diff(today, 'days');

            if (timeSpan <= 15) {
              this.layoutMessageItemComponent.messages.push(
                TranslateService.get('errors/licenseExpireIn', {
                  days: timeSpan,
                }),
              );
              this.layoutMessageItemComponent.messageKey.push(
                'errors/licenseExpireIn',
              );
            }
          }

          // Variables pour la gestion du nombre des assets
          let totalAssetNumber =
            this.licenseReader.currentLicense.features.assetNumber ?? 0;
          let totalAuditAssetNumber =
            this.licenseReader.currentLicense.features.assetAuditedNumber ?? 0;
          let totalEndUserNumber =
            this.licenseReader.currentLicense.features.endUserNumber ?? 0;
          let auditedAssetNumberUsed =
            totalAuditAssetNumber - lic.data.remainingAssetNumber;
          let assetNumberUsed = totalAssetNumber - lic.data.managedAssetNumber;
          let endUserUsed =
            totalEndUserNumber - lic.data.remainingUserHelpDeskNumber;

          let ratioChecker = 99;
          let ratioAsset = (ratioChecker * totalAssetNumber) / 100;
          let ratioAssetAudit = (ratioChecker * totalAuditAssetNumber) / 100;
          let ratioEndUserNumber = (ratioChecker * totalEndUserNumber) / 100;

          let roundedAsset = ratioAsset | 0;
          let roundedAssetAudit = ratioAssetAudit | 0;
          let roundedEndUser = ratioEndUserNumber | 0;

          // Fin Variables

          //Gestions erreurs assets
          if (
            this.licenseReader.currentLicense.features.featureAssets === true &&
            this.licenseReader.currentLicense.features.featureInventories ===
              true &&
            this.licenseReader.currentLicense.features.assetUnlimited ===
              false &&
            this.licenseReader.currentLicense.features.assetAuditedUnlimited ===
              false
          ) {
            if (
              lic.data.remainingAssetNumber > 0 &&
              lic.data.managedAssetNumber > 0
            ) {
              if (
                assetNumberUsed >= roundedAsset &&
                auditedAssetNumberUsed >= roundedAssetAudit
              ) {
                this.getErrorsTranslation('errors/assetsLimit');
              } else if (assetNumberUsed >= roundedAsset) {
                this.getErrorsTranslation('errors/assetLimit');
              } else if (auditedAssetNumberUsed >= roundedAssetAudit) {
                this.getErrorsTranslation('errors/assetAuditLimit');
              } else {
              }
            } else {
              if (
                lic.data.remainingAssetNumber === 0 &&
                lic.data.managedAssetNumber === 0
              ) {
                this.getErrorsTranslation('errors/assetAuditFull');
              } else if (lic.data.managedAssetNumber <= 0) {
                this.getErrorsTranslation('errors/assetFull');
              } else if (lic.data.remainingAssetNumber <= 0) {
                this.getErrorsTranslation('errors/assetAuditFull');
              } else {
              }
            }
          } else if (
            (this.licenseReader.currentLicense.features.featureAssets ===
              true &&
              this.licenseReader.currentLicense.features.featureInventories ===
                false &&
              this.licenseReader.currentLicense.features.assetUnlimited ===
                false) ||
            (this.licenseReader.currentLicense.features.featureAssets ===
              true &&
              this.licenseReader.currentLicense.features.featureInventories ===
                true &&
              this.licenseReader.currentLicense.features.assetUnlimited ===
                false &&
              this.licenseReader.currentLicense.features
                .assetAuditedUnlimited === true) ||
            (this.licenseReader.currentLicense.features.featureAssets ===
              true &&
              this.licenseReader.currentLicense.features.featureInventories ===
                true &&
              this.licenseReader.currentLicense.features.assetUnlimited ===
                true &&
              this.licenseReader.currentLicense.features
                .assetAuditedUnlimited === false)
          ) {
            if (
              lic.data.managedAssetNumber > 0 &&
              this.licenseReader.currentLicense.features.assetUnlimited ===
                false
            ) {
              if (assetNumberUsed >= roundedAsset) {
                this.getErrorsTranslation('errors/assetLimit');
              }
            } else if (
              lic.data.remainingAssetNumber > 0 &&
              this.licenseReader.currentLicense.features
                .assetAuditedUnlimited === false
            ) {
              if (auditedAssetNumberUsed >= roundedAssetAudit) {
                this.getErrorsTranslation('errors/assetAuditLimit');
              }
            } else if (
              lic.data.remainingAssetNumber === 0 &&
              this.licenseReader.currentLicense.features
                .assetAuditedUnlimited === false
            ) {
              if (auditedAssetNumberUsed >= roundedAssetAudit) {
                this.getErrorsTranslation('errors/assetAuditFull');
              }
            } else {
              this.getErrorsTranslation('errors/assetFull');
            }
          }

          if (
            this.licenseReader.currentLicense.features.featureServiceDesk ===
            true
          ) {
            if (lic.data.remainingUserHelpDeskNumber > 0) {
              if (endUserUsed >= roundedEndUser) {
                this.getErrorsTranslation('errors/userHelpDeskLimit');
              }
            } else {
              this.getErrorsTranslation('errors/userHelpDeskFull');
            }
          }

          //on déclenche le setter de message
          this.layoutMessageItemComponent.messages =
            this.layoutMessageItemComponent.messages;
        },

        //Fin gestions Asset
      );
  }

  getErrorsTranslation(translation: string, alreadyTranslate: boolean = false) {
    var errorMessage = translation;
    if (alreadyTranslate == false) {
      errorMessage = TranslateService.get(translation);
    }
    this.layoutMessageItemComponent.messages.push(errorMessage);
    this.layoutMessageItemComponent.messageKey.push(translation);
  }
  onOutletLoaded(component) {
    component['state'] = this.state;
  }
  /** @inheritdoc */
  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
    if (this.routerSubsccriber != undefined) {
      this.routerSubsccriber.unsubscribe();
    }

    this.chatBotService.cleanChatBot(this._document);
  }

  /** Affecte la langue de l'utilisateur. */
  setPreferenceLang(e) {
    this.userService
      .setUserLocalize(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        e.value,
      )
      .subscribe((_) => window.location.reload());
  }
  /** Ouvre les paramètres */
  open() {
    this.isOpened = true;
  }
  /** Ferme les paramètres */
  close() {
    this.isOpened = false;
  }
  /** @inheritdoc */
  async ngOnInit() {
    await this.secretClientAzureAd();
    this.features = await this.service.features();

    let user = await this.userService
      .get(
        [
          GqlSubField.create('data', [
            GqlField.create('name'),
            GqlField.create('recipentSystemNotification'),
          ]),
        ],
        this.authorizationService.user.getClaim('userId'),
      )
      .toPromise();
    this.layoutMessageItemComponent.systemNotification =
      user.data.recipentSystemNotification;
    let item = {
      key: 'profile',
      icon: 'fas fa-user-alt',
      text: user.data.name,
      items: [
        {
          key: 'account',
          text: TranslateService.get('globals/myAccountClarilog'),
        },

        {
          key: 'documentation',
          beginGroup: true,
          text: TranslateService.get('globals/documentation'),
        },
        {
          key: 'feedback',
          beginGroup: true,
          text: TranslateService.get('globals/feedback'),
        },
        // TODO : A prendre en compte une fois traduit
        {
          key: 'params',
          beginGroup: true,
          text: TranslateService.get('globals/params'),
        },
        {
          key: 'contact',
          beginGroup: true,
          text: TranslateService.get('globals/contactClarilog'),
        },
        {
          key: 'disconnect',
          beginGroup: true,
          text: TranslateService.get('globals/disconnect'),
        },
      ],
    };

    // Vérification si mode HelpMe
    if (
      !(
        this.authorizationService.user.hasHelpDeskOperator() ||
        this.authorizationService.user.hasManager()
      )
    ) {
      item.items = item.items.filter(
        (f) => f.key != 'documentation' && f.key != 'feedback',
      );
      //this.showFieldSearch = false;
    }

    let hasSd = await this.service.hasServiceDeskAsync();
    if (
      hasSd == true &&
      this.policyValidator.validate('/^help-desk-user') &&
      (this.authorizationService.user.hasHelpDeskOperator() ||
        this.authorizationService.user.hasManager())
    ) {
      item.items.splice(item.items.length - 1, 0, {
        key: 'viewHelpMe',
        beginGroup: true,
        text:
          localStorage.getItem('viewHelpMe') == 'true'
            ? TranslateService.get('globals/viewOperator')
            : TranslateService.get('globals/viewHelpMe'),
      });
      this.viewHelpMe =
        localStorage.getItem('viewHelpMe') == 'true' ? true : false;
    }

    this.loadLicenceAlert();

    if (this.viewHelpMe == false) {
      this.chatBotService.processWikitChatBot(
        this._render2,
        this._document,
        false,
      );
    }

    if (
      (this.viewHelpMe === false || this.viewHelpMe == undefined) &&
      (this.authorizationService.user.hasHelpDeskOperator() ||
        this.authorizationService.user.hasManager())
    ) {
      item.items.splice(3, 0, {
        key: 'organizations',
        beginGroup: true,
        text: TranslateService.get('globals/organizations'),
      });
    }

    if (this.viewHelpMe) {
      item.items = item.items.filter((f) => f.key != 'contact');
    }

    if (this.isDev === true) {
      item.items.push({
        key: 'printPerm',
        beginGroup: true,
        text: 'Console.log des permissions',
      });
    }

    if (
      this.customFeatures.find(
        (feature) =>
          feature.name === this.customFeatureEnum[0].name && feature.enable,
      )
    ) {
      item.items.push({
        key: 'goMobile',
        beginGroup: true,
        text: 'Basculer en mobile',
      });
    }
    this.menuItems.push(item);

    /**
     * https://github.com/emilol/angular-crumbs
     */

    // Cette methode est déja appelé au début du ngOnInit
    //this.features = await this.service.features();

    // Création des items de recherche (PS: Verif sur le this.features necessaire )
    if (this.features.length > 0) {
      this.itemsSearch = this.features[0].items
        .filter((f) => f.globalSearch == true)
        .map((r) => {
          let item = new itemSearch();
          item.title = TranslateService.get(r.title);
          item.icon = r.icon;
          item.id = r.name;
          item.route = r.data.route.replace('/overview', '');
          if (item.route.indexOf('softwares') >= 0) {
            item.route = item.route.replace('softwares', 'softwares/all');
          }

          return item;
        });
      this.selectedItemsSearch = this.itemsSearch.map((s) => s.id);
    }
    this.breadcrumbService.breadcrumbChanged.subscribe((crumbs) => {
      this.breadcrumbs = crumbs;
    });
    //console.log('getPreferencesQuery');
    // this.getPreferencesQuery.fetch().subscribe(result => {
    //   const lang = this.localizeService
    //     .getLocales()
    //     .filter(l => l.value === result.data.users.getPreferences.data.lang);
    //   if (lang.length > 0) {
    //     this.currentLang = lang[0].value;
    //   }
    // });

    // Récupère la langue de l'utilisateur suivant ses préférences
    this.userService.getUserLocalize().subscribe((result) => {
      // const lang = this.localizeService
      //   .getLocales()
      //   .filter((l) => l.value === result.data.lang);
      // if (lang.length > 0) {
      // Sélectionne la valeur de la langue dans la liste déroulante
      this.currentLang = result.data.lang;
      //}
    });

    //Affiche toutes les langues de traduction
    this.langs = this.translatedFieldHelperService.getLanguageDatasource();

    this.setCurrentRoute();

    this.routerSubsccriber = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setCurrentRoute();
      }
    });

    this.getBrowserUser();
  }

  /** Fonction de déconnexion de l'utilisateur. */
  disconnect() {
    this.authentificationService.logout();
    this.workItemHelper.deleteSession();
  }

  /** Fonction pour procéder à un retour d'experience (feedback) */
  changeView() {
    let currentValue = localStorage.getItem('viewHelpMe');
    localStorage.setItem(
      'viewHelpMe',
      currentValue == 'false' || currentValue == null ? 'true' : 'false',
    );
    location.href = location.origin;
  }

  /** Fonction pour procéder à un retour d'experience (feedback) */
  feedback() {
    this.modelCompilerService.compile(feedBackModel).subscribe((model) => {
      this.modelFeedback = model;
      this.modelFeedback.sharedContext.entry.set('email', () =>
        this.authorizationService.user.getClaim('email'),
      );
      this.modelFeedback.sharedContext.entry.set(
        'name',
        () =>
          `${this.authorizationService.user.getClaim(
            'firstName',
          )} ${this.authorizationService.user.getClaim('family_name')}`,
      );

      this.showFeedback = true;
    });
  }

  /** Validation du feedback */
  onSubmit(e) {
    this.showFeedback = false;
  }

  /** Permet de récupérer les droits de l'utilisateur.  */
  account() {
    const redirectUri = encodeURIComponent(location.href);
    location.href = `${this.envService.identityURL}/Account/EditProfil?ReturnUrl=${redirectUri}`;
  }
  /** Permet de retourner sur la listes des organisations.  */
  organization() {
    const redirectUri = encodeURIComponent(location.href);
    const port = window.location.port;
    const hostName = window.location.hostname;

    let host = hostName.split('.');

    host[0] = port === '4200' ? 'main' : 'www';

    location.href =
      window.location.protocol +
      '//' +
      host.join('.') +
      ':' +
      port +
      '/?force=true';
  }
  /** Permet de rediriger la page de documentation.  */
  documentation() {
    open(' https://www.clarilog.com/ONE/documentations/index.html', '_blank');
  }

  printPerm() {
    console.log(this.authorizationSettings.settings._keys);
  }

  /** Fonction du menu "Profil" */
  itemMenuClick(e) {
    e.event.stopPropagation();
    const itemData = e.itemData;

    // Récupère l'item sélectionné du menu
    switch (itemData.key) {
      // Dans le cas de la déconnexion on appel la fonction de déconnexion
      case 'disconnect':
        this.disconnect();
        break;
      case 'account':
        this.account();
        break;
      case 'organizations':
        this.organization();
        break;
      case 'params':
        this.open();
        break;
      case 'documentation':
        this.documentation();
        break;
      case 'feedback':
        this.feedback();
        break;
      case 'viewHelpMe':
        this.changeView();
        break;
      case 'printPerm':
        this.printPerm();
        break;
      case 'goMobile':
        this.goToMobile();
        break;
      case 'contact':
        this.contactClarilog();
    }
  }
  /** Clique en dehors de paramètre. */
  onClickedOutside(e?) {
    this.close();
  }
  /** Ouvre l'item grâce au clique molette. */
  itemFeatureMouseDown(e, item) {
    // Récupère la route de la feature cliqué
    const route = typeof item === 'string' ? item : item.data.route;
    if (e.event != undefined && e.event.which === 2) {
      const url = this.router.createUrlTree([route]);
      window.open(url.toString(), '_blank');
    }
  }
  /** Fonction de selection d'une feature. */
  async itemFeatureClick(e, item, replaceUrl = false) {
    // Récupère la route de la feature cliqué
    const route = typeof item === 'string' ? item : item.data.route;
    if (e.event != undefined && (e.event.ctrlKey || e.event.metaKey)) {
      e.event.preventDefault();
      e.event.stopPropagation();
      setTimeout(() => {
        const url = this.router.createUrlTree([route]);
        window.open(url.toString(), '_blank');
      });
    } else {
      // Redirige vers la route
      this.currentItem = item;
      let extraNavigate = {};
      if (replaceUrl == true) {
        extraNavigate = { replaceUrl: true };
      }
      const result = await this.router.navigate([route], extraNavigate);
      if (result === false) {
        this.setCurrentRoute();
      }
    }
  }
  /** Permet d'activé le menuitem en fonction de la route. */
  private setCurrentRoute() {
    const queryParamsIndex = this.router.routerState.snapshot.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.routerState.snapshot.url
        : this.router.routerState.snapshot.url.slice(0, queryParamsIndex);

    // Vérification si l'url permet d'avoir le menu
    this.showChooserSearch = this.globalSearchService.showChooseMenu();

    // Recherche de l'élément sélectionné.
    if (baseUrl.indexOf('/administration') !== -1) {
      this.currentItem = <any>'administration';
      this.changeDetectorRef.detectChanges();
      return;
    }
    if (baseUrl.indexOf('/identity-users') !== -1) {
      this.currentItem = <any>'identity-users';
      this.changeDetectorRef.detectChanges();
      return;
    }
    for (const group of this.features) {
      let index = group.items.findIndex((f) =>
        baseUrl.startsWith(
          `/${
            f.data.route.indexOf('/') !== -1
              ? f.data.route.substring(0, f.data.route.indexOf('/'))
              : f.data.route
          }`,
        ),
      );
      if (index !== -1) {
        this.currentItem = group.items[index];
        this.changeDetectorRef.detectChanges();
        break;
      }
    }
    if (this.currentItem == undefined) {
      if (this.features.length > 0 && this.features[0].items.length > 0) {
        let item = this.features[0].items[0];
        this.currentItem = item;
        this.changeDetectorRef.detectChanges();
        this.itemFeatureClick({}, item, baseUrl == '/' ? true : false);
      } else {
        // Si il n'y a aucun droit
        this.router.navigate(['unauthorized']);
      }
    }
  }

  /** Événement d'initialisation */
  onSearchButtonInitialized(e) {
    this.itemSearchButtonElement = e.element;
    this.changeDetectorRef.detectChanges();
  }
  /** Evénemet d'initialisation */
  onSearchTextInitialized(e) {
    this.itemSearchTextElement = e.element;
    this.changeDetectorRef.detectChanges();
  }

  /** Evénement click sur le choix des items à rechercher */
  itemSearchClick(e) {
    this.showItemSearch = true;
  }

  /** Evénement de recherche globale */

  onSearchTextChanged(e) {
    if (this.previousTextSearchValue == undefined) {
      this.previousTextSearchValue = '';
    }

    if (
      e.text != undefined &&
      (this.previousTextSearchValue.trim().toLocaleLowerCase() !=
        e.text.trim().toLocaleLowerCase() ||
        e.event.keyCode == 13)
    ) {
      this.textSearchValue = e.text;
      this.search();
      this.previousTextSearchValue = this.textSearchValue;
    }
  }
  isSearchLoading: boolean = false;
  searchSubscribe: Subscription = null;

  /** Lance la recherche */
  search() {
    if (this.textSearchValue == undefined) {
      return;
    }

    if (this.isSearchLoading) {
      //annule la recherche si la precedente a pas fini
      setTimeout(() => {
        this.search();
      }, 2000);
      return;
    }

    if (this.searchSubscribe != undefined) {
      this.searchSubscribe.unsubscribe();
    }

    // If number
    let parse = parseInt(this.textSearchValue);
    let allowCall = 2;
    if (
      parse != undefined &&
      !isNaN(parse) &&
      parse.toString() == this.textSearchValue
    ) {
      allowCall = 1;
    }

    if (
      this.textSearchValue != undefined &&
      this.textSearchValue.length >= allowCall
    ) {
      this.sourceSearch = [];
      this.showResultSearch = true;
      this.isSearchLoading = true;
      // Recherche global
      this.searchSubscribe = this.globalSearchService
        .search(this.selectedItemsSearch, this.textSearchValue)
        .subscribe((r) => {
          for (let key of r) {
            let icon = this.itemsSearch.filter((f) => f.id == key.id);
            if (icon.length > 0) {
              key.icon = icon[0].icon;
            }
          }
          this.sourceSearch = r;
          this.isSearchLoading = false;
          this.searchSubscribe = null;
        });
    } else {
      this.sourceSearch = [];
      this.showResultSearch = false;
    }
  }

  /** Evénement de perte de focus */
  onSearchTextFocusOut(e) {
    this.searchTextBoxFocused = false;
    this.reduceTextBox();
  }

  /** Evénement de prise de focus */
  onSearchTextFocusIn(e) {
    this.searchTextBoxFocused = true;
    e.component.option('width', '500px');
  }

  /** Gestion de la taille du textbox */
  reduceTextBox() {
    setTimeout(() => {
      this.searchText.instance.option('width', '200px');
    }, 200);
  }

  /** Evénement sur le clic dans la liste */
  onSearchResultItemClick(e) {
    let group = e.component.option('items')[e.itemIndex.group];
    let id = e.itemData.id;

    if (group.useEditMode == undefined || group.useEditMode) {
      if (
        group.urlGroup.includes('tickets') ||
        group.urlGroup.includes('requests') ||
        group.urlGroup.includes('incidents') ||
        group.urlGroup.includes('problems')
      ) {
        let type = e.itemData.__typename.toLowerCase();
        let pluralType = pluralize.plural(type);
        let url = `${pluralType}/edit/${id}`;
        let finalURl = this.resultItemClickLink(url, type);

        window.open(finalURl);
      } else {
        window.open(`${group.url}/edit/` + id);
      }
    } else {
      this.showViewer = true;
      this.idEntity = id;
    }
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    //   this.router.navigateByUrl(`${items[0].route}/edit/` + id)
    // );

    this.showResultSearch = false;
  }

  /** Evénement sur l'appuie d'une touche */
  onSearchTextKeyDown(e) {
    if (
      this.showResultSearch &&
      (e.event.keyCode == 40 || e.event.keyCode == 38)
    ) {
      this.searchResultList.instance.focus();
    }
  }

  /** Événement lorsque le popover se ferme */
  onResultSearchHidden(e) {
    this.reduceTextBox();
    if (this.searchText?.instance != undefined) {
      this.searchText.instance.blur();
    }
  }

  /** Événement sur le clic d'un groupe */
  onItemGroupClick(e) {
    let url = `${e.urlGroup}`;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url], {
        queryParams: {
          searchFilter: this.textSearchValue,
        },
      });
    });
    this.showResultSearch = false;
  }

  onItemGroupAuxClick(e) {
    window.open(`${e.urlGroup}?searchFilter=` + this.textSearchValue);
    this.showResultSearch = false;
  }

  public async displayMesssage() {
    //Part du principe que service desk est accessible

    if (
      !this.authorizationService.user.hasHelpDeskOperator() &&
      !this.authorizationService.user.hasManager() &&
      this.authorizationService.user.hasHelpDeskUser()
    ) {
      localStorage.setItem('hasHelpDeskUser', 'true');
    } else {
      let currentValue = localStorage.getItem('viewHelpMe');
      localStorage.setItem(
        'hasHelpDeskUser',
        currentValue == 'true' ? 'true' : 'false',
      );
    }
  }

  /**Liste des navigateurs excepter safari et chrome */
  public getBrowserUser() {
    let browserListIncompatibility = [
      {
        browserName: 'Firefox',
        userAgentId: 'Firefox',
      },
      {
        browserName: 'Edge',
        userAgentId: 'Edg',
      },
      {
        browserName: 'Opera',
        userAgentId: 'Opr',
      },
    ];

    // Pour la version prendre le 1er chiffre.
    //104 eg
    //103 ch
    //fire 100
    //saf 603
    /** Nous assurons la comptabilité avec les versions courantes des éditeurs et jusqu’au 2 versions */
    let versionSupportedForEachBrowser = {
      chrome: [101],
      firefox: [101],
      safari: [602],
      edg: [101],
      opr: [87],
    };

    let userAgent = navigator.userAgent.toLocaleLowerCase();
    let version = userAgent.split(' ');
    browserListIncompatibility.map((element) => {
      this.getBrowserData(element.userAgentId);
    });

    // Verifie pour Chrome et Safari
    this.getBrowserDataForSafariOrChrome('Chrome');
    this.getBrowserDataForSafariOrChrome('Safari');

    let versionUserAgent = Number(this.browserUser.version?.split('.')[0]);
    let versionSupported =
      versionSupportedForEachBrowser[this.browserUser.name];
    this.isBrowserNotCompatible = versionUserAgent < versionSupported;
    if (this.isBrowserNotCompatible || this.browserUser == null)
      this.getErrorsTranslation('globals/browserIncompatibility');
  }

  public getBrowserData(value: string) {
    let userAgent = navigator.userAgent.toLocaleLowerCase();
    let userAgentSplitToArray = userAgent.split(' ');

    let browserName = value.toLocaleLowerCase();
    let userAgentArray = userAgentSplitToArray.reverse();
    let index = userAgentArray.findIndex((x) => x.includes(browserName));
    if (index > -1) {
      let split = userAgentArray[index].split('/');
      this.browserUser = {
        name: browserName,
        version: split[1],
      };
    }
  }

  public getBrowserDataForSafariOrChrome(browserName: string) {
    if (this.browserUser == null) {
      this.getBrowserData(browserName);
    }
  }

  getTranslateField(field) {
    if (field != undefined) {
      let lang = this.translatedFieldHelperService.getTranslateKey();
      return field[lang];
    }

    return field;
  }

  getTranslateValue(value) {
    if (typeof value == 'object' && !Array.isArray(value)) {
      return this.getTranslateField(value);
    }
    return value;
  }

  contactClarilog() {
    window.open('mailto:contact@clarilog.com', '_blank');
  }

  /** Permet d'accéder a la version mobile.  */
  goToMobile() {
    if (localStorage.getItem('viewHelpMe')) {
      window.open('/mobile/user/home', 'mobileView', 'width=430,height=932');
    } else {
      window.open('/mobile', 'mobileView', 'width=430,height=932');
    }
  }

  public resultItemClickLink(url: string, ticketType: string) {
    if (!this.policyValidator.validate(`${ticketType}.all-${ticketType}s`)) {
      if (
        this.policyValidator.validate(`${ticketType}.my-team-${ticketType}s`)
      ) {
        url = url.replace(`${ticketType}s/`, `${ticketType}s/my-team/all/`);
      } else if (
        this.policyValidator.validate(`${ticketType}.my-${ticketType}s`)
      ) {
        url = url.replace(`${ticketType}s/`, `${ticketType}s/my/all/`);
      }
    }
    return url;
  }

  async secretClientAzureAd() {
    let hasExpire = await lastValueFrom(
      this.applicationAzureAdService.hasExpireSecretClientAzureAd(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      ),
    );
    if (
      hasExpire?.data != '' &&
      hasExpire?.data != undefined &&
      hasExpire?.data != null
    ) {
      this.getErrorsTranslation(hasExpire?.data, true);
    }
  }
}
