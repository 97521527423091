import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslatedFieldHelperService } from '@clarilog/shared2';
import DataSource from 'devextreme/data/data_source';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'cl-mobile-actions',
  templateUrl: './mobile-actions.component.html',
  styleUrls: ['./mobile-actions.component.scss'],
})
export class MobileActionsComponent implements OnInit {
  constructor(
    public translateService: TranslatedFieldHelperService,
    private cd: ChangeDetectorRef,
  ) {}

  @Input() dataSource: DataSource;
  @Input() title: string;
  @Input() icon: string;
  @Input() index: number;
  @Input() displayExpr: string = 'name';
  @Input() keyExpr: string = 'id';
  @Input() translatable: boolean = false;
  @Input() popupText: string;
  _visible: boolean = true;
  @Input() set visible(value) {
    if (value == null) {
      return;
    }
    this._visible = value;
  }

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  finalDisplayExpr: string;

  isDropdownPopupVisible = false;

  columns = [
    {
      label: "[resource('entities/ticketStatus/name')]",
      field: 'name',
      translatable: true,
    },
  ];

  popupId = uuidv4();

  onItemClick(e) {
    this.isDropdownPopupVisible = false;
    this.emitClick(e.itemData);
  }

  emitClick(data: any) {
    if (this._visible === true) {
      this.onClick.emit(data);
    }
  }

  onButtonClick($event) {
    if (this.dataSource != undefined) {
      this.isDropdownPopupVisible = true;
    } else {
      this.emitClick(this);
    }
  }

  initDisplayExpr() {
    this.finalDisplayExpr =
      this.displayExpr +
      (this.translatable === true
        ? '.' + this.translateService.getTranslateKey()
        : '');
  }

  ngOnInit(): void {
    this.initDisplayExpr();
  }
}
