import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthentificationService,
  EnvironmentService,
  GC,
  GCFactory,
} from '@clarilog/core';
import {
  AuthorizationCoreService,
  CorePolicyValidator,
} from '@clarilog/core/services2/authorization';
import { MobileLayoutNavigationEvent } from '@clarilog/layouts2/mobile-layout/mobile-layout.component';
import {
  CoreFeatureService,
  MobileWorkItem,
  WorkItem,
} from '@clarilog/shared2';
import { MobileListEventData } from '@clarilog/shared2/components/mobile-list/mobile-list.component';
import { TranslateService } from '@clarilog/shared2/services';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { DxTreeViewComponent } from 'devextreme-angular';

export enum MobileHeaderEvent {
  LIST_ACTION = 'LIST_ACTION', //Actionde liste ex: refresh
  LIST_ACTION_REMOVE = 'LIST_ACTION_REMOVE', //Actionde liste ex: refresh
  ACTION = 'ACTION', // action global ex: edit / add / delete
  ACTION_REMOVE = 'ACTION_REMOVE', // action global ex: edit / add / delete
  CUSTOM_ACTION = 'CUSTOM_ACTION', //autre type d'action
  CUSTOM_ACTION_REMOVE = 'CUSTOM_ACTION_REMOVE', //autre type d'action
}

@Component({
  selector: 'cl-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  private gc: GC;

  showPopSystem: Boolean = false;
  showMyOrga: Boolean = false;

  constructor(
    private gcFactory: GCFactory,
    private route: ActivatedRoute,
    private envService: EnvironmentService,
    private router: Router,
    private authService: AuthentificationService,
    private authentificationService: AuthentificationService,
    private authorizationService: AuthorizationCoreService,
    private service: CoreFeatureService,
    private policyValidator: CorePolicyValidator,
  ) {
    // En attendant de savoir ce qu'on en fait
    // if (
    //   this.authorizationService.user.hasHelpDeskOperator() ||
    //   this.authorizationService.user.hasManager()
    // ) {
    //   this.showMyOrga = true;
    // }
    if (envService.isDev === true) {
      this.isDev = true;
    }
    this.gc = gcFactory.create();
    this.selectedItems = [];
  }

  @ViewChild(DxTreeViewComponent, { static: false })
  treeView: DxTreeViewComponent;

  @Input() state: ModelState;
  @Input() optionalStartingRoute: string;

  @Output() isMenuOpenChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  listActions: MobileListEventData[] = [];
  listSystem: MobileListEventData[] = [];
  actions: MobileListEventData[] = [];
  customActions: MobileListEventData[] = [];
  selectedItems;
  /*
  Gestion menu
   */
  _isMenuOpen: boolean = false;
  _debounceMenuOpen: boolean = false;
  /** Permet de savoir si on est en mode vue Help-Me */
  viewHelpMe: boolean = false;
  viewTitle: string;

  isDev: boolean = false;

  @Input() set isMenuOpen(value) {
    let oldValue = this._isMenuOpen;
    this._isMenuOpen = value;
    this.isMenuOpenChange.emit(this._isMenuOpen);
    let items = this.items as MobileWorkItem[];

    if (this._isMenuOpen === true && oldValue != this._isMenuOpen) {
      this.treeView.instance.focus();
    }
  }

  get isMenuOpen() {
    return this._isMenuOpen;
  }

  @Input() items: WorkItem[];

  debounceMenuOpen() {
    this._debounceMenuOpen = true;

    setTimeout((_) => {
      this._debounceMenuOpen = false;
    }, 50);
  }

  toogleMenu() {
    if (this._debounceMenuOpen === false) {
      setTimeout((_) => {
        this.isMenuOpen = !this.isMenuOpen;
      }, 50);
    }
  }

  /*
 END Gestion menu
 */

  menuSelectionChanged(e) {
    let item = e.itemData as MobileWorkItem;
    if (item.children != undefined && item.children.length > 0) {
      item.isSelected = false;
      this.collapseParents(item);
      let defaultChild = this.selectFirstMenu(item.children);

      defaultChild.isSelected = true;
      item = defaultChild;
    } else {
      if (
        item.data.route.includes('help-desk-incident') ||
        item.data.route.includes('help-desk-request')
      ) {
        this.router.navigate([this.generateRoute('user/' + item.data.route)]);
      } else {
        this.router.navigate([this.generateRoute(item.data.route)]);
      }
      this.isMenuOpen = false;
    }
  }

  private navigateInTree(
    keyToFind: string,
    tree: MobileWorkItem[],
  ): MobileWorkItem {
    let current = keyToFind;
    if (tree != undefined)
      for (let child of tree) {
        if (child.children != undefined && child.children.length > 0) {
          //cas c'est un parent donc on verifie les enfants  car dsans un menu uniquement des enfant sont selectionné
          let childItem = this.navigateInTree(keyToFind, child.children);

          if (childItem != undefined) {
            return childItem;
          }
        }
        if (current.startsWith(child.data.route) || child.data.route === '') {
          return child;
        }
      }
    // not found
    return null;
  }

  private selectFirstMenu(items: MobileWorkItem[]) {
    if (items != undefined) {
      for (let item of items) {
        if (item.children == undefined || item.children.length == 0) {
          return item;
        } else {
          return this.selectFirstMenu(item.children);
        }
        break;
      }
    }
  }

  private generateRoute(route: string) {
    if (
      this.optionalStartingRoute != undefined &&
      this.optionalStartingRoute.trim() != ''
    ) {
      return '/' + this.optionalStartingRoute + '/' + route;
    }
  }

  initTreeView(e) {
    this.treeView = e.component;
  }

  private selectCorrectMenu() {
    let routeTree = this.router.url
      .split('/')
      .filter(
        (el) =>
          el.trim() != '' &&
          this.optionalStartingRoute != undefined &&
          this.optionalStartingRoute.trim() != '' &&
          el != this.optionalStartingRoute,
      );

    let editNewIndex = routeTree.findIndex(
      (el) => el === 'new' || el === 'edit',
    );

    if (editNewIndex != -1 && editNewIndex != 0) {
      routeTree = routeTree.slice(0, editNewIndex);
    }

    let currentRoute: MobileWorkItem;
    let needNavigate = false;
    if (routeTree == undefined || routeTree.length == 0) {
      currentRoute = this.selectFirstMenu(this.items);
      needNavigate = true;
    } else {
      currentRoute = this.navigateInTree(routeTree.join('/'), this.items);
    }
    this.selectedItems = [currentRoute];
    if (currentRoute != null) currentRoute.isSelected = true;
    if (needNavigate === true && currentRoute != undefined) {
      this.router.navigate([this.generateRoute(currentRoute.data.route)]);
    }
  }

  addListNoDuplicate(
    list: MobileListEventData[],
    elemToAdd: MobileListEventData,
  ) {
    let existIndex = list.findIndex(
      (el) => el.eventActionReturnType === elemToAdd.eventActionReturnType,
    );

    if (existIndex === -1) {
      list.push(elemToAdd);
    }
  }

  removeListAction(
    list: MobileListEventData[],
    elemToRemove: MobileListEventData,
  ) {
    return list.filter(
      (el) => el.eventActionReturnType != elemToRemove.eventActionReturnType,
    );
  }

  async ngOnInit() {
    this.selectCorrectMenu();
    this.manageBtn();
    if (
      this.state.sharedContext.params.get(MobileHeaderEvent.LIST_ACTION) ==
      undefined
    ) {
      this.gc.forDispose(
        this.state.on.subscribe((event) => {
          if (event.eventName === MobileHeaderEvent.LIST_ACTION) {
            this.addListNoDuplicate(this.listActions, event.eventData);
          }
          if (event.eventName === MobileHeaderEvent.LIST_ACTION_REMOVE) {
            this.listActions = this.removeListAction(
              this.listActions,
              event.eventData,
            );
          }
          if (event.eventName === MobileHeaderEvent.ACTION) {
            this.addListNoDuplicate(this.actions, event.eventData);
          }

          if (event.eventName === MobileHeaderEvent.ACTION_REMOVE) {
            this.actions = this.removeListAction(this.actions, event.eventData);
          }

          if (event.eventName === MobileHeaderEvent.CUSTOM_ACTION) {
            this.addListNoDuplicate(this.customActions, event.eventData);
          }
          if (event.eventName === MobileHeaderEvent.CUSTOM_ACTION_REMOVE) {
            this.customActions = this.removeListAction(
              this.customActions,
              event.eventData,
            );
          }
          // if (event.eventName === MobileLayoutNavigationEvent.LOADING) {
          //   for (let newItem of event.eventData) {
          //     newItem.parentId = this.selectedItems[0].data.route;
          //     newItem.data = {};
          //     newItem.data.route = uuidv4();
          //     console.log(newItem)
          //     this.items.push(newItem)
          //   }
          //   this.items.push()
          //   console.log(event.eventData)
          // }
        }),
      );
    } else {
      this.listActions.push(
        this.state.sharedContext.params.get(MobileHeaderEvent.LIST_ACTION),
      );
    }

    // Vérification si mode HelpMe
    let hasSd = await this.service.hasServiceDeskAsync();

    if (
      hasSd == true &&
      (this.authorizationService.user.hasHelpDeskOperator() ||
        this.authorizationService.user.hasManager())
    ) {
      this.viewTitle =
        localStorage.getItem('viewHelpMe') == 'true'
          ? TranslateService.get('globals/viewMobileOperator')
          : TranslateService.get('globals/viewMobileHelpMe');
      this.viewHelpMe = this.authorizationService.user.hasHelpDeskUser()
        ? true
        : false;
    }
  }

  manageClick(type: string) {
    this.state.on.emit({ eventName: type, eventData: null });
  }

  ngOnDestroy(): void {
    this.gc.dispose();
  }

  ngAfterViewInit(): void {}

  close(timeout = 200) {
    if (this._isMenuOpen === true) {
      this._isMenuOpen = false;
      //this.isMenuOpenChange.emit(false);
      this.debounceMenuOpen();
    }
  }

  onFocusOut(e) {
    this.close(0);
  }

  onFocusOut2() {
    console.log('fdùsmfsldùfk');
  }

  manageBtn() {
    let addData = {
      icon: 'fa-solid fa-user',
      hint: 'User',
      eventActionReturnType: MobileLayoutNavigationEvent.NAVIGATE,
    } as MobileListEventData;

    this.listSystem.push(addData);
    this.gc.forDispose(
      this.state.on.subscribe((event) => {
        if (event.eventName === MobileLayoutNavigationEvent.NAVIGATE) {
          this.showPopSystem = true;
        }
      }),
    );
  }

  closePopUp() {
    this.showPopSystem = !this.showPopSystem;
    return false;
  }

  logout() {
    this.authService.disconnect();
  }

  goOrga() {
    const redirectUri = encodeURIComponent(location.href);
    const port = window.location.port;
    const hostName = window.location.hostname;

    let host = hostName.split('.');

    host[0] = port === '4200' ? 'main' : 'www';

    location.href =
      window.location.protocol +
      '//' +
      host.join('.') +
      ':' +
      port +
      '/organizations/my-organizations/mobile';
  }

  switchMode() {
    if (localStorage.getItem('displayMode') == 'helpme') {
      this.router.navigate([`/mobile`]).then(() => window.location.reload());
    } else {
      this.router
        .navigate([`/mobile/user/home`])
        .then(() => window.location.reload());
    }
  }
  backToHome() {
    let pathName = window.location.pathname.toString();

    if (pathName.includes('user')) {
      this.router.navigate([`/mobile/user/home`]);
    } else {
      this.router.navigate([`/mobile`]);
    }
  }

  // Fermer le parent manuellement
  collapseParents(itemValue: MobileWorkItem) {
    if (itemValue.expanded) {
      this.treeView.instance.collapseItem(itemValue); // Fermer tous les parents au départ
    } else {
      this.treeView.instance.expandItem(itemValue); // Fermer tous les parents au départ
    }
  }

  /** Permet d'accéder a la version mobile.  */
  goPc() {
    location.href = `/`;
  }
}
