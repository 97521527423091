import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import {
  DesignerEventAction,
  DesignerEventData,
  DesignerEventKey,
  DesignerEventType,
} from '@clarilog/shared2';
import { FormDesignerCoreService } from '@clarilog/core/services2/graphql/generated-types/services/form-designer.service';
import { OptionDesigner } from '@clarilog/core/services2/graphql/generated-types/services/form-designer.service';
/** Représente le mode d'affichage du libellé. */
export type WorkItemFieldMode = 'line' | 'default' | 'full' | 'inline';

/** Représente un composent field d'un formulaire. */
@Component({
  selector: 'clc-work-item-field',
  templateUrl: './work-item-field.component.html',
  styleUrls: ['./work-item-field.component.scss'],
})
export class CoreWorkItemFieldComponent implements OnChanges {
  /** Obtient ou définit la visibilité. */
  @Input() visibled: boolean;
  /** Obtient ou définit le libellé. */
  @Input() label: string;
  /** Obtient ou définit les informations d'aide pour l'utilisateur. */
  @Input() hint: string | Function;
  /** Degfinit la visibilité du hint Ex on peux le mettre en FULL pour qu'il soit fortement visible */
  @Input() hintVisibility: string;
  /** Obtient ou définit une valeur indiquant si le form est chargé. */
  @Input() loaded: boolean = true;
  /** Obtient ou définit une valeur indiquant la validité. */
  @Input() isValid: boolean;
  /** Obtient ou définit la liste des erreurs. */
  @Input() errors;
  /** Obtient ou définit l'état indiquant si l'élément est requis. */
  @Input() required: boolean;
  /** Obtient ou définit le mode. */
  @Input() mode: WorkItemFieldMode = 'default';
  /** Obtient ou définit si la valeur contenu a changé. */
  @Input() valueChange: boolean = false;
  /** Obtient ou définit si la valeur contenu a changé. */
  @Input() control: any;

  _hasOptionDesigner: OptionDesigner;

  /** Obtient ou définit la classe css en fonction du mode. */
  @HostBinding('class')
  public get getCssClasses(): any {
    return this.mode;
  }

  /** Active la classe css hide si la propriété @property {visibled} est false. */
  @HostBinding('class.hide')
  public get getCssHide(): any {
    return !this.visibled;
  }

  /** Used for designer **/

  @Input() designerMode = false;
  @Input() designerId: string;
  @Input() state: ModelState;
  getAction = DesignerEventAction;

  formAction(e: any, action: DesignerEventAction) {
    e.preventDefault();
    e.stopPropagation();

    this.state.on.emit({
      eventName: DesignerEventKey,
      eventData: new DesignerEventData(
        DesignerEventType.CONTROL,
        action,
        this.designerId,
      ),
    });
    e.prevent;
  }

  // contextMenu(e: MouseEvent) {
  //   if (this.designerMode === true) {
  //     FormDesignerPopupComponent.actionDialog({
  //       title: TranslateService.get('entities/formDesigner/popupTitleControl'),
  //       message: '<b>' + TranslateService.get('entities/formDesigner/popupDescrControl') + ' :</b><br>' + this.label,
  //       hasDown: true,
  //       hasRemove: true,
  //       hasEdit: true
  //     }).show().then(dialogResult => {
  //       if (dialogResult.buttonText === 'addDown') {
  //         this.state.on.emit({
  //           eventName: DesignerEventKey,
  //           eventData: new DesignerEventData(DesignerEventType.CONTROL, DesignerEventAction.ADD_DOWN, this.designerId),
  //         });
  //       }
  //       if (dialogResult.buttonText === 'edit') {
  //         this.state.on.emit({
  //           eventName: DesignerEventKey,
  //           eventData: new DesignerEventData(DesignerEventType.CONTROL, DesignerEventAction.EDIT, this.designerId),
  //         });
  //       }
  //       if (dialogResult.buttonText === 'remove') {
  //         this.state.on.emit({
  //           eventName: DesignerEventKey,
  //           eventData: new DesignerEventData(DesignerEventType.CONTROL, DesignerEventAction.REMOVE, this.designerId),
  //         });
  //       }

  //     });

  //     return false;
  //   }
  //   return true;
  // }

  constructor(private formDesignerService: FormDesignerCoreService) {}

  private hintFunc: Function;

  ngOnInit(): void {
    if (this.designerMode === true) {
      this._hasOptionDesigner = this.formDesignerService.hasOptionDesigner(
        this.control,
        this.required,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hint != undefined &&
      typeof changes.hint.currentValue === 'function'
    ) {
      this.hintFunc = <Function>this.hint;
      this.hint = this.hintFunc();
    } else if (
      changes.valueChange != undefined &&
      changes.valueChange.currentValue === true
    ) {
      if (this.hintFunc != undefined) {
        this.hint = this.hintFunc();
      }
    }
  }
}
