<div class="container">
  <div class="title">
    {{ title }}
  </div>
  <dx-list
    id="widgets"
    class="widget"
    [items]="defaultSource"
    [scrollingEnabled]="true"
    [noDataText]="'entities/alarm/noAlam' | translate"
    [grouped]="true"
    [collapsibleGroups]="true"
    groupTemplate="group"
    itemTemplate="alarmItems"
    (onGroupRendered)="onGroupRendered($event)"
    (onScroll)="onScroll($event)"
  >
    <div *dxTemplate="let alarmType of 'group'" id="test-group-func">
      @if(!isCollapsed){
        <b>{{ translateAlarmType(alarmType.key) }}</b><br/>
      } @else {
        <div class="group-header">
          <i [class]="getAlarmTypeIcon(alarmType.key)" id="alarmIcon"></i>
          <span class="badge" *ngIf="isCollapsed">{{ alarmType.countNotViewedAlert }}</span>
        </div>
      }
    </div>

    <div
      *dxTemplate="let alarmItem of 'alarmItems'; let indexColumn = index"
      [ngClass]="indexColumn % 2 === 0 ? '' : 'cl-row-color'"
      (click)="clickEvent($event, alarmItem)"
      [matTooltip]="
        getDisplayExpr(alarmItem.name) +
        ' (' +
        alarmItem.countNotViewedAlert +
        ')'
      "
      matTooltipPosition="before"
    >
      <b>{{ getDisplayExpr(alarmItem.name) }}</b>
      <b> ({{ alarmItem.countNotViewedAlert }})</b> <br />
    </div>
  </dx-list>
</div>

<dx-load-panel
  [visible]="isVisible"
  [showIndicator]="true"
  [showPane]="true"
  [position]="{ at: 'center', my: 'center', of: '#widgets' }"
  [shading]="false"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
