<clc-dynamic-popup
  [height]="500"
  [width]="500"
  [title]="'entities/labelPrinter/_title/plural' | translate"
  [titleBtn]="'globals/printForm' | translate"
  [(isVisible)]="_isVisible"
  (isVisibleChange)="visibleChange($event)"
  [inputModel]="massiveEditModel"
  [(selectedKeys)]="selectedKeys"
  [hideOnOutsideClick]="false"
  (onSaved)="onDynamicFormSave($event)"
  (onFormLoaded)="onDynamicFormLoaded($event)"
  [updateFn]="printCallBack"
>
</clc-dynamic-popup>

<dx-popup
  [width]="400"
  [height]="130"
  [showTitle]="false"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [(visible)]="progressVisibe"
>
  <div class="cl-print-popup">
    <dx-progress-bar
      [min]="0"
      [max]="selectedKeys != undefined ? selectedKeys.length : 1"
      [value]="progressValue"
      [statusFormat]="format"
    >
    </dx-progress-bar>
    <dx-button
      type="default"
      class="button-info"
      text="Annuler"
      (onClick)="onCancelPrint($event)"
    >
    </dx-button>
  </div>
</dx-popup>
