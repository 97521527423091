import { Directive, ViewContainerRef, ChangeDetectorRef } from '@angular/core';

/** Représente une classe host. */
@Directive({
  selector: '[clc-work-item-host]',
})
export class CoreWorkItemHostDirective {
  constructor(public viewContainerRef: ViewContainerRef, private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
