import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfAssetCategory, FilterOfAssetCategory, QueryContextOfAssetCategory, ServiceSingleResultOfInt64, ServiceListResultOfAssetCategory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfSecurityGroup, FilterOfSecurityGroup, QueryContextOfSecurityGroup, ServiceSingleResultOfBoolean, ServiceListResultOfNamingConvention, FilterOfNamingConvention, QueryContextOfNamingConvention, ServiceSingleResultOfProcessActionEntity, FilterOfProcessActionEntity, QueryContextOfProcessActionEntity, ServiceListResultOfAsset, FilterOfAsset, QueryContextOfAsset, ServiceListResultOfAssetModel, FilterOfAssetModel, QueryContextOfAssetModel, ServiceListResultOfAssetCategoryRule, FilterOfAssetCategoryRule, QueryContextOfAssetCategoryRule, ServiceListResultOfSnmpMapInformation, FilterOfSnmpMapInformation, QueryContextOfSnmpMapInformation, ServiceListResultOfSnmpMapInformationKb, FilterOfSnmpMapInformationKb, QueryContextOfSnmpMapInformationKb, AssetCategoryInput, FieldUpdateOperatorOfAssetCategory } from '../types'

export type GetAssetCategoriesResultType = {
    assetCategories: {
        get?: Maybe<ServiceSingleResultOfAssetCategory>
    }
}

export type GetAssetCategoriesBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getAssetCategories ${args} {
    assetCategories {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstAssetCategoriesResultType = {
    assetCategories: {
        first?: Maybe<ServiceSingleResultOfAssetCategory>
    }
}

export type FirstAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstAssetCategories ${args} {
    assetCategories {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAssetCategoriesResultType = {
    assetCategories: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAssetCategories ${args} {
    assetCategories {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssetCategoriesResultType = {
    assetCategories: {
        find?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type FindAssetCategoriesBaseVariables = {
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfAssetCategory = null, $filter: FilterOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssetCategories ${args} {
    assetCategories {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchAssetCategoriesResultType = {
    assetCategories: {
        search?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type SearchAssetCategoriesBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchAssetCategories ${args} {
    assetCategories {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaAssetCategoriesResultType = {
    assetCategories: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaAssetCategoriesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaAssetCategories ${args} {
    assetCategories {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataAssetCategoriesResultType = {
    assetCategories: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataAssetCategories ${args} {
    assetCategories {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryAssetCategoriesResultType = {
    assetCategories: {
        customQuery?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type CustomQueryAssetCategoriesBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryAssetCategories ${args} {
    assetCategories {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdAssetCategoriesResultType = {
    assetCategories: {
        customQueryId?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type CustomQueryIdAssetCategoriesBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdAssetCategories ${args} {
    assetCategories {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSecurityGroupsAssetCategoriesResultType = {
    assetCategories: {
        findUnassociatedSecurityGroups?: Maybe<ServiceListResultOfSecurityGroup>
    }
}

export type FindUnassociatedSecurityGroupsAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les profils non liés de l'entité. */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSecurityGroupsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSecurityGroupsAssetCategories ${args} {
    assetCategories {
        findUnassociatedSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSecurityGroupsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSecurityGroupsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSecurityGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedAssetCategoriesResultType = {
    assetCategories: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedAssetCategoriesBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedAssetCategories ${args} {
    assetCategories {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindConsumableCategoryAssetCategoriesResultType = {
    assetCategories: {
        findConsumableCategory?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type FindConsumableCategoryAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindConsumableCategoryAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findConsumableCategoryAssetCategories ${args} {
    assetCategories {
        findConsumableCategory(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindConsumableCategoryAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindConsumableCategoryAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssetCategoriesAssetCategoriesResultType = {
    assetCategories: {
        findAssetCategories?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type FindAssetCategoriesAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssetCategoriesAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssetCategoriesAssetCategories ${args} {
    assetCategories {
        findAssetCategories(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssetCategoriesAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssetCategoriesAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNamingConventionsAssetCategoriesResultType = {
    assetCategories: {
        findNamingConventions?: Maybe<ServiceListResultOfNamingConvention>
    }
}

export type FindNamingConventionsAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfNamingConvention>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNamingConvention>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNamingConventionsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfNamingConvention = null, $options: QueryContextOfNamingConvention = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNamingConventionsAssetCategories ${args} {
    assetCategories {
        findNamingConventions(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNamingConventionsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNamingConventionsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNamingConvention", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindWithoutSecurityGroupAssetCategoriesResultType = {
    assetCategories: {
        findWithoutSecurityGroup?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type FindWithoutSecurityGroupAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindWithoutSecurityGroupAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findWithoutSecurityGroupAssetCategories ${args} {
    assetCategories {
        findWithoutSecurityGroup(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindWithoutSecurityGroupAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindWithoutSecurityGroupAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ParentKeyAssetCategoriesResultType = {
    assetCategories: {
        parentKey?: Maybe<ServiceSingleResultOfString>
    }
}

export type ParentKeyAssetCategoriesBaseVariables = {
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ParentKeyAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query parentKeyAssetCategories ${args} {
    assetCategories {
        parentKey(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ParentKeyAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ParentKeyAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistAssetCategoriesResultType = {
    assetCategories: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistAssetCategoriesBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existAssetCategories ${args} {
    assetCategories {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindWithOrphelinAssetCategoriesResultType = {
    assetCategories: {
        findWithOrphelin?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type FindWithOrphelinAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/** Récupère toutes les entités avec noeud orphelin. */
@Injectable({providedIn: 'root'})
export class FindWithOrphelinAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findWithOrphelinAssetCategories ${args} {
    assetCategories {
        findWithOrphelin(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindWithOrphelinAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindWithOrphelinAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type HasBlockedAssetCategoriesResultType = {
    assetCategories: {
        hasBlocked?: Maybe<ServiceSingleResultOfProcessActionEntity>
    }
}

export type HasBlockedAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfProcessActionEntity>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfProcessActionEntity>, /** Les options de requêtage à appliquer. */
}

/** Obtient si le type est actuellement en process. */
@Injectable({providedIn: 'root'})
export class HasBlockedAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfProcessActionEntity = null, $options: QueryContextOfProcessActionEntity = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query hasBlockedAssetCategories ${args} {
    assetCategories {
        hasBlocked(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: HasBlockedAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<HasBlockedAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfProcessActionEntity", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesAssetCategoriesResultType = {
    assetCategories: {
        findRecycles?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type FindRecyclesAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesAssetCategories ${args} {
    assetCategories {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesAssetCategoriesResultType = {
    assetCategories: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesAssetCategoriesBaseVariables = {
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesAssetCategories ${args} {
    assetCategories {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedChildrenAssetCategoriesResultType = {
    assetCategories: {
        findUnassociatedChildren?: Maybe<ServiceListResultOfAssetCategory>
    }
}

export type FindUnassociatedChildrenAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAssetCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedChildrenAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAssetCategory = null, $options: QueryContextOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedChildrenAssetCategories ${args} {
    assetCategories {
        findUnassociatedChildren(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedChildrenAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedChildrenAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAssetsAssetCategoriesResultType = {
    assetCategories: {
        findUnassociatedAssets?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindUnassociatedAssetsAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAssetsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAssetsAssetCategories ${args} {
    assetCategories {
        findUnassociatedAssets(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAssetsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAssetsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAssetModelsAssetCategoriesResultType = {
    assetCategories: {
        findUnassociatedAssetModels?: Maybe<ServiceListResultOfAssetModel>
    }
}

export type FindUnassociatedAssetModelsAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAssetModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAssetModelsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAssetModel = null, $options: QueryContextOfAssetModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAssetModelsAssetCategories ${args} {
    assetCategories {
        findUnassociatedAssetModels(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAssetModelsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAssetModelsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAssetCategoryRulesAssetCategoriesResultType = {
    assetCategories: {
        findUnassociatedAssetCategoryRules?: Maybe<ServiceListResultOfAssetCategoryRule>
    }
}

export type FindUnassociatedAssetCategoryRulesAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAssetCategoryRule>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetCategoryRule>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAssetCategoryRulesAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAssetCategoryRule = null, $options: QueryContextOfAssetCategoryRule = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAssetCategoryRulesAssetCategories ${args} {
    assetCategories {
        findUnassociatedAssetCategoryRules(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAssetCategoryRulesAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAssetCategoryRulesAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetCategoryRule", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSnmpMapInformationsAssetCategoriesResultType = {
    assetCategories: {
        findUnassociatedSnmpMapInformations?: Maybe<ServiceListResultOfSnmpMapInformation>
    }
}

export type FindUnassociatedSnmpMapInformationsAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSnmpMapInformation>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSnmpMapInformation>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSnmpMapInformationsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSnmpMapInformation = null, $options: QueryContextOfSnmpMapInformation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSnmpMapInformationsAssetCategories ${args} {
    assetCategories {
        findUnassociatedSnmpMapInformations(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSnmpMapInformationsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSnmpMapInformationsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSnmpMapInformation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSnmpMapInformationKbsAssetCategoriesResultType = {
    assetCategories: {
        findUnassociatedSnmpMapInformationKbs?: Maybe<ServiceListResultOfSnmpMapInformationKb>
    }
}

export type FindUnassociatedSnmpMapInformationKbsAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSnmpMapInformationKb>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSnmpMapInformationKb>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSnmpMapInformationKbsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSnmpMapInformationKb = null, $options: QueryContextOfSnmpMapInformationKb = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSnmpMapInformationKbsAssetCategories ${args} {
    assetCategories {
        findUnassociatedSnmpMapInformationKbs(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSnmpMapInformationKbsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSnmpMapInformationKbsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSnmpMapInformationKb", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertAssetCategoriesResultType = {
    assetCategories: {
        insert?: Maybe<ServiceSingleResultOfAssetCategory>
    }
}

export type InsertAssetCategoriesBaseVariables = {
	entity: AssetCategoryInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: AssetCategoryInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertAssetCategories ${args} {
    assetCategories {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateAssetCategoriesResultType = {
    assetCategories: {
        update?: Maybe<ServiceSingleResultOfAssetCategory>
    }
}

export type UpdateAssetCategoriesBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfAssetCategory>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateAssetCategories ${args} {
    assetCategories {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAssetCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataAssetCategoriesResultType = {
    assetCategories: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataAssetCategoriesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataAssetCategories ${args} {
    assetCategories {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyAssetCategoriesResultType = {
    assetCategories: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyAssetCategoriesBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfAssetCategory>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyAssetCategories ${args} {
    assetCategories {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteAssetCategoriesResultType = {
    assetCategories: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteAssetCategoriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteAssetCategories ${args} {
    assetCategories {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DragAssetCategoriesResultType = {
    assetCategories: {
        drag?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DragAssetCategoriesBaseVariables = {
	fromId: string, /** L'identifiant à ordonner. */
	toId: Maybe<string>, /** L'identifiant de l'élément vers qui l'ordonner. */
}

/** Permet d'ordonner l'entité. */
@Injectable({providedIn: 'root'})
export class DragAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fromId: Uuid!, $toId: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation dragAssetCategories ${args} {
    assetCategories {
        drag(fromId: $fromId, toId: $toId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DragAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DragAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DragIdsAssetCategoriesResultType = {
    assetCategories: {
        dragIds?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DragIdsAssetCategoriesBaseVariables = {
	fromIds: Array<string>, /** Les identifiants à ordonner. */
	toId: Maybe<string>, /** L'identifiant de l'élément vers qui l'ordonner. */
}

/** Permet d'ordonner l'entité. */
@Injectable({providedIn: 'root'})
export class DragIdsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fromIds: [Uuid!], $toId: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation dragIdsAssetCategories ${args} {
    assetCategories {
        dragIds(fromIds: $fromIds, toId: $toId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DragIdsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DragIdsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupAssetCategoriesResultType = {
    assetCategories: {
        addSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupAssetCategoriesBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupAssetCategories ${args} {
    assetCategories {
        addSecurityGroup(id: $id, securityGroupIds: $securityGroupIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupAssetCategoriesResultType = {
    assetCategories: {
        removeSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupAssetCategoriesBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupAssetCategories ${args} {
    assetCategories {
        removeSecurityGroup(id: $id, securityGroupIds: $securityGroupIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyLifetimeAssetCategoriesResultType = {
    assetCategories: {
        updateManyLifetime?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyLifetimeAssetCategoriesBaseVariables = {
	assetCategoryIds: Array<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfAssetCategory>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateManyLifetimeAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetCategoryIds: [Uuid!], $entry: FieldUpdateOperatorOfAssetCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyLifetimeAssetCategories ${args} {
    assetCategories {
        updateManyLifetime(assetCategoryIds: $assetCategoryIds, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyLifetimeAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyLifetimeAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecalculateLifetimeAllAssetCategoriesResultType = {
    assetCategories: {
        recalculateLifetimeAll?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecalculateLifetimeAllAssetCategoriesBaseVariables = {
	assetCategoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RecalculateLifetimeAllAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetCategoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recalculateLifetimeAllAssetCategories ${args} {
    assetCategories {
        recalculateLifetimeAll(assetCategoryIds: $assetCategoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecalculateLifetimeAllAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecalculateLifetimeAllAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecalculateLifetimeEmptyAssetCategoriesResultType = {
    assetCategories: {
        recalculateLifetimeEmpty?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecalculateLifetimeEmptyAssetCategoriesBaseVariables = {
	assetCategoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RecalculateLifetimeEmptyAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetCategoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recalculateLifetimeEmptyAssetCategories ${args} {
    assetCategories {
        recalculateLifetimeEmpty(assetCategoryIds: $assetCategoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecalculateLifetimeEmptyAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecalculateLifetimeEmptyAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreAssetCategoriesResultType = {
    assetCategories: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreAssetCategoriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreAssetCategories ${args} {
    assetCategories {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleAssetCategoriesResultType = {
    assetCategories: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleAssetCategoriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleAssetCategories ${args} {
    assetCategories {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedAssetCategoriesResultType = {
    assetCategories: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedAssetCategoriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedAssetCategories ${args} {
    assetCategories {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedAssetCategoriesResultType = {
    assetCategories: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedAssetCategoriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedAssetCategories ${args} {
    assetCategories {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldAssetCategoriesResultType = {
    assetCategories: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldAssetCategories ${args} {
    assetCategories {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldAssetCategoriesResultType = {
    assetCategories: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldAssetCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldAssetCategories ${args} {
    assetCategories {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddChildrenAssetCategoriesResultType = {
    assetCategories: {
        addChildren?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddChildrenAssetCategoriesBaseVariables = {
	id: string, /**  */
	assetCategoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddChildrenAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetCategoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addChildrenAssetCategories ${args} {
    assetCategories {
        addChildren(id: $id, assetCategoryIds: $assetCategoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddChildrenAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddChildrenAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveChildrenAssetCategoriesResultType = {
    assetCategories: {
        removeChildren?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveChildrenAssetCategoriesBaseVariables = {
	assetCategoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveChildrenAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetCategoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeChildrenAssetCategories ${args} {
    assetCategories {
        removeChildren(assetCategoryIds: $assetCategoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveChildrenAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveChildrenAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAssetCategoryRulesAssetCategoriesResultType = {
    assetCategories: {
        addAssetCategoryRules?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAssetCategoryRulesAssetCategoriesBaseVariables = {
	id: string, /**  */
	assetCategoryRuleIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAssetCategoryRulesAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetCategoryRuleIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAssetCategoryRulesAssetCategories ${args} {
    assetCategories {
        addAssetCategoryRules(id: $id, assetCategoryRuleIds: $assetCategoryRuleIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAssetCategoryRulesAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAssetCategoryRulesAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAssetCategoryRulesAssetCategoriesResultType = {
    assetCategories: {
        removeAssetCategoryRules?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAssetCategoryRulesAssetCategoriesBaseVariables = {
	assetCategoryRuleIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAssetCategoryRulesAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetCategoryRuleIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAssetCategoryRulesAssetCategories ${args} {
    assetCategories {
        removeAssetCategoryRules(assetCategoryRuleIds: $assetCategoryRuleIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAssetCategoryRulesAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAssetCategoryRulesAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAssetsAssetCategoriesResultType = {
    assetCategories: {
        addAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAssetsAssetCategoriesBaseVariables = {
	id: string, /**  */
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAssetsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAssetsAssetCategories ${args} {
    assetCategories {
        addAssets(id: $id, assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAssetsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAssetsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAssetsAssetCategoriesResultType = {
    assetCategories: {
        removeAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAssetsAssetCategoriesBaseVariables = {
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAssetsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAssetsAssetCategories ${args} {
    assetCategories {
        removeAssets(assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAssetsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAssetsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAssetModelsAssetCategoriesResultType = {
    assetCategories: {
        addAssetModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAssetModelsAssetCategoriesBaseVariables = {
	id: string, /**  */
	assetModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAssetModelsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAssetModelsAssetCategories ${args} {
    assetCategories {
        addAssetModels(id: $id, assetModelIds: $assetModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAssetModelsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAssetModelsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAssetModelsAssetCategoriesResultType = {
    assetCategories: {
        removeAssetModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAssetModelsAssetCategoriesBaseVariables = {
	assetModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAssetModelsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAssetModelsAssetCategories ${args} {
    assetCategories {
        removeAssetModels(assetModelIds: $assetModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAssetModelsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAssetModelsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSnmpMapInformationsAssetCategoriesResultType = {
    assetCategories: {
        addSnmpMapInformations?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSnmpMapInformationsAssetCategoriesBaseVariables = {
	id: string, /**  */
	snmpMapInformationIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSnmpMapInformationsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $snmpMapInformationIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSnmpMapInformationsAssetCategories ${args} {
    assetCategories {
        addSnmpMapInformations(id: $id, snmpMapInformationIds: $snmpMapInformationIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSnmpMapInformationsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSnmpMapInformationsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSnmpMapInformationsAssetCategoriesResultType = {
    assetCategories: {
        removeSnmpMapInformations?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSnmpMapInformationsAssetCategoriesBaseVariables = {
	snmpMapInformationIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSnmpMapInformationsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$snmpMapInformationIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSnmpMapInformationsAssetCategories ${args} {
    assetCategories {
        removeSnmpMapInformations(snmpMapInformationIds: $snmpMapInformationIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSnmpMapInformationsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSnmpMapInformationsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSnmpMapInformationKbsAssetCategoriesResultType = {
    assetCategories: {
        addSnmpMapInformationKbs?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSnmpMapInformationKbsAssetCategoriesBaseVariables = {
	id: string, /**  */
	snmpMapInformationKbIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSnmpMapInformationKbsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $snmpMapInformationKbIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSnmpMapInformationKbsAssetCategories ${args} {
    assetCategories {
        addSnmpMapInformationKbs(id: $id, snmpMapInformationKbIds: $snmpMapInformationKbIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSnmpMapInformationKbsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSnmpMapInformationKbsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSnmpMapInformationKbsAssetCategoriesResultType = {
    assetCategories: {
        removeSnmpMapInformationKbs?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSnmpMapInformationKbsAssetCategoriesBaseVariables = {
	snmpMapInformationKbIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSnmpMapInformationKbsAssetCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$snmpMapInformationKbIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSnmpMapInformationKbsAssetCategories ${args} {
    assetCategories {
        removeSnmpMapInformationKbs(snmpMapInformationKbIds: $snmpMapInformationKbIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSnmpMapInformationKbsAssetCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSnmpMapInformationKbsAssetCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
