import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  GC,
  GCFactory,
  IAppointmentType,
  ServicePermission,
} from '@clarilog/core';
import { CorePolicyValidator } from '@clarilog/core/services2';
import { ValidationError } from '@clarilog/core/services2/graphql/generated-types/types';
import { Column } from '@clarilog/shared2/models';
import { TranslateService } from '@clarilog/shared2/services';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import Calendar from 'devextreme/ui/calendar';
import Globalize from 'globalize/dist/globalize';
import { ItemsValue } from '../form/work-form/form-link-array';
import { TranslatedFieldHelperService } from '../translate-field';

import SelectBox from 'devextreme/ui/select_box';
import { ActivatedRoute } from '@angular/router';
import { FormGroupHelpers } from '../form/work-form';
import { CoreSubFormLinkListComponent } from '../list/sub-form-link-list/sub-form-link-list.component';
import { first } from 'rxjs';
const appointmentClassName = '.dx-scheduler-appointment';

/** Représente la classe du composent cl-scheduler. */
@Component({
  selector: 'clc-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreSchedulerComponent),
      multi: true,
    },
  ],
})
export class CoreSchedulerComponent implements ControlValueAccessor, OnInit {
  /** Sauvegarde les valeurs. */
  _values: ItemsValue;
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  /** Garbage */
  _gc: GC;

  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;

  /** Obtient ou définit la source de données. */
  @Input() source: ModelDataSourceContext;

  /** Obtient ou définit l'affichage par défaut. */
  @Input() currentView: string;
  /** Obtient ou définit l'affichage par défaut. */
  @Input() fieldExpr: string = 'scanConfigurationIds';
  /** Obtient ou définit l'affichage de toute la journée est visible. */
  @Input() showAllDayPanel: boolean;
  /** Obtient ou définit les vues disponibles. */
  @Input() views: string[];
  /** Obtient ou définit l'heure de début. */
  @Input() startDayHour: number;
  /** Obtient ou définit l'heure de fin. */
  @Input() endDayHour: number;
  /** Obtient ou définit si l'ajout est autorisé. */
  @Input() allowAdding: boolean;
  /** Obtient ou définit si la suppression est autorisé. */
  @Input() allowDeleting: boolean;
  /** Obtient ou définit si la modification est autorisé. */
  @Input() allowUpdating: boolean;
  /** Obtient ou définit la duration par défaut d'un nouvelle élément. */
  @Input() defaultDuration: ('endOfDay' | 'oneDay') | number;
  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() readOnly: boolean = false;
  /** Obtient ou définit le source de la ressource. */
  @Input() sourceRessource: ModelDataSourceContext;
  /** Obtient ou définit le source de la ressource. */
  @Input() insertRessource: ModelFnContext;
  /** Obtient ou définit le source de la ressource. */
  @Input() updateRessource: ModelFnContext;
  /** Obtient ou définit les données que l'on souhaite récupérer. */
  @Input() fields: ModelFnContext;
  /*** Obtient la méthode de mise à jour d'une ressource scheduler */
  @Input() removeItems: ModelFnContext;
  /** Obtient ou définit si la paginatin (par date) est coté serveur. */
  @Input() remote: boolean = false;
  /** Obtient ou définit le model en cours. */
  @Input() model: ModelState;
  /** Obtient ou définit  si l'id est vérifie. */
  @Input() checkId: boolean = true;
  /** Obtient ou définit si le composant doit se rafraichir */
  @Input() autoRefresh: boolean = false;
  /** Obtient ou définit l'affichage par défaut. */
  _selectTitle: string = 'entities/scanConfiguration/_title/plural';

  /** Obtient ou définit l'item à ne pas afficher dans le champ recurrence pour les planifications. */
  @Input() removeRecurrenceItem: string[];

  /** Obtient ou définit la source de données. */
  currentSource: IAppointmentType[] = [];
  currentSourceRemote;
  popupVisible: boolean = false;
  schedulerSource: CustomStore;

  /** Vérifie si l'on est déjà passé dans le onContenteReady */
  added = false;
  /** Indique que le composant a bien été initialisé */
  isInitialize = false;

  /** instancie le composant */
  component: any;

  /** Indique si un appoiment a été cliqué */
  isAppointmentClick: boolean = false;

  /** Affiche le wait */
  loadWait: boolean = false;
  schedulerAuditsColumns: Column[] = [
    {
      label: TranslateService.get('entities/taskSequence/name'),
      field: 'name' + this.translateFieldHelperService.getTranslateKey(),
    },
  ];
  target: string = appointmentClassName;
  dataSourceMenu: any[] = [];
  onContextMenuItemClick: any;
  appointmentContextMenuItemsScan: any[];
  /** Évènement d'erreur sur la liste. */
  @Output() onError: EventEmitter<ValidationError[]> = new EventEmitter<
    ValidationError[]
  >();
  /** Obtient ou définit les valeurs. */
  get values(): ItemsValue {
    return this._values;
  }

  set values(values: ItemsValue) {
    this._values = values;
    this.onChange(this._values);
    this.onTouched();
  }

  get selectTitle(): string {
    return this._selectTitle;
  }

  @Input()
  set selectTitle(selectTitle: string) {
    this._selectTitle =
      selectTitle ?? 'entities/scanConfiguration/_title/plural';
  }

  observer;

  /** Définit l'injector */
  injector: Injector;

  /**
   * Identifiant de l'audit actuelle
   */
  currentAuditId: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _gcFactory: GCFactory,
    private translateFieldHelperService: TranslatedFieldHelperService,
    private servicePermission: ServicePermission,
    injector: Injector,
    private policyValidator: CorePolicyValidator,
    private el: ElementRef,
    private zone: NgZone,
    private route: ActivatedRoute,
  ) {
    this.injector = injector;
    this._gc = _gcFactory.create();
    this.schedulerAuditsColumns = [
      {
        label: TranslateService.get('entities/alarm/name'),
        field: 'name' + this.translateFieldHelperService.getTranslateKey(),
      },
    ];

    this.observer = new ResizeObserver((entries) => {
      this.zone.run(() => {
        this.saveBeforePlanning();
        if (this.component != undefined) {
          // force le repaint
          this.component.repaint();
        }
      });
    });

    this.observer.observe(this.el.nativeElement);
  }

  /** Destruction du composant */
  ngOnDestroy() {
    this._gc.dispose();
    if (this.observer != undefined) {
      this.observer.unobserve(this.el.nativeElement);
    }
  }

  /** @inheritdoc */
  writeValue(values: any): void {
    this.values = values;

    let hasBeenReset = false;
    if (values.itemsRemoved.length === 0) {
      hasBeenReset = true;
      this._values.itemsRemoved.clear();
    }
    if (values.itemsAdded.length === 0) {
      hasBeenReset = true;
      this._values.itemsAdded.clear();
    }
    if (values.itemsUpdated.length === 0) {
      hasBeenReset = true;
      this._values.itemsUpdated.clear();
    }

    if (hasBeenReset === true && this.isInitialize) {
      this.refresh();
    }
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** @inheritdoc */
  ngOnInit() {
    this._gc.forDispose(
      this.model.on.subscribe((data) => {
        if (data.eventName === 'tab-change') {
          // Refresh auto si définit
          if (this.autoRefresh) {
            if (
              data?.eventData?.newTab?.item?.control?.type ==
              'SchedulerComponent'
            ) {
              this.refresh();
            }
          }
        }
      }),
    );

    this.route.params.subscribe((params) => {
      this.currentAuditId = params['id'];
    });

    // TODO check
    let service = this.injector.get(this.source.serviceName);
    let policy = this.servicePermission.getAuthorizations(service);
    this.allowAdding = this.policyValidator.validate(policy + '.update');
  }

  async remoteTest() {
    this.changeDetectorRef.detectChanges();
    let endDate = this.component.getEndViewDate();
    let startDate = this.component.getStartViewDate();
    this.source.context.params.set('start', () => startDate);
    this.source.context.params.set('end', () => endDate);
    let dataSource = this.source.datasource;
    let result = await dataSource.load();
    this.changeDetectorRef.detectChanges();

    return result;
  }
  /** Rafraîchissement du composant */
  async refresh(init: boolean = false) {
    var canLoad = true;
    if (this.checkId == true || this.checkId == undefined) {
      canLoad =
        this.source.rootState.sharedContext.params.get('id') != undefined;
    } else if (this.checkId === false) {
      canLoad = true;
    }

    if (canLoad) {
      if (this.remote) {
        this.loadWait = true;

        this.currentSourceRemote = new DataSource({
          store: new CustomStore({
            load: (options) => this.remoteTest(),
          }),
        });
        this.loadWait = false;
        this.changeDetectorRef.detectChanges();
      } else {
        let dataSource = this.source.datasource;
        let result = await dataSource.load();

        // Dans le cas où le sourceRessource est renseigné
        if (this.sourceRessource != undefined) {
          // Chargement de la source de données supplémentaire
          this.sourceRessource.datasource.load();
        }
        this.currentSource = result;
      }
    } else {
      this.currentSource = [];
    }
  }

  /** Chanegement d'option */
  async onOptionChanged(e) {
    if (e.fullName == 'currentDate' || e.fullName == 'currentView') {
      if (this.remote && this.isInitialize) {
        await this.refresh();
      }
    }
  }

  /** Pendant l'ajout d'un rendez-vous */
  onAppointmentAdding(e) {
    delete e.appointmentData['undefined'];
  }

  /** Ajout d'un rendez vous */
  onAppointmentAdded(e) {
    // Calcul du dernier élements
    let items = e.component.option('dataSource');
    let lastItem = items[items.length - 1];
    lastItem['id'] = items.filter((f) => f.id == undefined).length + 1;

    this._values.itemsAdded.push(lastItem);
    this.onChange(this._values);
  }

  /** Suppression d'un rendez vous */
  onAppointmentDeleting(e) {
    if (e.appointmentData.id != undefined) {
      // Recherche si existe dans les ajout
      let added = this._values.itemsAdded.findIndex(
        (f) => f.id == e.appointmentData.id,
      );
      if (added != -1) {
        this._values.itemsAdded.splice(added, 1);
      } else {
        // Ajoute la clé dans les items supprimés (uniquement si pas nouveau)
        this._values.itemsRemoved.push(e.appointmentData.id);
      }

      // Recherche si existe dans les modifié
      let updated = this._values.itemsUpdated.findIndex(
        (f) => f.id == e.appointmentData.id,
      );
      if (updated != -1) {
        this._values.itemsUpdated.splice(updated, 1);
      }

      this.onChange(this._values);
    }
  }

  /** Pendant l'update d'un rendez-vous */
  onAppointmentUpdating(e) {
    delete e.newData['undefined'];
  }

  /** Modification d'un rendez vous */
  onAppointmentUpdated(e) {
    let value = e.appointmentData;

    let added = this._values.itemsAdded.findIndex((f) => f.id == value.id);
    if (added == -1) {
      // Modification
      let updated = this._values.itemsUpdated.findIndex(
        (f) => f.id == value.id,
      );
      if (updated != -1) {
        this._values.itemsUpdated.splice(updated, 1);
      }
      delete value['__typename'];
      this._values.itemsUpdated.push({
        fields: { set: { ...value } },
        id: value.id,
      });
    }
    this.onChange(this._values);
  }

  /**
   * Récupère le nom de l'audit actuel
   * @returns nom de l'audit
   */
  defaultTitle = (): string => {
    let name = FormGroupHelpers.formControlByName(this.model.form, 'name');
    return name?.value == undefined ? '' : name.value;
  };

  /**
   * Permet de mettre une valeur par défaut
   * pour le champ de type 'text"
   * @param formItems liste d'item du formulaire
   */
  setDefaultValueTypeText = (formItems): void => {
    formItems.items.forEach((item) => {
      if (item.dataField == 'text') {
        item.editorOptions = {
          value: this.defaultTitle(),
        };
      }
    });
  };

  /**
   * Check si le formulaire est enregistré
   * avant de faire une planification
   */
  saveBeforePlanning = async () => {
    const isDirty$ = this.model.formComponent.workItemForm?.isDirty$;

    if(isDirty$){
      isDirty$.pipe(first()).subscribe(async (res) => {
        if (res === true) {
          let confirm = await CoreSubFormLinkListComponent.confirmSaveDialog(
            TranslateService.get('confirm-title'),
            TranslateService.get('globals/confirm-refresh'),
            TranslateService.get('save'),
            TranslateService.get('cancel'),
          );
          if (confirm) {
            this.model.formComponent.save({ close: false });
          }
        }
      });
    }
  };

  /** Permet de gérer la durée par défaut d'un rendez-vous à la création */
  onAppointmentFormOpening(e) {
    let form = e.form;
    let formItems = form.option('items');

    let auditItemExists = formItems[0].items.filter(
      (x) => x.dataField === 'scanConfigurationIds',
    );

    if (this.sourceRessource != undefined && auditItemExists.length == 0) {
      formItems[0].items.push({
        dataField: 'scanConfigurationIds',
        label: {
          text: TranslateService.get(
            this._selectTitle ?? 'entities/scanConfiguration/_title/plural',
          ),
        },
        editorType: 'dxTagBox',
        editorOptions: {
          dataSource: this.sourceRessource.datasource,
          valueExpr: 'id',
          value: [this.model.formComponent.id],
          fieldExpr: this.fieldExpr,
          displayExpr: 'name',
          searchMode: 'contains',
          minSearchLength: 0,
          searchEnabled: true,
          showSelectionControls: true,
          multiline: true,
        },
      });
    }

    formItems[0].items.forEach((item) => {
      if (
        item.dataField == 'scanConfigurationIds' ||
        item.dataField == 'text'
      ) {
        item.validationRules = [
          {
            type: 'required',
            message: TranslateService.get(`errors/required`),
          },
        ];
      }
    });

    let recurrenceItems = this.removeRecurrenceItem;

    let recurrenceChange = () => {
      if (recurrenceItems !== undefined) {
        setTimeout(() => {
          let repeatAppointment = form.getEditor('repeat').option('value');
          if (repeatAppointment) {
            let selectDOM = form
              .element()
              .querySelector('.dx-selectbox.dx-recurrence-selectbox-freq');

            // DxSelectBoxModule
            let selectBox = SelectBox.getInstance(selectDOM);
            let selectItems: any = selectBox.option('items');

            // prevent doing this multiple times
            if (selectItems.length === 5) {
              selectItems = selectItems.filter(
                (item) => !recurrenceItems.includes(item.value),
              );
              selectBox.option('items', selectItems);
            }
          }
        });
      }
    };

    recurrenceChange();

    form.option('onFieldDataChanged', function (args) {
      if (args.dataField === 'repeat' && args.value) {
        // Reforce au chargement du type de recurrence
        recurrenceChange();
      }
    });

    if (e.appointmentData.id == undefined) {
      let date = new Date(e.appointmentData.endDate);

      if (this.defaultDuration == 'endOfDay') {
        date = new Date(e.appointmentData.startDate);
        date = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          23,
          59,
          59,
        );
      } else if (this.defaultDuration == 'oneDay') {
        date = new Date(e.appointmentData.startDate);
        date = new Date(date.getTime() + 24 * 60 * 60000);
      } else {
        date = new Date(e.appointmentData.startDate);
        date = new Date(date.getTime() + this.defaultDuration * 60000);
      }
      form.itemOption('endDate', {
        editorOptions: {
          value: date,
          type: 'datetime',
        },
      });
    } else {
      form.itemOption('endDate', {
        editorOptions: {
          value: e.appointmentData.endDate,
          type: 'datetime',
        },
      });
    }

    this.setDefaultValueTypeText(formItems[0]);

    form.option({ items: formItems });
  }

  /** Initialisation du composant */
  onContentReady(e) {
    this.component = e.component;

    if (!this.isInitialize) {
      this.refresh(true);
    }

    this.isInitialize = true;
    if (this.added) return;

    var today = new Date();
    // Scroll auto sur l'heure actuelle
    e.component.scrollToTime(today.getHours(), today.getMinutes());

    let element = document.querySelectorAll('.dx-scheduler-navigator');

    element[0].addEventListener('click', (event) => {
      setTimeout(() => {
        let cal = document.querySelectorAll('.dx-scheduler-navigator-calendar');
        let instance = Calendar.getInstance(cal[0]);
        if (instance != undefined) {
          instance.option('showTodayButton', true);
        }
      }, 100);
    });

    this.added = true;
  }

  /** Affiche le formulaire de création */
  add() {
    this.component.showAppointmentPopup(
      {
        startDate: new Date(Date.now()),
        endDate: new Date(Date.now() + 2 * 60 * 60000),
      },
      true,
    );
  }

  /** Gestion du rendu des rendez vous */
  onAppointmentRendered(e) {
    var el = e.appointmentElement;

    el.onmouseenter = (args) => {
      if (!this.isAppointmentClick) {
        e.component.showAppointmentTooltip(
          e.appointmentData,
          e.appointmentElement,
          e.targetedAppointmentData,
        );
      }
    };

    el.onmouseleave = (args) => {
      if (!this.isAppointmentClick) {
        e.component.hideAppointmentTooltip();
      }
    };

    el.onblur = (args) => {
      this.isAppointmentClick = false;
    };

    el.onfocus = (args) => {
      this.isAppointmentClick = true;
    };
  }

  /** Suppression d'un item */
  deleteAppointment(e, model) {
    var app = this.currentSource.filter(
      (s) => (<any>s).id == model.targetedAppointmentData.id,
    );
    if (app.length > 0) {
      this.component.deleteAppointment(app[0]);
      this.component.hideAppointmentTooltip();
    }
    e.event.preventDefault();
    e.event.stopPropagation();
  }

  /** Affichage des date */
  displayDate(e, mode = null) {
    if (e != undefined) {
      var options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return new Date(e).toLocaleDateString(Globalize.locale().locale, options);
    }

    return '';
  }

  /** Affichage des heure */
  displayTime(e) {
    if (e != undefined) {
      var options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(e).toLocaleTimeString(Globalize.locale().locale, options);
    }

    return '';
  }
  /** Affichage du text traduit */
  public displayText(text) {
    return this.translateFieldHelperService.findValueToShow(text);
  }

  onAppointmentContextMenu(e) {
    this.target = appointmentClassName;
    if (e.appointmentData.__typename != 'TicketAppointment') {
      this.dataSourceMenu = this.appointmentContextMenuItemsScan;
    }
  }
}
