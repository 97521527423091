import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import {
  CancelableEventArgs,
  CoreNavMenuItemComponent,
  EventArgs,
  FilterArgs,
} from '../nav-menu-item/nav-menu-item.component';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import {
  DesignerEventAction,
  DesignerEventData,
  DesignerEventKey,
  DesignerEventType,
  FormDesignerPopupComponent,
  TranslateService,
} from '@clarilog/shared2';

/** Représente les arguments de sélection. */
/** TODO A déplacer dans un dossier event-args */
export type SelectedEventArgs<T, U> = EventArgs<T> & { selected: U };
export type SelectingEventArgs<T, U> = CancelableEventArgs<T> & { selected: U };

/** Représente le mode d'affichage. */
export type NavGroupMode = 'compact' | 'normal';

@Component({
  selector: 'clc-nav-group',
  templateUrl: './nav-group.component.html',
  styleUrls: ['./nav-group.component.scss'],
})
export class CoreNavGroupComponent implements AfterContentInit {
  /** Obtient ou définit le mode. */
  @Input() mode: NavGroupMode = 'normal';
  /** Obtient ou définit si le groupe est ouvert ou non. */
  @Input() expanded: boolean = true;
  /** Obtient ou définit la valeur du text affiché. */
  @Input() text: string = undefined;
  /** Obtient ou définit si le menu est visible. */
  @Input() visibled: boolean = true;
  /** Obtient ou définit les éléments du menu. */
  @ContentChildren(CoreNavMenuItemComponent)
  items: QueryList<CoreNavMenuItemComponent>;
  /** Se produit lorsqu'un item du treeview est sélectionné. */
  @Output() onItemSelectionChanged: EventEmitter<FilterArgs> =
    new EventEmitter<FilterArgs>();
  /** Se produit lors de la sélection. */
  @Output() onSelectionChanged: EventEmitter<
    SelectedEventArgs<CoreNavGroupComponent, CoreNavMenuItemComponent>
  > = new EventEmitter<
    SelectedEventArgs<CoreNavGroupComponent, CoreNavMenuItemComponent>
  >();
  /** Obtient ou définit le message d'aide. */
  @Input() hint: string;
  /** USed for designer**/

  @Input() designerMode = false;
  @Input() designerId: string;
  @Input() state: ModelState;

  designerClick(e) {
    if (e === 'NAV_ITEM') {
      this.state.on.emit({
        eventName: DesignerEventKey,
        eventData: new DesignerEventData(
          DesignerEventType.NAV_GROUP,
          DesignerEventAction.ADD_IN,
          this.designerId,
        ),
      });
    } else if (e === 'REMOVE_GROUP') {
      this.state.on.emit({
        eventName: DesignerEventKey,
        eventData: new DesignerEventData(
          this.text == undefined
            ? DesignerEventType.NAV_ITEM
            : DesignerEventType.NAV_GROUP,
          DesignerEventAction.REMOVE,
          this.designerId,
        ),
      });
    }
  }

  getAction = DesignerEventAction;

  formAction(e: any, action: DesignerEventAction) {
    e.preventDefault();
    e.stopPropagation();
    this.state.on.emit({
      eventName: DesignerEventKey,
      eventData: new DesignerEventData(
        DesignerEventType.NAV_GROUP,
        action,
        this.designerId,
      ),
    });

    e.prevent;
  }

  designerDialog($event) {
    if (this.designerMode === true) {
      FormDesignerPopupComponent.actionDialog({
        title: TranslateService.get('entities/formDesigner/popupTitleNavGroup'),
        message:
          '<b>' +
          TranslateService.get('entities/formDesigner/popupDescrNavGroup') +
          ' :</b><br>' +
          this.text,
        hasIn: true,
        hasUp: true,
        hasDown: true,
        hasEdit: true,
        hasRemove: true,
      })
        .show()
        .then((dialogResult) => {
          if (dialogResult.buttonText === 'addIn') {
            this.state.on.emit({
              eventName: DesignerEventKey,
              eventData: new DesignerEventData(
                DesignerEventType.NAV_GROUP,
                DesignerEventAction.ADD_IN,
                this.designerId,
              ),
            });
          } else if (dialogResult.buttonText === 'remove') {
            this.state.on.emit({
              eventName: DesignerEventKey,
              eventData: new DesignerEventData(
                this.text == undefined
                  ? DesignerEventType.NAV_ITEM
                  : DesignerEventType.NAV_GROUP,
                DesignerEventAction.REMOVE,
                this.designerId,
              ),
            });
          } else if (dialogResult.buttonText === 'addDown') {
            this.state.on.emit({
              eventName: DesignerEventKey,
              eventData: new DesignerEventData(
                this.text == undefined
                  ? DesignerEventType.NAV_ITEM
                  : DesignerEventType.NAV_GROUP,
                DesignerEventAction.ADD_DOWN,
                this.designerId,
              ),
            });
          } else if (dialogResult.buttonText === 'addUp') {
            this.state.on.emit({
              eventName: DesignerEventKey,
              eventData: new DesignerEventData(
                this.text == undefined
                  ? DesignerEventType.NAV_ITEM
                  : DesignerEventType.NAV_GROUP,
                DesignerEventAction.ADD_UP,
                this.designerId,
              ),
            });
          } else if (dialogResult.buttonText === 'edit') {
            this.state.on.emit({
              eventName: DesignerEventKey,
              eventData: new DesignerEventData(
                this.text == undefined
                  ? DesignerEventType.NAV_ITEM
                  : DesignerEventType.NAV_GROUP,
                DesignerEventAction.EDIT,
                this.designerId,
              ),
            });
          } else if (dialogResult.buttonText === 'cancel') {
            //nada
          }
        });
      return false;
    }
    return true;
  }

  /** end section**/
  constructor() {}
  /** Propage la sélection. */
  selectionChanged(e: EventArgs<CoreNavMenuItemComponent>) {
    this.onSelectionChanged.emit({
      component: this,
      selected: e.component,
      event: e.event,
    });
  }
  /** Propage la sélection des items. */
  itemSelectionChanged(e: FilterArgs) {
    this.onItemSelectionChanged.emit(e);
  }
  /** @inheritdoc */
  ngAfterContentInit(): void {
    this.items.forEach((item) => {
      if (!this.visibled) {
        item.visibled = false;
      }
      item.onItemSelectionChanged.subscribe((e) => {
        this.itemSelectionChanged(e);
      });
      item.onClick.subscribe((e) => {
        this.selectionChanged(e);
      });
    });
  }
}
