import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetServiceOffersBaseVariables, FirstServiceOffersBaseVariables, CountServiceOffersBaseVariables, FindServiceOffersBaseVariables, SearchServiceOffersBaseVariables, ExportSchemaServiceOffersBaseVariables, ExportDataServiceOffersBaseVariables, CustomQueryServiceOffersBaseVariables, CustomQueryIdServiceOffersBaseVariables, UsedServiceOffersBaseVariables, ExistServiceOffersBaseVariables, FindWithOrphelinServiceOffersBaseVariables, HasBlockedServiceOffersBaseVariables, FindRecyclesServiceOffersBaseVariables, CountRecyclesServiceOffersBaseVariables, FindUnassociatedSuppliersServiceOffersBaseVariables, InsertServiceOffersBaseVariables, UpdateServiceOffersBaseVariables, ImportDataServiceOffersBaseVariables, UpdateManyServiceOffersBaseVariables, DeleteServiceOffersBaseVariables, DragServiceOffersBaseVariables, DragIdsServiceOffersBaseVariables, RestoreServiceOffersBaseVariables, RecycleServiceOffersBaseVariables, RestoreArchivedServiceOffersBaseVariables, ArchivedServiceOffersBaseVariables, AddFileDynamicFieldServiceOffersBaseVariables, RemoveFileDynamicFieldServiceOffersBaseVariables, AddSuppliersServiceOffersBaseVariables, RemoveSuppliersServiceOffersBaseVariables } from '../gqls'
import { GetServiceOffersDocument, FirstServiceOffersDocument, CountServiceOffersDocument, FindServiceOffersDocument, SearchServiceOffersDocument, ExportSchemaServiceOffersDocument, ExportDataServiceOffersDocument, CustomQueryServiceOffersDocument, CustomQueryIdServiceOffersDocument, UsedServiceOffersDocument, ExistServiceOffersDocument, FindWithOrphelinServiceOffersDocument, HasBlockedServiceOffersDocument, FindRecyclesServiceOffersDocument, CountRecyclesServiceOffersDocument, FindUnassociatedSuppliersServiceOffersDocument, InsertServiceOffersDocument, UpdateServiceOffersDocument, ImportDataServiceOffersDocument, UpdateManyServiceOffersDocument, DeleteServiceOffersDocument, DragServiceOffersDocument, DragIdsServiceOffersDocument, RestoreServiceOffersDocument, RecycleServiceOffersDocument, RestoreArchivedServiceOffersDocument, ArchivedServiceOffersDocument, AddFileDynamicFieldServiceOffersDocument, RemoveFileDynamicFieldServiceOffersDocument, AddSuppliersServiceOffersDocument, RemoveSuppliersServiceOffersDocument } from '../gqls'
import { ServiceSingleResultOfServiceOffer, QueryContextOfServiceOffer, FilterOfServiceOffer, ServiceSingleResultOfInt64, ServiceListResultOfServiceOffer, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfProcessActionEntity, QueryContextOfProcessActionEntity, FilterOfProcessActionEntity, QueryContextOfSupplier, FilterOfSupplier, ServiceListResultOfSupplier, ServiceOfferInput, FieldUpdateOperatorOfServiceOffer } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ServiceOfferBaseService {
    
public modelName = 'serviceOffer';
public modelPluralName = 'serviceOffers';

	private getServiceOffersQuery: GetServiceOffersDocument;
	private firstServiceOffersQuery: FirstServiceOffersDocument;
	private countServiceOffersQuery: CountServiceOffersDocument;
	private findServiceOffersQuery: FindServiceOffersDocument;
	private searchServiceOffersQuery: SearchServiceOffersDocument;
	private exportSchemaServiceOffersQuery: ExportSchemaServiceOffersDocument;
	private exportDataServiceOffersQuery: ExportDataServiceOffersDocument;
	private customQueryServiceOffersQuery: CustomQueryServiceOffersDocument;
	private customQueryIdServiceOffersQuery: CustomQueryIdServiceOffersDocument;
	private usedServiceOffersQuery: UsedServiceOffersDocument;
	private existServiceOffersQuery: ExistServiceOffersDocument;
	private findWithOrphelinServiceOffersQuery: FindWithOrphelinServiceOffersDocument;
	private hasBlockedServiceOffersQuery: HasBlockedServiceOffersDocument;
	private findRecyclesServiceOffersQuery: FindRecyclesServiceOffersDocument;
	private countRecyclesServiceOffersQuery: CountRecyclesServiceOffersDocument;
	private findUnassociatedSuppliersServiceOffersQuery: FindUnassociatedSuppliersServiceOffersDocument;
	private insertServiceOffersMutation: InsertServiceOffersDocument;
	private updateServiceOffersMutation: UpdateServiceOffersDocument;
	private importDataServiceOffersMutation: ImportDataServiceOffersDocument;
	private updateManyServiceOffersMutation: UpdateManyServiceOffersDocument;
	private deleteServiceOffersMutation: DeleteServiceOffersDocument;
	private dragServiceOffersMutation: DragServiceOffersDocument;
	private dragIdsServiceOffersMutation: DragIdsServiceOffersDocument;
	private restoreServiceOffersMutation: RestoreServiceOffersDocument;
	private recycleServiceOffersMutation: RecycleServiceOffersDocument;
	private restoreArchivedServiceOffersMutation: RestoreArchivedServiceOffersDocument;
	private archivedServiceOffersMutation: ArchivedServiceOffersDocument;
	private addFileDynamicFieldServiceOffersMutation: AddFileDynamicFieldServiceOffersDocument;
	private removeFileDynamicFieldServiceOffersMutation: RemoveFileDynamicFieldServiceOffersDocument;
	private addSuppliersServiceOffersMutation: AddSuppliersServiceOffersDocument;
	private removeSuppliersServiceOffersMutation: RemoveSuppliersServiceOffersDocument;

	constructor(private injector: Injector) {
		this.getServiceOffersQuery = this.injector.get(GetServiceOffersDocument);
		this.firstServiceOffersQuery = this.injector.get(FirstServiceOffersDocument);
		this.countServiceOffersQuery = this.injector.get(CountServiceOffersDocument);
		this.findServiceOffersQuery = this.injector.get(FindServiceOffersDocument);
		this.searchServiceOffersQuery = this.injector.get(SearchServiceOffersDocument);
		this.exportSchemaServiceOffersQuery = this.injector.get(ExportSchemaServiceOffersDocument);
		this.exportDataServiceOffersQuery = this.injector.get(ExportDataServiceOffersDocument);
		this.customQueryServiceOffersQuery = this.injector.get(CustomQueryServiceOffersDocument);
		this.customQueryIdServiceOffersQuery = this.injector.get(CustomQueryIdServiceOffersDocument);
		this.usedServiceOffersQuery = this.injector.get(UsedServiceOffersDocument);
		this.existServiceOffersQuery = this.injector.get(ExistServiceOffersDocument);
		this.findWithOrphelinServiceOffersQuery = this.injector.get(FindWithOrphelinServiceOffersDocument);
		this.hasBlockedServiceOffersQuery = this.injector.get(HasBlockedServiceOffersDocument);
		this.findRecyclesServiceOffersQuery = this.injector.get(FindRecyclesServiceOffersDocument);
		this.countRecyclesServiceOffersQuery = this.injector.get(CountRecyclesServiceOffersDocument);
		this.findUnassociatedSuppliersServiceOffersQuery = this.injector.get(FindUnassociatedSuppliersServiceOffersDocument);
		this.insertServiceOffersMutation = this.injector.get(InsertServiceOffersDocument);
		this.updateServiceOffersMutation = this.injector.get(UpdateServiceOffersDocument);
		this.importDataServiceOffersMutation = this.injector.get(ImportDataServiceOffersDocument);
		this.updateManyServiceOffersMutation = this.injector.get(UpdateManyServiceOffersDocument);
		this.deleteServiceOffersMutation = this.injector.get(DeleteServiceOffersDocument);
		this.dragServiceOffersMutation = this.injector.get(DragServiceOffersDocument);
		this.dragIdsServiceOffersMutation = this.injector.get(DragIdsServiceOffersDocument);
		this.restoreServiceOffersMutation = this.injector.get(RestoreServiceOffersDocument);
		this.recycleServiceOffersMutation = this.injector.get(RecycleServiceOffersDocument);
		this.restoreArchivedServiceOffersMutation = this.injector.get(RestoreArchivedServiceOffersDocument);
		this.archivedServiceOffersMutation = this.injector.get(ArchivedServiceOffersDocument);
		this.addFileDynamicFieldServiceOffersMutation = this.injector.get(AddFileDynamicFieldServiceOffersDocument);
		this.removeFileDynamicFieldServiceOffersMutation = this.injector.get(RemoveFileDynamicFieldServiceOffersDocument);
		this.addSuppliersServiceOffersMutation = this.injector.get(AddSuppliersServiceOffersDocument);
		this.removeSuppliersServiceOffersMutation = this.injector.get(RemoveSuppliersServiceOffersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfServiceOffer> {
    
    		let variables: GetServiceOffersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.get));
            }
            else{
                let result:ServiceSingleResultOfServiceOffer={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfServiceOffer,
		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfServiceOffer> {
    
    		let variables: FirstServiceOffersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountServiceOffersBaseVariables = {
    			filter: filter
    		}
    				return this.countServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfServiceOffer,
		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceOffer> {
    
    		let variables: FindServiceOffersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfServiceOffer,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceOffer> {
    
    		let variables: SearchServiceOffersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaServiceOffersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataServiceOffersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfServiceOffer,
		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceOffer> {
    
    		let variables: CustomQueryServiceOffersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfServiceOffer,
		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceOffer> {
    
    		let variables: CustomQueryIdServiceOffersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedServiceOffersBaseVariables = {
    			ids: ids
    		}
    				return this.usedServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistServiceOffersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.exist));
    	}

    	/** Récupère toutes les entités avec noeud orphelin. */
    	@InjectArgs
    	public findWithOrphelin(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfServiceOffer,
		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceOffer> {
    
    		let variables: FindWithOrphelinServiceOffersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findWithOrphelinServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.findWithOrphelin));
    	}

    	/** Obtient si le type est actuellement en process. */
    	@InjectArgs
    	public hasBlocked(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfProcessActionEntity,
		@Args('filter?') filter?: FilterOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfProcessActionEntity> {
    
    		let variables: HasBlockedServiceOffersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.hasBlockedServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.hasBlocked));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfServiceOffer,
		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceOffer> {
    
    		let variables: FindRecyclesServiceOffersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesServiceOffersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.countRecycles));
    	}

	@InjectArgs
	public findAssociatedSuppliers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSupplier,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSupplier,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSupplier> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('suppliers', fields, null, [
			GqlSubFieldArg.create('filterOfSuppliers', 'filter'),
			GqlSubFieldArg.create('optionsOfSuppliers', 'options'),
		]),
		])
		extendedVariables['filterOfSuppliers'] = filter;
		extendedVariables['optionsOfSuppliers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.suppliers));
            }
            else{
                let result: ServiceListResultOfSupplier = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedSuppliers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSupplier,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSupplier,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSupplier> {
    
    		let variables: FindUnassociatedSuppliersServiceOffersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSuppliersServiceOffersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.findUnassociatedSuppliers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ServiceOfferInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfServiceOffer> {
    
    		let variables: InsertServiceOffersBaseVariables = {
    			entity: entity
    		}
    				return this.insertServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfServiceOffer> {
    
    		let variables: UpdateServiceOffersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataServiceOffersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfServiceOffer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyServiceOffersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteServiceOffersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public drag(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DragServiceOffersBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.dragServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.drag));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public dragIds(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromIds') fromIds: Array<string>,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DragIdsServiceOffersBaseVariables = {
    			fromIds: fromIds,
			toId: toId
    		}
    				return this.dragIdsServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.dragIds));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreServiceOffersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleServiceOffersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedServiceOffersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedServiceOffersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldServiceOffersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldServiceOffersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addSuppliers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('supplierIds') supplierIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSuppliersServiceOffersBaseVariables = {
    			id: id,
			supplierIds: supplierIds
    		}
    				return this.addSuppliersServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.addSuppliers));
    	}

    	/**  */
    	@InjectArgs
    	public removeSuppliers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('supplierIds') supplierIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSuppliersServiceOffersBaseVariables = {
    			id: id,
			supplierIds: supplierIds
    		}
    				return this.removeSuppliersServiceOffersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.serviceOffers.removeSuppliers));
    	}
    
}