import {
  Component,
  EventEmitter,
  Input,
  input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { CoreDynamicPopUpComponent } from '../../dynamic-popup';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { BrotherLabelCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import brotherModel from './brother-model.json';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import notify from 'devextreme/ui/notify';

/** Représente la classe du composent clc-bpac-popup. */
@Component({
  selector: 'clc-bpac-popup',
  templateUrl: './bpac-popup.component.html',
  styleUrls: ['./bpac-popup.component.scss'],
})
export class BrotherBpacPopupComponent {
  @Input() selectedKeys: string[] = [];

  @Output() visibleChanged = new EventEmitter<boolean>();

  progressVisibe: boolean = false;
  progressValue: number = 0;

  _isVisible: boolean = false;
  @Input()
  set visible(value: boolean) {
    this._isVisible = value;
  }

  get visible(): boolean {
    return this._isVisible;
  }

  @ViewChild(CoreDynamicPopUpComponent, { static: true })
  popupDynamic: CoreDynamicPopUpComponent;
  gc: GC;

  massiveButtonTitle: string = undefined;
  /** Methode de call back */
  printCallBack: any;
  massiveEditModel: any;

  constructor(
    public brotherService: BrotherLabelCoreService,
    private gcFactory: GCFactory,
    private _localStorageService: LocalStorageService,
  ) {
    this.gc = gcFactory.create();
    this.massiveButtonTitle = TranslateService.get('globals/printForm');

    this.printCallBack = (fields, keys, entry) => {};
    this.printCallBack = this.printCallBack.bind(this);
    this.format = this.format.bind(this);
    this.massiveEditModel = JSON.parse(JSON.stringify(brotherModel));

    this.gc.forDispose(
      this._localStorageService.ModelState.on.subscribe((s) => {
        if (
          s.eventName == LocalStorageService.EventConst.BrotherProgressPrint
        ) {
          this.progressVisibe = true;
          this.progressValue = s.eventData.value;

          if (this.progressValue >= this.selectedKeys.length) {
            this.progressVisibe = false;
          }
        }
        if (s.eventName == LocalStorageService.EventConst.BrotherEndPrint) {
          this.progressVisibe = false;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.gc.dispose();
  }

  visibleChange(e) {
    this.visibleChanged.emit(e);
    this._isVisible = e;
  }
  onDynamicFormSave(e) {
    this.printOrPreview(true);
  }

  printOrPreview(print: boolean) {
    // Impression
    let labelId = FormGroupHelpers.formControlByName(
      this.popupDynamic.model.form,
      'labelPrinterId',
    );
    let templateFile = FormGroupHelpers.formControlByName(
      this.popupDynamic.model.form,
      'templateFile',
    );
    if (labelId?.value != undefined && templateFile != undefined) {
      if (print) {
        this.progressVisibe = true;
        this.progressValue = 0;
      }

      this.brotherService
        .get(
          [
            GqlSubField.create('data', [
              GqlField.create('fileId'),
              GqlSubField.create('brotherLabelMappings', [
                GqlField.create('objectName'),
                GqlField.create('entityPropertyName'),
              ]),
            ]),
          ],
          labelId.value,
        )
        .subscribe((result) => {
          // Preview
          templateFile.setValue({
            fileId: result.data.fileId,
            loadField: true,
            selectedKeys: this.selectedKeys,
            brotherLabelMappings: result.data.brotherLabelMappings,
            print: print,
          });
        });
    } else {
      notify(
        TranslateService.get('entities/labelPrinter/noSelectedLabel'),
        'error',
        5000,
      );
    }
  }

  onDynamicFormLoaded(e) {
    let countLabel = FormGroupHelpers.formControlByName(
      this.popupDynamic.model.form,
      'countLabel',
    );
    if (countLabel != undefined) {
      countLabel.setValue(this.selectedKeys.length);
    }

    // Gestion des changements de valeur
    let labelId = FormGroupHelpers.formControlByName(
      this.popupDynamic.model.form,
      'labelPrinterId',
    );
    let templateFile = FormGroupHelpers.formControlByName(
      this.popupDynamic.model.form,
      'templateFile',
    );
    if (labelId != undefined && templateFile != undefined) {
      this.gc.forDispose(
        labelId.valueChanges.subscribe((c) => {
          if (c != undefined) {
            this.printOrPreview(false);
          } else {
            //clear
            templateFile.setValue({
              fileId: undefined,
              loadField: true,
              selectedKeys: this.selectedKeys,
              brotherLabelMappings: undefined,
            });
          }
        }),
      );

      // Recherche da la valeur par défaut
      this.brotherService
        .find(
          [GqlSubField.create('data', [GqlField.create('id')])],
          undefined,
          {
            default: {
              eq: true,
            },
          },
        )
        .subscribe((result) => {
          // Chargement par défaut
          if (result?.data != undefined && result.data.length > 0) {
            labelId.setValue(result.data[0].id);
          }
        });
    }
  }

  /** Affichage de la progression */
  format(ratio) {
    return `${TranslateService.get('globals/progress')}: ${this.progressValue} / ${this.selectedKeys?.length} (${Math.round(ratio * 100)}%)`;
  }

  /** Annulation de l'impression */
  onCancelPrint(e) {
    this._localStorageService.ModelState.on.emit({
      eventName: LocalStorageService.EventConst.BrotherCancelPrint,
      eventData: undefined,
    });
  }
}
