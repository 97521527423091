import { LicenseBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { GqlField, GqlSubField } from '../helpers';
import { Authorize } from '@clarilog/core/services/graphql';

@Injectable({ providedIn: 'root' })
@Authorize('clarilog.administrator')
export class LicenseCoreService extends LicenseBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public static fields(): Array<GqlField | GqlSubField> {
    return [
      GqlSubField.create('data', [
        GqlField.create('clientId'),
        GqlField.create('expireAt'),
        GqlField.create('type'),
        GqlSubField.create('customer', [
          GqlField.create('company'),
          GqlField.create('email'),
          GqlField.create('name'),
        ]),
        GqlSubField.create('features', [
          GqlField.create('assetAuditedNumber'),
          GqlField.create('assetAuditedUnlimited'),
          GqlField.create('assetNumber'),
          GqlField.create('assetUnlimited'),
          GqlField.create('managerNumber'),
          GqlField.create('operatorNumber'),
          GqlField.create('endUserNumber'),
          GqlField.create('featureServiceDesk'),
          GqlField.create('featureServiceDeskExpireAt'),
          GqlField.create('featureAssets'),
          GqlField.create('featureAssetsExpireAt'),
          GqlField.create('featureContracts'),
          GqlField.create('featureContractsExpireAt'),
          GqlField.create('featureFinancials'),
          GqlField.create('featureFinancialsExpireAt'),
          GqlField.create('featureStocks'),
          GqlField.create('featureStocksExpireAt'),
          GqlField.create('featureSuppliers'),
          GqlField.create('featureSuppliersExpireAt'),
          GqlField.create('featureInventories'),
          GqlField.create('featureInventoriesExpireAt'),
          GqlField.create('featureLoans'),
          GqlField.create('featureLoansExpireAt'),
          GqlField.create('featureSoftware'),
          GqlField.create('featureSoftwareExpireAt'),
          GqlField.create('mailBoxNumber'),
          GqlField.create('featureTeleDistributions'),
          GqlField.create('featureTeleDistributionsExpireAt'),
          GqlField.create('assetTeleDistributionNumber'),
          GqlField.create('featureMailBoxObserver'),
          GqlField.create('featureMailBoxObserverExpireAt'),
          GqlField.create('inBoundMailBoxObserverNumber'),
          GqlField.create('outBoundMailBoxObserverNumber'),
        ]),
      ]),
    ];
  }
}
