import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetModelManufacturersBaseVariables, FirstModelManufacturersBaseVariables, CountModelManufacturersBaseVariables, FindModelManufacturersBaseVariables, SearchModelManufacturersBaseVariables, ExportSchemaModelManufacturersBaseVariables, ExportDataModelManufacturersBaseVariables, CustomQueryModelManufacturersBaseVariables, CustomQueryIdModelManufacturersBaseVariables, UsedModelManufacturersBaseVariables, FindManufacturerModelManufacturersBaseVariables, FindListManufacturerModelManufacturersBaseVariables, ExistModelManufacturersBaseVariables, FindUnassociatedManufacturersModelManufacturersBaseVariables, FindUnassociatedAssetModelsModelManufacturersBaseVariables, FindUnassociatedAssetManufacturersModelManufacturersBaseVariables, FindUnassociatedAssetModelManufacturersModelManufacturersBaseVariables, FindUnassociatedAssetModelModelsModelManufacturersBaseVariables, InsertModelManufacturersBaseVariables, UpdateModelManufacturersBaseVariables, ImportDataModelManufacturersBaseVariables, UpdateManyModelManufacturersBaseVariables, DeleteModelManufacturersBaseVariables, DragModelManufacturersBaseVariables, DragIdsModelManufacturersBaseVariables, AddAssetModelsModelManufacturersBaseVariables, RemoveAssetModelsModelManufacturersBaseVariables, AddAssetManufacturersModelManufacturersBaseVariables, RemoveAssetManufacturersModelManufacturersBaseVariables, AddAssetModelManufacturersModelManufacturersBaseVariables, RemoveAssetModelManufacturersModelManufacturersBaseVariables, AddAssetModelModelsModelManufacturersBaseVariables, RemoveAssetModelModelsModelManufacturersBaseVariables, AddManufacturersModelManufacturersBaseVariables, RemoveManufacturersModelManufacturersBaseVariables } from '../gqls'
import { GetModelManufacturersDocument, FirstModelManufacturersDocument, CountModelManufacturersDocument, FindModelManufacturersDocument, SearchModelManufacturersDocument, ExportSchemaModelManufacturersDocument, ExportDataModelManufacturersDocument, CustomQueryModelManufacturersDocument, CustomQueryIdModelManufacturersDocument, UsedModelManufacturersDocument, FindManufacturerModelManufacturersDocument, FindListManufacturerModelManufacturersDocument, ExistModelManufacturersDocument, FindUnassociatedManufacturersModelManufacturersDocument, FindUnassociatedAssetModelsModelManufacturersDocument, FindUnassociatedAssetManufacturersModelManufacturersDocument, FindUnassociatedAssetModelManufacturersModelManufacturersDocument, FindUnassociatedAssetModelModelsModelManufacturersDocument, InsertModelManufacturersDocument, UpdateModelManufacturersDocument, ImportDataModelManufacturersDocument, UpdateManyModelManufacturersDocument, DeleteModelManufacturersDocument, DragModelManufacturersDocument, DragIdsModelManufacturersDocument, AddAssetModelsModelManufacturersDocument, RemoveAssetModelsModelManufacturersDocument, AddAssetManufacturersModelManufacturersDocument, RemoveAssetManufacturersModelManufacturersDocument, AddAssetModelManufacturersModelManufacturersDocument, RemoveAssetModelManufacturersModelManufacturersDocument, AddAssetModelModelsModelManufacturersDocument, RemoveAssetModelModelsModelManufacturersDocument, AddManufacturersModelManufacturersDocument, RemoveManufacturersModelManufacturersDocument } from '../gqls'
import { ServiceSingleResultOfModelManufacturer, QueryContextOfModelManufacturer, FilterOfModelManufacturer, ServiceSingleResultOfInt64, ServiceListResultOfModelManufacturer, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfAssetModel, FilterOfAssetModel, ServiceListResultOfAssetModel, ModelManufacturerInput, FieldUpdateOperatorOfModelManufacturer } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ModelManufacturerBaseService {
    
public modelName = 'modelManufacturer';
public modelPluralName = 'modelManufacturers';

	private getModelManufacturersQuery: GetModelManufacturersDocument;
	private firstModelManufacturersQuery: FirstModelManufacturersDocument;
	private countModelManufacturersQuery: CountModelManufacturersDocument;
	private findModelManufacturersQuery: FindModelManufacturersDocument;
	private searchModelManufacturersQuery: SearchModelManufacturersDocument;
	private exportSchemaModelManufacturersQuery: ExportSchemaModelManufacturersDocument;
	private exportDataModelManufacturersQuery: ExportDataModelManufacturersDocument;
	private customQueryModelManufacturersQuery: CustomQueryModelManufacturersDocument;
	private customQueryIdModelManufacturersQuery: CustomQueryIdModelManufacturersDocument;
	private usedModelManufacturersQuery: UsedModelManufacturersDocument;
	private findManufacturerModelManufacturersQuery: FindManufacturerModelManufacturersDocument;
	private findListManufacturerModelManufacturersQuery: FindListManufacturerModelManufacturersDocument;
	private existModelManufacturersQuery: ExistModelManufacturersDocument;
	private findUnassociatedManufacturersModelManufacturersQuery: FindUnassociatedManufacturersModelManufacturersDocument;
	private findUnassociatedAssetModelsModelManufacturersQuery: FindUnassociatedAssetModelsModelManufacturersDocument;
	private findUnassociatedAssetManufacturersModelManufacturersQuery: FindUnassociatedAssetManufacturersModelManufacturersDocument;
	private findUnassociatedAssetModelManufacturersModelManufacturersQuery: FindUnassociatedAssetModelManufacturersModelManufacturersDocument;
	private findUnassociatedAssetModelModelsModelManufacturersQuery: FindUnassociatedAssetModelModelsModelManufacturersDocument;
	private insertModelManufacturersMutation: InsertModelManufacturersDocument;
	private updateModelManufacturersMutation: UpdateModelManufacturersDocument;
	private importDataModelManufacturersMutation: ImportDataModelManufacturersDocument;
	private updateManyModelManufacturersMutation: UpdateManyModelManufacturersDocument;
	private deleteModelManufacturersMutation: DeleteModelManufacturersDocument;
	private dragModelManufacturersMutation: DragModelManufacturersDocument;
	private dragIdsModelManufacturersMutation: DragIdsModelManufacturersDocument;
	private addAssetModelsModelManufacturersMutation: AddAssetModelsModelManufacturersDocument;
	private removeAssetModelsModelManufacturersMutation: RemoveAssetModelsModelManufacturersDocument;
	private addAssetManufacturersModelManufacturersMutation: AddAssetManufacturersModelManufacturersDocument;
	private removeAssetManufacturersModelManufacturersMutation: RemoveAssetManufacturersModelManufacturersDocument;
	private addAssetModelManufacturersModelManufacturersMutation: AddAssetModelManufacturersModelManufacturersDocument;
	private removeAssetModelManufacturersModelManufacturersMutation: RemoveAssetModelManufacturersModelManufacturersDocument;
	private addAssetModelModelsModelManufacturersMutation: AddAssetModelModelsModelManufacturersDocument;
	private removeAssetModelModelsModelManufacturersMutation: RemoveAssetModelModelsModelManufacturersDocument;
	private addManufacturersModelManufacturersMutation: AddManufacturersModelManufacturersDocument;
	private removeManufacturersModelManufacturersMutation: RemoveManufacturersModelManufacturersDocument;

	constructor(private injector: Injector) {
		this.getModelManufacturersQuery = this.injector.get(GetModelManufacturersDocument);
		this.firstModelManufacturersQuery = this.injector.get(FirstModelManufacturersDocument);
		this.countModelManufacturersQuery = this.injector.get(CountModelManufacturersDocument);
		this.findModelManufacturersQuery = this.injector.get(FindModelManufacturersDocument);
		this.searchModelManufacturersQuery = this.injector.get(SearchModelManufacturersDocument);
		this.exportSchemaModelManufacturersQuery = this.injector.get(ExportSchemaModelManufacturersDocument);
		this.exportDataModelManufacturersQuery = this.injector.get(ExportDataModelManufacturersDocument);
		this.customQueryModelManufacturersQuery = this.injector.get(CustomQueryModelManufacturersDocument);
		this.customQueryIdModelManufacturersQuery = this.injector.get(CustomQueryIdModelManufacturersDocument);
		this.usedModelManufacturersQuery = this.injector.get(UsedModelManufacturersDocument);
		this.findManufacturerModelManufacturersQuery = this.injector.get(FindManufacturerModelManufacturersDocument);
		this.findListManufacturerModelManufacturersQuery = this.injector.get(FindListManufacturerModelManufacturersDocument);
		this.existModelManufacturersQuery = this.injector.get(ExistModelManufacturersDocument);
		this.findUnassociatedManufacturersModelManufacturersQuery = this.injector.get(FindUnassociatedManufacturersModelManufacturersDocument);
		this.findUnassociatedAssetModelsModelManufacturersQuery = this.injector.get(FindUnassociatedAssetModelsModelManufacturersDocument);
		this.findUnassociatedAssetManufacturersModelManufacturersQuery = this.injector.get(FindUnassociatedAssetManufacturersModelManufacturersDocument);
		this.findUnassociatedAssetModelManufacturersModelManufacturersQuery = this.injector.get(FindUnassociatedAssetModelManufacturersModelManufacturersDocument);
		this.findUnassociatedAssetModelModelsModelManufacturersQuery = this.injector.get(FindUnassociatedAssetModelModelsModelManufacturersDocument);
		this.insertModelManufacturersMutation = this.injector.get(InsertModelManufacturersDocument);
		this.updateModelManufacturersMutation = this.injector.get(UpdateModelManufacturersDocument);
		this.importDataModelManufacturersMutation = this.injector.get(ImportDataModelManufacturersDocument);
		this.updateManyModelManufacturersMutation = this.injector.get(UpdateManyModelManufacturersDocument);
		this.deleteModelManufacturersMutation = this.injector.get(DeleteModelManufacturersDocument);
		this.dragModelManufacturersMutation = this.injector.get(DragModelManufacturersDocument);
		this.dragIdsModelManufacturersMutation = this.injector.get(DragIdsModelManufacturersDocument);
		this.addAssetModelsModelManufacturersMutation = this.injector.get(AddAssetModelsModelManufacturersDocument);
		this.removeAssetModelsModelManufacturersMutation = this.injector.get(RemoveAssetModelsModelManufacturersDocument);
		this.addAssetManufacturersModelManufacturersMutation = this.injector.get(AddAssetManufacturersModelManufacturersDocument);
		this.removeAssetManufacturersModelManufacturersMutation = this.injector.get(RemoveAssetManufacturersModelManufacturersDocument);
		this.addAssetModelManufacturersModelManufacturersMutation = this.injector.get(AddAssetModelManufacturersModelManufacturersDocument);
		this.removeAssetModelManufacturersModelManufacturersMutation = this.injector.get(RemoveAssetModelManufacturersModelManufacturersDocument);
		this.addAssetModelModelsModelManufacturersMutation = this.injector.get(AddAssetModelModelsModelManufacturersDocument);
		this.removeAssetModelModelsModelManufacturersMutation = this.injector.get(RemoveAssetModelModelsModelManufacturersDocument);
		this.addManufacturersModelManufacturersMutation = this.injector.get(AddManufacturersModelManufacturersDocument);
		this.removeManufacturersModelManufacturersMutation = this.injector.get(RemoveManufacturersModelManufacturersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfModelManufacturer> {
    
    		let variables: GetModelManufacturersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.get));
            }
            else{
                let result:ServiceSingleResultOfModelManufacturer={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfModelManufacturer> {
    
    		let variables: FirstModelManufacturersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountModelManufacturersBaseVariables = {
    			filter: filter
    		}
    				return this.countModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelManufacturer> {
    
    		let variables: FindModelManufacturersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelManufacturer> {
    
    		let variables: SearchModelManufacturersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaModelManufacturersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataModelManufacturersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelManufacturer> {
    
    		let variables: CustomQueryModelManufacturersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelManufacturer> {
    
    		let variables: CustomQueryIdModelManufacturersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedModelManufacturersBaseVariables = {
    			ids: ids
    		}
    				return this.usedModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.used));
    	}

    	/**  */
    	@InjectArgs
    	public findManufacturer(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelManufacturer> {
    
    		let variables: FindManufacturerModelManufacturersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findManufacturerModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.findManufacturer));
    	}

    	/** Obtient la liste des marques et modèles pour le drag and drop */
    	@InjectArgs
    	public findListManufacturer(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelManufacturer> {
    
    		let variables: FindListManufacturerModelManufacturersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findListManufacturerModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.findListManufacturer));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistModelManufacturersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.exist));
    	}

	@InjectArgs
	public findAssociatedManufacturers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfModelManufacturer,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfModelManufacturer> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('manufacturers', fields, null, [
			GqlSubFieldArg.create('filterOfManufacturers', 'filter'),
			GqlSubFieldArg.create('optionsOfManufacturers', 'options'),
		]),
		])
		extendedVariables['filterOfManufacturers'] = filter;
		extendedVariables['optionsOfManufacturers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.manufacturers));
            }
            else{
                let result: ServiceListResultOfModelManufacturer = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelManufacturer,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelManufacturer> {
    
    		let variables: FindUnassociatedManufacturersModelManufacturersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedManufacturersModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.findUnassociatedManufacturers));
    	}

	@InjectArgs
	public findAssociatedAssetModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assetModels', fields, null, [
			GqlSubFieldArg.create('filterOfAssetModels', 'filter'),
			GqlSubFieldArg.create('optionsOfAssetModels', 'options'),
		]),
		])
		extendedVariables['filterOfAssetModels'] = filter;
		extendedVariables['optionsOfAssetModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assetModels));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssetModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetModelsModelManufacturersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetModelsModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.findUnassociatedAssetModels));
    	}

	@InjectArgs
	public findAssociatedAssetManufacturers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assetManufacturers', fields, null, [
			GqlSubFieldArg.create('filterOfAssetManufacturers', 'filter'),
			GqlSubFieldArg.create('optionsOfAssetManufacturers', 'options'),
		]),
		])
		extendedVariables['filterOfAssetManufacturers'] = filter;
		extendedVariables['optionsOfAssetManufacturers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assetManufacturers));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssetManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetManufacturersModelManufacturersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetManufacturersModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.findUnassociatedAssetManufacturers));
    	}

	@InjectArgs
	public findAssociatedAssetModelManufacturers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAssetModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAssetModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assetModelManufacturers', fields, null, [
			GqlSubFieldArg.create('filterOfAssetModelManufacturers', 'filter'),
			GqlSubFieldArg.create('optionsOfAssetModelManufacturers', 'options'),
		]),
		])
		extendedVariables['filterOfAssetModelManufacturers'] = filter;
		extendedVariables['optionsOfAssetModelManufacturers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assetModelManufacturers));
            }
            else{
                let result: ServiceListResultOfAssetModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssetModelManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindUnassociatedAssetModelManufacturersModelManufacturersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetModelManufacturersModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.findUnassociatedAssetModelManufacturers));
    	}

	@InjectArgs
	public findAssociatedAssetModelModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAssetModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAssetModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assetModelModels', fields, null, [
			GqlSubFieldArg.create('filterOfAssetModelModels', 'filter'),
			GqlSubFieldArg.create('optionsOfAssetModelModels', 'options'),
		]),
		])
		extendedVariables['filterOfAssetModelModels'] = filter;
		extendedVariables['optionsOfAssetModelModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assetModelModels));
            }
            else{
                let result: ServiceListResultOfAssetModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssetModelModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindUnassociatedAssetModelModelsModelManufacturersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetModelModelsModelManufacturersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.findUnassociatedAssetModelModels));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ModelManufacturerInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfModelManufacturer> {
    
    		let variables: InsertModelManufacturersBaseVariables = {
    			entity: entity
    		}
    				return this.insertModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfModelManufacturer> {
    
    		let variables: UpdateModelManufacturersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataModelManufacturersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfModelManufacturer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyModelManufacturersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteModelManufacturersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public drag(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DragModelManufacturersBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.dragModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.drag));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public dragIds(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromIds') fromIds: Array<string>,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DragIdsModelManufacturersBaseVariables = {
    			fromIds: fromIds,
			toId: toId
    		}
    				return this.dragIdsModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.dragIds));
    	}

    	/**  */
    	@InjectArgs
    	public addAssetModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetModelsModelManufacturersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetModelsModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.addAssetModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssetModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetModelsModelManufacturersBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetModelsModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.removeAssetModels));
    	}

    	/**  */
    	@InjectArgs
    	public addAssetManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetManufacturersModelManufacturersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetManufacturersModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.addAssetManufacturers));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssetManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetManufacturersModelManufacturersBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetManufacturersModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.removeAssetManufacturers));
    	}

    	/**  */
    	@InjectArgs
    	public addAssetModelManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetModelIds') assetModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetModelManufacturersModelManufacturersBaseVariables = {
    			id: id,
			assetModelIds: assetModelIds
    		}
    				return this.addAssetModelManufacturersModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.addAssetModelManufacturers));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssetModelManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetModelIds') assetModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetModelManufacturersModelManufacturersBaseVariables = {
    			assetModelIds: assetModelIds
    		}
    				return this.removeAssetModelManufacturersModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.removeAssetModelManufacturers));
    	}

    	/**  */
    	@InjectArgs
    	public addAssetModelModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetModelIds') assetModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetModelModelsModelManufacturersBaseVariables = {
    			id: id,
			assetModelIds: assetModelIds
    		}
    				return this.addAssetModelModelsModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.addAssetModelModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssetModelModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetModelIds') assetModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetModelModelsModelManufacturersBaseVariables = {
    			assetModelIds: assetModelIds
    		}
    				return this.removeAssetModelModelsModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.removeAssetModelModels));
    	}

    	/**  */
    	@InjectArgs
    	public addManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('modelManufacturerIds') modelManufacturerIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddManufacturersModelManufacturersBaseVariables = {
    			id: id,
			modelManufacturerIds: modelManufacturerIds
    		}
    				return this.addManufacturersModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.addManufacturers));
    	}

    	/**  */
    	@InjectArgs
    	public removeManufacturers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('modelManufacturerIds') modelManufacturerIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveManufacturersModelManufacturersBaseVariables = {
    			modelManufacturerIds: modelManufacturerIds
    		}
    				return this.removeManufacturersModelManufacturersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelManufacturers.removeManufacturers));
    	}
    
}