import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Args, GC, InjectArgs } from '@clarilog/core';
import {
  AuthorizationCoreService,
  CoreGraphQLSource,
  CriteriaHelpers,
} from '@clarilog/core/services2';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  ChannelCoreService,
  CustomFieldCoreService,
  FormDesignerCoreService,
  IncidentCoreService,
  IncidentModelCoreService,
  ModelRulesDesignerCoreService,
  MyOrganizationCoreService,
  OrganizationCoreService,
  RequestCoreService,
  SatisfactionSettingCoreService,
  TicketCoreService,
  UserCoreService,
  UserPrivilegeCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import {
  FieldUpdateOperatorOfIncident,
  ModelRulesDesigner,
  QueryContextOfTicketStatus,
  SatisfactionSetting,
  ServiceListResultOfDynamicPropertyField,
  ServiceListResultOfTicketStatus,
  ServiceSingleResultOfBoolean,
  ServiceSingleResultOfIncident,
  ServiceSingleResultOfRequest,
  ServiceSingleResultOfStatusWorkflow,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { ModelRuleApplicationService } from '@clarilog/core/services2/model-rule-application/model-rule-application.service';
import interventionModel from '@clarilog/modules2/help-desk-incident/pages/help-desk-incident/intervention.model.json';
import satisfactoryModel from '@clarilog/modules2/help-desk-incident/pages/help-desk-incident/satisfactory.model.json';
import taskModel from '@clarilog/modules2/help-desk-incident/pages/help-desk-incident/task.model.json';
import {
  CoreMassiveEditComponent,
  CoreModelCompilerService,
  CoreWorkFormComponent,
  FormGroupHelpers,
  ModelFieldCompilerService,
  TranslateService,
  TranslatedFieldHelperService,
} from '@clarilog/shared2';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FindDynamicPropertyFieldsIncidentsBaseVariables } from '../../gqls/incident.gqls';

export class HelpDeskTicketVars {
  model: ModelState = undefined;
  useFormDesigner: boolean = false;
  incidentModelId: string;
  rawModel;
  rawEditModel;
  rule: ModelRulesDesigner;
  reminder;
  reminderActivated: boolean = false;
  isReminderVisible: boolean = false;
  isSatisfactionPopupVisible = false;
  massiveEditVisibility: boolean = false;
  disableStatusChange: boolean = false;
  newStatusSet: boolean = false;
  workflowData: ServiceSingleResultOfStatusWorkflow;
  currentStatusId: string;
  statusChangeSource;
  noStatusToChange: boolean = false;
  token = '';
  savedSatisfactionTitle: string;
  satSaveStatusId: any;
  savedSatisfactionSettings: SatisfactionSetting[] = [];
  satValueChangedSub: Subscription;
  newStatusState: boolean = false;
  newStatusValue;
  statusChange;
  modelCompileEndedEvent: EventEmitter<any> = new EventEmitter<any>();
  ownTitle: string = TranslateService.get('entities/incident/_title/singular');
  ownTitleBase: string = TranslateService.get(
    'entities/incident/_title/singular',
  );
  ticketType: 'incident' | 'request';
  initialized: boolean = false;
  intervalCheck;
  checkLockBack: boolean = false;
  backUrl: string;
  addOtherUserModel;
  addUserModel;
  isAddOtherUserVisible: boolean = false;
  allowAddOtherUsers: boolean = false;
  popUpVisibleAddOtherUserVisible: boolean = false;
  isAddOtherUser: boolean = false;
}

@Injectable({
  providedIn: 'root',
})
export class TicketHelperService {
  userPrivilegeAddSatisfaction: boolean;
  route: ActivatedRoute;
  router: Router;
  isReminder: boolean;
  timerReminder: boolean = true;
  tempAddOtherUserIds;

  /** Permet de savoir si on est sur la vue Help Me */
  isViewHelpMe: boolean = false;

  /** Permet de savoir si on est en mode 'mobile' */
  isMobile: boolean = false;

  constructor(
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private incidentService: IncidentCoreService,
    private customFieldCoreService: CustomFieldCoreService,
    private modelRulesDesignerCoreService: ModelRulesDesignerCoreService,
    private modelRulesApplicationService: ModelRuleApplicationService,
    private modelCompilerService: CoreModelCompilerService,
    private authService: AuthorizationCoreService,
    private ticketService: TicketCoreService,
    private localStorageService: LocalStorageService,
    private satisfactionSettingService: SatisfactionSettingCoreService,
    private organizationService: OrganizationCoreService,
    private requestService: RequestCoreService,
    private channelService: ChannelCoreService,
    private userService: UserCoreService,
    private deviceService: DeviceDetectorService,
    private myOrganizationCoreService: MyOrganizationCoreService,
    public formDesignerService: FormDesignerCoreService,
    private ticketModelService: IncidentModelCoreService,
  ) {
    this.isViewHelpMe =
      localStorage.getItem('hasHelpDeskUser') == 'true' ? true : false;

    this.isMobile = this.deviceService.isMobile();
  }

  /**  */
  @InjectArgs
  public findDynamicPropertyFields(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id?') id?: string,
    @Args('entry?') entry?: FieldUpdateOperatorOfIncident,
    @Args('qualification?') qualification?: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfDynamicPropertyField> {
    let variables: FindDynamicPropertyFieldsIncidentsBaseVariables = {
      id: id,
      entry: entry,
    };
    if (qualification != undefined) {
      if (qualification.toLocaleLowerCase() == 'incident') {
        return this.incidentService.findDynamicPropertyFields(
          fields,
          id,
          entry,
          extendedVariables,
        );
      } else if (qualification.toLocaleLowerCase() == 'request') {
        return this.requestService.findDynamicPropertyFields(
          fields,
          id,
          entry,
          extendedVariables,
        );
      }
    }
    console.error(
      '[TicketHelperService.findDynamicPropertyFields] qualication can not be null.',
    );
    return undefined;
  }

  reloadSourceStatus(
    id: string,
    statusFnCallBack: (
      res: ServiceListResultOfTicketStatus,
      context: HelpDeskTicketVars,
    ) => void,
    context: HelpDeskTicketVars,
  ) {
    let datasource = CoreGraphQLSource.create({
      query: (filters, options: QueryContextOfTicketStatus) => {
        let langKey =
          this.translatedFieldHelperService.setColumnTranslateField('name');

        options.sort = CriteriaHelpers.convertSortQuerySortType([
          { selector: langKey, desc: false },
        ]);

        let service: IncidentCoreService | RequestCoreService =
          context.ticketType === 'incident'
            ? this.incidentService
            : this.requestService;

        return service
          .findStatus(
            [
              GqlSubField.create('data', [
                GqlSubField.create(
                  'name',
                  this.translatedFieldHelperService.translatedFields(),
                ),
                GqlField.create('key'),
                GqlField.create('id'),
              ]),
              GqlSubField.create('errors', [GqlField.create('messageError')]),
            ],
            options,
            id,
          )
          .pipe(
            map((data) => {
              statusFnCallBack(data, context);
              return data;
            }),
          );
      },
    });

    datasource.reload();

    context.statusChangeSource = datasource;
  }

  /** Permet d'afficher le formulaire d'ajout ou le formulaire d'édition (help desk) */
  manageModel(context: HelpDeskTicketVars, id: string, gc: GC) {
    let model = context.rawModel;
    if (id != undefined) {
      model = context.rawEditModel as any;
    }

    // Changement des libellés des champs personnalisés
    this.customFieldCoreService
      .find([
        GqlSubField.create('data', [
          GqlSubField.create(
            'name',
            this.translatedFieldHelperService.translatedFields(),
          ),
          GqlField.create('id'),
          GqlField.create('fieldName'),
          GqlField.create('key'),
        ]),
      ])
      .subscribe((res) => {
        // Changement des libellés des champs personnalisés
        let customFields =
          this.localStorageService.customFieldStorage.get(model);

        if (customFields != undefined) {
          customFields.subscribe((s) => {
            model = s;
          });
        }

        // Chargement des formulaires Utilisateurs
        let service: IncidentCoreService | RequestCoreService =
          context.ticketType === 'incident'
            ? this.incidentService
            : this.requestService;

        service.findFirstStatus(id).then((statusId) => {
          let connectedUserId = this.authService.user.getClaim('userId');

          this.modelRulesDesignerCoreService
            .find(this.modelRulesDesignerCoreService.getFields(), null, {
              and: [
                { operatorIds: { elemMatch: { in: [connectedUserId] } } },
                { qualification: { elemMatch: { in: [context.ticketType] } } },
                { type: { eq: `user-${context.ticketType}` } },
              ],
            })
            .subscribe((res) => {
              context.rule = res.data[0];

              if (context.useFormDesigner == false) {
                // Utilisation clasdsique des rules
                let customModel =
                  this.modelRulesApplicationService.applyModelRule(
                    model as any,
                    res.data[0],
                    statusId,
                    null,
                    id,
                  );

                if (customModel != undefined) {
                  context.useFormDesigner = true;
                  model = customModel;
                }
              }

              this.modelCompilerService.compile(model).subscribe((model) => {
                if (id == undefined) {
                  // par defaut set de la date
                  model.sharedContext.entry.set('created', () => new Date());
                }

                if (
                  context.incidentModelId != undefined &&
                  context.useFormDesigner == true
                ) {
                  model.sharedContext.entry.set(
                    'incidentModelId',
                    () => context.incidentModelId,
                  );
                }

                context.model = model;
                gc.forDispose(
                  model.on.subscribe((event: any) => {
                    if (event?.eventName === 'enlarge_click') {
                      context.isAddOtherUser = true;
                      context.popUpVisibleAddOtherUserVisible = true;
                    }
                  }),
                );
                // if (context.disableStatusChange === true) {
                //   if (
                //     context.model?.model?.form?.layout?.pages[4] != undefined
                //   ) {
                //     context.model.model.form.layout.pages[4].visible = true;
                //   }
                // } else {
                //   if (
                //     context.model?.model?.form?.layout?.pages[4] != undefined
                //   ) {
                //     context.model.model.form.layout.pages[4].visible = false;
                //   }
                // }

                this.ticketService.changeIncidentModelModeWithCategoryVisibility(
                  context.rule,
                  context.model,
                );

                context.model.sharedContext.params.set(
                  'intervention-subform',
                  () => interventionModel,
                );

                context.model.sharedContext.params.set(
                  'task-subform',
                  () => taskModel,
                );

                context.modelCompileEndedEvent.emit(null);
              });
            });
        });
      });
  }

  private checkIfEndWorkFlowStatusFields = [
    GqlSubField.create('data', [
      GqlField.create('favoriteUserTicketIds'),
      GqlField.create('isFavorite'),
      GqlField.create('statusId'),
    ]),
  ];

  private checkIfEndWorkFlowStatusAction(
    ticket: ServiceSingleResultOfIncident | ServiceSingleResultOfRequest,
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
  ) {
    let nodes = context.workflowData.data.config['nodes'];

    let endIds = nodes.filter((x) => x.isEnding).map((x) => x.statusId);
    let endOfTreatmentIds = nodes
      .filter((x) => x.isEndTreatment)
      .map((x) => x.statusId);

    if (endIds.includes(ticket.data.statusId)) {
      context.disableStatusChange = true;
      context.isReminderVisible = false;

      if (context.model != undefined && context.model.model != undefined) {
        context.model.model.form.layout.pages[4].visible = true;
      }
    } else if (endOfTreatmentIds.includes(ticket.data.statusId)) {
      context.isReminderVisible = false;
    } else if (this.isReminder != undefined) {
      context.isReminderVisible = this.isReminder;
    }

    // Gestion du routage
    if (
      nodes != undefined &&
      this.router != undefined &&
      this.route != undefined
    ) {
      let actualNode = nodes.find((x) => x.statusId == ticket.data.statusId);
      if (actualNode != undefined) {
        let routeTo = undefined;
        if (actualNode.isEnding) {
          // PAs de changement (overview)
          routeTo = undefined;
        } else if (actualNode.isEndTreatment) {
          routeTo = 'resolution';
        }

        if (routeTo != undefined) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { navigateTo: routeTo },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
          formComponent.navigateToByTitle(
            TranslateService.get(`entities/${context.ticketType}/${routeTo}`),
          );
        }
      }
    }
  }

  checkIfEndWorkFlowStatus(
    gc: GC,
    id: string,
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
  ) {
    if (context.ticketType === 'incident') {
      gc.forDispose(
        this.incidentService
          .get(this.checkIfEndWorkFlowStatusFields, id)
          .subscribe((x) => {
            this.checkIfEndWorkFlowStatusAction(x, context, formComponent);
          }),
      );
    } else {
      gc.forDispose(
        this.requestService
          .get(this.checkIfEndWorkFlowStatusFields, id)
          .subscribe((x) => {
            this.checkIfEndWorkFlowStatusAction(x, context, formComponent);
          }),
      );
    }
  }

  async massiveEditFormLoaded(
    id: string,
    massiveComponent: CoreMassiveEditComponent,
    context: HelpDeskTicketVars,
  ) {
    let labelCtrl = FormGroupHelpers.formControlByName(
      massiveComponent.modelState.form,
      'label',
    );
    if (labelCtrl != undefined && context.isSatisfactionPopupVisible == true) {
      labelCtrl.setValue(context.savedSatisfactionTitle);
    } else {
      await this.getDataWorkflow(context, id);

      let status = FormGroupHelpers.formControlByName(
        massiveComponent.modelState.form,
        'statusIdOld',
      );

      let newStatus = FormGroupHelpers.formControlByName(
        massiveComponent.modelState.form,
        'statusId',
      );

      // On check si le status a le commentaire obligatoire
      this.checkIsCommentary(context.workflowData, massiveComponent);

      if (id != null) {
        // On pré remplis le champ de la popup avec le status actuel
        if (status != null) {
          status.setValue(context.currentStatusId);
        }

        if (newStatus != null && context.currentStatusId) {
          let nodes = context.workflowData.data.config['nodes'];
          let edges = context.workflowData.data.config['edges'];

          // On va chercher le status actuel et donc son id
          let currentStatus = nodes.find(
            (element) => element.statusId == context.currentStatusId,
          );
          let currentStatusId = currentStatus.objId;

          // Ensuite on vérifie si plusieures edges ont cet id en from
          let countIfFrom = edges.filter(
            (element) => element.fromId == currentStatusId,
          ).length;

          if (countIfFrom != 0 && countIfFrom == 1) {
            // Si juste from je recup l'ojb id
            let fromEdge = edges.find(
              (element) => element.fromId == currentStatusId,
            );
            let newOjbId = fromEdge.toId;

            // On affecte direct l'obj id au champ
            let newStatusObj = nodes.find(
              (element) => element.objId == newOjbId,
            );
            newStatus.setValue(newStatusObj.statusId);
          }
        }
      }

      // On vérifie si on passe depuis le click item status
      if (context.newStatusState === true) {
        // Dans ce cas, on met directement le nouveau status, status sur lequel on a cliqué
        newStatus.setValue(context.newStatusValue);
        newStatus.updateValueAndValidity();
        // Vu qu'on passe deux fois, on attend le deuxième passage avant de remettre tout par défaut
        if (context.newStatusSet) {
          context.newStatusState = false;
          context.newStatusValue = null;
          context.newStatusSet = false;
        } else {
          context.newStatusSet = true;
        }
      }
    }

    massiveComponent.modelState.sharedContext.params.set('id', () => id);
  }

  private getDataWorkflowTicketFields = [
    GqlSubField.create('data', [
      GqlField.create('statusId'),
      GqlField.create('levelSupport'),
    ]),
  ];

  /** Charge les données des workflows */
  async getIsReminderOpen(context: HelpDeskTicketVars, id: string, gc: GC) {
    if (id != null) {
      let service: IncidentCoreService | RequestCoreService =
        context.ticketType === 'incident'
          ? this.incidentService
          : this.requestService;
      gc.forDispose(
        service
          .get(
            [
              GqlSubField.create('data', [
                GqlField.create('isReminderOpen'),
                GqlField.create('created'),
              ]),
            ],
            id,
          )
          .subscribe(
            (value) => (this.timerReminder = value.data?.isReminderOpen),
          ),
      );
    }
  }

  /** Charge les données des workflows */
  async getDataWorkflow(context: HelpDeskTicketVars, id: string) {
    if (id != null) {
      let service: IncidentCoreService | RequestCoreService =
        context.ticketType === 'incident'
          ? this.incidentService
          : this.requestService;

      let workflowData = await service
        .statusWorkflowByTicketId(
          [
            GqlSubField.create('data', [
              GqlSubField.create('config', [
                GqlSubField.create('nodes', [
                  GqlField.create('statusId'),
                  GqlField.create('objId'),
                  GqlField.create('isEntry'),
                  GqlField.create('isEnding'),
                  GqlField.create('isTakingCharge'),
                  GqlField.create('isEndTreatment'),
                  GqlField.create('isCommentaryRequired'),
                  GqlField.create('canAddSatisfaction'),
                ]),
                GqlSubField.create('edges', [
                  GqlField.create('fromId'),
                  GqlField.create('toId'),
                ]),
              ]),
            ]),
          ],
          id,
        )
        .toPromise();

      context.workflowData = workflowData;

      let ticket: ServiceSingleResultOfIncident | ServiceSingleResultOfRequest;

      if (context.ticketType === 'incident') {
        ticket = await this.incidentService
          .get(this.getDataWorkflowTicketFields, id)
          .toPromise();
      } else {
        ticket = await this.requestService
          .get(this.getDataWorkflowTicketFields, id)
          .toPromise();
      }

      context.currentStatusId = ticket.data.statusId;
    }
  }

  /** Check si le status à le commentaire obligatoire */
  checkIsCommentary(
    workflowData: ServiceSingleResultOfStatusWorkflow,
    massiveComponent: CoreMassiveEditComponent,
  ) {
    let newStatus = FormGroupHelpers.formControlByName(
      massiveComponent.modelState.form,
      'statusId',
    );

    let description = FormGroupHelpers.formControlByName(
      massiveComponent.modelState.form,
      'description',
    );

    if (newStatus != undefined) {
      newStatus.valueChanges.subscribe((val) => {
        if (workflowData != null) {
          let node =
            workflowData.data?.config?.nodes[
              workflowData.data?.config.nodes.findIndex(
                (x) => x.statusId === val,
              )
            ];
          if (node.isCommentaryRequired) {
            //obligatoire donc on passe le champ en required
            description.setValidators(Validators.required);
            description.updateValueAndValidity();
          } else {
            if (description.validator && description.validator.length > 0) {
              // pas obligatoire donc on enlève le required du champ
              description.removeValidators(Validators.required);
              description.updateValueAndValidity();
            }
          }
        }
      });
    }
  }

  onSave(
    e,
    id: string,
    router: Router,
    statusFnCallBack: (
      res: ServiceListResultOfTicketStatus,
      context: HelpDeskTicketVars,
    ) => void,
    context: HelpDeskTicketVars,
    massiveComponent: CoreMassiveEditComponent,
    formComponent: CoreWorkFormComponent,
    gc: GC,
  ) {
    if (this.isMobile || (!this.isMobile && router.url.includes('/mobile/'))) {
      setTimeout((_) => {
        if (id == undefined) {
          router.navigate([
            `mobile/user/help-desk-${context.ticketType}`,
            'edit',
            e,
          ]);
        }
      }, 100);
    } else {
      setTimeout((_) => {
        if (id == undefined) {
          router.navigate([`help-desk-${context.ticketType}`, 'my', 'edit', e]);
        }
      }, 100);
    }

    this.reloadSourceStatus(id, statusFnCallBack, context);

    if (context.token != '') {
      if (context.token == 'status-change') {
        this.openStatusChange(id, massiveComponent, formComponent, context);
      } else if (context.token === 'statisfaction') {
        this.openSatisfactory(id, context, massiveComponent, formComponent, gc);
      }
    }

    if (
      context.isSatisfactionPopupVisible == true &&
      context.satSaveStatusId === undefined
    ) {
      context.model.model.form.layout.pages[4].visible = true;
    } else if (context.model.model.form.layout.pages[4] != undefined) {
      context.model.model.form.layout.pages[4].visible = false;
    }
  }

  onSubmit(
    e,
    id: string,
    router: Router,
    statusFnCallBack: (
      res: ServiceListResultOfTicketStatus,
      context: HelpDeskTicketVars,
    ) => void,
    context: HelpDeskTicketVars,
    massiveComponent: CoreMassiveEditComponent,
    formComponent: CoreWorkFormComponent,
    gc: GC,
  ) {
    if (id == undefined) {
      if (
        context.allowAddOtherUsers == true &&
        this.tempAddOtherUserIds != undefined
      ) {
        this.applyAddOtherUser(
          e.id,
          this.tempAddOtherUserIds,
          formComponent,
          context,
          gc,
        );
      }
    }
  }

  reminderBeforeSave(context: HelpDeskTicketVars) {
    return (_) => {
      setTimeout(() => {
        return (context.reminderActivated = true);
      }, 500);
    };
  }

  startReminder(
    massiveComponent: CoreMassiveEditComponent,
    context: HelpDeskTicketVars,
  ) {
    massiveComponent.beforeSave = this.reminderBeforeSave(context).bind(this);
    massiveComponent.updateFn = this.ticketService.updateReminder.bind(
      this.ticketService,
    );
    massiveComponent.inputModel = context.reminder;
    massiveComponent.title = TranslateService.get('globals/reminder');
    massiveComponent.width = 600;
    massiveComponent.height = 400;
    context.massiveEditVisibility = true;
    context.token = '';
  }

  openStatusChange(
    id: string,
    massiveComponent: CoreMassiveEditComponent,
    formComponent: CoreWorkFormComponent,
    context: HelpDeskTicketVars,
  ) {
    massiveComponent.updateFn = this.changeStatusFunc(context).bind(this);
    massiveComponent.inputModel = context.statusChange;
    massiveComponent.title = TranslateService.get('globals/statusChange');
    massiveComponent.width = 800;
    massiveComponent.height = 730;
    massiveComponent.beforeSave = this.beforeSaveFunc(
      id,
      context,
      massiveComponent,
      formComponent,
    );
    context.massiveEditVisibility = true;

    context.token = '';
  }

  beforeSaveFunc(
    id: string,
    context: HelpDeskTicketVars,
    massiveComponent: CoreMassiveEditComponent,
    formComponent: CoreWorkFormComponent,
  ) {
    let beforeSave: (e: any) => Promise<boolean> = async (e: any) => {
      //- Permet de récuperer l'id du nouveau status
      let newStatusId = FormGroupHelpers.formControlByName(
        massiveComponent.modelState.form,
        'statusId',
      );
      // -Applique les règles du form opérateur
      if (newStatusId.value != null) {
        if (context.useFormDesigner === false) {
          this.modelRulesApplicationService.applyModelRule(
            context.model.model,
            context.rule,
            newStatusId.value,
            context.model.form,
            id,
          );
        }

        formComponent.form.updateValueAndValidity();
        if (formComponent.form.invalid == true) {
          notify(
            TranslateService.get('entities/asset/errors/noForm', 'error', 5000),
          );
          return true;
        } else {
          return false;
        }
      }
    };
    return beforeSave;
  }

  changeStatusFunc(context: HelpDeskTicketVars) {
    return (
      fields: Array<GqlField | GqlSubField>,
      incidentId: string,
      entry?: FieldUpdateOperatorOfIncident,
      extendedVariables?: any,
    ): Observable<ServiceSingleResultOfBoolean> => {
      let service: IncidentCoreService | RequestCoreService =
        context.ticketType === 'incident'
          ? this.incidentService
          : this.requestService;

      return service
        .changeStatus(fields, incidentId, entry, extendedVariables)
        .pipe(
          map((res) => {
            context.model.model.form.layout.pages[4].visible =
              context.isSatisfactionPopupVisible == true;
            return res;
          }),
        );
    };
  }

  openSatisfactory(
    id: string,
    context: HelpDeskTicketVars,
    massiveComponent: CoreMassiveEditComponent,
    formComponent: CoreWorkFormComponent,
    gc: GC,
  ) {
    massiveComponent.beforeSave = (_) => {
      return of(false).toPromise();
    };
    massiveComponent.updateFn = (
      fields: Array<GqlField | GqlSubField>,
      ticketId: string,
      entry?: FieldUpdateOperatorOfIncident,
      extendedVariables?: any,
    ) => {
      if (context.satValueChangedSub != undefined) {
        context.satValueChangedSub.unsubscribe();
        context.satValueChangedSub = undefined;
      }
      let ratingCtlr = FormGroupHelpers.formControlByName(
        context.model.form,
        'satisfaction_rating',
      );

      if (entry?.set?.satisfaction?.rating != null) {
        ratingCtlr.setValue(entry.set.satisfaction.rating);
      } else {
        ratingCtlr.setValue(6);
      }

      ratingCtlr['forceSendValue'] = true;

      let commentaryCtlr = FormGroupHelpers.formControlByName(
        context.model.form,
        'satisfaction_commentary',
      );

      commentaryCtlr.setValue(entry?.set?.satisfaction?.commentary);

      commentaryCtlr['forceSendValue'] = true;

      let userIdCtlr = FormGroupHelpers.formControlByName(
        context.model.form,
        'satisfaction_userId',
      );
      userIdCtlr.setValue(this.authService.user.getClaim('userId'));
      userIdCtlr['forceSendValue'] = true;

      this.actionUpdateStatus(
        { id: context.satSaveStatusId },
        id,
        gc,
        context,
        formComponent,
        true,
      );

      return of({ data: true });
    };

    let titleCallBack = () => {
      massiveComponent.inputModel = satisfactoryModel;

      massiveComponent.title = TranslateService.get('globals/satisfaction');
      massiveComponent.width = 800;
      massiveComponent.height = 730;
      context.massiveEditVisibility = true;
      context.token = '';
    };
    this.manageSatisfactionComentaryRequired(context, massiveComponent);

    if (
      context.savedSatisfactionTitle == undefined ||
      context.savedSatisfactionTitle.trim() === ''
    ) {
      let myThemeField = [
        GqlSubField.create('data', [
          GqlSubField.create('helpMe', [
            GqlSubField.create('satisfactionLabel', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
          ]),
        ]),
      ];

      gc.forDispose(
        this.organizationService
          .findContentCustomizationOrga(myThemeField)
          .subscribe((value) => {
            context.savedSatisfactionTitle =
              this.translatedFieldHelperService.findValueToShow(
                value.data.helpMe.satisfactionLabel,
              );
            titleCallBack();
          }),
      );
    } else {
      titleCallBack();
    }
  }

  manageSatisfactionComentaryRequired(
    context: HelpDeskTicketVars,
    massiveComponent: CoreMassiveEditComponent,
  ) {
    let un = massiveComponent.onFormLoaded.subscribe((_) => {
      un.unsubscribe();

      let setRequiredCallBack = () => {
        let ratingCtrl = FormGroupHelpers.formControlByName(
          massiveComponent.modelState.form,
          'satisfaction_rating',
        );
        let commentaryCtrl = FormGroupHelpers.formControlByName(
          massiveComponent.modelState.form,
          'satisfaction_commentary',
        );

        context.satValueChangedSub = ratingCtrl.valueChanges.subscribe(
          (val) => {
            let setings = context.savedSatisfactionSettings.find(
              (el) => el.rating === val,
            );

            if (setings?.commentaryRequired === true) {
              commentaryCtrl.setValidators(Validators.required);
              commentaryCtrl.updateValueAndValidity();
            } else {
              commentaryCtrl.removeValidators(Validators.required);
              commentaryCtrl.updateValueAndValidity();
            }
          },
        );
      };
      if (context.savedSatisfactionSettings.length === 0) {
        this.satisfactionSettingService
          .find([
            GqlSubField.create('data', [
              GqlField.create('rating'),
              GqlField.create('commentaryRequired'),
            ]),
          ])
          .subscribe((res) => {
            context.savedSatisfactionSettings = res.data;
            setRequiredCallBack();
          });
      } else {
        setRequiredCallBack();
      }
    });
  }

  /** Permet de mettre à jour le status du prêt */
  async actionUpdateStatus(
    e,
    id: string,
    gc: GC,
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
    byPassSatisfaction = false,
  ) {
    if (context.useFormDesigner === false) {
      this.modelRulesApplicationService.applyModelRule(
        context.model.model,
        context.rule,
        e.id,
        context.model.form,
        id,
      );
    } else {
      context.model.on.emit({ eventName: 'statusId', eventData: e.id });
    }

    await this.getDataWorkflow(context, id);

    formComponent.form.updateValueAndValidity({ emitEvent: true });

    setTimeout(async () => {
      if (
        formComponent.form.valid === false ||
        formComponent.form.invalid === true
      ) {
        notify(
          TranslateService.get('entities/asset/errors/noForm', 'error', 5000),
        );
        return;
      } else {
        if (context.workflowData != null) {
          let node =
            context.workflowData.data?.config?.nodes[
              context.workflowData.data.config.nodes.findIndex(
                (x) => x.statusId === e.id,
              )
            ];

          if (
            node.canAddSatisfaction === true &&
            this.userPrivilegeAddSatisfaction &&
            byPassSatisfaction === false
          ) {
            context.satSaveStatusId = e.id;
            this.initSatisfactionPopup(context, formComponent);
            return;
          }

          if (node.isCommentaryRequired) {
            // Obligatoire donc on passe la valeur et on met le boolean à true
            context.newStatusState = true;
            context.newStatusValue = e.id;
            this.openMassiveEditStatus(context, formComponent);
          } else {
            let statusId = FormGroupHelpers.formControlByName(
              formComponent.form,
              'statusId',
            );
            if (statusId != undefined) {
              statusId.setValue(e.id);
              statusId.markAsDirty();
            }

            context.model.sharedContext.entry.set('statusId', () => e.id);

            //reset sat stat
            context.satSaveStatusId = undefined;
            formComponent.save({ close: false }).then((f) => {
              context.model.sharedContext.entry.remove('statusId');

              this.reloadSourceStatus(id, this.statusFnCallBack, context);
              this.checkIfEndWorkFlowStatus(gc, id, context, formComponent);

              this.reloadNavMenuConst();
            });
          }
        }
      }
    }, 500);
  }

  /** Emit le pour indiquer un refresh des compteur d'un menu */
  reloadNavMenuConst() {
    if (this.localStorageService?.ModelState != undefined) {
      this.localStorageService.ModelState.on.emit({
        eventName: LocalStorageService.EventConst.ReloadNavMenuConst,
        eventData: undefined,
      });
    }
  }

  statusFnCallBack(
    res: ServiceListResultOfTicketStatus,
    context: HelpDeskTicketVars,
  ) {
    if (res.data.length === 0) {
      context.noStatusToChange = true;
    } else {
      context.noStatusToChange = false;
    }
  }

  initSatisfactionPopup(
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
  ) {
    context.isSatisfactionPopupVisible = true;

    if (formComponent.form.invalid == true) {
      notify(
        TranslateService.get('entities/asset/errors/noForm', 'error', 5000),
      );
      return;
    } else {
      formComponent.save({ close: false });
      context.token = 'statisfaction';
    }
  }

  openMassiveEditStatus(
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
  ) {
    if (formComponent.form.invalid == true) {
      notify(
        TranslateService.get('entities/asset/errors/noForm', 'error', 5000),
      );
      return;
    } else {
      formComponent.save({ close: false });
      context.token = 'status-change';
    }
  }

  // Methode permettant d'afficher le formulaire d'ajout ou le formulaire d'édition

  //end
  //Gestion de la matrice des priorité
  managePriorityMatrix(
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
    gc: GC,
  ) {
    let impactField = FormGroupHelpers.formControlByName(
      formComponent.form,
      'impactId',
    );
    let urgencyField = FormGroupHelpers.formControlByName(
      formComponent.form,
      'urgencyId',
    );
    let priorityField = FormGroupHelpers.formControlByName(
      formComponent.form,
      'priorityId',
    );

    let service: IncidentCoreService | RequestCoreService =
      context.ticketType === 'incident'
        ? this.incidentService
        : this.requestService;

    if (priorityField != undefined) {
      service
        .isActiveMatrix([GqlField.create('data')])
        .toPromise()
        .then((x) => {
          if (x.data == true) {
            priorityField.disable();
          }
        });
    }

    if (impactField != undefined) {
      gc.forDispose(
        impactField.valueChanges.subscribe((value) => {
          if (impactField.originalValue != value) {
            this.ticketService.getPriorityByMatrix(
              impactField,
              urgencyField,
              formComponent.form,
              service,
            );
          }
        }),
      );
    }
    if (urgencyField != undefined) {
      gc.forDispose(
        urgencyField.valueChanges.subscribe((value) => {
          if (urgencyField.originalValue != value) {
            this.ticketService.getPriorityByMatrix(
              impactField,
              urgencyField,
              formComponent.form,
              service,
            );
          }
        }),
      );
    }
  }

  resetPage() {
    location.reload();
  }

  /** Méthode permettant d'afficher le titre du formulaire (ajout ou édition) */
  manageTicketTitle(
    context: HelpDeskTicketVars,
    workForm: CoreWorkFormComponent,
    ticketBaseTitle: string,
  ) {
    if (context.ownTitle == ticketBaseTitle) {
      let ticketNumber = FormGroupHelpers.formControlByName(
        workForm.form,
        'ticketNumber',
      );

      if (
        ticketNumber.value != undefined &&
        ticketNumber.value != null &&
        ticketNumber.value != ''
      ) {
        context.ownTitle = context.ownTitleBase + ' n° ' + ticketNumber.value;
      }
    }
  }

  // Gestion des valeurs par défaut
  manageDefaultValue(id: string, context: HelpDeskTicketVars, gc: GC) {
    if (id != undefined) return;

    // Méthode récupérant l'utilisateur connecté
    let connectedUserId = this.authService.user.getClaim('userId');
    context.model.sharedContext.entry.set(
      'userAffectedId',
      () => connectedUserId,
    );
    context.model.sharedContext.entry.set(
      'userMakeRequestId',
      () => connectedUserId,
    );

    // Méthode récupérant le canal de réception par défaut
    gc.forDispose(
      this.channelService
        .findDefault(
          [GqlSubField.create('data', [GqlField.create('id')])],
          'web',
        )
        .subscribe((res) => {
          if (res.data != null) {
            let channelId = res.data.id;
            context.model.sharedContext.entry.set('channelId', () => channelId);
          }
        }),
    );
  }

  private executeTicketModel(
    value,
    formComponent: CoreWorkFormComponent,
    context: HelpDeskTicketVars,
  ) {
    this.ticketService.manageTicketModel(
      value,
      formComponent,
      context.ticketType,
      context.model,
      context.ticketType === 'incident'
        ? this.incidentService
        : this.requestService,
    );
  }

  modelSub: Subscription;

  //Gestion du modéle de ticket
  manageModelTicket(
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
    gc: GC,
  ) {
    let modelTicketField = FormGroupHelpers.formControlByName(
      formComponent.form,
      'incidentModelId',
    );
    if (modelTicketField != undefined) {
      if (this.modelSub !== undefined) {
        this.modelSub.unsubscribe();
      }
      this.ticketService.lastTicketModelId = undefined;
      context.model.form.markAsUntouched();
      this.modelSub = modelTicketField.valueChanges.subscribe(async (value) => {
        if (modelTicketField.originalValue != value) {
          // Get info
          let model = await this.ticketModelService
            .get(
              [
                GqlSubField.create('data', [
                  GqlField.create('id'),
                  GqlField.create('operatorNewFormDesignerId'),
                ]),
              ],
              value,
            )
            .toPromise();
          if (
            model.data?.operatorNewFormDesignerId != undefined &&
            !window.location.href.includes(value)
          ) {
            // Reload de la page si le model incident contient un formulaire perso
            let confirmMessage = await confirm(
              TranslateService.get('entities/ticket/loadFormIncidentModel'),
              TranslateService.get('globals/warning'),
            );
            if (confirmMessage === true) {
              formComponent.workItemForm.inClosed = true;
              // Reload page
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([window.location.pathname], {
                    queryParams: {
                      incidentModelId: value,
                    },
                  });
                });
            }
          } else {
            if (context.model.form.untouched === false) {
              confirm(
                TranslateService.get('confirm-change-model-ticket'),
                TranslateService.get('confirm-title'),
              ).then((result) => {
                if (result === true) {
                  this.executeTicketModel(value, formComponent, context);
                } else {
                  modelTicketField.reset();
                }
              });
            } else {
              this.executeTicketModel(value, formComponent, context);
            }
          }
        }
      });
    }
  }

  massiveEditSaved(
    id: string,
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
    gc: GC,
  ) {
    formComponent.loadData();
    formComponent.save({ close: false });
    this.checkIfEndWorkFlowStatus(gc, id, context, formComponent);
  }

  async ticketNgOnInit(
    id: string,
    context: HelpDeskTicketVars,
    route: ActivatedRoute,
    router: Router,
    formComponent: CoreWorkFormComponent,
    gc: GC,
  ) {
    this.route = route;
    this.router = router;

    // Cas specifique du formDesigner
    let modelId = undefined;
    if (id == undefined) {
      modelId = this.route.snapshot.queryParamMap.get('incidentModelId');
    }

    let result = await this.modelRulesApplicationService.getFormDesigner(
      modelId,
      id,
      context.ticketType,
    );
    if (result != undefined) {
      context.rawEditModel = result?.rawEditModel;
      context.rawModel = result?.rawModel;
      context.incidentModelId = result?.incidentModelId;
      context.useFormDesigner = result?.useFormDesigner;
    }

    let service: IncidentCoreService | RequestCoreService =
      context.ticketType === 'incident'
        ? this.incidentService
        : this.requestService;

    if (router.url.indexOf('/edit/') > 0) {
      context.backUrl = router.url.substring(0, router.url.indexOf('/edit/'));
    } else if (router.url.indexOf('/new') > 0) {
      context.backUrl =
        this.isViewHelpMe && !this.isMobile
          ? '/home'
          : router.url.substring(0, router.url.indexOf('/new'));
    }

    if (id != null) {
      service
        .checkLockTicket(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          id,
        )
        .subscribe((x) => {
          context.checkLockBack = x.data;
        });
    }

    // Affiche le bon formulaire
    this.manageModel(context, id, gc);

    service
      .findStatus(
        [
          GqlSubField.create('data', [
            GqlSubField.create(
              'name',
              this.translatedFieldHelperService.translatedFields(),
            ),
            GqlField.create('key'),
            GqlField.create('id'),
          ]),
        ],
        null,
        id,
      )
      .pipe(
        map((data) => {
          this.statusFnCallBack(data, context);
          return data;
        }),
      )
      .subscribe((res) => {});

    this.reloadSourceStatus(id, this.statusFnCallBack, context);

    formComponent.formReady.subscribe((data) => {
      this.ticketService.manageCallbackNumber(
        id,
        route.snapshot.routeConfig.path,
        context.model.form,
        this.localStorageService,
        this.userService,
        context.ticketType,
        true,
        this.modelRulesDesignerCoreService,
        this.myOrganizationCoreService,
      );
    });
    this.tempAddOtherUserIds = undefined;
    // On récupère si le bouton relancer doit se dégriser
    await this.getIsReminderOpen(context, id, gc);
  }

  async ticketNgAfterViewInit(
    id: string,
    context: HelpDeskTicketVars,
    route: ActivatedRoute,
    router: Router,
    ticketBaseTitle: string,
    formComponent: CoreWorkFormComponent,
    gc: GC,
  ) {
    await this.manageUserPrivilege(id, context);
    if (id != null) {
      // On va chercher les nodes et les noeuds pour les stocker et éviter les aller/retour
      await this.getDataWorkflow(context, id);
      this.manageAccesObserver(router, formComponent);
    } else {
      context.isReminderVisible = false;
    }
    this.debounceCheckqueryParam = false;
    formComponent.formReady.subscribe((data: any) => {
      this.manageTicketTitle(context, formComponent, ticketBaseTitle);
      let priorityField = FormGroupHelpers.formControlByName(
        formComponent.form,
        'priorityId',
      );
      if (!context.initialized) {
        this.manageDefaultValue(id, context, gc);
        this.managePriorityMatrix(context, formComponent, gc);
        this.manageModelTicket(context, formComponent, gc);
      }

      if (id != null) {
        this.checkIfEndWorkFlowStatus(gc, id, context, formComponent);
      } else {
        context.isReminderVisible = false;

        this.manageQueryParams(
          route,
          context.model.form,
          context,
          formComponent,
        );
      }
    });
  }

  ticketNgOnDestroy(context: HelpDeskTicketVars, gc: GC) {
    clearInterval(context.intervalCheck);
    gc.dispose();
  }

  async manageUserPrivilege(id, context) {
    let userPrivilege = await this.localStorageService.user
      .getHelpDeskPrivilege()
      .toPromise();
    context.allowAddOtherUsers = UserPrivilegeCoreService.hasPrivilegeEntry(
      userPrivilege.data['affectedUserEntries'],
      'allow-add-other-user',
    );
    if (id != undefined) {
      let connectedUserId = this.userService.getConnectedUser();
      let service: IncidentCoreService | RequestCoreService =
        context.ticketType === 'incident'
          ? this.incidentService
          : this.requestService;

      service
        .userPrivilegeProperty([GqlField.create('data')], id)
        .subscribe((x) => {
          let propertyName = x.data[0].toLowerCase() + x.data.slice(1);
          this.isReminder = UserPrivilegeCoreService.hasPrivilegeEntry(
            userPrivilege.data[propertyName],
            'use-call-again',
          );
          this.userPrivilegeAddSatisfaction =
            UserPrivilegeCoreService.hasPrivilegeEntry(
              userPrivilege.data[propertyName],
              'add-satisfaction',
            );
        });
    }
  }

  async manageAccesObserver(
    router: Router,
    formComponent: CoreWorkFormComponent,
  ) {
    if (router.url.includes('/my-area')) {
      let userPrivilege = await this.localStorageService.user
        .getHelpDeskPrivilege()
        .toPromise();
      let allowupdate = UserPrivilegeCoreService.hasPrivilegeEntry(
        userPrivilege.data['accessTicketsObserverEntries'],
        'allow-update-ticket',
      );
      if (!allowupdate) {
        formComponent.readOnly = true;
      }
    }
  }

  debounceCheckqueryParam = false;

  manageQueryParams(
    route: ActivatedRoute,
    form: UntypedFormGroup,
    context: HelpDeskTicketVars,
    formComponent: CoreWorkFormComponent,
  ) {
    if (this.debounceCheckqueryParam === true) {
      return;
    }
    this.debounceCheckqueryParam = true;
    if (route.snapshot.queryParamMap.get('incidentModelId') != undefined) {
      let value = route.snapshot.queryParamMap.get('incidentModelId');
      let incidentModelId = FormGroupHelpers.formControlByName(
        form,
        'incidentModelId',
      );
      if (incidentModelId != undefined) {
        incidentModelId.setValue(value);
        incidentModelId.markAsDirty();
      } else {
        // Chargement
        this.executeTicketModel(value, formComponent, context);
      }
    }
  }

  openAddOtherUser(context) {
    context.popUpVisibleAddOtherUserVisible = true;
  }

  addOtherUser(id, userIds, formComponent, context, gc) {
    if (id != undefined) {
      this.applyAddOtherUser(id, userIds, formComponent, context, gc);
    } else {
      this.tempAddOtherUserIds = userIds;
      context.popUpVisibleAddOtherUserVisible = false;
    }
  }

  addUserAffected(id, userId, formComponent, context, gc) {
    if (id != undefined) {
      this.applyAddOtherUser(id, userId, formComponent, context, gc);
    } else {
      let userIdField = FormGroupHelpers.formControlByName(
        formComponent.form,
        'userAffectedId',
      );
      userIdField.setValue(userId[0]);
      context.popUpVisibleAddOtherUserVisible = false;
    }
  }

  applyAddOtherUser(id, userIds, formComponent, context, gc) {
    gc.forDispose(
      this.ticketService
        .addUsers(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          userIds,
          id,
        )
        .subscribe((response) => {
          context.popUpVisibleAddOtherUserVisible = false;
          formComponent.save({ close: false });
        }),
    );
  }

  addOtherUserFormLoaded(id, form, gc) {
    let idField = FormGroupHelpers.formControlByName(form, 'id');
    idField.setValue(id);
    let locationField = FormGroupHelpers.formControlByName(form, 'locationId');
    if (locationField != undefined) {
      gc.forDispose(
        locationField.valueChanges.subscribe((value) => {
          this.localStorageService.ModelState.on.emit({
            eventName: LocalStorageService.EventConst.ReloadSelectSimpleList,
            eventData: {},
          });
        }),
      );
    }
    let organizationField = FormGroupHelpers.formControlByName(
      form,
      'organizationalUnitId',
    );
    if (organizationField != undefined) {
      gc.forDispose(
        organizationField.valueChanges.subscribe((value) => {
          this.localStorageService.ModelState.on.emit({
            eventName: LocalStorageService.EventConst.ReloadSelectSimpleList,
            eventData: {},
          });
        }),
      );
    }
    if (this.tempAddOtherUserIds != undefined) {
      let userIdsField = FormGroupHelpers.formControlByName(form, 'userIds');
      userIdsField.setValue(this.tempAddOtherUserIds);
    }
  }

  /**
   *  Permet de naviguer vers la page 'new' d'un incident dans le mode 'mobile' (opérateur)
   */
  createNewMobileOperatorTicket(router: Router, type: string) {
    router.navigate([`/mobile/${type}/new`]);
  }

  /**
   *  Permet de naviguer vers la page 'new' d'un incident dans le mode 'mobile' (utilisateur)
   */
  createNewMobileHelpDeskTicket(router: Router, type: string) {
    router.navigate([`/mobile/user/help-desk-${type}/new`]);
  }

  //end
}
