import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfApplicationAzureAd, FilterOfApplicationAzureAd, QueryContextOfApplicationAzureAd, ServiceSingleResultOfInt64, ServiceListResultOfApplicationAzureAd, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfEmailConnector, FilterOfEmailConnector, QueryContextOfEmailConnector, ApplicationAzureAdInput, FieldUpdateOperatorOfApplicationAzureAd } from '../types'

export type GetApplicationAzureAdsResultType = {
    applicationAzureAds: {
        get?: Maybe<ServiceSingleResultOfApplicationAzureAd>
    }
}

export type GetApplicationAzureAdsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getApplicationAzureAds ${args} {
    applicationAzureAds {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfApplicationAzureAd", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstApplicationAzureAdsResultType = {
    applicationAzureAds: {
        first?: Maybe<ServiceSingleResultOfApplicationAzureAd>
    }
}

export type FirstApplicationAzureAdsBaseVariables = {
	filter: Maybe<FilterOfApplicationAzureAd>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfApplicationAzureAd>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfApplicationAzureAd = null, $options: QueryContextOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstApplicationAzureAds ${args} {
    applicationAzureAds {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfApplicationAzureAd", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountApplicationAzureAdsResultType = {
    applicationAzureAds: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountApplicationAzureAdsBaseVariables = {
	filter: Maybe<FilterOfApplicationAzureAd>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countApplicationAzureAds ${args} {
    applicationAzureAds {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindApplicationAzureAdsResultType = {
    applicationAzureAds: {
        find?: Maybe<ServiceListResultOfApplicationAzureAd>
    }
}

export type FindApplicationAzureAdsBaseVariables = {
	options: Maybe<QueryContextOfApplicationAzureAd>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfApplicationAzureAd>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfApplicationAzureAd = null, $filter: FilterOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findApplicationAzureAds ${args} {
    applicationAzureAds {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfApplicationAzureAd", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchApplicationAzureAdsResultType = {
    applicationAzureAds: {
        search?: Maybe<ServiceListResultOfApplicationAzureAd>
    }
}

export type SearchApplicationAzureAdsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfApplicationAzureAd>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchApplicationAzureAds ${args} {
    applicationAzureAds {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfApplicationAzureAd", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaApplicationAzureAdsResultType = {
    applicationAzureAds: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaApplicationAzureAdsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaApplicationAzureAds ${args} {
    applicationAzureAds {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataApplicationAzureAdsResultType = {
    applicationAzureAds: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataApplicationAzureAdsBaseVariables = {
	filter: Maybe<FilterOfApplicationAzureAd>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataApplicationAzureAds ${args} {
    applicationAzureAds {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryApplicationAzureAdsResultType = {
    applicationAzureAds: {
        customQuery?: Maybe<ServiceListResultOfApplicationAzureAd>
    }
}

export type CustomQueryApplicationAzureAdsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfApplicationAzureAd>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfApplicationAzureAd>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfApplicationAzureAd = null, $options: QueryContextOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryApplicationAzureAds ${args} {
    applicationAzureAds {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfApplicationAzureAd", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdApplicationAzureAdsResultType = {
    applicationAzureAds: {
        customQueryId?: Maybe<ServiceListResultOfApplicationAzureAd>
    }
}

export type CustomQueryIdApplicationAzureAdsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfApplicationAzureAd>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfApplicationAzureAd>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfApplicationAzureAd = null, $options: QueryContextOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdApplicationAzureAds ${args} {
    applicationAzureAds {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfApplicationAzureAd", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedApplicationAzureAdsResultType = {
    applicationAzureAds: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedApplicationAzureAdsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedApplicationAzureAds ${args} {
    applicationAzureAds {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type HasExpireSecretClientAzureAdApplicationAzureAdsResultType = {
    applicationAzureAds: {
        hasExpireSecretClientAzureAd?: Maybe<ServiceSingleResultOfString>
    }
}

export type HasExpireSecretClientAzureAdApplicationAzureAdsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class HasExpireSecretClientAzureAdApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query hasExpireSecretClientAzureAdApplicationAzureAds ${args} {
    applicationAzureAds {
        hasExpireSecretClientAzureAd {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: HasExpireSecretClientAzureAdApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<HasExpireSecretClientAzureAdApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistApplicationAzureAdsResultType = {
    applicationAzureAds: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistApplicationAzureAdsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existApplicationAzureAds ${args} {
    applicationAzureAds {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedEmailConnectorsApplicationAzureAdsResultType = {
    applicationAzureAds: {
        findUnassociatedEmailConnectors?: Maybe<ServiceListResultOfEmailConnector>
    }
}

export type FindUnassociatedEmailConnectorsApplicationAzureAdsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfEmailConnector>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailConnector>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedEmailConnectorsApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfEmailConnector = null, $options: QueryContextOfEmailConnector = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedEmailConnectorsApplicationAzureAds ${args} {
    applicationAzureAds {
        findUnassociatedEmailConnectors(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedEmailConnectorsApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedEmailConnectorsApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailConnector", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertApplicationAzureAdsResultType = {
    applicationAzureAds: {
        insert?: Maybe<ServiceSingleResultOfApplicationAzureAd>
    }
}

export type InsertApplicationAzureAdsBaseVariables = {
	entity: ApplicationAzureAdInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: ApplicationAzureAdInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertApplicationAzureAds ${args} {
    applicationAzureAds {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfApplicationAzureAd", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateApplicationAzureAdsResultType = {
    applicationAzureAds: {
        update?: Maybe<ServiceSingleResultOfApplicationAzureAd>
    }
}

export type UpdateApplicationAzureAdsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfApplicationAzureAd>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateApplicationAzureAds ${args} {
    applicationAzureAds {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfApplicationAzureAd", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataApplicationAzureAdsResultType = {
    applicationAzureAds: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataApplicationAzureAdsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataApplicationAzureAds ${args} {
    applicationAzureAds {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyApplicationAzureAdsResultType = {
    applicationAzureAds: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyApplicationAzureAdsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfApplicationAzureAd>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfApplicationAzureAd = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyApplicationAzureAds ${args} {
    applicationAzureAds {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteApplicationAzureAdsResultType = {
    applicationAzureAds: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteApplicationAzureAdsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteApplicationAzureAds ${args} {
    applicationAzureAds {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddEmailConnectorsApplicationAzureAdsResultType = {
    applicationAzureAds: {
        addEmailConnectors?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddEmailConnectorsApplicationAzureAdsBaseVariables = {
	id: string, /**  */
	emailConnectorIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddEmailConnectorsApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $emailConnectorIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addEmailConnectorsApplicationAzureAds ${args} {
    applicationAzureAds {
        addEmailConnectors(id: $id, emailConnectorIds: $emailConnectorIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddEmailConnectorsApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddEmailConnectorsApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveEmailConnectorsApplicationAzureAdsResultType = {
    applicationAzureAds: {
        removeEmailConnectors?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveEmailConnectorsApplicationAzureAdsBaseVariables = {
	emailConnectorIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveEmailConnectorsApplicationAzureAdsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$emailConnectorIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeEmailConnectorsApplicationAzureAds ${args} {
    applicationAzureAds {
        removeEmailConnectors(emailConnectorIds: $emailConnectorIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveEmailConnectorsApplicationAzureAdsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveEmailConnectorsApplicationAzureAdsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
