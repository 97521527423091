<div
  class="cl-section-form-designer-hover cl-action-form-designer-hover"
  *ngIf="designerMode === true"
>
  <div class="cl-section-form-designer-title">
    {{ 'entities/formDesigner/section' | translate }}
  </div>
  <div *ngIf="designerMode" class="cl-action-form-designer">
    <div (click)="formAction($event, getAction.ADD_IN)">
      <i class="fa-solid fa-plus"></i>
      <p>{{ 'entities/formDesigner/group' | translate }}</p>
    </div>
    <div
      class="cl-edit-form-designer"
      (click)="formAction($event, getAction.EDIT)"
    >
      <i class="fa-solid fa-pen"></i>
    </div>
    <div
      class="cl-delete-form-designer"
      (click)="formAction($event, getAction.REMOVE)"
    >
      <i class="fa-solid fa-trash-xmark"></i>
    </div>
  </div>
</div>
<div [ngClass]="{ 'cl-section-form-designer': designerMode }">
  <div
    *ngIf="designerMode === true"
    class="cl-section-fill-form-designer"
  ></div>
  <ng-content></ng-content>
</div>
