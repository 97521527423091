import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from '@clarilog/core/services/environment/environment.service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  ArticleKnowledgeCoreService,
  NewsCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { TranslateService } from '@clarilog/shared/services/translate';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import notify from 'devextreme/ui/notify';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization';

/** Représente la classe du composent cl-viewer-popup. */
@Component({
  selector: 'clc-viewer-popup',
  templateUrl: './viewer-popup.component.html',
  styleUrls: ['./viewer-popup.component.scss'],
})
export class ViewerPopUpComponent implements OnInit {
  /** Définit le type */
  @Input() serviceName: string;
  /** Définit la couleur du bandeau*/
  @Input() color: string;
  /** Définit la couleur du titre*/
  @Input() secondColor: string;
  /** Définit l'icone */
  @Input() icon: string;
  /** Définit le titre de la zone */
  title: string;
  /** Définit le footer de la zone */
  lastUpdateBy: string;
  lastUpdated: string;
  /** Définit l'icone dans la list */
  content: string;
  /** Définit l'etat du load panel' */
  waitLoading: boolean = false;
  /** Définit l'etat du bouton "Imprimer" */
  printVisible: boolean = false;
  /** Définit la traduction*/
  @Input() translatable: boolean = false;
  /** Définit l'etat d'affichage*/
  @Input() showViewer: boolean = false;

  /** Permet de rendre visible la zone des évaluation */
  @Input() showEvaluateZone: string;

  /** Definit si le service de comptage est appeler */
  @Input() countService: string;
  /** Definit si la methode du service de comptage est appeler */
  @Input() countMethodeService: string;

  /** Definit si le titre doit etre traduit */
  @Input() titleTranslatable: boolean = false;
  /** Definit si le content doit etre traduit */
  @Input() contentTranslatable: boolean = false;

  @Output() showViewerChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() idChange: EventEmitter<string> = new EventEmitter<string>();

  /** Définit l'id sélectionner */
  @Input()
  public set id(value: string) {
    if (value != undefined) {
      this.loadData(value);
    }
    this._value = value;
    this.idChange.emit(this._value);
  }
  public get id() {
    return this._value;
  }

  _value: string;

  /** Définit l'injector */
  injector: Injector;

  /** Obtient le nombre de note */
  countRating: number = 0.0;
  /** Obtient la note personal */
  averageRating: number = 0.0;
  /** Obtient la note personal */
  personalRating: number = 0.0;

  /** Obient l'id courrant */
  currentId: string;

  /** Permet de savoir si on est sur la vue Help Me */
  isViewHelpMe: boolean = false;

  constructor(
    private _envService: EnvironmentService,
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private articleKnowledgeService: ArticleKnowledgeCoreService,
    private router: Router,
    private authorizationService: AuthorizationCoreService,
    private newsCoreService: NewsCoreService,
  ) {
    document.documentElement.style.setProperty(
      '--primaryAeraColor',
      'rgb(213, 106, 240)',
    );
    this.injector = injector;
    this.starClickChange = this.starClickChange.bind(this);
  }

  /** @inheritdoc */
  async ngOnInit() {
    this.isViewHelpMe =
      localStorage.getItem('viewHelpMe') == 'true' ? true : false;
  }

  onHiding(e) {
    this.showViewer = false;
    this.averageRating = -1;
    this.personalRating = -1;
    this.countRating = 0;
    this.id = undefined;
    this.showViewerChange.emit(false);
  }

  async loadData(id: string) {
    this.currentId = id;
    this.averageRating = 0;
    this.personalRating = 0;
    this.countRating = 0;
    if (this.countService != undefined) {
      // Incremente les compteurs de vue
      // TODO check
      let service = this.injector.get(this.countService);
      if (service != undefined) {
        if (service[this.countMethodeService] != undefined) {
          service[this.countMethodeService](
            ModelFieldCompilerService.createServiceSingleResultScalar(),
            id,
          ).toPromise();
        }
      }
    }

    let fieldTilte: GqlField | GqlSubField = GqlField.create('title');
    if (this.titleTranslatable === true) {
      fieldTilte = GqlSubField.create(
        'title',
        this.translatedFieldHelperService.translatedFields(),
      );
    }

    this.waitLoading = true;
    let customField = [
      fieldTilte,
      GqlField.create('lastUpdated'),
      GqlSubField.create('lastUpdateBy', [
        GqlSubField.create('data', [GqlField.create('name')]),
      ]),
    ];
    switch (this.serviceName) {
      case 'NewsCoreService':
        customField.push(
          GqlSubField.create(
            'content',
            this.translatedFieldHelperService.translatedFields(),
          ),
        );
        break;
      case 'ArticleKnowledgeCoreService':
        customField.push(
          GqlSubField.create(
            'description',
            this.translatedFieldHelperService.translatedFields(),
          ),
        );
        customField.push(
          GqlSubField.create(
            'solution',
            this.translatedFieldHelperService.translatedFields(),
          ),
        );
        customField.push(GqlField.create('links'));
        customField.push(GqlField.create('knowledgeNumber'));
        customField.push(
          GqlSubField.create('attachmentFiles', [
            GqlSubField.create('data', [
              GqlField.create('fileId'),
              GqlField.create('name'),
            ]),
          ]),
        );
        break;
    }

    if (this.showEvaluateZone != undefined) {
      customField.push(GqlField.create('averageRating'));
      customField.push(GqlField.create('countRating'));

      let rating = await this.getValueEvaluate(id, 'noteByUser');
      if (rating != undefined) {
        this.personalRating = rating;
      }
    }

    this.lastUpdateBy = '';
    this.lastUpdated = '';
    this.content = '';
    this.title = '';
    let field = [GqlSubField.create('data', customField)];
    // TODO check
    this.injector
      .get(this.serviceName)
      ['get'](field, id)
      .subscribe((entity) => {
        if (entity?.data == null) {
          notify(
            TranslateService.get('entities/articleKnowledge/msgErrorView'),
            'error',
            5000,
          );
          this.showViewer = false;
          return;
        }
        this.lastUpdateBy = '';
        this.lastUpdated = '';
        this.content = '';
        if (entity.data.knowledgeNumber != null) {
          let tradBaseTitle = TranslateService.get(
            'entities/articleKnowledge/baseTitlePopup',
          );
          //On construit le titre pour les articles de connaissance
          let tilte = entity.data.title;
          if (this.titleTranslatable === true) {
            tilte =
              entity.data.title[
                this.translatedFieldHelperService.getTranslateKey()
              ];
          }
          this.title =
            tradBaseTitle + entity.data.knowledgeNumber + ' : ' + tilte;
        } else {
          let tilte = entity.data.title;
          if (this.titleTranslatable === true) {
            tilte =
              entity.data.title[
                this.translatedFieldHelperService.getTranslateKey()
              ];
          }
          this.title = tilte;
        }
        if (entity.data.lastUpdateBy.data != null) {
          this.lastUpdateBy = entity.data.lastUpdateBy.data.name;
          let date = new Date(entity.data.lastUpdated);
          var options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          };
          this.lastUpdated =
            date.toLocaleDateString() +
            ' ' +
            date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }

        if (this.showEvaluateZone != undefined) {
          if (entity.data.averageRating != undefined) {
            this.averageRating = entity.data.averageRating;
          }
          if (entity.data.countRating != undefined) {
            this.countRating = entity.data.countRating;
          }
        }

        switch (this.serviceName) {
          case 'NewsCoreService':
            this.content = entity.data.content;
            break;
          case 'ArticleKnowledgeCoreService':
            this.printVisible = true;
            this.content +=
              '<span style="font-weight: bold;">' +
              TranslateService.get('entities/articleKnowledge/description') +
              ':</span><br><br>' +
              entity.data.description[
                this.translatedFieldHelperService.getTranslateKey()
              ] +
              '<br><br>';
            this.content +=
              '<span style="font-weight: bold;">' +
              TranslateService.get('entities/articleKnowledge/solution') +
              ':</span><br><br>' +
              entity.data.solution[
                this.translatedFieldHelperService.getTranslateKey()
              ];
            if (entity.data.attachmentFiles.data.length > 0) {
              this.content +=
                '<br><br><span style="font-weight: bold;">' +
                TranslateService.get('entities/files/_title/plural') +
                ':</span><br><br>';

              let link = window.location.origin + '/file/viewer/';

              entity.data.attachmentFiles.data.forEach((file) => {
                let url = link + file.fileId;
                this.content +=
                  '<a href="' +
                  url +
                  '" target="_blank">' +
                  file.name +
                  '</a><br>';
              });
            }
            if (
              entity.data.links != undefined &&
              entity.data.links.length > 0
            ) {
              this.content +=
                '<br><br><span style="font-weight: bold;">' +
                TranslateService.get('entities/articleKnowledge/link') +
                ':</span><br><br>';
              entity.data.links.forEach((link) => {
                this.content +=
                  '<a href="' +
                  (link.toLowerCase().indexOf('http') < 0 ? 'https://' : '') +
                  link +
                  '" target="_blank">' +
                  link +
                  '</a><br>';
              });
            }

            break;
        }

        this.content = ModelFieldCompilerService.createElementFromHTML(
          this.content,
        );

        this.waitLoading = false;
      });
  }

  /**
   * Permet de savoir si l'utilisateur est de type Help Desk
   * @returns boolean
   */
  isHelpDeskUser = () =>
    !this.authorizationService.user.hasHelpDeskOperator() &&
    !this.authorizationService.user.hasManager() &&
    this.authorizationService.user.hasHelpDeskUser();

  getArticleLink(serviceName: string, isHelpMe: boolean = false) {
    if (serviceName === 'ArticleKnowledgeCoreService') {
      this.articleKnowledgeService.getArticleLink(
        location.origin,
        'knowledge',
        this.id,
        isHelpMe,
      );
    }

    if (serviceName === 'NewsCoreService') {
      this.newsCoreService.getArticleLink(location.origin, this.id, isHelpMe);
    }

    notify(
      TranslateService.get('entities/articleKnowledge/linkCopied'),
      'success',
      2000,
    );
  }

  async getValueEvaluate(id, methodName) {
    if (this.showEvaluateZone != undefined) {
      // TODO check
      let service = this.injector.get(this.showEvaluateZone);
      if (service != undefined) {
        if (service[methodName] != undefined) {
          let result = await service[methodName](
            ModelFieldCompilerService.createServiceSingleResultScalar(),
            id,
          ).toPromise();
          if (result?.data != undefined) {
            return result.data;
          }
        }
      }
    }

    return 0;
  }

  ngOnDestroy() {}

  async starClickChange(e) {
    if (this.showEvaluateZone != undefined) {
      // TODO check
      let service = this.injector.get(this.showEvaluateZone);
      if (service != undefined) {
        if (service['setNoteByUser'] != undefined) {
          let customField = [];
          customField.push(GqlField.create('averageRating'));
          customField.push(GqlField.create('countRating'));
          let field = [GqlSubField.create('data', customField)];

          let result = await service['setNoteByUser'](
            field,
            e.rating,
            this.currentId,
          ).toPromise();
          // Reload
          if (result?.data != undefined) {
            if (result.data.averageRating != undefined) {
              this.averageRating = result.data.averageRating;
            }
            if (result.data.countRating != undefined) {
              this.countRating = result.data.countRating;
            }
          }
        }
      }
    }
  }

  getTranlateTilte(field) {
    if (this.titleTranslatable && field != undefined) {
      return field[this.translatedFieldHelperService.getTranslateKey()];
    }
    return field;
  }
  getTranlateContent(field) {
    if (this.contentTranslatable && field != undefined) {
      return field[this.translatedFieldHelperService.getTranslateKey()];
    }
    return field;
  }

  private s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  public htmlSchemaTemplate() {
    // Récupèration du template HTML d'impression encodé
    this.articleKnowledgeService
      .printArticleKnowledgeData(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        this.id,
      )
      .subscribe((res) => {
        // Création d'un élément lambda
        const element = document.createElement('a');
        // Récupère le service
        const serviceName =
          this.serviceName == undefined
            ? this.articleKnowledgeService.constructor.name.replace(
                /Service$/gm,
                '',
              )
            : this.serviceName;
        // Définit le type-MIME du fichier
        let type = 'text/html';
        // Définit l'extension du fichier
        let ext = '.html';
        //Création du BinaryLargeObject comprenant le fichier encodé
        const blob = new Blob([this.s2ab(atob(res.data))], { type: type });
        //Création de la date du jour pour le nom du fichier
        const date = new Date();
        const dateString =
          date.getFullYear() +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          ('0' + date.getDate()).slice(-2) +
          ('0' + date.getHours()).slice(-2) +
          ('0' + date.getMinutes()).slice(-2);

        element.href = URL.createObjectURL(blob);
        //Téléchargement du fichier comprenant le nom du métier, de la date suivi de l'extension
        element.download = serviceName + '_print_' + dateString + ext;

        document.body.appendChild(element);
        element.click();
        setTimeout(() => {
          element.remove();
        });
      });
  }
  // Action sur le bouton "Imprimer"
  async printForm(e) {
    if (this.id != null && this.id != undefined) {
      // décode du template HTML
      this.htmlSchemaTemplate();
    }
  }
  close() {
    this.showViewer = false;
    this.id = undefined;
    this.showViewerChange.emit(false);
  }
}
