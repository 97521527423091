import { Authorize } from '@clarilog/core/services/graphql';
import { WorkflowTicketTaskBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { TicketCoreService } from './ticket.service';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { Observable, of } from 'rxjs';
import {
  FilterOfTicketTaskModel,
  QueryContextOfTicketTaskModel,
  ServiceListResultOfTicketTaskModel,
} from '../types';
import { GqlField, GqlSubField } from '../helpers';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-workflow')
export class WorkflowTicketTaskCoreService extends WorkflowTicketTaskBaseService {
  constructor(
    injector: Injector,
    private ticketCoreService: TicketCoreService,
  ) {
    super(injector);
  }

  @InjectArgs
  public findTaskModel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfTicketTaskModel,
    @Args('filter?') filter?: FilterOfTicketTaskModel,
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('type?') type?: string,
  ): Observable<ServiceListResultOfTicketTaskModel> {
    let qualificationList = ['incident', 'problem', 'request'];
    if (!qualificationList.includes(type.toLowerCase())) {
      return of({ totalCount: 0, data: [] });
    }
    return this.ticketCoreService.findTaskModel(
      fields,
      type,
      options,
      filter,
      extendedVariables,
    );
  }
}
