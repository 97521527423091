
import { NgModule } from "@angular/core";
import { AccessPointCoreService,
AccountCoreService,
ActivityCoreService,
AffectedRuleCoreService,
AgentConfigurationCoreService,
AgentHistoryCoreService,
AgentTokenCoreService,
AlarmMailTaskCoreService,
AlarmCoreService,
AlertCoreService,
AlertTaskCoreService,
AnalyseScanByAzureAdTaskCoreService,
AntiSpywareProductCoreService,
AntiVirusProductCoreService,
ApplicationAzureAdCoreService,
AppLogCoreService,
AppointmentCoreService,
ArticleKnowledgeCategoryCoreService,
ArticleKnowledgeNoteCoreService,
ArticleKnowledgeCoreService,
AssetAccountCoreService,
AssetAcquisitionModeCoreService,
AssetAcquisitionStateCoreService,
AssetAffectedRuleCoreService,
AssetCategoryCoreService,
AssetCategoryRuleCoreService,
AssetContractCoreService,
AssetCostCenterCoreService,
AssetLoanCoreService,
AssetModelCoreService,
AssetModelStockEntryCoreService,
AssetPropertyCoreService,
AssetPropertyTrackingCoreService,
AssetProvisionalCommissioningCoreService,
AssetCoreService,
AssetStatusCoreService,
AssetTypeAccountingCoreService,
AssetTypeStaffingCoreService,
AsyncTaskCoreService,
AutoRunCoreService,
AxelDatumCoreService,
BaseBoardCoreService,
BatteryCoreService,
BioCoreService,
BootConfigurationCoreService,
BrotherLabelCoreService,
BudgetCategoryCoreService,
BudgetCoreService,
BusinessTimeCoreService,
CalendarConnectorCoreService,
CampaignHistoryCoreService,
CampaignCoreService,
CDRomDriveCoreService,
ChannelCoreService,
ChatBotCoreService,
CheckFileTaskCoreService,
CheckSizeFileTaskCoreService,
ClarilogServerCoreService,
CodecFileCoreService,
CommandCoreService,
CommentaryModelCoreService,
ComputerSystemProductCoreService,
ComputerSystemCoreService,
ConcernedProjectCoreService,
ConsumableMovementCoreService,
ContractCategoryCoreService,
ContractLicenseTypeCoreService,
ContractCoreService,
CopyCountCoreService,
CorrectionStockMovementCoreService,
CostCenterCoreService,
CronJobHourlyTaskCoreService,
CustomFieldCoreService,
DashboardCoreService,
DataFileCoreService,
DayOffCoreService,
DeleteFileTaskCoreService,
DesktopCoreService,
DiskDriveCoreService,
DiskRamInformationCoreService,
DomainCoreService,
DynamicPropertyFieldCoreService,
DynamicPropertyGroupCoreService,
DynamicPropertyListCoreService,
EmailBounceCoreService,
EmailConnectorCoreService,
EmailConnectorSystemLogCoreService,
EmailConnectorSystemCoreService,
EmailCredentialCoreService,
EmailPrototypeLogCoreService,
EmailPrototypeCoreService,
EncryptableVolumeCoreService,
EntryTableCoreService,
EnvironmentVariableCoreService,
EsxCredentialCoreService,
ExchangeConnectorCoreService,
FaxCountCoreService,
FeedbackCoreService,
FieldMappingCoreService,
FirewallProductCoreService,
FontInfoActionCoreService,
FormDesignerCoreService,
GroupAzureAdCoreService,
GroupCoreService,
HtmlFieldsSettingCoreService,
IamtCredentialCoreService,
IiCoreService,
ImpactCoreService,
ImportAuditTaskCoreService,
ImportPackageLogCoreService,
IncidentModelCoreService,
IncidentCoreService,
InfraredDeviceCoreService,
InheritLocationCharterCoreService,
InStockMovementCoreService,
InternetExplorerCoreService,
InterventionModelCoreService,
InterventionCoreService,
InventoryHistoryCoreService,
KeyboardCoreService,
LabelFormatCoreService,
LabelTemplateCoreService,
LayoutModelCoreService,
LdapAnalysisCoreService,
LdapHistoryCoreService,
LdapOrganizationalUnitCoreService,
LdapPropertyCoreService,
LicenseKeyCoreService,
LifeCycleDestinationCoreService,
LifeCycleReasonCoreService,
LifeCycleTicketCoreService,
LinkAssetCoreService,
ListDesignerCoreService,
LoanAppointmentCoreService,
LoanCoreService,
LoanStatusCoreService,
LocationCategoryCoreService,
LocationCharterCoreService,
LocationCoreService,
LogicalDiskHistoryCoreService,
LogicalDiskCoreService,
LogonHistoryCoreService,
LogonSessionCoreService,
MailboxObserverLogCoreService,
MailboxObserverTaskCoreService,
MailSlurpBlackListCoreService,
MailSlurpWebhookTaskCoreService,
MatrixPriorityCoreService,
MessageModelCoreService,
MessageCoreService,
ModelManufacturerCoreService,
ModelRulesDesignerCoreService,
MonitorCoreService,
NamingConventionHistoryCoreService,
NamingConventionCoreService,
NetworkAdapterCoreService,
NetworkCredentialCoreService,
NetworkDriveCoreService,
NewsCoreService,
NoteCoreService,
NotificationCoreService,
NtLogEventCoreService,
ObjectClassMappingCoreService,
OdbcDriverCoreService,
OneEventLogCoreService,
OneSettingCoreService,
OperatingSystemCoreService,
OperatorTeamCoreService,
OptionalFeatureCoreService,
OrderBillCoreService,
OrderCoreService,
OrganizationalUnitCategoryCoreService,
OrganizationalUnitCoreService,
OrganizationCoreService,
OriginCoreService,
OsRecoveryConfigurationCoreService,
OutStockMovementCoreService,
PackageCoreService,
PageCountCoreService,
PageFileCoreService,
ParallelPortCoreService,
PatCoreService,
PcmciaControllerCoreService,
PhysicalMemoryArrayCoreService,
PhysicalMemoryCoreService,
PnpEntityBaseCoreService,
PnpEntityCoreService,
PnpSignedDriverCoreService,
PnpVendorCoreService,
PointingDeviceCoreService,
PopulationCoreService,
PortableBatteryCoreService,
PortConnectorCoreService,
PortOpenCoreService,
PotsModemCoreService,
PrinterConsumableCoreService,
PrinterLabelCoreService,
PrinterCoreService,
PriorityCoreService,
PrivilegeCoreService,
ProblemCoreService,
ProcessActionEntityCoreService,
ProcessorCoreService,
ProcessCoreService,
ProgramCoreService,
QueryBuilderAppointmentCoreService,
QueryBuilderAppointmentTaskCoreService,
QueryBuilderCoreService,
QuickFixEngineeringCoreService,
RecipientBounceCoreService,
RegistryCoreService,
ReportCachCoreService,
ReportCoreService,
RequestCoreService,
ResolutionCategoryCoreService,
ResolutionModelCoreService,
ResultScanAzureAdCoreService,
RoleAccountCoreService,
RoleCoreService,
SatisfactionSettingCoreService,
ScanByAddressIpRangeCoreService,
ScanByAzureAdAppointmentCoreService,
ScanByAzureAdAppointmentTaskCoreService,
ScanByAzureAdCoreService,
ScanByAzureAdTaskCoreService,
ScanByHostCoreService,
ScanByLdapCoreService,
ScanByLocalAgentCoreService,
ScanByWorkgroupCoreService,
ScanConfigurationAppointmentCoreService,
ScanConfigurationCoreService,
ScanDataFileCoreService,
ScanExcludeCoreService,
ScanImportOptionCoreService,
ScanMethodProfilCoreService,
ScanMethodCoreService,
ScannerCountCoreService,
ScanRegistryKeyCoreService,
ScanRegistryCoreService,
ScheduleTaskErrorCoreService,
ScheduleTaskCoreService,
ScsiControllerCoreService,
SearchWarrantyTaskCoreService,
SecurityGroupCoreService,
SecurityGroupRuleCoreService,
SerialNumberCoreService,
SerialPortCoreService,
ServiceLevelAgreementCoreService,
ServiceOfferCoreService,
SftpConnectorHistoryCoreService,
SftpConnectorCoreService,
ShareCoreService,
SmtpConnectorSystemCoreService,
SnmpCredentialCoreService,
SnmpMapInformationKbCoreService,
SnmpMapInformationCoreService,
SnmpSummaryCoreService,
SnmpVendorCoreService,
SoftwareAttributeTypeCoreService,
SoftwareConnectionModeCoreService,
SoftwareGroupCoreService,
SoftwareHostedComponentCoreService,
SoftwareHostedLinkCoreService,
SoftwarePackageCoreService,
SoftwarePropertyCoreService,
SoftwareReferentLinkCoreService,
SoftwareReferentTypeCoreService,
SoftwareCoreService,
SoundDeviceCoreService,
SqlServerCoreService,
SshCredentialCoreService,
StatisticTaskCoreService,
StatusWorkflowCoreService,
StockInReasonCoreService,
StockOutReasonCoreService,
StockTransferReasonCoreService,
StockTransferCoreService,
SubstituteCoreService,
SupplierCoreService,
SystemAccountCoreService,
SystemEnclosureCoreService,
SystemSlotCoreService,
TapeDriveCoreService,
TaskSequenceCoreService,
TaskStatusCoreService,
TaskStatusWorkflowCoreService,
TemplateCoreService,
TermsContractCoreService,
TicketAffectedRuleCoreService,
TicketAppointmentCoreService,
TicketBacklogCoreService,
TicketCategoryCoreService,
TicketDailyBacklogCoreService,
TicketDurationTaskCoreService,
TicketEmailCoreService,
TicketEmailTaskCoreService,
TicketMonthlyBacklogCoreService,
TicketCoreService,
TicketStatisticCoreService,
TicketStatusReasonCoreService,
TicketStatusCoreService,
TicketTagCoreService,
TicketTagTaskCoreService,
TicketTaskModelCoreService,
TicketTaskCoreService,
TicketWeeklyBacklogCoreService,
TicketYearlyBacklogCoreService,
TrackingEntityCoreService,
TrackingCoreService,
UptimeCalendarCoreService,
UrgencyCoreService,
UserAccountControlCoreService,
UserAccountCoreService,
UserAffectedRuleCoreService,
UserPrivilegeCoreService,
UserProfileCoreService,
UserRoleCoreService,
UserCoreService,
VerifaliaEmailCheckMetricCoreService,
VerifaliaEmailCheckCoreService,
VerifaliaTaskCoreService,
VideoControllerCoreService,
VirtualMachineCoreService,
VlanCoreService,
WallSocketCoreService,
WarningCustomerCoreService,
WarningMessageCoreService,
WarningViewCustomerCoreService,
WarrantyLinkVendorCoreService,
WindowsFirewallCoreService,
WindowsServiceCoreService,
WindowsUpdateCoreService,
WmiCredentialCoreService,
WorkApplicationCoreService,
WorkflowActionCoreService,
WorkflowArchiveCoreService,
WorkflowDeleteCoreService,
WorkflowHttpRequestCoreService,
WorkflowIntervalTaskCoreService,
WorkflowInterventionCoreService,
WorkflowInvitationCoreService,
WorkflowInvitationTaskCoreService,
WorkflowLinkItemCoreService,
WorkflowLogCoreService,
WorkflowMailCoreService,
WorkflowMailTaskCoreService,
WorkflowManagedCoreService,
WorkflowNotificationTeamCoreService,
WorkflowPropertyCoreService,
WorkflowRevocationCoreService,
WorkflowRevocationWithoutEmailCoreService,
WorkflowCoreService,
WorkflowScheduleTaskCoreService,
WorkflowTicketTaskCoreService,
WorkTimeCoreService,
MyOrganizationCoreService,
AgentCoreService,
CommonCoreService,
LicenseCoreService,
ContactCoreService,
ClarilogLocalAgentCoreService,
FileManagerCoreService } from '@clarilog/core/services2/graphql/generated-types/services'
import { EnumCoreService } from '@clarilog/core/services2/graphql/generated-types/enum-service'
@NgModule({
	providers: [
        { provide: 'EnumCoreService', useClass: EnumCoreService },
		{ provide: 'AccessPointCoreService', useClass: AccessPointCoreService },
		{ provide: 'AccountCoreService', useClass: AccountCoreService },
		{ provide: 'ActivityCoreService', useClass: ActivityCoreService },
		{ provide: 'AffectedRuleCoreService', useClass: AffectedRuleCoreService },
		{ provide: 'AgentConfigurationCoreService', useClass: AgentConfigurationCoreService },
		{ provide: 'AgentHistoryCoreService', useClass: AgentHistoryCoreService },
		{ provide: 'AgentTokenCoreService', useClass: AgentTokenCoreService },
		{ provide: 'AlarmMailTaskCoreService', useClass: AlarmMailTaskCoreService },
		{ provide: 'AlarmCoreService', useClass: AlarmCoreService },
		{ provide: 'AlertCoreService', useClass: AlertCoreService },
		{ provide: 'AlertTaskCoreService', useClass: AlertTaskCoreService },
		{ provide: 'AnalyseScanByAzureAdTaskCoreService', useClass: AnalyseScanByAzureAdTaskCoreService },
		{ provide: 'AntiSpywareProductCoreService', useClass: AntiSpywareProductCoreService },
		{ provide: 'AntiVirusProductCoreService', useClass: AntiVirusProductCoreService },
		{ provide: 'ApplicationAzureAdCoreService', useClass: ApplicationAzureAdCoreService },
		{ provide: 'AppLogCoreService', useClass: AppLogCoreService },
		{ provide: 'AppointmentCoreService', useClass: AppointmentCoreService },
		{ provide: 'ArticleKnowledgeCategoryCoreService', useClass: ArticleKnowledgeCategoryCoreService },
		{ provide: 'ArticleKnowledgeNoteCoreService', useClass: ArticleKnowledgeNoteCoreService },
		{ provide: 'ArticleKnowledgeCoreService', useClass: ArticleKnowledgeCoreService },
		{ provide: 'AssetAccountCoreService', useClass: AssetAccountCoreService },
		{ provide: 'AssetAcquisitionModeCoreService', useClass: AssetAcquisitionModeCoreService },
		{ provide: 'AssetAcquisitionStateCoreService', useClass: AssetAcquisitionStateCoreService },
		{ provide: 'AssetAffectedRuleCoreService', useClass: AssetAffectedRuleCoreService },
		{ provide: 'AssetCategoryCoreService', useClass: AssetCategoryCoreService },
		{ provide: 'AssetCategoryRuleCoreService', useClass: AssetCategoryRuleCoreService },
		{ provide: 'AssetContractCoreService', useClass: AssetContractCoreService },
		{ provide: 'AssetCostCenterCoreService', useClass: AssetCostCenterCoreService },
		{ provide: 'AssetLoanCoreService', useClass: AssetLoanCoreService },
		{ provide: 'AssetModelCoreService', useClass: AssetModelCoreService },
		{ provide: 'AssetModelStockEntryCoreService', useClass: AssetModelStockEntryCoreService },
		{ provide: 'AssetPropertyCoreService', useClass: AssetPropertyCoreService },
		{ provide: 'AssetPropertyTrackingCoreService', useClass: AssetPropertyTrackingCoreService },
		{ provide: 'AssetProvisionalCommissioningCoreService', useClass: AssetProvisionalCommissioningCoreService },
		{ provide: 'AssetCoreService', useClass: AssetCoreService },
		{ provide: 'AssetStatusCoreService', useClass: AssetStatusCoreService },
		{ provide: 'AssetTypeAccountingCoreService', useClass: AssetTypeAccountingCoreService },
		{ provide: 'AssetTypeStaffingCoreService', useClass: AssetTypeStaffingCoreService },
		{ provide: 'AsyncTaskCoreService', useClass: AsyncTaskCoreService },
		{ provide: 'AutoRunCoreService', useClass: AutoRunCoreService },
		{ provide: 'AxelDatumCoreService', useClass: AxelDatumCoreService },
		{ provide: 'BaseBoardCoreService', useClass: BaseBoardCoreService },
		{ provide: 'BatteryCoreService', useClass: BatteryCoreService },
		{ provide: 'BioCoreService', useClass: BioCoreService },
		{ provide: 'BootConfigurationCoreService', useClass: BootConfigurationCoreService },
		{ provide: 'BrotherLabelCoreService', useClass: BrotherLabelCoreService },
		{ provide: 'BudgetCategoryCoreService', useClass: BudgetCategoryCoreService },
		{ provide: 'BudgetCoreService', useClass: BudgetCoreService },
		{ provide: 'BusinessTimeCoreService', useClass: BusinessTimeCoreService },
		{ provide: 'CalendarConnectorCoreService', useClass: CalendarConnectorCoreService },
		{ provide: 'CampaignHistoryCoreService', useClass: CampaignHistoryCoreService },
		{ provide: 'CampaignCoreService', useClass: CampaignCoreService },
		{ provide: 'CDRomDriveCoreService', useClass: CDRomDriveCoreService },
		{ provide: 'ChannelCoreService', useClass: ChannelCoreService },
		{ provide: 'ChatBotCoreService', useClass: ChatBotCoreService },
		{ provide: 'CheckFileTaskCoreService', useClass: CheckFileTaskCoreService },
		{ provide: 'CheckSizeFileTaskCoreService', useClass: CheckSizeFileTaskCoreService },
		{ provide: 'ClarilogServerCoreService', useClass: ClarilogServerCoreService },
		{ provide: 'CodecFileCoreService', useClass: CodecFileCoreService },
		{ provide: 'CommandCoreService', useClass: CommandCoreService },
		{ provide: 'CommentaryModelCoreService', useClass: CommentaryModelCoreService },
		{ provide: 'ComputerSystemProductCoreService', useClass: ComputerSystemProductCoreService },
		{ provide: 'ComputerSystemCoreService', useClass: ComputerSystemCoreService },
		{ provide: 'ConcernedProjectCoreService', useClass: ConcernedProjectCoreService },
		{ provide: 'ConsumableMovementCoreService', useClass: ConsumableMovementCoreService },
		{ provide: 'ContractCategoryCoreService', useClass: ContractCategoryCoreService },
		{ provide: 'ContractLicenseTypeCoreService', useClass: ContractLicenseTypeCoreService },
		{ provide: 'ContractCoreService', useClass: ContractCoreService },
		{ provide: 'CopyCountCoreService', useClass: CopyCountCoreService },
		{ provide: 'CorrectionStockMovementCoreService', useClass: CorrectionStockMovementCoreService },
		{ provide: 'CostCenterCoreService', useClass: CostCenterCoreService },
		{ provide: 'CronJobHourlyTaskCoreService', useClass: CronJobHourlyTaskCoreService },
		{ provide: 'CustomFieldCoreService', useClass: CustomFieldCoreService },
		{ provide: 'DashboardCoreService', useClass: DashboardCoreService },
		{ provide: 'DataFileCoreService', useClass: DataFileCoreService },
		{ provide: 'DayOffCoreService', useClass: DayOffCoreService },
		{ provide: 'DeleteFileTaskCoreService', useClass: DeleteFileTaskCoreService },
		{ provide: 'DesktopCoreService', useClass: DesktopCoreService },
		{ provide: 'DiskDriveCoreService', useClass: DiskDriveCoreService },
		{ provide: 'DiskRamInformationCoreService', useClass: DiskRamInformationCoreService },
		{ provide: 'DomainCoreService', useClass: DomainCoreService },
		{ provide: 'DynamicPropertyFieldCoreService', useClass: DynamicPropertyFieldCoreService },
		{ provide: 'DynamicPropertyGroupCoreService', useClass: DynamicPropertyGroupCoreService },
		{ provide: 'DynamicPropertyListCoreService', useClass: DynamicPropertyListCoreService },
		{ provide: 'EmailBounceCoreService', useClass: EmailBounceCoreService },
		{ provide: 'EmailConnectorCoreService', useClass: EmailConnectorCoreService },
		{ provide: 'EmailConnectorSystemLogCoreService', useClass: EmailConnectorSystemLogCoreService },
		{ provide: 'EmailConnectorSystemCoreService', useClass: EmailConnectorSystemCoreService },
		{ provide: 'EmailCredentialCoreService', useClass: EmailCredentialCoreService },
		{ provide: 'EmailPrototypeLogCoreService', useClass: EmailPrototypeLogCoreService },
		{ provide: 'EmailPrototypeCoreService', useClass: EmailPrototypeCoreService },
		{ provide: 'EncryptableVolumeCoreService', useClass: EncryptableVolumeCoreService },
		{ provide: 'EntryTableCoreService', useClass: EntryTableCoreService },
		{ provide: 'EnvironmentVariableCoreService', useClass: EnvironmentVariableCoreService },
		{ provide: 'EsxCredentialCoreService', useClass: EsxCredentialCoreService },
		{ provide: 'ExchangeConnectorCoreService', useClass: ExchangeConnectorCoreService },
		{ provide: 'FaxCountCoreService', useClass: FaxCountCoreService },
		{ provide: 'FeedbackCoreService', useClass: FeedbackCoreService },
		{ provide: 'FieldMappingCoreService', useClass: FieldMappingCoreService },
		{ provide: 'FirewallProductCoreService', useClass: FirewallProductCoreService },
		{ provide: 'FontInfoActionCoreService', useClass: FontInfoActionCoreService },
		{ provide: 'FormDesignerCoreService', useClass: FormDesignerCoreService },
		{ provide: 'GroupAzureAdCoreService', useClass: GroupAzureAdCoreService },
		{ provide: 'GroupCoreService', useClass: GroupCoreService },
		{ provide: 'HtmlFieldsSettingCoreService', useClass: HtmlFieldsSettingCoreService },
		{ provide: 'IamtCredentialCoreService', useClass: IamtCredentialCoreService },
		{ provide: 'IiCoreService', useClass: IiCoreService },
		{ provide: 'ImpactCoreService', useClass: ImpactCoreService },
		{ provide: 'ImportAuditTaskCoreService', useClass: ImportAuditTaskCoreService },
		{ provide: 'ImportPackageLogCoreService', useClass: ImportPackageLogCoreService },
		{ provide: 'IncidentModelCoreService', useClass: IncidentModelCoreService },
		{ provide: 'IncidentCoreService', useClass: IncidentCoreService },
		{ provide: 'InfraredDeviceCoreService', useClass: InfraredDeviceCoreService },
		{ provide: 'InheritLocationCharterCoreService', useClass: InheritLocationCharterCoreService },
		{ provide: 'InStockMovementCoreService', useClass: InStockMovementCoreService },
		{ provide: 'InternetExplorerCoreService', useClass: InternetExplorerCoreService },
		{ provide: 'InterventionModelCoreService', useClass: InterventionModelCoreService },
		{ provide: 'InterventionCoreService', useClass: InterventionCoreService },
		{ provide: 'InventoryHistoryCoreService', useClass: InventoryHistoryCoreService },
		{ provide: 'KeyboardCoreService', useClass: KeyboardCoreService },
		{ provide: 'LabelFormatCoreService', useClass: LabelFormatCoreService },
		{ provide: 'LabelTemplateCoreService', useClass: LabelTemplateCoreService },
		{ provide: 'LayoutModelCoreService', useClass: LayoutModelCoreService },
		{ provide: 'LdapAnalysisCoreService', useClass: LdapAnalysisCoreService },
		{ provide: 'LdapHistoryCoreService', useClass: LdapHistoryCoreService },
		{ provide: 'LdapOrganizationalUnitCoreService', useClass: LdapOrganizationalUnitCoreService },
		{ provide: 'LdapPropertyCoreService', useClass: LdapPropertyCoreService },
		{ provide: 'LicenseKeyCoreService', useClass: LicenseKeyCoreService },
		{ provide: 'LifeCycleDestinationCoreService', useClass: LifeCycleDestinationCoreService },
		{ provide: 'LifeCycleReasonCoreService', useClass: LifeCycleReasonCoreService },
		{ provide: 'LifeCycleTicketCoreService', useClass: LifeCycleTicketCoreService },
		{ provide: 'LinkAssetCoreService', useClass: LinkAssetCoreService },
		{ provide: 'ListDesignerCoreService', useClass: ListDesignerCoreService },
		{ provide: 'LoanAppointmentCoreService', useClass: LoanAppointmentCoreService },
		{ provide: 'LoanCoreService', useClass: LoanCoreService },
		{ provide: 'LoanStatusCoreService', useClass: LoanStatusCoreService },
		{ provide: 'LocationCategoryCoreService', useClass: LocationCategoryCoreService },
		{ provide: 'LocationCharterCoreService', useClass: LocationCharterCoreService },
		{ provide: 'LocationCoreService', useClass: LocationCoreService },
		{ provide: 'LogicalDiskHistoryCoreService', useClass: LogicalDiskHistoryCoreService },
		{ provide: 'LogicalDiskCoreService', useClass: LogicalDiskCoreService },
		{ provide: 'LogonHistoryCoreService', useClass: LogonHistoryCoreService },
		{ provide: 'LogonSessionCoreService', useClass: LogonSessionCoreService },
		{ provide: 'MailboxObserverLogCoreService', useClass: MailboxObserverLogCoreService },
		{ provide: 'MailboxObserverTaskCoreService', useClass: MailboxObserverTaskCoreService },
		{ provide: 'MailSlurpBlackListCoreService', useClass: MailSlurpBlackListCoreService },
		{ provide: 'MailSlurpWebhookTaskCoreService', useClass: MailSlurpWebhookTaskCoreService },
		{ provide: 'MatrixPriorityCoreService', useClass: MatrixPriorityCoreService },
		{ provide: 'MessageModelCoreService', useClass: MessageModelCoreService },
		{ provide: 'MessageCoreService', useClass: MessageCoreService },
		{ provide: 'ModelManufacturerCoreService', useClass: ModelManufacturerCoreService },
		{ provide: 'ModelRulesDesignerCoreService', useClass: ModelRulesDesignerCoreService },
		{ provide: 'MonitorCoreService', useClass: MonitorCoreService },
		{ provide: 'NamingConventionHistoryCoreService', useClass: NamingConventionHistoryCoreService },
		{ provide: 'NamingConventionCoreService', useClass: NamingConventionCoreService },
		{ provide: 'NetworkAdapterCoreService', useClass: NetworkAdapterCoreService },
		{ provide: 'NetworkCredentialCoreService', useClass: NetworkCredentialCoreService },
		{ provide: 'NetworkDriveCoreService', useClass: NetworkDriveCoreService },
		{ provide: 'NewsCoreService', useClass: NewsCoreService },
		{ provide: 'NoteCoreService', useClass: NoteCoreService },
		{ provide: 'NotificationCoreService', useClass: NotificationCoreService },
		{ provide: 'NtLogEventCoreService', useClass: NtLogEventCoreService },
		{ provide: 'ObjectClassMappingCoreService', useClass: ObjectClassMappingCoreService },
		{ provide: 'OdbcDriverCoreService', useClass: OdbcDriverCoreService },
		{ provide: 'OneEventLogCoreService', useClass: OneEventLogCoreService },
		{ provide: 'OneSettingCoreService', useClass: OneSettingCoreService },
		{ provide: 'OperatingSystemCoreService', useClass: OperatingSystemCoreService },
		{ provide: 'OperatorTeamCoreService', useClass: OperatorTeamCoreService },
		{ provide: 'OptionalFeatureCoreService', useClass: OptionalFeatureCoreService },
		{ provide: 'OrderBillCoreService', useClass: OrderBillCoreService },
		{ provide: 'OrderCoreService', useClass: OrderCoreService },
		{ provide: 'OrganizationalUnitCategoryCoreService', useClass: OrganizationalUnitCategoryCoreService },
		{ provide: 'OrganizationalUnitCoreService', useClass: OrganizationalUnitCoreService },
		{ provide: 'OrganizationCoreService', useClass: OrganizationCoreService },
		{ provide: 'OriginCoreService', useClass: OriginCoreService },
		{ provide: 'OsRecoveryConfigurationCoreService', useClass: OsRecoveryConfigurationCoreService },
		{ provide: 'OutStockMovementCoreService', useClass: OutStockMovementCoreService },
		{ provide: 'PackageCoreService', useClass: PackageCoreService },
		{ provide: 'PageCountCoreService', useClass: PageCountCoreService },
		{ provide: 'PageFileCoreService', useClass: PageFileCoreService },
		{ provide: 'ParallelPortCoreService', useClass: ParallelPortCoreService },
		{ provide: 'PatCoreService', useClass: PatCoreService },
		{ provide: 'PcmciaControllerCoreService', useClass: PcmciaControllerCoreService },
		{ provide: 'PhysicalMemoryArrayCoreService', useClass: PhysicalMemoryArrayCoreService },
		{ provide: 'PhysicalMemoryCoreService', useClass: PhysicalMemoryCoreService },
		{ provide: 'PnpEntityBaseCoreService', useClass: PnpEntityBaseCoreService },
		{ provide: 'PnpEntityCoreService', useClass: PnpEntityCoreService },
		{ provide: 'PnpSignedDriverCoreService', useClass: PnpSignedDriverCoreService },
		{ provide: 'PnpVendorCoreService', useClass: PnpVendorCoreService },
		{ provide: 'PointingDeviceCoreService', useClass: PointingDeviceCoreService },
		{ provide: 'PopulationCoreService', useClass: PopulationCoreService },
		{ provide: 'PortableBatteryCoreService', useClass: PortableBatteryCoreService },
		{ provide: 'PortConnectorCoreService', useClass: PortConnectorCoreService },
		{ provide: 'PortOpenCoreService', useClass: PortOpenCoreService },
		{ provide: 'PotsModemCoreService', useClass: PotsModemCoreService },
		{ provide: 'PrinterConsumableCoreService', useClass: PrinterConsumableCoreService },
		{ provide: 'PrinterLabelCoreService', useClass: PrinterLabelCoreService },
		{ provide: 'PrinterCoreService', useClass: PrinterCoreService },
		{ provide: 'PriorityCoreService', useClass: PriorityCoreService },
		{ provide: 'PrivilegeCoreService', useClass: PrivilegeCoreService },
		{ provide: 'ProblemCoreService', useClass: ProblemCoreService },
		{ provide: 'ProcessActionEntityCoreService', useClass: ProcessActionEntityCoreService },
		{ provide: 'ProcessorCoreService', useClass: ProcessorCoreService },
		{ provide: 'ProcessCoreService', useClass: ProcessCoreService },
		{ provide: 'ProgramCoreService', useClass: ProgramCoreService },
		{ provide: 'QueryBuilderAppointmentCoreService', useClass: QueryBuilderAppointmentCoreService },
		{ provide: 'QueryBuilderAppointmentTaskCoreService', useClass: QueryBuilderAppointmentTaskCoreService },
		{ provide: 'QueryBuilderCoreService', useClass: QueryBuilderCoreService },
		{ provide: 'QuickFixEngineeringCoreService', useClass: QuickFixEngineeringCoreService },
		{ provide: 'RecipientBounceCoreService', useClass: RecipientBounceCoreService },
		{ provide: 'RegistryCoreService', useClass: RegistryCoreService },
		{ provide: 'ReportCachCoreService', useClass: ReportCachCoreService },
		{ provide: 'ReportCoreService', useClass: ReportCoreService },
		{ provide: 'RequestCoreService', useClass: RequestCoreService },
		{ provide: 'ResolutionCategoryCoreService', useClass: ResolutionCategoryCoreService },
		{ provide: 'ResolutionModelCoreService', useClass: ResolutionModelCoreService },
		{ provide: 'ResultScanAzureAdCoreService', useClass: ResultScanAzureAdCoreService },
		{ provide: 'RoleAccountCoreService', useClass: RoleAccountCoreService },
		{ provide: 'RoleCoreService', useClass: RoleCoreService },
		{ provide: 'SatisfactionSettingCoreService', useClass: SatisfactionSettingCoreService },
		{ provide: 'ScanByAddressIpRangeCoreService', useClass: ScanByAddressIpRangeCoreService },
		{ provide: 'ScanByAzureAdAppointmentCoreService', useClass: ScanByAzureAdAppointmentCoreService },
		{ provide: 'ScanByAzureAdAppointmentTaskCoreService', useClass: ScanByAzureAdAppointmentTaskCoreService },
		{ provide: 'ScanByAzureAdCoreService', useClass: ScanByAzureAdCoreService },
		{ provide: 'ScanByAzureAdTaskCoreService', useClass: ScanByAzureAdTaskCoreService },
		{ provide: 'ScanByHostCoreService', useClass: ScanByHostCoreService },
		{ provide: 'ScanByLdapCoreService', useClass: ScanByLdapCoreService },
		{ provide: 'ScanByLocalAgentCoreService', useClass: ScanByLocalAgentCoreService },
		{ provide: 'ScanByWorkgroupCoreService', useClass: ScanByWorkgroupCoreService },
		{ provide: 'ScanConfigurationAppointmentCoreService', useClass: ScanConfigurationAppointmentCoreService },
		{ provide: 'ScanConfigurationCoreService', useClass: ScanConfigurationCoreService },
		{ provide: 'ScanDataFileCoreService', useClass: ScanDataFileCoreService },
		{ provide: 'ScanExcludeCoreService', useClass: ScanExcludeCoreService },
		{ provide: 'ScanImportOptionCoreService', useClass: ScanImportOptionCoreService },
		{ provide: 'ScanMethodProfilCoreService', useClass: ScanMethodProfilCoreService },
		{ provide: 'ScanMethodCoreService', useClass: ScanMethodCoreService },
		{ provide: 'ScannerCountCoreService', useClass: ScannerCountCoreService },
		{ provide: 'ScanRegistryKeyCoreService', useClass: ScanRegistryKeyCoreService },
		{ provide: 'ScanRegistryCoreService', useClass: ScanRegistryCoreService },
		{ provide: 'ScheduleTaskErrorCoreService', useClass: ScheduleTaskErrorCoreService },
		{ provide: 'ScheduleTaskCoreService', useClass: ScheduleTaskCoreService },
		{ provide: 'ScsiControllerCoreService', useClass: ScsiControllerCoreService },
		{ provide: 'SearchWarrantyTaskCoreService', useClass: SearchWarrantyTaskCoreService },
		{ provide: 'SecurityGroupCoreService', useClass: SecurityGroupCoreService },
		{ provide: 'SecurityGroupRuleCoreService', useClass: SecurityGroupRuleCoreService },
		{ provide: 'SerialNumberCoreService', useClass: SerialNumberCoreService },
		{ provide: 'SerialPortCoreService', useClass: SerialPortCoreService },
		{ provide: 'ServiceLevelAgreementCoreService', useClass: ServiceLevelAgreementCoreService },
		{ provide: 'ServiceOfferCoreService', useClass: ServiceOfferCoreService },
		{ provide: 'SftpConnectorHistoryCoreService', useClass: SftpConnectorHistoryCoreService },
		{ provide: 'SftpConnectorCoreService', useClass: SftpConnectorCoreService },
		{ provide: 'ShareCoreService', useClass: ShareCoreService },
		{ provide: 'SmtpConnectorSystemCoreService', useClass: SmtpConnectorSystemCoreService },
		{ provide: 'SnmpCredentialCoreService', useClass: SnmpCredentialCoreService },
		{ provide: 'SnmpMapInformationKbCoreService', useClass: SnmpMapInformationKbCoreService },
		{ provide: 'SnmpMapInformationCoreService', useClass: SnmpMapInformationCoreService },
		{ provide: 'SnmpSummaryCoreService', useClass: SnmpSummaryCoreService },
		{ provide: 'SnmpVendorCoreService', useClass: SnmpVendorCoreService },
		{ provide: 'SoftwareAttributeTypeCoreService', useClass: SoftwareAttributeTypeCoreService },
		{ provide: 'SoftwareConnectionModeCoreService', useClass: SoftwareConnectionModeCoreService },
		{ provide: 'SoftwareGroupCoreService', useClass: SoftwareGroupCoreService },
		{ provide: 'SoftwareHostedComponentCoreService', useClass: SoftwareHostedComponentCoreService },
		{ provide: 'SoftwareHostedLinkCoreService', useClass: SoftwareHostedLinkCoreService },
		{ provide: 'SoftwarePackageCoreService', useClass: SoftwarePackageCoreService },
		{ provide: 'SoftwarePropertyCoreService', useClass: SoftwarePropertyCoreService },
		{ provide: 'SoftwareReferentLinkCoreService', useClass: SoftwareReferentLinkCoreService },
		{ provide: 'SoftwareReferentTypeCoreService', useClass: SoftwareReferentTypeCoreService },
		{ provide: 'SoftwareCoreService', useClass: SoftwareCoreService },
		{ provide: 'SoundDeviceCoreService', useClass: SoundDeviceCoreService },
		{ provide: 'SqlServerCoreService', useClass: SqlServerCoreService },
		{ provide: 'SshCredentialCoreService', useClass: SshCredentialCoreService },
		{ provide: 'StatisticTaskCoreService', useClass: StatisticTaskCoreService },
		{ provide: 'StatusWorkflowCoreService', useClass: StatusWorkflowCoreService },
		{ provide: 'StockInReasonCoreService', useClass: StockInReasonCoreService },
		{ provide: 'StockOutReasonCoreService', useClass: StockOutReasonCoreService },
		{ provide: 'StockTransferReasonCoreService', useClass: StockTransferReasonCoreService },
		{ provide: 'StockTransferCoreService', useClass: StockTransferCoreService },
		{ provide: 'SubstituteCoreService', useClass: SubstituteCoreService },
		{ provide: 'SupplierCoreService', useClass: SupplierCoreService },
		{ provide: 'SystemAccountCoreService', useClass: SystemAccountCoreService },
		{ provide: 'SystemEnclosureCoreService', useClass: SystemEnclosureCoreService },
		{ provide: 'SystemSlotCoreService', useClass: SystemSlotCoreService },
		{ provide: 'TapeDriveCoreService', useClass: TapeDriveCoreService },
		{ provide: 'TaskSequenceCoreService', useClass: TaskSequenceCoreService },
		{ provide: 'TaskStatusCoreService', useClass: TaskStatusCoreService },
		{ provide: 'TaskStatusWorkflowCoreService', useClass: TaskStatusWorkflowCoreService },
		{ provide: 'TemplateCoreService', useClass: TemplateCoreService },
		{ provide: 'TermsContractCoreService', useClass: TermsContractCoreService },
		{ provide: 'TicketAffectedRuleCoreService', useClass: TicketAffectedRuleCoreService },
		{ provide: 'TicketAppointmentCoreService', useClass: TicketAppointmentCoreService },
		{ provide: 'TicketBacklogCoreService', useClass: TicketBacklogCoreService },
		{ provide: 'TicketCategoryCoreService', useClass: TicketCategoryCoreService },
		{ provide: 'TicketDailyBacklogCoreService', useClass: TicketDailyBacklogCoreService },
		{ provide: 'TicketDurationTaskCoreService', useClass: TicketDurationTaskCoreService },
		{ provide: 'TicketEmailCoreService', useClass: TicketEmailCoreService },
		{ provide: 'TicketEmailTaskCoreService', useClass: TicketEmailTaskCoreService },
		{ provide: 'TicketMonthlyBacklogCoreService', useClass: TicketMonthlyBacklogCoreService },
		{ provide: 'TicketCoreService', useClass: TicketCoreService },
		{ provide: 'TicketStatisticCoreService', useClass: TicketStatisticCoreService },
		{ provide: 'TicketStatusReasonCoreService', useClass: TicketStatusReasonCoreService },
		{ provide: 'TicketStatusCoreService', useClass: TicketStatusCoreService },
		{ provide: 'TicketTagCoreService', useClass: TicketTagCoreService },
		{ provide: 'TicketTagTaskCoreService', useClass: TicketTagTaskCoreService },
		{ provide: 'TicketTaskModelCoreService', useClass: TicketTaskModelCoreService },
		{ provide: 'TicketTaskCoreService', useClass: TicketTaskCoreService },
		{ provide: 'TicketWeeklyBacklogCoreService', useClass: TicketWeeklyBacklogCoreService },
		{ provide: 'TicketYearlyBacklogCoreService', useClass: TicketYearlyBacklogCoreService },
		{ provide: 'TrackingEntityCoreService', useClass: TrackingEntityCoreService },
		{ provide: 'TrackingCoreService', useClass: TrackingCoreService },
		{ provide: 'UptimeCalendarCoreService', useClass: UptimeCalendarCoreService },
		{ provide: 'UrgencyCoreService', useClass: UrgencyCoreService },
		{ provide: 'UserAccountControlCoreService', useClass: UserAccountControlCoreService },
		{ provide: 'UserAccountCoreService', useClass: UserAccountCoreService },
		{ provide: 'UserAffectedRuleCoreService', useClass: UserAffectedRuleCoreService },
		{ provide: 'UserPrivilegeCoreService', useClass: UserPrivilegeCoreService },
		{ provide: 'UserProfileCoreService', useClass: UserProfileCoreService },
		{ provide: 'UserRoleCoreService', useClass: UserRoleCoreService },
		{ provide: 'UserCoreService', useClass: UserCoreService },
		{ provide: 'VerifaliaEmailCheckMetricCoreService', useClass: VerifaliaEmailCheckMetricCoreService },
		{ provide: 'VerifaliaEmailCheckCoreService', useClass: VerifaliaEmailCheckCoreService },
		{ provide: 'VerifaliaTaskCoreService', useClass: VerifaliaTaskCoreService },
		{ provide: 'VideoControllerCoreService', useClass: VideoControllerCoreService },
		{ provide: 'VirtualMachineCoreService', useClass: VirtualMachineCoreService },
		{ provide: 'VlanCoreService', useClass: VlanCoreService },
		{ provide: 'WallSocketCoreService', useClass: WallSocketCoreService },
		{ provide: 'WarningCustomerCoreService', useClass: WarningCustomerCoreService },
		{ provide: 'WarningMessageCoreService', useClass: WarningMessageCoreService },
		{ provide: 'WarningViewCustomerCoreService', useClass: WarningViewCustomerCoreService },
		{ provide: 'WarrantyLinkVendorCoreService', useClass: WarrantyLinkVendorCoreService },
		{ provide: 'WindowsFirewallCoreService', useClass: WindowsFirewallCoreService },
		{ provide: 'WindowsServiceCoreService', useClass: WindowsServiceCoreService },
		{ provide: 'WindowsUpdateCoreService', useClass: WindowsUpdateCoreService },
		{ provide: 'WmiCredentialCoreService', useClass: WmiCredentialCoreService },
		{ provide: 'WorkApplicationCoreService', useClass: WorkApplicationCoreService },
		{ provide: 'WorkflowActionCoreService', useClass: WorkflowActionCoreService },
		{ provide: 'WorkflowArchiveCoreService', useClass: WorkflowArchiveCoreService },
		{ provide: 'WorkflowDeleteCoreService', useClass: WorkflowDeleteCoreService },
		{ provide: 'WorkflowHttpRequestCoreService', useClass: WorkflowHttpRequestCoreService },
		{ provide: 'WorkflowIntervalTaskCoreService', useClass: WorkflowIntervalTaskCoreService },
		{ provide: 'WorkflowInterventionCoreService', useClass: WorkflowInterventionCoreService },
		{ provide: 'WorkflowInvitationCoreService', useClass: WorkflowInvitationCoreService },
		{ provide: 'WorkflowInvitationTaskCoreService', useClass: WorkflowInvitationTaskCoreService },
		{ provide: 'WorkflowLinkItemCoreService', useClass: WorkflowLinkItemCoreService },
		{ provide: 'WorkflowLogCoreService', useClass: WorkflowLogCoreService },
		{ provide: 'WorkflowMailCoreService', useClass: WorkflowMailCoreService },
		{ provide: 'WorkflowMailTaskCoreService', useClass: WorkflowMailTaskCoreService },
		{ provide: 'WorkflowManagedCoreService', useClass: WorkflowManagedCoreService },
		{ provide: 'WorkflowNotificationTeamCoreService', useClass: WorkflowNotificationTeamCoreService },
		{ provide: 'WorkflowPropertyCoreService', useClass: WorkflowPropertyCoreService },
		{ provide: 'WorkflowRevocationCoreService', useClass: WorkflowRevocationCoreService },
		{ provide: 'WorkflowRevocationWithoutEmailCoreService', useClass: WorkflowRevocationWithoutEmailCoreService },
		{ provide: 'WorkflowCoreService', useClass: WorkflowCoreService },
		{ provide: 'WorkflowScheduleTaskCoreService', useClass: WorkflowScheduleTaskCoreService },
		{ provide: 'WorkflowTicketTaskCoreService', useClass: WorkflowTicketTaskCoreService },
		{ provide: 'WorkTimeCoreService', useClass: WorkTimeCoreService },
		{ provide: 'MyOrganizationCoreService', useClass: MyOrganizationCoreService },
		{ provide: 'AgentCoreService', useClass: AgentCoreService },
		{ provide: 'CommonCoreService', useClass: CommonCoreService },
		{ provide: 'LicenseCoreService', useClass: LicenseCoreService },
		{ provide: 'ContactCoreService', useClass: ContactCoreService },
		{ provide: 'ClarilogLocalAgentCoreService', useClass: ClarilogLocalAgentCoreService },
		{ provide: 'FileManagerCoreService', useClass: FileManagerCoreService }
    ],
})
export class SharedCoreServicesModule { }