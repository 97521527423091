import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetProcessActionEntitiesBaseVariables, FirstProcessActionEntitiesBaseVariables, CountProcessActionEntitiesBaseVariables, FindProcessActionEntitiesBaseVariables, SearchProcessActionEntitiesBaseVariables, ExportSchemaProcessActionEntitiesBaseVariables, ExportDataProcessActionEntitiesBaseVariables, CustomQueryProcessActionEntitiesBaseVariables, CustomQueryIdProcessActionEntitiesBaseVariables, UsedProcessActionEntitiesBaseVariables, ExistProcessActionEntitiesBaseVariables, InsertProcessActionEntitiesBaseVariables, UpdateProcessActionEntitiesBaseVariables, ImportDataProcessActionEntitiesBaseVariables, UpdateManyProcessActionEntitiesBaseVariables, DeleteProcessActionEntitiesBaseVariables } from '../gqls'
import { GetProcessActionEntitiesDocument, FirstProcessActionEntitiesDocument, CountProcessActionEntitiesDocument, FindProcessActionEntitiesDocument, SearchProcessActionEntitiesDocument, ExportSchemaProcessActionEntitiesDocument, ExportDataProcessActionEntitiesDocument, CustomQueryProcessActionEntitiesDocument, CustomQueryIdProcessActionEntitiesDocument, UsedProcessActionEntitiesDocument, ExistProcessActionEntitiesDocument, InsertProcessActionEntitiesDocument, UpdateProcessActionEntitiesDocument, ImportDataProcessActionEntitiesDocument, UpdateManyProcessActionEntitiesDocument, DeleteProcessActionEntitiesDocument } from '../gqls'
import { ServiceSingleResultOfProcessActionEntity, QueryContextOfProcessActionEntity, FilterOfProcessActionEntity, ServiceSingleResultOfInt64, ServiceListResultOfProcessActionEntity, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ProcessActionEntityInput, FieldUpdateOperatorOfProcessActionEntity } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ProcessActionEntityBaseService {
    
public modelName = 'processActionEntity';
public modelPluralName = 'processActionEntities';

	private getProcessActionEntitiesQuery: GetProcessActionEntitiesDocument;
	private firstProcessActionEntitiesQuery: FirstProcessActionEntitiesDocument;
	private countProcessActionEntitiesQuery: CountProcessActionEntitiesDocument;
	private findProcessActionEntitiesQuery: FindProcessActionEntitiesDocument;
	private searchProcessActionEntitiesQuery: SearchProcessActionEntitiesDocument;
	private exportSchemaProcessActionEntitiesQuery: ExportSchemaProcessActionEntitiesDocument;
	private exportDataProcessActionEntitiesQuery: ExportDataProcessActionEntitiesDocument;
	private customQueryProcessActionEntitiesQuery: CustomQueryProcessActionEntitiesDocument;
	private customQueryIdProcessActionEntitiesQuery: CustomQueryIdProcessActionEntitiesDocument;
	private usedProcessActionEntitiesQuery: UsedProcessActionEntitiesDocument;
	private existProcessActionEntitiesQuery: ExistProcessActionEntitiesDocument;
	private insertProcessActionEntitiesMutation: InsertProcessActionEntitiesDocument;
	private updateProcessActionEntitiesMutation: UpdateProcessActionEntitiesDocument;
	private importDataProcessActionEntitiesMutation: ImportDataProcessActionEntitiesDocument;
	private updateManyProcessActionEntitiesMutation: UpdateManyProcessActionEntitiesDocument;
	private deleteProcessActionEntitiesMutation: DeleteProcessActionEntitiesDocument;

	constructor(private injector: Injector) {
		this.getProcessActionEntitiesQuery = this.injector.get(GetProcessActionEntitiesDocument);
		this.firstProcessActionEntitiesQuery = this.injector.get(FirstProcessActionEntitiesDocument);
		this.countProcessActionEntitiesQuery = this.injector.get(CountProcessActionEntitiesDocument);
		this.findProcessActionEntitiesQuery = this.injector.get(FindProcessActionEntitiesDocument);
		this.searchProcessActionEntitiesQuery = this.injector.get(SearchProcessActionEntitiesDocument);
		this.exportSchemaProcessActionEntitiesQuery = this.injector.get(ExportSchemaProcessActionEntitiesDocument);
		this.exportDataProcessActionEntitiesQuery = this.injector.get(ExportDataProcessActionEntitiesDocument);
		this.customQueryProcessActionEntitiesQuery = this.injector.get(CustomQueryProcessActionEntitiesDocument);
		this.customQueryIdProcessActionEntitiesQuery = this.injector.get(CustomQueryIdProcessActionEntitiesDocument);
		this.usedProcessActionEntitiesQuery = this.injector.get(UsedProcessActionEntitiesDocument);
		this.existProcessActionEntitiesQuery = this.injector.get(ExistProcessActionEntitiesDocument);
		this.insertProcessActionEntitiesMutation = this.injector.get(InsertProcessActionEntitiesDocument);
		this.updateProcessActionEntitiesMutation = this.injector.get(UpdateProcessActionEntitiesDocument);
		this.importDataProcessActionEntitiesMutation = this.injector.get(ImportDataProcessActionEntitiesDocument);
		this.updateManyProcessActionEntitiesMutation = this.injector.get(UpdateManyProcessActionEntitiesDocument);
		this.deleteProcessActionEntitiesMutation = this.injector.get(DeleteProcessActionEntitiesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfProcessActionEntity> {
    
    		let variables: GetProcessActionEntitiesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.get));
            }
            else{
                let result:ServiceSingleResultOfProcessActionEntity={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfProcessActionEntity,
		@Args('filter?') filter?: FilterOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfProcessActionEntity> {
    
    		let variables: FirstProcessActionEntitiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountProcessActionEntitiesBaseVariables = {
    			filter: filter
    		}
    				return this.countProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfProcessActionEntity,
		@Args('filter?') filter?: FilterOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProcessActionEntity> {
    
    		let variables: FindProcessActionEntitiesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfProcessActionEntity,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProcessActionEntity> {
    
    		let variables: SearchProcessActionEntitiesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaProcessActionEntitiesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataProcessActionEntitiesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfProcessActionEntity,
		@Args('filter?') filter?: FilterOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProcessActionEntity> {
    
    		let variables: CustomQueryProcessActionEntitiesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfProcessActionEntity,
		@Args('filter?') filter?: FilterOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProcessActionEntity> {
    
    		let variables: CustomQueryIdProcessActionEntitiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedProcessActionEntitiesBaseVariables = {
    			ids: ids
    		}
    				return this.usedProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistProcessActionEntitiesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existProcessActionEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ProcessActionEntityInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfProcessActionEntity> {
    
    		let variables: InsertProcessActionEntitiesBaseVariables = {
    			entity: entity
    		}
    				return this.insertProcessActionEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfProcessActionEntity> {
    
    		let variables: UpdateProcessActionEntitiesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateProcessActionEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataProcessActionEntitiesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataProcessActionEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfProcessActionEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyProcessActionEntitiesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyProcessActionEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteProcessActionEntitiesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteProcessActionEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.processActionEntities.delete));
    	}
    
}