import { Component } from '@angular/core';
import { Router, Route, UrlSegment, UrlTree } from '@angular/router';
import { CommonCoreService } from '@clarilog/core/services2/graphql/generated-types/services/common.service';

/** Représente la page de page non trouvé. */
@Component({
  selector: 'cl-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
  visible: boolean = false;
  checkedSegments: string[] = [];
  constructor(private router: Router) {
    let urlTree: UrlTree = this.router.parseUrl(this.router.url);
    let segments = urlTree.root.children.primary.segments;
    let currentRoute = router.config[0].children[0];

    for (let segment of segments) {
      let childs = this.getChildrenRoute(currentRoute);
      let checkElement = childs.find(
        (x) => x.path.toLowerCase() == segment.path.toLowerCase(),
      );
      if (
        checkElement != undefined ||
        segment.path == 'edit' ||
        CommonCoreService.isGuid(segment.path)
      ) {
        this.checkedSegments.push(
          checkElement != undefined ? checkElement.path : segment.path,
        );
        currentRoute = checkElement != undefined ? checkElement : segment;
      } else {
        this.checkedSegments = undefined;
        break;
      }
    }
    if (this.checkedSegments != undefined) {
      this.router.navigate(['/' + this.checkedSegments.join('/')], {
        queryParams: urlTree.queryParams,
      });
    } else {
      this.visible = true;
    }
  }

  private getChildrenRoute(route: Route) {
    if (route.children !== undefined && route.children.length > 0) {
      return route.children;
    } else if (
      route['_loadedRoutes'] !== undefined &&
      route['_loadedRoutes'][0].children.length > 0
    ) {
      return route['_loadedRoutes'][0].children;
    }
    return [];
  }
}
