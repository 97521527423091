import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowInterventionsBaseVariables, FirstWorkflowInterventionsBaseVariables, CountWorkflowInterventionsBaseVariables, FindWorkflowInterventionsBaseVariables, SearchWorkflowInterventionsBaseVariables, ExportSchemaWorkflowInterventionsBaseVariables, ExportDataWorkflowInterventionsBaseVariables, CustomQueryWorkflowInterventionsBaseVariables, CustomQueryIdWorkflowInterventionsBaseVariables, UsedWorkflowInterventionsBaseVariables, ExistWorkflowInterventionsBaseVariables, InsertWorkflowInterventionsBaseVariables, UpdateWorkflowInterventionsBaseVariables, ImportDataWorkflowInterventionsBaseVariables, UpdateManyWorkflowInterventionsBaseVariables, DeleteWorkflowInterventionsBaseVariables } from '../gqls'
import { GetWorkflowInterventionsDocument, FirstWorkflowInterventionsDocument, CountWorkflowInterventionsDocument, FindWorkflowInterventionsDocument, SearchWorkflowInterventionsDocument, ExportSchemaWorkflowInterventionsDocument, ExportDataWorkflowInterventionsDocument, CustomQueryWorkflowInterventionsDocument, CustomQueryIdWorkflowInterventionsDocument, UsedWorkflowInterventionsDocument, ExistWorkflowInterventionsDocument, InsertWorkflowInterventionsDocument, UpdateWorkflowInterventionsDocument, ImportDataWorkflowInterventionsDocument, UpdateManyWorkflowInterventionsDocument, DeleteWorkflowInterventionsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowIntervention, QueryContextOfWorkflowIntervention, FilterOfWorkflowIntervention, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowIntervention, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowInterventionInput, FieldUpdateOperatorOfWorkflowIntervention } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowInterventionBaseService {
    
public modelName = 'workflowIntervention';
public modelPluralName = 'workflowInterventions';

	private getWorkflowInterventionsQuery: GetWorkflowInterventionsDocument;
	private firstWorkflowInterventionsQuery: FirstWorkflowInterventionsDocument;
	private countWorkflowInterventionsQuery: CountWorkflowInterventionsDocument;
	private findWorkflowInterventionsQuery: FindWorkflowInterventionsDocument;
	private searchWorkflowInterventionsQuery: SearchWorkflowInterventionsDocument;
	private exportSchemaWorkflowInterventionsQuery: ExportSchemaWorkflowInterventionsDocument;
	private exportDataWorkflowInterventionsQuery: ExportDataWorkflowInterventionsDocument;
	private customQueryWorkflowInterventionsQuery: CustomQueryWorkflowInterventionsDocument;
	private customQueryIdWorkflowInterventionsQuery: CustomQueryIdWorkflowInterventionsDocument;
	private usedWorkflowInterventionsQuery: UsedWorkflowInterventionsDocument;
	private existWorkflowInterventionsQuery: ExistWorkflowInterventionsDocument;
	private insertWorkflowInterventionsMutation: InsertWorkflowInterventionsDocument;
	private updateWorkflowInterventionsMutation: UpdateWorkflowInterventionsDocument;
	private importDataWorkflowInterventionsMutation: ImportDataWorkflowInterventionsDocument;
	private updateManyWorkflowInterventionsMutation: UpdateManyWorkflowInterventionsDocument;
	private deleteWorkflowInterventionsMutation: DeleteWorkflowInterventionsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowInterventionsQuery = this.injector.get(GetWorkflowInterventionsDocument);
		this.firstWorkflowInterventionsQuery = this.injector.get(FirstWorkflowInterventionsDocument);
		this.countWorkflowInterventionsQuery = this.injector.get(CountWorkflowInterventionsDocument);
		this.findWorkflowInterventionsQuery = this.injector.get(FindWorkflowInterventionsDocument);
		this.searchWorkflowInterventionsQuery = this.injector.get(SearchWorkflowInterventionsDocument);
		this.exportSchemaWorkflowInterventionsQuery = this.injector.get(ExportSchemaWorkflowInterventionsDocument);
		this.exportDataWorkflowInterventionsQuery = this.injector.get(ExportDataWorkflowInterventionsDocument);
		this.customQueryWorkflowInterventionsQuery = this.injector.get(CustomQueryWorkflowInterventionsDocument);
		this.customQueryIdWorkflowInterventionsQuery = this.injector.get(CustomQueryIdWorkflowInterventionsDocument);
		this.usedWorkflowInterventionsQuery = this.injector.get(UsedWorkflowInterventionsDocument);
		this.existWorkflowInterventionsQuery = this.injector.get(ExistWorkflowInterventionsDocument);
		this.insertWorkflowInterventionsMutation = this.injector.get(InsertWorkflowInterventionsDocument);
		this.updateWorkflowInterventionsMutation = this.injector.get(UpdateWorkflowInterventionsDocument);
		this.importDataWorkflowInterventionsMutation = this.injector.get(ImportDataWorkflowInterventionsDocument);
		this.updateManyWorkflowInterventionsMutation = this.injector.get(UpdateManyWorkflowInterventionsDocument);
		this.deleteWorkflowInterventionsMutation = this.injector.get(DeleteWorkflowInterventionsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowIntervention> {
    
    		let variables: GetWorkflowInterventionsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowIntervention={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowIntervention,
		@Args('filter?') filter?: FilterOfWorkflowIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowIntervention> {
    
    		let variables: FirstWorkflowInterventionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowInterventionsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowIntervention,
		@Args('filter?') filter?: FilterOfWorkflowIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowIntervention> {
    
    		let variables: FindWorkflowInterventionsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowIntervention,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowIntervention> {
    
    		let variables: SearchWorkflowInterventionsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowInterventionsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowInterventionsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowIntervention,
		@Args('filter?') filter?: FilterOfWorkflowIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowIntervention> {
    
    		let variables: CustomQueryWorkflowInterventionsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowIntervention,
		@Args('filter?') filter?: FilterOfWorkflowIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowIntervention> {
    
    		let variables: CustomQueryIdWorkflowInterventionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowInterventionsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowInterventionsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowInterventionInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowIntervention> {
    
    		let variables: InsertWorkflowInterventionsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowIntervention> {
    
    		let variables: UpdateWorkflowInterventionsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowInterventionsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowInterventionsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowInterventionsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInterventions.delete));
    	}
    
}