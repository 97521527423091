import { TaskStatusWorkflowBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { LogicDiagramView } from '@clarilog/shared2/components/logic-diagram/logic-diagram.component';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-workflow-task-status')
export class TaskStatusWorkflowCoreService extends TaskStatusWorkflowBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getService() {
    return this;
  }

  /** Obtient l'affichage de l'edition */
  public getLogicDiagramView(): LogicDiagramView {
    return {
      type: 'workflow-task',
      isEnding: true,
      allOperator: true,
      affectedTeam: true,
      operatorAffected: true,
      isTakingCharge: false,
      isEndTreatment: false,
      isCommentaryRequired: false,
      allTaskFinished: false,
      canAddSatisfaction: false,
      isForbiddenPredecessor: true,
      referentTeam: false,
      operatorReferent: false,
      allConcerned: false,
      makeRequest: false,
      affected: false,
      manager: false,
      isApplySla: false,
    };
  }
}
