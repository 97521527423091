import { QueryBuilderBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { CoreFeatureService } from '@clarilog/shared2/services/features/feature.service';
import { TranslateService } from '@clarilog/shared';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  FilterOfQueryBuilder,
  QueryContextOfQueryBuilder,
  ServiceListResultOfQueryBuilder,
  Sort,
} from '../types';
import { GqlField, GqlSubField } from '../helpers';
import { Observable, of } from 'rxjs';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { Args } from '@clarilog/core/modules';
import { map } from 'rxjs/operators';
import { UserCoreService } from './user.service';
import { field } from 'devexpress-reporting/scopes/reporting-designer-controls-pivotGrid-metadata';

export interface QueryBuilderType {
  /** Obtient ou définit le nom du modele (entitytype) */
  type: string;
  /** Obtient ou définit la traduction */
  translatedName: string;
  /** Obtient ou définit les métiers associé */
  features: string[];
  /** Obtient ou définit le lien d'acces */
  link: string;
  /** Obtient ou définit la clé de l'id */
  linkKey: string;
  /** Colonne obligatoire dans la requete */
  dependentColumns: string[];
}

@Injectable({ providedIn: 'root' })
@Authorize('query-builder')
export class QueryBuilderCoreService extends QueryBuilderBaseService {
  constructor(
    injector: Injector,
    public featureService: CoreFeatureService,
    private serviceInjector: Injector,
    public userService: UserCoreService,
  ) {
    super(injector);
  }

  findTypesAsync(checkLicence: boolean) {
    return of(this.findTypes(checkLicence));
  }

  /** Obtient les type disponible */
  public async findTypes(checkLicence: boolean): Promise<QueryBuilderType[]> {
    let translateNameAsset = TranslateService.get(
      'entities/asset/_title/singular',
    );
    let translatedNameTicket = TranslateService.get(
      'entities/ticket/_title/singular',
    );
    var types: QueryBuilderType[] = [
      {
        type: 'asset',
        translatedName: translateNameAsset,
        features: ['assets'],
        link: 'assets',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'program',
        translatedName: `${translateNameAsset} > ${TranslateService.get(
          'entities/program/_title/singular',
        )}`,
        features: ['assets', 'softwares'],
        link: 'softwares/software',
        linkKey: 'softwareId',
        dependentColumns: [],
      },
      {
        type: 'operatingSystem',
        translatedName: `${translateNameAsset} > ${TranslateService.get(
          'entities/operatingSystem/_title/singular',
        )}`,
        features: ['assets', 'softwares'],
        link: 'softwares/operatingSystems',
        linkKey: 'softwareId',
        dependentColumns: [],
      },
      {
        type: 'outOfPark',
        translatedName: `${translateNameAsset} > ${TranslateService.get(
          'entities/asset/outOfPark',
        )}`,
        features: ['assets'],
        link: 'assets/out-of-park',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'printerConsumable',
        translatedName: `${translateNameAsset} > ${TranslateService.get(
          'entities/printerConsumable/printer-consumables-level',
        )}`,
        features: ['assets', 'printerConsumable'],
        link: 'assets',
        linkKey: 'assetId',
        dependentColumns: ['maxLevel', 'colorCode'],
      },
      {
        type: 'pageCount',
        translatedName: `${translateNameAsset} > ${TranslateService.get(
          'entities/pageCount/_title/plural',
        )}`,
        features: ['assets', 'pageCounts'],
        link: 'assets',
        linkKey: 'assetId',
        dependentColumns: ['lastEntry'],
      },
      {
        type: 'copyCount',
        translatedName: `${translateNameAsset} > ${TranslateService.get(
          'entities/copyCount/_title/plural',
        )}`,
        features: ['assets', 'copyCounts'],
        link: 'assets',
        linkKey: 'assetId',
        dependentColumns: ['lastEntry'],
      },
      {
        type: 'faxCount',
        translatedName: `${translateNameAsset} > ${TranslateService.get(
          'entities/faxCount/_title/plural',
        )}`,
        features: ['assets', 'faxCounts'],
        link: 'assets',
        linkKey: 'assetId',
        dependentColumns: ['lastEntry'],
      },
      {
        type: 'scannerCount',
        translatedName: `${translateNameAsset} > ${TranslateService.get(
          'entities/scannerCount/_title/plural',
        )}`,
        features: ['assets', 'scannerCounts'],
        link: 'assets',
        linkKey: 'assetId',
        dependentColumns: ['lastEntry'],
      },
      {
        type: 'user',
        translatedName: TranslateService.get('entities/user/_title/singular'),
        features: ['users'],
        link: 'users',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'incident',
        translatedName: TranslateService.get(
          'entities/incident/_title/singular',
        ),
        features: ['incidents'],
        link: 'incidents',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'problem',
        translatedName: TranslateService.get(
          'entities/problem/_title/singular',
        ),
        features: ['problems'],
        link: 'problems',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'ticket',
        translatedName: translatedNameTicket,
        features: ['incidents', 'requests'],
        link: 'tickets',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'contract',
        translatedName: TranslateService.get(
          'entities/contract/_title/singular',
        ),
        features: ['contracts'],
        link: 'contracts/list-contract',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'budget',
        translatedName: TranslateService.get('entities/budget/_title/singular'),
        features: ['budgets'],
        link: 'financials/budgets',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'loan',
        translatedName: TranslateService.get('entities/loan/_title/singular'),
        features: ['loans'],
        link: 'loans',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'supplier',
        translatedName: TranslateService.get(
          'entities/supplier/_title/singular',
        ),
        features: ['suppliers'],
        link: 'suppliers',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'request',
        translatedName: TranslateService.get(
          'entities/request/_title/singular',
        ),
        features: ['requests'],
        link: 'requests',
        linkKey: 'id',
        dependentColumns: [],
      },
      {
        type: 'ticketTask',
        translatedName: TranslateService.get('entities/task/_title/singular'),
        features: ['tasks'],
        link: '',
        linkKey: 'id',
        dependentColumns: [
          'ticket.data.__typename',
          'ticketId',
          'operatorTeamId',
          'operatorId',
        ],
      },
      {
        type: 'intervention',
        translatedName: `${translatedNameTicket} > ${TranslateService.get(
          'entities/intervention/_title/singular',
        )}`,
        features: ['incidents', 'requests'],
        link: '',
        linkKey: 'id',
        dependentColumns: [
          'ticket.data.__typename',
          'ticketId',
          'taskId',
          'task.data.ticketId',
          'task.data.ticket.data.__typename',
          'operatorTeamId',
          'operatorId',
        ],
      },
      {
        type: 'consumableMovement',
        translatedName: `${TranslateService.get(
          'entities/stock/_title/singular',
        )} > ${TranslateService.get('entities/stock/consummable')}`,
        features: ['stocks', 'consumableMovements'],
        link: 'stocks',
        linkKey: 'id',
        dependentColumns: [],
      },
    ];

    // Vérification si on a les droits sur la feature
    if (checkLicence) {
      var features = await this.featureService.features();

      //Atention source de bug sur main (ne pas toucher)
      if (features.length > 0) {
        types = types.filter(
          (type) =>
            type.features.filter(
              (feature) =>
                features[0].items.filter((g) => g.name == feature).length > 0,
            ).length > 0,
        );
      }
    }

    types.sort(function (a, b) {
      if (a.translatedName < b.translatedName) {
        return -1;
      }
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      return 0;
    });

    return types;
  }

  /** Execute la query */
  run(
    type: string,
    state: string,
    options?: QueryContextOfQueryBuilder,
    filter?: any,
  ): Observable<any> {
    // TODO what to use ?
    let service = this.serviceInjector.get(
      type.toPascalCase() + 'CoreService',
      '',
    );
    if (type == undefined) {
      throw (
        'Impossibble de trouver le service [' +
        type.toPascalCase() +
        'CoreService' +
        ']'
      );
    }

    if (state == undefined) {
      throw 'Aucune configuration de colonne trouvée.';
    }

    let columns = JSON.parse(state);
    // Map des colonne
    let fieldColumns = [GqlField.create('id')];
    columns.forEach((element) => {
      ModelFieldCompilerService.createField(
        element.dataField,
        undefined,
        fieldColumns,
      );
    });

    let fields = [GqlSubField.create('data', fieldColumns)];
    if (filter != undefined && typeof filter == 'object') {
      filter = JSON.stringify(filter);
    }
    return service.customQuery(
      fields,
      {
        filter: filter,
      },
      options,
      undefined,
    );
  }

  public findQueryBuilderByAppointment(
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('options?') options?: QueryContextOfQueryBuilder,
    @Args('filter?') filter?: FilterOfQueryBuilder,
  ): any {
    let fields = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('name'),
      ]),
      GqlField.create('totalCount'),
    ];
    return this.findQueryBuilderWithEmailProto(
      fields,
      options,
      filter,
      extendedVariables,
    );
  }

  defaultData() {
    return of({ data: [], totalCount: 0 });
  }

  /**Obtient la liste de tous les requêteurs selon le métiers */
  private getAllRequest(queryFilterService: string[]) {
    if (queryFilterService.length > 0) {
      return this.find(
        [
          GqlSubField.create('data', [
            GqlField.create('name'),
            GqlField.create('type'),
            GqlField.create('id'),
            GqlField.create('favoriteUserIds'),
            GqlField.create('isFavorite'),
          ]),
        ],
        { sort: [{ name: Sort.Asc }] },
        {
          and: [
            { type: { in: queryFilterService } },
            { displayRequestToJob: { eq: true } },
          ],
        },
      );
    } else {
      let result: ServiceListResultOfQueryBuilder = {
        data: [],
        totalCount: 0,
      };
      return of(result);
    }
  }

  public generateMenuItems(
    queryFilterService: string[],
    route: string,
    specificType: string = undefined,
  ) {
    return this.getAllRequest(queryFilterService).pipe(
      map((element) => {
        let menuItems = [];
        let favoriteElement = element.data?.filter((x) => x.isFavorite == true);
        let noFavoriteElement = element.data?.filter(
          (x) => x.isFavorite == false,
        );

        favoriteElement.map((element) => {
          this.manageAddMenuItem(element, menuItems, specificType, route);
        });
        noFavoriteElement.map((element) => {
          this.manageAddMenuItem(element, menuItems, specificType, route);
        });
        return menuItems;
      }),
    );
  }

  private manageAddMenuItem(element, menuItems, specificType, route) {
    if (specificType == undefined || specificType == element.type) {
      let item = {
        text: `${element.name}`,
        active: `/${route}/queryBuildersResult/${element.id}`,
        routerLink: `/${route}/queryBuildersResult/${element.id}`,
      };
      menuItems.push(item);
    } else {
      let url = this.specificQueryBuilderLink(
        element.type,
        'queryBuildersOtherResult',
      );
      let item = {
        text: `${element.name}`,
        active: `/${route}/${url}/${element.id}`,
        routerLink: `/${route}/${url}/${element.id}`,
      };
      menuItems.push(item);
    }
  }

  private specificQueryBuilderLink(
    queryBuilderType: string,
    defaultLink: string,
  ) {
    let url = defaultLink;
    if (queryBuilderType.toLocaleLowerCase() == 'outofpark') {
      url = 'queryBuildersOtherResult/outOfPark/';
    }

    return url;
  }
  public findFavorite(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfQueryBuilder,
    @Args('filter?') filter?: FilterOfQueryBuilder,
    @Args('extendedVariables?') extendedVariables?: any,
  ): any {
    let connectedUser = this.userService.getConnectedUser();
    let filerFavorite = {
      favoriteUserIds: { elemMatch: { in: [connectedUser] } },
    };
    if (filter == undefined) {
      filter = filerFavorite;
    } else {
      filter = {
        and: [filter, filerFavorite],
      };
    }
    return this.find(fields, options, filter, extendedVariables);
  }

  /**
   * Récupère la liste des champs à exclure
   * @param type type
   * @param fields champs
   * @returns champs exclus
   */
  findExcludeFields(
    @Args('type') type: string,
    @Args('fields') fields: Array<GqlField | GqlSubField>
  ): string[] {
    const excludeFieldsMap: { [key: string]: string[] } = {
      program: ['asset.mainOperatingSystem'],
      asset: ['operatingSystem'],
      ticketEmail: ['ticket'],
    };
    const excludeFields = excludeFieldsMap[type] || [];
  
    const excludedFields = fields
      .map((field) => field.name)
      .filter((fieldName) => excludeFields.includes(fieldName));
  
    return excludedFields;
  }
}
