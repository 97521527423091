<!-- <span> Duplicat obligatoire car on peut pas enlever le formcontrolName ou le mettre a nul</span> -->

<div [id]="magicId" [formGroup]="fg" *ngIf="noForm === false">
  <div
    *ngIf="readOnlyFullScreenVisible"
    style="display: flex; flex-direction: row; justify-content: flex-end"
  >
    <dx-button
      style="box-shadow: none"
      [hint]="'clHtmlEditor-fullScreen' | translate"
      icon="fa fa-expand"
      (onClick)="readOnlyFullScreen()"
    >
    </dx-button>
  </div>

  <dx-html-editor
    *ngIf="control.type === 'HtmlEditorComponent'"
    (onInitialized)="onComponentInitialized($event, control)"
    (onValueChanged)="onValueChanged($event, control)"
    (onContentReady)="onContentReady($event)"
    (onFocusIn)="onFocusIn($event)"
    (onBlur)="onBlur($event)"
    [placeholder]="control.emptyText"
    [value]="control.defaultValue"
    valueType="html"
    [readOnly]="control.readOnly"
    [ngClass]="{
      'htmleditor-full': fullMode,
      'htmleditor-native': !fullMode || fullMode == undefined
    }"
    [formControlName]="control.name"
    [isValid]="
      fg.root.loaded === false ||
      getFormControl(control.name).enabled === false ||
      !getFormControl(control.name).errors
    "
  >
    <dxo-table-resizing
      [enabled]="control.options?.enableTable"
    ></dxo-table-resizing>
    <dxo-toolbar
      *ngIf="toolbarCanVisible && !_localStorageService.isMobile"
      [multiline]="
        control.options?.multiline == undefined
          ? false
          : control.options?.multiline
      "
    >
      <dxi-item name="undo"></dxi-item>
      <dxi-item name="redo"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="size" [acceptedValues]="acceptedTextSizes"></dxi-item>
      <dxi-item name="font" [acceptedValues]="acceptedTextFonts"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="bold"></dxi-item>
      <dxi-item name="italic"></dxi-item>
      <dxi-item name="strike"></dxi-item>
      <dxi-item name="underline"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="alignLeft"></dxi-item>
      <dxi-item name="alignCenter"></dxi-item>
      <dxi-item name="alignRight"></dxi-item>
      <dxi-item name="alignJustify"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item
        name="header"
        [acceptedValues]="[false, 1, 2, 3, 4, 5]"
      ></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="orderedList"></dxi-item>
      <dxi-item name="bulletList"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item
        name="color"
        widget="dxButton"
        [options]="showColorPickerButton"
      >
      </dxi-item>
      <dxi-item
        name="background"
        widget="dxButton"
        [options]="showBackgroundColorPickerButton"
      >
      </dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="link"></dxi-item>
      <dxi-item name="image"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="clear"></dxi-item>
      <dxi-item name="codeBlock"></dxi-item>
      <dxi-item name="blockquote"></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="control.options?.resolutionModel"
      ></dxi-item>
      <dxi-item
        name="cl-widget-selectbox"
        *ngIf="control.options?.resolutionModel"
        widget="dxSelectBox"
        [options]="{
          placeholder: control.options.resolutionModel.placeHolder,
          dataSource: control.options.resolutionModel.source.datasource,
          showClearButton: true,
          searchEnabled: true,
          displayExpr: control.options.resolutionModel.displayExpr,
          valueExpr: control.options.resolutionModel.valueExpr,
          onItemClick: applyResolutionModel,
          onValueChanged: selectionChangedResolutionModel,
          onFocusIn: focusInResolutionModel
        }"
      ></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="control.options?.commentaryModel"
      ></dxi-item>
      <dxi-item
        name="cl-widget-selectbox"
        *ngIf="control.options?.commentaryModel"
        widget="dxSelectBox"
        [options]="{
          placeholder: control.options.commentaryModel.placeHolder,
          dataSource: control.options.commentaryModel.source.datasource,
          showClearButton: true,
          searchEnabled: true,
          displayExpr: control.options.commentaryModel.displayExpr,
          valueExpr: control.options.commentaryModel.valueExpr,
          onItemClick: applyCommentaryModel,
          onFocusIn: focusInCommentaryModel
        }"
      ></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertTable"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteTable"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertRowAbove"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertRowBelow"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteRow"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertColumnLeft"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertColumnRight"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteColumn"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="cellProperties"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="tableProperties"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="showHtmlCode || fullMode || control.options?.enabledStamp"
      >
      </dxi-item>
      <dxi-item
        name="cachet"
        widget="dxButton"
        *ngIf="control.options?.enabledStamp"
        [options]="stampButtonOptions"
      >
      </dxi-item>
      <dxi-item
        name="showHtml"
        widget="dxButton"
        *ngIf="showHtmlCode"
        [options]="showHtmlButton"
      >
      </dxi-item>
      <dxi-item
        name="wikit-logo"
        *ngIf="showAiTools && enableIaTools"
        [options]="aiToolsLogoButtonOption"
      >
      </dxi-item>
      <dxi-item
        name="cl-widget-selectbox"
        *ngIf="showAiTools && enableIaTools"
        widget="dxSelectBox"
        [options]="{
          placeholder: 'Outils Wikit',
          dataSource: wikitToolsDatasource,
          showClearButton: true,
          searchEnabled: true,
          displayExpr: 'name',
          valueExpr: 'id',
          onItemClick: applyWikitTools
        }"
      ></dxi-item>
      <dxi-item
        name="fullScreen"
        widget="dxButton"
        *ngIf="fullMode"
        [options]="fullScreenButton"
      >
      </dxi-item>
      <dxi-item name="checkList" *ngIf="control.options?.enabledCheckList">
      </dxi-item>
      <dxi-item
        name="addFiles"
        widget="dxButton"
        *ngIf="control.options?.enableAddFiles"
        [options]="addFilesButton"
      >
      </dxi-item>
    </dxo-toolbar>
    <dxo-toolbar
      *ngIf="!toolbarCanVisible && _localStorageService.isMobile"
      [multiline]="
        control.options?.multiline == undefined
          ? false
          : control.options?.multiline
      "
    >
      <dxi-item
        name="separator"
        *ngIf="control.options?.resolutionModel"
      ></dxi-item>
      <dxi-item
        name="cl-widget-selectbox"
        *ngIf="control.options?.resolutionModel"
        widget="dxSelectBox"
        [options]="{
          placeholder: control.options.resolutionModel.placeHolder,
          dataSource: control.options.resolutionModel.source.datasource,
          showClearButton: true,
          searchEnabled: true,
          displayExpr: control.options.resolutionModel.displayExpr,
          valueExpr: control.options.resolutionModel.valueExpr,
          onItemClick: applyResolutionModel,
          onValueChanged: selectionChangedResolutionModel,
          onFocusIn: focusInResolutionModel
        }"
      ></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="control.options?.commentaryModel"
      ></dxi-item>
      <dxi-item
        name="cl-widget-selectbox"
        *ngIf="control.options?.commentaryModel"
        widget="dxSelectBox"
        [options]="{
          placeholder: control.options.commentaryModel.placeHolder,
          dataSource: control.options.commentaryModel.source.datasource,
          showClearButton: true,
          searchEnabled: true,
          displayExpr: control.options.commentaryModel.displayExpr,
          valueExpr: control.options.commentaryModel.valueExpr,
          onItemClick: applyCommentaryModel,
          onFocusIn: focusInCommentaryModel
        }"
      ></dxi-item>

      <dxi-item
        name="fullScreen"
        widget="dxButton"
        *ngIf="fullMode"
        [options]="fullScreenButton"
      >
      </dxi-item>
      <dxi-item name="checkList" *ngIf="control.options?.enabledCheckList">
      </dxi-item>
    </dxo-toolbar>
    <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
    <dxi-mention
      *ngIf="control.options?.mentions"
      [valueExpr]="control.options?.mentions?.valueExpr"
      [displayExpr]="control.options?.mentions?.displayExpr"
      [searchExpr]="control.options?.mentions?.searchExpr"
      [dataSource]="control.options?.mentions?.source || mentionDataSource"
    ></dxi-mention>
  </dx-html-editor>

  <dx-html-editor
    *ngIf="control.type === 'HtmlEditorSimpleComponent'"
    [ngClass]="'htmleditorsimple'"
    (onInitialized)="onComponentInitialized($event, control)"
    (onContentReady)="onContentReady($event, false)"
    (onValueChanged)="onValueChanged($event, control)"
    (onFocusIn)="onFocusIn($event)"
    (onBlur)="onBlur($event)"
    [placeholder]="control.emptyText"
    [value]="control.defaultValue"
    valueType="html"
    [readOnly]="control.readOnly"
    style="height: 44px"
    [formControlName]="control.name"
    [isValid]="
      fg.root.loaded === false ||
      getFormControl(control.name).enabled === false ||
      !getFormControl(control.name).errors
    "
  >
    <dxi-mention
      *ngIf="control.options?.mentions"
      [valueExpr]="control.options?.mentions?.valueExpr"
      [displayExpr]="control.options?.mentions?.displayExpr"
      [searchExpr]="control.options?.mentions?.searchExpr"
      [dataSource]="
        (control.options?.mentions?.source?.fnCall == undefined &&
          control.options?.mentions?.source) ||
        mentionDataSource
      "
    ></dxi-mention>
    <dxo-toolbar
      [multiline]="
        control.options?.multiline == undefined
          ? false
          : control.options?.multiline
      "
    >
      <dxi-item
        name="insertTable"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteTable"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertRowAbove"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertRowBelow"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteRow"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertColumnLeft"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertColumnRight"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteColumn"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="cellProperties"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="tableProperties"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
    </dxo-toolbar>
  </dx-html-editor>
</div>

<div *ngIf="noForm === true">
  <dx-html-editor
    *ngIf="
      control.type === 'HtmlEditorComponent' || type === 'HtmlEditorComponent'
    "
    (onInitialized)="onComponentInitialized($event, control)"
    (onValueChanged)="onValueChanged($event, control)"
    (onContentReady)="onContentReady($event)"
    (onFocusIn)="onFocusIn($event)"
    (onBlur)="onBlur($event)"
    [placeholder]="control.emptyText"
    valueType="html"
    [readOnly]="control.readOnly"
    [(value)]="value"
    [ngClass]="{
      'htmleditor-full': fullMode,
      'htmleditor-native': !fullMode || fullMode == undefined
    }"
    [isValid]="
      getFormControl(control.name).enabled === false ||
      !getFormControl(control.name).errors
    "
  >
    <dxo-table-resizing
      [enabled]="control.options?.enableTable"
    ></dxo-table-resizing>
    <dxo-toolbar
      [multiline]="
        control.options?.multiline == undefined
          ? false
          : control.options?.multiline
      "
    >
      <dxi-item name="undo"></dxi-item>
      <dxi-item name="redo"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item
        name="size"
        [acceptedValues]="[
          '8pt',
          '10pt',
          '12pt',
          '14pt',
          '18pt',
          '24pt',
          '36pt'
        ]"
      ></dxi-item>
      <dxi-item
        name="font"
        [acceptedValues]="[
          'Segoe UI',
          'Arial',
          'Courier New',
          'Georgia',
          'Impact',
          'Lucida Console',
          'Tahoma',
          'Times New Roman',
          'Verdana'
        ]"
      ></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="bold"></dxi-item>
      <dxi-item name="italic"></dxi-item>
      <dxi-item name="strike"></dxi-item>
      <dxi-item name="underline"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="alignLeft"></dxi-item>
      <dxi-item name="alignCenter"></dxi-item>
      <dxi-item name="alignRight"></dxi-item>
      <dxi-item name="alignJustify"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item
        name="header"
        [acceptedValues]="[false, 1, 2, 3, 4, 5]"
      ></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="orderedList"></dxi-item>
      <dxi-item name="bulletList"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item
        name="colorPicker"
        widget="dxButton"
        [options]="showColorPickerButton"
      >
      </dxi-item>
      <dxi-item
        name="background"
        widget="dxButton"
        [options]="showBackgroundColorPickerButton"
      >
      </dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="link"></dxi-item>
      <dxi-item name="image"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="clear"></dxi-item>
      <dxi-item name="codeBlock"></dxi-item>
      <dxi-item name="blockquote"></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="control.options?.resolutionModel"
      ></dxi-item>
      <dxi-item
        name="cl-widget-selectbox"
        *ngIf="control.options?.resolutionModel"
        widget="dxSelectBox"
        [options]="{
          placeholder: control.options.resolutionModel.placeHolder,
          dataSource: control.options.resolutionModel.source.datasource,
          showClearButton: true,
          searchEnabled: true,
          displayExpr: control.options.resolutionModel.displayExpr,
          valueExpr: control.options.resolutionModel.valueExpr,
          onItemClick: selectionChangedResolutionModel,
          onFocusIn: focusInResolutionModel
        }"
      ></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="control.options?.commentaryModel"
      ></dxi-item>
      <dxi-item
        name="cl-widget-selectbox"
        *ngIf="control.options?.commentaryModel"
        widget="dxSelectBox"
        [options]="{
          placeholder: control.options.commentaryModel.placeHolder,
          dataSource: control.options.commentaryModel.source.datasource,
          showClearButton: true,
          searchEnabled: true,
          displayExpr: control.options.commentaryModel.displayExpr,
          valueExpr: control.options.commentaryModel.valueExpr,
          onItemClick: applyCommentaryModel,
          onFocusIn: focusInCommentaryModel
        }"
      ></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertTable"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteTable"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertRowAbove"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertRowBelow"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteRow"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertColumnLeft"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertColumnRight"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteColumn"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="cellProperties"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="tableProperties"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="separator"
        *ngIf="showHtmlCode || fullMode || control.options?.enabledStamp"
      >
      </dxi-item>
      <dxi-item
        name="cachet"
        widget="dxButton"
        [options]="stampButtonOptions"
        *ngIf="control.options?.enabledStamp"
      >
      </dxi-item>
      <dxi-item
        name="wikit-logo"
        *ngIf="showAiTools && enableIaTools"
        [options]="aiToolsLogoButtonOption"
      >
      </dxi-item>
      <dxi-item
        name="cl-widget-selectbox"
        *ngIf="showAiTools && enableIaTools"
        widget="dxSelectBox"
        [options]="{
          placeholder: 'Outils Wikit',
          dataSource: wikitToolsDatasource,
          showClearButton: true,
          searchEnabled: true,
          displayExpr: 'name',
          valueExpr: 'id',
          onItemClick: applyWikitTools
        }"
      ></dxi-item>
      <dxi-item
        name="showHtml"
        widget="dxButton"
        *ngIf="showHtmlCode"
        [options]="showHtmlButton"
      >
      </dxi-item>
      <dxi-item
        name="fullScreen"
        widget="dxButton"
        *ngIf="fullMode"
        [options]="fullScreenButton"
      >
      </dxi-item>
      <dxi-item name="checkList" *ngIf="control.options?.enabledCheckList">
      </dxi-item>
    </dxo-toolbar>
    <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
    <dxi-mention
      *ngIf="control.options?.mentions"
      [valueExpr]="control.options?.mentions?.valueExpr"
      [displayExpr]="control.options?.mentions?.displayExpr"
      [searchExpr]="control.options?.mentions?.searchExpr"
      [dataSource]="control.options?.mentions?.source || mentionDataSource"
    ></dxi-mention>
  </dx-html-editor>

  <dx-html-editor
    *ngIf="
      control.type === 'HtmlEditorSimpleComponent' ||
      type === 'HtmlEditorSimpleComponent'
    "
    [ngClass]="'htmleditorsimple'"
    (onInitialized)="onComponentInitialized($event, control)"
    (onContentReady)="onContentReady($event, false)"
    (onValueChanged)="onValueChanged($event, control)"
    (onFocusIn)="onFocusIn($event)"
    (onBlur)="onBlur($event)"
    [placeholder]="control.emptyText"
    [(value)]="value"
    valueType="html"
    [readOnly]="control.readOnly"
    style="height: 44px"
  >
    <dxi-mention
      *ngIf="control.options?.mentions"
      [valueExpr]="control.options?.mentions?.valueExpr"
      [displayExpr]="control.options?.mentions?.displayExpr"
      [searchExpr]="control.options?.mentions?.searchExpr"
      [dataSource]="control.options?.mentions?.source || mentionDataSource"
    ></dxi-mention>
    <dxo-toolbar
      [multiline]="
        control.options?.multiline == undefined
          ? false
          : control.options?.multiline
      "
    >
      <dxi-item
        name="insertTable"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteTable"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertRowAbove"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertRowBelow"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteRow"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertColumnLeft"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="insertColumnRight"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="deleteColumn"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="cellProperties"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="tableProperties"
        *ngIf="control.options?.enableTable"
      ></dxi-item>
      <dxi-item
        name="showHtml"
        widget="dxButton"
        *ngIf="showHtmlCode"
        [options]="showHtmlButton"
      >
      </dxi-item>
    </dxo-toolbar>
  </dx-html-editor>
</div>

<dx-popup
  *ngIf="showHtmlInfo"
  [width]="700"
  [height]="500"
  [title]="'clHtmlEditor-markdown-title' | translate"
  class="add-item-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="false"
  [deferRendering]="true"
  [(visible)]="showHtmlInfo"
>
  <div style="padding: 5px">
    <dx-text-area [height]="380" [(value)]="htmlValue"> </dx-text-area>
  </div>
  <div style="padding: 10px; text-align: right" class="clc-buttons">
    <dx-button
      [text]="'ok' | translate"
      type="default"
      [elementAttr]="{ class: 'load-button' }"
      (onClick)="editHtmlClick($event)"
    >
    </dx-button>
    <dx-button
      [text]="'cancel' | translate"
      type="default"
      [elementAttr]="{ class: 'load-button' }"
      (onClick)="closeClick($event)"
    >
    </dx-button>
  </div>
</dx-popup>

<dx-popup
  class="popup"
  [width]="800"
  [height]="300"
  [title]="'entities/files/_title/plural' | translate"
  [(visible)]="attachmentPopUpVisible"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
>
  <dx-file-uploader
    #fileUploader
    [chunkSize]="this.maxSize"
    [allowedFileExtensions]="allowedFileExtensions"
    [uploadChunk]="uploadFileChunk"
    [multiple]="true"
    uploadMode="instantly"
    allowCanceling="true"
    [visible]="true"
    [maxFileSize]="4000000"
  ></dx-file-uploader>
</dx-popup>

<dx-popup
  class="popup"
  [title]="'clHtmlEditor-changeFontColor' | translate"
  [width]="280"
  [height]="'auto'"
  [(visible)]="colorPickerVisible"
  [hideOnOutsideClick]="true"
>
  <div style="padding: 8%">
    <span
      [(cpToggle)]="colorPickerVisible"
      [cpDialogDisplay]="'inline'"
      [(colorPicker)]="textColor"
      [cpPresetLabel]="'clHtmlEditor-defaultColor' | translate"
      [cpPresetColors]="arrayColors"
      [cpPositionRelativeToArrow]="true"
      [cpSaveClickOutside]="false"
    ></span>
    <div style="padding: 5px; text-align: right" class="clc-buttons">
      <dx-button
        [text]="'ok' | translate"
        type="default"
        [elementAttr]="{ class: 'load-button' }"
        (onClick)="editTextColor()"
      >
      </dx-button>
      <dx-button
        [text]="'cancel' | translate"
        type="default"
        [elementAttr]="{ class: 'load-button' }"
        (onClick)="closePickerColor()"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  class="popup"
  [title]="'clHtmlEditor-changeBackgroundColor' | translate"
  [width]="280"
  [height]="'auto'"
  [(visible)]="backgroundColorPickerVisible"
  [hideOnOutsideClick]="true"
>
  <div style="padding: 8%">
    <span
      [(cpToggle)]="backgroundColorPickerVisible"
      [cpDialogDisplay]="'inline'"
      [(colorPicker)]="backgroundColor"
      [cpPresetLabel]="'clHtmlEditor-defaultColor' | translate"
      [cpPresetColors]="arrayColors"
      [cpPositionRelativeToArrow]="true"
      [cpSaveClickOutside]="false"
    ></span>
    <div style="padding: 5px; text-align: right" class="clc-buttons">
      <dx-button
        [text]="'ok' | translate"
        type="default"
        [elementAttr]="{ class: 'load-button' }"
        (onClick)="editBackgroundColor()"
      >
      </dx-button>
      <dx-button
        [text]="'cancel' | translate"
        type="default"
        [elementAttr]="{ class: 'load-button' }"
        (onClick)="closeBackgroundPickerColor()"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>
<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="isWikitLoading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
></dx-load-panel>
