import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfQueryBuilder, FilterOfQueryBuilder, QueryContextOfQueryBuilder, ServiceSingleResultOfInt64, ServiceListResultOfQueryBuilder, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfSecurityGroup, FilterOfSecurityGroup, QueryContextOfSecurityGroup, ServiceSingleResultOfBoolean, QueryContextOfQueryBuilderQueriesResolver, ServiceListResultOfQueryBuilderAppointment, FilterOfQueryBuilderAppointment, QueryContextOfQueryBuilderAppointment, ServiceListResultOfEmailPrototype, FilterOfEmailPrototype, QueryContextOfEmailPrototype, ServiceListResultOfEmailPrototypeLog, FilterOfEmailPrototypeLog, QueryContextOfEmailPrototypeLog, ServiceListResultOfSftpConnector, FilterOfSftpConnector, QueryContextOfSftpConnector, FieldUpdateOperatorOfQueryBuilder, QueryBuilderAppointmentInput, FieldUpdatesOperatorOfQueryBuilderAppointment } from '../types'

export type GetQueryBuildersResultType = {
    queryBuilders: {
        get?: Maybe<ServiceSingleResultOfQueryBuilder>
    }
}

export type GetQueryBuildersBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getQueryBuilders ${args} {
    queryBuilders {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstQueryBuildersResultType = {
    queryBuilders: {
        first?: Maybe<ServiceSingleResultOfQueryBuilder>
    }
}

export type FirstQueryBuildersBaseVariables = {
	filter: Maybe<FilterOfQueryBuilder>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfQueryBuilder>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfQueryBuilder = null, $options: QueryContextOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstQueryBuilders ${args} {
    queryBuilders {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountQueryBuildersResultType = {
    queryBuilders: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountQueryBuildersBaseVariables = {
	filter: Maybe<FilterOfQueryBuilder>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countQueryBuilders ${args} {
    queryBuilders {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindQueryBuildersResultType = {
    queryBuilders: {
        find?: Maybe<ServiceListResultOfQueryBuilder>
    }
}

export type FindQueryBuildersBaseVariables = {
	options: Maybe<QueryContextOfQueryBuilder>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfQueryBuilder>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfQueryBuilder = null, $filter: FilterOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findQueryBuilders ${args} {
    queryBuilders {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchQueryBuildersResultType = {
    queryBuilders: {
        search?: Maybe<ServiceListResultOfQueryBuilder>
    }
}

export type SearchQueryBuildersBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfQueryBuilder>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchQueryBuilders ${args} {
    queryBuilders {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaQueryBuildersResultType = {
    queryBuilders: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaQueryBuildersBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaQueryBuilders ${args} {
    queryBuilders {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataQueryBuildersResultType = {
    queryBuilders: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataQueryBuildersBaseVariables = {
	filter: Maybe<FilterOfQueryBuilder>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataQueryBuilders ${args} {
    queryBuilders {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryQueryBuildersResultType = {
    queryBuilders: {
        customQuery?: Maybe<ServiceListResultOfQueryBuilder>
    }
}

export type CustomQueryQueryBuildersBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfQueryBuilder>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfQueryBuilder>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfQueryBuilder = null, $options: QueryContextOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryQueryBuilders ${args} {
    queryBuilders {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdQueryBuildersResultType = {
    queryBuilders: {
        customQueryId?: Maybe<ServiceListResultOfQueryBuilder>
    }
}

export type CustomQueryIdQueryBuildersBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfQueryBuilder>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfQueryBuilder>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfQueryBuilder = null, $options: QueryContextOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdQueryBuilders ${args} {
    queryBuilders {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSecurityGroupsQueryBuildersResultType = {
    queryBuilders: {
        findUnassociatedSecurityGroups?: Maybe<ServiceListResultOfSecurityGroup>
    }
}

export type FindUnassociatedSecurityGroupsQueryBuildersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les profils non liés de l'entité. */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSecurityGroupsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSecurityGroupsQueryBuilders ${args} {
    queryBuilders {
        findUnassociatedSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSecurityGroupsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSecurityGroupsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSecurityGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedQueryBuildersResultType = {
    queryBuilders: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedQueryBuildersBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedQueryBuilders ${args} {
    queryBuilders {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SendMailResultQueryBuildersResultType = {
    queryBuilders: {
        sendMailResult?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type SendMailResultQueryBuildersBaseVariables = {
	id: string, /**  */
	options: Maybe<QueryContextOfQueryBuilderQueriesResolver>, /** Les options de requêtage à appliquer. */
}

/** Permet l'envoi d'un email avec le résultat dans un excel */
@Injectable({providedIn: 'root'})
export class SendMailResultQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $options: QueryContextOfQueryBuilderQueriesResolver = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query sendMailResultQueryBuilders ${args} {
    queryBuilders {
        sendMailResult(id: $id, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SendMailResultQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SendMailResultQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindQueryBuilderAppointmentsQueryBuildersResultType = {
    queryBuilders: {
        findQueryBuilderAppointments?: Maybe<ServiceListResultOfQueryBuilderAppointment>
    }
}

export type FindQueryBuilderAppointmentsQueryBuildersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfQueryBuilderAppointment>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfQueryBuilderAppointment>, /** Les options de requêtage à appliquer. */
}

/** Permet de trouver les plannifications de requetteur */
@Injectable({providedIn: 'root'})
export class FindQueryBuilderAppointmentsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfQueryBuilderAppointment = null, $options: QueryContextOfQueryBuilderAppointment = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findQueryBuilderAppointmentsQueryBuilders ${args} {
    queryBuilders {
        findQueryBuilderAppointments(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindQueryBuilderAppointmentsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindQueryBuilderAppointmentsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfQueryBuilderAppointment", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindQueryBuilderWithEmailProtoQueryBuildersResultType = {
    queryBuilders: {
        findQueryBuilderWithEmailProto?: Maybe<ServiceListResultOfQueryBuilder>
    }
}

export type FindQueryBuilderWithEmailProtoQueryBuildersBaseVariables = {
	filter: Maybe<FilterOfQueryBuilder>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfQueryBuilder>, /** Les options de requêtage à appliquer. */
}

/** Permet de trouver les requêteurs avec au moins un email proto. */
@Injectable({providedIn: 'root'})
export class FindQueryBuilderWithEmailProtoQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfQueryBuilder = null, $options: QueryContextOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findQueryBuilderWithEmailProtoQueryBuilders ${args} {
    queryBuilders {
        findQueryBuilderWithEmailProto(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindQueryBuilderWithEmailProtoQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindQueryBuilderWithEmailProtoQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistQueryBuildersResultType = {
    queryBuilders: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistQueryBuildersBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existQueryBuilders ${args} {
    queryBuilders {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedEmailPrototypesQueryBuildersResultType = {
    queryBuilders: {
        findUnassociatedEmailPrototypes?: Maybe<ServiceListResultOfEmailPrototype>
    }
}

export type FindUnassociatedEmailPrototypesQueryBuildersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedEmailPrototypesQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfEmailPrototype = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedEmailPrototypesQueryBuilders ${args} {
    queryBuilders {
        findUnassociatedEmailPrototypes(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedEmailPrototypesQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedEmailPrototypesQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedEmailPrototypeLogsQueryBuildersResultType = {
    queryBuilders: {
        findUnassociatedEmailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog>
    }
}

export type FindUnassociatedEmailPrototypeLogsQueryBuildersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfEmailPrototypeLog>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototypeLog>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedEmailPrototypeLogsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfEmailPrototypeLog = null, $options: QueryContextOfEmailPrototypeLog = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedEmailPrototypeLogsQueryBuilders ${args} {
    queryBuilders {
        findUnassociatedEmailPrototypeLogs(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedEmailPrototypeLogsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedEmailPrototypeLogsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototypeLog", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSftpConnectorsQueryBuildersResultType = {
    queryBuilders: {
        findUnassociatedSftpConnectors?: Maybe<ServiceListResultOfSftpConnector>
    }
}

export type FindUnassociatedSftpConnectorsQueryBuildersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSftpConnector>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSftpConnector>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSftpConnectorsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSftpConnector = null, $options: QueryContextOfSftpConnector = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSftpConnectorsQueryBuilders ${args} {
    queryBuilders {
        findUnassociatedSftpConnectors(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSftpConnectorsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSftpConnectorsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSftpConnector", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertQueryBuildersResultType = {
    queryBuilders: {
        insert?: Maybe<ServiceSingleResultOfQueryBuilder>
    }
}

export type InsertQueryBuildersBaseVariables = {
	entity: QueryBuilderInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: QueryBuilderInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertQueryBuilders ${args} {
    queryBuilders {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateQueryBuildersResultType = {
    queryBuilders: {
        update?: Maybe<ServiceSingleResultOfQueryBuilder>
    }
}

export type UpdateQueryBuildersBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfQueryBuilder>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateQueryBuilders ${args} {
    queryBuilders {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataQueryBuildersResultType = {
    queryBuilders: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataQueryBuildersBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataQueryBuilders ${args} {
    queryBuilders {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyQueryBuildersResultType = {
    queryBuilders: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyQueryBuildersBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfQueryBuilder>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyQueryBuilders ${args} {
    queryBuilders {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteQueryBuildersResultType = {
    queryBuilders: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteQueryBuildersBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteQueryBuilders ${args} {
    queryBuilders {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupQueryBuildersResultType = {
    queryBuilders: {
        addSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupQueryBuildersBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupQueryBuilders ${args} {
    queryBuilders {
        addSecurityGroup(id: $id, securityGroupIds: $securityGroupIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupQueryBuildersResultType = {
    queryBuilders: {
        removeSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupQueryBuildersBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupQueryBuilders ${args} {
    queryBuilders {
        removeSecurityGroup(id: $id, securityGroupIds: $securityGroupIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DuplicateQueryBuilderQueryBuildersResultType = {
    queryBuilders: {
        duplicateQueryBuilder?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DuplicateQueryBuilderQueryBuildersBaseVariables = {
	ids: Array<string>, /**  */
	name: Maybe<string>, /**  */
	type: Maybe<string>, /**  */
}

/** Permet de dupliquer un requêteur. */
@Injectable({providedIn: 'root'})
export class DuplicateQueryBuilderQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $name: String = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation duplicateQueryBuilderQueryBuilders ${args} {
    queryBuilders {
        duplicateQueryBuilder(ids: $ids, name: $name, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DuplicateQueryBuilderQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DuplicateQueryBuilderQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAppointmentsQueryBuildersResultType = {
    queryBuilders: {
        addAppointments?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAppointmentsQueryBuildersBaseVariables = {
	queryBuilderAppointments: Array<Maybe<QueryBuilderAppointmentInput>>, /**  */
	id: string, /**  */
}

/** Permet d'ajouter une planification sur des requêteurs. */
@Injectable({providedIn: 'root'})
export class AddAppointmentsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilderAppointments: [QueryBuilderAppointmentInput] = null, $id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAppointmentsQueryBuilders ${args} {
    queryBuilders {
        addAppointments(queryBuilderAppointments: $queryBuilderAppointments, id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAppointmentsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAppointmentsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAppointmentQueryBuildersResultType = {
    queryBuilders: {
        addAppointment?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAppointmentQueryBuildersBaseVariables = {
	queryBuilderAppointment: Maybe<QueryBuilderAppointmentInput>, /**  */
}

/** Permet d'ajouter une planification sur un requêteurs */
@Injectable({providedIn: 'root'})
export class AddAppointmentQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilderAppointment: QueryBuilderAppointmentInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAppointmentQueryBuilders ${args} {
    queryBuilders {
        addAppointment(queryBuilderAppointment: $queryBuilderAppointment) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAppointmentQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAppointmentQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateAppointmentsQueryBuildersResultType = {
    queryBuilders: {
        updateAppointments?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateAppointmentsQueryBuildersBaseVariables = {
	entry: Array<Maybe<FieldUpdatesOperatorOfQueryBuilderAppointment>>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de modifier des planification de requetteur */
@Injectable({providedIn: 'root'})
export class UpdateAppointmentsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entry: [FieldUpdatesOperatorOfQueryBuilderAppointment] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateAppointmentsQueryBuilders ${args} {
    queryBuilders {
        updateAppointments(entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateAppointmentsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateAppointmentsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateAppointmentQueryBuildersResultType = {
    queryBuilders: {
        updateAppointment?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateAppointmentQueryBuildersBaseVariables = {
	id: string, /**  */
	modified: Maybe<QueryBuilderAppointmentInput>, /**  */
}

/** Permet de modifier une planification de requetteur */
@Injectable({providedIn: 'root'})
export class UpdateAppointmentQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $modified: QueryBuilderAppointmentInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateAppointmentQueryBuilders ${args} {
    queryBuilders {
        updateAppointment(id: $id, modified: $modified) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateAppointmentQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateAppointmentQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAppointmentsQueryBuildersResultType = {
    queryBuilders: {
        removeAppointments?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAppointmentsQueryBuildersBaseVariables = {
	queryBuilderAppointments: Array<string>, /**  */
}

/** Permet de supprimer des planification de requetteur */
@Injectable({providedIn: 'root'})
export class RemoveAppointmentsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilderAppointments: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAppointmentsQueryBuilders ${args} {
    queryBuilders {
        removeAppointments(queryBuilderAppointments: $queryBuilderAppointments) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAppointmentsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAppointmentsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteAppointmentsQueryBuildersResultType = {
    queryBuilders: {
        deleteAppointments?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteAppointmentsQueryBuildersBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de supprimer des planification de requetteur */
@Injectable({providedIn: 'root'})
export class DeleteAppointmentsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteAppointmentsQueryBuilders ${args} {
    queryBuilders {
        deleteAppointments(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteAppointmentsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteAppointmentsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFavoriteQueryBuildersResultType = {
    queryBuilders: {
        addFavorite?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFavoriteQueryBuildersBaseVariables = {
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFavoriteQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFavoriteQueryBuilders ${args} {
    queryBuilders {
        addFavorite(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFavoriteQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFavoriteQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFavoriteQueryBuildersResultType = {
    queryBuilders: {
        removeFavorite?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFavoriteQueryBuildersBaseVariables = {
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFavoriteQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFavoriteQueryBuilders ${args} {
    queryBuilders {
        removeFavorite(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFavoriteQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFavoriteQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddEmailPrototypesQueryBuildersResultType = {
    queryBuilders: {
        addEmailPrototypes?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddEmailPrototypesQueryBuildersBaseVariables = {
	id: string, /**  */
	emailPrototypeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddEmailPrototypesQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $emailPrototypeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addEmailPrototypesQueryBuilders ${args} {
    queryBuilders {
        addEmailPrototypes(id: $id, emailPrototypeIds: $emailPrototypeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddEmailPrototypesQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddEmailPrototypesQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveEmailPrototypesQueryBuildersResultType = {
    queryBuilders: {
        removeEmailPrototypes?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveEmailPrototypesQueryBuildersBaseVariables = {
	id: string, /**  */
	emailPrototypeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveEmailPrototypesQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $emailPrototypeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeEmailPrototypesQueryBuilders ${args} {
    queryBuilders {
        removeEmailPrototypes(id: $id, emailPrototypeIds: $emailPrototypeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveEmailPrototypesQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveEmailPrototypesQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSftpConnectorsQueryBuildersResultType = {
    queryBuilders: {
        addSftpConnectors?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSftpConnectorsQueryBuildersBaseVariables = {
	id: string, /**  */
	sftpConnectorIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSftpConnectorsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $sftpConnectorIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSftpConnectorsQueryBuilders ${args} {
    queryBuilders {
        addSftpConnectors(id: $id, sftpConnectorIds: $sftpConnectorIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSftpConnectorsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSftpConnectorsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSftpConnectorsQueryBuildersResultType = {
    queryBuilders: {
        removeSftpConnectors?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSftpConnectorsQueryBuildersBaseVariables = {
	id: string, /**  */
	sftpConnectorIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSftpConnectorsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $sftpConnectorIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSftpConnectorsQueryBuilders ${args} {
    queryBuilders {
        removeSftpConnectors(id: $id, sftpConnectorIds: $sftpConnectorIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSftpConnectorsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSftpConnectorsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddEmailPrototypeLogsQueryBuildersResultType = {
    queryBuilders: {
        addEmailPrototypeLogs?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddEmailPrototypeLogsQueryBuildersBaseVariables = {
	id: string, /**  */
	emailPrototypeLogIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddEmailPrototypeLogsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $emailPrototypeLogIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addEmailPrototypeLogsQueryBuilders ${args} {
    queryBuilders {
        addEmailPrototypeLogs(id: $id, emailPrototypeLogIds: $emailPrototypeLogIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddEmailPrototypeLogsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddEmailPrototypeLogsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveEmailPrototypeLogsQueryBuildersResultType = {
    queryBuilders: {
        removeEmailPrototypeLogs?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveEmailPrototypeLogsQueryBuildersBaseVariables = {
	emailPrototypeLogIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveEmailPrototypeLogsQueryBuildersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$emailPrototypeLogIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeEmailPrototypeLogsQueryBuilders ${args} {
    queryBuilders {
        removeEmailPrototypeLogs(emailPrototypeLogIds: $emailPrototypeLogIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveEmailPrototypeLogsQueryBuildersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveEmailPrototypeLogsQueryBuildersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
