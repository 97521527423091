@if(messages.length > 1 && isViewHelpMe !== true && systemNotification === true){
<div (click)="onMessageClick()" class="cl-warning-customer-box">
  <i class="fa-solid fa-circle-exclamation"></i><span>{{'globals/problemSystem' | translate}}</span>
</div>

<dx-popup [hideOnOutsideClick]="false" [width]="'auto'" [height]="'auto'" [minHeight]="'200px'" [maxHeight]="'90vh'"
  [maxWidth]="'1024'" [showCloseButton]="false" [(visible)]="showPopup" [title]="'globals/problemSystem' | translate">
  <dx-scroll-view width="100%" height="100%">
    <div class="cl-warnings-div">
      <div *ngFor="let message of messages">
        <span class="cl-warning-span">{{ message }}</span>
      </div>
    </div>
  </dx-scroll-view>

  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="center" [options]="okButton">
  </dxi-toolbar-item>
</dx-popup>
}
@else {
<div class="box" *ngIf="
    messages !== undefined &&
    messages.length > 0 &&
    isViewHelpMe !== true &&
    systemNotification === true
  ">
  <span>{{ messages[0] }}</span>
</div>
}