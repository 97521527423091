import {
  AfterContentInit,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CoreListComponent } from '../list/list.component';
import { ListComponentBase } from '../list/list.component-base';
import outCartModel from './model.json';
import correctionCartModel from './correction.model.json';
import notify from 'devextreme/ui/notify';
import { Observable, of } from 'rxjs';
import {
  AssetModelCategory,
  OutCart,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { CoreCartListSelectComponent } from '../cart-list-select/cart-list-select.component';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import {
  CoreGraphQLSource,
  GraphQLStore,
} from '@clarilog/core/services2/graphql/graphql-store.service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import {
  CorrectionStockMovementCoreService,
  OutStockMovementCoreService,
  StockTransferCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CriteriaHelpers } from '@clarilog/core/services2/graphql/criteria-helpers';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';

/** Représente le composent LinkList. Permet de lier des éléments à un élément. */
@Component({
  selector: 'clc-current-in-stock-list',
  templateUrl: './in-stock-list.component.html',
  styleUrls: ['./in-stock-list.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreCurrentInStockListComponent),
      multi: true,
    },
  ],
})
export class CoreCurrentInStockListComponent
  extends ListComponentBase
  implements ControlValueAccessor, AfterContentInit, OnInit
{
  /** Obtient ou définit la source de données courante. */
  currentSource: ModelDataSourceContext;
  disableButton: boolean = false;

  /** Obtient ou définit la query globale. */
  @Input() query: any = undefined;
  /** Obtient ou définit l'état d'ouverture du popup. */
  @Input() associatePopup: boolean = false;
  /** Obtient ou définit le nom du label du control */
  @Input() label: string = undefined;

  /** Obtient ou définit le nom du disable du control */
  @Input() disableEdit: boolean;

  /** Obtient ou définit la query du select. */
  @Input() select: any = undefined;
  /** Obtient ou définit le composent list. */
  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;

  // /** Obtient ou définit le composent StockSelect. */
  @ViewChild(CoreCartListSelectComponent, { static: true })
  selectComponent: CoreCartListSelectComponent;

  /** Obtient ou définit la valeur de la route de gestion */
  @Input() route: string;
  /** Obtient ou définit l'état activé du composent. */
  @Input() disabled: boolean;
  /** Obtient ou définit l'état activé du composent. */
  @Input() mode: string;

  /** Obtient ou définit la route d'un nouveau bouton */
  @Input() routeButton: string;

  /** Suppression active pour les sorties de stock (consommable)  */
  @Input() allowDeleting: boolean = false;
  valueItems: any[] = [];
  outCartModel;

  TRANSFER: string = 'TRANSFER';
  id: string;

  _storageUnitId;
  selectedAssetModelId;
  maxAmountByGuid;

  set storageUnitId(value) {
    this._storageUnitId = value;
  }

  get storageUnitId(): string[] {
    return this._storageUnitId;
  }
  /** Définit l'injector */
  injector: Injector;
  service: any;
  constructor(
    private _router: Router,
    private router: ActivatedRoute,
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super();
    this.outCartModel = outCartModel;
    this.maxAmountByGuid = [];
    this.injector = injector;
  }

  /** @inheritdoc */
  ngOnInit() {
    let getId = this.router.snapshot.paramMap.get('id');
    this.id = getId != undefined || getId != null ? getId : null;
    if (this.mode === 'CORRECTION') {
      this.outCartModel = correctionCartModel;
    }
    let storageUnitControl = FormGroupHelpers.formControlByName(
      this.state.form,
      'storageUnitIds',
    );

    let toStorageUnit = null;
    if (this.mode === 'TRANSFER') {
      storageUnitControl = FormGroupHelpers.formControlByName(
        this.state.form,
        'fromStorageUnitId',
      );

      toStorageUnit = FormGroupHelpers.formControlByName(
        this.state.form,
        'toStorageUnitId',
      ).value;
    }
    let methodeName = 'findStorageOutConsumableItems';
    switch (this.mode) {
      case 'CORRECTION':
        this.service = this.injector.get(CorrectionStockMovementCoreService);
        break;
      case 'TRANSFER':
        this.service = this.injector.get(StockTransferCoreService);
        methodeName = 'findCustomStorageConsumableItems';
        break;
      default:
        this.service = this.injector.get(OutStockMovementCoreService);
        break;
    }

    let store = this.source.datasource.store() as GraphQLStore;
    this.currentSource = new ModelDataSourceContext({
      datasource: CoreGraphQLSource.create(
        {
          ...store.context.context.config,
          update: this.updatingProcess.bind(this),
          query: (filters?: any, options?: any) => {
            let filter = null;
            if (filters != undefined)
              filter = CriteriaHelpers.convertDxFilter(filters);
            if (options == undefined) options = null;

            if (this.mode == this.TRANSFER) {
              return this.service[methodeName](
                this.createFields(),
                storageUnitControl.value,
                toStorageUnit,
                options,
                this.id,
                filter,
                null,
              ) as Observable<any>;
            }
            return this.service[methodeName](
              this.createFields(),
              storageUnitControl.value,
              options,
              this.id,
              filter,
              null,
            ) as Observable<any>;
          },
          delete: (id: string) => {
            let deleteMethod = of(true);
            if (this.mode == undefined && this.allowDeleting == true) {
              let index = this.list.component.getRowIndexByKey(id);
              let getAllRow =
                this.list.component.getVisibleRows() as Array<any>;
              let getSpecificRowData = getAllRow[index];
              let updateHasConsumable = getAllRow.length == 1 ? true : false;
              let data = getSpecificRowData.data as OutCart;

              deleteMethod = this.service['deleteConsumableItems'](
                ModelFieldCompilerService.createServiceSingleResultScalar(),
                updateHasConsumable,
                data.storageUnitId,
                this.id,
                data.entryId
              ) as Observable<boolean>;
            }
            return deleteMethod;
          },
        },
        null,
        this.postProcess.bind(this),
      ),
      rootState: this.source.rootState,
    });

    this.storageUnitId = storageUnitControl.value;
    storageUnitControl.valueChanges.subscribe((val) => {
      this.storageUnitId = val;
      // Reset si changement de storage unit
      this.valueItems = [];
      this.refresh();
    });

    if (this.disableEdit == true) {
      this.disableButton = true;
    }
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo(defaultRoute: boolean = false) {
    /** liste les différents paramètre à intégrer dans l'url */
    let filter: object = {};
    if (this.id != null) {
      /** Ajoute de l'id dans l'objet filter */
      Object.assign(filter, { type: this.id });
    }
    let url = this._router.createUrlTree([this.route], {
      skipLocationChange: true,
    } as NavigationExtras);

    if (defaultRoute && this.routeButton != undefined) {
      if (filter != undefined) {
        url = this._router.createUrlTree([this.routeButton], {
          queryParams: filter,
          skipLocationChange: true,
        } as NavigationExtras);
      }
    }
    let win = window.open(this._router.serializeUrl(url), '_blank');
    win.opener.callback = async () => await this.refresh();
  }
  dataStore: any[];
  private postProcess(data: any[]) {
    let res = [];
    this.maxAmountByGuid = [];

    for (let item of data) {
      let value = this.valueItems.find(
        (x) =>
          x.entryId === item.entryId && x.storageUnitId === item.storageUnitId,
      );
      if (value != undefined) {
        item.amount = value.amount;
      } else if (this.mode == 'CORRECTION') {
        item.amount = undefined;
      }
      const itemCopy = { ...item };
      res.push(itemCopy);
    }
    this.dataStore = res;
    return res;
  }

  /**
   * Prends en charge la mise à jour du ou des valeurs du ou des champs modifiables
   * @param e
   * @param data
   */
  public updatingProcess(e, data: any) {
    let value = data.amount; // data.cart.amount;
    if (value < 0) {
      notify(TranslateService.get('error'), 'error', 5000);
    } else {
      let d = this.dataStore.find((x) => x.id === e);

      if (
        (this.mode === 'OUT' || this.mode === 'TRANSFER') &&
        value > d.availableAmount
      ) {
        value = d.availableAmount;
      }
      let existValue = this.valueItems.findIndex(
        (x) => x.entryId === d.entryId && x.storageUnitId === d.storageUnitId,
      );
      if (existValue == undefined || existValue === -1) {
        this.valueItems.push({
          amount: Number(value),
          entryId: d.entryId,
          storageUnitId: d.storageUnitId,
        });
      } else {
        //...
        this.valueItems[existValue] = {
          amount: value,
          entryId: d.entryId,
          storageUnitId: d.storageUnitId,
        };
      }
      this.emitChange(this.valueItems);
      this.refresh();
      return of(this.valueItems);
      this.objectPass(e);
    }
    this.refresh();
    return of(this.valueItems);
  }

  /** Affiche le popup */
  showPopUp(assetModelId = undefined) {
    this.associatePopup = true;
    let data = this.dataStore.find((x) => x.id === assetModelId);
    let value = this.valueItems.find((x) => x.entryId === data.entryId);

    if (value == undefined) {
      value = {
        amount: 0,
        entryId: data.entryId,
        storageUnitId: data.storageUnitId,
      };
    }

    this.selectComponent.open(AssetModelCategory.Consumable, value);
  }

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  writeValue(value: any): void {
    if (value != undefined && typeof value !== 'string') {
      this.valueItems = value;
      this.refresh();
    }
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** @inheritdoc */
  public ngAfterContentInit(): void {}

  /** Rafraîchi la liste. */
  public refresh() {
    this.state.sharedContext.params.set(
      'storageUnitId',
      () => this.storageUnitId,
    );

    //this.currentSource.reload();
    this.list.refresh();
  }
  /** Method permettant de gérer le retour de la fermeture du popup avec le retour du formulaire intermédiaire */
  public objectPass(e) {
    let data = this.dataStore.find((x) => x.id === this.selectedAssetModelId);
    e.entryId = data.entryId;
    e.storageUnitId = data.storageUnitId;
    if (e.amount == undefined) {
      let value = this.valueItems.find((x) => x.entryId === data.entryId);
      if (value == undefined) {
        e.amount = 0;
      } else {
        e.amount = value.amount;
      }
    }
    let existValue = this.valueItems.findIndex(
      (x) => x.entryId === e.entryId && e.storageUnitId == x.storageUnitId,
    );

    if (existValue == undefined || existValue === -1) {
      this.valueItems.push(e);
    } else {
      this.valueItems[existValue] = e;
    }
    this.emitChange(this.valueItems);
    this.refresh();

    // this.load(this.currentValues, true);
  }

  private emitChange(values) {
    this.onChange(values);
    this.onTouched();
  }

  /** Ouvre le popup en mode edition et sauvegarde l'index de l'item éditer pour pouvoir le mettre a jour */
  public update(e) {
    let data;
    if (e.value != undefined) {
      this.selectedAssetModelId = e.value;
      data = e.data;
    } else {
      this.selectedAssetModelId = this.selectedKeys[0];
      data = this.selectedData[0];
    }
    if (this.mode !== 'CORRECTION') {
      this.outCartModel.form.layout.pages[0].sections[0].groups[0].controls[2].options.max =
        data.availableAmount;
      // data.entriesAmount.data;
    }
    this.showPopUp(this.selectedAssetModelId);
  }

  /** Affiche les éléments à ajouter. */
  public cancel(e) {
    let selectedAssetModelId = this.selectedKeys[0];
    let existValue = this.valueItems.findIndex(
      (x) => x.entryId === selectedAssetModelId,
    );
    if (existValue !== -1) {
      this.valueItems.splice(existValue, 1);
    }
    this.refresh();
  }

  /**Création des fields */
  private createFields() {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('entryName'),
        GqlField.create('entryId'),
        GqlSubField.create('assetCategory', [
          GqlSubField.create(
            'name',
            this.translatedFieldHelperService.translatedFields(),
          ),
        ]),
        GqlSubField.create('storageUnit', [
          GqlSubField.create(
            'name',
            this.translatedFieldHelperService.translatedFields(),
          ),
          GqlField.create('id'),
        ]),
        GqlField.create('storageUnitId'),
        GqlSubField.create('storageUnitEnd', [
          GqlSubField.create(
            'name',
            this.translatedFieldHelperService.translatedFields(),
          ),
          GqlField.create('id'),
        ]),
        GqlField.create('storageUnitEndId'),
        GqlField.create('availableAmount'),
        GqlField.create('movedAmount'),
        GqlField.create('amount'),
        GqlSubField.create('assetManufacturer', [GqlField.create('name')]),
        GqlSubField.create('assetModel', [GqlField.create('name')]),
      ]),
      GqlField.create('totalCount'),
    ];
  }
}
