import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GC,
  GCFactory,
  ServicePermission,
  StateManagementService,
} from '@clarilog/core';
import { CorePolicyValidator } from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import {
  ServiceSingleResultOfBoolean,
  ServiceSingleResultOfInt64,
  ValidationError,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { CommandItems } from '@clarilog/shared2/models/schema';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { of } from 'rxjs';
import { ModelFieldCompilerService, TranslateService } from '../../../services';
import { ClickEventArgs, CoreListComponent } from '../list';
import { ListComponentBase, ListType } from '../list/list.component-base';
import { CoreWorkItemsComponent } from '../work-items/work-items.component';
import { alert } from 'devextreme/ui/dialog';
import { EventArgs } from '../../nav-menu/nav-menu-item/nav-menu-item.component';
import { ToolbarItemsComponent } from '../../toolbar/toolbar-items/toolbar-items.component';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';

/** Représente le composent de gestion des listes des éléments managés. */
@Component({
  selector: 'clc-manage-list',
  templateUrl: './manage-list.component.html',
})
export class CoreManageListComponent
  extends ListComponentBase
  implements OnChanges, OnInit, OnDestroy
{
  errors: ValidationError[];
  gc: GC;
  //icon chargement pour l'edit
  iconEdit: string = 'fas fa-pencil-alt';
  /** Représente le compte du nombre d'élément mis dans la corbeille. */
  countRecycles: number = 0;
  /** Représente l'etat du chargement des permissions.*/
  alreadyGetPermission: boolean = false;
  /** Définit si la vérification de blocage est activé */
  @Input() autoBlock: boolean = false;
  timerAutoBlock;

  /** Représente la command 'new'. */
  @Input() newCommand: CommandItems = undefined;
  /** Représente la command 'edit'. */
  @Input() editCommand: CommandItems = undefined;
  /** Active ou désactive l'affichage du bouton retour. */
  @Input() showBack: boolean = false;
  /**  Obtient ou définit le mode de retour. - Si à 'true' l'event 'onBackClick' est émit */
  @Input() backMode: boolean = false;
  /** Obtient ou définit l'url de retour */
  @Input() backUrl: string;
  /** Obtient ou définit si on utilise le previous url */
  @Input() usePreviousBackUrl: boolean = false;
  /** Obtient ou définit le model basé sur le schema de la liste. */
  @Input() modelState: ModelState;
  /** Obtient ou définit les resources. */
  @Input() resources: any;
  /** Obtient ou définit les paramètres. */
  @Input() parameters: any;
  /** Obtient ou définit le titre. */
  @Input() title: string = undefined;
  /** Obtient ou définit les actions. */
  @ContentChild(ToolbarItemsComponent, { static: true })
  toolbar: ToolbarItemsComponent;
  /** Obtient ou définit la fonction de suppression. */
  @Input() deleteFunction: ModelFnContext;
  /** Obtient ou définit la fonction de comptage des éléments supprimés. */
  @Input() countRecyclesFunction: Function;
  /** Obtient ou définit la fonction isUsed. */
  @Input() isUsedFunction: ModelFnContext;
  /** Obtient ou définit si l'élément peut être mis en corbeille. */
  @Input() canRecycle: boolean = true;
  /** Obtient ou définit si l'élément peut être ajouté. */
  @Input() canAdd: boolean = true;
  /** Obtient ou définit si l'élément peut être édité. */
  @Input() canEdit: boolean = true;
  /** Obtient ou définit si l'élément peut être lu. */
  @Input() canRead: boolean = true;
  /** Obtient ou définit si l'élément peut être supprimé. */
  @Input() canDelete: boolean = true;
  /** Obtient ou définit si l'élément peut être importé. */
  @Input() canImport: boolean = true;
  /** Obtient ou définit si l'élément peut être activé ou désactivé. */
  @Input() canEnable: boolean = false;
  /** Obtient ou définit si la sélection est active. */
  @Input() canSelect: boolean = true;
  /** Cas d'une route d'edition différente de la route en cour */
  @Input() canExport: boolean = true;
  /** Cas d'une route d'edition différente de la route en cour */
  @Input() editRootRoute;
  /** Obtient definis le scope du state pour un metier */
  @Input() stateScope: string[];
  /** Obtient ou définit le composant WorkItems. */
  @ViewChild(CoreWorkItemsComponent, { static: true })
  workItemsComponent: CoreWorkItemsComponent;
  /** Obtient ou définit le service. */
  @Input() service: any;
  /** Se déclenche lors du changement de la selection d'une liste du menu de navigation */
  @Output() onNavListSelectionChanged: EventEmitter<any> =
    new EventEmitter<any>();
  /** Se déclenche lorsque le bouton nouveau est cliqué. */
  @Output() onNewClick = new EventEmitter<EventArgs<MouseEvent>>();
  /** Se déclenche sur le clic d'une ligne. */
  @Output() onRowClick: EventEmitter<ClickEventArgs<CoreListComponent, any>> =
    new EventEmitter<ClickEventArgs<CoreListComponent, any>>();

  @Output() onSingleCellClick: EventEmitter<
    ClickEventArgs<CoreListComponent, any>
  > = new EventEmitter<ClickEventArgs<CoreListComponent, any>>();

  /** Se déclenche sur si la sélection change. */
  @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter<any>();
  /** Indicateur pour savoir si on attends la mise en corbeille */
  @Input() deleteWaiting: boolean = false;
  /** Permet de repercuter la commandNew custom sur la colonne du tree */
  @Input() applyTreeCmd: boolean = true;
  /** Grise le bouton d'edit */
  disableBtnEdit: boolean = false;
  /** Obtient ou définit le type de la liste. */
  @Input() type: ListType = undefined;

  /** Obtient ou définit le delais de refresh du datasource. */
  @Input() delayAutoRefresh: number = undefined;

  /** Obtient ou définit si la checkbox auto-refresh est affichée ou non */
  @Input() displayAutoRefreshCheckbox: boolean = true;

  /** Si à false => Pause l'auto-refresh d'une liste pendant un certain moment (jusqu'au refresh de la page) */
  @Input() enableForceAutoRefresh: boolean = true;

  /** Donne l'accès aux actions de sélection/désélection du noeud parents */
  @Input() activeSelectLevelAction: boolean = true;

  /**Masque le bouton supprimer */
  hideDeleteButton: boolean = false;

  /**Met à jour le compteur sur les tickets lorsqu'on rafraichi la grille */
  refreshCounter: boolean = false;

  /** Se déclenche sur si la sélection change. */
  @Input() beforeDeleted: (e: any) => Promise<boolean> = (e) => {
    return of(true).toPromise();
  };

  /** Se déclenche aprés la supression. */
  @Output() onDeleted: EventEmitter<any> = new EventEmitter<any>();

  setEnableSource: any[];
  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private servicePermission: ServicePermission,
    private policyValidator: CorePolicyValidator,
    private gcFactory: GCFactory,
    private _state: StateManagementService,
    public localStorageService: LocalStorageService,
  ) {
    super();
    this.gc = gcFactory.create();
    this.setEnableSource = [
      {
        id: 'enable',
        name: TranslateService.get('globals/enable'),
      },
      {
        id: 'disable',
        name: TranslateService.get('globals/disable'),
      },
    ];
    if (this.stateScope == undefined || this.stateScope.length == 0) {
      this.stateScope = [window.location.pathname];
    }
  }

  ngOnDestroy(): void {
    this.gc.dispose();
    if (this.timerAutoBlock != undefined) {
      clearTimeout(this.timerAutoBlock);
    }
  }

  setIconLoading() {
    this.iconEdit = 'fas fa-circle-notch fa-spin';
    this.disableBtnEdit = true;
  }

  setIconBasic() {
    this.iconEdit = 'fas fa-pencil-alt';
    this.disableBtnEdit = false;
  }
  /** @inheritdoc */
  ngOnInit(): void {
    // Save la loction
    sessionStorage.setItem('prevState', window.location.pathname);

    if (this.stateScope != undefined && this.stateScope.length > 0) {
      this._state.activeState(this.stateScope);
    }

    if (this.service != undefined) {
      this.getPermission();
    } else {
      throw new Error(
        `La propriété service est requise sur 'ManageListComponent'.`,
      );
    }

    /** Liste des paramètres ne devant pas rester */
    if (
      this.modelState != undefined &&
      this.modelState.model != undefined &&
      this.modelState.model.grid != undefined &&
      this.modelState.model.grid.layout != undefined
    ) {
      let removeParams = [];
      if (this.modelState.model.grid.layout.parentIdExpr != undefined) {
        removeParams.push(this.modelState.model.grid.layout.parentIdExpr);
      }
      if (this.modelState.model.grid.layout.commands != undefined) {
        this.modelState.model.grid.layout.commands.forEach((f) => {
          if (f['targetIdExpr'] != undefined) {
            removeParams.push(f['targetIdExpr']);
          }
        });
      }
      this.deleteParamsUrl(removeParams);
    }

    this.checkBlock();
  }

  get editRouterLink() {
    return [...this.editRoute, this.selectedKeys[0]];
  }

  get editRoute() {
    return this.editRootRoute == undefined
      ? ['edit']
      : [this.editRootRoute, 'edit'];
  }

  /** Permet de supprimer les paramètre inutile */
  deleteParamsUrl(names: string[]) {
    // Vérification si parentId est toujours présent
    if (
      names != undefined &&
      names.length > 0 &&
      this.route.snapshot.queryParamMap != undefined
    ) {
      let params = JSON.parse(
        JSON.stringify(this.route.snapshot.queryParamMap['params']),
      );
      if (params != undefined) {
        names.forEach((f) => delete params[f]);

        this.router.navigate(['.'], {
          relativeTo: this.route,
          queryParams: params,
        });
      }
    }
  }

  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.modelState != undefined &&
      changes.modelState.currentValue != undefined
    ) {
      this.type = this.modelState.model.grid.layout.type;
      this.getPermission();

      //Reset le filtre sur les permissions
      if (this.workItemsComponent?.list?.component != undefined) {
        this.workItemsComponent.list.component.clearFilter();
        //  this.workItemsComponent.list.clearFilter();
      }

      // this.canRecycle = this.modelState.model.grid.layout.canRecycle;
      if (this.modelState.model.grid.layout.delete != undefined) {
        this.deleteFunction = this.modelState.model.grid.layout
          .delete as any as ModelFnContext;
      }
      if (this.modelState.model.grid.layout.countRecycles) {
        this.countRecyclesFunction = (
          this.modelState.model.grid.layout.countRecycles as any
        ).fnCall as Function;
      }

      this.isUsedFunction = this.modelState.model.grid.layout
        .isUsed as unknown as ModelFnContext;

      if (this.canRecycle && this.countRecyclesFunction !== undefined) {
        this.countRecyclesFunction().subscribe(
          (result: ServiceSingleResultOfInt64) => {
            this.countRecycles = result.data;
          },
        );
      }
      // Créé la source de données pour le bouton 'new'.
      if (this.modelState.model.grid.layout.commands != undefined) {
        let index = this.modelState.model.grid.layout.commands.findIndex(
          (c) => c.name === 'new',
        );
        if (index > -1) {
          this.newCommand = this.modelState.model.grid.layout.commands[index];
          if (this.newCommand.source != undefined) {
            this.newCommand.source['datasource'].sort([
              { selector: this.newCommand.displayExpr, asc: true },
            ]);
          }
        }
      } else {
      }
      if (
        this.modelState.model.grid.layout.type === 'Tree' &&
        this.canAdd === true
      ) {
        let newCmd = this.newCommand;
        if (this.applyTreeCmd == false) {
          newCmd = undefined;
        }
        this.modelState.model.grid.layout.columns.unshift({
          allowResizing: false,
          template: 'buttonsTemplate',
          type: 'buttons',
          width: 50,
          minWidth: 50,
          parentIdExpr: this.modelState.model.grid.layout.parentIdExpr,
          source: newCmd && newCmd.source && newCmd.source['datasource'],
          keyExpr: (newCmd && newCmd.keyExpr) || 'id',
          displayExpr: newCmd && newCmd.displayExpr,
          targetIdExpr: newCmd && newCmd.targetIdExpr,
        });
      }
    }
  }
  getPermission() {
    if (this.alreadyGetPermission == false) {
      this.alreadyGetPermission = true;
      let policies = this.servicePermission.getAuthorizations(this.service);
      if (policies != undefined) {
        let operator = 'and';
        if (policies['operator'] != undefined) {
          operator = policies['operator'];
          policies = policies['policies'];
        } else if (policies['policies'] != undefined) {
          policies = policies['policies'];
        } else {
          if (policies['operator'] != undefined) {
            operator = policies['operator'];
            policies = policies['policies'];
          }
        }
        let canAddTmp = false;
        let canDeleteTmp = false;
        let canEditTmp = false;
        let canReadTmp = false;
        let canImportTmp = false;
        for (let policy of policies) {
          if (operator == 'or') {
            if (canAddTmp == false) {
              canAddTmp =
                this.canAdd &&
                this.policyValidator.validate(
                  policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
                );
            }
            if (canDeleteTmp == false) {
              canDeleteTmp =
                this.canDelete &&
                this.policyValidator.validate(
                  policy.indexOf('.') > -1 ? `${policy}` : `${policy}.delete`,
                );
            }
            if (canEditTmp == false) {
              canEditTmp =
                this.canEdit &&
                this.policyValidator.validate(
                  policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
                );
            }
            if (canReadTmp == false) {
              canReadTmp =
                this.canRead &&
                this.policyValidator.validate(
                  policy.indexOf('.') > -1 ? `${policy}` : `${policy}.read`,
                );
            }
            if (canImportTmp == false) {
              canImportTmp =
                this.canImport &&
                this.policyValidator.validate(
                  policy.indexOf('.') > -1
                    ? `${policy}`
                    : `${policy}.execute-import`,
                );
            }
          } else {
            this.canAdd =
              this.canAdd &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
              );

            this.canDelete =
              this.canDelete &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.delete`,
              );
            this.canEdit =
              this.canEdit &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
              );

            this.canRead =
              this.canRead &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.read`,
              );
            this.canImport =
              this.canImport &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1
                  ? `${policy}`
                  : `${policy}.execute-import`,
              );
          }

          this.canRecycle = this.canRecycle && this.canDelete;
        }

        if (operator == 'or') {
          this.canAdd = canAddTmp;
          this.canDelete = canDeleteTmp;
          this.canEdit = canEditTmp;
          this.canRead = canReadTmp;
          this.canImport = canImportTmp;
        }
      }
    }
  }

  deleteLoadIndicator = false;

  delete() {
    this.errors = undefined;
    // Confirmation
    let title = 'confirm-delete';
    let header =
      this.countRecyclesFunction != undefined
        ? 'confirm-recycle-title'
        : 'confirm-permanent-delete-title';

    // Vérification si l'item est utilisé en base de données
    let isUsed;
    if (this.isUsedFunction == undefined) {
      isUsed = of({ data: false });
    } else {
      this.isUsedFunction.context.params.set('ids', () => this.selectedKeys);
      isUsed = this.isUsedFunction.fnCall();
    }

    isUsed.subscribe((isUsed: ServiceSingleResultOfBoolean) => {
      // Message de confirmation indépendant
      let fncbeforeDeleted = this.beforeDeleted(this.selectedKeys);
      fncbeforeDeleted.then((s) => {
        // annule si le retoure est false
        if (s !== true) return;

        if (this.isUsedFunction != undefined) {
          this.isUsedFunction.context.params.remove('ids');
        }
        let confirmMessage: Promise<boolean> = Promise.resolve(true);
        title = TranslateService.get(title, {
          count: this.selectedKeys.length,
        });
        if (isUsed.data) {
          // Message utilisé
          title =
            TranslateService.get('warningIsUsed', {
              count: this.selectedKeys.length,
            }) +
            '<br><br>' +
            title;
        }

        // Message de confirmation de suppression
        if (this.countRecyclesFunction == undefined || isUsed.data) {
          confirmMessage = confirm(title, TranslateService.get(header));
        }

        // Suppression
        confirmMessage.then((result) => {
          if (result === true) {
            //Affiche le message d'avertissement
            //Possibilité d'afficher le message pour une suppression définitive
            if (this.deleteWaiting && this.countRecyclesFunction != undefined) {
              let warningMessage = TranslateService.get('trashIn');
              let messageWarning = alert(
                TranslateService.get('deleteWaiting', {
                  label: warningMessage,
                }),
                TranslateService.get('globals/warning'),
              );
              messageWarning.then((res) => {
                this.deleteAction();
              });
            } else {
              this.deleteAction();
            }
          }
        });
      });
    });
  }

  public deleteAction(): void {
    this.deleteLoadIndicator = true;

    this.deleteFunction.context.params.set('ids', () => this.selectedKeys);
    let toUnsubscribe = this.deleteFunction
      .fnCall()
      .subscribe((result: ServiceSingleResultOfBoolean) => {
        let typeError = 'error';
        this.deleteFunction.context.params.remove('ids');
        if (result.data) {
          notify(TranslateService.get('deletedSuccess'), 'success', 5000);
          typeError = 'warning';
        }
        if (result.errors != undefined && result.errors.length > 0) {
          this.errors = result.errors;
          notify(result.errors[0].messageError, typeError, 5000);
        } else if (!result.data) {
          notify(TranslateService.get('deletedError'), typeError, 5000);
        }

        this.reloadNavMenuConst();
        this.checkBlock();
        if (
          this.workItemsComponent != undefined &&
          this.workItemsComponent.list.refresh != undefined
        ) {
          this.workItemsComponent.list.refresh();
        }
        if (
          this.workItemsComponent != undefined &&
          this.workItemsComponent.list.component != undefined &&
          this.workItemsComponent.list.clearSelection != undefined
        ) {
          this.workItemsComponent.list.component.clearSelection();
        }
        this.selectedKeys.clear();
        // Rafraîchi le total en corbeille
        if (this.countRecyclesFunction != undefined) {
          this.countRecyclesFunction().subscribe((result) => {
            this.countRecycles = result.data;
          });
        }
        this.deleteLoadIndicator = false;
        this.onDeleted.emit(result);
      });
    this.gc.forDispose(toUnsubscribe);
  }

  /** Emit le pour indiquer un refresh des compteur d'un menu */
  reloadNavMenuConst() {
    if (this.localStorageService?.ModelState != undefined) {
      this.localStorageService.ModelState.on.emit({
        eventName: LocalStorageService.EventConst.ReloadNavMenuConst,
        eventData: undefined,
      });
    }
  }

  async refresh() {
    this.checkBlock();
    this.reloadNavMenuConst();
    await this.workItemsComponent.refresh();
  }
  newClick(e) {
    this.onNewClick.emit(e);
    if (!e.event.isDefaultPrevented()) {
      this.router.navigate(['new'], {
        relativeTo: this.route,
        queryParams: this.route.snapshot.queryParams,
      });
    }
  }
  newItemClick(e) {
    let keyExpr = this.newCommand.keyExpr || 'id';
    let targetIdExpr = this.newCommand.targetIdExpr;
    if (targetIdExpr == undefined) {
      throw new Error(`La propriété "targetIdExpr" doit être définie.`);
    }
    let queryParams = { ...this.route.snapshot.queryParams };
    if (targetIdExpr != undefined) {
      queryParams[targetIdExpr] = e.itemData[keyExpr];
    }
    if (this.newCommand.onItemClick != undefined) {
      this.newCommand.onItemClick(e);
    } else {
      this.router.navigate(['new'], {
        relativeTo: this.route,
        queryParams: queryParams,
      });
    }
  }

  editClick(e) {
    if (this.selectedKeys.length !== 1 && this.editCommand.splitButton) {
      return null;
    }

    if (!e.event.isDefaultPrevented()) {
      this.router.navigate([...this.editRoute, this.selectedKeys[0]], {
        relativeTo: this.route,
        queryParams: this.route.snapshot.queryParams,
      });
    }
  }

  editItemClick(e) {
    if (this.editCommand.onItemClick != undefined) {
      this.editCommand.onItemClick(e);
    }
  }

  navListSelectionChanged(e) {
    this.onNavListSelectionChanged.emit(e);
  }

  /**
   * Intercepte le changement de sélection.
   */
  selectionChanged(e) {
    this.onSelectionChanged.emit(e);
  }

  singleCellClick(e: ClickEventArgs<CoreListComponent, any>) {
    this.onSingleCellClick.emit(e);
  }

  /**
   * Intercepte le click d'une ligne.
   */
  rowClick(e: ClickEventArgs<CoreListComponent, any>) {
    this.onRowClick.emit(e);
    if (e.event != undefined) {
      if (e.event instanceof Event && e.event.type == 'dxclick') {
        if (!(<any>e.event).isDefaultPrevented() && this.canEdit == true) {
          // TODO setTimeout pour laisser le composent remplir le selectedData.
          setTimeout(() => {
            let queryParams = { ...this.route.snapshot.queryParams }; //{ ...this.route.snapshot.params };
            if (
              this.type === 'Tree' &&
              e.component.parentIdExpr != undefined &&
              // A laisser pour éviter de chercher une erreur pour rien
              // e.component.selectedData.length > 0 &&
              e.component.selectedData[0][e.component.parentIdExpr] != undefined
            ) {
              queryParams[e.component.parentIdExpr] =
                e.component.selectedData[0][e.component.parentIdExpr];
            }

            if (e.ctrl) {
              let url = this.router.createUrlTree(
                [...this.editRoute, e.value],
                {
                  relativeTo: this.route,
                  queryParams: queryParams,
                },
              );
              window.open(url.toString(), '_blank');
            } else {
              this.router.navigate([...this.editRoute, e.value], {
                relativeTo: this.route,
                queryParams: queryParams,
              });
            }
          }, 50);
        }
      } else if (e.event instanceof Event && e.event.type == 'click') {
        if (this.canRead == true) {
          setTimeout(() => {
            let queryParams = { ...this.route.snapshot.queryParams }; //{ ...this.route.snapshot.params };
            // A laisser pour éviter de chercher une erreur pour rien
            // e.component.selectedData.length > 0 &&

            if (e.ctrl) {
              let url = this.router.createUrlTree(
                [...this.editRoute, e.value],
                {
                  relativeTo: this.route,
                  queryParams: queryParams,
                },
              );
              window.open(url.toString(), '_blank');
            } else {
              this.router.navigate([...this.editRoute, e.value], {
                relativeTo: this.route,
                queryParams: queryParams,
              });
            }
          }, 50);
        }
      } else if (e.event instanceof Event && e.event.type == 'auxclick') {
        if (this.canEdit == true) {
          setTimeout(() => {
            let queryParams = { ...this.route.snapshot.queryParams };

            let url = this.router.createUrlTree([...this.editRoute, e.value], {
              relativeTo: this.route,
              queryParams: queryParams,
            });
            window.open(url.toString(), '_blank');
          }, 50);
        }
      }
    }
  }

  /** Permet d'effacer les erreurs en cours */
  clearErrors() {
    this.errors = undefined;
  }

  /** Permet d'ajouter une erreur */
  addError(error: ValidationError) {
    if (this.errors == undefined) {
      this.errors = [];
    }

    this.errors.push(error);
  }
  /** Permet d'ajouter des erreurs */
  addErrors(errors: ValidationError[]) {
    if (errors != undefined) {
      if (this.errors == undefined) {
        this.errors = [];
      }
      errors.forEach((f) => this.errors.push(f));
    }
  }

  /** Sur le rafraîchissement */
  onBeforeRefresh(e) {
    if (e != undefined && e.clearError) {
      this.clearErrors();
    }

    if (this.refreshCounter == true) {
      this.reloadNavMenuConst();
    }
  }

  setEnableEvent(e) {
    switch (e.itemData.id) {
      case 'enable':
        this.setEnable(true);
        break;
      case 'disable':
        this.setEnable(false);
        break;
    }
  }

  setEnable(state) {
    this.service['setEnable'](
      ModelFieldCompilerService.createServiceSingleResultScalar(),
      state,
      this.selectedKeys,
    ).subscribe((result) => {
      this.workItemsComponent.list.refresh();
    });
  }

  /** Vérification automatique du blocage */
  checkBlock() {
    if (
      this.autoBlock == true &&
      this.service != undefined &&
      this.service['hasBlocked']
    ) {
      this.service
        .hasBlocked([
          GqlSubField.create('data', [GqlField.create('description')]),
        ])
        .subscribe((r) => {
          if (r?.data?.description != undefined) {
            this.localStorageService.emitActionProgress(
              true,
              r?.data?.description,
            );

            if (this.timerAutoBlock != undefined) {
              clearTimeout(this.timerAutoBlock);
            }
            // timer de vérification
            this.timerAutoBlock = setTimeout(() => {
              this.checkBlock();
            }, 10000);
          } else {
            this.localStorageService.emitActionProgress(false, undefined);
          }
        });
    }
  }
}
