<dx-popup
  #popup
  [width]="800"
  [height]="'80%'"
  [showTitle]="false"
  class="add-item-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="showViewer"
  (onHiding)="onHiding($event)"
>
  <dx-load-panel
    #loadPanel
    class="add-item-load"
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="waitLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  >
  </dx-load-panel>
  <dx-box
    direction="col"
    class="viewerFullSize"
    style="border-width: 18px 0px 0px 0px; border-style: solid"
    [ngStyle]="{ 'border-color': color }"
  >
    <dxi-item
      [baseSize]="80"
      style="border-width: 0px 10px 0px 10px; border-style: solid"
      [ngStyle]="{ 'border-color': color }"
    >
      <dx-box direction="row">
        <dxi-item [baseSize]="110">
          <div
            style="font-size: 6em; padding-left: 0.2em; padding-right: 0.2em"
            [class]="icon"
            [ngStyle]="{ color: color }"
          ></div>
        </dxi-item>
        <dxi-item
          [ratio]="1"
          style="display: flex; justify-content: center; padding-left: 0px"
        >
          <div>
            <div
              style="font-size: 1.6em; font-weight: bold"
              [ngStyle]="{ color: secondColor }"
            >
              {{ title }}
            </div>
            <div
              style="font-size: 10pt; font-style: italic"
              [ngStyle]="{ color: secondColor }"
            >
              <span *ngIf="lastUpdateBy != ''"
                >{{ lastUpdateBy }} - {{ lastUpdated }}</span
              >
            </div>
          </div>
        </dxi-item>
      </dx-box>
    </dxi-item>
    <dxi-item
      [ratio]="1"
      style="border-width: 0px 10px 0px 10px; border-style: solid"
      [ngStyle]="{ 'border-color': color }"
    >
      <dx-html-editor
        height="100%"
        readOnly="true"
        [value]="getTranlateContent(content)"
      ></dx-html-editor>
    </dxi-item>

    <dxi-item
      [baseSize]="50"
      style="border-width: 0px 10px 0px 10px; border-style: solid"
      [ngStyle]="{ 'border-color': color }"
      *ngIf="showEvaluateZone != undefined"
    >
      <div style="display: flex; padding-top: 5px">
        <div style="width: 50%; padding-left: 10px">
          <div>
            {{ 'globals/averageRating' | translate }} ({{ countRating }})
          </div>
          <div style="float: left">
            <star-rating
              [disabled]="true"
              [starType]="'svg'"
              [rating]="averageRating"
            ></star-rating>
          </div>
        </div>
        <div style="width: 50%; text-align: right; padding-right: 10px">
          <div>{{ 'globals/personalRating' | translate }}</div>
          <div style="float: right">
            <star-rating
              [starType]="'svg'"
              [hoverEnabled]="true"
              [rating]="personalRating"
              (starClickChange)="starClickChange($event)"
            ></star-rating>
          </div>
        </div>
      </div>
    </dxi-item>
    <dxi-item [baseSize]="45">
      <div
        class="viewerFullSize"
        [ngStyle]="{ background: color }"
        style="display: flex; align-items: center"
      >
        <div style="width: 50%; padding-left: 5px"></div>
        <div style="width: 50%; text-align: right; padding-right: 5px">
          <div style="float: right">
            <div class="dropup">
              @if (!isViewHelpMe){
                <dx-button
                  [visible]="
                    serviceName == 'ArticleKnowledgeCoreService' || 
                    serviceName == 'NewsCoreService'"
                  [text]="'entities/articleKnowledge/links' | translate"
                  icon="fas fa-link"
                  [disabled]="id === undefined"
                  id="dropup-dx-button"
                >
                </dx-button>
              }

              <div class="dropup-content">
                @if (!isHelpDeskUser()) {
                  <a
                    type="button"
                    (click)="getArticleLink(serviceName)"
                  >
                  {{ 'entities/articleKnowledge/operatorLink' | translate }}
                  </a>
                }
                <a type="button" (click)="getArticleLink(serviceName, true)">
                  {{ 'entities/articleKnowledge/helpMeLink' | translate }}
                </a>
              </div>
            </div>

            <dx-button
              [visible]="printVisible"
              [text]="'globals/printForm' | translate"
              icon="fal fa-print"
              [disabled]="id === undefined"
              (onClick)="printForm($event)"
            >
            </dx-button>

            <dx-button [text]="'close' | translate" (onClick)="close()">
            </dx-button>
          </div>
        </div>
      </div>
    </dxi-item>
  </dx-box>
</dx-popup>
