import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';

/** Représente une classe d'aide au FormGroup. */
/** TODO A intégrer à FormGroup. */
export class FormGroupHelpers {
  public static valid(
    form: UntypedFormGroup,
    excludeFields: string[] = [],
    excludeNotVisibledField: boolean = false,
  ): boolean {
    let controls = FormGroupHelpers.formControls(form);

    if (excludeFields != undefined && excludeFields.length > 0) {
      controls = controls.filter(
        (f) => excludeFields.filter((ex) => ex == f.key).length == 0,
      );
    }

    if (excludeNotVisibledField) {
      controls = controls.filter((f) => f.value.visibled != false);
    }

    let valid = true;
    if (form != undefined && form.loaded === true) {
      for (let keyValue of controls.filter(
        (c) => c.value.visibled === true && c.value.enabled === true,
      )) {
        valid = keyValue.value.valid;

        if (valid === false) {
          break;
        }
      }
    }

    return valid;
  }

  /*
  cette fonction permet de retournée un event de chargeement du formulaire
  on utilise cette focntion pour geré au cas ou on est deja passé dans l'event formReady.
   */
  public static onFormLoaded(state: ModelState) {
    if (state?.formComponent.isFormLoaded === true) {
      return of(true);
    } else {
      return state.formComponent.formReady.pipe(map((res) => res));
    }
  }

  /** Récupère le control par défaut. */
  public static getDefaultControl(
    form: UntypedFormGroup,
    translatedFieldService: TranslatedFieldHelperService,
  ): Observable<string> {
    //FormControl {
    return form.valueChanges.pipe(
      map((r) => {
        let defaultControl = FormGroupHelpers.getDefaultCtl(form);
        if (defaultControl != undefined) {
          if (defaultControl.isTranslatable === true) {
            return translatedFieldService.findValueToShow(defaultControl.value);
          }
          return defaultControl.value;
        } else if (form.isDefault != undefined) {
          if (typeof form.isDefault === 'function') {
            return (form.isDefault as Function)();
          }
          return form.isDefault;
        }
      }),
    );
  }

  public static getDefaultCtl(form: UntypedFormGroup): UntypedFormControl {
    for (let key in form.controls) {
      let control = form.controls[key];
      if (
        control instanceof UntypedFormControl &&
        (<any>control).isDefault === true
      ) {
        return control;
      } else if (control instanceof UntypedFormGroup) {
        let result = FormGroupHelpers.getDefaultCtl(control);
        if (result != undefined) {
          return result;
        }
      }
    }
    return undefined;
  }

  /** Récupère un control via son nom. */
  public static formControlByName(
    form: UntypedFormGroup,
    name: string,
  ): UntypedFormControl {
    for (let key in form.controls) {
      let control = form.controls[key];
      if (control instanceof UntypedFormControl && name === key) {
        return control;
      } else if (control instanceof UntypedFormGroup) {
        let result = this.formControlByName(control, name);
        if (result != undefined) {
          return result;
        }
      }
    }
    return undefined;
  }

  /** Permet de lister tous les contrôles du form group. */
  public static formControls(form): {
    key: string;
    value: UntypedFormControl;
  }[] {
    let controls: { key: string; value: UntypedFormControl }[] = [];
    if (form != undefined && form.controls != undefined) {
      Object.keys(form.controls).forEach((key) => {
        if (!!form.controls[key].controls) {
          let results = this.formControls(form.controls[key]);
          if (results.length > 0) {
            controls = controls.concat(results);
          }
        } else {
          controls.push({ key: key, value: form.controls[key] });
        }
      });
    }
    return controls;
  }

  public static forceDefaultValue(form) {
    let controls = FormGroupHelpers.formControls(form);
    for (let i = 0; i < controls.length; i++) {
      let control = FormGroupHelpers.formControlByName(form, controls[i].key);
      if (control != undefined) {
        if (control.value != control.originalValue) {
          control.originalValue = control.value;
        }
      }
    }
  }

  /** Permet de trouver les forms groups via le nom. */
  public static findFormGroupByName(
    form: UntypedFormGroup,
    name: string,
  ): UntypedFormGroup {
    let formGoup: UntypedFormGroup;
    if (form != undefined) {
      for (let key in form.controls) {
        let value = <UntypedFormGroup>form.controls[key];
        if (form.controls[key] instanceof UntypedFormGroup) {
          if (key === name) {
            formGoup = value;
            break;
          } else {
            formGoup = this.findFormGroupByName(value, name);
            if (formGoup != undefined) {
              break;
            }
          }
        }
      }
    }
    return formGoup;
  }

  /** Permet de trouver les forms groups via le label. */
  public static findFormGroupByLabel(
    form: UntypedFormGroup,
    label: string,
  ): UntypedFormGroup {
    let formGoup: UntypedFormGroup;
    if (form != undefined) {
      for (let key in form.controls) {
        let value = <UntypedFormGroup>form.controls[key];
        if (form.controls[key] instanceof UntypedFormGroup) {
          if (key === label) {
            formGoup = value;
            break;
          } else {
            formGoup = this.findFormGroupByLabel(value, label);
            if (formGoup != undefined) {
              break;
            }
          }
        }
      }
    }
    return formGoup;
  }

  /** Recherche dans le model une property  */
  public static findControlInModel(
    model: any,
    fieldName: string,
    propertyKeyName: string = 'fieldName',
  ): any {
    if (model == undefined || fieldName == undefined) {
      return null;
    }

    if (Array.isArray(model)) {
      //case array
      for (let keyValueItem of model as Array<any>) {
        let foundedOrNot = this.findControlInModel(
          keyValueItem,
          fieldName,
          propertyKeyName,
        );

        if (foundedOrNot != null) {
          return foundedOrNot;
        }
      }
    } else {
      //case obj
      let keys = Object.keys(model);

      for (let key of keys) {
        let keyValue = model[key];

        if (keyValue != undefined && keyValue[propertyKeyName] === fieldName) {
          if (keyValue.type != undefined) {
            //Vérification si un type de control est présente
            return keyValue;
          }
        }

        if (
          keyValue != undefined &&
          key === propertyKeyName &&
          keyValue === fieldName
        ) {
          if (model.type != undefined) {
            //Vérification si un type de control est présente
            return model;
          }
        }

        if (
          keyValue != undefined &&
          (Array.isArray(keyValue) || typeof keyValue === 'object')
        ) {
          let items = Array.isArray(keyValue)
            ? (keyValue as Array<any>)
            : Object.values(keyValue);

          for (let keyValueItem of items) {
            let foundedOrNot = this.findControlInModel(
              keyValueItem,
              fieldName,
              propertyKeyName,
            );

            if (foundedOrNot != null) {
              return foundedOrNot;
            }
          }
        }
      }
    }
    return null;
  }

  /**
   * Permet de set la visibilitée sur tous le form group
   * @param formGroup
   * * @returns
   */
  public static fixFormGroupVisibility(formGroup: UntypedFormGroup): boolean {
    if (formGroup.controls == undefined) {
      if (formGroup.visibled == undefined) {
        formGroup.visible();
      }
      return formGroup.visibled;
    }
    let result: boolean;

    if (Array.isArray(formGroup.controls)) {
      (formGroup.parent.controls as AbstractControl[]).forEach((value) => {
        let ret = this.fixFormGroupVisibility(value as UntypedFormGroup);
        if (ret === true) {
          result = true;
        }
      });
    } else {
      Object.values(formGroup.controls).forEach((value) => {
        let ret = this.fixFormGroupVisibility(value as UntypedFormGroup);
        if (ret === true) {
          result = true;
        }
      });
    }

    //Attention avec l'application des formulaire soumis au model rule
    // Les modele rule sont appliqué avant, et les controles activé ou désactivé avant
    // Raison pour laquel on ne reforce pas le l'activation ici
    if (result === true) {
      formGroup.visible(false);
    } else {
      formGroup.invisible();
    }
    return formGroup.visibled;
  }
}
