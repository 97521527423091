<clc-work-sub-form
  [rawModel]="model"
  [saveTitle]="'validate'"
  [heightPopup]="popupHeight"
  [widthPopup]="'40%'"
  [disableSaveNotification]="true"
  [forceSaveBtn]="true"
  [parentModel]="parentState"
  [(isPopupOpen)]="isPopupOpen"
  [service]="fakeDesignerCoreService"
  (onSaved)="saved($event)"
  [fieldName]="'parentId'"
  [key]="'test'"
  [component]="this"
></clc-work-sub-form>
<!--<dx-button-->
<!--  icon="fal fa-plus"-->
<!--  (onClick)="designerClick('CTRL')"-->
<!--  class="cl-item-button-alert"-->
<!--  text="visible"-->

<!--&gt;-->
<!--</dx-button>-->
