import { Component, HostBinding, Input } from '@angular/core';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import {
  DesignerEventAction,
  DesignerEventData,
  DesignerEventKey,
  DesignerEventType,
  FormDesignerPopupComponent,
  TranslateService,
} from '@clarilog/shared2';

/** Permet de choisir l'emplacement de la section
 * before : en premier
 * center : au milieu
 * after : en dernier
 */
export type WorkItemSectionLocation = 'before' | 'after' | 'center' | 'full';

/** Représente une section d'un formulaire. */
@Component({
  selector: 'cl-work-item-section',
  templateUrl: './work-item-section.component.html',
  styleUrls: ['./work-item-section.component.scss'],
})
export class WorkItemSectionComponent {
  /** Représente l'emplacement. */
  @Input() location: WorkItemSectionLocation = 'before';
  /** Obtient la classe css du composant par rapport à la location. */
  @HostBinding('class')
  public get getCssClasses(): any {
    return this.location;
  }

  /** USed for designer**/
  getAction = DesignerEventAction;
  @Input() designerMode = false;
  @Input() designerId: string;
  @Input() state: ModelState;

  formAction(e: any, action: DesignerEventAction) {
    e.preventDefault();
    e.stopPropagation();

    this.state.on.emit({
      eventName: DesignerEventKey,
      eventData: new DesignerEventData(
        DesignerEventType.SECTION,
        action,
        this.designerId,
      ),
    });
    e.prevent;
  }

  // contextMenu(e: MouseEvent) {
  //   if (this.designerMode === true) {
  //     FormDesignerPopupComponent.actionDialog({
  //       title:  TranslateService.get('entities/formDesigner/popupTitleSection'),
  //       message: '<b>' + TranslateService.get('entities/formDesigner/popupDescrSection') + ' :</b><br>' + (this.location ?? "Standard"),
  //       hasIn: true,
  //       hasEdit: true,
  //       hasRemove: true,
  //     }).show().then(dialogResult => {
  //       if (dialogResult.buttonText === 'addIn') {
  //         this.state.on.emit({eventName: DesignerEventKey, eventData: new DesignerEventData(DesignerEventType.SECTION, DesignerEventAction.ADD_IN, this.designerId)})

  //       } else if (dialogResult.buttonText === 'remove') {
  //         this.state.on.emit({eventName: DesignerEventKey, eventData: new DesignerEventData(DesignerEventType.SECTION, DesignerEventAction.REMOVE, this.designerId)})

  //       } else if (dialogResult.buttonText === 'edit') {
  //         this.state.on.emit({eventName: DesignerEventKey, eventData: new DesignerEventData(DesignerEventType.SECTION, DesignerEventAction.EDIT, this.designerId)})

  //       }
  //       else if (dialogResult.buttonText === 'cancel') {
  //         //nada
  //       }
  //     });

  //     return false;
  //   }
  //   return true;
  // }

  constructor() {}
}
