import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { IncidentCoreService } from '@clarilog/core/services2/graphql/generated-types/services/incident.service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { RequestCoreService } from '@clarilog/core/services2/graphql/generated-types/services/request.service';
import { ContentCustomization } from '@clarilog/core/services2/graphql/generated-types/types';
import {
  ChatBotCoreService,
  FileManagerCoreService,
  OrganizationCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { EnvironmentService } from '@clarilog/core/services';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import { DOCUMENT } from '@angular/common';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

enum types {
  incident,
  request,
}

/** Représente la classe du composent cl-dashboard-user. */
@Component({
  selector: 'clc-dashboard-user-container',
  templateUrl: './dashboard-user-container.component.html',
  styleUrls: ['./dashboard-user-container.component.scss'],
})
export class CoreDashboardUserContainerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /** Obtient ou définit la source de données pour les Kbs. */
  sourceKbContext: ModelDataSourceContext;
  sourceNewsContext: ModelDataSourceContext;
  sourceMessages: ModelDataSourceContext;
  //sourceMessagesContext: ModelDataSourceContext;
  nbrNewIncident: number = 0;
  nbrOpenIncident: number = 0;
  nbrProcessedIncident: number = 0;
  nbrClosedIncident: number = 0;
  nbrMyServiceIncident: number = 0;
  nbrMyAreaIncident: number = 0;
  nbrMyAreaRequest: number = 0;
  nbrNewRequest: number = 0;
  nbrOpenRequest: number = 0;
  nbrProcessedRequest: number = 0;
  nbrClosedRequest: number = 0;
  img64: string;
  fileId: string;
  img64Incident: string;
  fileIncidentId: string;
  img64Request: string;
  fileRequestId: string;
  urlImgRequest: string;
  urlImgIncident: string;
  myTheme: ContentCustomization;
  private _gc: GC;
  littleText: string;
  boldTexte: string;
  accessTicketsObserverLabel: string;
  timer: NodeJS.Timeout;
  nbrMyServiceRequest: number = 0;
  authorizationService: AuthorizationCoreService;
  autoTimerRefresh: number = undefined;
  isAccesTicketObserver: boolean = false;
  isAccessMyService: boolean = true;
  constructor(
    private router: Router,
    private incidentService: IncidentCoreService,
    private requestService: RequestCoreService,
    private _authorizationService: AuthorizationCoreService,
    private _envService: EnvironmentService,
    private organizationService: OrganizationCoreService,
    public fileManagerService: FileManagerCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private localStorageService: LocalStorageService,
    gcFactory: GCFactory,
    private _renderer2: Renderer2,
    private chatbotService: ChatBotCoreService,
    @Inject(DOCUMENT) private _document: Document,
    private titleService: Title
  ) {
    this._gc = gcFactory.create();

    if (_authorizationService.user.hasTimerHelpMe) {
      this.autoTimerRefresh = _authorizationService.user.getTimerHelpMe();
      // Refrsh auto
      this.timer = setInterval(() => {
        this.initDataSource();
      }, _authorizationService.user.getTimerHelpMe());
    }

    chatbotService.processWikitChatBot(this._renderer2,this._document, true)

    this.authorizationService = _authorizationService;

    this.sourceKbContext = new ModelDataSourceContext({
      serviceName: 'ArticleKnowledgeCoreService',
      methodName: 'findVisibleOnHelpMePortal',
    });

    this.sourceNewsContext = new ModelDataSourceContext({
      serviceName: 'NewsCoreService',
      methodName: 'findVisibleOnHelpMePortal',
    });

    this.sourceMessages = new ModelDataSourceContext({
      serviceName: 'MessageCoreService',
      methodName: 'findVisibleOnHelpMePortal',
    });
    if (this.authorizationService.user.getCustomization() != undefined) {
      // Récupère le theme de l'organisation pour helpme
      this.myTheme = this.authorizationService.user.getCustomization();
      this.fileId = this.authorizationService.user.getLogo();
      this.fileIncidentId = this.authorizationService.user.getImgIncident();
      this.urlImgIncident = this.authorizationService.user.getUrlImgIncident();
      this.fileRequestId = this.authorizationService.user.getImgRequest();
      this.urlImgRequest = this.authorizationService.user.getUrlImgRequest();
      if (
        this.fileId != undefined &&
        this.fileId != '00000000-0000-0000-0000-000000000000'
      ) {
        this.img64 = `${this._envService.apiURL}/file/get/${this.fileId}`;
      }
      if (
        this.fileIncidentId != undefined &&
        this.fileIncidentId != '00000000-0000-0000-0000-000000000000'
      ) {
        this.img64Incident = `${this._envService.apiURL}/file/get/${this.fileIncidentId}`;
      } else {
        this.img64Incident = '/static-files/illustration-incidents.svg';
      }
      if (
        this.fileRequestId != undefined &&
        this.fileRequestId != '00000000-0000-0000-0000-000000000000'
      ) {
        this.img64Request = `${this._envService.apiURL}/file/get/${this.fileRequestId}`;
      } else {
        this.img64Request = '/static-files/illustration-demandes.svg';
      }
      if (this.urlImgIncident == undefined || this.urlImgIncident == '') {
        this.urlImgIncident = `/help-desk-incident`;
      }
      if (this.urlImgRequest == undefined || this.urlImgRequest == '') {
        this.urlImgRequest = `/help-desk-request`;
      }
    } else {
      let myThemeField = [
        GqlSubField.create('data', [
          GqlSubField.create('helpMe', [
            GqlSubField.create('littleText', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlSubField.create('boldTexte', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlSubField.create('accessTicketsObserverLabel', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlField.create('primaryColor'),
            GqlField.create('secondaryColor'),
            GqlField.create('headBandColor'),
            GqlField.create('name'),
          ]),
        ]),
      ];

      this._gc.forDispose(
        this.organizationService
          .findContentCustomizationOrga(myThemeField)
          .subscribe((value) => {
            this.myTheme = value.data;
          }),
      );
    }
    this.boldTexte =
      this.myTheme.helpMe?.boldTexte[
        this.translatedFieldHelperService.getTranslateKey()
      ];
    this.accessTicketsObserverLabel =
      this.myTheme.helpMe?.accessTicketsObserverLabel[
        this.translatedFieldHelperService.getTranslateKey()
      ];
    this.littleText =
      this.myTheme.helpMe?.littleText[
        this.translatedFieldHelperService.getTranslateKey()
      ];

    this.localStorageService.user.getHelpDeskPrivilege().subscribe((value) => {
      this.isAccesTicketObserver =
        value?.data?.accessTicketsObserverEntries?.filter(
          (x) => x.enable == true,
        ).length > 0;

      this.isAccessMyService =
        value?.data?.responsableUserEntries?.filter(
          (x) => x.enable == true && x.key == 'show-my-service',
        ).length > 0;
    });

    this.initDataSource();
  }

  /**
   * Permet de définir le titre de l'onglet du navigateur
   */
  setTitleTabNavigator() {
    const title = TranslateService.get('globals/home-help-desk-user') + ' - One by Clarilog';
    this.titleService.setTitle(title);
  }

  /** @inheritdoc */
  async ngOnInit() {
    if (
      window.location.host.includes('presentation') === true &&
      this._envService.isProduction === true
    ) {
      let script = this._renderer2.createElement('script');
      script.type = `text/javascript`;
      script.id = 'WIKIT-CHATBOT-SCRIPT';
      script.text = `
 wrapWebchat({
    color: '#243469', // La couleur d'arrière plan du bouton de chat et du bandeau en haut de la fenêtre.
    webchatParams: { // Ces paramètres permettront d'identifier l'utilisateur.
      userId: '${this.authorizationService.user.getClaim(
        'name',
      )}', // <-- À DÉFINIR DYNAMIQUEMENT PAR L'APPLICATION APPELANTE
      userIdType: 'login', // <-- À POSITIONNER : login OU email
      userFirstName: '${this.authorizationService.user.getClaim(
        'firstName',
      )}', // <-- À DÉFINIR DYNAMIQUEMENT PAR L'APPLICATION APPELANTE (OU LAISSER VIDE)
      userLastName: '${this.authorizationService.user.getClaim(
        'family_name',
      )}', // <-- À DÉFINIR DYNAMIQUEMENT PAR L'APPLICATION APPELANTE (OU LAISSER VIDE)
      webChatToken: 'MWVlZGZjODQtNWZiZi00ZGE1LTk5YjQtZGRhZTgyZjI3ZTAzLTE2NjM5MjAxNTkyMzI=',
      originId: '${
        window.location.host
      }', // Indique l'endroit où est intégré le webchat (Information qui peut être retrouvée dans l'entraînement),
      customParams: {}, // Des paramètres personnalisés à ajouter dans l'url du chatbot,
      persona: null, // Le nom du persona à utiliser (optionnel)
    },
    height: '80%', // La hauteur de la fenêtre.
    width: '30%', // La largeur de la fenêtre.
    chatButtonIcon: { // Paramétrage du bouton de chat.
      url: "https://files.wikit.ai/cla/1717070988708-Neo.png", // Ajouter une image en arrière plan du bouton de chat.
      altText: null, // Text alternatif pour l'image (accessibilité).
      height: '50px', // Hauteur du bouton de chat.
      width: '50px', // Largeur du bouton de chat.
      borderRadius: '50%', // Rayon de la bordure du bouton de chat.
    },
    chatButtonTooltip: { // Afficher une bulle de texte à côté du bouton de chat.
      text: null, // Le texte du message
      backgroundColor: null, // La couleur d'arrière plan.
      textColor: null, // La couleur du texte.
      visibility: 'hidden', // La visibilité de la bulle au passage de la souris ('hidden') ou tout le temps ('visible').
    },
    chatButtonAnimation: { // Animation du bouton de chat
      delay: null, // Délais en millisecondes après lequel le bouton s'anime
      enabled: false, // Active l'animation
      openTooltip: false // Ouvre l'info-bulle après l'animation
    },
    headerButtons: { // Le texte d'accessibilité des boutons au haut de la fenêtre du webchat.
      color: '#fff',
      closeIconDescription: 'Fermer la fenêtre',
      launchIconDescription: 'Ouvrir dans un nouvel onglet',
      maximizeIconDescription: 'Agrandir la fenêtre',
      minimizeIconDescription: 'Rétrécir la fenêtre',
    },
    opening: { // L'état (ouvert / fermé) du webchat en arrivant sur la page. Noter qu'une ouverture automatique n\`'est pas possible sur mobile.
      mode: 'close', // 3 modes possibles, ouvert ('open'), fermé ('close') ou ouvert après un délais ('delay').
      delay: null, // Le délais en millisecondes pour l'ouverture du webchat.
      memorize: true, // Mémoriser l'état d'ouverture du webchat.
    },
    position: { // La position du bouton de chat et du webchat dans le navigateur.
      right: '1rem',
      bottom: '1rem',
    },
  });`;

      this._renderer2.appendChild(this._document.body, script);
    }

    if (
      window.location.host.includes('cc-macs') === true &&
      this._envService.isProduction === true
    ) {
      let script = this._renderer2.createElement('script');
      script.type = `text/javascript`;
      script.id = 'WIKIT-CHATBOT-SCRIPT';
      script.text = `
wrapWebchat({
       color: '#4281a4', // La couleur d'arrière plan du bouton de chat et du bandeau en haut de la fenêtre.
       webchatParams: { // Ces paramètres permettront d'identifier l'utilisateur.
          userId: '${this.authorizationService.user.getClaim(
            'name',
          )}', // <-- À DÉFINIR DYNAMIQUEMENT PAR L'APPLICATION APPELANTE
          userIdType: 'email', // <-- À POSITIONNER : login OU email
          userFirstName: '${this.authorizationService.user.getClaim(
            'firstName',
          )}', // <-- À DÉFINIR DYNAMIQUEMENT PAR L'APPLICATION APPELANTE (OU LAISSER VIDE)
          userLastName: '${this.authorizationService.user.getClaim(
            'family_name',
          )}', // <-- À DÉFINIR DYNAMIQUEMENT PAR L'APPLICATION APPELANTE (OU LAISSER VIDE)
          webChatToken: 'Y2U0YjM3YjItNmJkOC00ZTY1LTlmNzktZWFhYzU2NTVlYTQ1LTE2OTkyODk0ODM3NTc=',
          originId: '${
            window.location.host
          }', // Indique l'endroit où est intégré le webchat (Information qui peut être retrouvée dans l'entraînement),
          customParams: {}, // Des paramètres personnalisés à ajouter dans l'url du chatbot,
          persona: null, // Le nom du persona à utiliser (optionnel)
       },
      height: '80%', // La hauteur de la fenêtre.
          width: '30%', // La largeur de la fenêtre.
          chatButtonIcon: { // Paramétrage du bouton de chat.
              url: 'https://files.wikit.ai/ccm/1704785188547-Mia-Visage1.jpg', // Ajouter une image en arrière plan du bouton de chat.
              altText: null, // Text alternatif pour l'image (accessibilité).
              height: '50px', // Hauteur du bouton de chat.
              width: '50px', // Largeur du bouton de chat.
              borderRadius: '50%', // Rayon de la bordure du bouton de chat.
          },
          chatButtonTooltip: { // Afficher une bulle de texte à côté du bouton de chat.
              text: 'Comment puis-je vous aider ?', // Le texte du message
              backgroundColor: '#00afff', // La couleur d'arrière plan.
              textColor: null, // La couleur du texte.
              visibility: 'hidden', // La visibilité de la bulle au passage de la souris ('hidden') ou tout le temps ('visible').
          },
          chatButtonAnimation: { // Animation du bouton de chat
              delay: 2000, // Délais en millisecondes après lequel le bouton s'anime
              enabled: true, // Active l'animation
              openTooltip: true // Ouvre l'info-bulle après l'animation
          },
          headerButtons: { // Le texte d'accessibilité des boutons au haut de la fenêtre du webchat.
              color: '#fff',
              closeIconDescription: 'Fermer la fenêtre',
              launchIconDescription: 'Ouvrir dans un nouvel onglet',
              maximizeIconDescription: 'Agrandir la fenêtre',
              minimizeIconDescription: 'Rétrécir la fenêtre',
          },
          opening: { // L'état (ouvert / fermé) du webchat en arrivant sur la page. Noter qu'une ouverture automatique n\`'est pas possible sur mobile.
              mode: 'close', // 3 modes possibles, ouvert ('open'), fermé ('close') ou ouvert après un délais ('delay').
              delay: null, // Le délais en millisecondes pour l'ouverture du webchat.
              memorize: true, // Mémoriser l'état d'ouverture du webchat.
          },
          position: { // La position du bouton de chat et du webchat dans le navigateur.
              right: '1rem',
              bottom: '1rem',
          },
});`;

      this._renderer2.appendChild(this._document.body, script);
    }


    this.setTitleTabNavigator();
  }
  async initialized(e) {}

  createIncidentClick(event) {
    this.router.navigate([`/help-desk-incident/my/new`]);
  }

  createRequestClick(event) {
    this.router.navigate([`/help-desk-request/my/new`]);
  }
  openTypeListByStatus(type: types, status: string) {
    this.router.navigate([`help-desk-${types[type]}/${status}`]);
  }
  initDataSource() {
    let field = [
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('id'),
      ]),
    ];

    this.incidentService
      .findByStatusKey(field, null, 'new')
      .toPromise()
      .then((r) => {
        this.nbrNewIncident = r.data.length;
      });

    this.incidentService
      .findByStatusKey(field, null, 'in-progress')
      .toPromise()
      .then((r) => {
        this.nbrOpenIncident = r.data.length;
      });

    this.incidentService
      .findByStatusKey(field, null, 'resolved')
      .toPromise()
      .then((r) => {
        this.nbrProcessedIncident = r.data.length;
      });

    this.incidentService
      .findByStatusKey(field, null, 'closed')
      .toPromise()
      .then((r) => {
        this.nbrClosedIncident = r.data.length;
      });

    this.incidentService
      .findByCurrentSubordinate(field, null)
      .toPromise()
      .then((r) => {
        this.nbrMyServiceIncident = r.data.length;
      });

    this.incidentService
      .findByCurrentArea(field, null)
      .toPromise()
      .then((r) => {
        this.nbrMyAreaIncident = r.data.length;
      });

    this.requestService
      .findByCurrentArea(field, null)
      .toPromise()
      .then((r) => {
        this.nbrMyAreaRequest = r.data.length;
      });

    this.requestService
      .findByStatusKey(field, null, 'new')
      .toPromise()
      .then((r) => {
        this.nbrNewRequest = r.data.length;
      });
    this.requestService
      .findByStatusKey(field, null, 'in-progress')
      .toPromise()
      .then((r) => {
        this.nbrOpenRequest = r.data.length;
      });
    this.requestService
      .findByStatusKey(field, null, 'resolved')
      .toPromise()
      .then((r) => {
        this.nbrProcessedRequest = r.data.length;
      });
    this.requestService
      .findByStatusKey(field, null, 'closed')
      .toPromise()
      .then((r) => {
        this.nbrClosedRequest = r.data.length;
      });

    this.requestService
      .findByCurrentSubordinate(field, null)
      .toPromise()
      .then((r) => {
        this.nbrMyServiceRequest = r.data.length;
      });
  }

  async ngAfterViewInit() {
    let test = this.authorizationService.user.hasHelpDeskResponsible();
  }
  ngOnDestroy() {
    this._gc.dispose();
    if (this.timer != undefined) {
      clearTimeout(this.timer);
    }

    this.chatbotService.cleanChatBot(this._document);
  }
  visibleServiceCatalog: any = false;
  classOpenServiceCatalog: any = 'fal fa-solid fa-chevron-down';
  ngclassHiddden: any;
  ngClassFlex100: any;
  serviceCatalogClick(event) {
    this.visibleServiceCatalog = !this.visibleServiceCatalog;
    if (this.visibleServiceCatalog == true) {
      this.classOpenServiceCatalog = 'fal fa-solid fa-chevron-up';
      this.ngclassHiddden = 'cl-display-none';
      this.ngClassFlex100 = 'cl-flex-full';
    } else {
      this.classOpenServiceCatalog = 'fal fa-solid fa-chevron-down';
      this.ngclassHiddden = 'cl-display-block';
    }
  }
}
