import {
  AfterContentInit,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  Injector,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Router,
  ActivatedRoute,
  UrlTree,
  NavigationExtras,
} from '@angular/router';
import { GC, GCFactory } from '@clarilog/core';
import {
  CoreGraphQLDataSource,
  GraphQLStore,
} from '@clarilog/core/services2/graphql/graphql-store.service';
import { Filter } from '@clarilog/shared2/models';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import DataSource from 'devextreme/data/data_source';
import { TranslateService } from '../../../services/translate/translate.service';
import { ItemsValue } from '../../form/work-form/form-link-array';
import { CoreListComponent } from '../list/list.component';
import { ListComponentBase } from '../list/list.component-base';
import { CoreSelectListComponent } from '../select-list/select-list.component';

/**
 * Représente l'état dans lequel se trouve le composent LinkList.
 */
export enum CheckedLinkListMode {
  /** État normal. */
  Current,
  /** État ajout. */
  Added,
  /** État supprimé */
  Removed,
}
/** Représente le composent CheckLinkList. Permet de lier des éléments à un élément. */
@Component({
  selector: 'clc-check-link-list',
  templateUrl: './check-link-list.component.html',
  styleUrls: ['./check-link-list.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreCheckLinkListComponent),
      multi: true,
    },
  ],
})
export class CoreCheckLinkListComponent
  extends ListComponentBase
  implements ControlValueAccessor, AfterContentInit, OnInit
{
  /** Représente la valeur. */
  _value: ItemsValue;
  /** Obtient ou définit le mode d'affichage. */
  public mode: CheckedLinkListMode = CheckedLinkListMode.Current;
  /** Obtient ou définit la source de données courante. */
  currentSource: ModelDataSourceContext = new ModelDataSourceContext({
    datasource: new DataSource([]),
  });
  /** Obtient ou définit la source du select. */
  @Input() selectSource: ModelDataSourceContext;
  /** Obtient ou définit la query globale. */
  @Input() query: any = undefined;
  // /** Obtient ou définit les éléments ajoutés. */
  @Input() itemsAdded: any[] = [];
  // /** Obtient ou définit les éléments supprimés. */
  @Input() itemsRemoved: any[] = [];
  /** Obtient ou définit si une action de delete existe. */
  @Input() canDeleted: boolean = false;
  /** Obtient ou définit le nom du label du control */
  @Input() label: string = undefined;
  /** Obtient ou définit la query du select. */
  @Input() select: any = undefined;
  /** Obtient le composant liste associé. */
  @ViewChild(CoreSelectListComponent, { static: true })
  selectList: CoreSelectListComponent;
  /** Obtient ou définit le composent list. */
  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;
  /** Obtient ou définit la valeur de la route de gestion */
  @Input() route: string;
  /** Obtient ou définit l'état activé du composent. */
  @Input() disabled: boolean;
  /** Obtient ou définit le titre d'un nouveau bouton */
  @Input() titleButton: string;
  /** Obtient ou définit la route d'un nouveau bouton */
  @Input() routeButton: string;
  /** Active ou désactive le bouton 'Nouveau Prêt' */
  @Input() activeButton: boolean = false;
  /** Utile pour l'enum dans la vue. */
  public LinkListMode: typeof CheckedLinkListMode = CheckedLinkListMode;
  /** Obtient ou définit la source de données par défaut. */
  @Input() defaultSource: ModelDataSourceContext = undefined;
  /** obtient ou définit le fonction permettant s'executant avant la suppression. */
  @Input() beforeRemoveItems: any = undefined;
  /** Obtient ou définit le message affiché lorsque l'on ne peut pas supprimer. */
  @Input() beforeRemoveItemsMessage: string;
  /** Obtient ou définit une valuer indiquant si la mise à jour en live est activée. */
  @Input() isLiveUpdated: boolean = false;
  /** Obtiens définis les filtres */
  @Input() filters: Filter[];
  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() readOnly: boolean = false;

  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() addSource: boolean = true;
  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() removeSource: boolean = true;
  /** Obtient ou définit le modelState */
  @Input() modelState: ModelState;
  /** Obtient ou définit le message hint à afficher */
  @Input() hintMessage: string;

  /** Obtient ou définit si toutes les valeurs doivent être cochées par défaut */
  @Input() selectAllByDefault: boolean = false;

  /** Garbage */
  gc: GC;
  filterForSelect: Filter[];

  initSelectedGrid: boolean = false;
  originalSelectedKey: string[];

  /** Définit l'injector */
  injector: Injector;

  /** Obtient ou définit la valeur. */
  get value(): ItemsValue {
    return this._value;
  }
  set value(value: ItemsValue) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.initSelectedGrid = false;
    this.loadSelectedCheck();
  }

  constructor(
    private _router: Router,
    private router: ActivatedRoute,
    public _route: ActivatedRoute,
    injector: Injector,
    public gcFactory: GCFactory,
  ) {
    super();
    this.injector = injector;
    this.gc = this.gcFactory.create();
  }

  loadDefaultSource() {
    let isEdit = this._router.url.includes('/edit/');
    if (this.selectAllByDefault == true && !isEdit) {
      this.source?.datasource.load().then((data) => {
        this.originalSelectedKey = data.map((x) => x.id);
        this.selectedKeys = data.map((x) => x.id);

        this.setItemsAdded(this.selectedKeys);
      });
    }
  }

  /** @inheritdoc */
  ngOnDestroy(): void {
    this.gc.dispose();
  }

  private findFirstFilter(filters: Filter[]): Filter {
    if (filters[0].items != undefined && filters[0].items.length > 0) {
      return this.findFirstFilter(filters[0].items);
    } else {
      return filters[0];
    }
  }

  /** @inheritdoc */
  ngOnInit() {
    this.currentFilter =
      this.filters != undefined ? this.findFirstFilter(this.filters) : null;
    if (this.filters != undefined && this.selectSource != undefined) {
      this.filterForSelect = [];
      for (let i = 0; i < this.filters.length; i++) {
        this.filterForSelect.push({
          items: [],
          list: undefined,
          text: this.filters[i].text,
        });

        if (this.filters[i].items != undefined) {
          for (let y = 0; y < this.filters[i].items.length; y++) {
            if (this.filters[i].items[y].list != undefined) {
              this.filterForSelect[i].items.push({
                text: this.filters[i].items[y].text,
                source: this.filters[i].items[y].source,
                inContext: this.filters[i].items[y].inContext,
                filterExpr: this.filters[i].items[y].filterExpr,
                parentIdExpr: this.filters[i].items[y].parentIdExpr,
                description: this.filters[i].items[y].description,
                keyExpr: this.filters[i].items[y].keyExpr,
                displayExpr: this.filters[i].items[y].displayExpr,
                multiple: this.filters[i].items[y].multiple,
                filterOperator: this.filters[i].items[y].filterOperator,
                recursive: this.filters[i].items[y].recursive,
                command: this.filters[i].items[y].command,
                list: {
                  source: <any>new ModelDataSourceContext({
                    datasource: this.selectSource.datasource,
                  }),
                },
                visible: this.filters[i].items[y].visible,
              });
            }
          }
        }

        if (this.filters[i].list != undefined) {
          this.filterForSelect[i].list = {
            source: <any>new ModelDataSourceContext({
              datasource: this.selectSource.datasource,
            }),
          };
        }
      }
    }

    let query = (this.source.datasource.store() as GraphQLStore).context.context
      .config.query;
    let defaultQuery = undefined;
    if (this.defaultSource != undefined) {
      defaultQuery = (this.defaultSource.datasource.store() as GraphQLStore)
        .context.context.config.query;
    }
    if (this.source.datasource instanceof CoreGraphQLDataSource) {
      (
        this.source.datasource.store() as GraphQLStore
      ).context.context.config.query = (...args) => {
        return query.apply(
          (this.source.datasource.store() as GraphQLStore).context.context
            .config,
          args,
        );
      };
    }

    // </fix>
    this.currentSource = new ModelDataSourceContext({
      datasource: this.source.datasource,
    });

    //Initialisation du titre pour le nouveau bouton
    if (this.titleButton == undefined || this.titleButton == null) {
      this.titleButton = TranslateService.get('new');
    }

    this.list.onBeforeRefresh.subscribe((e) => {
      if (e != undefined) {
        this.loadSelectedCheck();
      }
    });

    if (this.modelState?.formComponent != undefined) {
      this.gc.forDispose(
        this.modelState.formComponent.onSaved.subscribe((res) => {
          this.loadSelectedCheck();
        }),
      );
    }

    // Sélection des éléments à sélectionner par défaut
    this.loadDefaultSource();
  }

  loadSelectedCheck() {
    this.selectSource.datasource.pageSize(1000);
    this.selectSource.datasource.load().then((data) => {
      this.originalSelectedKey = data.map((x) => x.id);
      this.selectedKeys = data.map((x) => x.id);
      setTimeout(() => {
        this.initSelectedGrid = true;
      }, 500);
    });
  }

  onSelectionKeyChanged(e) {
    if (this.initSelectedGrid == true) {
      this.selectedKeys = e.selectedRowKeys;
      this.setItemsAdded(
        this.selectedKeys.filter((x) => !this.originalSelectedKey.includes(x)),
      );
      this.setItemsRemoved(
        this.originalSelectedKey.filter((x) => !this.selectedKeys.includes(x)),
      );
    }
  }

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  writeValue(value: any): void {
    if (value != undefined) {
      this.value = value;
    }
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** Permet de modifier les items de add. */
  private setItemsAdded(items: any[]) {
    this._value.itemsAdded = items;
    this.onChange(this._value);
    this.onTouched();
  }
  /** Permet de modifier les items de remove. */
  private setItemsRemoved(items: any[]) {
    this._value.itemsRemoved = items;
    this.onChange(this._value);
    this.onTouched();
  }
  /** @inheritdoc */
  public ngAfterContentInit(): void {
    this.currentSource = new ModelDataSourceContext({
      datasource: this.source.datasource,
    });
  }

  currentSourceFilter: any;

  onItemSelectionChanged(e) {
    delete window.history.state.filters;
    if (this.currentSource == undefined) {
      this.currentSource = this.currentFilter.list.source;
    }
    if (e !== undefined && e.filters && e.filters.length > 0) {
      this.currentSource.datasource.filter([e.filters]);
    } else {
      this.currentSource.datasource.filter(null);
    }
    this.list.refresh();
  }
  /** Rafraîchi la liste. */
  public refresh() {
    this.currentSourceFilter = [];
    for (let item of this.itemsRemoved) {
      this.currentSourceFilter.push(['id', '<>', item['id']]);
      if (this.itemsRemoved.indexOf(item) < this.itemsRemoved.length - 1) {
        this.currentSourceFilter.push('and');
      }
    }

    if (this.mode === this.LinkListMode.Current) {
      // this.current();

      if (this.type == undefined || this.type == 'Grid') {
        this.list.refresh();
      } else {
        this.currentSource.datasource.reload();
      }
    }
  }

  /** Affiche les données en cours de l'élément. */
  public async current() {
    setTimeout(() => {
      this.selectedKeys = [];
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource(this.source.datasource.store()),
      });
      if (
        this.currentSourceFilter != undefined &&
        this.currentSourceFilter.length > 0
      ) {
        this.currentSource.datasource.filter(this.currentSourceFilter);
      }
    });
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo(defaultRoute: boolean = false) {
    let id = this.router.snapshot.paramMap.get('id');

    /** liste les différents paramètre à intégrer dans l'url */
    let filter: object = {};
    if (id != null) {
      /** Ajoute de l'id dans l'objet filter */
      Object.assign(filter, { type: id });
    }
    let url = this._router.createUrlTree([this.route], {
      skipLocationChange: true,
    } as NavigationExtras);

    if (defaultRoute && this.routeButton != undefined) {
      if (filter != undefined) {
        url = this._router.createUrlTree([this.routeButton], {
          queryParams: filter,
          skipLocationChange: true,
        } as NavigationExtras);
      }
    }
    let win = window.open(this._router.serializeUrl(url), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  currentFilter;
  onNavFilterClick(e) {
    this.currentSource = this.currentFilter.list.source;
    this.list.isLiveUpdated = this.currentFilter.list.liveUpdate;
  }

  onRowListClick(e) {
    /** liste les différents paramètre à intégrer dans l'url */
    let filter: object = {};
    let addRouteUrl = undefined;
    let typeData = e?.data?.__typename;
    let params = undefined;
    /** Cas spécifique */
    if (typeData != undefined) {
      switch (typeData) {
        case 'Software':
          switch (e?.data?.softwareCategory) {
            case 'PROGRAM':
              addRouteUrl = 'software';
              break;
            case 'OPERATING_SYSTEM':
              addRouteUrl = 'operatingSystems';
              break;
            case 'UPDATE':
              addRouteUrl = 'updates';
              break;
            case 'WORK_APPLICATION':
              addRouteUrl = 'workApplications';
              break;
          }
          break;
        case 'SecurityGroup':
          params = { type: 'group' };
          break;
        case 'Role':
          params = { type: 'role' };
          break;
      }
    }

    let url: UrlTree;
    let id: string;
    if (typeof e.value == 'object') {
      id = e.value.id;
    } else {
      id = e.value;
    }
    if (addRouteUrl != undefined) {
      url = this._router.createUrlTree([this.route, addRouteUrl, 'edit', id], {
        queryParams: params,
        skipLocationChange: true,
      } as NavigationExtras);
    } else {
      url = this._router.createUrlTree([this.route, 'edit', id], {
        queryParams: params,
        skipLocationChange: true,
      } as NavigationExtras);
    }

    if (this.routeButton != undefined) {
      if (filter != undefined) {
        url = this._router.createUrlTree([this.routeButton], {
          queryParams: filter,
          skipLocationChange: true,
        } as NavigationExtras);
      }
    }

    let win = window.open(this._router.serializeUrl(url), '_blank');
    win.opener.callback = async () => await this.refresh();
  }
  /** Supprime les lien */
  findColumns() {
    if (this.columns != undefined) {
      let col = JSON.parse(JSON.stringify(this.columns));

      col.forEach((c) => {
        c.link = false;
      });

      return col;
    }

    return [];
  }
}
