<div [ngClass]="{ 'cl-form-designer-hover': designerMode }">
  <button
    class="group-title"
    [title]="hint != undefined ? hint : ''"
    (contextmenu)="designerDialog($event)"
    style="cursor: pointer"
    *ngIf="
      mode === 'normal' &&
      text !== undefined &&
      ((items.length > 0 && visibled) || designerMode === true)
    "
    (click)="expanded = !expanded"
    type="button"
  >
    {{ text }}
    <div class="icon-container">
      <i
        style="padding-left: 5px"
        [ngClass]="{
          'fa-chevron-down': !expanded,
          'fa-chevron-up': expanded,
        }"
        class="fal"
      ></i>
    </div>

    <div class="cl-navgroup-button-designer" *ngIf="designerMode === true">
      <div
        style="float: right"
        class="cl-add-navgroup-form-designer"
        *ngIf="designerMode === true"
        (click)="formAction($event, getAction.ADD_IN)"
      >
        <i class="fa-solid fal fa-plus"></i>
      </div>

      <div
        style="float: right"
        class="cl-add-navgroup-form-designer"
        *ngIf="designerMode === true"
        (click)="formAction($event, getAction.ADD_DOWN)"
      >
        <i class="fa-solid fa-arrow-down-from-bracket"></i>
      </div>

      <div
        style="float: right"
        class="cl-add-navgroup-form-designer"
        *ngIf="designerMode === true"
        (click)="formAction($event, getAction.ADD_UP)"
      >
        <i class="fa-solid fa-arrow-up-from-bracket"></i>
      </div>

      <div
        style="float: right"
        class="cl-edit-navgroup-form-designer"
        *ngIf="designerMode === true"
        (click)="formAction($event, getAction.EDIT)"
      >
        <i class="fa-solid fa-pen"></i>
      </div>
      <div
        style="float: right"
        class="cl-delete-navgroup-form-designer"
        *ngIf="designerMode === true"
        (click)="formAction($event, getAction.REMOVE)"
      >
        <i class="fa-solid fa-trash-xmark"></i>
      </div>
    </div>
  </button>

  <div
    class="border-bottom"
    *ngIf="
      (expanded === true || mode === 'compact') &&
      text !== undefined &&
      ((items.length > 0 && visibled) || designerMode === true)
    "
  ></div>
</div>

<div
  [ngStyle]="{
    display: expanded === true || mode === 'compact' ? 'block' : 'none',
  }"
>
  <!--  <dx-button-->
  <!--    *ngIf="designerMode === true && text !== undefined"-->
  <!--    icon="fal fa-plus"-->
  <!--    (onClick)="designerClick('NAV_ITEM')"-->
  <!--    class="cl-item-button-alert"-->
  <!--    text="NAV item group"-->

  <!--  >-->
  <!--  </dx-button>-->
  <ng-content select="clc-nav-menu-item"></ng-content>
</div>
