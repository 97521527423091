import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { FormDesignerComponent } from '../form-designer.component';
import { ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { FormGroupHelpers } from '../../form/work-form';
import helpDeskRequestNewModel from '../../../../modules2/help-desk-request/pages/help-desk-request/model.json';
import helpDeskRequestEditModel from '../../../../modules2/help-desk-request/pages/help-desk-request/model-edit.json';
import helpDeskIncidentNewModel from '../../../../modules2/help-desk-incident/pages/help-desk-incident/model.json';
import helpDeskIncidentEditModel from '../../../../modules2/help-desk-incident/pages/help-desk-incident/model-edit.json';
import helpDeskOperatorIncidentNewModel from '../../../../modules2/incident/pages/incident/model.json';
import helpDeskOperatorRequestNewModel from '../../../../modules2/request/pages/request/model.json';
import {
  FormDesignerEditing,
  FormDesignerTo,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { IncidentHelpers } from '@clarilog/modules2/incident/pages/incident/incident-model-helper';
import { RequestHelpers } from '@clarilog/modules2/request/pages/request/request-model-helper';
import { CoreModelCompilerService } from '@clarilog/shared2/services/compiler/model-compiler.service';

@Component({
  selector: 'cl-form-designer-container',
  templateUrl: './form-designer-container.component.html',
  styleUrl: './form-designer-container.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDesignerContainerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormDesignerContainerComponent),
      multi: true,
    },
  ],
})
export class FormDesignerContainerComponent
  implements ControlValueAccessor, Validator
{
  @Input() state: ModelState;
  @Input() options: any;
  @Input() canUseStatus: boolean = true;
  @Input() fieldName: any;

  qualification: string;
  formDesignerMode: FormDesignerEditing;
  formDesignerTo: FormDesignerTo;

  popupVisible = false;
  jsonModel;
  id;
  baseModel;
  canSaveFormType = true;

  /** Obtient le formulaire */
  @ViewChild(FormDesignerComponent, { static: false })
  formDesignerComponent: FormDesignerComponent;

  submit() {
    this.popupVisible = false;
    this.onChange(JSON.stringify(this.jsonModel));
    this.onTouched(JSON.stringify(this.jsonModel));
  }

  change(e: { model: any }) {
    this.jsonModel = e.model;
  }

  constructor(
    private route: ActivatedRoute,
    private modelCompilerService: CoreModelCompilerService,
  ) {}

  ngOnInit() {
    this.state.formComponent.onSubmit.subscribe(async (f) => {
      if (this.id == undefined && this.jsonModel == undefined) {
        // Force par defaut
        this.setFormType();
        this.jsonModel = await this.getDefaultJson();
        this.submit();
      }
    });
  }

  /** Obtient le formType a utiliser */
  setFormType() {
    this.canUseStatus = true;
    if (this.options?.formType == undefined && this.canSaveFormType === true) {
      // Get formType
      let formType = FormGroupHelpers.formControlByName(
        this.state.form,
        'formType',
      );

      let formDesignerTo = this.getValue('formDesignerTo');
      let editing = this.getValue('editing');
      this.qualification = this.getValue('qualification');

      if (formDesignerTo == FormDesignerTo.HelpMe) {
        if (this.qualification == 'incident') {
          if (editing == FormDesignerEditing.Creation) {
            formType.setValue('help-desk-user-incident-new');
          } else {
            formType.setValue('help-desk-user-incident-edit');
          }
        } else if (this.qualification == 'request') {
          if (editing == FormDesignerEditing.Creation) {
          } else {
            formType.setValue('help-desk-user-request-edit');
          }
        }
      } else {
        if (this.qualification == 'incident') {
          formType.setValue('help-desk-operator-incident-new');
        } else if (this.qualification == 'request') {
          formType.setValue('help-desk-operator-request-new');
        }
      }

      if (editing == FormDesignerEditing.Creation) {
        this.canUseStatus = false;
      }
      formType.markAsDirty();
      this.state.sharedContext.entry.set('formType', () => formType.value);
    }
  }

  /** Ouverture du designer */
  async openFormDesigner() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == undefined) {
      this.id = this.state.id;
    }
    if (this.id == undefined) {
      // Force save

      if (this.state.form.invalid == true) {
        notify(
          TranslateService.get('entities/asset/errors/noForm', 'error', 5000),
        );
        return;
      } else {
        this.setFormType();

        this.state.formComponent.save({ close: false }).then(async (f) => {
          await this.loadModel();
        });
      }
    } else {
      await this.loadModel();
    }
  }

  getValue(fieldName: string) {
    // vérification si formTypeforcé
    if (this.options?.formType != undefined && fieldName == 'formType') {
      this.canSaveFormType = false;
      return this.options?.formType;
    }

    let formToField = FormGroupHelpers.formControlByName(
      this.state.form,
      fieldName,
    );
    return formToField?.value;
  }
  /** Obtient le formulaire de base a utiliser */
  async getDefaultJson() {
    let formType = this.getValue('formType');

    if (formType != undefined && this.baseModel == undefined) {
      switch (formType) {
        case 'help-desk-user-incident-new':
          this.baseModel = helpDeskIncidentNewModel;
          this.canUseStatus = false;
          this.qualification = 'incident';
          this.formDesignerTo = FormDesignerTo.HelpMe;
          break;
        case 'help-desk-user-incident-edit':
          this.baseModel = helpDeskIncidentEditModel;
          this.qualification = 'incident';
          this.formDesignerTo = FormDesignerTo.HelpMe;
          break;

        case 'help-desk-user-request-new':
          this.baseModel = helpDeskRequestNewModel;
          this.qualification = 'request';
          this.canUseStatus = false;
          this.formDesignerTo = FormDesignerTo.HelpMe;
          break;
        case 'help-desk-user-request-edit':
          this.baseModel = helpDeskRequestEditModel;
          this.qualification = 'request';
          this.formDesignerTo = FormDesignerTo.HelpMe;
          break;

        case 'help-desk-operator-incident-new':
          this.qualification = 'incident';
          this.baseModel = helpDeskOperatorIncidentNewModel;
          IncidentHelpers.loadPage(this.baseModel.form.layout.pages);
          this.formDesignerTo = FormDesignerTo.Operator;
          break;
        case 'help-desk-operator-request-new':
          this.qualification = 'request';
          this.baseModel = helpDeskOperatorRequestNewModel;
          RequestHelpers.loadPage(this.baseModel.form.layout.pages);
          this.formDesignerTo = FormDesignerTo.Operator;
          break;
        default:
          console.error('Model Not set');
          break;
      }

      let editing = this.getValue('editing');
      if (editing != undefined) {
        if (editing == FormDesignerEditing.Creation) {
          this.canUseStatus = false;
        }
      } else {
        editing = this.options?.formDesignerEditing;
      }
      this.formDesignerMode = editing;

      if (this.baseModel != undefined) {
        this.baseModel = await this.addingPersonnalField(this.baseModel);

        if (this.id == undefined) {
          let defaultFieldName = 'json';
          if (this.fieldName != undefined) {
            defaultFieldName = this.fieldName;
          }
          //Permet de force le save si pas d'ouverture du formulaire
          this.state.sharedContext.entry.set(defaultFieldName, () =>
            JSON.stringify(this.baseModel),
          );
          if (this.canSaveFormType) {
            this.state.sharedContext.entry.set('formType', () => formType);
          }
        }
      }
    }

    return this.baseModel;
  }
  // Ajoute les champs perso
  async addingPersonnalField(modelRaw) {
    let myModel = JSON.parse(JSON.stringify(modelRaw));

    myModel = await this.modelCompilerService.addingDynamicField(myModel, {
      id: undefined,
      entry: undefined,
    });

    return myModel;
  }

  async loadModel() {
    let defaultModel = await this.getDefaultJson();

    if (this.jsonModel == undefined) {
      // set le model de base en cas de nouveau
      // si edition le model est set dans le writevalue
      this.jsonModel = defaultModel;
    }
    if (defaultModel == undefined) {
      console.error('Not option.key or model for formDesigner');
      return;
    }
    this.popupVisible = true;
    setTimeout(() => {
      this.formDesignerComponent.applyModels(defaultModel, this.jsonModel);
    }, 50);
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    return [];
  }
  hasInitVal = false;
  writeValue(obj: any): void {
    if (obj != undefined) {
      this.hasInitVal = true;
      this.jsonModel = JSON.parse(obj);
    }
  }

  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}
}
