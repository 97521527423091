import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import { alert } from 'devextreme/ui/dialog';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { WorkItemFieldMode } from '../form/work-item-field/work-item-field.component';
import { EventArgs } from '../nav-menu';
import { TemplatesService } from '../templates/templates.service';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';

/** Représente un composant dynamique. */
@Component({
  selector: 'cl-tagBox',
  templateUrl: './tagBox.component.html',
  styleUrls: ['./tagBox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreTagBoxComponent),
      multi: true,
    },
  ],
})
export class CoreTagBoxComponent
  implements OnInit, ControlValueAccessor, AfterViewInit
{
  public parent: FormGroupDirective;

  valueChange: boolean = false;
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  value: any[] = [];

  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  /** Obtient ou définit le mode. */
  @Input() mode: WorkItemFieldMode = 'default';
  /** Obtient ou définit les controls à afficher. */
  @Input() control: any;
  /** Active ou désactive l'auto-focus sur le premier control. */
  @Input() autoFocus: boolean = false;

  //destination de l'info bulle
  target;
  //texte de l'info bulle
  text;
  /** Obtient ou définit l'id du timeOut pour le popover' */
  timeOutId: any;

  @Output() onInitialized = new EventEmitter<EventArgs<CoreTagBoxComponent>>();

  /** Evenement action d'un bouton */
  @Output() onButtonActionClicked = new EventEmitter<any>();

  /** Bind la classe css par rapport au mode. */
  @HostBinding('class')
  public get getCssClasses(): any {
    return this.mode;
  }

  @Input() translatable: boolean = false;

  _displayExpr: string;
  /** Obtient ou définit le champ à afficher. */
  @Input() get displayExpr(): string {
    return this._displayExpr;
  }

  set displayExpr(value: string) {
    if (this.translatable != undefined && this.translatable === true) {
      let addLanguage =
        '.' + this.translatedFieldHelperService.getTranslateKey();
      if (!value.endsWith(addLanguage)) {
        value = value + addLanguage;
      }
    }
    this._displayExpr = value;
  }

  defaultVisible: string = '';

  /** Obtient la valeur si le champs est systeme */
  isSystemValue: boolean = false;

  constructor(
    /** Récupère le FormGroup parent. */
    private formGroupDirective: FormGroupDirective,
    public templateService: TemplatesService,
    private cd: ChangeDetectorRef,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    this.parent = this.formGroupDirective;
  }
  /** @inheritdoc */
  public writeValue(value: any[]) {
    this.value = value;
  }
  /** @inheritdoc */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @Input() state: ModelState;
  /** @inheritdoc */
  ngOnInit() {
    this.onInitialized.emit({
      component: this,
      event: undefined,
    });
  }

  valueChanged(data) {
    if (this.value == undefined) {
      this.value = [];
      this.cd.detectChanges();
    }
    if (data == undefined) {
      data = [];
      this.cd.detectChanges();
    }

    if (this.value.length != data.length) {
      this.value = data;
      this.onChange(data);
    }
  }

  /** @inheritdoc */
  ngAfterViewInit() {}

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  sub;
  isAlreadyCheck = false;
  addTagBoxCustomItem(e, control) {
    if (!this.isAlreadyCheck) {
      this.isAlreadyCheck = true;
      if (
        this.control?.options?.unique != undefined &&
        e.text != undefined &&
        e.text.trim != undefined
      ) {
        let fnContext = this.control.options.unique;
        fnContext.context.params.set('excludeId', () =>
          fnContext.rootState.sharedContext.params.get('id'),
        );

        fnContext.context.params.set('fieldName', () => this.control.fieldName);
        if (e.text.trim != undefined) {
          fnContext.context.params.set('fieldValue', () => e.text.trim());
        } else {
          fnContext.context.params.set('fieldValue', () => e.text);
        }
        fnContext.context.params.set('fields', () =>
          ModelFieldCompilerService.createServiceSingleResultScalar(),
        );

        let values = e.component.option('value');

        let sub = fnContext.fnCall().subscribe((r) => {
          sub.unsubscribe();

          if ((r as any).data === false) {
            this.checkAdd(e, control);
          } else {
            // can't add
            e.component.option('value', values);
            alert(
              TranslateService.get('errors/unique'),
              TranslateService.get('globals/warning'),
            );
          }
          this.isAlreadyCheck = false;
        });
      } else {
        this.checkAdd(e, control);
        this.isAlreadyCheck = false;
      }
    }
  }

  checkAdd(e, control) {
    this.addTagBoxCustomItemSource(e.text, e, control);

    let values = e.component.option('value');

    let formControl = FormGroupHelpers.formControlByName(
      this.formGroupDirective.form,
      control.fieldName,
    );

    // Ici on ne peut pas passer par un values.push car il est lier au composant et dans le formcontrol ca set l'originalValue sur values et donc ca génére un probleme
    // au moment d'appeler le materialize
    // le code en dessous permet de recuperer les valueurs de values et de copier le tableau comme ca pas de lier avec le composant
    let formValues = [];

    if (values == undefined) {
      values = [];
    } else {
      values = values.filter((x) => x != undefined);
    }

    Object.assign(formValues, values);

    formValues.push(e.text);

    formValues = formValues.filter(this.onlyUnique);
    setTimeout(() => {
      formControl.setValue(formValues, { emitEvent: true });
      formControl.markAsDirty();
    }, 0);
  }

  tagBoxItem = [];
  tagBoxCurrentText: string;
  tagBoxCustomItem = [];
  tagBoxDataSource = new DataSource({
    load: (load) => {
      let list = [...this.tagBoxCustomItem, ...this.tagBoxItem];

      // Permet de faire une recherche dans la liste
      if (load.searchValue) {
        list = list.filter(item => {
          const lang = this.detectLanguage(item.path);
          const valueToFilter = item.path?.[lang];
          const normalizeStr = (str: string) => str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase(); 

          if(!this.translatable) {
            return normalizeStr(item.name).includes(normalizeStr(load.searchValue));
          }else {
            return normalizeStr(valueToFilter).includes(normalizeStr(load.searchValue));
          }
        });
        return list;
      }

      list.sort((a, b) => {
        if (this.translatable) {
          return this.translatedFieldHelperService
            .findValueToShow(a.name)
            .localeCompare(
              this.translatedFieldHelperService.findValueToShow(b.name),
              undefined,
              { numeric: true },
            );
        } else if (a?.name != undefined && b?.name != undefined) {
          return a.name.localeCompare(b.name, undefined, { numeric: true });
        } else {
          if (a.localeCompare != undefined) {
            return a.localeCompare(b, undefined, { numeric: true });
          }
        }
      });
      const start = load.skip > 0 ? load.skip : 0;
      const end = load.take ? start + load.take : list.length;

      return list.slice(start, end);
    },
    byKey: (key: any) => {
      return key;
    }
  });

  normalizeString = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  /**
   * Permet de détecter la langue d'un objet
   * @param path 
   * @returns langue de l'objet
   */
  detectLanguage(path: any): string {
    if (!path) return 'fr';
    const availableLanguages = Object.keys(path);
    return availableLanguages.length > 0 ? availableLanguages[0] : 'fr';
  }

  private addTagBoxCustomItemSource(element, e, control) {
    if (element != undefined) {
      if (
        (control.options.valueExpr != undefined &&
          this.tagBoxItem.find(
            (x) => x[control.options.valueExpr] === element,
          ) == undefined &&
          this.tagBoxCustomItem.find(
            (x) => x[control.options.valueExpr] === element,
          ) == undefined) ||
        (control.options.valueExpr == undefined &&
          this.tagBoxItem.find((x) => x === element) == undefined &&
          this.tagBoxCustomItem.find((x) => x === element) == undefined)
      ) {
        let newValue = {};
        if (control.options.valueExpr != undefined) {
          newValue[control.options.valueExpr] = element;
        } else {
          newValue = element;
        }

        this.tagBoxCustomItem.push(newValue);
        if (this.tagBoxCurrentText == undefined) {
          e.component.repaint();
        }
      }
    }
  }

  async initialiseTagBox(e, control) {
    if (control.options?.source?.datasource) {
      control.options?.source?.datasource.pageSize(200);

      this.tagBoxItem = await control.options?.source?.datasource.reload();
      e.component.repaint();
    }

    let formControl = FormGroupHelpers.formControlByName(
      this.formGroupDirective.form,
      control.fieldName,
    );
    if (formControl != null) {
      this.sub = formControl.valueChanges.subscribe((val) => {
        if (val != undefined) {
          val.forEach((element) => {
            this.addTagBoxCustomItemSource(element, e, control);
          });
        }

        if (val != undefined && val.includes(undefined) === true) {
          formControl.setValue(
            val.filter((x) => x != undefined),
            { emitEvent: false },
          );
          formControl.markAsDirty();
        }
      });
    }
  }

  tagBoxKeyPressed(e, control) {
    this.tagBoxCurrentText = e.component.option('text');
  }

  lostFocusTagBox(e, control) {
    if (
      this.tagBoxCurrentText != undefined &&
      this.tagBoxCurrentText.trim().length > 0 &&
      control.options.acceptCustomValue != false
    ) {
      e.component._createCustomItem(this.tagBoxCurrentText);
      e.component._completeSelection(this.tagBoxCurrentText);
    }
    this.tagBoxCurrentText = undefined;
  }

  isCheckUndeselect: boolean = false;
  onTagBoxValueChanged(e, control) {
    if (control?.options?.unDeselectItem?.datasource.load != undefined) {
      if (!this.isCheckUndeselect) {
        this.isCheckUndeselect = true;
        control.options.unDeselectItem.datasource.load().then((d) => {
          if (d != undefined && d.length > 0) {
            let value = e.value;

            if (value == undefined) {
              value = [];
            }

            let msg = false;
            d.forEach((f) => {
              if (value.filter((g) => g == f).length == 0) {
                // vérifie dans le datasrouce si la valeur existe
                value.push(f);
                if (!msg && control?.options?.unDeselectItemMessage) {
                  let itemName = undefined;
                  // Recherche dans la source
                  if (control.options?.source?.datasource?.items != undefined) {
                    let itemSource =
                      control.options?.source?.datasource.items();
                    if (itemSource != undefined) {
                      let itemSearch = itemSource.filter((is) => is['id'] == f);
                      if (itemSearch != undefined && itemSearch.length > 0) {
                        itemName = itemSearch[0]['name'] + ': ';
                      }
                    }
                  }
                  alert(
                    itemName + control?.options?.unDeselectItemMessage,
                    TranslateService.get('globals/warning'),
                  );
                  msg = true;
                }
              }
            });

            e.value = value;
            e.component.option('value', e.value);
            this.valueChanged(e.value);
            this.cd.detectChanges();
          } else {
            e.component.option('value', e.value);
            this.valueChanged(e.value);
            this.cd.detectChanges();
          }
          this.isCheckUndeselect = false;
        });
      }
    } else {
      this.valueChanged(e.value);
    }
  }

  /** @inheritdoc */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
