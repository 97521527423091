import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormGroupHelpers } from '@clarilog/shared2/components/form/work-form/form-group-helpers';
import { CustomValidators } from '@clarilog/shared2/components/form/work-form/work-form-validations';
import {
  Control,
  Form,
  PageControl,
  PageGroup,
  PageSection,
  PageTab,
  Section,
  SectionGroup,
  WorkItemConfiguration,
} from '@clarilog/shared2/models/schema';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import {
  ModelRule,
  ModelRulesDesigner,
} from '../graphql/generated-types/types';
import { ControlElementWithParentHighlight } from '@devexpress/analytics-core/analytics-internal';
import { AuthorizationCoreService } from '../authorization/authorization.service';
import { Router } from '@angular/router';
import { FormDesignerCoreService } from '../graphql/generated-types/services/form-designer.service';
import { GqlField, GqlSubField } from '../graphql/generated-types/helpers';

@Injectable({ providedIn: 'root' })
export class ModelRuleApplicationService {
  constructor(
    private authService: AuthorizationCoreService,
    public formDesignerService: FormDesignerCoreService,
  ) {}
  private getFullLabelFromType(controlType) {
    if (controlType != undefined) {
      if (controlType != 'HtmlEditorTranslatedComponent') {
        if (controlType === 'StarRatingComponent') {
          return "[resource('entities/satisfaction/rating')]";
        }

        return controlType;
      }
    }

    return '';
  }

  private parseFormPageSectionGroup(group: SectionGroup) {
    let res = [];
    group.controls.forEach((control) => {
      control.fullLabel = this.translateEach(
        group.label,
        control.label != undefined
          ? control.label
          : this.getFullLabelFromType(control.type),
      );

      if (
        !(
          control.designerOptions != undefined &&
          control.designerOptions.managed === false
        )
      ) {
        res.push(control);
      }
    });
    return res;
  }

  private parseFormPageSection(section: Section, label: string) {
    let res = [];
    section.groups.forEach((group) => {
      group.label = this.translateEach(label, group.label);
      res = res.concat(this.parseFormPageSectionGroup(group));
    });

    return res;
  }

  private parseFormPage(page: PageSection | PageControl | PageGroup | PageTab) {
    let res = [];
    if ((page as PageSection).sections != undefined) {
      (page as PageSection).sections.forEach((section) => {
        let defaultLabel = page['labelModelDesignerRule'];
        if (
          defaultLabel == undefined ||
          defaultLabel == null ||
          defaultLabel == ''
        ) {
          defaultLabel = page.label;
        }
        res = res.concat(this.parseFormPageSection(section, defaultLabel));
      });
    }

    if (page['tabs'] != undefined) {
      (page as PageTab).tabs.forEach((tab) => {
        tab.label = this.translateEach(page.label, tab.label);
        if (tab['sections'] != undefined) {
          (tab as PageSection).sections.forEach((section) => {
            res = res.concat(this.parseFormPageSection(section, page.label));
          });
        } else {
          let ctrl = (tab as PageControl).control;
          ctrl.fullLabel = this.translateEach(tab.label, ctrl.label);
          res.push(ctrl);
        }
      });
    }

    if ((page as PageGroup).pages != undefined) {
      (page as PageGroup).pages.forEach((pagee) => {
        pagee.label = this.translateEach(page.label, pagee.label);
        if (pagee['tabs'] != undefined) {
          (pagee as PageTab).tabs.forEach((tab) => {
            tab.label = this.translateEach(pagee.label, tab.label);

            if (tab['sections'] != undefined) {
              (tab as PageSection).sections.forEach((section) => {
                res = res.concat(this.parseFormPageSection(section, tab.label));
              });
            } else {
              let ctrl = (tab as PageControl).control;
              ctrl.fullLabel = this.translateEach(tab.label, ctrl.label);
              res.push(ctrl);
            }
          });
        } else {
          if (page['sections'] != undefined) {
            (page as PageSection).sections.forEach((section) => {
              res = res.concat(this.parseFormPageSection(section, page.label));
            });
          } else {
            let ctrl = (page as PageControl).control;

            ctrl.fullLabel = this.translateEach(page.label, ctrl.label);
            res.push(ctrl);
          }
        }
      });
    }

    if ((page as PageControl).control != undefined) {
      let ctrl = (page as PageControl).control;
      ctrl.fullLabel = this.translateEach(page.label, ctrl.label);
      res.push(ctrl);
    }

    return res;
  }

  private parseForm(form: Form) {
    let res = [];
    let copy = JSON.parse(JSON.stringify(form));
    copy.layout.pages.forEach((page) => {
      res = res.concat(this.parseFormPage(page));
    });
    return res;
  }

  private translateEach(label1, label2) {
    let trans1 = this.translate(label1);
    let trans2 = this.translate(label2);

    if (trans2 == undefined || trans2 === '' || trans1 === trans2) {
      return trans1;
    }
    if (trans1 == '' && trans2 != '') {
      return trans2;
    }
    return trans1 + '/' + trans2;
  }

  private translate(label: string) {
    if (label == undefined) {
      return '';
    }

    if (label.includes("[resource('") === false) {
      return label;
    }

    return TranslateService.get(
      label.replace("[resource('", '').replace("')]", ''),
    );
  }

  private determineAllowingRules(x, rulesView: string, defaultValue: boolean) {
    let allowingRules = {};

    let editableOptionOnlyVisibleOnView = (x as Control).designerOptions
      ?.editableOptionOnlyVisibleOnView;
    allowingRules['editable'] =
      editableOptionOnlyVisibleOnView !== undefined
        ? editableOptionOnlyVisibleOnView === rulesView
        : defaultValue;

    let requiredOptionOnlyVisibleOnView = (x as Control).designerOptions
      ?.requiredOptionOnlyVisibleOnView;
    allowingRules['required'] =
      requiredOptionOnlyVisibleOnView !== undefined
        ? requiredOptionOnlyVisibleOnView === rulesView
        : defaultValue;

    return allowingRules;
  }

  public extracValueFromModel(
    model: WorkItemConfiguration,
    onlyVisibile: boolean = false,
    currentView?: string,
  ) {
    let tt = this.parseForm(model.form);
    if (currentView === 'newRules') {
      tt = tt.filter((s) => !(s.designerOptions?.notVisibleOnNew === true));
    } else {
      tt = tt.filter((s) => !(s.designerOptions?.notVisibleOnEdit === true));
    }

    tt = tt.filter((s) => !(s.label == undefined && s.visible === false));
    tt = tt.filter((s) => !(s.designerOptions?.ignore === true));

    return tt.map((x) => {
      let onlyVisibility = false;
      if (onlyVisibile == false) {
        if (
          (x as Control).designerOptions != undefined &&
          (x as Control).designerOptions.onlyVisibility === true
        ) {
          onlyVisibility = true;
        }
      } else {
        onlyVisibility = true;
      }

      // Gestion du cas des formulaires d'opérateurs
      let defaultAllowingValue = !onlyVisibility;
      let allowEditable = defaultAllowingValue;
      let allowRequired = defaultAllowingValue;
      let editValue = true;
      let requiredValue = false;
      let visibilityValue = true;

      if (x.type == 'CheckBoxComponent') {
        allowRequired = false;
      }

      if (currentView !== undefined) {
        if (currentView === 'newRules') {
          // On vérifie l'inverse
          if (x.designerOptions?.editableOptionOnlyVisibleOnView === 'Edit') {
            allowEditable = false;
            allowRequired = false;
          }

          if (x.designerOptions?.requiredOptionOnlyVisibleOnView === 'Edit') {
            allowRequired = false;
          }

          if (x.designerOptions?.newEditValue !== undefined) {
            editValue = x.designerOptions.newEditValue;
          }
          if (x.designerOptions?.newRequiredValue !== undefined) {
            requiredValue = x.designerOptions.newRequiredValue;
          }
          if (x.designerOptions?.newVisibleValue !== undefined) {
            visibilityValue = x.designerOptions.newVisibleValue;
          }
        }

        if (currentView === 'editRules') {
          // On vérifie l'inverse
          if (x.designerOptions?.editableOptionOnlyVisibleOnView === 'New') {
            allowEditable = false;
            allowRequired = false;
          }

          if (x.designerOptions?.requiredOptionOnlyVisibleOnView === 'New') {
            allowRequired = false;
          }

          if (x.designerOptions?.editEditValue !== undefined) {
            editValue = x.designerOptions.editEditValue;
          }
          if (x.designerOptions?.editRequiredValue !== undefined) {
            requiredValue = x.designerOptions.editRequiredValue;
          }
          if (x.designerOptions?.editVisibleValue !== undefined) {
            visibilityValue = x.designerOptions.editVisibleValue;
          }
        }
        // controle
        if (visibilityValue === false) {
          editValue = false;
          requiredValue = false;
        }
      }

      return {
        title:
          x.fullLabel != undefined
            ? x.fullLabel
            : TranslateService.get(x.label),
        fieldName:
          (x as Control)?.designerOptions?.fieldName != undefined
            ? (x as Control)?.designerOptions?.fieldName
            : x.fieldName != undefined
              ? x.fieldName
              : x.label != undefined
                ? 'key' + x.label
                : x.type,
        statusIds: [],
        editable: !allowEditable ? false : editValue,
        visible: visibilityValue,
        required: !allowRequired ? false : requiredValue,
        option: {
          allowEdit: allowEditable,
          allowRequire: allowRequired,
          allowStatus: !onlyVisibility,
        },
      };
    });
  }

  private findSubFormGroup(
    index: number,
    formGroup: UntypedFormGroup,
  ): UntypedFormGroup {
    if (formGroup == undefined) {
      return null;
    }
    if (Array.isArray(formGroup.controls)) {
      let tt = (formGroup.parent.controls as AbstractControl[]).find(
        (x, idx) => idx === index,
      );

      return tt as UntypedFormGroup;
    } else {
      let key = Object.keys(formGroup.controls).find((x, idx) => idx === index);
      let tt = formGroup.controls[key];
      return tt as UntypedFormGroup;
    }
  }

  private applyRequireOrNot(rule: ModelRule, statusId: string): boolean {
    if (
      rule?.option?.allowStatus != undefined &&
      rule.option.allowStatus === true
    ) {
      if (
        (rule.statusIds != undefined &&
          rule.statusIds.length > 0 &&
          rule.statusIds.includes(statusId)) ||
        rule.statusIds == undefined ||
        rule.statusIds.length <= 0
      ) {
        return true;
      }
    }
    return false;
  }

  private applyEditOrNot(rule: ModelRule, statusId: string): boolean {
    if (
      rule?.option?.allowEditStatus != undefined &&
      rule.option.allowEditStatus === true
    ) {
      if (
        (rule.editableStatusIds != undefined &&
          rule.editableStatusIds.length > 0 &&
          rule.editableStatusIds.includes(statusId)) ||
        rule.editableStatusIds == undefined ||
        rule.editableStatusIds.length <= 0
      ) {
        return true;
      }
    }
    return false;
  }

  private findRule(
    fieldName: string,
    rules: ModelRulesDesigner,
    statusId,
    id: string,
  ): ModelRule {
    let rulesToUsed = id == undefined ? rules.rules : rules.editRules;
    let findRule = rulesToUsed.find((x) => x.fieldName === fieldName);
    if (findRule == undefined) {
    }
    return findRule;
  }

  private checkVisible(arrayToCheck: { visible?: boolean }[]): boolean {
    let result = false;
    arrayToCheck.forEach((x) => {
      if (x.visible === true || x.visible == undefined) {
        result = true;
      }
    });
    return result;
  }

  private setFormGroupVisibility(
    visibility: boolean,
    formGroup: UntypedFormGroup,
  ) {
    if (formGroup != undefined) {
      if (visibility === false) {
        formGroup.invisible();
      } else {
        formGroup.visible(false);
      }
    }
  }

  private applyModelFormControl(
    control: Control,
    rules: ModelRulesDesigner,
    statusId: string,
    formGroup: UntypedFormGroup,
    id: string,
  ) {
    if (control.designerOptions == undefined) {
      control.designerOptions = {};
    }
    if (control.designerOptions.fieldName == undefined) {
      control.designerOptions.fieldName =
        control.fieldName ??
        (control.label ? 'key' + control.label : control.type);
    }

    // If not managed
    if (control.designerOptions.managed === false) {
      if (control.designerOptions?.editEditValue === true) {
        control.visible = true;
      } else {
        control.visible = false;
      }

      return;
    }

    let ctrl: UntypedFormControl = null;

    let rule = this.findRule(
      control.designerOptions.fieldName,
      rules,
      statusId,
      id,
    );

    let allowRequire = this.applyRequireOrNot(rule, statusId);

    let allowEdit = this.applyEditOrNot(rule, statusId);

    if (formGroup != undefined) {
      if (control.fieldName != undefined) {
        ctrl = FormGroupHelpers.formControlByName(
          formGroup,
          control.fieldName.replace(/\./g, '_'),
        );
      } else {
        /// Laisser le 'else' pour les cas non gérés actuellement
      }
    }

    // If not managed
    if (id == undefined && control.designerOptions?.notVisibleOnNew === true) {
      control.visible = false;
      control.checkByValidation = true;
    } else if (
      id != undefined &&
      control.designerOptions?.notVisibleOnNew === true
    ) {
      //reforce la visibilité
      control.visible = true;
      control.checkByValidation = true;
    }

    // If not managed
    if (id != undefined && control.designerOptions?.notVisibleOnEdit === true) {
      control.visible = false;
      control.checkByValidation = true;
    }

    if (rule != undefined) {
      control.visible = rule.visible;

      control.readOnly =
        rule.option.allowEdit === true
          ? !rule.editable
          : control.readOnly !== undefined
            ? control.readOnly
            : true;
      control.validators = [];

      if (!control.readOnly) {
        //controle du statut d'edition
        control.readOnly = !allowEdit;
      }

      control.checkByValidation = true;

      // Annule-les 'dependsOn'
      if (control.visible === false) {
        control.dependsOn = undefined;
      }

      if (
        rule.required === true &&
        rule.option.allowRequire === true &&
        allowRequire === true
      ) {
        ModelRuleApplicationService.setValidator(control);
      }

      if (formGroup != undefined && ctrl != undefined) {
        ctrl.required = false;
        ctrl.setValidators(null);
        ctrl.setAsyncValidators(null);
        if (ctrl.errors != undefined) {
          ctrl.updateValueAndValidity();
        }
        if (rule.option.allowRequire === true) {
          if (rule.required === true && allowRequire === true) {
            ModelRuleApplicationService.setValidatorByForm(control, ctrl);
          }
        }

        if (rule.visible === true) {
          if (
            ctrl.hasParentDependOn === true ||
            control.dependsOn?.effect === 'Visible'
          ) {
            // On ne set pas la visibilité si un dependOn est présent sur le parent ou lui meme

            return;
          } else {
            ctrl.visible();
          }
        } else {
          ctrl.invisible();
        }
      }
    } else {
      if (
        id == undefined &&
        control.designerOptions?.notVisibleOnNew === true
      ) {
        control.visible = false;
      } else if (
        id != undefined &&
        control.designerOptions?.notVisibleOnEdit === true
      ) {
        control.visible = false;
      } else {
        control.visible = true;
      }

      if (!(control.designerOptions.onlyVisibility === true)) {
        control.validators = [];
        control.readOnly = true;
      }

      if (
        formGroup != undefined &&
        ctrl != undefined &&
        control.visible === true
      ) {
        ctrl.visible();
        if (!(control.designerOptions.onlyVisibility === true)) {
          ctrl.required = false;

          formGroup.enable();
        }
      }
    }
  }
  /** Applique le bon validator en fnction du type de control */
  public static setValidatorByForm(control, ctrl) {
    ctrl.required = true;
    if (
      control.type === 'CoreSubFormLinkListComponent' ||
      control.type === 'CoreSubFormGanttComponent' ||
      control.type === 'TicketEmailListComponent' ||
      control.type === 'MessageTicketComponent'
    ) {
      let fnContext = (control.options as any).service as ModelFnContext;
      ctrl.setAsyncValidators([
        CustomValidators.oneOrMoreAsync(
          fnContext.fnCall(),
          fnContext.rootState,
          control.type,
        ),
      ]);
      ctrl.updateValueAndValidity();
    } else {
      ctrl.setValidators([Validators.required]);
      ctrl.updateValueAndValidity();
    }
  }
  /** Applique le bon validator en fnction du type de control */
  public static setValidator(control) {
    if (control.validators == undefined) {
      control.validators = [];
    }
    if (control.type == 'FileManagerComponent') {
      control['alert'] = true;
      control.validators.push({
        name: 'oneOrMoreAsync',
        value: "[call('FileManagerCoreService','getService')]",
      });
    }
    if (control.type == 'MessageTicketComponent') {
      control['alert'] = true;
      control.validators.push({
        name: 'oneOrMoreAsync',
        value: "[call('MessageCoreService','getService')]",
      });
    }
    if (control.type == 'TicketEmailListComponent') {
      control['alert'] = true;
      control.validators.push({
        name: 'oneOrMoreAsync',
        value: "[call('TicketEmailCoreService','getService')]",
      });
    }
    if (
      control.type === 'CoreSubFormLinkListComponent' ||
      control.type === 'CoreSubFormGanttComponent'
    ) {
      control.validators.push({
        name: 'oneOrMoreAsync',
        value: (control.options as any).service,
      });
      control.validators.push({ name: 'required' });
    } else {
      control.validators.push({ name: 'notNullOrEmpty' });
    }
    // Non implementé pour le moment
    // else if (control.type === 'LinkListComponent') {
    //   if (
    //     control?.options?.source?.serviceName != undefined &&
    //     control.fieldName != undefined
    //   ) {
    //     control.validators.push({
    //       name: 'oneOrMoreAsync',
    //       value:
    //         "[call('" +
    //         control?.options?.source?.serviceName +
    //         "','getService')]",
    //     });
    //   }
    //   debugger;
    // }

    //
  }

  private applyModelFormPageSectionGroup(
    group: SectionGroup,
    rules: ModelRulesDesigner,
    statusId: string,
    formGroup: UntypedFormGroup,
    id: string,
  ): boolean {
    group.controls.forEach((control, indexControl) => {
      if (control.visible === false && control.checkByValidation !== true) {
        // Si défini a false par défaut dans le JSON de base
      } else {
        this.applyModelFormControl(
          control,
          rules,
          statusId,
          formGroup as UntypedFormGroup,
          id,
        );
      }
      //this.setFormGroupVisibility(control.visible, formGroup);
    });
    return this.checkVisible(group.controls);
  }

  private applyModelFormPageSection(
    section: Section,
    rules: ModelRulesDesigner,
    statusId: string,
    formGroup: UntypedFormGroup,
    id: string,
  ): boolean {
    let result = false;
    section.groups.forEach((group, groupIndex) => {
      //
      let groupFormGroup = this.findSubFormGroup(groupIndex, formGroup);
      let groupRes = this.applyModelFormPageSectionGroup(
        group,
        rules,
        statusId,
        formGroup,
        id,
      );
      result = groupRes === true ? true : result;
    });
    return result;
  }

  private applyModelFormPage(
    page: PageSection | PageControl | PageGroup | PageTab,
    rules: ModelRulesDesigner,
    statusId: string,
    formGroup: UntypedFormGroup,
    id: string,
  ) {
    if ((page as PageSection).sections != undefined) {
      (page as PageSection).sections.forEach((section, sectionIndex) => {
        //
        let sectionFormGroup = this.findSubFormGroup(sectionIndex, formGroup);

        let visible = this.applyModelFormPageSection(
          section,
          rules,
          statusId,
          formGroup,
          id,
        );
        page.userVisible = visible === true ? true : page.visible;
        //set visible
      });
    }
    if (page['tabs'] != undefined) {
      (page as PageTab).tabs.forEach((tab, tabIndex) => {
        let tabFormGroup = this.findSubFormGroup(tabIndex, formGroup);

        if (tab['sections'] != undefined) {
          let tabVisible = false;
          (tab as PageSection).sections.forEach((section, sectionIndex) => {
            //
            let sectionFormGroup = this.findSubFormGroup(
              sectionIndex,
              tabFormGroup,
            );

            let visible = this.applyModelFormPageSection(
              section,
              rules,
              statusId,
              formGroup,
              id,
            );
            tabVisible = visible === true ? true : tabVisible;
            //set visible
          });
          tab.visible = tabVisible;
        } else {
          //
          this.applyModelFormControl(
            (tab as PageControl).control,
            rules,
            statusId,
            tabFormGroup,
            id,
          );
          tab.visible = this.checkVisible([(tab as PageControl).control]);
        }
        //set formvisibility
      });
      page.userVisible = this.checkVisible((page as PageTab).tabs);
      //set visible
    }
    if ((page as PageGroup).pages != undefined) {
      (page as PageGroup).pages.forEach((pagee, pageeIndex) => {
        pagee.label = this.translateEach(page.label, pagee.label);
        let pageeFormGroup = this.findSubFormGroup(pageeIndex, formGroup);

        if (pagee['tabs'] != undefined) {
          (pagee as PageTab).tabs.forEach((tab, tabIndex) => {
            if (tab['sections'] != undefined) {
              (tab as PageSection).sections.forEach((section, sectionIndex) => {
                let visible = this.applyModelFormPageSection(
                  section,
                  rules,
                  statusId,
                  formGroup,
                  id,
                );
                tab.visible = visible === true ? true : tab.visible;
              });
            } else {
              /// Laisser le else pour les cas non gérés actuellement
            }
          });
          pagee.userVisible = this.checkVisible((pagee as PageTab).tabs);
        } else {
          if (pagee['sections'] != undefined) {
            (pagee as PageSection).sections.forEach((section, sectionIndex) => {
              //
              let sectionFormGroup = this.findSubFormGroup(
                sectionIndex,
                pageeFormGroup,
              );

              let visible = this.applyModelFormPageSection(
                section,
                rules,
                statusId,
                formGroup,
                id,
              );
              pagee.visible = visible === true ? true : pagee.visible;
            });
          } else {
            /// Laisser le else pour les cas non gérés actuellement
          }
        }
      });
      page.userVisible = this.checkVisible((page as PageGroup).pages);
    }
    if ((page as PageControl).control != undefined) {
      this.applyModelFormControl(
        (page as PageControl).control,
        rules,
        statusId,
        formGroup as UntypedFormGroup,
        id,
      );
      page.visible = this.checkVisible([(page as PageControl).control]);
    }
  }

  private applyModelForm(
    form: Form,
    rules: ModelRulesDesigner,
    statusId: string,
    formGroup: UntypedFormGroup,
    id: string,
  ) {
    form.layout.pages.forEach((page, index) => {
      this.applyModelFormPage(
        page,
        rules,
        statusId,
        this.findSubFormGroup(index, formGroup),
        id,
      );
    });

    if (formGroup != undefined) {
      FormGroupHelpers.fixFormGroupVisibility(formGroup);
    }
  }

  /** Indique si des formulaire person sont utilisé */
  public hasDesignerForm(rules: ModelRulesDesigner): boolean {
    if (rules != undefined) {
      let hasDesignerForm =
        this.authService?.user?.hasDevTools('formDesigner') === true;
      if (hasDesignerForm === true) {
        return (
          rules.useEditFormDesigner === true ||
          rules.useNewFormDesigner === true
        );
      }
    }
    return false;
  }

  // Force le refresh apres save (new -> Edit)
  public redirectAfterSave(
    canReload: boolean,
    rules: ModelRulesDesigner,
    model: ModelState,
    router: Router,
  ): boolean {
    if (canReload && this.hasDesignerForm(rules)) {
      model.formComponent.workItemForm.inClosed = true;
      router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        router.navigate([window.location.pathname], {});
      });
    }
    return false;
  }

  /** Obtient le formulaire personnalisé a utilisé */
  async getFormDesigner(
    modelId,
    id,
    type,
  ): Promise<{
    incidentModelId?: string;
    rawModel?: any;
    rawEditModel?: any;
    useFormDesigner: boolean;
  }> {
    if (id == undefined && modelId == undefined) {
      return;
    }

    //le problème venait du res qui n'était pas nul mais qui n'avait pas de valeur (les 4 valeurs de model sont null)
    let res = await this.formDesignerService
      .models(
        [
          GqlSubField.create('data', [
            GqlField.create('editFormModelId'),
            GqlField.create('newFormModelId'),
            GqlField.create('editFormModel'),
            GqlField.create('newFormModel'),
          ]),
        ],
        type,
        id,
        modelId,
      )
      .toPromise();

    if (id == undefined) {
      if (res?.data?.newFormModel != undefined) {
        let raw = JSON.parse(res.data.newFormModel);
        raw.useFormDesigner = true;
        return {
          incidentModelId: modelId,
          rawModel: raw,
          useFormDesigner: true,
        };
      }
    } else {
      if (res?.data?.editFormModel != undefined) {
        let raw = JSON.parse(res.data.editFormModel);
        raw.useFormDesigner = true;
        return {
          rawEditModel: raw,
          useFormDesigner: true,
        };
      }
    }

    return undefined;
  }

  public applyModelRule(
    model: WorkItemConfiguration,
    rules: ModelRulesDesigner,
    statusId: string,
    formGroup: UntypedFormGroup = null,
    id: string = null,
  ): any {
    let useCustomModel = undefined;
    if (rules != undefined) {
      // Permet de limité l'usage temp au dev tool
      let hasDesignerForm =
        this.authService?.user?.hasDevTools('formDesigner') === true;
      if (hasDesignerForm === true) {
        if (
          id == undefined &&
          rules.useNewFormDesigner === true &&
          rules.jsonNewFormDesigner != undefined &&
          rules.jsonNewFormDesigner != ''
        ) {
          // Mode new
          useCustomModel = JSON.parse(rules.jsonNewFormDesigner);
        } else if (
          id != undefined &&
          rules.useEditFormDesigner === true &&
          rules.jsonEditFormDesigner != undefined &&
          rules.jsonEditFormDesigner != ''
        ) {
          // Mode edit
          useCustomModel = JSON.parse(rules.jsonEditFormDesigner);
        }

        if (useCustomModel != undefined) {
          useCustomModel.useFormDesigner = true;
          return useCustomModel;
        }
      }

      // Tous les autres cas on utilise les model Rules
      useCustomModel = model;
      this.applyModelForm(useCustomModel.form, rules, statusId, formGroup, id);
    }

    return useCustomModel;
  }
}
