import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DevExtremeModule } from 'devextreme-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import {
  BreadcrumbComponent,
  BreadcrumbService,
  CoreCartListComponent,
  CoreCartListSelectComponent,
  CoreCheckLinkListComponent,
  CoreColumnChooserComponent,
  CoreCurrentInStockListComponent,
  CoreDashboardUserContainerComponent,
  CoreDayOffComponent,
  CoreDebugComponent,
  CoreDesignerRuleListComponent,
  CoreDynamicLinkListComponent,
  CoreEmailPrototypeComponent,
  CoreEmailPrototypeListComponent,
  CoreEmailPrototypeTrashListComponent,
  CoreFieldChooserComponent,
  CoreFileManagerComponent,
  CoreFlatListComponent,
  CoreGridComponent,
  CoreImportDataPopupComponent,
  CoreLinkComponent,
  CoreLinkListComponent,
  CoreListComponent,
  CoreManageListComponent,
  CoreMassiveEditComponent,
  CoreMassiveLinkComponent,
  CoreMatrixComponent,
  CoreMemoAreaComponent,
  CoreNavGroupComponent,
  CoreNavMenuComponent,
  CoreNavMenuItemComponent,
  CorePasswordComponent,
  CorePnpEntityListComponent,
  CorePrintLinkListComponent,
  CoreResearchComponent,
  CoreSchedulerComponent,
  CoreSelectListComponent,
  CoreSelectRemotePopupComponent,
  CoreStockCreatedAssetListComponent,
  CoreSubFormGanttComponent,
  CoreSubFormLinkListComponent,
  CoreTicketAttachmentListComponent,
  CoreTicketArchivedListComponent,
  CoreTicketListComponent,
  CoreTrackingLogListComponent,
  CoreTrashListComponent,
  CoreVisibleSelectableEntityComponent,
  CoreWorkFormComponent,
  CoreWorkItemComponent,
  CoreWorkItemControlDynamicComponent,
  CoreWorkItemFieldComponent,
  CoreWorkItemFormComponent,
  CoreWorkItemGroupDynamicComponent,
  CoreWorkItemHostDirective,
  CoreWorkItemSectionContainerComponent,
  CoreWorkItemSectionGroupComponent,
  CoreWorkItemTabGroupComponent,
  CoreWorkItemsComponent,
  CoreWorkTimeComponent,
  HtmlEditorComponent,
  HtmlEditorTranslatedComponent,
  LayoutMessageItemComponent,
  LicenseComponent,
  LoadComponent,
  LogicDiagramComponent,
  MessageTicketComponent,
  PropertyChangeComponent,
  SelectTemplateComponent,
  ServiceCatalogComponent,
  TicketEmailListComponent,
  ToolbarComponent,
  ToolbarItemButtonComponent,
  ToolbarItemButtonGroupComponent,
  ToolbarItemDropDownButtonComponent,
  ToolbarItemMenuButtonComponent,
  ToolbarItemWaitButtonComponent,
  ToolbarItemsComponent,
  TranslateFieldComponent,
  ValidationErrorComponent,
  WorkItemFormSystemComponent,
  WorkItemSectionComponent,
  WorkPageComponent,
  WorkPageContentComponent,
  WorkPageErrorsComponent,
  WorkPageHeaderComponent,
  WorkPageHeaderContentBodyComponent,
  WorkPageHeaderContentComponent,
  WorkPageHeaderContentTitleComponent,
  WorkPageLeftComponent,
  WorkPageSystemComponent,
  FormDesignerComponent,
  FormDesignerContainerComponent,
  BrotherBpacComponent,
  BrotherBpacPopupComponent,
  CoreFileSingleManagerComponent,
  ButtonComponent,
} from './components';

import { ModelRuleApplicationService } from '@clarilog/core/services2/model-rule-application/model-rule-application.service';
import { CoreAlertComponent } from '@clarilog/modules2/alerts/pages/alert/alert.component';
import { EditableListComponent } from '@clarilog/shared2/components/editable-list/editable-list.componant';
import { CoreTagBoxCustomItemComponent } from '@clarilog/shared2/components/tagbox-custom-item';
import { WarningMessageItemComponent } from '@clarilog/shared2/components/warning-message-item';
import { StarRatingModule } from 'angular-star-rating';
import { CoreDashboardUserAeraListComponent } from './components/dashboard-user-container/aera-list/dashboard-user-aera-list.component';
import { ViewerPopUpComponent } from './components/dashboard-user-container/viewer-popup/viewer-popup.component';
import { CoreWidgetWarningMessageComponent } from './components/dashboard-user-container/widget-warning-message/widget-warning-message.component';
import { DragAndDropMassiveComponent } from './components/drag-and-drop-massive/drag-and-drop-massive.component';
import { CoreDynamicPopUpComponent } from './components/dynamic-popup';
import { CoreEmailPrototypeLogListComponent } from './components/email-prototype-log/email-prototype-log-list/email-prototype-log-list.component';
import { TagBoxEmailComponent } from './components/email-prototype/tag-box-email/tag-box-email.component';
import { CoreFileManagerViewerComponent } from './components/file-manager/viewer/file-manager-viewer.component';
import { CoreFileUploaderComponent } from './components/file-uploader';
import { CoreFilterBuilderComponent } from './components/filter-builder';
import { CoreWorkSubFormComponent } from './components/form/work-sub-form/work-sub-form.component';
import { CoreCommandAssetCategoryListComponent } from './components/list/command-asset-category-list/command-asset-category-list.component';
import { CorePermissionsListComponent } from './components/list/permissions-list';
import { CoreSimpleListComponent } from './components/list/simple-list/simple-list.component';
import { CoreSelectSimpleListComponent } from './components/list/select-simple-list/select-simple-list.component';
import { CoreVisibleSelectableLinkEntityComponent } from './components/list/visible-selectable-link-entity';
import { MobileActionsComponent } from './components/mobile-actions/mobile-actions.component';
import { MobileListComponent } from './components/mobile-list/mobile-list.component';
import { MobileManageListComponent } from './components/mobile-manage-list/mobile-manage-list.component';
import { MobileNavMenuComponent } from './components/mobile-nav-menu/mobile-nav-menu.component';
import { MobileToolbarComponent } from './components/mobile-toolbar/mobile-toolbar.component';
import { CoreNotificationComponent } from './components/notification/notification/notification.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { CoreTagBoxComponent } from './components/tagbox/tagBox.component';
import { CoreTemplatesComponent } from './components/templates/templates.component';
import { TemplatesService } from './components/templates/templates.service';
import { CoreTimespanComponent } from './components/timespan/timespan.component';
import { CoreChronoComponent } from './components/chrono/chrono.component';
import { WarningCustomerItemComponent } from './components/warning-customer-item/warning-customer-item.component';
import { CoreWidgetDashboardComponent } from './components/widget-dashboard/widget-dashboard.component';
import { WorkPageRightComponent } from './components/work-page/work-page-right';
import { CoreHasPoliciesDirective } from './directives/has-policies.directive';
import { LetDirective } from './directives/let.directive';
import {
  ClcSwitchOnlyOne,
  ClcSwitchOnlyOneCase,
} from './directives/use.directive';
import { LocalVariableDirective } from './directives/variable.directive';
import { GraphQLModule } from './modules/graph-ql/graph-ql.module';
import { MaterialModule } from './modules/material/material.module';
import {
  ErrorComponent,
  NoLicenseComponent,
  NotFoundComponent,
  UnauthorizedComponent,
} from './pages';
import { CountPipe, FilterPipe, TranslatePipe } from './pipes';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml.pipe';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { FormDesignerPopupComponent } from '@clarilog/shared2/components/form-designer/form-designer-popup/form-designer-popup.component';
import { TaskTreeListComponent } from './components/list/task-tree-list';

@NgModule({
  declarations: [
    //NOT MIG
    // Common
    BreadcrumbComponent,
    NotFoundComponent,
    NoLicenseComponent,
    UnauthorizedComponent,
    ErrorComponent,
    LoadComponent,

    // Pipes
    FilterPipe,
    CountPipe,
    OrderByPipe,
    TranslatePipe,
    SanitizeHtmlPipe,
    LetDirective,
    LocalVariableDirective,

    // Pas encore classés

    // UIActionHeaderComponent,
    // GridOptionUnselectDirective,
    // ContentLayoutComponent,

    // Mise en forme des pages
    WorkPageComponent,
    WorkPageSystemComponent,
    WorkPageHeaderComponent,
    WorkPageErrorsComponent,
    WorkPageContentComponent,
    WorkPageRightComponent,
    WorkPageLeftComponent,
    WorkPageHeaderContentComponent,
    WorkPageHeaderContentTitleComponent,
    WorkItemSectionComponent,
    // Common Components
    // List
    TaskTreeListComponent,
    ToolbarComponent,
    ToolbarItemsComponent,
    ToolbarItemButtonComponent,
    ToolbarItemWaitButtonComponent,
    ToolbarItemDropDownButtonComponent,
    ToolbarItemMenuButtonComponent,
    ToolbarItemButtonGroupComponent,
    SelectTemplateComponent,
    CoreTimespanComponent,
    // Form
    WorkPageHeaderContentBodyComponent,
    EditableListComponent,
    // Components
    LicenseComponent,
    HtmlEditorComponent,
    // Menu
    // Listes
    LayoutMessageItemComponent,
    WarningCustomerItemComponent,
    WarningMessageItemComponent,
    ValidationErrorComponent,
    //NOT MIG
    WorkItemFormSystemComponent,
    CoreSubFormGanttComponent,
    CoreDesignerRuleListComponent,

    // Common
    NotFoundComponent,
    NoLicenseComponent,
    UnauthorizedComponent,
    ErrorComponent,
    CoreDebugComponent,
    CoreNavMenuComponent,
    CoreAlertComponent,
    CoreNotificationComponent,
    CoreMassiveLinkComponent,
    // Pipes
    FilterPipe,
    CountPipe,
    OrderByPipe,
    TranslatePipe,
    TranslateFieldComponent,
    SanitizeHtmlPipe,

    // Directives
    ClcSwitchOnlyOne,
    ClcSwitchOnlyOneCase,
    LetDirective,
    LocalVariableDirective,
    CoreTemplatesComponent,
    CoreImportDataPopupComponent,
    CoreWorkSubFormComponent,
    CoreSubFormLinkListComponent,
    TicketEmailListComponent,
    CoreTagBoxComponent,
    CoreTagBoxCustomItemComponent,
    TagBoxEmailComponent,
    // Pas encore classés
    //AppLayoutComponent,
    CoreHasPoliciesDirective,
    HtmlEditorTranslatedComponent,
    // GridOptionDirective,

    // UIActionHeaderComponent,
    // GridOptionUnselectDirective,
    // ContentLayoutComponent,

    // Mise en forme des pages

    // Common Components

    CoreListComponent,
    CoreManageListComponent,
    CoreLinkListComponent,
    CoreCheckLinkListComponent,
    CoreFlatListComponent,
    CoreCommandAssetCategoryListComponent,
    CoreDashboardUserContainerComponent,
    CoreWidgetWarningMessageComponent,
    CoreSimpleListComponent,
    CoreSelectSimpleListComponent,
    CorePrintLinkListComponent,
    CoreDynamicLinkListComponent,

    // Form
    // form
    CoreWorkItemComponent,
    CoreWorkFormComponent,
    CoreWorkItemFieldComponent,
    CoreWorkItemFormComponent,
    CoreWorkItemControlDynamicComponent,
    CoreWorkItemHostDirective,
    CoreWorkItemGroupDynamicComponent,
    CoreWorkItemTabGroupComponent,
    CoreWorkItemSectionGroupComponent,
    CoreWorkItemSectionContainerComponent,
    CoreGridComponent,
    CoreWidgetDashboardComponent,
    CoreTrackingLogListComponent,
    CoreFileManagerComponent,
    CoreFileUploaderComponent,
    CoreFileManagerViewerComponent,
    CoreDashboardUserAeraListComponent,
    ViewerPopUpComponent,
    CoreMatrixComponent,
    CoreWorkTimeComponent,
    PropertyChangeComponent,
    CoreVisibleSelectableEntityComponent,
    CoreVisibleSelectableLinkEntityComponent,
    CoreChronoComponent,

    //Nav
    CoreNavMenuComponent,
    CoreNavGroupComponent,

    CoreNavMenuItemComponent,
    CoreTimespanComponent,
    LogicDiagramComponent,

    CoreCurrentInStockListComponent,
    CoreCartListSelectComponent,
    CoreStockCreatedAssetListComponent,
    CoreCartListComponent,

    CoreMassiveEditComponent,
    CoreSchedulerComponent,
    CoreTrashListComponent,
    CorePermissionsListComponent,
    CorePnpEntityListComponent,
    // Components
    CoreFilterBuilderComponent,
    TagBoxEmailComponent,
    CoreResearchComponent,
    // Listes
    CoreWorkItemsComponent,
    CoreLinkComponent,
    CorePasswordComponent,
    CoreSelectListComponent,
    CoreDynamicPopUpComponent,
    CoreEmailPrototypeListComponent,
    CoreEmailPrototypeComponent,
    CoreEmailPrototypeTrashListComponent,
    CoreEmailPrototypeLogListComponent,
    CoreMemoAreaComponent,
    CoreColumnChooserComponent,
    CoreFieldChooserComponent,
    CoreDayOffComponent,
    ViewerPopUpComponent,
    MessageTicketComponent,
    CoreTicketListComponent,
    CoreTicketAttachmentListComponent,
    CoreTicketArchivedListComponent,
    DragAndDropMassiveComponent,
    CoreSelectRemotePopupComponent,
    MobileManageListComponent,
    MobileListComponent,
    MobileToolbarComponent,
    MobileNavMenuComponent,
    MobileActionsComponent,
    StarRatingComponent,
    ServiceCatalogComponent,
    FormDesignerComponent,
    FormDesignerPopupComponent,
    FormDesignerContainerComponent,
    BrotherBpacComponent,
    BrotherBpacPopupComponent,
    CoreFileSingleManagerComponent,
    ButtonComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    //BrowserAnimationsModule,
    RouterModule,
    // Common
    DevExtremeModule,
    ReactiveFormsModule,
    FormsModule,
    // App
    MaterialModule,
    GraphQLModule,
    StarRatingModule.forRoot(),
    ColorPickerModule,
    MonacoEditorModule.forRoot(),
    // App
  ],
  exports: [
    //NOTM IG
    // GridOptionDirective,
    // Common
    LoadComponent,
    SelectTemplateComponent,
    TaskTreeListComponent,
    ToolbarItemButtonComponent,
    ToolbarItemWaitButtonComponent,
    ToolbarItemDropDownButtonComponent,
    ToolbarItemMenuButtonComponent,
    ToolbarItemButtonGroupComponent,
    ToolbarComponent,
    ToolbarItemsComponent,
    LetDirective,
    LocalVariableDirective,
    FilterPipe,
    CountPipe,
    OrderByPipe,
    SanitizeHtmlPipe,
    HtmlEditorComponent,
    HtmlEditorTranslatedComponent,

    // GridOptionUnselectDirective,
    //ContentLayoutComponent,
    BreadcrumbComponent,
    FormDesignerComponent,
    FormDesignerPopupComponent,
    FormDesignerContainerComponent,

    TranslatePipe,
    LayoutMessageItemComponent,
    WarningCustomerItemComponent,
    WarningMessageItemComponent,
    ValidationErrorComponent,

    // Components
    LicenseComponent,
    WorkPageComponent,
    WorkPageSystemComponent,
    WorkPageHeaderComponent,
    WorkPageErrorsComponent,
    WorkPageContentComponent,
    WorkPageLeftComponent,
    WorkPageRightComponent,
    WorkPageHeaderContentComponent,
    WorkPageHeaderContentTitleComponent,
    WorkPageHeaderContentBodyComponent,
    DragAndDropMassiveComponent,

    //NOTMIG
    WorkItemFormSystemComponent,
    // Angular
    CommonModule,
    RouterModule,
    MaterialModule,
    TagBoxEmailComponent,
    TranslateFieldComponent,
    WorkItemSectionComponent,
    // App
    CoreHasPoliciesDirective,
    CoreAlertComponent,
    CoreNotificationComponent,
    CoreSubFormGanttComponent,

    // GridOptionDirective,
    // Common
    CoreWidgetDashboardComponent,
    DevExtremeModule,
    ReactiveFormsModule,
    CoreListComponent,
    CoreDebugComponent,
    // Directive
    ClcSwitchOnlyOne,
    ClcSwitchOnlyOneCase,
    LetDirective,
    LocalVariableDirective,
    FilterPipe,
    CountPipe,
    OrderByPipe,
    SanitizeHtmlPipe,
    PropertyChangeComponent,
    CoreDashboardUserContainerComponent,
    CoreDashboardUserAeraListComponent,
    ViewerPopUpComponent,
    CoreWorkSubFormComponent,
    CoreSubFormLinkListComponent,
    TicketEmailListComponent,
    TagBoxEmailComponent,
    CoreDesignerRuleListComponent,
    // GridOptionUnselectDirective,
    // form
    CoreWorkItemComponent,
    CoreWorkItemFieldComponent,
    CoreWorkItemHostDirective,
    CoreWorkFormComponent,
    CoreWorkItemFormComponent,
    CoreWorkItemControlDynamicComponent,
    CoreWorkItemGroupDynamicComponent,
    CoreLinkListComponent,
    CorePrintLinkListComponent,
    CoreDynamicLinkListComponent,
    CoreCheckLinkListComponent,
    CoreWorkItemTabGroupComponent,
    CoreWorkItemSectionGroupComponent,
    CoreWorkItemSectionContainerComponent,
    CoreMassiveEditComponent,
    CoreResearchComponent,
    CoreTrashListComponent,
    CoreGridComponent,
    CoreTrackingLogListComponent,
    CoreCommandAssetCategoryListComponent,
    CoreFileManagerComponent,
    CoreFileUploaderComponent,
    CoreFileManagerViewerComponent,
    CoreCurrentInStockListComponent,
    CoreCartListSelectComponent,
    CoreStockCreatedAssetListComponent,
    CoreCartListComponent,
    CoreWidgetWarningMessageComponent,
    CorePnpEntityListComponent,
    CoreMatrixComponent,
    CoreWorkTimeComponent,
    //ContentLayoutComponent,
    CoreNavMenuComponent,
    TranslatePipe,
    CoreTemplatesComponent,
    CoreWorkItemsComponent,
    CoreManageListComponent,
    CoreSimpleListComponent,
    CoreSelectSimpleListComponent,
    CoreSchedulerComponent,
    CoreFlatListComponent,
    CoreVisibleSelectableEntityComponent,
    CoreVisibleSelectableLinkEntityComponent,

    //Nav
    CoreFilterBuilderComponent,

    CoreNavMenuComponent,
    CoreNavGroupComponent,
    CoreNavMenuItemComponent,
    LogicDiagramComponent,
    CoreImportDataPopupComponent,
    CoreLinkComponent,
    CorePasswordComponent,
    CoreSelectListComponent,
    CoreDynamicPopUpComponent,
    CorePermissionsListComponent,
    CoreEmailPrototypeListComponent,
    CoreEmailPrototypeComponent,
    CoreEmailPrototypeTrashListComponent,
    CoreEmailPrototypeLogListComponent,
    CoreMemoAreaComponent,
    CoreMassiveLinkComponent,
    CoreColumnChooserComponent,
    CoreFieldChooserComponent,
    CoreDayOffComponent,
    ViewerPopUpComponent,
    MessageTicketComponent,
    CoreTicketListComponent,
    CoreTicketAttachmentListComponent,
    CoreTicketArchivedListComponent,
    CoreSelectRemotePopupComponent,
    MobileManageListComponent,
    MobileListComponent,
    MobileActionsComponent,
    StarRatingComponent,
    ServiceCatalogComponent,
    BrotherBpacComponent,
    BrotherBpacPopupComponent,
    CoreFileSingleManagerComponent,
    ButtonComponent,
  ],
})
export class SharedModule2 {
  static forRoot(): ModuleWithProviders<SharedModule2> {
    return {
      ngModule: SharedModule2,
      providers: [
        TemplatesService,
        BreadcrumbService,
        ModelRuleApplicationService,
      ],
    };
  }
}
