import {
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService, FileModelType } from '@clarilog/core';
import { GqlField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { FileManagerCoreService } from '@clarilog/core/services2/graphql/generated-types/services/file-manager.service';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate';
import { DxFileUploaderComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { v4 as uuidv4 } from 'uuid';

/** Représente la classe du composent cl-file-uploader. */
@Component({
  selector: 'clc-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFileUploaderComponent),
      multi: true,
    },
  ],
})
export class CoreFileUploaderComponent implements ControlValueAccessor, OnInit {
  @Input() dynamicFieldName: string;
  @Input() modelState: ModelState;
  @Input() addItems: ModelFnContext;
  @Input() removeItems: ModelFnContext;
  @Input() readOnly: boolean = false;
  @ViewChild('fileUploader') uploader: DxFileUploaderComponent;
  /** Représente la valeur. */
  _value: String;
  _filesItem: FileModelType[] = [];
  _tempFileId: any;
  allowedFileExtensions: any[];
  instanceFileComponent: any;
  isDropZoneActive = false;
  imageSource = '';
  textVisible = true;
  progressVisible = false;
  progressValue = 0;
  dataSource: DataSource;
  urlImage: string;
  /** Définit la résolution max pour une image basé sur blob*/
  maxChunkSize: number = 2000000;
  /** Obtient ou définit si on est en erreur de chunkSize */
  errorMessageChunk: boolean = false;
  /** Obtient ou définit la source de données. */
  @Input() source: ModelDataSourceContext;
  // /** Obtient ou définit les éléments ajoutés. */
  @Input() itemsAdded: any;
  savedSubscriber: Subscription;
  _filesItem_Added: FileModelType[] = [];
  _filesItem_Removed: FileModelType[] = [];
  isNewValue: boolean = false;
  // /** Set a dynamic "id" HTML attribute for drop zone */
  dropzoneId: string;

  /** Obtient ou définit l'état  */
  disabled = false;

  get value(): String {
    return this._value;
  }
  set value(value: String) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  constructor(
    private route: ActivatedRoute,
    public fileManagerService: FileManagerCoreService,
    private _envService: EnvironmentService,
    private injector: Injector,
    private authorizationService: AuthorizationCoreService,
  ) {
    this.allowedFileExtensions = ['.jpg', '.png', '.jpeg'];
    this.uploadFileChunk = this.uploadFileChunk.bind(this);
  }

  async onInitialized(e) {
    this.instanceFileComponent = e.component;
    const id = this.route.snapshot.paramMap.get('id');
  }

  customizeDetailColumns(columns) {
    columns[1].cellTemplate = 'fileOpenCellTemplate';
    return columns;
  }

  async ngOnInit() {
    this.dropzoneId = uuidv4();
    this.savedSubscriber = this.modelState.formComponent.onSaved.subscribe(
      (res) => {
        if (this.isNewValue) {
          this.addItems.fnCall().subscribe((value) => {});
        }
        this.isNewValue = false;
      },
    );
  }

  writeValue(value: any): void {
    if (value != undefined) {
      this.value = value;
      this.urlImage = `${this._envService.apiURL}/file/get/${value}`;
    }
  }
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** Permet de modifier les items de add. */
  private setItemsAdded(items: any) {
    if (items != null) {
      this.urlImage = `${this._envService.apiURL}/file/get/${items}`;
    }
    this._value = items;
    this.onChange(this._value);
    this.onTouched();
    this.isNewValue = true;
  }

  /** Permet de lire le fichier pour l'envoyer. */
  async readFileAsync(chunksInfo): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (file) => {
        let result = btoa(reader.result.toString());
        resolve(result);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(chunksInfo.chunkBlob);
    });
  }

  onDropZoneEnter(e) {
    if (e.dropZoneElement.id === this.dropzoneId) this.isDropZoneActive = true;
  }

  onDropZoneLeave(e) {
    if (e.dropZoneElement.id === this.dropzoneId) this.isDropZoneActive = false;
  }

  onUploaded(e) {
    const file = e.file;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.isDropZoneActive = false;
      this.imageSource = fileReader.result as string;
    };
    fileReader.readAsDataURL(file);
    this.textVisible = false;
    this.progressVisible = false;
    this.progressValue = 0;
  }

  onProgress(e) {
    this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
  }

  onUploadStarted(e) {
    this.imageSource = '';
    this.progressVisible = true;
  }

  async uploadFileChunk(fileData, chunksInfo, destinationDir) {
    this.errorMessageChunk = false;
    let organisationId = this.authorizationService.user.getTenantId();

    if (this.dynamicFieldName != undefined) {
      organisationId = undefined;
    }

    if (this.maxChunkSize >= fileData.size) {
      if (chunksInfo.bytesUploaded == 0) fileData['_tempFileId'] = null;
      let chunks = await this.readFileAsync(chunksInfo);
      let reader = new FileReader();
      reader.onload = async (file) => {
        let result = btoa(reader.result.toString());
        //resolve(result);
        let lastChunk = chunksInfo.chunkIndex == chunksInfo.chunkCount - 1;
        let fields = [GqlField.create('data')];

        // TODO check
        fileData['_tempFileId'] = await this.injector
          .get(this.source.serviceName)
          ['uploadChunkFile'](
            fields,
            true,
            fileData.size,
            true,
            lastChunk,
            chunksInfo.chunkIndex,
            fileData.type,
            fileData.name,
            fileData['_tempFileId']?.data,
            organisationId,
            result,
          )
          .toPromise()
          .then((value: any) => {
            if (chunksInfo.chunkCount == chunksInfo.chunkIndex + 1) {
              (<any>fileData).fileId = value?.data;
              this._filesItem_Added.push(fileData);
              this.setItemsAdded(value?.data);
            }
          });
      };
      reader.readAsBinaryString(chunksInfo.chunkBlob);
    } else {
      this.errorMessageChunk = true;
      this.progressVisible = false;
      notify(
        TranslateService.get('entities/fileUploader/errorMessageChunk'),
        'error',
        10000,
      );
      this.uploader.instance.reset();
    }
  }

  /** Permet de reconstituer le fichier. */
  async writeFileAsync(fileInfo) {
    let fields = [GqlField.create('data')];
    var sourceFile = await this.fileManagerService
      .downloadFile(fields, fileInfo.fileId)
      .toPromise();
    var b64Data = sourceFile.data;
    var bin = atob(b64Data);
    const contentType = fileInfo.type;
    const byteNumbers = new Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      byteNumbers[i] = bin.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  /**
   * Permet de demander la validation avant de supprimer le logo
   */
  async click($event) {
    confirm(
      TranslateService.get('entities/fileUploader/deleteElementConfirm'),
      TranslateService.get('confirm-title'),
    ).then((result) => {
      if (result) {
        if (this.dynamicFieldName != undefined) {
          this.removeItems.context.params.set('fileId', () => this.value);
          this.removeItems.context.params.set(
            'propertyName',
            () => this.dynamicFieldName,
          );
        }
        this.removeItems.fnCall().subscribe((value) => {
          if (value) {
            this.imageSource = null;
            this.urlImage = null;
            this.textVisible = true;
            this.value = undefined;
          }
        });
      }
    });
  }
}
