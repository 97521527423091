import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDynamicPropertyListsBaseVariables, FirstDynamicPropertyListsBaseVariables, CountDynamicPropertyListsBaseVariables, FindDynamicPropertyListsBaseVariables, SearchDynamicPropertyListsBaseVariables, ExportSchemaDynamicPropertyListsBaseVariables, ExportDataDynamicPropertyListsBaseVariables, CustomQueryDynamicPropertyListsBaseVariables, CustomQueryIdDynamicPropertyListsBaseVariables, UsedDynamicPropertyListsBaseVariables, FindDynamicListValueDynamicPropertyListsBaseVariables, FindByTypeDynamicPropertyListsBaseVariables, ExistDynamicPropertyListsBaseVariables, InsertDynamicPropertyListsBaseVariables, UpdateDynamicPropertyListsBaseVariables, ImportDataDynamicPropertyListsBaseVariables, UpdateManyDynamicPropertyListsBaseVariables, DeleteDynamicPropertyListsBaseVariables, DownloadDynamicPropertyListValueSchemaDynamicPropertyListsBaseVariables, ImportDynamicPropertyListValueDynamicPropertyListsBaseVariables } from '../gqls'
import { GetDynamicPropertyListsDocument, FirstDynamicPropertyListsDocument, CountDynamicPropertyListsDocument, FindDynamicPropertyListsDocument, SearchDynamicPropertyListsDocument, ExportSchemaDynamicPropertyListsDocument, ExportDataDynamicPropertyListsDocument, CustomQueryDynamicPropertyListsDocument, CustomQueryIdDynamicPropertyListsDocument, UsedDynamicPropertyListsDocument, FindDynamicListValueDynamicPropertyListsDocument, FindByTypeDynamicPropertyListsDocument, ExistDynamicPropertyListsDocument, InsertDynamicPropertyListsDocument, UpdateDynamicPropertyListsDocument, ImportDataDynamicPropertyListsDocument, UpdateManyDynamicPropertyListsDocument, DeleteDynamicPropertyListsDocument, DownloadDynamicPropertyListValueSchemaDynamicPropertyListsDocument, ImportDynamicPropertyListValueDynamicPropertyListsDocument } from '../gqls'
import { ServiceSingleResultOfDynamicPropertyList, QueryContextOfDynamicPropertyList, FilterOfDynamicPropertyList, ServiceSingleResultOfInt64, ServiceListResultOfDynamicPropertyList, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfDynamicPropertyListValue, QueryContextOfDynamicPropertyListValue, FilterOfDynamicPropertyListValue, DynamicPropertyListInput, FieldUpdateOperatorOfDynamicPropertyList } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DynamicPropertyListBaseService {
    
public modelName = 'dynamicPropertyList';
public modelPluralName = 'dynamicPropertyLists';

	private getDynamicPropertyListsQuery: GetDynamicPropertyListsDocument;
	private firstDynamicPropertyListsQuery: FirstDynamicPropertyListsDocument;
	private countDynamicPropertyListsQuery: CountDynamicPropertyListsDocument;
	private findDynamicPropertyListsQuery: FindDynamicPropertyListsDocument;
	private searchDynamicPropertyListsQuery: SearchDynamicPropertyListsDocument;
	private exportSchemaDynamicPropertyListsQuery: ExportSchemaDynamicPropertyListsDocument;
	private exportDataDynamicPropertyListsQuery: ExportDataDynamicPropertyListsDocument;
	private customQueryDynamicPropertyListsQuery: CustomQueryDynamicPropertyListsDocument;
	private customQueryIdDynamicPropertyListsQuery: CustomQueryIdDynamicPropertyListsDocument;
	private usedDynamicPropertyListsQuery: UsedDynamicPropertyListsDocument;
	private findDynamicListValueDynamicPropertyListsQuery: FindDynamicListValueDynamicPropertyListsDocument;
	private findByTypeDynamicPropertyListsQuery: FindByTypeDynamicPropertyListsDocument;
	private existDynamicPropertyListsQuery: ExistDynamicPropertyListsDocument;
	private insertDynamicPropertyListsMutation: InsertDynamicPropertyListsDocument;
	private updateDynamicPropertyListsMutation: UpdateDynamicPropertyListsDocument;
	private importDataDynamicPropertyListsMutation: ImportDataDynamicPropertyListsDocument;
	private updateManyDynamicPropertyListsMutation: UpdateManyDynamicPropertyListsDocument;
	private deleteDynamicPropertyListsMutation: DeleteDynamicPropertyListsDocument;
	private downloadDynamicPropertyListValueSchemaDynamicPropertyListsMutation: DownloadDynamicPropertyListValueSchemaDynamicPropertyListsDocument;
	private importDynamicPropertyListValueDynamicPropertyListsMutation: ImportDynamicPropertyListValueDynamicPropertyListsDocument;

	constructor(private injector: Injector) {
		this.getDynamicPropertyListsQuery = this.injector.get(GetDynamicPropertyListsDocument);
		this.firstDynamicPropertyListsQuery = this.injector.get(FirstDynamicPropertyListsDocument);
		this.countDynamicPropertyListsQuery = this.injector.get(CountDynamicPropertyListsDocument);
		this.findDynamicPropertyListsQuery = this.injector.get(FindDynamicPropertyListsDocument);
		this.searchDynamicPropertyListsQuery = this.injector.get(SearchDynamicPropertyListsDocument);
		this.exportSchemaDynamicPropertyListsQuery = this.injector.get(ExportSchemaDynamicPropertyListsDocument);
		this.exportDataDynamicPropertyListsQuery = this.injector.get(ExportDataDynamicPropertyListsDocument);
		this.customQueryDynamicPropertyListsQuery = this.injector.get(CustomQueryDynamicPropertyListsDocument);
		this.customQueryIdDynamicPropertyListsQuery = this.injector.get(CustomQueryIdDynamicPropertyListsDocument);
		this.usedDynamicPropertyListsQuery = this.injector.get(UsedDynamicPropertyListsDocument);
		this.findDynamicListValueDynamicPropertyListsQuery = this.injector.get(FindDynamicListValueDynamicPropertyListsDocument);
		this.findByTypeDynamicPropertyListsQuery = this.injector.get(FindByTypeDynamicPropertyListsDocument);
		this.existDynamicPropertyListsQuery = this.injector.get(ExistDynamicPropertyListsDocument);
		this.insertDynamicPropertyListsMutation = this.injector.get(InsertDynamicPropertyListsDocument);
		this.updateDynamicPropertyListsMutation = this.injector.get(UpdateDynamicPropertyListsDocument);
		this.importDataDynamicPropertyListsMutation = this.injector.get(ImportDataDynamicPropertyListsDocument);
		this.updateManyDynamicPropertyListsMutation = this.injector.get(UpdateManyDynamicPropertyListsDocument);
		this.deleteDynamicPropertyListsMutation = this.injector.get(DeleteDynamicPropertyListsDocument);
		this.downloadDynamicPropertyListValueSchemaDynamicPropertyListsMutation = this.injector.get(DownloadDynamicPropertyListValueSchemaDynamicPropertyListsDocument);
		this.importDynamicPropertyListValueDynamicPropertyListsMutation = this.injector.get(ImportDynamicPropertyListValueDynamicPropertyListsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyList> {
    
    		let variables: GetDynamicPropertyListsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.get));
            }
            else{
                let result:ServiceSingleResultOfDynamicPropertyList={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDynamicPropertyList,
		@Args('filter?') filter?: FilterOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyList> {
    
    		let variables: FirstDynamicPropertyListsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDynamicPropertyListsBaseVariables = {
    			filter: filter
    		}
    				return this.countDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDynamicPropertyList,
		@Args('filter?') filter?: FilterOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyList> {
    
    		let variables: FindDynamicPropertyListsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDynamicPropertyList,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyList> {
    
    		let variables: SearchDynamicPropertyListsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDynamicPropertyListsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDynamicPropertyListsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDynamicPropertyList,
		@Args('filter?') filter?: FilterOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyList> {
    
    		let variables: CustomQueryDynamicPropertyListsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDynamicPropertyList,
		@Args('filter?') filter?: FilterOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyList> {
    
    		let variables: CustomQueryIdDynamicPropertyListsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDynamicPropertyListsBaseVariables = {
    			ids: ids
    		}
    				return this.usedDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.used));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicListValue(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDynamicPropertyListValue,
		@Args('filter?') filter?: FilterOfDynamicPropertyListValue,
		@Args('dynamicListId?') dynamicListId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyListValue> {
    
    		let variables: FindDynamicListValueDynamicPropertyListsBaseVariables = {
    			dynamicListId: dynamicListId,
			filter: filter,
			options: options
    		}
    				return this.findDynamicListValueDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.findDynamicListValue));
    	}

    	/**  */
    	@InjectArgs
    	public findByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('types?') types?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfDynamicPropertyList,
		@Args('filter?') filter?: FilterOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyList> {
    
    		let variables: FindByTypeDynamicPropertyListsBaseVariables = {
    			types: types,
			filter: filter,
			options: options
    		}
    				return this.findByTypeDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.findByType));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDynamicPropertyListsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDynamicPropertyListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: DynamicPropertyListInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyList> {
    
    		let variables: InsertDynamicPropertyListsBaseVariables = {
    			entity: entity
    		}
    				return this.insertDynamicPropertyListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyList> {
    
    		let variables: UpdateDynamicPropertyListsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateDynamicPropertyListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataDynamicPropertyListsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataDynamicPropertyListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfDynamicPropertyList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyDynamicPropertyListsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyDynamicPropertyListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteDynamicPropertyListsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteDynamicPropertyListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.delete));
    	}

    	/**  */
    	@InjectArgs
    	public downloadDynamicPropertyListValueSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: DownloadDynamicPropertyListValueSchemaDynamicPropertyListsBaseVariables = {
    
    		}
    				return this.downloadDynamicPropertyListValueSchemaDynamicPropertyListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.downloadDynamicPropertyListValueSchema));
    	}

    	/**  */
    	@InjectArgs
    	public importDynamicPropertyListValue(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('file?') file?: string,
		@Args('dynamicPropertyListId?') dynamicPropertyListId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDynamicPropertyListValueDynamicPropertyListsBaseVariables = {
    			file: file,
			dynamicPropertyListId: dynamicPropertyListId
    		}
    				return this.importDynamicPropertyListValueDynamicPropertyListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyLists.importDynamicPropertyListValue));
    	}
    
}