import { ContentChild, Input, Directive } from '@angular/core';
import {
  CoreGraphQLDataSource,
  GraphQLStore,
} from '@clarilog/core/services2/graphql/graphql-store.service';
import {
  ModelContextCore,
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { Column, MasterDetail } from '@clarilog/shared2/models/schema';
import { ToolbarItemsComponent } from '../../toolbar/toolbar-items/toolbar-items.component';
import { TreeListService } from './tree-list.service';
import { AtlasIndex } from '@clarilog/shared2/models/schema.extensions';

/** Représente un type pouvant être 'Grid' ou 'Tree'. */
export type ListType = 'Grid' | 'Tree' | 'Scheduler';

/** options de base de tree. */
@Directive()
export abstract class OptionsBase extends TreeListService {
  /** Obtient ou définit le champ représentant la clé (Utilisé uniquement pour un Array). */
  @Input() keyExpr: string = 'id';
  /** Obtient ou définit la multiplicité. */
  @Input() multiple: boolean = true;
  /** Obtient ou définit les colonnes. */
  _columns: Column[] = [];
  @Input() set columns(value: Column[]) {
    // Vé"rification si une colonne en tant que Link
    this._columns = value;
    this.forceAutoLink();
  }
  get columns(): Column[] {
    return this._columns;
  }
  /** Obtient ou définit le champ parent. */
  @Input() parentIdExpr: string = 'parentId';
  /** Obtient ou définit la récurssivité. */
  @Input() recursive: boolean = false;
  /** Obtient ou définit les données sélectionnées. */
  @Input() selectedData: any[] = [];
  /** Obtient ou définit les clés sélectionnées. */
  @Input() selectedKeys: Array<string> = [];
  /** Obtient ou définit le champ à afficher. */
  @Input() enabledExp: string;

  /** Obtient ou définit la source de données. */
  @Input() // source: DataSource;
  get source(): ModelDataSourceContext {
    return this._source;
  }
  set source(value: ModelDataSourceContext) {
    if (
      value != undefined &&
      value.datasource != undefined &&
      value.datasource.store != undefined
    ) {
      let pageSize = value.datasource.pageSize();
      let paginate = value.datasource.paginate();

      if (this.type == 'Scheduler') {
        pageSize = 1000;
        paginate = false;
      }

      this._source = new ModelDataSourceContext({
        serviceName:
          value.serviceName != undefined ? value.serviceName : undefined,
        datasource: new CoreGraphQLDataSource({
          store: value.datasource.store(),
          pageSize: pageSize,
          paginate: paginate,
          postProcess:
            value.datasource['_postProcessFunc'] != undefined
              ? value.datasource['_postProcessFunc']
              : null,
        }),
        rootState: value.rootState,
      });
      this._source.context = value.context;
      this._source.datasource.filter(value.datasource.filter());
      if ((<any>value)._setFilter === undefined) {
        (<any>value)._setFilter = true;

        value.datasource.filter = (filterExpr: any = undefined): void | any => {
          if (!Array.isArray(this._source))
            return this._source.datasource.filter(filterExpr);
        };
      }
      (<any>this._source)._postProcessFunc = (<any>value)._postProcessFunc;
    } else if (value != undefined && Array.isArray(value)) {
      this._source = value;
    } else {
      this._source = undefined;
    }
  }

  get context(): ModelContextCore {
    return (this._source.datasource.store() as GraphQLStore).context.context
      .config.context.context;
  }

  get sharedContext(): ModelContextCore {
    return (this._source.datasource.store() as GraphQLStore).context.context
      .config.context.rootState.sharedContext;
  }

  get state(): ModelState {
    return this._source?.rootState;
  }

  _source: ModelDataSourceContext;
  /** Obtient ou définit si le drag and drop est activé. */
  @Input() dragAndDrop: boolean = false;
  /** Obtient ou définit si le drag and drop est autorisé uniquement sur un niveau identique. */
  @Input() dragOnlySameLevel: boolean = false;
  /** Obtient ou définit la fonction pour le déplacement. */
  @Input() drag: ModelFnContext;
  /** Obtient ou définit les propriétés des sous grilles. */
  @Input() masterDetail: MasterDetail;
  /** Obtient ou définit le tableau des propriétés des sous grilles. */
  @Input() masterDetails: MasterDetail[];
  /** Obtient ou définit les propriétés des sous grilles. */
  @Input() viewSelectMode: boolean;

  /** Obtient ou définit le type de la liste. */
  @Input() type: ListType = 'Grid';

  /** Obtient ou définit le type de la liste. */
  @Input() sourceRessource: ModelDataSourceContext;

  /*** Obtient la méthode de mise à jour d'une ressource scheduler */
  @Input() insertRessource: ModelFnContext;

  /*** Obtient la méthode de mise à jour d'une ressource scheduler */
  @Input() updateRessource: ModelFnContext;

  /*** Obtient la méthode de mise à jour d'une ressource scheduler */
  @Input() removeItems: ModelFnContext;

  /** Obtient ou définit les données que l'on souhaite récupérer. */
  @Input() fields: ModelFnContext;

  /** PErmet de forcer l'hyperlien */
  _forceLinkEmpty: boolean = false;
  @Input() set forceLinkEmpty(value: boolean) {
    this._forceLinkEmpty = value;
    this.forceAutoLink();
  }
  get forceLinkEmpty(): boolean {
    return this._forceLinkEmpty;
  }

  forceAutoLink() {
    if (this.columns != undefined && this.forceLinkEmpty) {
      let linkColumn = this.columns.filter(
        (f) => f.link != undefined && f.link === true,
      );
      if (linkColumn == undefined || linkColumn.length == 0) {
        // Recherche la valeur par défaut name
        // TODO gestion a 99% de toutes les liste ayant le field Name
        linkColumn = this.columns.filter(
          (f) => f.field != undefined && f.field == 'name',
        );
        if (linkColumn != undefined || linkColumn.length > 0) {
          if (linkColumn[0] != undefined) {
            linkColumn[0].link = true;
          }
        }
      }
    }
  }
}
/** Options commune pour l'écran liste. */
@Directive()
export abstract class ListComponentBase extends OptionsBase {
  /** Obtient ou définit les actions. */
  @ContentChild(ToolbarItemsComponent, { static: true }) toolbar;

  /** Obtient ou définit la clé du layout. */
  @Input() layoutKey: string;

  /** Obtient ou définit si le master detail doit utilise le filtre auto. */
  @Input() useAutofilter: boolean = true;

  /** Obtient ou définit si on utilise la methode de recherche Atlas. */
  @Input() atlasIndexes: AtlasIndex[] = [];
}
