import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfOrganization, FilterOfOrganization, QueryContextOfOrganization, ServiceSingleResultOfInt64, ServiceListResultOfOrganization, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, FilterOfFileModel, QueryContextOfFileModel, ServiceSingleResultOfBoolean, ServiceSingleResultOfContentCustomization, FilterOfContentCustomization, QueryContextOfContentCustomization, ServiceListResultOfRole, FilterOfRole, QueryContextOfRole, ServiceListResultOfUser, FilterOfUser, QueryContextOfUser, ServiceListResultOfVerifaliaEmailCheck, FilterOfVerifaliaEmailCheck, QueryContextOfVerifaliaEmailCheck, ServiceListResultOfOneEventLog, FilterOfOneEventLog, QueryContextOfOneEventLog, ServiceSingleResultOfVerifaliaEmailCheck, QueryContextInput, ServiceListResultOfEmailConnector, FilterOfEmailConnector, QueryContextOfEmailConnector, ServiceListResultOfEmailBounce, FilterOfEmailBounce, QueryContextOfEmailBounce, ServiceListResultOfRecipientBounce, FilterOfRecipientBounce, QueryContextOfRecipientBounce, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfOrganization, ServiceListResultOfWarningCustomer, FilterOfWarningCustomer, QueryContextOfWarningCustomer, OrganizationInput, ServiceSingleResultOfGuid, ServiceSingleResultOfFileDescriptor, FieldUpdateOperatorOfEmailConnector } from '../types'

export type GetOrganizationsResultType = {
    organizations: {
        get?: Maybe<ServiceSingleResultOfOrganization>
    }
}

export type GetOrganizationsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getOrganizations ${args} {
    organizations {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstOrganizationsResultType = {
    organizations: {
        first?: Maybe<ServiceSingleResultOfOrganization>
    }
}

export type FirstOrganizationsBaseVariables = {
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganization = null, $options: QueryContextOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstOrganizations ${args} {
    organizations {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountOrganizationsResultType = {
    organizations: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountOrganizationsBaseVariables = {
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countOrganizations ${args} {
    organizations {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOrganizationsResultType = {
    organizations: {
        find?: Maybe<ServiceListResultOfOrganization>
    }
}

export type FindOrganizationsBaseVariables = {
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfOrganization = null, $filter: FilterOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOrganizations ${args} {
    organizations {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchOrganizationsResultType = {
    organizations: {
        search?: Maybe<ServiceListResultOfOrganization>
    }
}

export type SearchOrganizationsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchOrganizations ${args} {
    organizations {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaOrganizationsResultType = {
    organizations: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaOrganizationsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaOrganizations ${args} {
    organizations {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataOrganizationsResultType = {
    organizations: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataOrganizationsBaseVariables = {
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataOrganizations ${args} {
    organizations {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryOrganizationsResultType = {
    organizations: {
        customQuery?: Maybe<ServiceListResultOfOrganization>
    }
}

export type CustomQueryOrganizationsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfOrganization = null, $options: QueryContextOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryOrganizations ${args} {
    organizations {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdOrganizationsResultType = {
    organizations: {
        customQueryId?: Maybe<ServiceListResultOfOrganization>
    }
}

export type CustomQueryIdOrganizationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfOrganization = null, $options: QueryContextOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdOrganizations ${args} {
    organizations {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindFilesOrganizationsResultType = {
    organizations: {
        findFiles?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindFilesOrganizationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les fichiers liés. */
@Injectable({providedIn: 'root'})
export class FindFilesOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findFilesOrganizations ${args} {
    organizations {
        findFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindFilesOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindFilesOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedOrganizationsResultType = {
    organizations: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedOrganizationsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedOrganizations ${args} {
    organizations {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindToApprovedOrganizationsResultType = {
    organizations: {
        findToApproved?: Maybe<ServiceListResultOfOrganization>
    }
}

export type FindToApprovedOrganizationsBaseVariables = {
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindToApprovedOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganization = null, $options: QueryContextOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findToApprovedOrganizations ${args} {
    organizations {
        findToApproved(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindToApprovedOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindToApprovedOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOrgaBySubOrganizationsResultType = {
    organizations: {
        findOrgaBySub?: Maybe<ServiceListResultOfOrganization>
    }
}

export type FindOrgaBySubOrganizationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindOrgaBySubOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfOrganization = null, $options: QueryContextOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOrgaBySubOrganizations ${args} {
    organizations {
        findOrgaBySub(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOrgaBySubOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOrgaBySubOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindContentCustomizationOrgaOrganizationsResultType = {
    organizations: {
        findContentCustomizationOrga?: Maybe<ServiceSingleResultOfContentCustomization>
    }
}

export type FindContentCustomizationOrgaOrganizationsBaseVariables = {
	filter: Maybe<FilterOfContentCustomization>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfContentCustomization>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindContentCustomizationOrgaOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfContentCustomization = null, $options: QueryContextOfContentCustomization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findContentCustomizationOrgaOrganizations ${args} {
    organizations {
        findContentCustomizationOrga(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindContentCustomizationOrgaOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindContentCustomizationOrgaOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfContentCustomization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type OrganizationLocalizeOrganizationsResultType = {
    organizations: {
        organizationLocalize?: Maybe<ServiceSingleResultOfOrganization>
    }
}

export type OrganizationLocalizeOrganizationsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class OrganizationLocalizeOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query organizationLocalizeOrganizations ${args} {
    organizations {
        organizationLocalize {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: OrganizationLocalizeOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<OrganizationLocalizeOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRolesOrganizationsResultType = {
    organizations: {
        findRoles?: Maybe<ServiceListResultOfRole>
    }
}

export type FindRolesOrganizationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfRole>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfRole>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindRolesOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfRole = null, $options: QueryContextOfRole = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRolesOrganizations ${args} {
    organizations {
        findRoles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRolesOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRolesOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfRole", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type IdleTimerOrganizationsResultType = {
    organizations: {
        idleTimer?: Maybe<ServiceSingleResultOfOrganization>
    }
}

export type IdleTimerOrganizationsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class IdleTimerOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query idleTimerOrganizations ${args} {
    organizations {
        idleTimer {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: IdleTimerOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<IdleTimerOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAllSubIdsOrganizationsResultType = {
    organizations: {
        findAllSubIds?: Maybe<ServiceListResultOfUser>
    }
}

export type FindAllSubIdsOrganizationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAllSubIdsOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAllSubIdsOrganizations ${args} {
    organizations {
        findAllSubIds(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAllSubIdsOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAllSubIdsOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAllSubIdsByRolesOrganizationsResultType = {
    organizations: {
        findAllSubIdsByRoles?: Maybe<ServiceListResultOfUser>
    }
}

export type FindAllSubIdsByRolesOrganizationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAllSubIdsByRolesOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAllSubIdsByRolesOrganizations ${args} {
    organizations {
        findAllSubIdsByRoles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAllSubIdsByRolesOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAllSubIdsByRolesOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindVerifaliaEmailsOrganizationsResultType = {
    organizations: {
        findVerifaliaEmails?: Maybe<ServiceListResultOfVerifaliaEmailCheck>
    }
}

export type FindVerifaliaEmailsOrganizationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfVerifaliaEmailCheck>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfVerifaliaEmailCheck>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindVerifaliaEmailsOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfVerifaliaEmailCheck = null, $options: QueryContextOfVerifaliaEmailCheck = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findVerifaliaEmailsOrganizations ${args} {
    organizations {
        findVerifaliaEmails(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindVerifaliaEmailsOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindVerifaliaEmailsOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfVerifaliaEmailCheck", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOneLogsOrganizationsResultType = {
    organizations: {
        findOneLogs?: Maybe<ServiceListResultOfOneEventLog>
    }
}

export type FindOneLogsOrganizationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfOneEventLog>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOneEventLog>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindOneLogsOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfOneEventLog = null, $options: QueryContextOfOneEventLog = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOneLogsOrganizations ${args} {
    organizations {
        findOneLogs(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOneLogsOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOneLogsOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOneEventLog", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindVerifaliaEmailStatusByEmailOrganizationsResultType = {
    organizations: {
        findVerifaliaEmailStatusByEmail?: Maybe<ServiceSingleResultOfVerifaliaEmailCheck>
    }
}

export type FindVerifaliaEmailStatusByEmailOrganizationsBaseVariables = {
	id: string, /**  */
	email: Maybe<string>, /**  */
	context: Maybe<QueryContextInput>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindVerifaliaEmailStatusByEmailOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $email: String = null, $context: QueryContextInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findVerifaliaEmailStatusByEmailOrganizations ${args} {
    organizations {
        findVerifaliaEmailStatusByEmail(id: $id, email: $email, context: $context) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindVerifaliaEmailStatusByEmailOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindVerifaliaEmailStatusByEmailOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfVerifaliaEmailCheck", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindMailslurpConnectorsOrganizationsResultType = {
    organizations: {
        findMailslurpConnectors?: Maybe<ServiceListResultOfEmailConnector>
    }
}

export type FindMailslurpConnectorsOrganizationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfEmailConnector>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailConnector>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindMailslurpConnectorsOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfEmailConnector = null, $options: QueryContextOfEmailConnector = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findMailslurpConnectorsOrganizations ${args} {
    organizations {
        findMailslurpConnectors(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindMailslurpConnectorsOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindMailslurpConnectorsOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailConnector", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindEmailBounceOrganizationsResultType = {
    organizations: {
        findEmailBounce?: Maybe<ServiceListResultOfEmailBounce>
    }
}

export type FindEmailBounceOrganizationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfEmailBounce>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailBounce>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindEmailBounceOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfEmailBounce = null, $options: QueryContextOfEmailBounce = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findEmailBounceOrganizations ${args} {
    organizations {
        findEmailBounce(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindEmailBounceOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindEmailBounceOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailBounce", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecipientBounceOrganizationsResultType = {
    organizations: {
        findRecipientBounce?: Maybe<ServiceListResultOfRecipientBounce>
    }
}

export type FindRecipientBounceOrganizationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfRecipientBounce>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfRecipientBounce>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindRecipientBounceOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfRecipientBounce = null, $options: QueryContextOfRecipientBounce = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecipientBounceOrganizations ${args} {
    organizations {
        findRecipientBounce(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecipientBounceOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecipientBounceOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfRecipientBounce", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistOrganizationsResultType = {
    organizations: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistOrganizationsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existOrganizations ${args} {
    organizations {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesOrganizationsResultType = {
    organizations: {
        findRecycles?: Maybe<ServiceListResultOfOrganization>
    }
}

export type FindRecyclesOrganizationsBaseVariables = {
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganization = null, $options: QueryContextOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesOrganizations ${args} {
    organizations {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesOrganizationsResultType = {
    organizations: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesOrganizationsBaseVariables = {
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesOrganizations ${args} {
    organizations {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyOrganizationsResultType = {
    organizations: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyOrganizationsBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyOrganizations ${args} {
    organizations {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedOrganizationsResultType = {
    organizations: {
        findArchived?: Maybe<ServiceListResultOfOrganization>
    }
}

export type FindArchivedOrganizationsBaseVariables = {
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOrganization>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganization = null, $options: QueryContextOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedOrganizations ${args} {
    organizations {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllOrganizationsResultType = {
    organizations: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllOrganizationsBaseVariables = {
	filter: Maybe<FilterOfOrganization>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllOrganizations ${args} {
    organizations {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsOrganizationsResultType = {
    organizations: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsOrganizationsBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfOrganization>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsOrganizations ${args} {
    organizations {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedExcludeWarningCustomersOrganizationsResultType = {
    organizations: {
        findUnassociatedExcludeWarningCustomers?: Maybe<ServiceListResultOfWarningCustomer>
    }
}

export type FindUnassociatedExcludeWarningCustomersOrganizationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfWarningCustomer>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfWarningCustomer>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedExcludeWarningCustomersOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfWarningCustomer = null, $options: QueryContextOfWarningCustomer = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedExcludeWarningCustomersOrganizations ${args} {
    organizations {
        findUnassociatedExcludeWarningCustomers(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedExcludeWarningCustomersOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedExcludeWarningCustomersOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWarningCustomer", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedIncludeWarningCustomersOrganizationsResultType = {
    organizations: {
        findUnassociatedIncludeWarningCustomers?: Maybe<ServiceListResultOfWarningCustomer>
    }
}

export type FindUnassociatedIncludeWarningCustomersOrganizationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfWarningCustomer>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfWarningCustomer>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedIncludeWarningCustomersOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfWarningCustomer = null, $options: QueryContextOfWarningCustomer = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedIncludeWarningCustomersOrganizations ${args} {
    organizations {
        findUnassociatedIncludeWarningCustomers(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedIncludeWarningCustomersOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedIncludeWarningCustomersOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWarningCustomer", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertOrganizationsResultType = {
    organizations: {
        insert?: Maybe<ServiceSingleResultOfOrganization>
    }
}

export type InsertOrganizationsBaseVariables = {
	entity: OrganizationInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: OrganizationInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertOrganizations ${args} {
    organizations {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateOrganizationsResultType = {
    organizations: {
        update?: Maybe<ServiceSingleResultOfOrganization>
    }
}

export type UpdateOrganizationsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfOrganization>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateOrganizations ${args} {
    organizations {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOrganization", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataOrganizationsResultType = {
    organizations: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataOrganizationsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataOrganizations ${args} {
    organizations {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyOrganizationsResultType = {
    organizations: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyOrganizationsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfOrganization>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfOrganization = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyOrganizations ${args} {
    organizations {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteOrganizationsResultType = {
    organizations: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteOrganizationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteOrganizations ${args} {
    organizations {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UploadChunkFileOrganizationsResultType = {
    organizations: {
        uploadChunkFile?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type UploadChunkFileOrganizationsBaseVariables = {
	name: Maybe<string>, /**  */
	size: number, /**  */
	type: Maybe<string>, /**  */
	chunks: Maybe<string>, /**  */
	indexChunk: number, /**  */
	lastChunk: boolean, /**  */
	fileId: Maybe<string>, /**  */
	entityId: Maybe<string>, /**  */
	visibleByHelpDeskUser: boolean, /**  */
	outgoingEmails: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UploadChunkFileOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$name: String = null, $size: Int!, $type: String = null, $chunks: String = null, $indexChunk: Int!, $lastChunk: Boolean!, $fileId: Uuid = null, $entityId: Uuid = null, $visibleByHelpDeskUser: Boolean!, $outgoingEmails: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation uploadChunkFileOrganizations ${args} {
    organizations {
        uploadChunkFile(name: $name, size: $size, type: $type, chunks: $chunks, indexChunk: $indexChunk, lastChunk: $lastChunk, fileId: $fileId, entityId: $entityId, visibleByHelpDeskUser: $visibleByHelpDeskUser, outgoingEmails: $outgoingEmails) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UploadChunkFileOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UploadChunkFileOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ApprovedOrganizationsResultType = {
    organizations: {
        approved?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ApprovedOrganizationsBaseVariables = {
	organizationIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ApprovedOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$organizationIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation approvedOrganizations ${args} {
    organizations {
        approved(organizationIds: $organizationIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ApprovedOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ApprovedOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UnblockedOrganizationsResultType = {
    organizations: {
        unblocked?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UnblockedOrganizationsBaseVariables = {
	organizationIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UnblockedOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$organizationIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation unblockedOrganizations ${args} {
    organizations {
        unblocked(organizationIds: $organizationIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UnblockedOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UnblockedOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type BlockedOrganizationsResultType = {
    organizations: {
        blocked?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type BlockedOrganizationsBaseVariables = {
	organizationIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class BlockedOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$organizationIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation blockedOrganizations ${args} {
    organizations {
        blocked(organizationIds: $organizationIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: BlockedOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<BlockedOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAllTicketsOrganizationsResultType = {
    organizations: {
        removeAllTickets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAllTicketsOrganizationsBaseVariables = {
	organizationId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAllTicketsOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$organizationId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAllTicketsOrganizations ${args} {
    organizations {
        removeAllTickets(organizationId: $organizationId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAllTicketsOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAllTicketsOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SetOrganizationLocalizeOrganizationsResultType = {
    organizations: {
        setOrganizationLocalize?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type SetOrganizationLocalizeOrganizationsBaseVariables = {
	lang: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class SetOrganizationLocalizeOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$lang: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation setOrganizationLocalizeOrganizations ${args} {
    organizations {
        setOrganizationLocalize(lang: $lang) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: SetOrganizationLocalizeOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<SetOrganizationLocalizeOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileIncidentLinkOrganizationsResultType = {
    organizations: {
        addFileIncidentLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileIncidentLinkOrganizationsBaseVariables = {
	id: string, /**  */
	fileIncidentId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileIncidentLinkOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileIncidentId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileIncidentLinkOrganizations ${args} {
    organizations {
        addFileIncidentLink(id: $id, fileIncidentId: $fileIncidentId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileIncidentLinkOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileIncidentLinkOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileIncidentLinkOrganizationsResultType = {
    organizations: {
        removeFileIncidentLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileIncidentLinkOrganizationsBaseVariables = {
	id: string, /**  */
	fileIncidentId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileIncidentLinkOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileIncidentId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileIncidentLinkOrganizations ${args} {
    organizations {
        removeFileIncidentLink(id: $id, fileIncidentId: $fileIncidentId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileIncidentLinkOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileIncidentLinkOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileRequestLinkOrganizationsResultType = {
    organizations: {
        addFileRequestLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileRequestLinkOrganizationsBaseVariables = {
	id: string, /**  */
	fileRequestId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileRequestLinkOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileRequestId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileRequestLinkOrganizations ${args} {
    organizations {
        addFileRequestLink(id: $id, fileRequestId: $fileRequestId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileRequestLinkOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileRequestLinkOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileRequestLinkOrganizationsResultType = {
    organizations: {
        removeFileRequestLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileRequestLinkOrganizationsBaseVariables = {
	id: string, /**  */
	fileRequestId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileRequestLinkOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileRequestId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileRequestLinkOrganizations ${args} {
    organizations {
        removeFileRequestLink(id: $id, fileRequestId: $fileRequestId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileRequestLinkOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileRequestLinkOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AffectedToAnOrganizationOrganizationsResultType = {
    organizations: {
        affectedToAnOrganization?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AffectedToAnOrganizationOrganizationsBaseVariables = {
	organizationId: string, /**  */
	connectedUserId: string, /**  */
	userId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AffectedToAnOrganizationOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$organizationId: Uuid!, $connectedUserId: Uuid!, $userId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation affectedToAnOrganizationOrganizations ${args} {
    organizations {
        affectedToAnOrganization(organizationId: $organizationId, connectedUserId: $connectedUserId, userId: $userId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AffectedToAnOrganizationOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AffectedToAnOrganizationOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReforceVerificationOrganizationsResultType = {
    organizations: {
        reforceVerification?: Maybe<ServiceListResultOfVerifaliaEmailCheck>
    }
}

export type ReforceVerificationOrganizationsBaseVariables = {
	id: string, /**  */
	emails: Array<Maybe<string>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ReforceVerificationOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $emails: [String] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation reforceVerificationOrganizations ${args} {
    organizations {
        reforceVerification(id: $id, emails: $emails) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ReforceVerificationOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ReforceVerificationOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfVerifaliaEmailCheck", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateEmailStatusOrganizationsResultType = {
    organizations: {
        updateEmailStatus?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateEmailStatusOrganizationsBaseVariables = {
	emails: Array<Maybe<string>>, /**  */
	newStatus: number, /**  */
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateEmailStatusOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$emails: [String] = null, $newStatus: Int!, $id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateEmailStatusOrganizations ${args} {
    organizations {
        updateEmailStatus(emails: $emails, newStatus: $newStatus, id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateEmailStatusOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateEmailStatusOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DownloadOneLogsOrganizationsResultType = {
    organizations: {
        downloadOneLogs?: Maybe<ServiceSingleResultOfFileDescriptor>
    }
}

export type DownloadOneLogsOrganizationsBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class DownloadOneLogsOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation downloadOneLogsOrganizations ${args} {
    organizations {
        downloadOneLogs(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DownloadOneLogsOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DownloadOneLogsOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfFileDescriptor", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateEmailConnectorsOrganizationsResultType = {
    organizations: {
        updateEmailConnectors?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateEmailConnectorsOrganizationsBaseVariables = {
	ids: Array<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfEmailConnector>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateEmailConnectorsOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfEmailConnector = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateEmailConnectorsOrganizations ${args} {
    organizations {
        updateEmailConnectors(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateEmailConnectorsOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateEmailConnectorsOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchiveBouncesOrganizationsResultType = {
    organizations: {
        archiveBounces?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchiveBouncesOrganizationsBaseVariables = {
	organizationId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ArchiveBouncesOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$organizationId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archiveBouncesOrganizations ${args} {
    organizations {
        archiveBounces(organizationId: $organizationId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchiveBouncesOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchiveBouncesOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RefreshBouncesOrganizationsResultType = {
    organizations: {
        refreshBounces?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RefreshBouncesOrganizationsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class RefreshBouncesOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation refreshBouncesOrganizations ${args} {
    organizations {
        refreshBounces {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RefreshBouncesOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RefreshBouncesOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreOrganizationsResultType = {
    organizations: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreOrganizationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreOrganizations ${args} {
    organizations {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleOrganizationsResultType = {
    organizations: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleOrganizationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleOrganizations ${args} {
    organizations {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedOrganizationsResultType = {
    organizations: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedOrganizationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedOrganizations ${args} {
    organizations {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedOrganizationsResultType = {
    organizations: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedOrganizationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedOrganizations ${args} {
    organizations {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldOrganizationsResultType = {
    organizations: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldOrganizationsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldOrganizations ${args} {
    organizations {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldOrganizationsResultType = {
    organizations: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldOrganizationsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldOrganizations ${args} {
    organizations {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileLinkOrganizationsResultType = {
    organizations: {
        addFileLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileLinkOrganizationsBaseVariables = {
	id: string, /**  */
	fileId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileLinkOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileLinkOrganizations ${args} {
    organizations {
        addFileLink(id: $id, fileId: $fileId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileLinkOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileLinkOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileLinkOrganizationsResultType = {
    organizations: {
        removeFileLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileLinkOrganizationsBaseVariables = {
	id: string, /**  */
	fileId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileLinkOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileLinkOrganizations ${args} {
    organizations {
        removeFileLink(id: $id, fileId: $fileId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileLinkOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileLinkOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddExcludeWarningCustomersOrganizationsResultType = {
    organizations: {
        addExcludeWarningCustomers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddExcludeWarningCustomersOrganizationsBaseVariables = {
	id: string, /**  */
	warningCustomerIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddExcludeWarningCustomersOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $warningCustomerIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addExcludeWarningCustomersOrganizations ${args} {
    organizations {
        addExcludeWarningCustomers(id: $id, warningCustomerIds: $warningCustomerIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddExcludeWarningCustomersOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddExcludeWarningCustomersOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveExcludeWarningCustomersOrganizationsResultType = {
    organizations: {
        removeExcludeWarningCustomers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveExcludeWarningCustomersOrganizationsBaseVariables = {
	id: string, /**  */
	warningCustomerIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveExcludeWarningCustomersOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $warningCustomerIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeExcludeWarningCustomersOrganizations ${args} {
    organizations {
        removeExcludeWarningCustomers(id: $id, warningCustomerIds: $warningCustomerIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveExcludeWarningCustomersOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveExcludeWarningCustomersOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddIncludeWarningCustomersOrganizationsResultType = {
    organizations: {
        addIncludeWarningCustomers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddIncludeWarningCustomersOrganizationsBaseVariables = {
	id: string, /**  */
	warningCustomerIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddIncludeWarningCustomersOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $warningCustomerIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addIncludeWarningCustomersOrganizations ${args} {
    organizations {
        addIncludeWarningCustomers(id: $id, warningCustomerIds: $warningCustomerIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddIncludeWarningCustomersOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddIncludeWarningCustomersOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveIncludeWarningCustomersOrganizationsResultType = {
    organizations: {
        removeIncludeWarningCustomers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveIncludeWarningCustomersOrganizationsBaseVariables = {
	id: string, /**  */
	warningCustomerIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveIncludeWarningCustomersOrganizationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $warningCustomerIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeIncludeWarningCustomersOrganizations ${args} {
    organizations {
        removeIncludeWarningCustomers(id: $id, warningCustomerIds: $warningCustomerIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveIncludeWarningCustomersOrganizationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveIncludeWarningCustomersOrganizationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
