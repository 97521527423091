import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

/** Représente la licence courrante. */
@Injectable({
  providedIn: 'root',
})
/** Class implémentant le temp réèl façon pooling */
export class Pooling {
  constructor() {}

  /** Permet un pooling en fonction d'un id dans le custom store */
  poolingById(
    store: CustomStore,
    id,
    query,
    interval: number = 2000,
  ): number {
    return setInterval(_ => {
      let subscription: Subscription = query(id)
        .pipe(finalize(() => subscription.unsubscribe()))
        .subscribe(res => {
          if (
            res.data == undefined ||
            (res.data.length != undefined && res.data.length == 0)
          ) {
            store.push([
              {
                type: 'remove',
                key: id,
              },
            ]);
          } else {
            store.push([
              {
                type: 'update',
                key: id,
                data: Array.isArray(res.data) ? res.data[0] : res.data,
              },
            ]);
          }
        });
    }, interval);
  }
  /** Permet de créer un pooling qui callback a intervale régulier */
  poolingByCallback(callback: any, interval: number = 2000): number {
    return setInterval(_ => {
      callback();
    }, interval);
  }
}
