import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetEmailBouncesBaseVariables, FirstEmailBouncesBaseVariables, CountEmailBouncesBaseVariables, FindEmailBouncesBaseVariables, SearchEmailBouncesBaseVariables, ExportSchemaEmailBouncesBaseVariables, ExportDataEmailBouncesBaseVariables, CustomQueryEmailBouncesBaseVariables, CustomQueryIdEmailBouncesBaseVariables, UsedEmailBouncesBaseVariables, ExistEmailBouncesBaseVariables, InsertEmailBouncesBaseVariables, UpdateEmailBouncesBaseVariables, ImportDataEmailBouncesBaseVariables, UpdateManyEmailBouncesBaseVariables, DeleteEmailBouncesBaseVariables } from '../gqls'
import { GetEmailBouncesDocument, FirstEmailBouncesDocument, CountEmailBouncesDocument, FindEmailBouncesDocument, SearchEmailBouncesDocument, ExportSchemaEmailBouncesDocument, ExportDataEmailBouncesDocument, CustomQueryEmailBouncesDocument, CustomQueryIdEmailBouncesDocument, UsedEmailBouncesDocument, ExistEmailBouncesDocument, InsertEmailBouncesDocument, UpdateEmailBouncesDocument, ImportDataEmailBouncesDocument, UpdateManyEmailBouncesDocument, DeleteEmailBouncesDocument } from '../gqls'
import { ServiceSingleResultOfEmailBounce, QueryContextOfEmailBounce, FilterOfEmailBounce, ServiceSingleResultOfInt64, ServiceListResultOfEmailBounce, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, EmailBounceInput, FieldUpdateOperatorOfEmailBounce } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class EmailBounceBaseService {
    
public modelName = 'emailBounce';
public modelPluralName = 'emailBounces';

	private getEmailBouncesQuery: GetEmailBouncesDocument;
	private firstEmailBouncesQuery: FirstEmailBouncesDocument;
	private countEmailBouncesQuery: CountEmailBouncesDocument;
	private findEmailBouncesQuery: FindEmailBouncesDocument;
	private searchEmailBouncesQuery: SearchEmailBouncesDocument;
	private exportSchemaEmailBouncesQuery: ExportSchemaEmailBouncesDocument;
	private exportDataEmailBouncesQuery: ExportDataEmailBouncesDocument;
	private customQueryEmailBouncesQuery: CustomQueryEmailBouncesDocument;
	private customQueryIdEmailBouncesQuery: CustomQueryIdEmailBouncesDocument;
	private usedEmailBouncesQuery: UsedEmailBouncesDocument;
	private existEmailBouncesQuery: ExistEmailBouncesDocument;
	private insertEmailBouncesMutation: InsertEmailBouncesDocument;
	private updateEmailBouncesMutation: UpdateEmailBouncesDocument;
	private importDataEmailBouncesMutation: ImportDataEmailBouncesDocument;
	private updateManyEmailBouncesMutation: UpdateManyEmailBouncesDocument;
	private deleteEmailBouncesMutation: DeleteEmailBouncesDocument;

	constructor(private injector: Injector) {
		this.getEmailBouncesQuery = this.injector.get(GetEmailBouncesDocument);
		this.firstEmailBouncesQuery = this.injector.get(FirstEmailBouncesDocument);
		this.countEmailBouncesQuery = this.injector.get(CountEmailBouncesDocument);
		this.findEmailBouncesQuery = this.injector.get(FindEmailBouncesDocument);
		this.searchEmailBouncesQuery = this.injector.get(SearchEmailBouncesDocument);
		this.exportSchemaEmailBouncesQuery = this.injector.get(ExportSchemaEmailBouncesDocument);
		this.exportDataEmailBouncesQuery = this.injector.get(ExportDataEmailBouncesDocument);
		this.customQueryEmailBouncesQuery = this.injector.get(CustomQueryEmailBouncesDocument);
		this.customQueryIdEmailBouncesQuery = this.injector.get(CustomQueryIdEmailBouncesDocument);
		this.usedEmailBouncesQuery = this.injector.get(UsedEmailBouncesDocument);
		this.existEmailBouncesQuery = this.injector.get(ExistEmailBouncesDocument);
		this.insertEmailBouncesMutation = this.injector.get(InsertEmailBouncesDocument);
		this.updateEmailBouncesMutation = this.injector.get(UpdateEmailBouncesDocument);
		this.importDataEmailBouncesMutation = this.injector.get(ImportDataEmailBouncesDocument);
		this.updateManyEmailBouncesMutation = this.injector.get(UpdateManyEmailBouncesDocument);
		this.deleteEmailBouncesMutation = this.injector.get(DeleteEmailBouncesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailBounce> {
    
    		let variables: GetEmailBouncesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.get));
            }
            else{
                let result:ServiceSingleResultOfEmailBounce={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailBounce,
		@Args('filter?') filter?: FilterOfEmailBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailBounce> {
    
    		let variables: FirstEmailBouncesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountEmailBouncesBaseVariables = {
    			filter: filter
    		}
    				return this.countEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailBounce,
		@Args('filter?') filter?: FilterOfEmailBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailBounce> {
    
    		let variables: FindEmailBouncesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfEmailBounce,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailBounce> {
    
    		let variables: SearchEmailBouncesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaEmailBouncesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataEmailBouncesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfEmailBounce,
		@Args('filter?') filter?: FilterOfEmailBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailBounce> {
    
    		let variables: CustomQueryEmailBouncesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfEmailBounce,
		@Args('filter?') filter?: FilterOfEmailBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailBounce> {
    
    		let variables: CustomQueryIdEmailBouncesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedEmailBouncesBaseVariables = {
    			ids: ids
    		}
    				return this.usedEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistEmailBouncesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existEmailBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: EmailBounceInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailBounce> {
    
    		let variables: InsertEmailBouncesBaseVariables = {
    			entity: entity
    		}
    				return this.insertEmailBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailBounce> {
    
    		let variables: UpdateEmailBouncesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateEmailBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataEmailBouncesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataEmailBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyEmailBouncesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyEmailBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteEmailBouncesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteEmailBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailBounces.delete));
    	}
    
}