import { InStockMovementBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { InjectArgs } from '@clarilog/core/modules/decorators/args-decorator';
import { TranslateService } from '@clarilog/shared';

@Injectable({ providedIn: 'root' })
@Authorize('in-stock')
export class InStockMovementCoreService extends InStockMovementBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('name'),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  /**Liste mention pour les destinataires */
  public findMentionEmail(): { key: string; name: string }[] {
    let mentions: { key: string; name: string }[] = [];
    return mentions;
  }
  /** Liste mention pour le sujet */
  public findMentionSubject(): { key: string; name: string }[] {
    let mentions: { key: string; name: string }[] = this.commonMentionInStock();

    mentions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return mentions;
  }

  /**Liste mention pour le corps du mail */
  public findMentionBody(): any {
    let mentions: { key: string; name: string }[] = this.commonMentionInStock();

    mentions.push({
      key: 'inStockCartProperties',
      name: TranslateService.get(`entities/stock/deliveryOfAssetModel`),
    });

    mentions.push({
      key: 'inStockAssetCreatedProperties',
      name: TranslateService.get(`entities/stock/assetCreated`),
    });

    mentions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return mentions;
  }

  private commonMentionInStock(): { key: string; name: string }[] {
    return [
      {
        key: 'name',
        name: TranslateService.get(`entities/stock/name`),
      },
      {
        key: '[inStockMovementProperty].deliveryDate',
        name: TranslateService.get(`entities/stock/deliveryDate`),
      },
      {
        key: '[inStockMovementProperty].receivedById',
        name: TranslateService.get(`entities/stock/receivedBy`),
      },
      {
        key: '[inStockMovementProperty].deliveryOrder',
        name: TranslateService.get(`entities/stock/deliveryOrder`),
      },
      {
        key: 'deliveryNumber',
        name: TranslateService.get(`entities/stock/deliveryNumber`),
      },
      {
        key: '[inStockMovementProperty].defaultStorageUnitId',
        name: TranslateService.get(`entities/stock/defaultStorageUnit`),
      },
      {
        key: '[inStockMovementProperty].isDeliveryComplete',
        name: TranslateService.get(`entities/stock/inStock/completeDelivery`),
      },
      {
        key: '[inStockMovementPropertyFinancial].budgetId',
        name: TranslateService.get(`entities/stock/defaultBudget`),
      },
      {
        key: '[inStockMovementPropertyFinancial].modeId',
        name: TranslateService.get(`entities/assetAcquisition/acquisitionMode`),
      },
      {
        key: '[inStockMovementPropertyFinancial].typeOfStaffingId',
        name: TranslateService.get(`entities/assetAcquisition/typeOfStaffing`),
      },
      {
        key: '[inStockMovementPropertyFinancial].acquisitionStateId',
        name: TranslateService.get(
          `entities/assetAcquisition/acquisitionState`,
        ),
      },
      {
        key: '[inStockMovementPropertyFinancial].lotNumber',
        name: TranslateService.get(`entities/stock/lotNumber`),
      },
      {
        key: '[inStockMovementPropertyFinancial].lotDate',
        name: TranslateService.get(`entities/stock/lotDate`),
      },
      {
        key: '[inStockMovementPropertyOrder].orderDate',
        name: TranslateService.get(`entities/stock/orderDate`),
      },
      {
        key: '[inStockMovementPropertyOrder].supplierId',
        name: TranslateService.get(`entities/stock/supplierId`),
      },
      {
        key: '[inStockMovementPropertyOrder].supplierReferenceNumber',
        name: TranslateService.get(`entities/stock/supplierReference`),
      },
      {
        key: '[inStockMovementPropertyOrder].internalReferenceNumber',
        name: TranslateService.get(`entities/stock/internalReference`),
      },
      {
        key: '[inStockMovementPropertyOrder].reason',
        name: TranslateService.get(`entities/stock/reason`),
      },
      {
        key: '[inStockMovementPropertyBill].bill.billDate',
        name: TranslateService.get(`entities/stock/billDate`),
      },
      {
        key: '[inStockMovementPropertyBill].billNumber',
        name: TranslateService.get(`entities/stock/billNumber`),
      },
      {
        key: '[inStockMovementPropertyBill].total',
        name: TranslateService.get(`entities/stock/totalBill`),
      },
      {
        key: 'description',
        name: TranslateService.get(`entities/stock/description`),
      },
      {
        key: '[inStockMovementProperty].realTotal',
        name: TranslateService.get(`entities/stock/realTotal`),
      },
      {
        key: '[inStockMovementProperty].costTotal',
        name: TranslateService.get(`entities/stock/costTotal`),
      },
    ];
  }
}
