import { Injectable } from '@angular/core';
import { LocalizeService } from '@clarilog/core/modules/globalize/services/localize.service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  Sort,
  TranslatedField,
} from '@clarilog/core/services2/graphql/generated-types/types';

@Injectable({ providedIn: 'root' })
export class TranslatedFieldHelperService {
  constructor(private localeService: LocalizeService) {}

  public static ModelStateLanguageKey = 'translated-key-language';

  findValueToShow(value: TranslatedField): string {
    let lang = this.getTranslateKey();

    if (value == undefined || (typeof value == 'string' && value === '')) {
      return '';
    }

    if (typeof value == 'string') {
      return value;
    } else {
      if (value != undefined && value[lang] == undefined) {
        console.error(lang);
        console.error(value);
        console.error('Language not implemented');
      }
      return value[lang];
    }
  }

  getDefaultvalue(value: string = ''): TranslatedField {
    let def = {};

    for (let el of this.getLanguageDatasource()) {
      def[el.key] = value;
    }
    return def as TranslatedField;
  }

  public static getStaticLanguageDatasource(): Array<{
    value: string;
    key: string;
  }> {
    return [
      {
        key: 'fr',
        value: 'Français',
      },
      {
        key: 'en',
        value: 'English',
      },
      {
        key: 'it',
        value: 'Italiano',
      },
      {
        key: 'de',
        value: 'Deutsch',
      },
      {
        key: 'nl',
        value: 'Nederlands',
      },
    ];
  }

  getLanguageDatasource(): Array<{ value: string; key: string }> {
    return TranslatedFieldHelperService.getStaticLanguageDatasource();
  }

  /** Obtient l'objet de trie avec la langue */
  public getSortDefault(sortBy: Sort) {
    let sort = {};
    sort[this.getTranslateKey()] = sortBy;
    return sort;
  }

  public getLanguageValue(): string {
    let language = this.localeService.getLocal()?.value;
    if (language != undefined) {
      let lang = language.split('-')[0];

      return this.getLanguageDatasource().find((el) => el.key === lang)?.value;
    } else {
      return 'fr';
    }
  }

  public getLanguageValueByKey(key): string {
    return this.getLanguageDatasource().find((el) => el.key === key)?.value;
  }

  public static getStaticTranslatedFields(): Array<GqlField | GqlSubField> {
    return TranslatedFieldHelperService.getStaticLanguageDatasource().map(
      (el) => GqlField.create(el.key),
    );
  }

  public translatedFields(): Array<GqlField | GqlSubField> {
    return TranslatedFieldHelperService.getStaticTranslatedFields();
  }

  getAllSupportedLanguage(): string[] {
    return this.getLanguageDatasource().map((el) => el.key);
  }

  getTranslateKey() {
    let language = this.localeService.getLocal()?.value;
    if (language != undefined) {
      return language.split('-')[0];
    } else {
      return 'fr';
    }
  }

  setColumnTranslateField(field: string): string {
    let lang = '.' + this.getTranslateKey();
    if (!field.endsWith(lang)) {
      return (field += lang);
    }
    return field;
  }

  setValueToTranslated(value, translatedField: TranslatedField) {
    let lang = this.getTranslateKey();

    if (translatedField[lang] == undefined) {
      console.error('language not implemented');
    }
    translatedField[lang] = value;
  }

  setNewValue(value) {
    let translate: TranslatedField = {};
    TranslatedFieldHelperService.getStaticLanguageDatasource().forEach(
      (element) => {
        translate[element.key] = value;
      },
    );

    return translate;
  }
}
