import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPackagesBaseVariables, FirstPackagesBaseVariables, CountPackagesBaseVariables, FindPackagesBaseVariables, SearchPackagesBaseVariables, ExportSchemaPackagesBaseVariables, ExportDataPackagesBaseVariables, CustomQueryPackagesBaseVariables, CustomQueryIdPackagesBaseVariables, UsedPackagesBaseVariables, ExistPackagesBaseVariables, FindUnassociatedCampaignsPackagesBaseVariables, InsertPackagesBaseVariables, UpdatePackagesBaseVariables, ImportDataPackagesBaseVariables, UpdateManyPackagesBaseVariables, DeletePackagesBaseVariables, ImportPackagesBaseVariables, AddCampaignsPackagesBaseVariables, RemoveCampaignsPackagesBaseVariables } from '../gqls'
import { GetPackagesDocument, FirstPackagesDocument, CountPackagesDocument, FindPackagesDocument, SearchPackagesDocument, ExportSchemaPackagesDocument, ExportDataPackagesDocument, CustomQueryPackagesDocument, CustomQueryIdPackagesDocument, UsedPackagesDocument, ExistPackagesDocument, FindUnassociatedCampaignsPackagesDocument, InsertPackagesDocument, UpdatePackagesDocument, ImportDataPackagesDocument, UpdateManyPackagesDocument, DeletePackagesDocument, ImportPackagesDocument, AddCampaignsPackagesDocument, RemoveCampaignsPackagesDocument } from '../gqls'
import { ServiceSingleResultOfPackage, QueryContextOfPackage, FilterOfPackage, ServiceSingleResultOfInt64, ServiceListResultOfPackage, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfCampaign, FilterOfCampaign, ServiceListResultOfCampaign, PackageInput, FieldUpdateOperatorOfPackage } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PackageBaseService {
    
public modelName = 'package';
public modelPluralName = 'packages';

	private getPackagesQuery: GetPackagesDocument;
	private firstPackagesQuery: FirstPackagesDocument;
	private countPackagesQuery: CountPackagesDocument;
	private findPackagesQuery: FindPackagesDocument;
	private searchPackagesQuery: SearchPackagesDocument;
	private exportSchemaPackagesQuery: ExportSchemaPackagesDocument;
	private exportDataPackagesQuery: ExportDataPackagesDocument;
	private customQueryPackagesQuery: CustomQueryPackagesDocument;
	private customQueryIdPackagesQuery: CustomQueryIdPackagesDocument;
	private usedPackagesQuery: UsedPackagesDocument;
	private existPackagesQuery: ExistPackagesDocument;
	private findUnassociatedCampaignsPackagesQuery: FindUnassociatedCampaignsPackagesDocument;
	private insertPackagesMutation: InsertPackagesDocument;
	private updatePackagesMutation: UpdatePackagesDocument;
	private importDataPackagesMutation: ImportDataPackagesDocument;
	private updateManyPackagesMutation: UpdateManyPackagesDocument;
	private deletePackagesMutation: DeletePackagesDocument;
	private importPackagesMutation: ImportPackagesDocument;
	private addCampaignsPackagesMutation: AddCampaignsPackagesDocument;
	private removeCampaignsPackagesMutation: RemoveCampaignsPackagesDocument;

	constructor(private injector: Injector) {
		this.getPackagesQuery = this.injector.get(GetPackagesDocument);
		this.firstPackagesQuery = this.injector.get(FirstPackagesDocument);
		this.countPackagesQuery = this.injector.get(CountPackagesDocument);
		this.findPackagesQuery = this.injector.get(FindPackagesDocument);
		this.searchPackagesQuery = this.injector.get(SearchPackagesDocument);
		this.exportSchemaPackagesQuery = this.injector.get(ExportSchemaPackagesDocument);
		this.exportDataPackagesQuery = this.injector.get(ExportDataPackagesDocument);
		this.customQueryPackagesQuery = this.injector.get(CustomQueryPackagesDocument);
		this.customQueryIdPackagesQuery = this.injector.get(CustomQueryIdPackagesDocument);
		this.usedPackagesQuery = this.injector.get(UsedPackagesDocument);
		this.existPackagesQuery = this.injector.get(ExistPackagesDocument);
		this.findUnassociatedCampaignsPackagesQuery = this.injector.get(FindUnassociatedCampaignsPackagesDocument);
		this.insertPackagesMutation = this.injector.get(InsertPackagesDocument);
		this.updatePackagesMutation = this.injector.get(UpdatePackagesDocument);
		this.importDataPackagesMutation = this.injector.get(ImportDataPackagesDocument);
		this.updateManyPackagesMutation = this.injector.get(UpdateManyPackagesDocument);
		this.deletePackagesMutation = this.injector.get(DeletePackagesDocument);
		this.importPackagesMutation = this.injector.get(ImportPackagesDocument);
		this.addCampaignsPackagesMutation = this.injector.get(AddCampaignsPackagesDocument);
		this.removeCampaignsPackagesMutation = this.injector.get(RemoveCampaignsPackagesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPackage> {
    
    		let variables: GetPackagesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.get));
            }
            else{
                let result:ServiceSingleResultOfPackage={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPackage,
		@Args('filter?') filter?: FilterOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPackage> {
    
    		let variables: FirstPackagesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPackagesBaseVariables = {
    			filter: filter
    		}
    				return this.countPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPackage,
		@Args('filter?') filter?: FilterOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPackage> {
    
    		let variables: FindPackagesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPackage,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPackage> {
    
    		let variables: SearchPackagesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPackagesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPackagesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPackage,
		@Args('filter?') filter?: FilterOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPackage> {
    
    		let variables: CustomQueryPackagesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPackage,
		@Args('filter?') filter?: FilterOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPackage> {
    
    		let variables: CustomQueryIdPackagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPackagesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPackagesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.exist));
    	}

	@InjectArgs
	public findAssociatedCampaigns(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfCampaign,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfCampaign,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfCampaign> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('campaigns', fields, null, [
			GqlSubFieldArg.create('filterOfCampaigns', 'filter'),
			GqlSubFieldArg.create('optionsOfCampaigns', 'options'),
		]),
		])
		extendedVariables['filterOfCampaigns'] = filter;
		extendedVariables['optionsOfCampaigns'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.campaigns));
            }
            else{
                let result: ServiceListResultOfCampaign = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedCampaigns(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCampaign,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaign> {
    
    		let variables: FindUnassociatedCampaignsPackagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedCampaignsPackagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.packages.findUnassociatedCampaigns));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: PackageInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPackage> {
    
    		let variables: InsertPackagesBaseVariables = {
    			entity: entity
    		}
    				return this.insertPackagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.packages.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPackage> {
    
    		let variables: UpdatePackagesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updatePackagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.packages.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataPackagesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataPackagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.packages.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyPackagesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyPackagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.packages.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeletePackagesBaseVariables = {
    			ids: ids
    		}
    				return this.deletePackagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.packages.delete));
    	}

    	/**  */
    	@InjectArgs
    	public import(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('packages?') packages?: Array<Maybe<PackageInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportPackagesBaseVariables = {
    			packages: packages
    		}
    				return this.importPackagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.packages.import));
    	}

    	/**  */
    	@InjectArgs
    	public addCampaigns(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('campaignIds') campaignIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddCampaignsPackagesBaseVariables = {
    			id: id,
			campaignIds: campaignIds
    		}
    				return this.addCampaignsPackagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.packages.addCampaigns));
    	}

    	/**  */
    	@InjectArgs
    	public removeCampaigns(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('campaignIds') campaignIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveCampaignsPackagesBaseVariables = {
    			id: id,
			campaignIds: campaignIds
    		}
    				return this.removeCampaignsPackagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.packages.removeCampaigns));
    	}
    
}