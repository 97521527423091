import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { WorkflowActionBaseService } from '../service-bases';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

@Injectable({ providedIn: 'root' })
export class WorkflowActionCoreService extends WorkflowActionBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getService(): WorkflowActionCoreService {
    return this;
  }

  @InjectArgs
  public addFields(@Args('type') type: string): string[] {
    switch (type) {
      case 'Message':
      case 'TicketEmail':
        return [
          'ticketId',
          'ticket.data.description',
          'ticket.data.resolutionComment',
          'ticket.data.privateResolutionComment',
          'ticket.data.statusId',
          'ticket.data.ticketCategoryId',
          'ticket.data.operatorAffectedId',
          'ticket.data.operatorReferentId',
          'ticket.data.operatorTeamAffectedId',
          'ticket.data.operatorTeamReferentId',
          'ticket.data.impactId',
          'ticket.data.priorityId',
          'ticket.data.urgencyId',
          'ticket.data.concernedProjectId',
          'ticket.data.resolutionCategoryId',
          'ticket.data.statusReasonId',
          'ticket.data.levelSupport',
          'ticket.data.tags',
          'ticket.data.satisfaction',
        ];
      default:
        return [];
    }
  }

  @InjectArgs
  public selectFields(@Args('type') type: string): string[] {
    switch (type) {
      case 'Asset':
        return [
          'assetStatusId',
          'ownerId',
          'locationId',
          'organizationalUnitId',
        ];
      default:
        return [];
    }
  }

  @InjectArgs
  public clearFieldToExclude(@Args('type') type: string): string[] {
    switch (type) {
      case 'Request':
        return ['statusId'];
      case 'Incident':
        return ['statusId'];
      case 'TicketTask':
        return ['statusId'];
      case 'TicketEmail':
        return ['ticket.statusId'];
      case 'Asset':
        return ['assetStatusId'];
      default:
        return [];
    }
  }

  @InjectArgs
  public recipientNotficationTeams(): any[] {
    return [
      {
        id: 'ticket.operatorReferentId',
        value:
          TranslateService.get('entities/ticket/_title/singular') +
          ' / ' +
          TranslateService.get('entities/ticket/operatorReferent'),
      },
      {
        id: 'ticket.operatorAffectedId',
        value:
          TranslateService.get('entities/ticket/_title/singular') +
          ' / ' +
          TranslateService.get('entities/ticket/operatorAffected'),
      },
      {
        id: 'ticket.userAffectedId',
        value:
          TranslateService.get('entities/ticket/_title/singular') +
          ' / ' +
          TranslateService.get('entities/ticket/userAffected'),
      },
      {
        id: 'ticket.userMakeRequestId',
        value:
          TranslateService.get('entities/ticket/_title/singular') +
          ' / ' +
          TranslateService.get('entities/ticket/userMakeRequest'),
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }
}
