<dx-popup
  [height]="heightPopup"
  [width]="widthPopup"
  [(visible)]="isPopupOpen"
  [showTitle]="false"
  [ngClass]="'cl-popup'"
>
  <div class="close-btn">
    <dx-button icon="fal fa-times" (onClick)="isPopupOpen = false"></dx-button>
  </div>
  @if (!_localStorageService.isMobile) {
  <clc-work-form
    *ngIf="modelState != undefined"
    [showBack]="false"
    [showSave]="showSave"
    [disableSaveNotification]="disableSaveNotification"
    [forceSaveBtn]="forceSaveBtn"
    [model]="modelState"
    [isExternalForm]="true"
    [displaySaveIcon]="displaySaveIcon"
    [service]="service"
    (onSaved)="saved($event)"
    [beforeSave]="beforeSave"
    [afterSave]="afterSave"
    [saveTilte]="saveTitle"
    [saveSplitButton]="saveSplitButton"
    [(title)]="title"
    [(readOnly)]="readOnly"
  ></clc-work-form>
  } @if (_localStorageService.isMobile) {
  <clc-work-form
    *ngIf="modelState != undefined"
    [showBack]="true"
    [model]="modelState"
    [isExternalForm]="true"
    [showSave]="showSave"
    [displaySaveIcon]="displaySaveIcon"
    [service]="service"
    (onSaved)="saved($event)"
    [beforeSave]="beforeSave"
    [afterSave]="afterSave"
    [saveTilte]="saveTitle"
    [(title)]="title"
    [displaySaveIcon]="false"
    [onlySaveButton]="true"
    [saveSplitButton]="false"
    [(readOnly)]="readOnly"
  ></clc-work-form>
  }
</dx-popup>
