<div
  class="cl-button-container"
  [ngClass]="{ 'cl-button-container-designer': designerMode }"
>
  <!--  <dx-button-->
  <!--    *ngIf="designerMode === true"-->
  <!--    icon="fal fa-plus"-->
  <!--    (onClick)="designerClick($event)"-->
  <!--    class="cl-button cl-button-pin"-->
  <!--    text="Nav Item"-->
  <!--  >-->
  <!--  </dx-button>-->

  <div class="cl-button-designer">
    <dx-button
      [text]="'entities/formDesigner/addPage' | translate"
      type="default"
      icon="fal fa-plus"
      *ngIf="designerMode === true"
      tabindex="-1"
      [useSubmitBehavior]="true"
      (onClick)="designerClick($event)"
    >
    </dx-button>
  </div>
  <!-- <button
    class="cl-button cl-button-pin"
    *ngIf="designerMode === true"
    tabindex="-1"
    (click)="designerClick($event)"
  >
    <i
      class="fal fa-plus"
      style="font-size: 0.8em"
    ></i> <span>{{'entities/formDesigner/addPage' | translate}}</span>
  </button> -->
  <button
    class="cl-button cl-button-pin"
    *ngIf="showPin && designerMode !== true"
    tabindex="-1"
    [ngStyle]="{ visibility: toggle ? 'hidden' : 'visible' }"
    (click)="onPinClick()"
  >
    <i
      class="fal fa-thumbtack"
      style="font-size: 0.8em"
      [ngClass]="{
        'un-pin': !pin,
        pin: pin,
      }"
    ></i>
  </button>
  <button
    class="cl-button cl-button-reduce"
    *ngIf="showToggle && designerMode !== true"
    (click)="toggleClick()"
    tabindex="-1"
  >
    <i
      class="fal"
      [ngClass]="{
        'fa-angle-double-left': !toggle,
        'fa-angle-double-right': toggle,
      }"
    ></i>
  </button>
</div>
<div
  class="clc-nav-menu-container"
  [ngClass]="{ 'cl-hide': mode === 'normal' && toggle }"
>
  <div class="clc-nav-menu-panel">
    <ng-content select="clc-nav-group:not([locate=footer])"></ng-content>
  </div>
</div>
<div class="clc-nav-menu-container-footer">
  <mat-divider *ngIf="hasFooter" style="margin: 0 10px"></mat-divider>
  <div class="clc-nav-menu-footer">
    <ng-content select="clc-nav-group[locate=footer]"></ng-content>
  </div>
</div>
