import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetFieldMappingsBaseVariables, FirstFieldMappingsBaseVariables, CountFieldMappingsBaseVariables, FindFieldMappingsBaseVariables, SearchFieldMappingsBaseVariables, ExportSchemaFieldMappingsBaseVariables, ExportDataFieldMappingsBaseVariables, CustomQueryFieldMappingsBaseVariables, CustomQueryIdFieldMappingsBaseVariables, UsedFieldMappingsBaseVariables, DefaultFieldMappingsBaseVariables, ExistFieldMappingsBaseVariables, FindUnassociatedScanByAzureAdsFieldMappingsBaseVariables, FindUnassociatedScanByLdapsFieldMappingsBaseVariables, InsertFieldMappingsBaseVariables, UpdateFieldMappingsBaseVariables, ImportDataFieldMappingsBaseVariables, UpdateManyFieldMappingsBaseVariables, DeleteFieldMappingsBaseVariables, AddScanByAzureAdsFieldMappingsBaseVariables, RemoveScanByAzureAdsFieldMappingsBaseVariables, AddScanByLdapsFieldMappingsBaseVariables, RemoveScanByLdapsFieldMappingsBaseVariables } from '../gqls'
import { GetFieldMappingsDocument, FirstFieldMappingsDocument, CountFieldMappingsDocument, FindFieldMappingsDocument, SearchFieldMappingsDocument, ExportSchemaFieldMappingsDocument, ExportDataFieldMappingsDocument, CustomQueryFieldMappingsDocument, CustomQueryIdFieldMappingsDocument, UsedFieldMappingsDocument, DefaultFieldMappingsDocument, ExistFieldMappingsDocument, FindUnassociatedScanByAzureAdsFieldMappingsDocument, FindUnassociatedScanByLdapsFieldMappingsDocument, InsertFieldMappingsDocument, UpdateFieldMappingsDocument, ImportDataFieldMappingsDocument, UpdateManyFieldMappingsDocument, DeleteFieldMappingsDocument, AddScanByAzureAdsFieldMappingsDocument, RemoveScanByAzureAdsFieldMappingsDocument, AddScanByLdapsFieldMappingsDocument, RemoveScanByLdapsFieldMappingsDocument } from '../gqls'
import { ServiceSingleResultOfFieldMapping, QueryContextOfFieldMapping, FilterOfFieldMapping, ServiceSingleResultOfInt64, ServiceListResultOfFieldMapping, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfScanByAzureAd, FilterOfScanByAzureAd, ServiceListResultOfScanByAzureAd, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceListResultOfScanByLdap, FieldMappingInput, FieldUpdateOperatorOfFieldMapping } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class FieldMappingBaseService {
    
public modelName = 'fieldMapping';
public modelPluralName = 'fieldMappings';

	private getFieldMappingsQuery: GetFieldMappingsDocument;
	private firstFieldMappingsQuery: FirstFieldMappingsDocument;
	private countFieldMappingsQuery: CountFieldMappingsDocument;
	private findFieldMappingsQuery: FindFieldMappingsDocument;
	private searchFieldMappingsQuery: SearchFieldMappingsDocument;
	private exportSchemaFieldMappingsQuery: ExportSchemaFieldMappingsDocument;
	private exportDataFieldMappingsQuery: ExportDataFieldMappingsDocument;
	private customQueryFieldMappingsQuery: CustomQueryFieldMappingsDocument;
	private customQueryIdFieldMappingsQuery: CustomQueryIdFieldMappingsDocument;
	private usedFieldMappingsQuery: UsedFieldMappingsDocument;
	private defaultFieldMappingsQuery: DefaultFieldMappingsDocument;
	private existFieldMappingsQuery: ExistFieldMappingsDocument;
	private findUnassociatedScanByAzureAdsFieldMappingsQuery: FindUnassociatedScanByAzureAdsFieldMappingsDocument;
	private findUnassociatedScanByLdapsFieldMappingsQuery: FindUnassociatedScanByLdapsFieldMappingsDocument;
	private insertFieldMappingsMutation: InsertFieldMappingsDocument;
	private updateFieldMappingsMutation: UpdateFieldMappingsDocument;
	private importDataFieldMappingsMutation: ImportDataFieldMappingsDocument;
	private updateManyFieldMappingsMutation: UpdateManyFieldMappingsDocument;
	private deleteFieldMappingsMutation: DeleteFieldMappingsDocument;
	private addScanByAzureAdsFieldMappingsMutation: AddScanByAzureAdsFieldMappingsDocument;
	private removeScanByAzureAdsFieldMappingsMutation: RemoveScanByAzureAdsFieldMappingsDocument;
	private addScanByLdapsFieldMappingsMutation: AddScanByLdapsFieldMappingsDocument;
	private removeScanByLdapsFieldMappingsMutation: RemoveScanByLdapsFieldMappingsDocument;

	constructor(private injector: Injector) {
		this.getFieldMappingsQuery = this.injector.get(GetFieldMappingsDocument);
		this.firstFieldMappingsQuery = this.injector.get(FirstFieldMappingsDocument);
		this.countFieldMappingsQuery = this.injector.get(CountFieldMappingsDocument);
		this.findFieldMappingsQuery = this.injector.get(FindFieldMappingsDocument);
		this.searchFieldMappingsQuery = this.injector.get(SearchFieldMappingsDocument);
		this.exportSchemaFieldMappingsQuery = this.injector.get(ExportSchemaFieldMappingsDocument);
		this.exportDataFieldMappingsQuery = this.injector.get(ExportDataFieldMappingsDocument);
		this.customQueryFieldMappingsQuery = this.injector.get(CustomQueryFieldMappingsDocument);
		this.customQueryIdFieldMappingsQuery = this.injector.get(CustomQueryIdFieldMappingsDocument);
		this.usedFieldMappingsQuery = this.injector.get(UsedFieldMappingsDocument);
		this.defaultFieldMappingsQuery = this.injector.get(DefaultFieldMappingsDocument);
		this.existFieldMappingsQuery = this.injector.get(ExistFieldMappingsDocument);
		this.findUnassociatedScanByAzureAdsFieldMappingsQuery = this.injector.get(FindUnassociatedScanByAzureAdsFieldMappingsDocument);
		this.findUnassociatedScanByLdapsFieldMappingsQuery = this.injector.get(FindUnassociatedScanByLdapsFieldMappingsDocument);
		this.insertFieldMappingsMutation = this.injector.get(InsertFieldMappingsDocument);
		this.updateFieldMappingsMutation = this.injector.get(UpdateFieldMappingsDocument);
		this.importDataFieldMappingsMutation = this.injector.get(ImportDataFieldMappingsDocument);
		this.updateManyFieldMappingsMutation = this.injector.get(UpdateManyFieldMappingsDocument);
		this.deleteFieldMappingsMutation = this.injector.get(DeleteFieldMappingsDocument);
		this.addScanByAzureAdsFieldMappingsMutation = this.injector.get(AddScanByAzureAdsFieldMappingsDocument);
		this.removeScanByAzureAdsFieldMappingsMutation = this.injector.get(RemoveScanByAzureAdsFieldMappingsDocument);
		this.addScanByLdapsFieldMappingsMutation = this.injector.get(AddScanByLdapsFieldMappingsDocument);
		this.removeScanByLdapsFieldMappingsMutation = this.injector.get(RemoveScanByLdapsFieldMappingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFieldMapping> {
    
    		let variables: GetFieldMappingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.get));
            }
            else{
                let result:ServiceSingleResultOfFieldMapping={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFieldMapping,
		@Args('filter?') filter?: FilterOfFieldMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFieldMapping> {
    
    		let variables: FirstFieldMappingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFieldMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountFieldMappingsBaseVariables = {
    			filter: filter
    		}
    				return this.countFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFieldMapping,
		@Args('filter?') filter?: FilterOfFieldMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFieldMapping> {
    
    		let variables: FindFieldMappingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfFieldMapping,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFieldMapping> {
    
    		let variables: SearchFieldMappingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaFieldMappingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFieldMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataFieldMappingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfFieldMapping,
		@Args('filter?') filter?: FilterOfFieldMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFieldMapping> {
    
    		let variables: CustomQueryFieldMappingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfFieldMapping,
		@Args('filter?') filter?: FilterOfFieldMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFieldMapping> {
    
    		let variables: CustomQueryIdFieldMappingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedFieldMappingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.used));
    	}

    	/**  */
    	@InjectArgs
    	public default(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFieldMapping> {
    
    		let variables: DefaultFieldMappingsBaseVariables = {
    			key: key
    		}
    				return this.defaultFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.default));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistFieldMappingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.exist));
    	}

	@InjectArgs
	public findAssociatedScanByAzureAds(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByAzureAd,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByAzureAd,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByAzureAd> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByAzureAds', fields, null, [
			GqlSubFieldArg.create('filterOfScanByAzureAds', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByAzureAds', 'options'),
		]),
		])
		extendedVariables['filterOfScanByAzureAds'] = filter;
		extendedVariables['optionsOfScanByAzureAds'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByAzureAds));
            }
            else{
                let result: ServiceListResultOfScanByAzureAd = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByAzureAds(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAzureAd,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAd> {
    
    		let variables: FindUnassociatedScanByAzureAdsFieldMappingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByAzureAdsFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.findUnassociatedScanByAzureAds));
    	}

	@InjectArgs
	public findAssociatedScanByLdaps(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByLdap> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByLdaps', fields, null, [
			GqlSubFieldArg.create('filterOfScanByLdaps', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByLdaps', 'options'),
		]),
		])
		extendedVariables['filterOfScanByLdaps'] = filter;
		extendedVariables['optionsOfScanByLdaps'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByLdaps));
            }
            else{
                let result: ServiceListResultOfScanByLdap = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindUnassociatedScanByLdapsFieldMappingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByLdapsFieldMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.findUnassociatedScanByLdaps));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: FieldMappingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFieldMapping> {
    
    		let variables: InsertFieldMappingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfFieldMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFieldMapping> {
    
    		let variables: UpdateFieldMappingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataFieldMappingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfFieldMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyFieldMappingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteFieldMappingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addScanByAzureAds(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanByAzureAdIds') scanByAzureAdIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanByAzureAdsFieldMappingsBaseVariables = {
    			id: id,
			scanByAzureAdIds: scanByAzureAdIds
    		}
    				return this.addScanByAzureAdsFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.addScanByAzureAds));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanByAzureAds(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanByAzureAdIds') scanByAzureAdIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanByAzureAdsFieldMappingsBaseVariables = {
    			scanByAzureAdIds: scanByAzureAdIds
    		}
    				return this.removeScanByAzureAdsFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.removeScanByAzureAds));
    	}

    	/**  */
    	@InjectArgs
    	public addScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanByLdapIds') scanByLdapIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanByLdapsFieldMappingsBaseVariables = {
    			id: id,
			scanByLdapIds: scanByLdapIds
    		}
    				return this.addScanByLdapsFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.addScanByLdaps));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanByLdapIds') scanByLdapIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanByLdapsFieldMappingsBaseVariables = {
    			scanByLdapIds: scanByLdapIds
    		}
    				return this.removeScanByLdapsFieldMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fieldMappings.removeScanByLdaps));
    	}
    
}