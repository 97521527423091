import { GqlField, GqlSubField } from '../../helpers';
import { Layout } from '../../types';
import { IncidentCoreService } from '../incident.service';
import { RequestCoreService } from '../request.service';
import { UserCoreService } from '../user.service';
import { BaseStorageService } from './base-storage-service';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { ProblemCoreService } from '../problem.service';

/** Classe sur les donnée de layout par defaut */
export class TicketStorageService extends BaseStorageService {
  _matrixIncident: boolean = undefined;
  _matrixRequest: boolean = undefined;
  _matrixProblem: boolean = undefined;

  constructor(
    userService: UserCoreService,
    authorizationService: AuthorizationCoreService,
    private incidentService: IncidentCoreService,
    private requestService: RequestCoreService,
    private problemService: ProblemCoreService,
  ) {
    super(userService, authorizationService);
  }

  reset() {
    this._matrixIncident = undefined;
    this._matrixRequest = undefined;
  }

  matrix(qualification): Observable<boolean> {
    if (qualification == 'incident') {
      return this.matrixIncident();
    } else if (qualification == 'request') {
      return this.matrixRequest();
    } else if (qualification == 'problem') {
      return this.matrixProblem();
    }

    return of(false);
  }

  /** Obtient si la matrice incident est activé */
  matrixIncident(): Observable<boolean> {
    if (this._matrixIncident == undefined) {
      return this.incidentService
        .isActiveMatrix([GqlField.create('data')])
        .pipe(
          map((x) => {
            this._matrixIncident = x?.data === true;
            return this._matrixIncident;
          }),
        );
    } else {
      return of(this._matrixIncident);
    }
  }
  /** Obtient si la matrice request est activé */
  matrixRequest(): Observable<boolean> {
    if (this._matrixRequest == undefined) {
      return this.requestService.isActiveMatrix([GqlField.create('data')]).pipe(
        map((x) => {
          this._matrixRequest = x?.data === true;
          return this._matrixRequest;
        }),
      );
    } else {
      return of(this._matrixRequest);
    }
  }
  /** Obtient si la matrice request est activé */
  matrixProblem(): Observable<boolean> {
    if (this._matrixProblem == undefined) {
      return this.problemService.isActiveMatrix([GqlField.create('data')]).pipe(
        map((x) => {
          this._matrixProblem = x?.data === true;
          return this._matrixProblem;
        }),
      );
    } else {
      return of(this._matrixProblem);
    }
  }
}
