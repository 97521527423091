import { Injectable, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { GqlField } from '../helpers';
import { NoteBaseService } from '../service-bases';

@Injectable({ providedIn: 'root' })
@Authorize('note')
export class NoteCoreService extends NoteBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getService(): NoteCoreService {
    return this;
  }

  public oneOrMore(
    state: ModelState,
    control: AbstractControl,
  ): Observable<boolean> {
    let id = state.sharedContext.params.get('id');

    if (id == undefined) {
      return of(true);
    }

    return this.find([GqlField.create('totalCount')], null, {
      and: [{ ticketId: { eq: id } }, { description: { ne: null } }],
    }).pipe(
      map((res) => {
        if (res.totalCount === 0) {
          return false;
        }
        return true;
      }),
    );
  }
}
