import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EntityAttributesType, GC, GCFactory } from '@clarilog/core';
import { ToolbarItemsComponent } from '@clarilog/shared2/components/toolbar/toolbar-items/toolbar-items.component';
import { Observable, of, Subscription } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { FormGroupHelpers } from '../work-form/form-group-helpers';
import { dirtyCheck, DirtyFormService } from './dirty-check.service';
import { WorkItem } from './work-item';
import { WorkItemFormSystemComponent } from './work-item-form-system';
import { CoreWorkItemHostDirective } from './work-item-host.component';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { ValidationError } from '@clarilog/core/services2/graphql/generated-types/types';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import { MobileNavFormEvent } from '@clarilog/shared2/components/mobile-nav-menu/mobile-nav-menu.component';
import { ReadOnlyAttribute } from '@clarilog/shared2/models/read-only-attribute';

/** Représente les informations sur les composants dynamiques. Aide au suivi. */
export type ComponentRefDescription = {
  component?: ComponentRef<any>;
  scrollPosition?: any;
  item: WorkItem;
  index: number;
  host: CoreWorkItemHostDirective;
};

export type WorkItemFormMode = 'Edit' | 'New' | 'Wait';

/** Représente un espace de travail de formulaires. */
@Component({
  selector: 'clc-work-item-form',
  templateUrl: './work-item-form.component.html',
  styleUrls: ['./work-item-form.component.scss'],
})
export class CoreWorkItemFormComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  /** Obtient les références du composent. */
  private readonly componentRefs: [ComponentRefDescription?] = [];
  /** Obtient l'état de changement. */
  isDirty$: Observable<boolean>;
  /** Obtient l'état de changement sans erreur. */
  canSave$: Observable<boolean>;
  /** Obtient ou définit l'item du menu courant.*/
  currentItem;
  /** Obtient ou définit l'item ComponentRefDescription actif. */
  activeItem: ComponentRefDescription;
  /** Obtient ou définit si le composant doit s'affciher en mode mobile. */
  @Input() enableMobileMode: boolean = false;
  /** Obtient ou définit si le bouton de retour est affiché. */
  @Input() showBack: boolean = true;
  /** Active ou désactive le mode du bouton retour. */
  @Input() backMode: boolean = false;
  /** Obtient ou définit la police de sauvegarde. */
  @Input() savePolicies: string[] = undefined;
  @Input() forceSaveBtn: boolean = false;
  /** Obtient ou définit le titre. */
  @Input() title: string;
  /** Obtient ou définit les items du menu de navigation. */
  @Input() items: WorkItem[];
  /** Obtient ou définit le FormGroup (Réactive form). */
  @Input() formGroup: UntypedFormGroup;
  /** Obtient ou définit ou définit les actions. */
  @ContentChild(ToolbarItemsComponent, { static: true })
  toolbar: ToolbarItemsComponent;
  /** Se déclenche lors de la validation du formulaire. */
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  /** Se déclenche lors de la fermeture du formulaire. */
  @Output() onClosed: EventEmitter<any> = new EventEmitter();
  /** Se déclenche au rafraîchissement. */
  @Output() onRefresh: EventEmitter<void> = new EventEmitter();
  /** Obtient ou définit le mode. */
  @Input() mode: WorkItemFormMode = 'Wait';

  @Input() userName: string = null;
  @Input() readOnlyAttribute: ReadOnlyAttribute = undefined;
  /** Obtient les hosts. */
  @ViewChildren(CoreWorkItemHostDirective)
  hosts: QueryList<CoreWorkItemHostDirective>;

  /** Obtient ou définit les erreurs asynchrone. */
  @Input() errors: ValidationError[];
  /** Obtient le composant system. */
  @ContentChild(WorkItemFormSystemComponent)
  system: WorkItemFormSystemComponent;
  @Input() showSave: boolean = true;
  @Input() attributesInfo: EntityAttributesType = undefined;

  /** Obtient ou définit la traduction du bouton save. */
  @Input() saveTilte: string;
  /** Obtient ou définit si le menu deroulant du bouton save est visible. */
  @Input() saveSplitButton: boolean = true;
  /** Obtient un bouton save si il est visible. */
  @Input() onlySaveButton: boolean = false;
  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() readOnly: boolean = false;

  @Input() displaySaveIcon: boolean = true;
  /** Obtient ou définit si on peut utiliser le ctrl + S */
  canUseSaveShortcut: boolean = false;
  inClosed: boolean = false;
  private _gc: GC;
  @Input() state: ModelState;
  /** Obtient ou définit si on affiche les info de lecture seule */
  @Input() displayReadOnlyMsg: boolean = true;

  formPending = false;

  /** Obtient ou définit si le composant doit s'affciher en mode mobile. */
  _menuNavMobileVisibility: boolean = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dirtyFormService: DirtyFormService,
    private translatedFieldService: TranslatedFieldHelperService,
    gcFactory: GCFactory,
  ) {
    this._dirtyFormService.setForm(this);
    this._gc = gcFactory.create();
  }

  /*
  Mobile
   */
  private _isMobileMenuOpen: boolean = false;

  onMobileMenuClick($event) {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  get isMobileMenuOpen(): boolean {
    return this._isMobileMenuOpen;
  }

  set isMobileMenuOpen(value: boolean) {
    this._isMobileMenuOpen = value;
  }

  /*
  END MOBILE
   */

  /** @inheritdoc */
  ngOnDestroy(): void {
    if (this.canSubscription) {
      this.canSubscription.unsubscribe();
      this._gc.forRelease(this.canSubscription);
    }
    this._gc.dispose();
  }

  canSubscription: Subscription;
  titlePlus: string;
  un: Subscription;
  defaultUnsubscribe: Subscription;

  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    this._gc.release();

    if (
      changes.formGroup != undefined &&
      changes.formGroup.currentValue != undefined
    ) {
      let defaultControl = FormGroupHelpers.getDefaultControl(
        this.formGroup,
        this.translatedFieldService,
      );

      if (defaultControl != undefined) {
        if (this.defaultUnsubscribe != undefined) {
          this.defaultUnsubscribe.unsubscribe();
        }
        this.defaultUnsubscribe = defaultControl.subscribe((r) => {
          // this.titlePlus = r;
          this.formPending = true;
          let un = this.formGroup.statusChanges.subscribe((r1) => {
            if (r1 !== 'PENDING') {
              un.unsubscribe();
              this.formPending = false;
            }
            if (this.canSave$ != undefined) {
              let csUn = this.canSave$.subscribe(
                () => {},
                finalize(() => {
                  csUn.unsubscribe();
                }),
              );
            }
          });
          if (r != undefined && typeof r == 'object') {
            let observable = r as Observable<string>;
            if (this.un != undefined) {
              this.un.unsubscribe();
            }
            if (observable != undefined) {
              this.un = observable.subscribe((r) => (this.titlePlus = r));
            }
          } else {
            this.titlePlus = r;

            if (
              this.titlePlus != undefined &&
              this.titlePlus.trim != undefined &&
              this.titlePlus.trim() !== ''
            ) {
              if (this.state?.isSubForm !== true) {
                document.title =
                  this.title + ' : ' + this.titlePlus + ' - One by Clarilog';
              }
            }
          }
        });
        this._gc.forDispose(this.defaultUnsubscribe);
      }
    }

    // Initialisation
    if (changes.mode != undefined && changes.mode.currentValue === 'New') {
      this.isDirty$ = dirtyCheck(
        this.formGroup,
        of(JSON.parse(JSON.stringify(this.formGroup.value))),
      )(this.formGroup.valueChanges);
      this.canSave$ = this.isDirty$.pipe(
        map((dirty) => {
          if (dirty) {
            dirty = FormGroupHelpers.valid(this.formGroup);
          }
          return dirty;
        }),
      );
      if (this.canSave$ !== undefined) {
        if (this.canSubscription == undefined) {
          this.canSubscription = this.canSave$.subscribe((res) => {
            this.canUseSaveShortcut = res;

            this.state.on.emit({
              eventName: 'refresh-tabs',
              eventData: undefined,
            });
          });
        }
      }
    } else if (
      changes.mode != undefined &&
      changes.mode.currentValue === 'Edit'
    ) {
      let unsubscribe = this.formGroup.valueChanges.subscribe((_) => {
        if (this.isDirty$ == undefined) {
          this.isDirty$ = dirtyCheck(
            this.formGroup,
            of(JSON.parse(JSON.stringify(this.formGroup.value))),
          )(this.formGroup.valueChanges);
        }

        this.canSave$ = this.isDirty$.pipe(
          map((dirty) => {
            if (dirty) {
              dirty = FormGroupHelpers.valid(this.formGroup);
            }
            return dirty;
          }),
        );
        if (this.canSave$ !== undefined) {
          if (this.canSubscription == undefined) {
            this.canSubscription = this.canSave$.subscribe((res) => {
              this.canUseSaveShortcut = res;

              this.state.on.emit({
                eventName: 'refresh-tabs',
                eventData: undefined,
              });
            });
          }
        }
      });
      this._gc.forRelease(unsubscribe);
    }

    // else if (
    //   changes.mode != undefined &&
    //   changes.mode.currentValue === 'Edit'
    // ) {
    //   this.isDirty$ = dirtyCheck(
    //     this.formGroup,
    //     of(JSON.parse(JSON.stringify(this.formGroup.value))),
    //   )(this.formGroup.valueChanges);
    //   this.canSave$ = this.isDirty$.pipe(
    //     map(dirty => dirty && FormGroupHelpers.valid(this.formGroup)),
    //   );
    // }
    // if (this.canSave$ !== undefined) {
    //   let unsubscribe = this.canSave$.subscribe(res => {
    //     this.canUseSaveShortcut = res;
    //   });
    //   this._gc.forRelease(unsubscribe);
    // }
  }

  /** Clique sur le bouton save. */
  save(e) {
    this.onSubmit.emit(e);
  }

  refresh() {
    this.onRefresh.emit();
  }

  private manageFormNavigationEvent() {
    this._gc.forDispose(
      this.state.on.subscribe((event) => {
        if (event.eventName === MobileNavFormEvent.NAVIGATE) {
          this.selectionChanged(event.eventData);
        }
      }),
    );
  }

  handleResultMobile($event) {
    this._menuNavMobileVisibility = $event;
  }

  /** @inheritdoc */
  ngAfterViewInit(): void {
    this.manageFormNavigationEvent();
    let changes = this.hosts.changes;
    if (this.hosts.length > 0) {
      changes = changes.pipe(startWith(this.hosts));
    }
    let unsubscribe = changes.subscribe((_) => {
      this.initializeContainers(this.items);

      // Normalement, il y a obligatoirement 1 élément.
      if (this.items.length > 0 && this.items[0].items.length > 0) {
        this.currentItem = this.items[0].items[0];
      }

      this.load(this.currentItem);

      this._changeDetectorRef.detectChanges();
    });
    this._gc.forDispose(unsubscribe);
  }

  ngAfterContentChecked(e) {
    this._changeDetectorRef.detectChanges();
  }

  /** Initialize les Hosts. */
  private initializeContainers(items, index: number = 0) {
    let hosts = this.hosts.toArray();

    for (let item of items) {
      if (item.items === undefined) {
        this.componentRefs.push({
          item: item,
          index: index,
          host: hosts[index],
        });
        index++;
      } else {
        index = this.initializeContainers(item.items, index);
      }
    }
    return index;
  }

  /** Trouve  */
  private findComponentRefByItem(item) {
    for (let componentRef of this.componentRefs) {
      if (componentRef.item === item) {
        return componentRef;
      }
    }
    return undefined;
  }

  /** Lors de la navigation dans le menu. */
  selectionChanged(e = null) {
    this.currentItem = e;
    this.load(this.currentItem);
  }

  /** Charge un item. */
  load(item) {
    if (
      this.activeItem != undefined &&
      this.activeItem.component.instance.onDeactivated !== undefined
    ) {
      this.activeItem.component.instance.onDeactivated();
    }
    let componentRef = this.findComponentRefByItem(item);
    if (componentRef != undefined) {
      if (componentRef.component === undefined) {
        // Here
        let componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(item.content);
        componentRef.component =
          componentRef.host.viewContainerRef.createComponent(componentFactory);

        componentRef.component.instance.state = this.state;
        if (item.data != undefined) {
          // Passe des données aux composent
          componentRef.component.instance.data = item.data;
        }
      } else {
        if (componentRef.component.instance.onActivated !== undefined) {
          componentRef.component.instance.onActivated();
        }
      }
    }
    this.activeItem = componentRef;
  }

  /** Appui sur le bouton du formulaire.. */
  submit(e?) {
    this.save({ close: false });
  }

  submitAndClose(e?) {
    this.inClosed = true;
    this.save({ close: true });
  }

  /** Appui sur le bouton close. */
  close(e?) {
    this.onClosed.emit();
  }

  /** Permet de connaître si une notification de badge est disponible. */
  isBadge(item: WorkItem): boolean {
    let find = false;
    if (
      item != undefined &&
      this.state?.sharedContext?.badges != undefined &&
      this.state?.sharedContext?.badges.length > 0 &&
      item.dependBadges != undefined &&
      item.dependBadges.length > 0
    ) {
      let count = this.state.sharedContext.badges.filter((b) => {
        let any = item.dependBadges.filter((f) => f == b.key);
        return b.hasValue === true && any.length > 0;
      });
      return count.length > 0;
    }

    return find;
  }

  /** Permet de connaître la validité d'un form group. */
  isValid(item: WorkItem, child: WorkItem): boolean {
    let group = FormGroupHelpers.findFormGroupByName(
      this.formGroup,
      child.name,
    );
    if (group != undefined) {
      return group.disabled || FormGroupHelpers.valid(group);
    }
    // TODO A tester
    let valid = FormGroupHelpers.findFormGroupByName(
      this.formGroup,
      item.name,
    ).valid;
    // this.formGroup.get(child.name) != undefined ? this.formGroup.get(child.name).valid : this.formGroup.get(item.name).valid;
    return group.disabled || valid;
  }

  /** Test si le form group est activé. */
  isEnabled(item: WorkItem, child: WorkItem): boolean {
    let group = FormGroupHelpers.findFormGroupByName(
      this.formGroup,
      child.name,
    );
    if (group != undefined) {
      return group.enabled;
    }
    let enabled = FormGroupHelpers.findFormGroupByName(
      this.formGroup,
      item.name,
    ).enabled;
    //this.formGroup.get(child.name) != undefined ? this.formGroup.get(child.name).enabled : this.formGroup.get(item.name).enabled;
    return enabled;
  }

  /** Test si le form group est visible. */
  isVisible(item: WorkItem, child: WorkItem): boolean {
    let group = FormGroupHelpers.findFormGroupByName(
      this.formGroup,
      child.name,
    );
    if (group != undefined) {
      item.visible =
        group.visibled &&
        (child.data.visible != undefined ? child.data.visible : true);
      return item.visible;
    }
    let visibled = FormGroupHelpers.findFormGroupByName(
      this.formGroup,
      item.name,
    ).visibled;
    //this.formGroup.get(child.name) != undefined ? this.formGroup.get(child.name).visibled : this.formGroup.get(item.name).visibled;
    return visibled;
  }

  /** A l'appui d'une touche. */
  onKeyDown($event) {
    // Detect platform
    if (navigator.platform.match('Mac')) {
      this.handleMacKeyEvents($event);
    } else {
      this.handleWindowsKeyEvents($event);
    }
  }

  /** Permet de controller le Pomme + s. */
  async handleMacKeyEvents($event) {
    // MetaKey documentation
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/metaKey
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.metaKey && charCode === 's') {
      // Action on Cmd + S
      $event.preventDefault();
      if (this.canUseSaveShortcut === true) {
        this.submit();
      }
    }
  }

  preventCtrl = false;

  handleWindowsKeyEvents($event) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.ctrlKey && charCode === 's') {
      // Action on Ctrl + S
      $event.preventDefault();
      if (this.canUseSaveShortcut === true && this.readOnly !== true) {
        if (!this.preventCtrl) {
          this.preventCtrl = true;
          this.submit();
          // Annulation de prévention multiple du CtrlS
          setTimeout(() => {
            this.preventCtrl = false;
          }, 500);
        }
      }
    }
  }
}
