import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowArchivesBaseVariables, FirstWorkflowArchivesBaseVariables, CountWorkflowArchivesBaseVariables, FindWorkflowArchivesBaseVariables, SearchWorkflowArchivesBaseVariables, ExportSchemaWorkflowArchivesBaseVariables, ExportDataWorkflowArchivesBaseVariables, CustomQueryWorkflowArchivesBaseVariables, CustomQueryIdWorkflowArchivesBaseVariables, UsedWorkflowArchivesBaseVariables, ExistWorkflowArchivesBaseVariables, InsertWorkflowArchivesBaseVariables, UpdateWorkflowArchivesBaseVariables, ImportDataWorkflowArchivesBaseVariables, UpdateManyWorkflowArchivesBaseVariables, DeleteWorkflowArchivesBaseVariables } from '../gqls'
import { GetWorkflowArchivesDocument, FirstWorkflowArchivesDocument, CountWorkflowArchivesDocument, FindWorkflowArchivesDocument, SearchWorkflowArchivesDocument, ExportSchemaWorkflowArchivesDocument, ExportDataWorkflowArchivesDocument, CustomQueryWorkflowArchivesDocument, CustomQueryIdWorkflowArchivesDocument, UsedWorkflowArchivesDocument, ExistWorkflowArchivesDocument, InsertWorkflowArchivesDocument, UpdateWorkflowArchivesDocument, ImportDataWorkflowArchivesDocument, UpdateManyWorkflowArchivesDocument, DeleteWorkflowArchivesDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowArchive, QueryContextOfWorkflowArchive, FilterOfWorkflowArchive, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowArchive, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowArchiveInput, FieldUpdateOperatorOfWorkflowArchive } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowArchiveBaseService {
    
public modelName = 'workflowArchive';
public modelPluralName = 'workflowArchives';

	private getWorkflowArchivesQuery: GetWorkflowArchivesDocument;
	private firstWorkflowArchivesQuery: FirstWorkflowArchivesDocument;
	private countWorkflowArchivesQuery: CountWorkflowArchivesDocument;
	private findWorkflowArchivesQuery: FindWorkflowArchivesDocument;
	private searchWorkflowArchivesQuery: SearchWorkflowArchivesDocument;
	private exportSchemaWorkflowArchivesQuery: ExportSchemaWorkflowArchivesDocument;
	private exportDataWorkflowArchivesQuery: ExportDataWorkflowArchivesDocument;
	private customQueryWorkflowArchivesQuery: CustomQueryWorkflowArchivesDocument;
	private customQueryIdWorkflowArchivesQuery: CustomQueryIdWorkflowArchivesDocument;
	private usedWorkflowArchivesQuery: UsedWorkflowArchivesDocument;
	private existWorkflowArchivesQuery: ExistWorkflowArchivesDocument;
	private insertWorkflowArchivesMutation: InsertWorkflowArchivesDocument;
	private updateWorkflowArchivesMutation: UpdateWorkflowArchivesDocument;
	private importDataWorkflowArchivesMutation: ImportDataWorkflowArchivesDocument;
	private updateManyWorkflowArchivesMutation: UpdateManyWorkflowArchivesDocument;
	private deleteWorkflowArchivesMutation: DeleteWorkflowArchivesDocument;

	constructor(private injector: Injector) {
		this.getWorkflowArchivesQuery = this.injector.get(GetWorkflowArchivesDocument);
		this.firstWorkflowArchivesQuery = this.injector.get(FirstWorkflowArchivesDocument);
		this.countWorkflowArchivesQuery = this.injector.get(CountWorkflowArchivesDocument);
		this.findWorkflowArchivesQuery = this.injector.get(FindWorkflowArchivesDocument);
		this.searchWorkflowArchivesQuery = this.injector.get(SearchWorkflowArchivesDocument);
		this.exportSchemaWorkflowArchivesQuery = this.injector.get(ExportSchemaWorkflowArchivesDocument);
		this.exportDataWorkflowArchivesQuery = this.injector.get(ExportDataWorkflowArchivesDocument);
		this.customQueryWorkflowArchivesQuery = this.injector.get(CustomQueryWorkflowArchivesDocument);
		this.customQueryIdWorkflowArchivesQuery = this.injector.get(CustomQueryIdWorkflowArchivesDocument);
		this.usedWorkflowArchivesQuery = this.injector.get(UsedWorkflowArchivesDocument);
		this.existWorkflowArchivesQuery = this.injector.get(ExistWorkflowArchivesDocument);
		this.insertWorkflowArchivesMutation = this.injector.get(InsertWorkflowArchivesDocument);
		this.updateWorkflowArchivesMutation = this.injector.get(UpdateWorkflowArchivesDocument);
		this.importDataWorkflowArchivesMutation = this.injector.get(ImportDataWorkflowArchivesDocument);
		this.updateManyWorkflowArchivesMutation = this.injector.get(UpdateManyWorkflowArchivesDocument);
		this.deleteWorkflowArchivesMutation = this.injector.get(DeleteWorkflowArchivesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowArchive> {
    
    		let variables: GetWorkflowArchivesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowArchive={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowArchive,
		@Args('filter?') filter?: FilterOfWorkflowArchive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowArchive> {
    
    		let variables: FirstWorkflowArchivesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowArchive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowArchivesBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowArchive,
		@Args('filter?') filter?: FilterOfWorkflowArchive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowArchive> {
    
    		let variables: FindWorkflowArchivesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowArchive,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowArchive> {
    
    		let variables: SearchWorkflowArchivesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowArchivesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowArchive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowArchivesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowArchive,
		@Args('filter?') filter?: FilterOfWorkflowArchive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowArchive> {
    
    		let variables: CustomQueryWorkflowArchivesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowArchive,
		@Args('filter?') filter?: FilterOfWorkflowArchive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowArchive> {
    
    		let variables: CustomQueryIdWorkflowArchivesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowArchivesBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowArchivesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowArchivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowArchiveInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowArchive> {
    
    		let variables: InsertWorkflowArchivesBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowArchivesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowArchive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowArchive> {
    
    		let variables: UpdateWorkflowArchivesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowArchivesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowArchivesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowArchivesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowArchive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowArchivesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowArchivesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowArchivesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowArchivesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowArchives.delete));
    	}
    
}