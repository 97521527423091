<!--<button (click)="popupVisible = true">test</button>-->
<div class="cl-button-designer">
  <dx-button
    [text]="'entities/formDesigner/openFormDesigner' | translate"
    type="default"
    [useSubmitBehavior]="true"
    (onClick)="openFormDesigner()"
  >
  </dx-button>
</div>
<dx-popup
  *ngIf="jsonModel != undefined"
  [(visible)]="popupVisible"
  [width]="'90%'"
  [height]="'99%'"
  [showTitle]="false"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
>
  <div class="cl-popup-designer-test">
    <cl-work-page-header>
      <cl-work-page-header-content>
        <cl-work-page-header-content-title [showBack]="false">
          <div style="width: 100%">
            <div style="float: left">
              {{ 'entities/formDesigner/_title/singular' | translate }}
            </div>
            <div class="cl-button-popup-designer">
              <dx-button
                icon="fal fa-times"
                (onClick)="popupVisible = false"
              ></dx-button>

              <div class="cl-button-designer">
                <dx-button
                  type="default"
                  [text]="'validate' | translate"
                  (onClick)="submit()"
                ></dx-button>
              </div>
            </div>
          </div>
          <!--        <div style="right: 0px; position: absolute"><button (click)="popupVisible = false;">Close</button><button (click)="popupVisible = false;">Save</button></div>-->
        </cl-work-page-header-content-title>
        <cl-work-page-header-content-body> </cl-work-page-header-content-body>
      </cl-work-page-header-content>
    </cl-work-page-header>
    <cl-form-designer
      (onChange)="change($event)"
      [jsonModel]="jsonModel"
      [options]="options"
      [canUseStatus]="canUseStatus"
      [qualification]="qualification"
      [formDesignerMode]="formDesignerMode"
    ></cl-form-designer>
  </div>
</dx-popup>
