import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetBrotherLabelsBaseVariables, FirstBrotherLabelsBaseVariables, CountBrotherLabelsBaseVariables, FindBrotherLabelsBaseVariables, SearchBrotherLabelsBaseVariables, ExportSchemaBrotherLabelsBaseVariables, ExportDataBrotherLabelsBaseVariables, CustomQueryBrotherLabelsBaseVariables, CustomQueryIdBrotherLabelsBaseVariables, FindFilesBrotherLabelsBaseVariables, UsedBrotherLabelsBaseVariables, ExistBrotherLabelsBaseVariables, InsertBrotherLabelsBaseVariables, UpdateBrotherLabelsBaseVariables, ImportDataBrotherLabelsBaseVariables, UpdateManyBrotherLabelsBaseVariables, DeleteBrotherLabelsBaseVariables, UploadChunkFileBrotherLabelsBaseVariables, AddFileLabelBrotherLabelsBaseVariables, RemoveFileLabelBrotherLabelsBaseVariables } from '../gqls'
import { GetBrotherLabelsDocument, FirstBrotherLabelsDocument, CountBrotherLabelsDocument, FindBrotherLabelsDocument, SearchBrotherLabelsDocument, ExportSchemaBrotherLabelsDocument, ExportDataBrotherLabelsDocument, CustomQueryBrotherLabelsDocument, CustomQueryIdBrotherLabelsDocument, FindFilesBrotherLabelsDocument, UsedBrotherLabelsDocument, ExistBrotherLabelsDocument, InsertBrotherLabelsDocument, UpdateBrotherLabelsDocument, ImportDataBrotherLabelsDocument, UpdateManyBrotherLabelsDocument, DeleteBrotherLabelsDocument, UploadChunkFileBrotherLabelsDocument, AddFileLabelBrotherLabelsDocument, RemoveFileLabelBrotherLabelsDocument } from '../gqls'
import { ServiceSingleResultOfBrotherLabel, QueryContextOfBrotherLabel, FilterOfBrotherLabel, ServiceSingleResultOfInt64, ServiceListResultOfBrotherLabel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, BrotherLabelInput, FieldUpdateOperatorOfBrotherLabel, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class BrotherLabelBaseService {
    
public modelName = 'brotherLabel';
public modelPluralName = 'brotherLabels';

	private getBrotherLabelsQuery: GetBrotherLabelsDocument;
	private firstBrotherLabelsQuery: FirstBrotherLabelsDocument;
	private countBrotherLabelsQuery: CountBrotherLabelsDocument;
	private findBrotherLabelsQuery: FindBrotherLabelsDocument;
	private searchBrotherLabelsQuery: SearchBrotherLabelsDocument;
	private exportSchemaBrotherLabelsQuery: ExportSchemaBrotherLabelsDocument;
	private exportDataBrotherLabelsQuery: ExportDataBrotherLabelsDocument;
	private customQueryBrotherLabelsQuery: CustomQueryBrotherLabelsDocument;
	private customQueryIdBrotherLabelsQuery: CustomQueryIdBrotherLabelsDocument;
	private findFilesBrotherLabelsQuery: FindFilesBrotherLabelsDocument;
	private usedBrotherLabelsQuery: UsedBrotherLabelsDocument;
	private existBrotherLabelsQuery: ExistBrotherLabelsDocument;
	private insertBrotherLabelsMutation: InsertBrotherLabelsDocument;
	private updateBrotherLabelsMutation: UpdateBrotherLabelsDocument;
	private importDataBrotherLabelsMutation: ImportDataBrotherLabelsDocument;
	private updateManyBrotherLabelsMutation: UpdateManyBrotherLabelsDocument;
	private deleteBrotherLabelsMutation: DeleteBrotherLabelsDocument;
	private uploadChunkFileBrotherLabelsMutation: UploadChunkFileBrotherLabelsDocument;
	private addFileLabelBrotherLabelsMutation: AddFileLabelBrotherLabelsDocument;
	private removeFileLabelBrotherLabelsMutation: RemoveFileLabelBrotherLabelsDocument;

	constructor(private injector: Injector) {
		this.getBrotherLabelsQuery = this.injector.get(GetBrotherLabelsDocument);
		this.firstBrotherLabelsQuery = this.injector.get(FirstBrotherLabelsDocument);
		this.countBrotherLabelsQuery = this.injector.get(CountBrotherLabelsDocument);
		this.findBrotherLabelsQuery = this.injector.get(FindBrotherLabelsDocument);
		this.searchBrotherLabelsQuery = this.injector.get(SearchBrotherLabelsDocument);
		this.exportSchemaBrotherLabelsQuery = this.injector.get(ExportSchemaBrotherLabelsDocument);
		this.exportDataBrotherLabelsQuery = this.injector.get(ExportDataBrotherLabelsDocument);
		this.customQueryBrotherLabelsQuery = this.injector.get(CustomQueryBrotherLabelsDocument);
		this.customQueryIdBrotherLabelsQuery = this.injector.get(CustomQueryIdBrotherLabelsDocument);
		this.findFilesBrotherLabelsQuery = this.injector.get(FindFilesBrotherLabelsDocument);
		this.usedBrotherLabelsQuery = this.injector.get(UsedBrotherLabelsDocument);
		this.existBrotherLabelsQuery = this.injector.get(ExistBrotherLabelsDocument);
		this.insertBrotherLabelsMutation = this.injector.get(InsertBrotherLabelsDocument);
		this.updateBrotherLabelsMutation = this.injector.get(UpdateBrotherLabelsDocument);
		this.importDataBrotherLabelsMutation = this.injector.get(ImportDataBrotherLabelsDocument);
		this.updateManyBrotherLabelsMutation = this.injector.get(UpdateManyBrotherLabelsDocument);
		this.deleteBrotherLabelsMutation = this.injector.get(DeleteBrotherLabelsDocument);
		this.uploadChunkFileBrotherLabelsMutation = this.injector.get(UploadChunkFileBrotherLabelsDocument);
		this.addFileLabelBrotherLabelsMutation = this.injector.get(AddFileLabelBrotherLabelsDocument);
		this.removeFileLabelBrotherLabelsMutation = this.injector.get(RemoveFileLabelBrotherLabelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBrotherLabel> {
    
    		let variables: GetBrotherLabelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.get));
            }
            else{
                let result:ServiceSingleResultOfBrotherLabel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBrotherLabel,
		@Args('filter?') filter?: FilterOfBrotherLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBrotherLabel> {
    
    		let variables: FirstBrotherLabelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBrotherLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountBrotherLabelsBaseVariables = {
    			filter: filter
    		}
    				return this.countBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBrotherLabel,
		@Args('filter?') filter?: FilterOfBrotherLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBrotherLabel> {
    
    		let variables: FindBrotherLabelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfBrotherLabel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBrotherLabel> {
    
    		let variables: SearchBrotherLabelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaBrotherLabelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBrotherLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataBrotherLabelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfBrotherLabel,
		@Args('filter?') filter?: FilterOfBrotherLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBrotherLabel> {
    
    		let variables: CustomQueryBrotherLabelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfBrotherLabel,
		@Args('filter?') filter?: FilterOfBrotherLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBrotherLabel> {
    
    		let variables: CustomQueryIdBrotherLabelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesBrotherLabelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.findFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedBrotherLabelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistBrotherLabelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existBrotherLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: BrotherLabelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBrotherLabel> {
    
    		let variables: InsertBrotherLabelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertBrotherLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfBrotherLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBrotherLabel> {
    
    		let variables: UpdateBrotherLabelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateBrotherLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataBrotherLabelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataBrotherLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfBrotherLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyBrotherLabelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyBrotherLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteBrotherLabelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteBrotherLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.delete));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileBrotherLabelsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileBrotherLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public addFileLabel(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fileId') fileId: string,
		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileLabelBrotherLabelsBaseVariables = {
    			id: id,
			fileId: fileId
    		}
    				return this.addFileLabelBrotherLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.addFileLabel));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileLabel(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fileId') fileId: string,
		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileLabelBrotherLabelsBaseVariables = {
    			id: id,
			fileId: fileId
    		}
    				return this.removeFileLabelBrotherLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.brotherLabels.removeFileLabel));
    	}
    
}