import { Injectable } from '@angular/core';
import { FieldsOnCorrectTypeRule } from 'graphql';
import { GeneratorHelper } from 'tools/clarilog-gql-code-gen/src/services/generator-helpers';
import {
  MyArg,
  MyFragment,
  MyField,
  MyType,
} from 'tools/clarilog-gql-code-gen/src/services/gql-introspection-parser.service';
import fragments from './fragments.json';
export class FragmensDocumentHelpers {
  static getAllRequiredFragment(deps: any[], alReadyCheck: any[] = []) {
    deps.forEach((dep) => {
      if (alReadyCheck.find((f) => f.name === dep.name) == undefined) {
        alReadyCheck.push(dep);
        alReadyCheck = FragmensDocumentHelpers.getAllRequiredFragment(
          dep.dependencies(),
          alReadyCheck,
        );
      }
    });
    return alReadyCheck;
  }
}

export type GqlFields = Array<GqlSubField | GqlField>;

export class GqlField {
  kind: 'GqlField' = 'GqlField';
  name: string = '';
  onType: string;

  constructor() {
    this.kind = 'GqlField';
  }

  static create(name: string, onType: string = null): GqlField {
    return { name: name, kind: 'GqlField', onType: onType };
  }
}

export class GqlSubFieldArg {
  name: string;
  argName: string;

  static create(name: string, argName: string): GqlSubFieldArg {
    return { name: name, argName: argName };
  }
}

export class GqlSubField {
  kind: 'GqlSubField' = 'GqlSubField';
  name: string = '';
  args: GqlSubFieldArg[] = [];
  onType: string;
  fields: Array<GqlField | GqlSubField> = [];

  constructor() {
    this.kind = 'GqlSubField';
  }

  static create(
    name: string,
    fields: Array<GqlField | GqlSubField> = [],
    onType: string = null,
    args: GqlSubFieldArg[] = [],
  ): GqlSubField {
    return {
      name: name,
      args: args,
      fields: fields,
      kind: 'GqlSubField',
      onType: onType,
    };
  }
}

// export type GqlField = {
//     name: string
// }

// export type GqlSubField = {
//     name: string;
//     fields: Array<GqlField | GqlSubField>;
// }

export type GqlQueryFields = {
  args: MyArg[];
  queryField: string;
};

@Injectable({ providedIn: 'root' })
export class ParseCustomGqlField {
  private fragmens: MyFragment[];

  constructor() {
    this.fragmens = fragments as MyFragment[];
  }

  private checkSubFieldInFragment(
    fragment: MyFragment,
    field: GqlSubField,
    needThrow: boolean = true,
  ): MyField {
    let result = null;
    fragment.fields.forEach((fragmentField) => {
      if (fragmentField.name === field.name) {
        let finalType = GeneratorHelper.getFinalType(fragmentField.type);

        if (
          finalType.hasFragment === true ||
          fragmentField.type?.name == 'DynamicPropertyScalarType'
        ) {
          result = fragmentField;
        } else {
          throw new Error(
            `Field "${field.name}" is not an object on framgent "${fragment.name}"`,
          );
        }
      }
    });

    fragment.possibleTypes.forEach((possibleType) => {
      if (result == undefined) {
        let typeFrag = this.findFragment(possibleType);
        result = this.checkSubFieldInFragment(typeFrag, field, false);
      }
    });
    if (result == undefined && needThrow === true) {
      console.log(fragment);
      throw new Error(
        `Field "${field.name}" Not found in fragment "${fragment.name}"`,
      );
    }
    return result;
  }

  private findFragment(type: MyType): MyFragment {
    let finalType = GeneratorHelper.getFinalType(type);

    let frag = this.fragmens.find((f) => f.name === finalType.name);

    if (frag != undefined) {
      return frag;
    } else {
      throw new Error('Fragment not found : ' + type.name);
    }
  }
  private onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  public getUniqueArgs(args: string) {
    let splitted = args.split(', ');
    return splitted.filter(this.onlyUnique).join(', ');
  }

  public generateOperationExtendedArgsString(fragArgs: MyArg[]): string {
    if (fragArgs.length === 0) {
      return '';
    }

    return `${fragArgs
      .map((m) => {
        let typeString = GeneratorHelper.generateGqlType(m.type);

        return `, $${m.name}: ${typeString}${
          typeString.includes('!') ? '' : ' = null'
        }`;
      })
      .join('')}`;
  }

  private generateFieldArgs(
    fragArgs: MyArg[],
    fieldArgs: GqlSubFieldArg[],
  ): MyArg[] {
    let result: MyArg[] = [];

    fieldArgs.map((m) => {
      let fragArg = fragArgs.find((f) => f.name === m.argName);
      if (fragArg == undefined) {
        throw new Error('Arg ' + m.argName + ' not found in fragment arg');
      } else {
        let newArg: MyArg = {
          name: m.name,
          type: fragArg.type,
          description: fragArg.description,
          defaultValue: fragArg.defaultValue,
        };
        result.push(newArg);
      }
    });

    return result;
  }

  private generateFragmentFieldArgs(
    fragArgs: MyArg[],
    fieldArgs: GqlSubFieldArg[],
  ) {
    if (fieldArgs.length === 0) {
      return '';
    }
    return `( ${fieldArgs.map((m) => {
      let fragArg = fragArgs.find((f) => f.name === m.argName);
      if (fragArg == undefined) {
        throw new Error('Arg ' + m.argName + ' not found in fragment arg');
      } else {
        return `${m.argName}: $${m.name}`;
      }
    })} )`;
  }

  private generateFragmentField(
    fragment: MyFragment,
    field: GqlField,
    needThrow: boolean = true,
  ): string {
    let found = null;
    if (field.name === '__typename') {
      found = field.name;
    } else {
      fragment.fields.forEach((fragmentField) => {
        if (fragmentField.name === field.name) {
          let finalType = GeneratorHelper.getFinalType(fragmentField.type);

          if (finalType.hasFragment === false || finalType.kind === 'ENUM') {
            // because enum has geenrated type
            found = field.name;
          } else {
            if (field.name === 'trackings') {
              console.error(
                `Field "${field.name}" Not found in fragment "${fragment.name}"`,
              );
            } else if (needThrow === true) {
              console.error(
                `Field "${field.name}" correspond to object on framgent "${fragment.name}"`,
              );
              throw new Error(
                `Field "${field.name}" correspond to object on framgent "${fragment.name}"`,
              );
            }
          }
        }
      });
    }

    if (found === null) {
      fragment.possibleTypes.forEach((possibleType) => {
        let otherFrag = this.findFragment(possibleType);
        let subFound = this.generateFragmentField(otherFrag, field, false);

        if (subFound != null) {
          found = `${field.name}`;
          // found = `... on ${otherFrag.name} { ${field.name} }`;
        }
      });
      if (needThrow === true && found === null) {
        console.log(fragment);
        if (field.name === 'trackings') {
          console.error(
            `Field "${field.name}" Not found in fragment "${fragment.name}"`,
          );
        } else {
          throw new Error(
            `Field "${field.name}" Not found in fragment "${fragment.name}"`,
          );
        }
      }
    }
    return found;
  }
  // old modify for scanconf list
  // private generateOnType(inside: string, onType: string, ) {
  //     if (onType != undefined) {
  //         this.findFragment({ name: onType, hasFragment: true, kind: 'MyType', ofType: null })
  //         return `... on ${onType} { ${inside} }`;
  //     }
  //     return inside;
  // }

  private generateOnType(inside: string, onType: string) {
    if (onType != undefined) {
      this.findFragment({
        name: onType,
        hasFragment: true,
        kind: 'MyType',
        ofType: null,
      });
      return `... on ${onType} { ${inside} }`;
    }
    return inside;
  }

  private generateField(
    fragment: MyFragment,
    field: GqlSubField | GqlField,
    variables: any,
  ): GqlQueryFields {
    switch (field.kind) {
      case 'GqlSubField':
        let fragmentFoundedField = this.checkSubFieldInFragment(
          fragment,
          field,
        );
        let newArgs = this.generateFieldArgs(
          fragmentFoundedField.args,
          field.args,
        );
        let res = this.generateGqlQueryFields(
          this.findFragment(fragmentFoundedField.type).name,
          field.fields,
          variables,
        );
        // old modify for scanconf list
        //                 let ret = {
        //                     args: res.args.concat(newArgs), queryField: `
        // ${field.name} ${this.generateFragmentFieldArgs(fragmentFoundedField.args, field.args)} {
        //     ${this.generateOnType(res.queryField, field.onType)}
        // }` };
        let ret = {
          args: res.args.concat(newArgs),
          queryField: `
${field.name} ${this.generateFragmentFieldArgs(
            fragmentFoundedField.args,
            field.args,
          )} {
    ${res.queryField}
}`,
        };
        if (field.onType != undefined) {
          ret.queryField = this.generateOnType(ret.queryField, field.onType);
        }
        return ret;

      case 'GqlField':
        let foundedField = this.generateFragmentField(fragment, field);
        let ret2 = {
          args: [],
          queryField: `${foundedField != undefined ? foundedField : ''}`,
        };

        if (field.onType != undefined) {
          ret2.queryField = this.generateOnType(field.name, field.onType);
        }

        return ret2;
      default:
        console.log(field);
        throw new Error('Not Implemented');
    }
  }

  private setInterfaceDefaultField(fragment: MyFragment = null): string {
    let result = '';

    if (fragment != undefined) {
      fragment.interfaces.forEach((interf) => {
        if (interf.name === 'ISystem') {
          result += ' key defaultName ';
        }
      });
    }

    return result;
  }

  public generateGqlQueryFields(
    fragmentName: string,
    fields: Array<GqlField | GqlSubField>,
    variables: any,
  ): GqlQueryFields {
    // ServiceList ResultOfAsset

    if (fragmentName === 'any') {
      return {
        args: [],
        queryField: '',
      };
    }

    let fragment = this.fragmens.find((f) => f.name === fragmentName);

    if (
      fields == undefined ||
      fields.length === 0 ||
      fields.forEach == undefined
    ) {
      console.log(fields);
      throw new Error(
        'Query fields cannot be null for fragment : ' + fragmentName,
      );
    }

    if (fragment != undefined) {
      let result: GqlQueryFields = {
        args: [],
        queryField: this.setInterfaceDefaultField(fragment),
      };

      fields.forEach((field) => {
        if (field.name != 'dynamicProperties') {
          let ret = this.generateField(
            fragment as MyFragment,
            field,
            variables,
          );

          // let finalArgs = [];
          // let variablesKeys = Object.keys(variables);

          // ret.args.forEach((el) => {
          //   // debugger;
          //   //if (variablesKeys.includes(el.name))
          // });

          result = {
            args: result.args.concat(ret.args),
            queryField: result.queryField + ' ' + ret.queryField,
          };
        } else {
          result = {
            args: result.args,
            queryField: result.queryField + ' ' + field.name,
          };
        }
      });
      return result;
    } else {
      throw new Error('Framgent ' + fragmentName + ' not found');
    }
  }
}
