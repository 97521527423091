import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { CampaignBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfAssetCategory,
  QueryContextOfAssetCategory,
  ServiceListResultOfAssetCategory,
} from '../types';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration')
export class CampaignCoreService extends CampaignBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findAssetCategories(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetCategory,
    @Args('filter?') filter?: FilterOfAssetCategory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAssetCategory> {
    ModelFieldCompilerService.createField('data.key', null, fields);
    ModelFieldCompilerService.createField('data.parentId', null, fields);

    if (
      (options.skip == undefined && options.limit >= 500000) ||
      options.limit == undefined
    ) {
      filter = {};
    }
    let result = super.findAssetCategories(
      fields,
      options,
      filter,
      extendedVariables,
    );

    if (options.limit != undefined && options.limit < 500000) {
      return result;
    }

    result.subscribe((res) => {
      const assetId = res.data.find((x) => x.key === 'asset');
      if (assetId != undefined) {
        res.data.forEach((element) => {
          if (element.parentId === assetId.id) {
            element.parentId = null;
          }
        });
        res.data = res.data.filter((obj) => obj !== assetId);
      }
    });
    return result;
  }
}
