import { Injectable, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, UrlSegment } from '@angular/router';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import {
  AuthorizationCoreService,
  CoreGraphQLSource,
} from '@clarilog/core/services2';
import modelIncidentIntervention from '@clarilog/modules2/incident/pages/incident/intervention.model.json';
import { FormGroupHelpers } from '@clarilog/shared2/components/form/work-form/form-group-helpers';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GqlField, GqlFields, GqlSubField, GqlSubFieldArg } from '../helpers';
import { TicketBaseService } from '../service-bases';
import {
  FilterOfResolutionModel,
  FilterOfTicketCategory,
  FilterOfTicketTag,
  FilterOfTicketTask,
  ModelRule,
  ModelRulesDesigner,
  Privilege,
  QueryContextOfResolutionModel,
  QueryContextOfTag,
  QueryContextOfTicketTask,
  ServiceListResultOfResolutionModel,
  ServiceListResultOfTicketTag,
  ServiceListResultOfTicketTask,
  Sort,
} from '../types';
import { IncidentModelCoreService } from './incident-model.service';

import modelIncidentEscalade from '@clarilog/modules2/incident/pages/incident/escalation.model.json';
import modelRequestEscalade from '@clarilog/modules2/request/pages/request/escalation.model.json';

import modelRequestIntervention from '@clarilog/modules2/request/pages/request/intervention.model.json';

import { CorePolicyValidator } from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import { ModelRuleApplicationService } from '@clarilog/core/services2/model-rule-application/model-rule-application.service';
import { IncidentHelpers } from '@clarilog/modules2/incident/pages/incident/incident-model-helper';
import { RequestHelpers } from '@clarilog/modules2/request/pages/request/request-model-helper';
import { AtlasIndex } from '@clarilog/shared2/models/schema.extensions';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { FileManagerCoreService } from './file-manager.service';
import { LocalStorageService } from './local-storage-service/local-storage-service';
import { ModelRulesDesignerCoreService } from './model-rules-designer.service';
import { PrivilegeCoreService } from './privilege.service';
import { ResolutionModelCoreService } from './resolution-model.service';
import { UserCoreService } from './user.service';
import { TicketTagCoreService } from './ticket-tag.service';
import { CustomFilterOperation } from '../custom-types';
import { MyOrganizationCoreService } from './my-organization.service';
import { CoreWorkFormComponent } from '@clarilog/shared2/components/form/work-form/work-form.component';

export interface ticketState {
  name: string;
  route: string;
  translatedName: string;
}

@Injectable({ providedIn: 'root' })
export class TicketCoreService extends TicketBaseService {
  _countElement: number = undefined;
  policies: any[] = [];
  constructor(
    injector: Injector,
    private ticketModelService: IncidentModelCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    public modelRuleApplicationService: ModelRuleApplicationService,
    private policyValidatorTicket: CorePolicyValidator,
    private resolutionModelService: ResolutionModelCoreService,
    private router: Router,
    private ticketTagService: TicketTagCoreService,
    private authorizationCoreService: AuthorizationCoreService,
  ) {
    super(injector);
  }

  /** Obtient toute les qualification incident / demande */
  getTicketType(): any[] {
    return this.getAllTicketType().filter(
      (f) => f.id == 'incident' || f.id == 'request',
    );
  }
  /** Obtient toute les qualification */
  getAllTicketType(): any[] {
    return [
      {
        id: 'incident',
        name: TranslateService.get('entities/incident/_title/singular'),
      },
      {
        id: 'request',
        name: TranslateService.get('entities/request/_title/singular'),
      },
      {
        id: 'problem',
        name: TranslateService.get('entities/problem/_title/singular'),
      },
    ];
  }

  @InjectArgs
  ticketGroupingQualification(@Args('qualification') qualification: string[]) {
    let arrayResult = [];

    qualification.map((element) => {
      switch (element) {
        case 'incident': {
          let incidentTranslate = TranslateService.get(
            'entities/incident/_title/singular',
          );
          arrayResult.push(incidentTranslate);
          break;
        }
        case 'request': {
          let requestTranslate = TranslateService.get(
            'entities/request/_title/singular',
          );
          arrayResult.push(requestTranslate);
          break;
        }
        case 'problem': {
          let problemTranslate = TranslateService.get(
            'entities/problem/_title/singular',
          );
          arrayResult.push(problemTranslate);
          break;
        }
        default:
          null;
          break;
      }
    });
    return arrayResult.length > 0 ? arrayResult : null;
  }

  /** Obtient toute les qualification */
  getBaseTicketTypes(): any[] {
    let items = this.getAllTicketType();
    items.push({
      id: 'ticket',
      name: TranslateService.get('entities/ticket/_title/singular'),
    });

    return items;
  }

  findBaseTicketTypesAsync() {
    return of({
      data: this.getBaseTicketTypes(),
    });
  }

  /** Obtient toute les qualification des problemes */
  getProblemType(): any[] {
    return this.getAllTicketType().filter((f) => f.id == 'problem');
  }

  @InjectArgs
  unDeselectQualification(
    @Args('unDeselectQualification') unDeselectQualification: string[],
  ): any[] {
    return unDeselectQualification;
  }

  @InjectArgs
  getTicketCatgoryType(
    @Args('qualifications') qualifications: string[],
  ): any[] {
    var result = this.getTicketType();

    if (qualifications != undefined && qualifications.length > 0) {
      result = result.filter(
        (f) => qualifications.filter((q) => q == f.id).length > 0,
      );
    }
    return result;
  }

  @InjectArgs
  getAllTicketCatgoryType(
    @Args('qualifications') qualifications: string[],
  ): any[] {
    var result = this.getAllTicketType();

    if (qualifications != undefined && qualifications.length > 0) {
      result = result.filter(
        (f) => qualifications.filter((q) => q == f.id).length > 0,
      );
    }
    return result;
  }

  @InjectArgs
  getTicketByType(@Args('qualification') qualification: string[]): any[] {
    var result = this.getTicketType();
    if (
      qualification != undefined &&
      qualification.length > 0 &&
      Array.isArray(qualification)
    ) {
      result = result.filter(
        (f) => qualification.filter((q) => q == f.id).length > 0,
      );
    } else if (qualification.length > 0) {
      result = result.filter((element) => element.id == qualification);
    }

    result.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return result;
  }
  public createHTMLFile(filename: string, data: any) {
    const element = document.createElement('a');
    let type = 'text/html';
    let extension = '.html';

    //Création du BinaryLargeObject comprenant le fichier encodé
    const blob = new Blob([this.s2ab(atob(data))], {
      type: type,
    });

    const date = new Date();
    const dateString =
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2);

    element.href = URL.createObjectURL(blob);
    element.download = filename + '_print_' + dateString + extension;

    document.body.appendChild(element);
    element.click();
    setTimeout(() => {
      element.remove();
    });
  }

  private s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  public changeIncidentModelModeWithCategoryVisibility(
    rules: ModelRulesDesigner,
    model: ModelState,
  ) {
    //evo n°7938
    // le but est de changer le mode de foncitonnement du filtre des model de ticket
    // mode normal = filtre si category
    // mode category invisible == pas de filtre sur les category
    if (rules == undefined) {
      return;
    }
    let categoryRule = rules.rules.filter(
      (el) => el.fieldName === 'ticketCategoryId',
    );
    if (categoryRule.length >= 1) {
      if (categoryRule[0].visible === false) {
        model.sharedContext.params.set('isCategoryVisible', () => false);
      }
    }
  }

  /** Liste des propriétés pour la recherche */
  getField(): AtlasIndex[] {
    let taskTitle = `${TranslateService.get(
      'entities/task/_title/plural',
    )} (${TranslateService.get('entities/task/title')})`;
    let taskDescription = `${TranslateService.get(
      'entities/task/_title/plural',
    )} (${TranslateService.get('entities/task/description')})`;

    return [
      {
        id: 0,
        value: TranslateService.get('entities/incident/title'),
        field: 'title',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: 1,
        value: TranslateService.get('entities/ticket/description'),
        field: 'description',
        type: 'tickets',
        isTranslateField: false,
      },

      {
        id: 2,
        value: TranslateService.get('entities/incident/resolutionPublic'),
        field: 'resolutionComment',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: 3,
        value: TranslateService.get(
          'entities/incident/privateResolutionComment',
        ),
        field: 'privateResolutionComment',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: 4,
        value: TranslateService.get('entities/note/_title/plural'),
        field: 'description',
        type: 'notes',
        isTranslateField: false,
      },
      {
        id: 5,
        value: TranslateService.get('entities/intervention/_title/plural'),
        field: 'description',
        type: 'interventions',
        isTranslateField: true,
      },
      {
        id: 6,
        value: TranslateService.get('entities/message/_title/plural'),
        field: 'content',
        type: 'messages',
        isTranslateField: false,
      },

      {
        id: 7,
        value: taskTitle,
        field: 'title',
        type: 'tasks',
        isTranslateField: true,
      },
      {
        id: 8,
        value: taskDescription,
        field: 'description',
        type: 'tasks',
        isTranslateField: true,
      },

      {
        id: 9,
        value: TranslateService.get('entities/customField/_title/plural'),
        field: 'customField1',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: 10,
        value: TranslateService.get('entities/incident/tags'),
        field: 'tags',
        type: 'tickets',
        isTranslateField: false,
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }

  getCustomFieldType(): ModelDataSourceContext {
    return new ModelDataSourceContext({
      datasource: CoreGraphQLSource.create({
        query: (filters?: any) => {
          return of({
            data: [
              {
                id: '1',
                name: TranslateService.get('entities/ticket/customField1'),
              },
              {
                id: '2',
                name: TranslateService.get('entities/ticket/customField2'),
              },
              {
                id: '3',
                name: TranslateService.get('entities/ticket/customField3'),
              },
              {
                id: '4',
                name: TranslateService.get('entities/ticket/customField4'),
              },
              {
                id: '5',
                name: TranslateService.get('entities/ticket/customField5'),
              },
            ],
            totalCount: 5,
          });
        },
      }),
    });
  }

  @InjectArgs
  public findSortedByDateAssociatedTasks(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfTicketTask,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfTicketTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketTask> {
    if (extendedVariables == undefined) {
      extendedVariables = {};
    }
    let queryFields = GqlSubField.create('data', [
      GqlSubField.create('tasks', fields, null, [
        GqlSubFieldArg.create('filterOfTasks', 'filter'),
        GqlSubFieldArg.create('optionsOfTasks', 'options'),
      ]),
    ]);
    if (options == undefined) {
      options = {};
    }

    options.sort = [{ order: Sort.Asc }];
    extendedVariables['filterOfTasks'] = filter;
    extendedVariables['optionsOfTasks'] = options;
    return this.get([queryFields], id, extendedVariables).pipe(
      map(
        (result) =>
          result?.data?.tasks ?? {
            data: [],
            totalCount: 0,
          },
      ),
    );
  }

  /** Liste des champs de mention pour le type problème */
  findProblemMentionEmail() {
    let mentions = [
      {
        key: 'operatorTeamAffectedUsersMail',
        translate: `entities/incident/operatorTeamAffectedId`,
      },
      {
        key: 'operatorAffectedMail',
        translate: `entities/incident/operatorAffected`,
      },
      {
        key: 'operatorTeamReferentUsersMail',
        translate: `entities/incident/operatorTeamReferentMailList`,
      },
      {
        key: 'operatorTeamAffectedMail',
        translate: `entities/incident/operatorTeamAffectedMail`,
      },
      {
        key: 'operatorTeamReferentMail',
        translate: `entities/incident/operatorTeamReferentMail`,
      },
      {
        key: 'operatorTeamReferentManagerMail',
        translate: `entities/incident/operatorTeamReferentManagerMail`,
      },
      {
        key: 'operatorTeamAffectedManagerMail',
        translate: `entities/incident/operatorTeamAffectedManagerMail`,
      },
      {
        key: 'externalObjectId',
        translate: `entities/request/externalObjectId`,
      },
    ];

    mentions.forEach((f) => {
      f['name'] = TranslateService.get(f.translate);
    });

    return mentions;
  }

  /** Liste des champs de mention pour le sujet */
  findMentionEmail(): any {
    let mentions = [
      {
        key: 'userMakeRequestMail',
        translate: `entities/incident/userMakeRequest`,
      },
      {
        key: 'userAffectedMail',
        translate: `entities/incident/userAffected`,
      },

      {
        key: 'otherUserImpactedOperatorMail',
        translate: `entities/incident/allUsersImpactedOperator`,
      },
      {
        key: 'otherUserImpactedHelpDeskMail',
        translate: `entities/incident/allUsersImpactedHelpDesk`,
      },
      {
        key: 'otherUserImpactedSupplierMail',
        translate: `entities/incident/allUsersImpactedSupplier`,
      },

      {
        key: 'otherUserImpactedMail',
        translate: `entities/incident/allUsersImpacted`,
      },
      {
        key: 'operatorReferentMail',
        translate: `entities/incident/operatorReferent`,
      },
      {
        key: 'operatorTeamAffectedUsersMail',
        translate: `entities/incident/operatorTeamAffectedId`,
      },
      {
        key: 'operatorAffectedMail',
        translate: `entities/incident/operatorAffected`,
      },
      {
        key: 'operatorTeamReferentUsersMail',
        translate: `entities/incident/operatorTeamReferentMailList`,
      },
      {
        key: 'operatorTeamAffectedMail',
        translate: `entities/incident/operatorTeamAffectedMail`,
      },
      {
        key: 'operatorTeamReferentMail',
        translate: `entities/incident/operatorTeamReferentMail`,
      },
      {
        key: 'operatorTeamReferentManagerMail',
        translate: `entities/incident/operatorTeamReferentManagerMail`,
      },
      {
        key: 'operatorTeamAffectedManagerMail',
        translate: `entities/incident/operatorTeamAffectedManagerMail`,
      },
      {
        key: 'managerUserAffectedMail',
        translate: `entities/incident/managerUserAffected`,
      },
      {
        key: 'managerUserMakeRequestMail',
        translate: `entities/incident/managerUserMakeRequest`,
      },
      {
        key: 'allOperatorsMail',
        translate: `entities/incident/allOperators`,
      },
      {
        key: 'externalObjectId',
        translate: `entities/request/externalObjectId`,
      },
    ];

    mentions.forEach((f) => {
      f['name'] = TranslateService.get(f.translate);
    });

    return mentions;
  }

  /** Liste des champs de mention pour le body des probleme */
  findProblemMentionBody() {
    let mentions = this.findProblemMentionSubject();

    mentions = mentions.concat([
      {
        key: 'ticketCategory.path',
        name: TranslateService.get(`entities/incident/categoryPath`),
      },
      {
        key: 'cause',
        name: TranslateService.get(`entities/problem/cause`),
      },
      {
        key: 'lastNoteAffectation',
        name: TranslateService.get(`entities/incident/lastNoteAffectation`),
      },
      {
        key: 'lastCommentStatusChange',
        name: TranslateService.get(`entities/incident/lastCommentStatusChange`),
      },
      {
        key: 'lastDateStatusChange',
        name: TranslateService.get(`entities/incident/lastDateStatusChange`),
      },
      {
        key: 'LastDateStatusChangeUTC',
        name: TranslateService.get(`entities/incident/lastDateStatusChangeUTC`),
      },
      {
        key: 'created',
        name: TranslateService.get(`entities/incident/createdUTC`),
      },
      {
        key: '[ticketProperties].created',
        name: TranslateService.get(`entities/incident/created`),
      },
      {
        key: 'description',
        name: TranslateService.get(`entities/incident/description`),
      },
      {
        key: 'urlIncidentOperator',
        name: TranslateService.get(`entities/incident/urlIncidentOperator`),
      },
      {
        key: 'location.name',
        name: TranslateService.get(`entities/location/_title/singular`),
      },
      {
        key: '[ticketOperatorConnectedProperty].name',
        name: TranslateService.get(`entities/incident/operatorConnectedName`),
      },
      {
        key: 'organizationalUnit.name',
        name: TranslateService.get(
          `entities/organizationalUnit/_title/singular`,
        ),
      },
      {
        key: 'workaround',
        name: TranslateService.get(`entities/problem/workaround`),
      },
      {
        key: 'solution',
        name: TranslateService.get(`entities/problem/solution`),
      },
      {
        key: 'urlRawIncidentOperator',
        name: TranslateService.get(`entities/incident/urlRawIncidentOperator`),
      },
    ]);

    mentions.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return mentions;
  }
  /** Liste des champs de mention pour le sujet  des probleme*/
  findProblemMentionSubject() {
    let mentions = [
      { key: 'title', name: TranslateService.get(`entities/incident/title`) },
      {
        key: 'ticketCategory.name',
        name: TranslateService.get(`entities/ticketCategory/_title/singular`),
      },
      {
        key: 'operatorTeamReferent.name',
        name: TranslateService.get(`entities/incident/operatorTeamReferentId`),
      },
      {
        key: 'operatorTeamAffected.name',
        name: TranslateService.get(`entities/incident/operatorTeamAffectedTo`),
      },
      {
        key: 'impact.name',
        name: TranslateService.get(`entities/impact/_title/singular`),
      },
      {
        key: 'ticketNumber',
        name: TranslateService.get(`entities/ticket/ticketNumber`),
      },
      {
        key: 'operatorAffected.name',
        name: TranslateService.get(`entities/incident/operatorAffected`),
      },
      {
        key: 'operatorReferent.name',
        name: TranslateService.get(`entities/incident/operatorReferentId`),
      },
      {
        key: 'origin.name',
        name: TranslateService.get(`entities/origin/_title/singular`),
      },
      {
        key: 'priority.name',
        name: TranslateService.get(`entities/priority/_title/singular`),
      },
      {
        key: 'status.name',
        name: TranslateService.get(`entities/ticketStatus/_title/singular`),
      },
      {
        key: 'replyTag',
        name: TranslateService.get(`entities/incident/replyTag`),
      },
      {
        key: 'urgency.name',
        name: TranslateService.get(`entities/urgency/_title/singular`),
      },
      {
        key: 'externalObjectId',
        name: TranslateService.get(`entities/request/externalObjectId`),
      },
    ];

    mentions.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return mentions;
  }

  /** Liste des champs de mention pour le sujet */
  findMentionSubject(): any {
    let mentions = [
      {
        key: 'ticketNumber',
        name: TranslateService.get(`entities/ticket/ticketNumber`),
      },
      {
        key: 'status.name',
        name: TranslateService.get(`entities/ticketStatus/_title/singular`),
      },
      { key: 'title', name: TranslateService.get(`entities/incident/title`) },
      {
        key: 'ticketCategory.name',
        name: TranslateService.get(`entities/ticketCategory/_title/singular`),
      },
      {
        key: 'impact.name',
        name: TranslateService.get(`entities/impact/_title/singular`),
      },
      {
        key: 'urgency.name',
        name: TranslateService.get(`entities/urgency/_title/singular`),
      },
      {
        key: 'priority.name',
        name: TranslateService.get(`entities/priority/_title/singular`),
      },
      {
        key: 'userMakeRequest.name',
        name: TranslateService.get(`entities/incident/userMakeRequest`),
      },
      {
        key: 'userAffected.name',
        name: TranslateService.get(`entities/incident/userAffected`),
      },
      {
        key: 'operatorReferent.name',
        name: TranslateService.get(`entities/incident/operatorReferentId`),
      },
      {
        key: 'operatorTeamReferent.name',
        name: TranslateService.get(`entities/incident/operatorTeamReferentId`),
      },
      {
        key: 'ticketStatusReasons.name',
        name: TranslateService.get(`entities/ticketStatusReason/reason`),
      },
      {
        key: 'operatorAffected.name',
        name: TranslateService.get(`entities/incident/operatorAffected`),
      },
      {
        key: 'operatorTeamAffected.name',
        name: TranslateService.get(`entities/incident/operatorTeamAffectedTo`),
      },
      {
        key: 'levelSupport',
        name: TranslateService.get(`entities/incident/levelSupport`),
      },
      {
        key: 'resolutionCategory.name',
        name: TranslateService.get(`entities/incident/resolutionCategory`),
      },
      {
        key: 'callbackNumber',
        name: TranslateService.get(`entities/incident/callbackNumber`),
      },
      {
        key: 'customField1',
        name: TranslateService.get(`entities/incident/customField1`),
      },
      {
        key: 'customField2',
        name: TranslateService.get(`entities/incident/customField2`),
      },
      {
        key: 'customField3',
        name: TranslateService.get(`entities/incident/customField3`),
      },
      {
        key: 'customField4',
        name: TranslateService.get(`entities/incident/customField4`),
      },
      {
        key: 'customField5',
        name: TranslateService.get(`entities/incident/customField5`),
      },
      {
        key: 'replyTag',
        name: TranslateService.get(`entities/incident/replyTag`),
      },
      {
        key: '[ticketProperties].reminder.lastReminder',
        name: TranslateService.get(`entities/ticket/lastReminderUTC`),
      },

      {
        key: '[ticketDateUTC].reminder.lastReminder',
        name: TranslateService.get(`entities/ticket/lastReminder`),
      },
      {
        key: '[ticketProperties].reminder.user.name',
        name: TranslateService.get(`entities/ticket/authorRemind`),
      },
      {
        key: '[ticketProperties].reminder.commentary',
        name: TranslateService.get(`entities/ticket/reminderCommentary`),
      },
      {
        key: '[ticketProperties].reminder.increment',
        name: TranslateService.get(`entities/ticket/increment`),
      },
      {
        key: '[ticketDateUTC].satisfaction.created',
        name: TranslateService.get(`entities/ticket/satisfactionDate`),
      },

      {
        key: '[ticketProperties].satisfaction.created',
        name: TranslateService.get(`entities/ticket/satisfactionDateUTC`),
      },
      {
        key: '[ticketProperties].satisfaction.user.name',
        name: TranslateService.get(`entities/ticket/satisfactionUser`),
      },
      {
        key: '[ticketProperties].satisfaction.rating',
        name: TranslateService.get(`entities/ticket/satisfactionNote`),
      },
      {
        key: '[ticketProperties].satisfaction.commentary',
        name: TranslateService.get(`entities/ticket/satisfactionCommentary`),
      },
      {
        key: 'externalObjectId',
        name: TranslateService.get(`entities/request/externalObjectId`),
      },
    ];

    mentions.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return mentions;
  }

  /** Liste des champs de mention pour le corps */
  findMentionBody(): any {
    let mentions = this.findMentionSubject();
    mentions = [
      {
        key: 'created',
        name: TranslateService.get(`entities/incident/createdUTC`),
      },
      {
        key: '[ticketProperties].created',
        name: TranslateService.get(`entities/incident/created`),
      },
      {
        key: 'channel.name',
        name: TranslateService.get(`entities/channel/_title/singular`),
      },
      {
        key: 'description',
        name: TranslateService.get(`entities/incident/description`),
      },
      {
        key: 'ticketCategory.path',
        name: TranslateService.get(`entities/incident/categoryPath`),
      },
      {
        key: 'location.name',
        name: TranslateService.get(`entities/location/_title/singular`),
      },
      {
        key: 'organizationalUnit.name',
        name: TranslateService.get(
          `entities/organizationalUnit/_title/singular`,
        ),
      },
      {
        key: 'sla.name',
        name: TranslateService.get(`entities/incident/serviceLevelAgreement`),
      },
      {
        key: 'respondBefore',
        name: TranslateService.get(`entities/incident/respondBeforeUTC`),
      },

      {
        key: '[ticketProperties].respondBefore',
        name: TranslateService.get(`entities/incident/respondBefore`),
      },
      {
        key: 'resolveBefore',
        name: TranslateService.get(`entities/incident/resolveBeforeUTC`),
      },
      {
        key: '[ticketProperties].resolveBefore',
        name: TranslateService.get(`entities/incident/resolveBefore`),
      },
      {
        key: 'planned',
        name: TranslateService.get(`entities/incident/plannedUTC`),
      },
      {
        key: '[ticketProperties].planned',
        name: TranslateService.get(`entities/incident/planned`),
      },
      {
        key: 'estimate',
        name: TranslateService.get(`entities/incident/duration`),
      },
      {
        key: 'resolutionComment',
        name: TranslateService.get(`entities/incident/resolutionComment`),
      },
      {
        key: 'privateResolutionComment',
        name: TranslateService.get(
          `entities/incident/privateResolutionComment`,
        ),
      },
      {
        key: 'tags',
        name: TranslateService.get(`entities/incident/tags`),
      },
      {
        key: 'lastCommentStatusChange',
        name: TranslateService.get(`entities/incident/lastCommentStatusChange`),
      },
      {
        key: 'lastDateStatusChange',
        name: TranslateService.get(`entities/incident/lastDateStatusChange`),
      },

      {
        key: 'LastDateStatusChangeUTC',
        name: TranslateService.get(`entities/incident/lastDateStatusChangeUTC`),
      },
      {
        key: 'urlUserMakeRequest',
        name: TranslateService.get(`entities/incident/urlUserMakeRequest`),
      },
      {
        key: 'urlUserAffected',
        name: TranslateService.get(`entities/incident/urlUserAffected`),
      },
      {
        key: 'urlMainImpactedAsset',
        name: TranslateService.get(`entities/incident/urlMainImpactedAsset`),
      },
      {
        key: 'lastNote',
        name: TranslateService.get(`entities/incident/lastNote`),
      },
      {
        key: 'lastNoteOperator',
        name: TranslateService.get(`entities/incident/lastNoteOperator`),
      },
      {
        key: 'lastDateNote',
        name: TranslateService.get(`entities/incident/lastDateNote`),
      },
      {
        key: 'lastDateNoteUTC',
        name: TranslateService.get(`entities/incident/lastDateNoteUTC`),
      },
      {
        key: 'urlIncidentOperator',
        name: TranslateService.get(`entities/incident/urlIncidentOperator`),
      },
      {
        key: 'urlRawIncidentOperator',
        name: TranslateService.get(`entities/incident/urlRawIncidentOperator`),
      },
      {
        key: 'urlIncidentHelpMe',
        name: TranslateService.get(`entities/incident/urlIncidentHelpMe`),
      },
      {
        key: 'urlRawIncidentHelpMe',
        name: TranslateService.get(`entities/incident/urlRawIncidentHelpMe`),
      },
      {
        key: 'urlTicketMessagesHelpMe',
        name: TranslateService.get(`entities/ticket/helpMeTicketLink`),
      },
      {
        key: 'lastNoteAffectation',
        name: TranslateService.get(`entities/incident/lastNoteAffectation`),
      },
      {
        key: 'allUsersImpacted',
        name: TranslateService.get(`entities/incident/allUsersImpacted`),
      },
      {
        key: 'allAssetsImpacted',
        name: TranslateService.get(`entities/incident/allAssetsImpacted`),
      },
      {
        key: 'mainAssetImpacted',
        name: TranslateService.get(`entities/incident/mainAssetImpacted`),
      },
      {
        key: 'mainImpactedAsset.name',
        name: TranslateService.get(`entities/incident/mainAssetImpactedName`),
      },
      {
        key: 'mainImpactedAsset.manufacturer.name',
        name: TranslateService.get(
          `entities/incident/mainAssetImpactedManufacturer`,
        ),
      },
      {
        key: 'mainImpactedAsset.model.name',
        name: TranslateService.get(`entities/incident/mainAssetImpactedModel`),
      },
      {
        key: 'mainImpactedAsset.serialNumber',
        name: TranslateService.get(
          `entities/incident/mainAssetImpactedSerialNumber`,
        ),
      },
      {
        key: 'hasAttachments',
        name: TranslateService.get(`entities/incident/hasAttachments`),
      },
      {
        key: 'managerUserMakeRequest',
        name: TranslateService.get(`entities/incident/managerUserMakeRequest`),
      },
      {
        key: 'managerUserAffected',
        name: TranslateService.get(`entities/incident/managerUserAffected`),
      },
      {
        key: 'userMakeRequest.email',
        name: TranslateService.get(`entities/incident/userMakeRequestEmail`),
      },
      {
        key: '[ticketUserMakeRequestProperty].PrincipalIpPhone',
        name: TranslateService.get(`entities/incident/userMakeRequestVoIP`),
      },
      {
        key: '[ticketUserMakeRequestProperty].PrincipalMobile',
        name: TranslateService.get(`entities/incident/userMakeRequestMobile`),
      },
      {
        key: '[ticketUserMakeRequestProperty].PrincipalPhone',
        name: TranslateService.get(`entities/incident/userMakeRequestPhone`),
      },
      {
        key: 'operatorAffected.email',
        name: TranslateService.get(`entities/incident/operatorAffectedEmail`),
      },
      {
        key: '[ticketOperatorConnectedProperty].name',
        name: TranslateService.get(`entities/incident/operatorConnectedName`),
      },
      {
        key: '[ticketOperatorAffectedProperty].principalIpPhone',
        name: TranslateService.get(`entities/incident/operatorAffectedVoIP`),
      },
      {
        key: '[ticketOperatorAffectedProperty].principalMobile',
        name: TranslateService.get(`entities/incident/operatorAffectedMobile`),
      },
      {
        key: '[ticketOperatorAffectedProperty].principalPhone',
        name: TranslateService.get(`entities/incident/operatorAffectedPhone`),
      },

      {
        key: 'userAffected.email',
        name: TranslateService.get(`entities/incident/userAffectedEmail`),
      },
      {
        key: '[ticketUserAffectedProperty].principalIpPhone',
        name: TranslateService.get(`entities/incident/userAffectedVoIP`),
      },
      {
        key: '[ticketUserAffectedProperty].principalMobile',
        name: TranslateService.get(`entities/incident/userAffectedMobile`),
      },
      {
        key: '[ticketUserAffectedProperty].principalPhone',
        name: TranslateService.get(`entities/incident/userAffectedPhone`),
      },
      {
        key: '[ticketProperties].parent.title',
        name: TranslateService.get(`entities/ticket/parentTitle`),
      },
      {
        key: '[ticketProperties].parent.TicketNumber',
        name: TranslateService.get(`entities/ticket/parentTicketNumber`),
      },
      {
        key: 'urlParentTicketOperateur',
        name: TranslateService.get(`entities/ticket/urlParentTicket`),
      },
      {
        key: 'elaspedTime',
        name: TranslateService.get(`entities/ticket/elaspedTime`),
      },
      {
        key: 'elaspedTimeHour',
        name: TranslateService.get(`entities/ticket/elaspedTimeHour`),
      },
      {
        key: '[TicketOperatorTeamAffectedManagerProperty].principalIpPhone',
        name: TranslateService.get(
          `entities/incident/operatorTeamAffectedManagerMail`,
        ),
      },
      {
        key: '[TicketOperatorTeamReferentManagerProperty].principalIpPhone',
        name: TranslateService.get(
          `entities/incident/operatorTeamReferentManagerMail`,
        ),
      },
      ...mentions,
    ];
    mentions.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return mentions;
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('ticketNumber'),
        GqlField.create('id'),
        GqlSubField.create('userAffected', [
          GqlSubField.create('data', [GqlField.create('name')]),
        ]),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  lastTicketModelId: string;
  public manageTicketModel(
    ticketModelId,
    formComponent: CoreWorkFormComponent,
    qualification,
    sharedContext: ModelState,
    service,
  ) {
    if (this.lastTicketModelId === ticketModelId) return;
    if (!window.location.href.includes('/new')) return;

    this.lastTicketModelId = ticketModelId;
    let form = formComponent.form;
    let categoryId: string;
    let ticketCategoryField = FormGroupHelpers.formControlByName(
      form,
      'ticketCategoryId',
    );
    this.ticketModelService
      .get(
        [
          GqlSubField.create('data', [
            GqlField.create('id'),
            GqlField.create('impactId'),
            GqlField.create('urgencyId'),
            GqlField.create('priorityId'),
            GqlField.create('ticketCategoryId'),
            GqlField.create('resolutionCategoryId'),
            GqlField.create('operatorTeamReferentId'),
            GqlField.create('operatorReferentId'),
            GqlField.create('operatorTeamAffectedId'),
            GqlField.create('operatorAffectedId'),
            GqlField.create('tags'),
            GqlField.create('fileIds'),
            GqlField.create('customField1'),
            GqlField.create('customField2'),
            GqlField.create('customField3'),
            GqlField.create('customField4'),
            GqlField.create('customField5'),
            GqlField.create('levelSupport'),
            GqlSubField.create(
              'files',
              FileManagerCoreService.FileModelFields(),
            ),
            GqlSubField.create('title', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlSubField.create('description', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlSubField.create(
              'resolutionComment',
              this.translatedFieldHelperService.translatedFields(),
            ),
            GqlSubField.create(
              'privateResolutionComment',
              this.translatedFieldHelperService.translatedFields(),
            ),
            GqlField.create('operatorNewFormDesignerId'),
          ]),
        ],
        ticketModelId,
      )
      .subscribe(async (x) => {
        if (x.data != undefined || x.data != null) {
          if (
            x.data?.operatorNewFormDesignerId != undefined &&
            !window.location.href.includes(ticketModelId)
          ) {
            // Reload de la page si le model incident contient un formulaire perso
            let confirmMessage = await confirm(
              TranslateService.get('entities/ticket/loadFormIncidentModel'),
              TranslateService.get('globals/warning'),
            );
            if (confirmMessage === true) {
              formComponent.workItemForm.inClosed = true;
              // Reload page
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([window.location.pathname], {
                    queryParams: {
                      incidentModelId: ticketModelId,
                    },
                  });
                });
            } else {
              // Supprime le choix du model
              let incidentModelIdField = FormGroupHelpers.formControlByName(
                form,
                'incidentModelId',
              );
              if (incidentModelIdField != undefined) {
                incidentModelIdField.setValue(undefined);
              }
            }
            return;
          }

          categoryId = x.data.ticketCategoryId;

          this.fillFieldModel(
            form,
            'impactId',
            x.data?.impactId,
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'urgencyId',
            x.data.urgencyId,
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'priorityId',
            x.data.priorityId,
            sharedContext,
          );
          this.getPriorityByMatrixId(
            x.data.impactId,
            x.data.urgencyId,
            form,
            service,
            sharedContext,
          );
          if (
            ticketCategoryField != undefined &&
            (ticketCategoryField.value == undefined ||
              ticketCategoryField.visibled == false)
          ) {
            this.fillFieldModel(
              form,
              'ticketCategoryId',
              x.data.ticketCategoryId,
              sharedContext,
            );
          }

          this.fillFieldModel(
            form,
            'resolutionCategoryId',
            x.data.resolutionCategoryId,
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'operatorTeamReferentId',
            x.data.operatorTeamReferentId,
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'operatorReferentId',
            x.data.operatorReferentId,
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'operatorTeamAffectedId',
            x.data.operatorTeamAffectedId,
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'operatorAffectedId',
            x.data.operatorAffectedId,
            sharedContext,
          );

          if (x.data.levelSupport != undefined) {
            this.fillFieldModel(
              form,
              'levelSupport',
              x.data.levelSupport,
              sharedContext,
            );
          }

          this.fillFieldModel(form, 'tags', x.data.tags, sharedContext);
          this.fillFieldModel(
            form,
            'title',
            x.data.title[this.translatedFieldHelperService.getTranslateKey()],
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'description',
            x.data.description[
              this.translatedFieldHelperService.getTranslateKey()
            ],
            sharedContext,
          );

          // Customs
          this.fillFieldModel(
            form,
            'customField1',
            x.data.customField1,
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'customField2',
            x.data.customField2,
            sharedContext,
          );

          this.fillFieldModel(
            form,
            'customField3',
            x.data.customField3,
            sharedContext,
          );

          this.fillFieldModel(
            form,
            'customField4',
            x.data.customField4,
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'customField5',
            x.data.customField5,
            sharedContext,
          );

          // Pour incident
          this.fillFieldModel(
            form,
            'resolutionComment',
            this.translatedFieldHelperService.findValueToShow(
              x.data.resolutionComment,
            ),
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'privateResolutionComment',
            this.translatedFieldHelperService.findValueToShow(
              x.data.privateResolutionComment,
            ),
            sharedContext,
          );
          this.fillFieldModel(
            form,
            'fileIds',
            {
              itemsAdded: x.data.fileIds != undefined ? x.data.fileIds : [],
              itemsFilesAdded:
                x.data.files?.data != undefined ? x.data.files?.data : [],
              itemsRemoved: [],
              itemsUpdated: [],
            },
            sharedContext,
          );

          //Pour demande
          if (qualification == 'request') {
            //Pour demande
            this.fillFieldModel(
              form,
              'comment',
              this.translatedFieldHelperService.findValueToShow(
                x.data.resolutionComment,
              ),
              sharedContext,
            );
            this.fillFieldModel(
              form,
              'privateComment',
              this.translatedFieldHelperService.findValueToShow(
                x.data.privateResolutionComment,
              ),
              sharedContext,
            );
          }
        }
        if (categoryId != undefined) {
          await this.checkCategory(
            form,
            ticketCategoryField.value,
            qualification,
          );
        }
      });
  }

  public getPriorityByMatrix(impactField, urgencyField, form, service) {
    if (impactField != undefined && urgencyField != undefined) {
      let impactId = impactField.value;
      let urgencyId = urgencyField.value;
      this.getPriorityByMatrixId(impactId, urgencyId, form, service, undefined);
    }
  }

  private getPriorityByMatrixId(
    impactId,
    urgencyId,
    form,
    service,
    sharedContext,
  ) {
    if (impactId != null && urgencyId != null) {
      service
        .priorityByMatrix(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          urgencyId,
          impactId,
        )
        .subscribe((p) => {
          if (p != null && p.data != null) {
            setTimeout(() => {
              this.fillFieldModel(form, 'priorityId', p.data, sharedContext);
            }, 500);
          }
        });
    }
  }

  private fillFieldModel(form, name, value, state: ModelState) {
    if (
      value == undefined ||
      (value.trim != undefined && value.trim() === '')
    ) {
      return;
    }
    let field = FormGroupHelpers.formControlByName(form, name);
    if (field != undefined) {
      field.setValue(value);
      field.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      if (state?.sharedContext != undefined) {
        state.sharedContext.entry.set(name, () => value);
      }
    } else {
      if (state?.sharedContext != undefined) {
        state.sharedContext.entry.set(name, () => value);
      }
    }
  }

  public appointmentFields(): Array<GqlField | GqlSubField> {
    return [
      GqlSubField.create('data', [
        GqlField.create('allDay'),
        GqlField.create('description'),
        GqlField.create('endDate'),
        GqlField.create('endDateTimeZone'),
        GqlField.create('recurrenceException'),
        GqlField.create('recurrenceRule'),
        GqlField.create('startDate'),
        GqlField.create('startDateTimeZone'),
        GqlField.create('text'),
        GqlField.create('disabled'),
        GqlField.create('html'),
        GqlField.create('template'),
        GqlField.create('id'),
        GqlField.create('ticketId'),
        GqlField.create('color'),
        GqlField.create('operatorId'),
        GqlField.create('operatorTeamId'),
        GqlField.create('type'),
        GqlField.create('taskId'),
        GqlField.create('qualification'),
      ]),
    ];
  }

  public async checkCategory(
    form: UntypedFormGroup,
    ticketCategoryId: string,
    qualification: string,
  ) {
    let errors = {};
    if (ticketCategoryId != undefined) {
      let filter: FilterOfTicketCategory = {
        id: { eq: ticketCategoryId },
      };
      let CategoryControl = FormGroupHelpers.formControlByName(
        form,
        'ticketCategoryId',
      );
      let toto = await this.findTicketCategoryByPopulation(
        [
          GqlSubField.create('data', [
            GqlField.create('allowSelection'),
            GqlSubField.create('name', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlField.create('id'),
          ]),
        ],
        qualification,
        null,
        filter,
      ).toPromise();
      if (
        toto.data[0]?.allowSelection == undefined ||
        toto.data[0]?.allowSelection === false
      ) {
        setTimeout((_) => {
          errors['categoryNotValid'] = true;
          CategoryControl.setErrors(errors);
        }, 100);

        // if (CategoryControl.enabled) {
        //   CategoryControl.enable();
        // }
      } else {
        if (
          CategoryControl.errors != undefined &&
          CategoryControl.errors.length > 0
        ) {
          CategoryControl.setErrors([]);
          CategoryControl.updateValueAndValidity();
        }
      }
    }
  }

  public async ticketStates(): Promise<ticketState[]> {
    return [
      {
        name: 'new',
        route: 'new',
        translatedName: TranslateService.get(`globals/isNew`),
      },
      {
        name: 'in-progress',
        route: 'in-progress',
        translatedName: TranslateService.get(`globals/isOpen`),
      },
      {
        name: 'resolved',
        route: 'resolved',
        translatedName: TranslateService.get(`globals/processed`),
      },
      {
        name: 'closed',
        route: 'closed',
        translatedName: TranslateService.get(`globals/isClosed`),
      },
    ];
  }
  filesResolutionModel = [];
  public async setFilesResolutionModel(
    resolutionModelData,
    form,
    sharedContext: ModelState,
  ) {
    let oldResolutionModelId = resolutionModelData.oldValue;
    let resolutionModelId = resolutionModelData.value;
    if (oldResolutionModelId != undefined && oldResolutionModelId != null) {
      let resolutionModelData = this.filesResolutionModel.find(
        (x) => x['id'] == oldResolutionModelId,
      );
      let values = {
        itemsAdded: [],
        itemsFilesAdded: [],
        itemsRemoved: [],
        itemsUpdated: [],
      };
      if (resolutionModelData != undefined) {
        values.itemsAdded = resolutionModelData.fileIds;
        values.itemsFilesAdded = resolutionModelData.fileData;
        if (values.itemsAdded?.length > 0) {
          this.fillFieldFiles(form, values, sharedContext, false);
        }
      }
    }
    if (resolutionModelId != undefined && resolutionModelId != null) {
      let values = {
        itemsAdded: [],
        itemsFilesAdded: [],
        itemsRemoved: [],
        itemsUpdated: [],
      };
      let resolutionModelData = this.filesResolutionModel.find(
        (x) => x['id'] == resolutionModelId,
      );
      if (resolutionModelData != undefined) {
        values.itemsAdded = resolutionModelData.fileIds;
        values.itemsFilesAdded = resolutionModelData.fileData;
        if (values.itemsAdded?.length > 0) {
          this.fillFieldFiles(form, values, sharedContext, true);
        }
      } else {
        this.resolutionModelService
          .get(
            [
              GqlSubField.create('data', [
                GqlField.create('id'),
                GqlField.create('fileIds'),
                GqlSubField.create(
                  'files',
                  FileManagerCoreService.FileModelFields(),
                ),
              ]),
            ],
            resolutionModelId,
          )
          .subscribe(async (x) => {
            if (x.data?.fileIds != undefined) {
              values.itemsAdded = x.data?.fileIds;
              values.itemsFilesAdded = x.data.files?.data;
              this.filesResolutionModel.push({
                id: resolutionModelId,
                fileIds: x.data.fileIds,
                fileData: x.data.files?.data,
              });
              if (values.itemsAdded?.length > 0) {
                this.fillFieldFiles(form, values, sharedContext, true);
              }
            }
          });
      }
    }
  }
  public async setFiles(file, form, sharedContext: ModelState) {
    let values = {
      itemsAdded: [file['_tempFileId']?.data],
      itemsFilesAdded: [file],
      itemsRemoved: [],
      itemsUpdated: [],
    };
    this.fillFieldFiles(form, values, sharedContext, true);
  }
  private fillFieldFiles(form, values, sharedContext, added: boolean = true) {
    let control = FormGroupHelpers.formControlByName(form, 'fileIds');
    if (control.value.itemsFilesAdded == undefined) {
      control.value.itemsFilesAdded = [];
    }
    if (added) {
      control.value.itemsAdded.push(...values.itemsAdded);
      control.value.itemsAdded = [...new Set(control.value.itemsAdded)];
      control.value.itemsFilesAdded.push(...values.itemsFilesAdded);
      control.value.itemsFilesAdded = [
        ...new Set(control.value.itemsFilesAdded),
      ];
    } else {
      control.value.itemsAdded = control.value.itemsAdded.filter(
        (item) => !values.itemsAdded.includes(item),
      );
      control.value.itemsFilesAdded = control.value.itemsFilesAdded.filter(
        (item) => !values.itemsFilesAdded.includes(item),
      );
    }
    this.fillFieldModel(form, 'fileIds', control.value, sharedContext);
    control.markAsDirty();
  }

  public confirmMessageTransform(
    ticketType: string = 'incident',
    ticketCount: number = 0,
  ): string {
    let message: string = TranslateService.get(
      `entities/incident/transformToRequest`,
      {
        count: ticketCount,
      },
    );

    if (ticketType == 'request') {
      message = TranslateService.get(`entities/request/transformToIncident`, {
        count: ticketCount,
      });
    } else if (ticketType == 'ticket') {
      message = TranslateService.get(`entities/incident/transformTickets`);
    }

    return message;
  }

  public getConditionResearch(): Observable<any> {
    let getFields = [
      {
        id: 0,
        name: TranslateService.get('entities/incident/title'),
        type: 'Tickets',
      },
      {
        id: 1,
        name: TranslateService.get('entities/incident/description'),
        type: 'Tickets',
      },

      {
        id: 2,
        name: TranslateService.get('entities/warningMessage/_title/plural'),
        type: 'Message',
      },
    ];

    return of({ data: getFields, totalCount: getFields.length });
  }

  @InjectArgs
  public getCountElement(@Args('type') type: string) {
    switch (type) {
      case '':
      case 'open':
      case 'closed':
      case 'processed':
        return this.findAll([GqlField.create('totalCount')], type);
      case 'all':
        return this.findAll([GqlField.create('totalCount')], type);
      case 'my':
      case 'my-open':
      case 'my-closed':
      case 'my-processed':
        return this.findMyTicket([GqlField.create('totalCount')], type);
      case 'my-all':
        return this.findMyTicket([GqlField.create('totalCount')], type);
      case 'my-team':
      case 'my-team-open':
      case 'my-team-closed':
      case 'my-team-processed':
        return this.findMyTeamTicket([GqlField.create('totalCount')], type);
      case 'my-team-all':
        return this.findMyTeamTicket([GqlField.create('totalCount')], type);
    }
  }

  @InjectArgs
  public findEditInterventionFields(@Args('type') type: string) {
    return this.findInterventionFields(type, 'editRules');
  }

  @InjectArgs
  public findNewInterventionFields(@Args('type') type: string) {
    return this.findInterventionFields(type, 'newRules');
  }

  @InjectArgs
  public findNewAffectedFields(@Args('type') type: string) {
    return this.findAffectedFields(type, 'newRules');
  }
  @InjectArgs
  public findEditAffectedFields(@Args('type') type: string) {
    return this.findAffectedFields(type, 'editRules');
  }

  @InjectArgs
  public findAffectedFields(
    @Args('type') type: string,
    @Args('editType') editType: string,
  ): any[] {
    if (type === undefined || type == 'operator-incident') {
      IncidentHelpers.loadPage(modelIncidentEscalade.form.layout.pages);
      let res = this.modelRuleApplicationService.extracValueFromModel(
        modelIncidentEscalade as any,
        false,
        editType,
      );
      return res;
    } else if (type === 'operator-request') {
      RequestHelpers.loadPage(modelRequestEscalade.form.layout.pages);
      let res = this.modelRuleApplicationService.extracValueFromModel(
        modelRequestEscalade as any,
        false,
        editType,
      );
      return res;
    }
  }

  @InjectArgs
  public findInterventionFields(
    @Args('type') type: string,
    @Args('editType') editType: string,
  ): any[] {
    if (type === undefined || type == 'operator-incident') {
      IncidentHelpers.loadPage(modelIncidentIntervention.form.layout.pages);
      let res = this.modelRuleApplicationService.extracValueFromModel(
        modelIncidentIntervention as any,
        false,
        editType,
      );
      return res;
    } else if (type === 'operator-request') {
      RequestHelpers.loadPage(modelRequestIntervention.form.layout.pages);
      let res = this.modelRuleApplicationService.extracValueFromModel(
        modelRequestIntervention as any,
        false,
        editType,
      );
      return res;
    }
  }

  /** Permet de vérifie la correspondance du type du ticket avec l'url passer */
  @InjectArgs
  public hasCorrectType(@Args('id') id: string): Observable<boolean> {
    // uniquement test si Id passé et pas dans un cas de redirection
    //Check de l'URL
    if (id != undefined) {
      return this.get(
        [GqlSubField.create('data', [GqlField.create('__typename')])],
        id,
      ).pipe(
        map((r) => {
          let pathname = window.location.pathname;
          if (
            r?.data != undefined &&
            r?.data['__typename'] != undefined &&
            pathname.toLowerCase().indexOf(r.data['__typename'].toLowerCase()) <
              0
          ) {
            let type = 'requests';
            if (r?.data['__typename'] != 'Request') {
              type = 'incidents';
            }

            this.router.navigate([`/${type}/edit/` + id], {
              replaceUrl: true,
              //relativeTo: this.route.parent,
              queryParams: {
                redirectTicket: 'true',
              },
            });

            return true;
          }

          return false;
        }),
      );
    } else {
      return of(true);
    }
  }

  //Obtient ou définit si on doit rendre visible le bouton changer de nature
  public getCanChangeTicketTypeValue(): boolean {
    let canChangeTicketType: boolean =
      this.policyValidatorTicket.validate('incident') &&
      this.policyValidatorTicket.validate('request');
    return canChangeTicketType;
  }

  /**Obtient ou définit si le bouton d'affectation est visible */
  displayEscalationButton(
    rules: ModelRulesDesigner,
    id: string,
    escalationVisible: boolean,
  ) {
    let count: number = 0;
    let escalationRules = rules.escalationRules as Array<any>;
    if (id != undefined && id != null) {
      escalationRules = rules.escalationEditRules as Array<any>;
    }
    let visibilityField = this.countVisibilityField(escalationRules);
    count = visibilityField.count;
    if (escalationRules.length == count) {
      escalationVisible = false;
    }
    let taille = visibilityField.taille;
    return { escalationVisible, taille };
  }

  /**Compte le nombre de champs présents dans le formulaire (visible ou pas)*/
  private countVisibilityField(modelRule: Array<any>) {
    let count = 0;
    let taille = '90%';
    modelRule.map((element) => {
      let value = element as ModelRule;
      if (value.visible == false) {
        if (value.fieldName == 'description') {
          taille = '40%';
        }
        count++;
      }
    });

    // Gestion de la taille du formulaire
    if (count == 1) {
      if (taille != '40%') {
        taille = '85%';
      }
    }

    if (count > 1) {
      if (taille != '40%') {
        if (count != 4) taille = '77%';
      } else {
        taille = '33%';
        if (count == 2) {
          taille = '33%';
        }
        if (count == 3) {
          taille = '25%';
        }
      }
    }

    return { count, taille };
  }

  /** Obtient les règles (en création et/ou en édition) pour un champ spécifique */
  getSpecificRules(
    rules,
    formState: 'new' | 'edit' | 'all',
    specificFieldName: string,
  ) {
    let specificRules = {
      rules: rules?.rules.filter((x) => x.fieldName == specificFieldName)[0],
      editRules: rules?.editRules.filter(
        (x) => x.fieldName == specificFieldName,
      )[0],
    };

    switch (formState) {
      case 'new':
        return specificRules.rules;
      case 'edit':
        return specificRules.editRules;
      default:
        return specificRules;
    }
  }

  changeCallbackNumber = (
    res: any,
    callbackNumberField: any,
    defaultPhone: string,
    ifDefaultPhoneEmpty: string,
  ) => {
    let defaultPhoneValue = res?.data?.[defaultPhone];
    if (defaultPhoneValue != null && defaultPhoneValue != '') {
      callbackNumberField.setValue(defaultPhoneValue);
      callbackNumberField.markAsDirty();
    } else if (
      res?.data?.[ifDefaultPhoneEmpty] != null &&
      res?.data?.[ifDefaultPhoneEmpty] != ''
    ) {
      callbackNumberField.setValue(res?.data?.[ifDefaultPhoneEmpty]);
      callbackNumberField.markAsDirty();
    }
  };

  /** Gère le champ 'n° de téléphone de rappel' dans les forms des tickets */
  manageCallbackNumber(
    id: string,
    routePath: string,
    form: UntypedFormGroup,
    localStorageService: LocalStorageService,
    userService: UserCoreService,
    modelRuleQualification: 'incident' | 'request',
    isHelpDesk: boolean = false,
    modelRulesDesignerCoreService: ModelRulesDesignerCoreService = null,
    myOrganizationCoreService: MyOrganizationCoreService = null,
  ) {
    if (form != undefined && form != null) {
      let affectedUserField = FormGroupHelpers.formControlByName(
        form,
        'userAffectedId',
      );

      let callbackNumberFieldname = 'callbackNumber';

      if (affectedUserField != undefined) {
        let rulesPromise;

        if (!isHelpDesk) {
          if (modelRuleQualification == 'incident') {
            rulesPromise = localStorageService.user
              .getIncidentModelRule()
              .toPromise();
          } else {
            rulesPromise = localStorageService.user
              .getRequestModelRule()
              .toPromise();
          }
        } else {
          let type = `user-${modelRuleQualification}`;

          rulesPromise = modelRulesDesignerCoreService
            .find(modelRulesDesignerCoreService.getFields(), null, {
              and: [
                {
                  operatorIds: {
                    elemMatch: { in: [userService.getConnectedUser()] },
                  },
                },
                {
                  qualification: {
                    elemMatch: { in: [modelRuleQualification] },
                  },
                },
                { type: { eq: type } },
              ],
            })
            .toPromise();
        }

        rulesPromise.then((res) => {
          let rules;

          if (!isHelpDesk) {
            rules = JSON.parse(JSON.stringify(res?.data[0]));
          } else {
            rules = res?.data[0];
          }

          let callbackNumberField = FormGroupHelpers.formControlByName(
            form,
            callbackNumberFieldname,
          );

          let callbackNumberRule =
            id != null && routePath != 'new'
              ? this.getSpecificRules(rules, 'edit', callbackNumberFieldname)
              : this.getSpecificRules(rules, 'new', callbackNumberFieldname);

          if (callbackNumberRule?.visible) {
            if (myOrganizationCoreService != null) {
              let fieldsDefaultPhones = [
                GqlSubField.create('data', [
                  GqlField.create('id'),
                  GqlSubField.create('ticketGlobalSettings', [
                    GqlField.create('ifDefaultPhoneEmpty'),
                    GqlField.create('defaultPhone'),
                  ]),
                ]),
              ];

              let tenantId = this.authorizationCoreService.user.getTenantId();

              myOrganizationCoreService
                .find(fieldsDefaultPhones, null, {
                  and: [{ id: { eq: tenantId } }],
                })
                .subscribe((res) => {
                  let defaultPhone: string = '';
                  let ifDefaultPhoneEmpty: string = '';

                  let ticketGlobalSettings = res.data[0].ticketGlobalSettings;
                  defaultPhone = ticketGlobalSettings.defaultPhone;
                  ifDefaultPhoneEmpty =
                    ticketGlobalSettings.ifDefaultPhoneEmpty;

                  if (defaultPhone != null && defaultPhone != '') {
                    let defaultPhoneFields: GqlSubField[] = [];
                    if (
                      ifDefaultPhoneEmpty != null &&
                      ifDefaultPhoneEmpty != ''
                    ) {
                      defaultPhoneFields = [
                        GqlSubField.create('data', [
                          GqlField.create('id'),
                          GqlField.create(`${defaultPhone}`),
                          GqlField.create(`${ifDefaultPhoneEmpty}`),
                        ]),
                      ];
                    } else {
                      defaultPhoneFields = [
                        GqlSubField.create('data', [
                          GqlField.create('id'),
                          GqlField.create(`${defaultPhone}`),
                        ]),
                      ];
                    }

                    userService
                      .get(defaultPhoneFields, affectedUserField.value)
                      .subscribe((res) => {
                        if (
                          callbackNumberField.value == null ||
                          callbackNumberField.value == ''
                        ) {
                          this.changeCallbackNumber(
                            res,
                            callbackNumberField,
                            defaultPhone,
                            ifDefaultPhoneEmpty,
                          );
                        }
                      });

                    affectedUserField.valueChanges.subscribe((value) => {
                      if (affectedUserField.originalValue != value) {
                        userService
                          .get(defaultPhoneFields, value)
                          .subscribe((res) => {
                            this.changeCallbackNumber(
                              res,
                              callbackNumberField,
                              defaultPhone,
                              ifDefaultPhoneEmpty,
                            );
                          });
                      }
                    });
                  }
                });
            } else {
              let fields = [
                GqlSubField.create('data', [
                  GqlField.create('id'),
                  GqlField.create('name'),
                  GqlField.create('principalPhone'),
                ]),
              ];

              if (
                affectedUserField.value != null &&
                affectedUserField.value != undefined
              ) {
                userService
                  .get(fields, affectedUserField.value)
                  .subscribe((res) => {
                    if (
                      callbackNumberField.value == null ||
                      callbackNumberField.value == ''
                    ) {
                      callbackNumberField.setValue(res?.data?.principalPhone);
                      callbackNumberField.markAsDirty();
                    }
                  });
              }

              affectedUserField.valueChanges.subscribe((value) => {
                if (affectedUserField.originalValue != value) {
                  userService.get(fields, value).subscribe((res) => {
                    callbackNumberField.setValue(res?.data?.principalPhone);
                    callbackNumberField.markAsDirty();
                  });
                }
              });
            }
          }
        });
      }
    }
  }

  public async sharedModels(modelName: string) {
    switch (modelName) {
      case 'add-time':
        return await import(
          '@clarilog/modules2/ticket/pages/shared-models/add-time.model.json'
        );
      case 'add-time-mobile':
        return await import(
          '@clarilog/modules2/ticket/pages/shared-models/add-time-mobile.model.json'
        );
      default:
        return;
    }
  }

  getSpecificPrivilege(privilege: Privilege, privilegeEntry: string) {
    if (privilege != null && privilege != undefined) {
      return PrivilegeCoreService.hasPrivilege(privilege, privilegeEntry);
    }

    return false;
  }

  getLinkHelpMeTicket(origin, type, id) {
    let url = `${origin}/help-desk-${type}/my/edit/${id}`;
    var txtArea = document.createElement('textarea');
    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = url;
    document.body.appendChild(txtArea);
    txtArea.select();
    document.execCommand('copy');
    document.body.removeChild(txtArea);
  }

  recycleFromTicket(id: string, redirectUrl: string) {
    // Confirmation
    let recycleBody = 'confirm-recycle';
    let recycleTitle = 'confirm-recycle-title';

    let confirmMessage: Promise<boolean> = Promise.resolve(true);
    confirmMessage = confirm(
      TranslateService.get(recycleBody, { count: 1 }),
      TranslateService.get(recycleTitle),
    );

    // Suppression
    confirmMessage.then((result) => {
      if (result === true) {
        this.recycle(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          [id],
        ).subscribe((result) => {
          let typeError = 'error';

          if (result.data) {
            window.location.href = window.location.origin + redirectUrl;
            notify(TranslateService.get('deletedSuccess'), 'success', 5000);
            typeError = 'warning';
          }

          if (result.errors != undefined && result.errors.length > 0) {
            notify(result.errors[0].messageError, typeError, 5000);
          } else if (!result.data) {
            notify(TranslateService.get('deletedError'), typeError, 5000);
          }
        });
      }
    });
  }

  @InjectArgs
  public findResolutionModelPublic(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('ticketCategoryId?') ticketCategoryId?: string,
    @Args('resolutionCategoryId?') resolutionCategoryId?: string,
    @Args('qualification?') qualification?: string,
    @Args('options?') options?: QueryContextOfResolutionModel,
    @Args('filter?') filter?: FilterOfResolutionModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfResolutionModel> {
    return super.findResolutionModel(
      fields,
      'public',
      ticketCategoryId,
      resolutionCategoryId,
      qualification,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findResolutionModelPrivate(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('ticketCategoryId?') ticketCategoryId?: string,
    @Args('resolutionCategoryId?') resolutionCategoryId?: string,
    @Args('qualification?') qualification?: string,
    @Args('options?') options?: QueryContextOfResolutionModel,
    @Args('filter?') filter?: FilterOfResolutionModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfResolutionModel> {
    return super.findResolutionModel(
      fields,
      'private',
      ticketCategoryId,
      resolutionCategoryId,
      qualification,
      options,
      filter,
      extendedVariables,
    );
  }

  public getLinkToParentColumns() {
    return [
      {
        label: TranslateService.get('entities/incident/ticketNumber'),
        field: 'ticketNumber',
      },
      {
        label: TranslateService.get('entities/incident/created'),
        field: 'created',
      },
      {
        label: TranslateService.get('entities/incident/category'),
        field: 'ticketCategory.data.path',
        translatable: true,
      },
      {
        label: TranslateService.get('entities/incident/title'),
        field: 'title',
      },
      {
        label: TranslateService.get('entities/incident/userAffected'),
        field: 'userAffected.data.name',
      },
      {
        label: TranslateService.get('entities/incident/status'),
        field: 'status.data.name',
        translatable: true,
      },
      {
        label: TranslateService.get('entities/incident/impact'),
        field: 'impact.data.name',
        translatable: true,
      },
      {
        label: TranslateService.get('entities/incident/urgency'),
        field: 'urgency.data.name',
        translatable: true,
      },
      {
        label: TranslateService.get('entities/incident/operatorTeamAffectedTo'),
        field: 'operatorTeamAffected.data.name',
        translatable: true,
      },
      {
        label: TranslateService.get('entities/incident/operatorAffectedTo'),
        field: 'operatorAffected.data.name',
      },
      {
        label: TranslateService.get('entities/incident/resolveBefore'),
        field: 'resolveBefore',
      },
    ];
  }

  public getLinkToParentFields() {
    let columns = this.getLinkToParentColumns();
    let dataFields = [];
    dataFields.push(GqlField.create('id'));

    let separator = '.data.';
    for (let column of columns) {
      let columnField = column.field;

      if (columnField.includes(separator)) {
        let columnFields = columnField.split(separator);
        if (column.translatable) {
          dataFields.push(
            GqlSubField.create(columnFields[0], [
              GqlSubField.create('data', [
                GqlSubField.create(
                  columnFields[1],
                  this.translatedFieldHelperService.translatedFields(),
                ),
              ]),
            ]),
          );
        } else {
          dataFields.push(
            GqlSubField.create(columnFields[0], [
              GqlSubField.create('data', [GqlField.create(columnFields[1])]),
            ]),
          );
        }
      } else {
        if (column.translatable) {
          dataFields.push(
            GqlSubField.create(
              columnField,
              this.translatedFieldHelperService.translatedFields(),
            ),
          );
        } else {
          dataFields.push(GqlField.create(columnField));
        }
      }
    }

    return [
      GqlSubField.create('data', dataFields),
      GqlField.create('totalCount'),
    ];
  }
  /* Permet de récupérer l'équipe d'opérateur affecté au ticket selon l'utilisateur connecté*/
  interventionOperatorTeam(
    userService: UserCoreService,
    form: UntypedFormGroup,
    subForm: UntypedFormGroup,
    subModelState: ModelState,
  ) {
    let connectedUser = userService.getConnectedUser();
    if (form != null && form != undefined && subModelState?.id == null) {
      let operatorTeamAffectedId = FormGroupHelpers.formControlByName(
        form,
        'operatorTeamAffectedId',
      );
      let operatorTeamId = FormGroupHelpers.formControlByName(
        subForm,
        'operatorTeamId',
      );
      let interventionId = FormGroupHelpers.formControlByName(subForm, 'id');
      if (interventionId == undefined) {
        userService
          .get(
            [
              GqlSubField.create('data', [
                GqlField.create('name'),
                GqlField.create('operatorTeamIds'),
                GqlField.create('mainOperatorTeamId'),
                GqlField.create('supportLevelOperator'),
              ]),
            ],
            connectedUser,
          )
          .subscribe((res) => {
            if (res != null) {
              //équipe = équipe affectée au ticket seulement si l'opérateur connecté en fait partie
              if (
                res.data.operatorTeamIds.includes(operatorTeamAffectedId.value)
              ) {
                operatorTeamId?.setValue(operatorTeamAffectedId.value);
                operatorTeamId?.markAsDirty();
              }
              //sinon équipe = équipe principale de l'opérateur connecté
            }
          });
      }
    }
  }

  atlasIndexes() {
    let index = 0;
    return [
      {
        id: index++,
        value: TranslateService.get('entities/incident/title'),
        field: 'title',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/ticket/description'),
        field: 'description',
        type: 'tickets',
        isTranslateField: false,
      },

      {
        id: index++,
        value: TranslateService.get('entities/incident/resolutionPublic'),
        field: 'resolutionComment',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get(
          'entities/incident/privateResolutionComment',
        ),
        field: 'privateResolutionComment',
        type: 'tickets',
        isTranslateField: false,
      },

      {
        id: index++,
        value: TranslateService.get('entities/request/customField1'),
        field: 'customField1',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/request/customField2'),
        field: 'customField2',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/request/customField3'),
        field: 'customField3',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/request/customField4'),
        field: 'customField4',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/request/customField5'),
        field: 'customField5',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/incident/tags'),
        field: 'tags',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/incident/ticketNumber'),
        field: 'ticketNumber',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/incident/userAffected'),
        field: 'userAffected.name',
        type: 'tickets',
        isTranslateField: false,
      },
      {
        id: index++,
        value: TranslateService.get('entities/incident/userMakeRequest'),
        field: 'userMakeRequest.name',
        type: 'tickets',
        isTranslateField: false,
      },

      {
        id: index++,
        value: TranslateService.get('entities/incident/category'),
        field: 'ticketCategory.name',
        type: 'tickets',
        isTranslateField: false,
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }

  @InjectArgs
  findEntityType() {
    return of({
      data: [
        {
          name: 'Incident',
          id: 'Incident',
        },
        {
          name: 'Demande',
          id: 'Request',
        },
      ],
    });
  }

  @InjectArgs
  findAllTicketType() {
    return of({
      data: [
        {
          name: 'Incident',
          id: 'Incident',
        },
        {
          name: 'Demande',
          id: 'Request',
        },
        {
          name: 'Problème',
          id: 'Problem',
        },
      ],
    });
  }

  @InjectArgs
  findTags(
    @Args('qualification') qualification: string,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfTag,
    @Args('filter?') filter?: FilterOfTicketTag,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketTag> {
    if (
      qualification !== undefined &&
      (qualification.toLocaleLowerCase() == 'request' ||
        qualification.toLocaleLowerCase() == 'incident')
    ) {
      qualification = qualification.toPascalCase();
      let qualificationFilter: FilterOfTicketTag = {
        entityType: {
          eq: qualification,
        },
      };
      if (filter != undefined) {
        filter = {
          ...filter,
          and: [qualificationFilter],
        };
      } else {
        filter = qualificationFilter;
      }
    }

    // All
    return this.ticketTagService.find(
      fields,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public customFilterOperations(): CustomFilterOperation[] {
    let customSubstitueDataSource = [
      {
        id: 'substituteConnectedUser',
        name: TranslateService.get('clFilbuilder-substituteConnectedUser'),
      },
      {
        id: 'substituteNotConnectedUser',
        name: TranslateService.get('clFilbuilder-substituteNotConnectedUser'),
      },
    ];
    return [
      {
        name: 'customSubstitueValue',
        caption: TranslateService.get('clFilbuilder-customSubstitueValue'),
        icon: 'preferences',
        useDataFields: ['operatorReferent', 'operatorAffected'],
        hasValue: true,
        editorTemplate: 'customLookupSource',
        dataSource: customSubstitueDataSource,
        customizeText(field) {
          if (
            Array.isArray(field.value) === false ||
            field.value.length === 0
          ) {
          } else {
            let item = customSubstitueDataSource.find(
              (d) => d.id == field.value[0],
            );
            if (item != undefined) {
              return item.name;
            }
          }

          return 'Pas de valeur';
        },
      },
    ];
  }

  createDropdownButton(): { key: string; name: string }[] {
    return [
      {
        key: 'operator',
        name: TranslateService.get('entities/articleKnowledge/operatorLink'),
      },
      {
        key: 'help-me',
        name: TranslateService.get('entities/articleKnowledge/helpMeLink'),
      },
    ].sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * Permet d'appliquer les filtres sur les localisations et les organigrammes
   * @param element
   * @returns
   */
  applyArchitecturePathsTemplate(element: any) {
    // Location.path & OrganizationalUnit.Path
    if (element.field.includes('location.data.path')) {
      element['headerFilterLookup'] = {
        model: 'location',
        columnExp: 'locationId',
      };
      element['allowFiltering'] = true;
    }

    if (element.field.includes('organizationalUnit.data.path')) {
      element['headerFilterLookup'] = {
        model: 'organizational-unit',
        columnExp: 'organizationalUnitId',
      };
      element['allowFiltering'] = true;
    }

    return element;
  }
}

export function matcherRouteHelpMeFunction(url: UrlSegment[]) {
  if (url.length >= 1) {
    const path = url[0].path;
    if (
      path.startsWith('edit') ||
      path.startsWith('closed') ||
      path.startsWith('resolved') ||
      path.startsWith('in-progress') ||
      path.startsWith('open') ||
      path.startsWith('processed') ||
      path.startsWith('archived') ||
      path.startsWith('new-open')
    ) {
      return {
        consumed: url,
        posParams: {
          id: new UrlSegment(url[url.length - 1].path, {}),
        },
      };
    }
  }
  return null;
}
