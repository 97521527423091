<div
  class="item-field"
  [ngClass]="{
    'cl-control-form-designer-hover cl-action-form-designer-hover':
      designerMode,
  }"
>
  <div class="label">
    <span *ngIf="designerMode === true && label != undefined">
      <span
        class="cl-control-option-designer"
        style="color: red"
        *ngIf="_hasOptionDesigner?.required"
      >
        <i class="fa-solid fa-asterisk"></i>
      </span>
      <span
        class="cl-control-option-designer"
        style="color: var(--primary)"
        *ngIf="_hasOptionDesigner?.filter"
      >
        <i class="fa-solid fa-filter"></i>
      </span>

      <span
        class="cl-control-option-designer"
        *ngIf="_hasOptionDesigner?.designerReadOnly === true"
      >
        <i class="fa-solid fa-lock"></i>
      </span>
    </span>
    {{ label
    }}<span *ngIf="required && designerMode === false" class="required">*</span>
  </div>
  <div
    [ngClass]="{
      'cl-invalid': loaded === true && errors != undefined,
      value: true,
    }"
  >
    <ng-content></ng-content>
  </div>
  <div
    [ngClass]="{
      hint: hintVisibility == undefined || hintVisibility === 'NORMAL',
      hintfull: hintVisibility === 'FULL',
    }"
  >
    {{ hint }}
  </div>
  <ng-container *ngIf="loaded === true && label != undefined">
    <div class="errors" *ngFor="let error of errors | keyvalue">
      <cl-validation-error [error]="error"></cl-validation-error>
    </div>
  </ng-container>

  <div
    *ngIf="designerMode === true"
    class="cl-control-fill-form-designer cl-action-form-designer-hover"
  >
    <div *ngIf="designerMode" class="cl-action-form-designer">
      <div (click)="formAction($event, getAction.ADD_DOWN)">
        <i class="fa-solid fa-plus"></i>
      </div>
      <div
        class="cl-edit-form-designer"
        (click)="formAction($event, getAction.EDIT)"
      >
        <i class="fa-solid fa-pen"></i>
      </div>
      <div
        class="cl-delete-form-designer"
        (click)="formAction($event, getAction.REMOVE)"
      >
        <i class="fa-solid fa-trash-xmark"></i>
      </div>
    </div>
  </div>
</div>
