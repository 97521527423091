import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CoreModelCompilerService } from '@clarilog/shared2';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { CoreFormLoader } from '../form/work-form/form-loader';
import { CoreFormRecorder } from '../form/work-form/form-recorder';
import { FormGroupHelpers } from '@clarilog/shared2/components/form/work-form/form-group-helpers';
import { ServiceSingleResultOfBoolean } from '@clarilog/core/services2/graphql/generated-types/types';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'clc-drag-and-drop-massive',
  templateUrl: './drag-and-drop-massive.component.html',
  styleUrls: ['./drag-and-drop-massive.component.scss'],
})
export class DragAndDropMassiveComponent implements OnInit {
  /**Définit le service qui utilse le drag and drop */
  @Input() service: {
    drag: (
      fields: Array<GqlField | GqlSubField>,
      fromId: string,
      toId?: string,
    ) => Observable<ServiceSingleResultOfBoolean>;

    dragIds: (
      fields: Array<GqlField | GqlSubField>,
      fromId: string[],
      toId?: string,
    ) => Observable<ServiceSingleResultOfBoolean>;
  };

  /** Titre du popup de la fenêtre d'association. */
  @Input() titleAssociate: string;

  /**Pemert de valider le drag and drop et de rafrechier la liste */
  @Output() save: EventEmitter<string> = new EventEmitter();

  /**Permet de désactiver le button deplacer */
  @Input() disabled: boolean = false;

  /** Obtient ou définit le model */
  @Input() modelJson: any;

  @Input() selectedData: any;

  /** Definit la priorité du noeud parent sur un enfant */
  @Input() priorityForParent = false;

  /**Permet d'afficher le pop-up */
  isVisible: boolean = false;

  /**Permet de grisée ou pas le bouton deplacer de la pop-up */
  disabledButtonMove: boolean = true;
  modelState: ModelState;

  /**indicatif sur le traitement de la réquete */
  isWaiting: boolean = false;

  constructor(
    private modelCompilerService: CoreModelCompilerService,
    private loader: CoreFormLoader,
    private recoder: CoreFormRecorder,
  ) {}

  close() {
    this.isVisible = false;
  }

  ngOnInit(): void {}

  saving(e) {
    this.isWaiting = true;
    let update = this.recoder.materialize(this.modelState.form, false, true);
    let toId = update.toId;
    if (toId === '00000000-0000-0000-0000-000000000000') {
      toId = null;
    }

    //- Envoie l'ordre de rafraichir la liste
    console.log('this.selectedData', this.selectedData);
    let ids = this.selectedData?.map((element, index) => element.id);
    console.log('ids', ids);
    this.service.dragIds([GqlField.create('data')], ids, toId).subscribe(() => {
      this.isWaiting = false;
      this.save.emit();
      let toId = FormGroupHelpers.formControlByName(
        this.modelState.form,
        'toId',
      );
      toId.setValue(null);
    });
  }

  async ngAfterViewInit() {
    this.modelCompilerService
      .coreCompile(this.modelJson)
      .subscribe(async (model) => {
        this.modelState = model;
        await this.loader.create(this.modelState).then((response) => {
          this.modelState.form = response;
          let toId = FormGroupHelpers.formControlByName(
            this.modelState.form,
            'toId',
          );
          toId.valueChanges.subscribe((element) => {
            if (element !== null) {
              this.disabledButtonMove = false;
            }
          });
        });
      });
    this.modelState.sharedContext.params.set(
      'selectedKeys?',
      () => this.selectedData,
    );
  }
}
