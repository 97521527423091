import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPrinterLabelsBaseVariables, FirstPrinterLabelsBaseVariables, CountPrinterLabelsBaseVariables, FindPrinterLabelsBaseVariables, SearchPrinterLabelsBaseVariables, ExportSchemaPrinterLabelsBaseVariables, ExportDataPrinterLabelsBaseVariables, CustomQueryPrinterLabelsBaseVariables, CustomQueryIdPrinterLabelsBaseVariables, UsedPrinterLabelsBaseVariables, ExistPrinterLabelsBaseVariables, UpdateManyPrinterLabelsBaseVariables, DeletePrinterLabelsBaseVariables } from '../gqls'
import { GetPrinterLabelsDocument, FirstPrinterLabelsDocument, CountPrinterLabelsDocument, FindPrinterLabelsDocument, SearchPrinterLabelsDocument, ExportSchemaPrinterLabelsDocument, ExportDataPrinterLabelsDocument, CustomQueryPrinterLabelsDocument, CustomQueryIdPrinterLabelsDocument, UsedPrinterLabelsDocument, ExistPrinterLabelsDocument, UpdateManyPrinterLabelsDocument, DeletePrinterLabelsDocument } from '../gqls'
import { ServiceSingleResultOfPrinterLabel, QueryContextOfPrinterLabel, FilterOfPrinterLabel, ServiceSingleResultOfInt64, ServiceListResultOfPrinterLabel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FieldUpdateOperatorOfPrinterLabel } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PrinterLabelBaseService {
    
public modelName = 'printerLabel';
public modelPluralName = 'printerLabels';

	private getPrinterLabelsQuery: GetPrinterLabelsDocument;
	private firstPrinterLabelsQuery: FirstPrinterLabelsDocument;
	private countPrinterLabelsQuery: CountPrinterLabelsDocument;
	private findPrinterLabelsQuery: FindPrinterLabelsDocument;
	private searchPrinterLabelsQuery: SearchPrinterLabelsDocument;
	private exportSchemaPrinterLabelsQuery: ExportSchemaPrinterLabelsDocument;
	private exportDataPrinterLabelsQuery: ExportDataPrinterLabelsDocument;
	private customQueryPrinterLabelsQuery: CustomQueryPrinterLabelsDocument;
	private customQueryIdPrinterLabelsQuery: CustomQueryIdPrinterLabelsDocument;
	private usedPrinterLabelsQuery: UsedPrinterLabelsDocument;
	private existPrinterLabelsQuery: ExistPrinterLabelsDocument;
	private updateManyPrinterLabelsMutation: UpdateManyPrinterLabelsDocument;
	private deletePrinterLabelsMutation: DeletePrinterLabelsDocument;

	constructor(private injector: Injector) {
		this.getPrinterLabelsQuery = this.injector.get(GetPrinterLabelsDocument);
		this.firstPrinterLabelsQuery = this.injector.get(FirstPrinterLabelsDocument);
		this.countPrinterLabelsQuery = this.injector.get(CountPrinterLabelsDocument);
		this.findPrinterLabelsQuery = this.injector.get(FindPrinterLabelsDocument);
		this.searchPrinterLabelsQuery = this.injector.get(SearchPrinterLabelsDocument);
		this.exportSchemaPrinterLabelsQuery = this.injector.get(ExportSchemaPrinterLabelsDocument);
		this.exportDataPrinterLabelsQuery = this.injector.get(ExportDataPrinterLabelsDocument);
		this.customQueryPrinterLabelsQuery = this.injector.get(CustomQueryPrinterLabelsDocument);
		this.customQueryIdPrinterLabelsQuery = this.injector.get(CustomQueryIdPrinterLabelsDocument);
		this.usedPrinterLabelsQuery = this.injector.get(UsedPrinterLabelsDocument);
		this.existPrinterLabelsQuery = this.injector.get(ExistPrinterLabelsDocument);
		this.updateManyPrinterLabelsMutation = this.injector.get(UpdateManyPrinterLabelsDocument);
		this.deletePrinterLabelsMutation = this.injector.get(DeletePrinterLabelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPrinterLabel> {
    
    		let variables: GetPrinterLabelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.get));
            }
            else{
                let result:ServiceSingleResultOfPrinterLabel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPrinterLabel,
		@Args('filter?') filter?: FilterOfPrinterLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPrinterLabel> {
    
    		let variables: FirstPrinterLabelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPrinterLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPrinterLabelsBaseVariables = {
    			filter: filter
    		}
    				return this.countPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPrinterLabel,
		@Args('filter?') filter?: FilterOfPrinterLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrinterLabel> {
    
    		let variables: FindPrinterLabelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPrinterLabel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrinterLabel> {
    
    		let variables: SearchPrinterLabelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPrinterLabelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPrinterLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPrinterLabelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPrinterLabel,
		@Args('filter?') filter?: FilterOfPrinterLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrinterLabel> {
    
    		let variables: CustomQueryPrinterLabelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPrinterLabel,
		@Args('filter?') filter?: FilterOfPrinterLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrinterLabel> {
    
    		let variables: CustomQueryIdPrinterLabelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPrinterLabelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPrinterLabelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPrinterLabelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.exist));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfPrinterLabel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyPrinterLabelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyPrinterLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeletePrinterLabelsBaseVariables = {
    			ids: ids
    		}
    				return this.deletePrinterLabelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.printerLabels.delete));
    	}
    
}