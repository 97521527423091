import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonComponentOptions } from '@clarilog/shared2/models';

@Component({
  selector: 'clc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonComponent),
      multi: true,
    },
  ],
})
export class ButtonComponent {
  @Input() state: ModelState;

  /** Obtient ou définit le status du readonly */
  @Input() options: ButtonComponentOptions;

  /** Obtient ou définit le status du readonly */
  @Input() readOnly: boolean = false;

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */

  disabled: boolean = false;

  private _value: string;
  @Input()
  public get value(): string {
    return this._value;
  }
  public set value(value: string) {
    this._value = value;
  }

  @Output() onValueChanged = new EventEmitter();

  constructor() {}
  /** @inheritdoc */
  public writeValue(value: any) {
    this.value = value;
  }
  /** @inheritdoc */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngAfterViewInit(): void {}

  onButtonClick(e) {
    let key =
      this.options?.eventName != undefined
        ? this.options?.eventName
        : 'buttonKeyEvent';

    this.state.on.emit({
      eventName: key,
      eventData: {
        value: this.value,
      },
    });
  }
}
