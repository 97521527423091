import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ModelDataSourceContext,
  ModelFnContext,
} from '@clarilog/shared2/services/compiler/model-state';
import { Observable } from 'rxjs';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import {
  IncidentModelCoreService,
  TicketCategoryCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { Column } from 'devextreme/ui/data_grid';
import { Router, RouterFeature } from '@angular/router';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '@clarilog/core/services/environment/environment.service';
import path from 'path';
import { optionName } from 'devexpress-dashboard/viewer-parts/index.internal';
import {
  Sort,
  TicketCategory,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { DxSelectBoxComponent } from 'devextreme-angular';
/** Représente le composant de catalog de service. */
@Component({
  selector: 'cl-service-catalog',
  templateUrl: './service-catalog.component.html',
  styleUrls: ['./service-catalog.component.scss'],
})
export class ServiceCatalogComponent implements OnInit {
  /** Obtient ou définit la source des categories. */
  @Input() ticketCategoriesSourcee: ModelDataSourceContext;
  @ViewChild('selectBox', { static: false }) selectBox!: DxSelectBoxComponent;

  categories: any;
  allCategories: any;
  lang: any;
  ticketModelSource: any;
  ticketCategoriesSource: any;
  allTicketCategoriesSource: any;
  ticketModelColumns: Column;
  selectedTicketCategory: TicketCategory;
  router: Router;
  myTheme: any;
  titleHeader: any;
  breadCrumb: any = [];
  visibleBack: boolean;
  gridTicketModel: any;
  _envService: any;
  _paginate = 0;
  _isLoad = false;
  parameter: string;
  // Tooltip
  mouseX: number = 0; // Coordonnées X de la souris
  mouseY: number = 0; // Coordonnées Y de la souris
  hoveredCategory: any = null; // Variable pour stocker la catégorie en survol

  constructor(
    translatedService: TranslatedFieldHelperService,
    private incidentModelCoreService: IncidentModelCoreService,
    ticketCategoriesSource: TicketCategoryCoreService,

    authorizationService: AuthorizationCoreService,
    router: Router,
    _envService: EnvironmentService,
  ) {
    this._envService = _envService;
    this.myTheme = authorizationService.user.getCustomization();
    this.router = router;
    this.lang = translatedService.getTranslateKey();
    this.parameter = "path."+this.lang;
    this.getTicketCategorySource(ticketCategoriesSource);

    this.getTicketModelSource(incidentModelCoreService);
    this.visibleBack = false;
  }

  /** @inheritdoc */
  ngOnInit() {
    this.ticketCategoriesSource.load();
    this.allTicketCategoriesSource.load();

  }

  getTicketCategorySource(ticketCategoriesSource: TicketCategoryCoreService) {
    let fieldCategories = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create('name', [GqlField.create(this.lang)]),
        GqlSubField.create('path', [GqlField.create(this.lang)]),
        GqlField.create('fileId'),
        GqlField.create('parentId'),
        GqlField.create('numberOfChildren'),
        GqlSubField.create('description', [GqlField.create(this.lang)]),
      ]),
    ];
    this.ticketCategoriesSource = CoreGraphQLSource.create({
      query: (filter?: any, options?: any) => {
        if (this.selectedTicketCategory?.id != undefined) {
          filter = {
            parentId: { eq: this.selectedTicketCategory.id },
          };
        } else {
          filter = { parentId: { eq: null } };
        }

        if (this._paginate <= 0) this._paginate = 0;

        let sortName = {};
        sortName[this.lang] = Sort.Asc;
        options.sort = [{ name: [sortName] }];
        options.limit = 20;
        options.skip = options.limit * this._paginate;

        return ticketCategoriesSource
          .findTicketCategoryByPopulationWithModel(
            fieldCategories,
            options,
            filter,
          )
          .pipe(
            map((result) => {
              if (this.categories == undefined || this._paginate == 0)
                this.categories = [];
              let categories = result.data;

              categories.forEach((f) => {
                this.categories.push(f);
              });
              this._isLoad = false;
              return result;
            }),
          );
      },
    
    });

    this.allTicketCategoriesSource = CoreGraphQLSource.create({
      query: (filter?: any, options?: any) => {
        if (this._paginate <= 0) this._paginate = 0;
        filter = null;

        let sortPath = {};
        sortPath[this.lang] = Sort.Asc;
        options.sort = [{ path: [sortPath] }];

        return ticketCategoriesSource
          .findTicketCategoryByPopulationWithModel(
            fieldCategories,
            options,
            filter,
          )
          .pipe(
            map((result) => {
              if (this.allCategories == undefined || this._paginate == 0)
                this.allCategories = [];
              let allCategories = result.data;
              allCategories.forEach((f) => {
                this.allCategories.push(f);
              });
              this._isLoad = false;
              return result;
            }),
          );
      },
    });
  }

  selectBoxValueChanged(e) {
    let selectedCategory = this.allCategories.find(cat => cat.id === e.value);
    if(selectedCategory != null){
      let currentCategory = selectedCategory;
      this.breadCrumb = [];
      while (currentCategory?.parentId !== null) {
          if(currentCategory?.numberOfChildren != 0) {
            this.breadCrumb.push(currentCategory);
          }
          currentCategory = this.allCategories.find(cat => cat.id === currentCategory.parentId);
          if(currentCategory?.parentId == null) {
            this.breadCrumb.push(currentCategory);
          }
      }
      
      this.breadCrumb.reverse();      
      this.selectCategoryItemFromSelectBox(selectedCategory);
  }
}

  selectCategoryItemFromSelectBox(e) {
    if (e.numberOfChildren > 0) {
      this.updateTitleHeader();
      this.selectedTicketCategory = e;
      this.refreshDataSource();
    } else {
      let selectedParentCategory = this.allCategories.find(cat => cat.id === e.parentId);
      this.selectedTicketCategory = selectedParentCategory;
      this.refreshDataSource();
      this.updateChildrenTitleHeader(e);
      this.selectedTicketCategory = e;
      this.gridTicketModel.refresh();
    } 
  }

  getTicketModelSource(incidentModelCoreService: IncidentModelCoreService) {
    let fieldTicketModel = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('userEditFormDesignerId'),
        GqlField.create('id'),
        GqlSubField.create('modelName', [GqlField.create(this.lang)]),
      ]),
    ];
    this.ticketModelSource = CoreGraphQLSource.create({
      query: (filter?: any, options?: any) => {
        return incidentModelCoreService.findByCategoryId(
          fieldTicketModel,
          options,
          this.selectedTicketCategory?.id,
          filter,
        );
      },
    });
  }

  getDisplayTicketModel(field) {
    return field;
  }

  selectCategoryItem(e) {
    this._paginate = 0;
    if (e.numberOfChildren > 0) {
      this.breadCrumb.push(e);
      this.updateTitleHeader();
      this.selectedTicketCategory = e;
      this.refreshDataSource();
    } else {
      this.updateChildrenTitleHeader(e);
      this.selectedTicketCategory = e;
      this.gridTicketModel.refresh();
    }
    if (this.selectBox) {
      this.selectBox.instance.option('value', null);
    }
  }

  selectTicketModelChanged(e) {
    this.incidentModelCoreService
      .get(
        [
          GqlSubField.create('data', [
            GqlField.create('qualification'),
            GqlField.create('id'),
          ]),
        ],
        e.data.id,
      )
      .subscribe((response) => {
        const qualification = response.data.qualification;
        this.router.navigate(
          [
            qualification.includes('request')
              ? '/help-desk-request/my/new'
              : '/help-desk-incident/my/new',
          ],
          {
            queryParams: {
              incidentModelId: e.data.id,
            },
          },
        );
      });
  }

  async backItem() {
    this._paginate = 0;
    this.breadCrumb.pop();
    this.updateTitleHeader();
    this.selectedTicketCategory = this.breadCrumb.slice()[0];
    this.refreshDataSource();
  }

  refreshDataSource() {
    this.ticketCategoriesSource.load();
    this.gridTicketModel.refresh();
  }

  updateTitleHeader() {
    if (this.breadCrumb != undefined && this.breadCrumb.length > 0) {
      let names = this.breadCrumb.map((x) => x.name[this.lang]);
      this.titleHeader = names.join(' > ');
      this.visibleBack = true;
    } else {
      this.titleHeader = '';
      this.visibleBack = false;
    }
  }

  updateChildrenTitleHeader(item) {
    let path = '';
    if (this.breadCrumb != undefined && this.breadCrumb.length > 0) {
      path = this.breadCrumb.map((x) => x.name[this.lang]).join(' > ') + ' > ';
    }
    this.titleHeader = path + item.name[this.lang];
    this.visibleBack = true;
  }

  initGridTicketModel(e) {
    this.gridTicketModel = e.component;
  }

  getUrlFile(fileId) {
    return `${this._envService.apiURL}/file/get/${fileId}`;
  }

  hintPopover(data) {
    if (data.description != undefined && data.description != '') {
      this.hoveredCategory = data;
    }
  }

  // Met à jour les coordonnées de la souris pour positionner le tooltip
  updateTooltipPosition(event: MouseEvent) {
    this.mouseX = event.clientX; // Coordonnées X de la souris
    this.mouseY = event.clientY; // Coordonnées Y de la souris
  }
  showPopup(category, event: MouseEvent) {
    if (category.description[this.lang] != '') {
      this.updateTooltipPosition(event);
      this.hintPopover(category);
    }
  }

  hidePopup() {
    this.hoveredCategory = null;
  }

  onScroll(e) {
    if (!this._isLoad) {
      if (e.reachedBottom == true) {
        this._isLoad = true;
        // reload
        this._paginate++;
        this.refreshDataSource();
      }
    }
  }
}
