<!-- Sert pour le debug du composant -->
<!-- <dx-button title="TOTO" (onClick)="totoClick()"></dx-button> -->
<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loadPanelShow"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
<dx-gantt
  taskListWidth="700"
  scaleType="days"
  taskContentTemplate="taskContentTemplate"
  [ngClass]="viewGantt ? 'visibleGantt' : 'hiddenGantt'"
  [(selectedRowKey)]="selectedRowKey"
  (onCustomCommand)="onCustomCommand($event)"
  (onContextMenuPreparing)="onContextMenuPreparing($event)"
  height="100%"
  (onTaskDblClick)="onTaskDblClick($event)"
  (onTaskEditDialogShowing)="onTaskEditDialogShowing($event)"
  taskTooltipContentTemplate="taskTooltipContentTemplate"
  (onContentReady)="onContentReady($event)"
>
  <dxo-toolbar *ngIf="canVisible">
    <dxi-item
      location="before"
      widget="dxDropDownButton"
      [disabled]="this.readOnly === true"
      [visible]="canAdd"
      [options]="{
        text: 'new' | translate,
        items: buttonsSource,
        displayExpr: 'value',
        keyExpr: 'name',
        splitButton: true,
        dropDownOptions: {
          disabled: selectedRowKey == undefined || this.readOnly === true,
          width: 200
        },
        useSelectMode: false,
        onButtonClick: onButtonClick
      }"
    >
    </dxi-item>

    <dxi-item
      *ngIf="canEdit"
      [disabled]="selectedRowKey == undefined"
      [visible]="!this.readOnly"
      widget="dxButton"
      [options]="buttonOptionsEdit"
    >
    </dxi-item>
    <dxi-item
      *ngIf="canRemove"
      [disabled]="selectedRowKey == undefined || this.readOnly === true"
      [visible]="!this.readOnly"
      widget="dxButton"
      [options]="buttonOptionsDelete"
    >
    </dxi-item>

    <dxi-item
      widget="dxButton"
      [options]="buttonOptionsTaskSequence"
      [disabled]="this.readOnly === true"
      [visible]="canAdd && canAddTaskSequence"
    >
    </dxi-item>
  </dxo-toolbar>

  <div
    *dxTemplate="let item of 'taskContentTemplate'"
    class="custom-task"
    style.width="{{ item.taskSize.width }}px"
  >
    <div class="custom-task-wrapper">
      <div class="custom-task-title">
        {{ getTranslateTitleValue(item.taskData.title) }}
      </div>
    </div>
  </div>
  <dxo-tasks
    [dataSource]="currentSource.datasource"
    [startExpr]="startExpr"
    [endExpr]="endExpr"
  ></dxo-tasks>

  <dxi-column
    *ngFor="let column of columns"
    [dataField]="column.field"
    [caption]="column.label"
    [format]="column.format"
    [name]="column.label + column.field"
    [dataType]="column.dataType"
    [groupIndex]="column.groupIndex"
    [fixed]="column.fixed"
    [sortIndex]="column.sortIndex"
    [sortOrder]="column.sortOrder"
    [minWidth]="column.minWidth !== undefined && column.minWidth != 'auto'? column.minWidth :80"
    [allowResizing]="column.allowResizing"
    [allowExporting]="
      column.allowExporting != undefined ? column.allowExporting : true
    "
    [fixedPosition]="column.fixedPosition"
    [filterValue]="column.filterValue"
    [visible]="column.visible != undefined ? column.visible : true"
    [showInColumnChooser]="true"
    [allowSorting]="column.allowSorting"
    [allowFiltering]="true"
    [showInColumnChooser]="
      column.showInColumnChooser != undefined
        ? column.showInColumnChooser
        : true
    "
    [allowGrouping]="column.allowGrouping"
    [allowEditing]="
      column.allowEditing != undefined ? column.allowEditing : false
    "
    [cellTemplate]="
      column.template == undefined && column.dataType == 'boolean'
        ? 'booleanTemplate'
        : column.template != undefined
        ? column.template
        : column.field == '__typename'
        ? column.link == true
          ? 'linkTypeNameTemplate'
          : 'typeNameTemplate'
        : column.link == true
        ? 'underlineTemplate'
        : column.template == undefined && column.dataType == undefined
        ? 'customCellTemplate'
        : ''
    "
    [groupCellTemplate]="
      column.groupTemplate != undefined
        ? column.groupTemplate
        : column.field == '__typename'
        ? 'typeNameTemplate'
        : 'groupCellTemplate'
    "
  >
    <dxo-lookup
      *ngIf="column.lookup != undefined"
      [dataSource]="column.lookup.source"
      [valueExpr]="column.lookup.valueExpr"
      [displayExpr]="column.lookup.displayExpr"
    >
    </dxo-lookup>
    <div
      *dxTemplate="let data of column.template"
      [ngClass]="getRowClass(data.data)"
    >
      <ng-template
        *ngTemplateOutlet="
          templateService.get(column.template);
          context: {
            $implicit: data,
            column: column,
            component: this
          }
        "
      >
      </ng-template>
    </div>
  </dxi-column>
  <!-- Template de base isGreyList -->
  <div
    *dxTemplate="let data of 'customCellTemplate'"
    [ngClass]="getRowClass(data.data)"
  >
    {{ data.value }}
  </div>
  <div
    *dxTemplate="let task of 'taskTooltipContentTemplate'"
    class="custom-task-edit-tooltip"
  >
    <div class="custom-tooltip-title">
      {{ getTranslateTitleValue(task.title) }}
    </div>

    <table style="border: none">
      <tr>
        <td>
          <span>{{ 'globals/start' | translate }}:</span>
        </td>
        <td>{{ getTime(task.scheduleAt) }}</td>
      </tr>
      <ng-container *ngIf="task.endAt > task.scheduleAt">
        <tr>
          <td>
            <span>{{ 'globals/end' | translate }}:</span>
          </td>
          <td>{{ getTime(task.endAt) }}</td>
        </tr>
      </ng-container>
    </table>
  </div>

  <dxo-context-menu>
    <dxi-item
      [visible]="!(selectedRowKey == undefined)"
      [disabled]="this.canAdd === false"
      [text]="'ganttNew' | translate"
    >
      <dxi-item
        name="addTaskUp1"
        [text]="'ganttNewTaskUp' | translate"
      ></dxi-item>
      <dxi-item
        name="addTaskDown1"
        [text]="'ganttNewTaskDown' | translate"
      ></dxi-item>
      <dxi-item
        [disabled]="selectedRowKey == undefined"
        name="addSubTask1"
        [text]="'ganttNewSubTask' | translate"
      >
      </dxi-item>
      <dxi-item
        [disabled]="selectedRowKey == undefined"
        name="addSuccessor1"
        [text]="'ganttNewSucessor' | translate"
      >
      </dxi-item>
    </dxi-item>
    <dxi-item
      [visible]="selectedRowKey == undefined"
      [disabled]="this.canAdd === false"
      name="addTask1"
      [text]="'ganttNewTask' | translate"
    ></dxi-item>

    <dxi-item
      [visible]="!(selectedRowKey == undefined) && this.canEdit === true"
      [text]="'ganttAction' | translate"
    >
      <dxi-item name="moveUp1" [text]="'ganttMoveUp' | translate"></dxi-item>
      <dxi-item
        name="moveDown1"
        [text]="'ganttMoveDown' | translate"
      ></dxi-item>
    </dxi-item>
    <dxi-item
      [disabled]="selectedRowKey == undefined || this.canEdit === false"
      name="editTask1"
      [text]="'ganttEditTask' | translate"
    >
    </dxi-item>
    <dxi-item
      [disabled]="selectedRowKey == undefined || this.canRemove === false"
      name="deleteTask1"
      [text]="'ganttDeleteTask' | translate"
    >
    </dxi-item>
    <dxi-item
      [disabled]="url == false || this.canAdd === false"
      name="addTaskSequence1"
      [text]="'ganttAddTaskSequence' | translate"
    >
    </dxi-item>
  </dxo-context-menu>

  <dxo-editing [enabled]="false"></dxo-editing>
</dx-gantt>

<clc-work-sub-form
  [rawModel]="rawModel"
  [rules]="rules"
  [parentModel]="state"
  [(isPopupOpen)]="isPopupOpen"
  [service]="service"
  (onSaved)="saved($event)"
  [fieldName]="fieldName"
  [key]="key"
  [(title)]="title"
></clc-work-sub-form>

<dx-popup
  [width]="800"
  [height]="'80%'"
  [showTitle]="false"
  #popup
  class="add-item-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="popupVisible"
>
  <clc-select-list
    [title]="'globals/addToTaskSequence' | translate"
    [buttonText]="'globals/select' | translate"
    [source]="taskSequenceSource"
    [route]="'/administration/service-desk/task-sequence'"
    [columns]="taskSequenceTypeColumns"
    (onClosed)="popupVisible = false"
    (onSelect)="onSelect($event)"
    [multiple]="false"
  >
  </clc-select-list>
</dx-popup>
