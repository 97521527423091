import { Component } from '@angular/core';

/** Représente la page de non autorisé. */
@Component({
  selector: 'cl-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.scss'],
})
export class UnauthorizedComponent {
  constructor() {
    let viewHM = localStorage.getItem('viewHelpMe');
    if (viewHM != undefined) {
      localStorage.removeItem('viewHelpMe');
    }
  }
}
