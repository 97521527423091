import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  CompareOptions,
  Control,
  Validator,
} from '@clarilog/shared2/models/schema';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CallbackFunction } from '../../../services/compiler/model-compiler-context.service';
import { TranslateService } from '../../../services/translate/translate.service';
import { TranslatedFieldHelperService } from '../../translate-field';
import { FormGroupHelpers } from './form-group-helpers';

/** Représente les validateurs custom de clarilog. */
export class CustomValidators {
  /**
   * Expression régulière pour valider une Adresse IP
   */
  private static ipPattern =
    /^(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|(?=$))){4}$/;

  /** Permet de tester unicité de la valeur. */
  public static unique(
    name: string,
    fnContext: ModelFnContext,
    isTranlate: boolean,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (
        control.value == undefined ||
        (control.isTranslatable !== true &&
          control.value.trim != undefined &&
          control.value.trim() == '')
      ) {
        return of(null);
      } else {
        fnContext.context.params.set('excludeId', () =>
          fnContext.rootState.sharedContext.params.get('id'),
        );
        let fieldName = name;
        let value = control.value;
        if (control.isTranslatable === true || isTranlate) {
          let transKey = fnContext.rootState.sharedContext.params.get(
            TranslatedFieldHelperService.ModelStateLanguageKey,
          );
          fieldName = name + '.' + transKey;
          value = value[transKey];

          if (
            value == undefined ||
            value == '' ||
            (value.trim != undefined && value.trim() == '')
          ) {
            return of(null);
          }
        }

        fnContext.context.params.set('fieldName', () => fieldName);
        if (value != undefined) {
          if (value.trim != undefined) {
            fnContext.context.params.set('fieldValue', () => value.trim());
          } else {
            fnContext.context.params.set('fieldValue', () => value);
          }
        }

        return fnContext.fnCall().pipe(
          map((r) => {
            if ((r as any).data === false) {
              return null;
            } else {
              return <ValidationErrors>{ unique: false };
            }
          }),
        );
      }
    };
  }
  /**
   * ce validateur fonctionne de facon generique, il prend en paramettre un service sur lequel on va chercher dynamiquemeent la method oneOrMore
   * Attention a son implementation sur le service
   * @param validator
   * @param fnContext
   * @param fn
   * @returns
   */
  public static oneOrMoreAsync(
    service: any,
    rootState: ModelState,
    controlType: string = undefined,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      let checkUndefined = true;
      if (
        controlType == 'TicketEmailListComponent' ||
        controlType == 'MessageTicketComponent'
      ) {
        checkUndefined = false;
      }

      if (control.value == undefined && checkUndefined) {
        return of(null);
      } else {
        return service['oneOrMore'](rootState, control).pipe(
          map((s: any) => {
            if (s === true) {
              return null;
            } else {
              return <ValidationErrors>{ oneOrMore: true };
            }
          }),
        );
      }
    };
  }

  public static hierarchicalUnique(
    name: string,
    validator: Validator,
    fnContext: ModelFnContext,
    fn: CallbackFunction<any>,
    isTranlate: boolean,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (
        control.value == undefined ||
        (control.isTranslatable !== true &&
          control.value.trim != undefined &&
          control.value.trim() == '')
      ) {
        return of(null);
      } else {
        fnContext.context.params.set('excludeId', () =>
          fnContext.rootState.sharedContext.params.get('id'),
        );

        let fieldName = name;
        let value = control.value;
        if (control.isTranslatable === true || isTranlate) {
          let transKey = fnContext.rootState.sharedContext.params.get(
            TranslatedFieldHelperService.ModelStateLanguageKey,
          );
          fieldName = name + '.' + transKey;
          value = value[transKey];

          if (
            value == undefined ||
            value == '' ||
            (value.trim != undefined && value.trim() == '')
          ) {
            return of(null);
          }
        }

        fnContext.context.params.set('fieldName', () => fieldName);
        if (value?.trim != undefined) {
          fnContext.context.params.set('fieldValue', () => value.trim());
        } else {
          fnContext.context.params.set('fieldValue', () => value);
        }
        fnContext.context.params.set(
          'parentFieldName',
          () => validator.parameterName,
        );
        fnContext.context.params.set('parentId', () =>
          fnContext.rootState.sharedContext.entry.get(validator.parameterName),
        );

        return fnContext.fnCall().pipe(
          map((r) => {
            if (r.data === false) {
              return null;
            } else {
              return <ValidationErrors>{ unique: false };
            }
          }),
          finalize(() => {
            fnContext.context.params.remove('excludeId');
            fnContext.context.params.remove('fieldName');
            fnContext.context.params.remove('fieldValue');
            fnContext.context.params.remove('parentFieldName');
            fnContext.context.params.remove('parentId');
          }),
        );
      }
    };
  }

  /**Permet de vérifier que la saisie n'est pas vide ou null*/
  public static notNullOrEmpty(
    modelState: ModelState,
    formControl: Control,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (Array.isArray(control.value) === true) {
        const values = control.value as Array<string>;

        if (values.length === 0) {
          return { notNullOrEmpty: true };
        }

        for (const value of control.value) {
          if (value != undefined && value.trim() === '') {
            return { notNullOrEmpty: true };
          }
        }
        return null;
      }

      // validation si translate particulier (cas tache d'un ticket)
      let isTranlated = control.isTranslatable;
      if (
        isTranlated == undefined &&
        control.value != undefined &&
        typeof control.value == 'object' &&
        control.value['fr'] != undefined
      ) {
        isTranlated = true;
      }

      // Test de valeur
      if (
        isTranlated === true &&
        control.value != undefined &&
        typeof control.value == 'object'
      ) {
        if (modelState?.sharedContext != undefined) {
          let lang = modelState.sharedContext.params.get(
            TranslatedFieldHelperService.ModelStateLanguageKey,
          );
          if (
            control.value[lang] == undefined ||
            (control.value[lang].trim != undefined &&
              control.value[lang].trim() === '')
          ) {
            return { notNullOrEmpty: true };
          }

          return null;
        }
      }

      // autre cas string
      if (
        control.value == undefined ||
        (control.value.trim != undefined && control.value.trim() === '')
      )
        return { notNullOrEmpty: true };

      // Valuer du timespan
      if (formControl?.type == 'TimeSpanComponent') {
        if (
          control.value == 'PT0M0S' ||
          control.value == 'P0DT0H0M0S' ||
          control.value == 'PT0S'
        ) {
          return { notNullOrEmpty: true };
        }
      }

      // Valuer du timespan
      if (formControl?.type == 'NumberBoxComponent') {
        if (control.value == '0' || control.value == 0) {
          return { notNullOrEmpty: true };
        }
      }

      return null;
    };
  }

  /**Permet de vérifier que la license a été coché.*/
  public static requiredLicense(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == undefined || control.value.accept != true) {
        return { requiredLicense: true };
      }
      return null;
    };
  }

  /**Permet de vérifier que la saisie est du type Adresse IP*/
  public static ipAddress(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value != undefined && control.value.trim() != '') {
        if (!CustomValidators.ipPattern.test(control.value))
          return { ipAddress: true };
      }

      return null;
    };
  }

  /**Permet de vérifier que la saisie est du type Date*/
  public static dateTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value != undefined && control.value.trim() != '') {
        if (!CustomValidators.ipPattern.test(control.value))
          return { ipAddress: true };
      }

      return null;
    };
  }

  /** Convertion d'une Adresse IP en Nombre. */
  private static ipAddresToint(ip): Number {
    if (ip && ip.match(CustomValidators.ipPattern)) {
      return (
        ip.split('.').reduce(function (ipInt, octet) {
          return (ipInt << 8) + parseInt(octet, 10);
        }, 0) >>> 0
      );
      // var parts = ip.split('.').map(function (str) {
      //   return parseInt(str);
      // });

      // return (
      //   (parts[0] ? parts[0] << 24 : 0) +
      //   (parts[1] ? parts[1] << 16 : 0) +
      //   (parts[2] ? parts[2] << 8 : 0) +
      //   parts[3]
      // );
    }

    return -1;
  }

  /** Permet de comparer 2 champs. */
  public static compare(options: CompareOptions): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == undefined || control.value === '') return null;
      let controls = FormGroupHelpers.formControls(control.root);
      if (controls.some((c) => c.key === options.fieldName)) {
        let targetControl = controls.filter(
          (c) => c.key === options.fieldName.replace(/\./g, '_'),
        )[0];
        targetControl.value.unsubscribers =
          targetControl.value.unsubscribers || {};
        if (targetControl.value.unsubscribers[options.fieldName] != undefined) {
          targetControl.value.unsubscribers[options.fieldName].unsubscribe();
        }
        targetControl.value.unsubscribers[options.fieldName] =
          targetControl.value.valueChanges.subscribe((_) => {
            control.updateValueAndValidity();
          });
        let result;
        let targetValue = targetControl.value.value;
        let value = control.value;
        if (options.converter == 'IpAddress') {
          targetValue = this.ipAddresToint(targetValue);
          value = this.ipAddresToint(value);
        }
        if (options.converter == 'DateTime') {
          if (value != undefined) {
            if (value.toISOString != undefined) {
              value = value.toISOString();
            }
          }
        }
        if (options.converter == 'DateTime') {
          if (targetValue != undefined) {
            if (targetValue.toISOString != undefined) {
              targetValue = targetValue.toISOString();
            }
          }
        }

        switch (options.operator) {
          case 'Equal':
            result = targetValue === value;
            break;
          case 'NotEqual':
            result = targetValue !== value;
            break;
          case 'LessThan':
            result = targetValue > value;
            break;
          case 'LessThanOrEqual':
            result = targetValue >= value;
            break;
          case 'GreaterThan':
            result = targetValue < value;
            break;
          case 'GreaterThanOrEqual':
            result = targetValue <= value;
            break;
        }
        if (result === true) {
          return null;
        }
        return {
          compare: {
            ...options,
            label: (<AbstractControl & { label: string }>(
              (<unknown>targetControl.value)
            )).label,
          },
        };
      }
      return null;
    };
  }

  /**Permet de vérifier que la saisie est valid et retourn un message*/
  public static CustomPattern(
    customPattern: string,
    customMessage: string,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && !control.value.match(customPattern)) {
        return { invalidMsg: TranslateService.get(customMessage) };
      }
      return null;
    };
  }

  /**Permet de vérifier que la saisie de la matrice*/
  public static requiredMatrix(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == undefined || control.value == null) {
        return { requiredMatrix: true };
      }
      return null;
    };
  }

  /** Permet de vérifier que la totalité des emails saisis est valide, et retourne un message d'erreur dans le cas contraire */
  public static emailArray(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (Array.isArray(control.value) === true) {
        const values = control.value as Array<string>;

        let allEmailsAreValid = true;

        for (const value of values) {
          let valueCtrl = new FormControl(value, Validators.email);
          allEmailsAreValid = allEmailsAreValid && valueCtrl.valid;
        }

        if (!allEmailsAreValid) {
          return { invalidMsg: TranslateService.get(errorMessage) };
        }
      }

      return null;
    };
  }
}
