import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { DynamicPropertyFieldBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlSubField } from '../helpers';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { of } from 'rxjs';
import { camelCase } from 'lodash';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['asset.manage-dynamic-property', 'help-desk-operator.manage-dynamic-property'],
  operator: 'or',
})

export class DynamicPropertyFieldCoreService extends DynamicPropertyFieldBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  /** Element par défaut de type ticket */
  private ticketElement(type: string) {
    return {
      name: type,
      rootType: 'Ticket',
      translatedName: TranslateService.get(
        'entities/' + camelCase(type) + '/_title/singular',
      ),
      urlLinkManage: 'administration/service-desk',
      invisibleControls: [
        'dynamicPropertyFormOption.visible',
        'dynamicPropertyFormOption.required',
      ],
    };
  }

  /** Obtient les types d'entité autorisé pour la création de champs dynamique */
  entityTypes() {
    return [
      {
        name: 'Asset',
        translatedName: TranslateService.get(`entities/asset/_title/singular`),
        rootType: 'Asset',
        urlLinkManage: 'assets/settings',
        invisibleControls: [],
      },
      this.ticketElement('Problem'),
      this.ticketElement('Incident'),
      this.ticketElement('Request'),
    ];
  }

  /** Obtient les nom par defaut des type possible en fonction du datatype url */
  @InjectArgs
  getDefaultValues(@Args('types') types: string[]) {
    return this.entityTypesByRoute(types).map((t) => t.name);
  }
  /** Obtient les type possible en fonction du datatype url */
  @InjectArgs
  entityTypesByRoute(@Args('types') types: string[]) {
    if (types == undefined) return [];
    let allTypes = this.entityTypes();
    let filter = allTypes.filter(
      (d) => types.find((g) => g == d.name) != undefined,
    );
    return filter;
  }

  /** Obtient l'url de gestion de la liste de valeur */
  @InjectArgs
  urlLinkManage(@Args('types') types: string[], @Args('url') url: string) {
    if (types == undefined) return '';
    let allTypes = this.entityTypes();
    let filter = allTypes.filter(
      (d) => types.find((g) => g == d.name) != undefined,
    );
    if (filter.length > 0) {
      return filter[0].urlLinkManage + '/' + url;
    }
    return '';
  }

  /** Obtient l'url de gestion de la liste de valeur */
  @InjectArgs
  urlLinkManageList(@Args('types') types: string[]) {
    return of(this.urlLinkManage(types, 'dynamic-property-list'));
  }

  /** Obtient l'url de gestion de la liste de valeur */
  @InjectArgs
  urlLinkManageGroup(@Args('types') types: string[]) {
    return of(this.urlLinkManage(types, 'dynamic-property-group'));
  }

  /** Obtient l'url de gestion des propriété */
  @InjectArgs
  urlLinkManageField(@Args('types') types: string[]) {
    return of(this.urlLinkManage(types, 'dynamic-property-field'));
  }

  @InjectArgs
  defaultEntityTypeByRoute(@Args('types') types: string[]) {
    let allTypes = this.entityTypes();
    let filter = allTypes.filter(
      (d) => types.find((g) => g == d.name) != undefined,
    );
    if (filter != undefined && filter.length > 1) {
      return filter[0].rootType;
    } else if (filter != undefined && filter.length == 1) {
      return filter[0].name;
    }
    return '';
  }

  /** Obtient le field générique des propriété dynamique */
  getFields(): (GqlSubField | GqlField)[] {
    let langName = GqlSubField.create(
      'name',
      this.translatedFieldHelperService.translatedFields(),
    );

    return [
      langName,
      GqlField.create('dynamicPropertyFieldType'),
      GqlField.create('propertyName'),
      GqlField.create('entityTypes'),
      GqlField.create('position'),
      GqlSubField.create('dynamicPropertyFormOption', [
        GqlField.create('visible'),
        GqlField.create('required'),
      ]),
      GqlSubField.create('dynamicPropertyColumnOption', [
        GqlField.create('sortable'),
        GqlField.create('filterable'),
        GqlField.create('showInColumnChooser'),
        GqlField.create('visible'),
      ]),
      GqlField.create('dynamicPropertyGroupId'),
      GqlSubField.create('dynamicPropertyGroup', [
        GqlSubField.create('data', [
          langName,
          GqlField.create('dynamicPropertyLocation'),
          GqlField.create('position'),
          GqlField.create('useFreeSpace'),
          GqlField.create('collapse'),
        ]),
      ]),
      GqlSubField.create('dynamicPropertyNumericFormat', [
        GqlField.create('dynamicPropertyNumericFormat'),
        GqlField.create('minimun'),
        GqlField.create('minimun'),
        GqlField.create('dynamicPropertyNumericFormat'),
      ]),
      GqlSubField.create('dynamicPropertyDateTime', [
        GqlField.create('dynamicPropertyDateFormat'),
      ]),
      GqlSubField.create('dynamicPropertySelectList', [
        GqlField.create('dynamicPropertyListId'),
      ]),
      GqlSubField.create('dynamicPropertyColumnOption', [
        GqlField.create('sortable'),
        GqlField.create('filterable'),
        GqlField.create('showInColumnChooser'),
        GqlField.create('template'),
      ]),
    ];
  }
}
