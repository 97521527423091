<cl-form-designer-popup
  (saveEvent)="popupSave($event)"
></cl-form-designer-popup>

<cl-work-page
  (keydown)="onKeyDown($event)"
  tabindex="0"
  *ngIf="resetComponent == false"
>
  <!--  <cl-work-page-header>-->
  <!--    <cl-work-page-header-content>-->
  <!--      <cl-work-page-header-content-title-->
  <!--        [showBack]="false"-->
  <!--      >-->
  <!--        <span>-->
  <!--          Designer formulaire-->
  <!--        </span>-->
  <!--      </cl-work-page-header-content-title>-->
  <!--      <cl-work-page-header-content-body>-->
  <!--      </cl-work-page-header-content-body>-->
  <!--    </cl-work-page-header-content>-->
  <!--  </cl-work-page-header>-->
  <cl-work-page-left>
    <clc-nav-menu
      *ngIf="resetNavMenu === false"
      [modelState]="modelState"
      [designerMode]="true"
    >
      <clc-nav-group
        *ngFor="let item of items"
        [text]="item.title"
        [state]="modelState"
        [designerMode]="true"
        [designerId]="item['id']"
      >
        <clc-nav-menu-item
          *ngFor="let child of item.items"
          [text]="child.title"
          [icon]="child.icon"
          [active]="child === currentItem"
          (onClick)="selectionChanged(child)"
          [designerMode]="true"
          [designerId]="child['id']"
          [modelState]="modelState"
          [options]="child"
          [isRootItem]="item.title === undefined"
        ></clc-nav-menu-item>
      </clc-nav-group>
    </clc-nav-menu>
  </cl-work-page-left>
  <cl-work-page-content *ngIf="isInit === true">
    <div class="work-item-container" [formGroup]="formGroup">
      <!-- Template pour afficher 1 host -->
      <ng-template #hostTemplate let-item let-fg="fg" let-parent="parent">
        <div
          *ngIf="item.items == undefined"
          [ngClass]="{ 'work-item-content-hide': item !== activeItem?.item }"
          class="work-item-content"
        >
          <div
            *ngIf="fg.get(item.name) != undefined"
            [formGroup]="fg.get(item.name)"
          >
            <ng-template clc-work-item-host></ng-template>
          </div>
        </div>
        <div
          *ngIf="
            item.title != undefined && item.items != undefined as value;
            else noTitle
          "
          [formGroup]="fg.get(item.name)"
        >
          <ng-container
            *ngTemplateOutlet="
              hostsTemplate;
              context: {
                $implicit: item.items,
                fg: fg.get(item.name),
                parent: item,
              }
            "
          >
          </ng-container>
        </div>
        <ng-template #noTitle>
          <ng-container
            *ngTemplateOutlet="
              hostsTemplate;
              context: { $implicit: item.items, fg: fg }
            "
          >
          </ng-container>
        </ng-template>
      </ng-template>
      <!-- Template pour afficher les hosts -->
      <ng-template #hostsTemplate let-items let-fg="fg" let-parent="parent">
        <ng-container
          *ngFor="let item of items"
          [ngTemplateOutlet]="hostTemplate"
          [ngTemplateOutletContext]="{
            $implicit: item,
            fg: fg,
            parent: parent,
          }"
        ></ng-container>
      </ng-template>
      <!-- Applique un template pour les items -->
      <ng-container
        *ngTemplateOutlet="
          hostsTemplate;
          context: { $implicit: items, fg: formGroup }
        "
      ></ng-container>
    </div>
  </cl-work-page-content>
</cl-work-page>
<!-- <dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="!formGroup.root.loaded"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel> -->
