import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetRecipientBouncesBaseVariables, FirstRecipientBouncesBaseVariables, CountRecipientBouncesBaseVariables, FindRecipientBouncesBaseVariables, SearchRecipientBouncesBaseVariables, ExportSchemaRecipientBouncesBaseVariables, ExportDataRecipientBouncesBaseVariables, CustomQueryRecipientBouncesBaseVariables, CustomQueryIdRecipientBouncesBaseVariables, UsedRecipientBouncesBaseVariables, ExistRecipientBouncesBaseVariables, InsertRecipientBouncesBaseVariables, UpdateRecipientBouncesBaseVariables, ImportDataRecipientBouncesBaseVariables, UpdateManyRecipientBouncesBaseVariables, DeleteRecipientBouncesBaseVariables } from '../gqls'
import { GetRecipientBouncesDocument, FirstRecipientBouncesDocument, CountRecipientBouncesDocument, FindRecipientBouncesDocument, SearchRecipientBouncesDocument, ExportSchemaRecipientBouncesDocument, ExportDataRecipientBouncesDocument, CustomQueryRecipientBouncesDocument, CustomQueryIdRecipientBouncesDocument, UsedRecipientBouncesDocument, ExistRecipientBouncesDocument, InsertRecipientBouncesDocument, UpdateRecipientBouncesDocument, ImportDataRecipientBouncesDocument, UpdateManyRecipientBouncesDocument, DeleteRecipientBouncesDocument } from '../gqls'
import { ServiceSingleResultOfRecipientBounce, QueryContextOfRecipientBounce, FilterOfRecipientBounce, ServiceSingleResultOfInt64, ServiceListResultOfRecipientBounce, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, RecipientBounceInput, FieldUpdateOperatorOfRecipientBounce } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class RecipientBounceBaseService {
    
public modelName = 'recipientBounce';
public modelPluralName = 'recipientBounces';

	private getRecipientBouncesQuery: GetRecipientBouncesDocument;
	private firstRecipientBouncesQuery: FirstRecipientBouncesDocument;
	private countRecipientBouncesQuery: CountRecipientBouncesDocument;
	private findRecipientBouncesQuery: FindRecipientBouncesDocument;
	private searchRecipientBouncesQuery: SearchRecipientBouncesDocument;
	private exportSchemaRecipientBouncesQuery: ExportSchemaRecipientBouncesDocument;
	private exportDataRecipientBouncesQuery: ExportDataRecipientBouncesDocument;
	private customQueryRecipientBouncesQuery: CustomQueryRecipientBouncesDocument;
	private customQueryIdRecipientBouncesQuery: CustomQueryIdRecipientBouncesDocument;
	private usedRecipientBouncesQuery: UsedRecipientBouncesDocument;
	private existRecipientBouncesQuery: ExistRecipientBouncesDocument;
	private insertRecipientBouncesMutation: InsertRecipientBouncesDocument;
	private updateRecipientBouncesMutation: UpdateRecipientBouncesDocument;
	private importDataRecipientBouncesMutation: ImportDataRecipientBouncesDocument;
	private updateManyRecipientBouncesMutation: UpdateManyRecipientBouncesDocument;
	private deleteRecipientBouncesMutation: DeleteRecipientBouncesDocument;

	constructor(private injector: Injector) {
		this.getRecipientBouncesQuery = this.injector.get(GetRecipientBouncesDocument);
		this.firstRecipientBouncesQuery = this.injector.get(FirstRecipientBouncesDocument);
		this.countRecipientBouncesQuery = this.injector.get(CountRecipientBouncesDocument);
		this.findRecipientBouncesQuery = this.injector.get(FindRecipientBouncesDocument);
		this.searchRecipientBouncesQuery = this.injector.get(SearchRecipientBouncesDocument);
		this.exportSchemaRecipientBouncesQuery = this.injector.get(ExportSchemaRecipientBouncesDocument);
		this.exportDataRecipientBouncesQuery = this.injector.get(ExportDataRecipientBouncesDocument);
		this.customQueryRecipientBouncesQuery = this.injector.get(CustomQueryRecipientBouncesDocument);
		this.customQueryIdRecipientBouncesQuery = this.injector.get(CustomQueryIdRecipientBouncesDocument);
		this.usedRecipientBouncesQuery = this.injector.get(UsedRecipientBouncesDocument);
		this.existRecipientBouncesQuery = this.injector.get(ExistRecipientBouncesDocument);
		this.insertRecipientBouncesMutation = this.injector.get(InsertRecipientBouncesDocument);
		this.updateRecipientBouncesMutation = this.injector.get(UpdateRecipientBouncesDocument);
		this.importDataRecipientBouncesMutation = this.injector.get(ImportDataRecipientBouncesDocument);
		this.updateManyRecipientBouncesMutation = this.injector.get(UpdateManyRecipientBouncesDocument);
		this.deleteRecipientBouncesMutation = this.injector.get(DeleteRecipientBouncesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRecipientBounce> {
    
    		let variables: GetRecipientBouncesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.get));
            }
            else{
                let result:ServiceSingleResultOfRecipientBounce={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRecipientBounce,
		@Args('filter?') filter?: FilterOfRecipientBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRecipientBounce> {
    
    		let variables: FirstRecipientBouncesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRecipientBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecipientBouncesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRecipientBounce,
		@Args('filter?') filter?: FilterOfRecipientBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRecipientBounce> {
    
    		let variables: FindRecipientBouncesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfRecipientBounce,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRecipientBounce> {
    
    		let variables: SearchRecipientBouncesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaRecipientBouncesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRecipientBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataRecipientBouncesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfRecipientBounce,
		@Args('filter?') filter?: FilterOfRecipientBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRecipientBounce> {
    
    		let variables: CustomQueryRecipientBouncesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfRecipientBounce,
		@Args('filter?') filter?: FilterOfRecipientBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRecipientBounce> {
    
    		let variables: CustomQueryIdRecipientBouncesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedRecipientBouncesBaseVariables = {
    			ids: ids
    		}
    				return this.usedRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistRecipientBouncesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existRecipientBouncesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: RecipientBounceInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRecipientBounce> {
    
    		let variables: InsertRecipientBouncesBaseVariables = {
    			entity: entity
    		}
    				return this.insertRecipientBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfRecipientBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRecipientBounce> {
    
    		let variables: UpdateRecipientBouncesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateRecipientBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataRecipientBouncesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataRecipientBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfRecipientBounce,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyRecipientBouncesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyRecipientBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteRecipientBouncesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteRecipientBouncesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.recipientBounces.delete));
    	}
    
}