import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TemplatesService } from '../../templates/templates.service';
import {
  ActivatedRoute,
  NavigationExtras,
  Router,
  UrlTree,
} from '@angular/router';
import { TranslateService } from '@clarilog/shared/services';

/** Représente la classe du composent clc-visible-selectable-link-entity. */
@Component({
  selector: 'clc-visible-selectable-link-entity',
  templateUrl: './visible-selectable-link-entity.component.html',
  styleUrls: ['./visible-selectable-link-entity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreVisibleSelectableLinkEntityComponent),
      multi: true,
    },
  ],
})
export class CoreVisibleSelectableLinkEntityComponent
  implements ControlValueAccessor, OnInit
{
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  /** Obtient ou définit les options */
  @Input() options: any;
  /** Obtient ou définit le modelState */
  @Input() state: ModelState;
  /** Obtient ou définit la source */
  @Input() source: any;

  /* Obtient ou définit les éléments ajoutés. */
  @Input() itemsAdded: any[] = [];
  /** Obtient ou définit les éléments supprimés. */
  @Input() itemsRemoved: any[] = [];

  @Input() entityType:
    | 'LOCATION'
    | 'TICKET_CATEGORY'
    | 'ORGANIZATIONAL_UNIT'
    | 'OPERATOR-TEAM';

  /** Definit l'etat des check */
  checkVisibled: any;
  checkAllowed: any;

  /** Valeur d'origine */
  originalValue: any;

  constructor(
    public templateService: TemplatesService,
    private router: Router,
    public _route: ActivatedRoute,
  ) {}

  /** @inheritdoc */
  writeValue(values: any[]): void {
    this.originalValue = values;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @inheritdoc */
  ngOnInit() {
    this.refresh();
  }

  /** Actualisation */
  refresh() {
    let dataSource = (<ModelDataSourceContext>(<unknown>this.options.source))
      .datasource;
    dataSource.pageSize(500000);
    dataSource.load().then((results) => {
      this.source = results;
      this.onChange(this.originalValue);
      this.onTouched();
    });
  }

  /** Création du toolbar */
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'before',
      options: {
        text: TranslateService.get('manage'),
        icon: 'fas fa-arrow-right',
        hint: this.options?.route,
        visible: this.options?.route != undefined,
        onClick: () => {
          this.onGoTo();
        },
      },
    });
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'refresh',
        hint: TranslateService.get('globals/refresh'),
        onClick: () => {
          this.refresh();
        },
      },
    });

    e.toolbarOptions.items.unshift({
      widget: 'dxCheckBox',
      cssClass: 'cl-checkbox-style',
      location: 'after',
      options: {
        text: TranslateService.get('globals/allowView'),
        rtlEnabled: true,
        onInitialized: (ec) => {
          this.checkAllowed = ec.component;
        },
        onValueChanged: (args) => {
          this.source.forEach((f) => {
            switch (this.entityType) {
              case 'LOCATION':
                f.locationAllowed = args.value;
                break;
              case 'ORGANIZATIONAL_UNIT':
                f.organizationalUnitAllowed = args.value;
                break;
              case 'TICKET_CATEGORY':
              case 'OPERATOR-TEAM':
                f.ticketCategoryAllowed = args.value;
                break;
              default:
                break;
            }

            if (args.value == true) {
              switch (this.entityType) {
                case 'LOCATION':
                  f.locationVisible = true;
                  break;
                case 'ORGANIZATIONAL_UNIT':
                  f.organizationalUnitVisible = true;
                  break;
                case 'TICKET_CATEGORY':
                case 'OPERATOR-TEAM':
                  f.ticketCategoryVisible = true;
                  break;
                default:
                  break;
              }
            }
          });
          if (args.value == true) {
            this.checkVisibled.option('value', true);
          }
          this.onChangedValue();
        },
      },
    });

    e.toolbarOptions.items.unshift({
      widget: 'dxCheckBox',
      cssClass: 'cl-checkbox-style',
      location: 'after',
      options: {
        text: TranslateService.get('globals/show'),
        rtlEnabled: true,
        onInitialized: (ec) => {
          this.checkVisibled = ec.component;
        },
        onValueChanged: (args) => {
          this.source.forEach((f) => {
            switch (this.entityType) {
              case 'LOCATION':
                f.locationVisible = args.value;
                break;
              case 'ORGANIZATIONAL_UNIT':
                f.organizationalUnitVisible = args.value;
                break;
              case 'TICKET_CATEGORY':
              case 'OPERATOR-TEAM':
                f.ticketCategoryVisible = args.value;
                break;
              default:
                break;
            }

            if (args.value == false) {
              switch (this.entityType) {
                case 'LOCATION':
                  f.locationAllowed = false;
                  break;
                case 'ORGANIZATIONAL_UNIT':
                  f.organizationalUnitAllowed = false;
                  break;
                case 'TICKET_CATEGORY':
                case 'OPERATOR-TEAM':
                  f.ticketCategoryAllowed = false;
                  break;
                default:
                  break;
              }
            }
          });
          if (args.value == false) {
            this.checkAllowed.option('value', false);
          }
          this.onChangedValue();
        },
      },
    });
  }

  /** Mise à jour */
  onRowUpdating(e) {
    if (e?.newData?.ticketCategoryVisible === false) {
      e.newData.ticketCategoryAllowed = false;
    }

    if (e?.newData?.ticketCategoryAllowed === true) {
      e.newData.ticketCategoryVisible = true;
    }

    if (e?.newData?.locationVisible === false) {
      e.newData.locationAllowed = false;
    }

    if (e?.newData?.locationAllowed === true) {
      e.newData.locationVisible = true;
    }

    if (e?.newData?.organizationalUnitVisible === false) {
      e.newData.organizationalUnitAllowed = false;
    }

    if (e?.newData?.organizationalUnitAllowed === true) {
      e.newData.organizationalUnitVisible = true;
    }
  }

  onRowUpdated(e) {
    this.onChangedValue();
  }

  /** Set la valeur */
  onChangedValue() {
    this.itemsAdded = this.source.map((s) => {
      switch (this.entityType) {
        case 'LOCATION':
          return {
            entityId: s.id,
            allowed: s.locationAllowed,
            visible: s.locationVisible,
          };
        case 'ORGANIZATIONAL_UNIT':
          return {
            entityId: s.id,
            allowed: s.organizationalUnitAllowed,
            visible: s.organizationalUnitVisible,
          };
        case 'TICKET_CATEGORY':
          return {
            entityId: s.id,
            allowed: s.ticketCategoryAllowed,
            visible: s.ticketCategoryVisible,
          };
        case 'OPERATOR-TEAM':
          return {
            entityId: s.id,
            allowed: s.ticketCategoryAllowed,
            visible: s.ticketCategoryVisible,
            qualification:
              s.qualificationAllowed != null ? s.qualificationAllowed : [],
          };
        default:
          return {
            entityId: s.id,
            allowed: false,
            visible: false,
          };
      }
    });
    this.onChange({
      itemsAdded: this.itemsAdded,
    });
    this.onTouched();
  }

  /** Gestion du lien */
  underline(e, data) {
    let url: UrlTree;
    let id = data.key.id;
    url = this.router.createUrlTree([this.options.route, 'edit', id]);

    let urlPath = this.router.serializeUrl(url);
    let win = window.open(urlPath, '_blank');
    if (win?.opener != undefined) {
      win.opener.callback = async () => await this.refresh();
    }
  }

  public async onGoTo() {
    let url = this.router.createUrlTree([this.options.route], {
      skipLocationChange: true,
    } as NavigationExtras);
    let win = window.open(url.toString(), '_blank');
  }

  selectionTemplateChanged(cellInfo, e) {
    let items = [];

    if (cellInfo.value != undefined) {
      items = JSON.parse(JSON.stringify(cellInfo.value));
    }
    if (e.addedItems.length === 0 && e.removedItems.length === 0) {
      return;
    }

    e.addedItems.forEach((element) => {
      let index = items.findIndex((x) => x === element['id']);
      if (index < 0) {
        items.push(JSON.parse(JSON.stringify(element['id'])));
      }
    });

    e.removedItems.forEach((element) => {
      let index = items.findIndex((x) => x === element['id']);

      if (index >= 0) {
        items.splice(index, 1);
      }
    });

    if (JSON.stringify(items) != JSON.stringify(cellInfo.value)) {
      cellInfo.setValue(items);
    }
  }
}
