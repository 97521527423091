import { Injectable, Injector } from '@angular/core';
import { FilterBuilderExtensionService } from '@clarilog/shared2/components/filter-builder/services/filter-builder-extension.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import CustomStore from 'devextreme/data/custom_store';
import { camelCase } from 'lodash';
import * as gqlTypes from '../../../../services/graphql/graphql-types';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { CommonBaseService } from '../service-bases';
import { PropertyField, ServiceListResultOfPropertyField } from '../types';
import { CoreCustomOperationService } from '@clarilog/shared2/components/list/list/custom-operation.service';

@Injectable({ providedIn: 'root' })
export class CommonCoreService extends CommonBaseService {
  fieldNameList: string[] = [
    'operator',
    'operatorAffected',
    'operatorAffectedId',
    'operatorReferent',
    'operatorReferentId',
    'ticket.operatorAffectedId',
    'ticket.operatorReferentId',
  ];

  baseTypeList: string[] = [
    'ticket',
    'tickettask',
    'incident',
    'request',
    'problem',
  ];
  customOperations: any[];
  customOperationWithChangeValues: any[];

  constructor(
    injector: Injector,
    private filterExtension: FilterBuilderExtensionService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private customOperationService: CoreCustomOperationService,
  ) {
    super(injector);
  }

  /** Obtient le modele pour la liste des properties */
  gqlField(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('serviceName'),
        GqlField.create('name'),
        GqlField.create('type'),
        GqlField.create('isEnum'),
        GqlField.create('isList'),
        GqlField.create('isNumeric'),
        GqlField.create('isTranslatable'),
        GqlField.create('isClarilogModel'),
        GqlField.create('hasQualification'),
        GqlField.create('displayExpression'),
        GqlField.create('valueExpression'),
        GqlField.create('parentType'),
        GqlField.create('keyName'),
        GqlField.create('keyPathName'),
        GqlField.create('id'),
        GqlField.create('parentId'),
        GqlField.create('hasChildren'),
        GqlField.create('completeName'),
        GqlField.create('canFilter'),
        GqlField.create('translated'),
        GqlSubField.create('treeField', [
          GqlField.create('nameExp'),
          GqlField.create('pathExp'),
          GqlField.create('parentIdExp'),
        ]),
      ]),
    ];
  }

  /** Obtient la source de données */
  private getDataSource(
    service: any,
    fields: GqlFields,
    displayExpr: string,
    valueExpr: string,
    isTree: boolean,
    hasQualification: boolean,
    baseType: string,
    isTranslatable: boolean,
    fieldName: string = null,
  ) {
    if (fields == undefined) {
      if (valueExpr == undefined || valueExpr == '') {
        valueExpr = 'id';
      }
      if (displayExpr == undefined || displayExpr == '') {
        if (service?.modelName === 'ticket') {
          displayExpr = 'ticketNumber';
        } else {
          displayExpr = 'name';
        }
      }

      let displayField: GqlField | GqlSubField = GqlField.create(displayExpr);
      if (isTranslatable) {
        displayField = GqlSubField.create(displayExpr, [
          GqlField.create(this.translatedFieldHelperService.getTranslateKey()),
        ]);
      }
      let fieldArray = [GqlField.create(valueExpr), displayField];

      if (
        fieldName !== null &&
        this.baseTypeList.includes(baseType.toLowerCase()) &&
        this.fieldNameList.includes(fieldName)
      ) {
        fieldArray.push(GqlField.create('email'));
        fieldArray.push(GqlField.create('serviceDeskActivated'));
        fieldArray.push(GqlField.create('displayOperatorFilter'));
      }

      if (isTree) {
        fieldArray.push(GqlField.create('parentId'));
      }
      fields = [GqlSubField.create('data', fieldArray)];
    }

    let sort = {};

    if (isTranslatable) {
      sort[displayExpr] = {};
      sort[displayExpr][this.translatedFieldHelperService.getTranslateKey()] =
        'ASC';
    } else {
      sort[displayExpr] = 'ASC';
    }

    let option = {
      sort: [sort],
    };

    let findField = [];
    fields.forEach((f) => findField.push(f));
    findField.push(GqlField.create('totalCount'));

    return new CustomStore({
      key: valueExpr,
      load: (loadOptions: any) => {
        let filter = undefined;

        if (loadOptions?.skip != undefined) {
          option['skip'] = loadOptions.skip;
        }

        if (loadOptions?.take != undefined) {
          option['limit'] = loadOptions.take;
        }

        if (
          loadOptions?.filter?.filterValue != undefined &&
          loadOptions.filter.filterValue != ''
        ) {
          filter = {};

          if (isTranslatable) {
            filter[displayExpr] = {};
            filter[displayExpr][
              this.translatedFieldHelperService.getTranslateKey()
            ] = {
              contains: loadOptions.filter.filterValue,
            };
          } else {
            filter[displayExpr] = {
              contains: loadOptions.filter.filterValue,
            };
          }
        }

        if (loadOptions?.filter?.value != undefined) {
          filter = loadOptions.filter.value;
        }

        if (hasQualification === true) {
          let qualification = [];

          switch (baseType.toLowerCase()) {
            case 'incident':
            case 'request':
            case 'problem':
              qualification = [baseType.toLowerCase()];
              break;
            case 'ticket':
              qualification = ['incident', 'request'];
              break;
          }

          if (service.findByQualification == undefined) {
            console.error('findByQualification not implemented');
            return service.find(findField, option, filter).toPromise();
          }

          return service
            .findByQualification(findField, qualification, option, filter)
            .toPromise();
        }

        if (
          this.baseTypeList.includes(baseType.toLowerCase()) &&
          this.fieldNameList.includes(fieldName)
        ) {
          if (fieldName.includes('operatorReferent')) {
            return service
              .findOperatorUser(findField, option, filter)
              .toPromise();
          }

          return service
            .findOperatorAndSupplier(findField, option, null, filter)
            .toPromise();
        }

        return service.find(findField, option, filter).toPromise();
      },
      byKey: async (key: any) => {
        let keyValue = this.getKeyValue(key);
        if (
          keyValue != undefined &&
          keyValue != '' &&
          CommonCoreService.isGuid(keyValue)
        ) {
          if (this.fieldNameList.includes(fieldName)) {
            let result = await service
              .operatorOrSupplier(fields, keyValue)
              .toPromise();
            return result?.data;
          }

          let result = await service.get(fields, keyValue).toPromise();
          return result?.data;
        } else {
          return undefined;
        }
      },
    });
  }

  static isGuid(key: any) {
    let uuidV1 =
      /^[0-9A-F]{8}-[0-9A-F]{4}-[1][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    let uuidV2 =
      /^[0-9A-F]{8}-[0-9A-F]{4}-[2][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    let uuidV3 =
      /^[0-9A-F]{8}-[0-9A-F]{4}-[3][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    let uuidV4 =
      /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    let uuidV5 =
      /^[0-9A-F]{8}-[0-9A-F]{4}-[5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

    if (key === undefined) {
      return false;
    }
    if (uuidV1.exec(key)) {
      return true;
    }
    if (uuidV2.exec(key)) {
      return true;
    }
    if (uuidV3.exec(key)) {
      return true;
    }
    if (uuidV4.exec(key)) {
      return true;
    }
    if (uuidV5.exec(key)) {
      return true;
    }
    return false;
  }

  getKeyValue(key: any) {
    if (key != undefined && Array.isArray(key) && key.length > 0) {
      return this.getKeyValue(key[0]);
    }
    return key;
  }

  /** Obtient la liste des Enum */
  private getEnumEntries(enumTypeName: string): any {
    let test = gqlTypes[enumTypeName];
    if (test != undefined) {
      return Object.values(test).map((x) => {
        return {
          id: x,
          value: TranslateService.get(`enums/${camelCase(enumTypeName)}/${x}`),
        };
      });
    }
  }

  /** Boucle récursive pour récupéré le type parent d'un champs de traduction */
  private getParentType(
    field: PropertyField,
    datas: ServiceListResultOfPropertyField,
  ) {
    if (field.parentType == 'translatedField') {
      if (field.completeName.lastIndexOf('.') > 0) {
        let parentFieldName = field.completeName.substring(
          0,
          field.completeName.lastIndexOf('.'),
        );
        // Recherche le précedent noeued
        let parentField = datas.data.find(
          (s) => s.completeName == parentFieldName,
        );
        if (parentField != undefined) {
          return this.getParentType(parentField, datas);
        }
      }
    }

    return field.parentType;
  }

  /** Obtient les custom operation du type */
  getDefaultOperations(dataType, field, addCustomOp = true) {
    let isCustom = false;
    if (field?.name != undefined && field.name.startsWith('customMethod_')) {
      isCustom = true;
    }

    let customOperationForTye =
      this.customOperationService.getDefaultOperations(dataType, isCustom);

    if (addCustomOp === true) {
      // Add custom operation sur chaque field
      let customOp = this.customOperations.filter(
        (d) => d.dataTypes != undefined && d.dataTypes.includes(dataType),
      );

      if (customOperationForTye == undefined) customOperationForTye = [];

      customOp.forEach((s) => {
        // Ajoute que les operation custom de non modification de valeur
        let canAdd = true;
        if (
          field.completeName != undefined &&
          (field.completeName.includes('.data.') ||
            field.name.includes('customMethod_')) &&
          this.customOperationWithChangeValues.find(
            (co) => co.name == s.name,
          ) != undefined
        ) {
          // On considère que si on vient d'un iref (.data.) on ne charge pas les operations de changement de valeur
          // Egalement si action custommethod
          canAdd = false;
        }

        if (canAdd) customOperationForTye.push(s.name);
      });
    }

    return customOperationForTye;
  }

  /** Obtient les type de propriété */
  private async getFieldType(
    type: string,
    loadDataSoure: boolean = true,
    field: PropertyField,
    complexity = 0,
    authorizeReturnTableGuid,
    datas: ServiceListResultOfPropertyField,
  ) {
    complexity++;
    let entityType = type;
    if (field.parentType != undefined) {
      entityType = field.parentType;
    }

    // Ne prend pas en compte la traduciton d'un type translateField
    if (entityType == 'translatedField') {
      entityType = this.getParentType(field, datas);
    }

    let fieldName = field.name;
    let translateFieldName = field.name;
    translateFieldName = this.renameFieldName(translateFieldName);

    if (translateFieldName.indexOf('.') > 0) {
      translateFieldName = translateFieldName.substring(
        translateFieldName.lastIndexOf('.') + 1,
      );
    }

    let translatedName = this.getTranslatedName(
      type,
      field,
      entityType,
      fieldName,
      translateFieldName,
    );
    if (
      field.type != 'ID' &&
      field.type != 'Uuid' &&
      field.type != 'Guid' &&
      translatedName.startsWith('[')
    ) {
      // Cas spcifique des entité pouvant etre hérité (exemple ticket)
      if (type == 'ticket') {
        translatedName = this.getTranslatedName(
          'incident',
          field,
          entityType,
          fieldName,
          translateFieldName,
        );
      }

      if (translatedName.startsWith('[')) {
        return null;
      }
    }

    // Ajout du champs
    switch (field.type) {
      case 'Guid[]':
        if (field.name == 'childrenIds' && field.serviceName == 'ticketTask') {
          return {
            dataField: fieldName,
            caption: translatedName,
            dataType: 'string',
            filterOperations: this.getDefaultOperations('Guid[]', field, false),
          };
        }

        let customField = this.filterExtension.getCustomFieldId(field);
        if (customField != undefined) {
          let fieldExp = field.displayExpression ?? 'name';
          if (field.isTranslatable) {
            fieldExp =
              this.translatedFieldHelperService.setColumnTranslateField(
                fieldExp,
              );
          }
          return {
            dataField: field.name,
            caption: translatedName,
            dataType: 'ID',
            editorTemplate:
              field.treeField != undefined
                ? 'treeviewTemplate'
                : 'gridViewTemplate',
            lookup: {
              displayExpr: fieldExp ?? 'name',
              valueExpr: field.valueExpression ?? 'id',
              isTree: field.keyName != undefined ? true : false,
              hasTreeField: field.treeField != undefined ? true : false,
              dataSource: this.getDataSource(
                customField.service,
                undefined,
                field.displayExpression,
                field.valueExpression,
                field.treeField != undefined ? true : false,
                field.hasQualification,
                type,
                field.isTranslatable ?? false,
              ),
            },
            filterOperations: ['customArrayContains'],
          };
        } else {
          if (authorizeReturnTableGuid == undefined) {
            return {
              dataField: fieldName,
              caption: translatedName,
              dataType: 'number',
              filterOperations: [
                'customArrayEq',
                'customArrayMoreThan',
                'customArrayLessThan',
              ],
            };
          }
        }
        break;
      case 'DateTime':
        return {
          dataField: fieldName,
          caption: translatedName,
          dataType: 'datetime',
          filterOperations: this.getDefaultOperations('datetime', field, true),
        };
      case 'String':
      case 'string':
        return {
          dataField: fieldName,
          caption: translatedName,
          dataType: 'string',
          filterOperations: this.getDefaultOperations('string', field, true),
        };
      case 'String[]':
        return {
          dataField: fieldName,
          caption: translatedName,
          dataType: 'string',
          filterOperations: undefined,
        };
      case 'ID':
      case 'Uuid':
      case 'Guid':
        let idField = this.filterExtension.getCustomFieldId(field);

        if (idField == null) return null;
        else {
          if (translatedName.indexOf('[') >= 0) {
            translatedName = idField.translatedName;
          }

          if (loadDataSoure && field.serviceName != 'loan') {
            let fieldExp = field.displayExpression ?? 'name';
            if (field.isTranslatable) {
              fieldExp =
                this.translatedFieldHelperService.setColumnTranslateField(
                  fieldExp,
                );
            }

            let isOperator = false;
            if (
              field?.name !== null &&
              field?.name !== undefined &&
              this.baseTypeList.includes(type.toLowerCase()) &&
              this.fieldNameList.includes(field?.name)
            ) {
              isOperator = true;
            }

            let fieldTypeResult = this.getFieldForFieldType(
              field,
              translatedName,
              fieldExp,
              idField,
              type,
              isOperator,
            );
            fieldTypeResult['filterOperations'] = this.getDefaultOperations(
              'ID',
              field,
              true,
            );

            return fieldTypeResult;
          } else {
            return {
              dataField: fieldName,
              caption: translatedName,
              dataType: 'ID',
              filterOperations: this.getDefaultOperations('ID', field, true),
            };
          }
        }
      case 'Date':
        return {
          dataField: fieldName,
          caption: translatedName,
          dataType: 'date',
          filterOperations: this.getDefaultOperations('datetime', field, true),
        };

      case 'Boolean':
        return {
          dataField: fieldName,
          caption: translatedName,
          dataType: 'boolean',
          filterOperations: this.getDefaultOperations('boolean', field, true),
        };
      case 'TimeSpan':
        return {
          dataField: fieldName,
          caption: translatedName,
          filterOperations: ['customTimeDate'],
        };
      default:
        if (field.isEnum) {
          if (gqlTypes[field.type] == undefined) {
            field.type = field.type + 'Type';
          }

          if (gqlTypes[field.type] != undefined) {
            return {
              dataField: fieldName,
              caption: translatedName,
              lookup: {
                valueExpr: 'id',
                displayExpr: 'value',
                dataSource: this.getEnumEntries(field.type),
              },
            };
          }
        } else if (field.isNumeric) {
          let numberField = {
            dataField: fieldName,
            caption: translatedName,
            dataType: 'number',
            filterOperations: this.getDefaultOperations('number', field, true),
          };
          if (
            (type === 'logicalDisk' && fieldName === 'freeSpace') ||
            (type === 'asset' && fieldName == 'memories')
          ) {
            numberField.filterOperations = [
              ...numberField.filterOperations,
              'customBetweenSizeGo',
            ];
            numberField['editorTemplate'] = 'sizeGoTemplate';
            numberField['customizeText'] = function (field) {
              return field.value / 1024 / 1024 / 1024 + ' Go';
            };
          }
          if (type === 'printerConsumable' && fieldName === 'actualLevel') {
            numberField['editorTemplate'] = 'percentTemplate';
            numberField['customizeText'] = function (field) {
              return field.value + '%';
            };
          }
          return numberField;
        } else if (field.isList) {
          if (field.type == 'SecurityGroupObject[]') {
            let translatable = false;
            if (
              field.name === 'memberOfPopulationIds' ||
              field.name.includes('.memberOfPopulationIds')
            ) {
              translatable = true;
            }
            let securityGroupField =
              this.filterExtension.getCustomFieldId(field);
            if (securityGroupField != undefined) {
              return {
                dataField: fieldName,
                caption: translatedName,
                dataType: 'ID',
                editorTemplate: 'gridViewTemplate',
                filterOperations: ['customArrayContains'],
                lookup: {
                  displayExpr:
                    translatable == true
                      ? this.translatedFieldHelperService.setColumnTranslateField(
                          'name',
                        )
                      : 'name',
                  valueExpr: 'id',
                  isTree: false,
                  hasTreeField: false,
                  dataSource: this.getDataSource(
                    securityGroupField.service,
                    undefined,
                    'name',
                    'id',
                    false,
                    false,
                    type,
                    translatable,
                  ),
                },
              };
            }
          }
        } else if (field.isClarilogModel) {
          if (field.isTranslatable) {
            return {
              dataField: fieldName,
              caption: translatedName,
              dataType: 'string',
            };
          }
          return {
            dataField: fieldName,
            caption: translatedName,
            dataType: 'object',
            filterOperations: this.getDefaultOperations('object', field, true),
          };
        }

        return null;
    }
  }

  private getFieldForFieldType(
    field,
    translatedName,
    fieldExp,
    idField,
    type,
    isOperator: boolean = false,
  ) {
    return {
      dataField: field.name,
      caption: translatedName,
      dataType: 'ID',
      editorTemplate:
        field.treeField != undefined ? 'treeviewTemplate' : 'gridViewTemplate',
      lookup: {
        displayExpr: isOperator
          ? 'displayOperatorFilter'
          : (fieldExp ?? 'name'),
        valueExpr: field.valueExpression ?? 'id',
        isTree: field.keyName != undefined ? true : false,
        hasTreeField: field.treeField != undefined ? true : false,
        dataSource: this.getDataSource(
          idField.service,
          undefined,
          field.displayExpression,
          field.valueExpression,
          field.treeField != undefined ? true : false,
          field.hasQualification,
          type,
          field.isTranslatable ?? false,
          this.sendFieldName(field.name),
        ),
      },
    };
  }

  /* Recherche la traduction du field */
  getTranslatedName(
    type: string,
    field: PropertyField,
    entityType: string,
    fieldName: string,
    translateFieldName: string,
  ): string {
    if (field.name == '__typename') {
      return TranslateService.get('entities/lifeCycleTicket/typeTicket');
    }

    if (field.translated != undefined) {
      return field.translated;
    }

    //Exception
    if (entityType == 'ticketSatisfaction') {
      entityType = 'satisfaction';
    }
    // Recherche de ma traduction
    let translatedName = undefined;
    if (field.isClarilogModel == true) {
      if (field.type.toLowerCase() != 'TranslatedField'.toLowerCase()) {
        translatedName = TranslateService.get(
          'entities/' + camelCase(field.type) + '/_title/singular',
        );
      } else {
        translatedName = '[]';
      }
      if (
        field.parentType != undefined &&
        translatedName.indexOf('[') >= 0 &&
        field.parentType != 'translatedField'
      ) {
        translatedName = TranslateService.get(
          'entities/' +
            camelCase(field.parentType) +
            '/' +
            camelCase(translateFieldName),
        );
      }
    } else {
      if (
        field.parentType != undefined &&
        field.parentType != 'translatedField'
      ) {
        translatedName = TranslateService.get(
          'entities/' +
            camelCase(field.parentType) +
            '/' +
            camelCase(translateFieldName),
        );
      } else {
        translatedName = '[]';
      }
      if (translatedName != undefined && translatedName.indexOf('[') >= 0) {
        translatedName = TranslateService.get(
          'entities/' +
            camelCase(entityType) +
            '/' +
            camelCase(translateFieldName),
        );
      }

      if (translatedName != undefined && translatedName.indexOf('[') >= 0) {
        translatedName = TranslateService.get(
          'entities/' + camelCase(type) + '/' + camelCase(translateFieldName),
        );
      }

      if (translatedName != undefined && translatedName.indexOf('[') >= 0) {
        if (fieldName.indexOf('.') > 0) {
          let fieldNameSplit = fieldName.split('.');

          translatedName = TranslateService.get(
            'entities/' +
              camelCase(fieldNameSplit[fieldNameSplit.length - 2]) +
              '/' +
              camelCase(fieldNameSplit[fieldNameSplit.length - 1]),
          );
        }
      }
    }

    // Recherche dans global
    if (translatedName.indexOf('[') >= 0) {
      translatedName = TranslateService.get('globals/' + camelCase(field.type));
    }
    if (translatedName.indexOf('[') >= 0) {
      translatedName = TranslateService.get('globals/' + camelCase(field.name));
    }

    if (translatedName.indexOf('[') >= 0 && field.type.includes('[]')) {
      let fieldTrans = camelCase(translateFieldName) as string;

      fieldTrans = fieldTrans.replace('Id', '');
      translatedName = TranslateService.get(
        'entities/' + camelCase(field.parentType) + '/' + fieldTrans,
      );
    }

    return translatedName;
  }

  public renameFieldName(fieldName: string) {
    switch (fieldName) {
      case 'task.duration':
        return 'task.durationMinute';
      default:
        return fieldName;
    }
  }

  private verifyFinancialFields(field: PropertyField): PropertyField {
    if (
      field.name.includes('financial.financial') ||
      field.completeName.includes('financial.financial')
    ) {
      field.name = field.name.replace(/financial\.financial/gi, 'financial');

      let completeName = field.completeName.replace(
        /financial\.financial/gi,
        'financial',
      );
      let addValue = '.data';
      let budgetRegex = /(?<=budget).?(?=\.)/gi;
      let managerRegex = /(?<=manager).?(?=\.)/gi;
      let parentRegex = /(?<=parent).?(?=\.)/gi;

      completeName = completeName.replace(budgetRegex, addValue);
      completeName = completeName.replace(managerRegex, addValue);
      completeName = completeName.replace(parentRegex, addValue);

      field.completeName = completeName;
    }

    return field;
  }

  /** Création des property */
  public async createFields(
    entityType: string,
    types: ServiceListResultOfPropertyField,
    loadDataSoure: boolean = true,
    useCompleteName: boolean = false,
    parentType = '',
    complexity = 0,
    authorizeReturnTableGuid,
    useChangeValue = false,
    excludeFieldName: Array<string> = undefined,
  ): Promise<any[]> {
    let fields: any[] = [];
    if (complexity > 5) {
      return null;
    }

    // Custom operation
    // Permet d'ajouter des opération custom en fonction de critète
    // Le nom correspond au custom opération (custom-operation.service.ts)
    this.customOperations = this.customOperationService.allCustomOperation();
    this.customOperationWithChangeValues =
      this.customOperationService.allCustomOperationWithChangeValues();
    if (useChangeValue === true) {
      this.customOperationWithChangeValues.forEach((d) =>
        this.customOperations.push(d),
      );
    }

    let typesData = types?.data;
    if (typesData !== undefined && typesData !== null) {
      typesData.forEach((f) => {
        f = this.verifyFinancialFields(f);
      });
    }
    let fieldIrefs = typesData.filter((x) => x.type == 'IRef`1');
    for (let fieldIref of fieldIrefs) {
      let fieldId = typesData.find((x) => x.name == fieldIref.name + 'Id');

      if (fieldId != undefined) {
        fieldId.keyName = fieldId.name;
        fieldId.keyPathName = fieldId.name;
        fieldId.id = fieldIref.id;
        fieldId.hasChildren = fieldIref.hasChildren;
        fieldId.name = fieldId.name.replace('Id', '');
        fieldIref.name = fieldIref.name + '_ref';
      }
    }
    typesData = typesData.filter((x) => !x.name.includes('_ref'));

    if (excludeFieldName != undefined && excludeFieldName?.length > 0) {
      excludeFieldName.map(
        (element) =>
          (typesData = typesData.filter((x) => !x.name.includes(element))),
      );
    }

    for (let field of typesData) {
      let type = await this.getFieldType(
        entityType,
        loadDataSoure,
        field,
        complexity,
        authorizeReturnTableGuid,
        types,
      );

      if (type != undefined) {
        type['keyName'] = field.keyName;
        type['keyPathName'] = field.keyPathName;
        type['parentId'] = field.parentId;
        type['id'] = field.id;
        type['hasChildren'] = field.hasChildren;
        type['translatable'] = field.isTranslatable;

        if (useCompleteName) {
          type['dataField'] = field['completeName'];
        }

        if (type['length'] != undefined) {
          console.log('Not impl getfield length >0');
        } else {
          fields.push(type);
        }
      }
    }

    // trie par ordre alpha
    fields.sort(function (item1, item2) {
      return item1.caption.localeCompare(item2.caption);
    });

    //evite la recursivité sur ticket avec le ticket pére
    fields = fields.filter((x) => !x.dataField.includes('parent.parent'));
    return fields;
  }

  /** Permet de forcer le titre des champs enfants et parents */
  validParentCaption(element, fields: any[]): string {
    if (element.parentId != undefined) {
      let parent = fields.filter((f) => f.id == element.parentId);
      if (parent != undefined && parent.length > 0) {
        const prefix = parent[0].caption + ' / ';
        if (!element.caption.startsWith(prefix)) {
          element.caption = prefix + element.caption;
        }
      }
    }

    return element.caption;
  }

  /** Obtient les différent template pouvante etre applique sur des colonne */
  columnTemplate(type: string, field: string) {
    if (type != undefined && field != undefined) {
      let columns = [
        {
          field: 'memories',
          template: 'sizeTemplate',
          format: undefined,
          type: 'asset',
        },
        {
          field: 'elaspedTime',
          template: 'timeSpanToMinutesTemplate',
          format: undefined,
          allowFiltering: false,
          type: 'intervention',
        },
        {
          field: 'task.data.duration',
          template: 'timeSpanToMinutesTemplate',
          format: undefined,
          allowFiltering: false,
          type: 'intervention',
        },
        {
          field: '__typename',
          template: 'ticketTypeColorTemplate',
          format: 'ticketType',
          type: 'ticket',
        },
      ];

      let column = columns.filter(
        (s) =>
          s.type.toLowerCase() == type.toLowerCase() &&
          s.field.toLowerCase() == field.toLowerCase(),
      );
      if (column.length > 0) {
        return column[0];
      }
    }
    return undefined;
  }

  private sendFieldName(fieldName: string): string {
    if (this.fieldNameList.includes(fieldName)) {
      return fieldName;
    }
    return null;
  }

  getQueryBuilderType(type: string): string {
    if (type == 'outOfPark') {
      return 'asset';
    }
    return type;
  }
}
