import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  ModelState,
  ModelFnContext,
} from '@clarilog/shared2/services/compiler/model-state';
import { GqlFields } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { dxButtonOptions } from 'devextreme/ui/button';
import { custom } from 'devextreme/ui/dialog';
import { CoreModelCompilerService } from '@clarilog/shared2/services/compiler/model-compiler.service';
import { GC, GCFactory } from '@clarilog/core';
import { Observable } from 'rxjs/internal/Observable';
import { ServiceSingleResult } from '@clarilog/core/services2/graphql/generated-types/types';
import { ActivatedRoute } from '@angular/router';
import { CoreWorkFormComponent } from '../work-form/work-form.component';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

@Component({
  selector: 'clc-work-sub-form',
  templateUrl: './work-sub-form.component.html',
  styleUrls: ['./work-sub-form.component.scss'],
})
export class CoreWorkSubFormComponent implements OnInit, OnDestroy {
  //TODO BUG LIST SELECTION SI DELETE LES SELECTEDKEYS RESTE
  private _isPopupOpen: boolean = false;
  /**Permet de savoir si on doit fermer où non le sub-form */
  private _isClosed: boolean = false;
  public _localStorageService: LocalStorageService;

  @Input() heightPopup = '80%';
  @Input() widthPopup = '80%';

  modelState: ModelState;
  @Input() parentModel: ModelState;
  @Input() rawModel: any;
  @Input() rules: ModelFnContext;
  @Input() fieldName: string;
  @Input() title: string;
  @Input() key: string;
  @Input() saveTitle: string;
  @Input() saveSplitButton: boolean = true;
  @Input() heightNew: string;
  @Input() component: any;
  @Input() forceSaveBtn: boolean = false;
  @Input() disableSaveNotification: boolean = false;
  @Input() keySubId: string;
  @Input() showSave: boolean = true;
  @Input() beforeSave = (obj: any) => {
    return true;
  };
  @Input() readOnly: boolean;
  /**Ne pas afficher l'icon save */
  @Input() displaySaveIcon: boolean = true;
  _subForm: CoreWorkFormComponent;

  @ViewChild(CoreWorkFormComponent) set subForm(value: CoreWorkFormComponent) {
    if (this._subForm == undefined && value != undefined) {
      value.formReady.subscribe(() => {
        if (this.modelState != undefined) {
          this.parentModel.subFormReady.emit({
            gc: this.subFormGc,
            subModelState: this.modelState,
            key: this.key,
          });
        }
      });
    }
    this._subForm = value;
  }
  get subForm(): CoreWorkFormComponent {
    return this._subForm;
  }

  @Output() onSaved = new EventEmitter<string>();

  @Input() get isPopupOpen(): boolean {
    return this._isPopupOpen;
  }

  @Input()
  service: {
    get: (
      fields: GqlFields,
      id: string,
    ) => Observable<ServiceSingleResult<any>>;
    update: (
      fields: GqlFields,
      id: string,
      entry: any,
    ) => Observable<ServiceSingleResult<any>>;
    insert: (
      fields: GqlFields,
      entry: any,
    ) => Observable<ServiceSingleResult<any>>;
    readOnly: (
      fields: GqlFields,
      id: any,
    ) => Observable<ServiceSingleResult<boolean>>;
  };

  private subFormGc: GC;
  private raw: any;

  @Input() public editId: string;
  set isPopupOpen(value: boolean) {
    if (value === true && this._isPopupOpen !== value) {
      this.subFormGc.dispose();

      // Complie rule

      this.raw = JSON.parse(JSON.stringify(this.rawModel));

      this.forceValidation();

      this.modelCompiler
        .compile(this.raw, false, this.component)
        .subscribe((model) => {
          this.modelState = model;
          this.modelState.isSubForm = true;

          let entityType = undefined;

          // Récupération de tout les paramètre lié
          if (this.parentModel != undefined && this.keySubId != undefined) {
            let subParameters = this.parentModel.sharedContext.params.get(
              this.keySubId + '-customParamsEntry',
            );

            if (subParameters != undefined) {
              let keyParemters = Object.keys(subParameters);

              keyParemters.forEach((s) => {
                let paramValue = subParameters[s];
                if (s == 'forceType' || s == 'workflowType') {
                  if (s == 'forceType') {
                    entityType = paramValue;
                  }
                  this.modelState.sharedContext.params.set(s, () => paramValue);
                } else {
                  this.modelState.sharedContext.entry.set(s, () => paramValue);
                }
              });
            }
          }

          this.modelState.subFormReady.subscribe(
            (data: { gc: GC; subModelState: ModelState; key: string }) => {
              this.parentModel.subFormReady.emit(data);
            },
          );

          if (entityType == undefined) {
            entityType = this.parentModel.sharedContext.entry.get('entityType');
          }

          if (entityType == undefined) {
            this.route.data.subscribe((data) => {
              entityType = data.type;

              this.modelState.sharedContext.params.set(
                'type',
                () => entityType,
              );
            });
          } else {
            this.modelState.sharedContext.params.set('type', () => entityType);
          }

          this.getParentId();

          if (this.editId != undefined) {
            this.modelState.id = this.editId;
          }
        });
      if (this.heightNew != undefined) {
        this.popUpSizeNote();
      }
      if (this._localStorageService.isMobile) {
        this.heightPopup = '100%';
        this.widthPopup = '100%';
      }
    }
    if (value === false && this._isPopupOpen !== value) {
      this.subFormGc.dispose();
      this.modelState = undefined;
      this.editId = undefined;
    }
    this._isPopupOpen = value;
    this.isPopupOpenChange.emit(value);
  }

  /** Reapplique la validation */
  forceValidation() {
    // Reapplique le modele
    if (this.rules != undefined) {
      if (this.modelState?.model != undefined) {
        this.rules.context.params.set('rawModel', () => this.modelState.model);
      } else {
        this.rules.context.params.set('rawModel', () => this.raw);
      }
      let id = this.editId;

      if (id == undefined) {
        id = this.modelState?.id;
      }
      this.rules.context.params.set('subFormId', () => id);

      this.rules.context.params.set('formGroup', () => this.modelState?.form);

      this.rules.fnCall();
    }
  }

  @Output() isPopupOpenChange = new EventEmitter<boolean>();

  constructor(
    private modelCompiler: CoreModelCompilerService,
    public gcFactory: GCFactory,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) {
    this.beforeSave = this.beforeSave.bind(this);
    this.afterSave = this.afterSave.bind(this);
    this.subFormGc = gcFactory.create();
    this._localStorageService = localStorageService;
  }

  getParentId() {
    if (this.parentModel.id != undefined) {
      this.modelState.sharedContext.entry.set(
        this.fieldName,
        () => this.parentModel.id,
      );
    } else {
      let id = this.route.snapshot.paramMap.get('id');

      if (id != undefined) {
        this.modelState.sharedContext.entry.set(this.fieldName, () => id);
      }
    }
  }

  afterSave(e: { result: any; e: any }) {
    this._isClosed = !e.e.close;

    if (this._isClosed) {
      if (this._subForm?.workItemForm != undefined) {
        this._subForm.workItemForm.inClosed = true;
      }
    }
  }

  saved(id: string) {
    if (this.isPopupOpen == true) {
      this.isPopupOpen = this._isClosed;
    }
    this.forceValidation();
    this.onSaved.emit(id);
  }

  ngOnInit(): void {
    if (this.heightNew != undefined) {
      this.popUpSizeNote();
    }
  }

  private popUpSizeNote() {
    if (this.heightNew == 'note') {
      this.heightPopup = '65%';
      this.widthPopup = '40%';
    } else {
      this.heightPopup = '80%';
      this.widthPopup = '80%';
    }
  }

  ngOnDestroy() {
    this.subFormGc.dispose();
  }

  async confirmSaveDialog(
    title: string,
    message: string,
    saveButtonMessage: string,
    discardButtonMessage: string,
    cancelButtonMessage: string,
  ): Promise<boolean> {
    let buttons: dxButtonOptions[] = [
      {
        type: 'default',
        text: saveButtonMessage,
        onClick: (e) => {
          return true;
        },
      },
      {
        text: discardButtonMessage,
        onClick: (e) => {
          return false;
        },
      },
      {
        text: cancelButtonMessage,
        onClick: (e) => {
          return false;
        },
      },
    ];
    return await custom({
      title: title,
      messageHtml: message,
      buttons: buttons,
    }).show();
  }
}
