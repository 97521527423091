<div class="widget-container flex-box">
  <p
    class="infoText"
    *ngIf="
      allowedFileExtensions != undefined &&
      allowedFileExtensions.length > 0 &&
      value == undefined
    "
  >
    {{ 'entities/labelPrinter/allowedExtension' | translate }} :
    {{ allowedFileExtensions.toString() }}
  </p>

  <dx-file-uploader
    *ngIf="value == undefined"
    [chunkSize]="this.maxChunkSize"
    [uploadChunk]="uploadFileChunk"
    [allowedFileExtensions]="allowedFileExtensions"
    [multiple]="false"
    uploadMode="instantly"
    allowCanceling="true"
    [readOnly]="readOnly"
  ></dx-file-uploader>

  <div *ngIf="value != undefined" class="cl-delete-file">
    <dx-button
      stylingMode="contained"
      icon="trash"
      type="danger"
      (onClick)="click($event)"
      [visible]="!readOnly"
    >
    </dx-button>
    <dx-button
      stylingMode="contained"
      icon="download"
      type="normal"
      (onClick)="download()"
      [visible]="!readOnly"
      [text]="fileModel?.name"
    >
    </dx-button>
  </div>
</div>
