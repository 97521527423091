import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  ComponentRefDescription,
  CoreFormLoader,
  CoreModelCompilerService,
  CoreWorkItemHostDirective,
  FormDesignerPopupComponent,
  FormDesignerPopupSaveEvent,
  FormGroupHelpers,
  ModelCompilerContextService,
  PageSection,
  TranslatedFieldHelperService,
  TranslateService,
  WorkFormCoreService,
  WorkItem,
} from '@clarilog/shared2';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { startWith } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
  FormDesignerEditing,
  FormDesignerTo,
  TranslatedField,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { CoreModelDynamicFieldCompilerService } from '@clarilog/shared2/services/compiler/model-dynamic-field-compiler';
import { OrganizationStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/organization-storage-service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

/** type used for pass event btw subcompoenent(group section, ...) and designer **/
export enum DesignerEventType {
  TEST = 'TEST',
  CONTROL = 'CONTROL',
  PAGE = 'PAGE',
  GROUP = 'GROUP',
  SECTION = 'SECTION',
  TAB = 'TAB',
  NAV_GROUP = 'NAV_GROUP',
  NAV_ITEM = 'NAV_ITEM',
}

export enum DesignerEventAction {
  ADD = 0,
  REMOVE = 1,
  NOTHING = 2,
  ADD_IN = 3,
  ADD_UP,
  ADD_DOWN,
  EDIT,
}

export const DesignerEventKey: string = 'DESIGNER_EVENT';

export class DesignerEventData {
  public type = DesignerEventType.TEST;
  public action = DesignerEventAction.NOTHING;
  public id = '';
  public other = '';

  constructor(
    type: DesignerEventType,
    action: DesignerEventAction,
    id: string,
    other: any = null,
  ) {
    this.action = action;
    this.id = id;
    this.type = type;
    this.other = other;
  }
}

@Component({
  selector: 'cl-form-designer',
  templateUrl: './form-designer.component.html',
  styleUrl: './form-designer.component.scss',
})
export class FormDesignerComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  @Input() state: ModelState;
  @Input() options: any;
  @Input() canUseStatus: boolean = true;

  @Input() qualification: string;
  @Input() formDesignerMode: FormDesignerEditing;
  @Input() formDesignerTo: FormDesignerTo;
  @Input() jsonModel;
  @Output() onChange: EventEmitter<{ model: string }> = new EventEmitter<{
    model: string;
  }>();
  popupVisible = false;

  constructor(
    private modelCompiler: CoreModelCompilerService,
    private workFormCoreService: WorkFormCoreService,
    private loader: CoreFormLoader,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _changeDetectorRef: ChangeDetectorRef,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private modelCompilerContextService: ModelCompilerContextService,
    private modelDynamicFieldCompilerService: CoreModelDynamicFieldCompilerService,
    private organizationStorageService: OrganizationStorageService,
    private route: ActivatedRoute,
    //private dyn: Dynam
  ) {}

  extractControls(
    obj,
    savedJson,
    onlyPage = false,
  ): { compiledResult: Array<any>; jsonResult: Array<any> } {
    let result = [];
    let jsonResult = [];
    let authorizedKeysToGoIn = ['form', 'layout', 'pages', 'items', 'tabs'];
    if (onlyPage == false) {
      authorizedKeysToGoIn.push(
        ...['control', 'controls', 'sections', 'groups'],
      );
    }

    if (obj == undefined || savedJson == undefined) {
      return { compiledResult: result, jsonResult: jsonResult };
    }

    if (Array.isArray(obj)) {
      //case array
      for (let cpt = 0; cpt < (obj as Array<any>).length; cpt++) {
        let keyValueItem = (obj as Array<any>)[cpt];

        let foundedOrNot = this.extractControls(
          keyValueItem,
          savedJson[cpt],
          onlyPage,
        );
        if (foundedOrNot.compiledResult.length > 0) {
          result = [...foundedOrNot.compiledResult, ...result];
          jsonResult = [...foundedOrNot.jsonResult, ...jsonResult];
        }
      }
    } else {
      //case obj
      let keys = Object.keys(obj);

      for (let key of keys) {
        let keyValue = obj[key];
        let savedJsonKeyValue = savedJson[key === 'items' ? 'pages' : key];

        if (key === 'items' && savedJsonKeyValue == undefined) {
          savedJsonKeyValue = savedJson;
        }
        if (
          savedJsonKeyValue == undefined ||
          authorizedKeysToGoIn.includes(key) === false
        ) {
          continue;
        }
        if (
          (key === 'controls' ||
            (onlyPage === true && (key === 'pages' || key === 'tabs'))) &&
          keyValue.length > 0
        ) {
          result = [...keyValue, ...result];
          jsonResult = [...savedJsonKeyValue, ...jsonResult];
        }

        if (Array.isArray(keyValue) || typeof keyValue === 'object') {
          let items = Array.isArray(keyValue)
            ? (keyValue as Array<any>)
            : Object.values(keyValue);
          let savedJsonItems = Array.isArray(savedJsonKeyValue)
            ? (savedJsonKeyValue as Array<any>)
            : Object.values(savedJsonKeyValue);

          for (let cpt = 0; cpt < items.length; cpt++) {
            let keyValueItem = items[cpt];
            let foundedOrNot = this.extractControls(
              keyValueItem,
              savedJsonItems[cpt],
              onlyPage,
            );

            if (foundedOrNot.compiledResult.length > 0) {
              result = [...foundedOrNot.compiledResult, ...result];
              jsonResult = [...foundedOrNot.jsonResult, ...jsonResult];
            }
          }
        }
      }
    }
    return {
      compiledResult: result.filter((el) => el.length == undefined),
      jsonResult: jsonResult.filter((el) => el.length == undefined),
    };
  }

  insertAtSpecificPlace(list, obj, index) {
    return Array.from({ length: list.length + 1 }, (_, i) =>
      i === index ? obj : i < index ? list[i] : list[i - 1],
    );
  }

  processTranslateField(
    keyToUse,
    customResources,
    newLabel: TranslatedField,
    forceTranslate: boolean = true,
  ): string {
    if (forceTranslate !== true || newLabel === undefined) {
      return;
    }

    let jsonLabel =
      keyToUse === 'label' ? this.saveEventData.obj1.savedJson.label : null;
    let customLabelValue = '';
    if (jsonLabel?.includes('customResource')) {
      let key = jsonLabel.split("'")[1];

      customLabelValue = "[customResource('" + key + "')]";
      this.savedModel.customResources[key] =
        this.processTranslateEditValue(newLabel);
    } else {
      let newCustomResourceId = uuidv4();
      customLabelValue = "[customResource('" + newCustomResourceId + "')]";
      if (this.savedModel.customResources == undefined) {
        this.savedModel.customResources = {};
      }
      this.savedModel.customResources[newCustomResourceId] =
        this.processTranslateEditValue(newLabel);
    }
    if (keyToUse === 'label') {
      this.saveEventData.obj1.compiledModel.label =
        this.translatedFieldHelperService.findValueToShow(newLabel);
      this.saveEventData.obj1.savedJson.label = customLabelValue;
    }
    return customLabelValue;
  }

  getCustomResourceKey(jsonLabel): TranslatedField {
    if (jsonLabel?.includes('customResource')) {
      let key = jsonLabel.split("'")[1];
      return this.savedModel.customResources[key];
    } else if (jsonLabel?.includes('DynamicFieldResource')) {
      let key = jsonLabel.split("'")[1];

      let dynprop = this.typeDynamicPropArray.find(
        (el) => el.propertyName === key,
      );

      return dynprop.name;
    } else if (jsonLabel?.includes("[resource('")) {
      //ici voir pour recup tte les trad des diff langue
      let translate = TranslateService.get(`custom/${jsonLabel.split("'")[1]}`);

      if (translate.startsWith('[custom/')) {
        translate = TranslateService.get(`${jsonLabel.split("'")[1]}`);
      }
      return this.translatedFieldHelperService.getDefaultvalue(translate);
    }
    return this.translatedFieldHelperService.getDefaultvalue();
  }

  processTranslateEditValue(newVal) {
    if (newVal != undefined) {
      let newEditValParsed = JSON.parse(JSON.stringify(newVal));
      for (let val of TranslatedFieldHelperService.getStaticLanguageDatasource()) {
        if (
          newEditValParsed[val.key] == undefined ||
          newEditValParsed[val.key].length == 0
        ) {
          newEditValParsed[val.key] =
            newVal[this.translatedFieldHelperService.getTranslateKey()];
        }
      }
      return newEditValParsed;
    } else {
      return newVal;
    }
  }

  addControl(event: FormDesignerPopupSaveEvent): {
    objToPush: any;
    jsonControl: any;
  } {
    let objToPush = this.findObjectById(
      this.savedCompiledModel.model,
      event.eventData.ctrlId,
      'id',
    );
    if (objToPush == undefined) {
      objToPush = this.savedControls.compiledResult.find(
        (el) => el.id === event.eventData.ctrlId,
      );
    }

    let jsonControl = this.findObjectById(
      this.jsonModel,
      objToPush['fieldName'],
      'fieldName',
    );
    if (jsonControl == undefined) {
      jsonControl = this.savedControls.jsonResult.find(
        (el) => el.fieldName === objToPush['fieldName'],
      );
    }
    objToPush['readOnly'] =
      event.eventData.editable == undefined ? true : !event.eventData.editable;
    jsonControl['readOnly'] = objToPush['readOnly'];

    if (event.eventData.required === true) {
      objToPush['validators'] = [{ name: 'required' }];
      jsonControl['validators'] = [{ name: 'required' }];
    }
    objToPush['id'] = uuidv4();
    objToPush['visible'] = true;
    jsonControl['visible'] = true;

    // Uniquement utilise pour l'affichage
    objToPush['designerReadOnly'] = objToPush['readOnly'];
    jsonControl['designerReadOnly'] = jsonControl['readOnly'];

    return { objToPush: objToPush, jsonControl: jsonControl };
  }

  /** Sauvegarde les options en fonction des status (edit/required) */
  saveStatusOptions(event: FormDesignerPopupSaveEvent) {
    let hasEditDepend = undefined;
    let hasRequiredDepend = undefined;

    if (
      event?.eventData?.editable != undefined &&
      event.eventData.editable === true &&
      event.eventData.editStatusIds?.length > 0
    ) {
      hasEditDepend = {
        effect: 'EditIfValueIn',
        controlName: 'statusId',
        options: {
          value: event.eventData.editStatusIds,
          operator: 'In',
        },
      };
    }

    if (event?.eventData?.editable != undefined) {
      this.saveEventData.obj1.savedJson.readOnly =
        event.eventData.editable === true ? false : true;
      this.saveEventData.obj1.compiledModel.readOnly =
        this.saveEventData.obj1.savedJson.readOnly;
    }

    if (
      event?.eventData?.required != undefined &&
      event.eventData.required === true &&
      event.eventData.statusIds?.length > 0
    ) {
      // Requis via status
      hasRequiredDepend = {
        effect: 'RequireIfValueIn',
        controlName: 'statusId',
        options: {
          value: event.eventData.statusIds,
          operator: 'In',
        },
      };
    } else if (event?.eventData?.required === true) {
      //requis tout statuy
      this.saveEventData.obj1.compiledModel.validators = [{ name: 'required' }];
      this.saveEventData.obj1.savedJson.validators = [{ name: 'required' }];
    } else if (event?.eventData?.required === false) {
      // non requis
      this.saveEventData.obj1.compiledModel.validators = [];
      this.saveEventData.obj1.savedJson.validators = [];
    }

    this.saveEventData.obj1.compiledModel.dependsOn = undefined;
    this.saveEventData.obj1.savedJson.dependsOn = undefined;
    this.saveEventData.obj1.compiledModel.dependsOns = [];
    this.saveEventData.obj1.savedJson.dependsOns = [];
    if (hasRequiredDepend != undefined || hasEditDepend != undefined) {
      if (hasRequiredDepend != undefined) {
        this.saveEventData.obj1.compiledModel.dependsOns.push(
          hasRequiredDepend,
        );
        this.saveEventData.obj1.savedJson.dependsOns.push(hasRequiredDepend);
      }
      if (hasEditDepend != undefined) {
        this.saveEventData.obj1.compiledModel.dependsOns.push(hasEditDepend);
        this.saveEventData.obj1.savedJson.dependsOns.push(hasEditDepend);
      }
    }
  }

  popupSave(event: FormDesignerPopupSaveEvent) {
    setTimeout(async (_) => {
      if (this.saveEventData == undefined) {
        return;
      }

      this.removeSystemField();

      if (this.saveEventData.action === DesignerEventAction.EDIT) {
        switch (this.saveEventData.type) {
          case DesignerEventType.CONTROL:
            //this.processTranslateEditValue(event.eventData.label)
            this.processTranslateField(
              'label',
              this.savedModel?.customResources,
              event.eventData.label,
              event.eventData?.changeTranslation,
            );

            // if (event.eventData.changeTranslation === true) {
            //   this.saveEventData.obj1.compiledModel.label = event.eventData.label;
            //   this.saveEventData.obj1.savedJson.label = event.eventData.label;
            // }
            if (event.eventData.editable == undefined) {
              event.eventData.editable = true;
            }

            this.saveEventData.obj1.compiledModel.validators = [];
            this.saveEventData.obj1.savedJson.validators = [];
            this.saveEventData.obj1.compiledModel.readOnly =
              event.eventData.editable === true ? false : true;

            this.saveStatusOptions(event);

            // Designer mode
            this.saveEventData.obj1.compiledModel.designerReadOnly =
              this.saveEventData.obj1.compiledModel.readOnly;

            this.saveEventData.obj1.savedJson.designerReadOnly =
              this.saveEventData.obj1.savedJson.readOnly;

            break;
          case DesignerEventType.PAGE:
          case DesignerEventType.SECTION:
            if (event.eventData === 'center') {
              // this.saveEventData.obj1.compiledModel.location = event.eventData;
              // this.saveEventData.obj1.savedJson.location = event.eventData;
            } else {
              this.saveEventData.obj1.compiledModel.location = event.eventData;
              this.saveEventData.obj1.savedJson.location = event.eventData;
            }

            if (event.eventData === 'before' || event.eventData === 'after') {
              // change cthe place in the array because not working
              let sectionIndex =
                this.saveEventData.compiledModelToPutUpdate.sections.findIndex(
                  (el) => el.id === this.saveEventData.obj1.compiledModel.id,
                );
              let jsonSection =
                this.saveEventData.jsonToPutUpdate.sections[sectionIndex];
              let compiledSection =
                this.saveEventData.compiledModelToPutUpdate.sections[
                  sectionIndex
                ];

              this.saveEventData.jsonToPutUpdate.sections.splice(
                sectionIndex,
                1,
              );
              this.saveEventData.compiledModelToPutUpdate.sections.splice(
                sectionIndex,
                1,
              );
              if (event.eventData === 'before') {
                this.saveEventData.jsonToPutUpdate.sections = [
                  jsonSection,
                  ...this.saveEventData.jsonToPutUpdate.sections,
                ];
                this.saveEventData.compiledModelToPutUpdate.sections = [
                  compiledSection,
                  ...this.saveEventData.compiledModelToPutUpdate.sections,
                ];
              }
              if (event.eventData === 'after') {
                this.saveEventData.jsonToPutUpdate.sections = [
                  ...this.saveEventData.jsonToPutUpdate.sections,
                  jsonSection,
                ];
                this.saveEventData.compiledModelToPutUpdate.sections = [
                  ...this.saveEventData.compiledModelToPutUpdate.sections,
                  compiledSection,
                ];
              }
            }

            break;
          case DesignerEventType.TAB:
          case DesignerEventType.GROUP:
          case DesignerEventType.NAV_GROUP:
          case DesignerEventType.NAV_ITEM:
            this.saveStatusOptions(event);
            this.processTranslateField(
              'label',
              this.savedModel?.customResources,
              event.eventData?.label,
              true,
            );
            // this.saveEventData.obj1.compiledModel.label = event.eventData;
            // this.saveEventData.obj1.savedJson.label = event.eventData;
            break;
        }
        this.keepPageOnReload = true;
      } else if (
        this.saveEventData.type === DesignerEventType.NAV_GROUP &&
        this.saveEventData.action !== DesignerEventAction.ADD_IN
      ) {
        if (this.saveEventData.action === DesignerEventAction.REMOVE) {
          let indexToDel =
            this.saveEventData.compiledModelToPutUpdate.pages.findIndex(
              (el) => el.id === this.saveEventData.obj1.compiledModel.id,
            );

          this.saveEventData.jsonToPutUpdate.pages.splice(indexToDel, 1);
          this.saveEventData.compiledModelToPutUpdate.pages.splice(
            indexToDel,
            1,
          );
        } else {
          let objToPush;
          let objJson;

          if (event.eventData.navItemType === 'EMPTY_GROUP') {
            objToPush = {
              pages: [],
              label: this.translatedFieldHelperService.findValueToShow(
                event.eventData.groupName,
              ),
              id: uuidv4(),
            };
            objJson = {
              pages: [],
              label: this.processTranslateField(
                'nope',
                this.savedModel.customResources,
                event.eventData.groupName,
                true,
              ),
            };
          } else if (event.eventData.navItemType === 'EMPTY_PAGE') {
            objToPush = {
              pages: [],
              label: this.translatedFieldHelperService.findValueToShow(
                event.eventData.pageName,
              ),
              id: uuidv4(),
            };
            objJson = {
              pages: [],
              label: this.processTranslateField(
                'nope',
                this.savedModel.customResources,
                event.eventData.pageName,
                true,
              ),
            };
          } else if (event.eventData.navItemType === 'EMPTY_TAB') {
            objToPush = {
              tabs: [{ sections: [], id: uuidv4() }],
              id: uuidv4(),
            } as any;
            objToPush['label'] = this.processTranslateField(
              'nope',
              this.savedModel.customResources,
              event.eventData.tabName,
              true,
            );
            objToPush.tabs[0]['label'] = this.processTranslateField(
              'nope',
              this.savedModel.customResources,
              event.eventData.tabName,
              true,
            );
          } else if (event.eventData.navItemType === 'PAGE') {
            objToPush = this.savedPages.compiledResult.find(
              (el) => el.id === event.eventData.navType,
            );
            objJson = this.savedPages.jsonResult.find(
              (el) =>
                el?.control?.fieldName === objToPush.control?.fieldName &&
                el?.control?.type === objToPush?.control?.type,
            );
          }
          let indexToPut =
            this.saveEventData.compiledModelToPutUpdate.pages.findIndex(
              (el) => el.id === this.saveEventData.obj1.compiledModel.id,
            );

          if (this.saveEventData.action === DesignerEventAction.ADD_DOWN) {
            indexToPut++;
          }
          this.saveEventData.compiledModelToPutUpdate.pages =
            this.insertAtSpecificPlace(
              this.saveEventData.compiledModelToPutUpdate.pages,
              objToPush,
              indexToPut,
            );
          this.saveEventData.jsonToPutUpdate.pages = this.insertAtSpecificPlace(
            this.saveEventData.jsonToPutUpdate.pages,
            objJson,
            indexToPut,
          );
        }

        this.keepPageOnReload = true;

        this.ResetForm();
        return;
      } else if (
        this.saveEventData.type === DesignerEventType.NAV_ITEM ||
        (this.saveEventData.type === DesignerEventType.NAV_GROUP &&
          this.saveEventData.action === DesignerEventAction.ADD_IN)
      ) {
        if (this.saveEventData.action === DesignerEventAction.REMOVE) {
          let indexToDel =
            this.saveEventData.compiledModelToPutUpdate.pages.findIndex(
              (el) => el.id === this.saveEventData.obj1.compiledModel.id,
            );

          this.saveEventData.jsonToPutUpdate.pages.splice(indexToDel, 1);
          this.saveEventData.compiledModelToPutUpdate.pages.splice(
            indexToDel,
            1,
          );
        } else {
          let objToPush: any = {};
          let objJson: any = {};
          if (event.eventData.navItemType.includes('EMPTY')) {
            if (event.eventData.navItemType === 'EMPTY_PAGE') {
              objToPush = { sections: [], id: uuidv4() } as any;
              objToPush['label'] = this.processTranslateField(
                'nope',
                this.savedModel.customResources,
                event.eventData.pageName,
              );
            } else {
              if (event.eventData.navItemType === 'EMPTY_TAB') {
                objToPush = {
                  tabs: [{ sections: [], id: uuidv4() }],
                  id: uuidv4(),
                } as any;
                objToPush['label'] = this.processTranslateField(
                  'nope',
                  this.savedModel.customResources,
                  event.eventData.tabName,
                );
                objToPush.tabs[0]['label'] = this.processTranslateField(
                  'nope',
                  this.savedModel.customResources,
                  event.eventData.tabName,
                );
              } else {
                //group

                objToPush = { pages: [], id: uuidv4() } as any;

                objToPush['label'] = this.processTranslateField(
                  'nope',
                  this.savedModel.customResources,
                  event.eventData.groupName,
                );
              }
            }
            objJson = JSON.parse(JSON.stringify(objToPush));

            delete objJson['id'];
            if (event.eventData.navItemType === 'EMPTY_TAB') {
              delete objJson.tabs[0]['id'];
            }
          } else {
            objToPush = this.savedPages.compiledResult.find(
              (el) => el.id === event.eventData.navType,
            );
            objJson = this.savedPages.jsonResult.find(
              (el) =>
                el?.control?.fieldName === objToPush.control?.fieldName &&
                el?.control?.type === objToPush?.control?.type,
            );
          }

          if (this.saveEventData.action === DesignerEventAction.ADD) {
            this.saveEventData.compiledModelToPutUpdate.form.layout.pages.push(
              objToPush,
            );
            this.saveEventData.jsonToPutUpdate.form.layout.pages.push(objJson);
          } else if (
            this.saveEventData.action === DesignerEventAction.ADD_IN &&
            this.saveEventData.type === DesignerEventType.NAV_GROUP
          ) {
            this.saveEventData.obj1.compiledModel.pages.push(objToPush);
            this.saveEventData.obj1.savedJson.pages.push(objJson);
          } else if (
            this.saveEventData.action === DesignerEventAction.ADD_UP &&
            this.saveEventData.type === DesignerEventType.NAV_ITEM
          ) {
            let indexToPut =
              this.saveEventData.compiledModelToPutUpdate.pages.findIndex(
                (el) => el.id === this.saveEventData.obj1.compiledModel.id,
              );
            // if (this.saveEventData.action === DesignerEventAction.ADD_UP) {
            //   indexToPut++;
            // }
            this.saveEventData.compiledModelToPutUpdate.pages =
              this.insertAtSpecificPlace(
                this.saveEventData.compiledModelToPutUpdate.pages,
                objToPush,
                indexToPut,
              );
            this.saveEventData.jsonToPutUpdate.pages =
              this.insertAtSpecificPlace(
                this.saveEventData.jsonToPutUpdate.pages,
                objJson,
                indexToPut,
              );
          } else {
            this.saveEventData.compiledModelToPutUpdate.pages.push(objToPush);
            this.saveEventData.jsonToPutUpdate.pages.push(objJson);
          }
        }

        this.ResetForm();
        return;
      } else if (
        this.saveEventData.type === DesignerEventType.NAV_GROUP &&
        this.saveEventData.action === DesignerEventAction.ADD_IN
      ) {
        //add empty page
        let objToPush = {
          sections: [],
          label: event.eventData.label,
          id: uuidv4(),
        };
        let objJson = JSON.parse(JSON.stringify(objToPush));
        delete objJson['id'];
        this.saveEventData.obj1.compiledModel.pages = [
          objToPush,
          ...this.saveEventData.obj1.compiledModel.pages,
        ];
        this.saveEventData.obj1.savedJson.pages = [
          objJson,
          ...this.saveEventData.obj1.savedJson.pages,
        ];

        this.ResetForm();
        return;
      } else if (
        this.saveEventData.type === DesignerEventType.SECTION &&
        this.saveEventData.action !== DesignerEventAction.ADD_IN
      ) {
        if (this.saveEventData.action === DesignerEventAction.REMOVE) {
          let indexToDel =
            this.saveEventData.compiledModelToPutUpdate.sections.findIndex(
              (el) => el.id === this.saveEventData.obj1.compiledModel.id,
            );

          this.saveEventData.jsonToPutUpdate.sections.splice(indexToDel, 1);
          this.saveEventData.compiledModelToPutUpdate.sections.splice(
            indexToDel,
            1,
          );
          this.keepPageOnReload = true;
        } else {
          let objToPush = {
            groups: [],
            location: event.eventData.sectionType,
            id: uuidv4(),
          };

          if (event.eventData.sectionType === 'after') {
            this.saveEventData.obj1.compiledModel.sections = [
              ...this.saveEventData.obj1.compiledModel.sections,
              objToPush,
            ];
            let objJson = JSON.parse(JSON.stringify(objToPush));
            delete objJson['id'];
            this.saveEventData.obj1.savedJson.sections = [
              ...this.saveEventData.obj1.savedJson.sections,
              objJson,
            ];
          } else if (event.eventData.sectionType === 'before') {
            this.saveEventData.obj1.compiledModel.sections = [
              objToPush,
              ...this.saveEventData.obj1.compiledModel.sections,
            ];

            let objJson = JSON.parse(JSON.stringify(objToPush));
            delete objJson['id'];
            this.saveEventData.obj1.savedJson.sections = [
              objJson,
              ...this.saveEventData.obj1.savedJson.sections,
            ];
          } else if (event.eventData.sectionType === 'center') {
            // center
            let saveCompiledModel =
              this.saveEventData.obj1.compiledModel.sections;
            let savedJson = this.saveEventData.obj1.savedJson.sections;
            this.saveEventData.obj1.compiledModel.sections = [];
            if (saveCompiledModel.length == 2) {
              //obj
              saveCompiledModel[0].location = 'before';
              objToPush.location = 'center';
              saveCompiledModel[1].location = 'after';
              this.saveEventData.obj1.compiledModel.sections = [
                saveCompiledModel[0],
                objToPush,
                saveCompiledModel[1],
              ];
              let objJson = JSON.parse(JSON.stringify(objToPush));
              delete objJson['id'];
              //Réorganise json
              savedJson[0].location = 'before';
              savedJson.location = 'center';
              savedJson[1].location = 'after';
              this.saveEventData.obj1.savedJson.sections = [
                savedJson[0],
                objJson,
                savedJson[1],
              ];
            } else {
            }
          } else {
            this.saveEventData.obj1.compiledModel.sections = [
              objToPush,
              ...this.saveEventData.obj1.compiledModel.sections,
            ];

            let objJson = JSON.parse(JSON.stringify(objToPush));
            delete objJson['id'];
            this.saveEventData.obj1.savedJson.sections = [
              objJson,
              ...this.saveEventData.obj1.savedJson.sections,
            ];
          }

          this.keepPageOnReload = true;
        }
      } else if (
        this.saveEventData.type === DesignerEventType.SECTION &&
        this.saveEventData.action === DesignerEventAction.ADD_IN
      ) {
        let objToPush = {
          controls: [],
          label: this.translatedFieldHelperService.findValueToShow(
            event.eventData.label,
          ),
          id: uuidv4(),
        };
        let objJson = JSON.parse(JSON.stringify(objToPush));

        objJson = {
          controls: [],
          label: this.processTranslateField(
            'nope',
            this.savedModel.customResources,
            event.eventData.label,
            true,
          ),
        };

        delete objJson['id'];
        console.log(this.saveEventData.obj1);
        this.saveEventData.obj1.savedJson.groups = [
          objJson,
          ...this.saveEventData.obj1.savedJson.groups,
        ];
        this.saveEventData.obj1.compiledModel.groups = [
          objToPush,
          ...this.saveEventData.obj1.compiledModel.groups,
        ];

        this.keepPageOnReload = true;
      } else if (
        (this.saveEventData.type === DesignerEventType.GROUP &&
          this.saveEventData.action !== DesignerEventAction.ADD_IN) ||
        (this.saveEventData.type === DesignerEventType.SECTION &&
          this.saveEventData.action === DesignerEventAction.ADD_IN)
      ) {
        let objToPush = {
          label: this.translatedFieldHelperService.findValueToShow(
            event.eventData.label,
          ),
          id: uuidv4(),
          controls: [],
        };

        let objJson = JSON.parse(JSON.stringify(objToPush));
        delete objJson['id'];
        objJson.label = this.processTranslateField(
          'nope',
          this.savedModel.customResources,
          event.eventData.label,
        );
        //
        if (this.saveEventData.action === DesignerEventAction.ADD) {
          this.saveEventData.obj1.savedJson.groups = [
            objJson,
            ...this.saveEventData.obj1.savedJson.groups,
          ];
          this.saveEventData.obj1.compiledModel.groups = [
            objToPush,
            ...this.saveEventData.obj1.compiledModel.groups,
          ];
        } else if (this.saveEventData.action === DesignerEventAction.ADD_UP) {
          let indexToPut =
            this.saveEventData.compiledModelToPutUpdate.groups.findIndex(
              (el) => el.id === this.saveEventData.obj1.compiledModel.id,
            );

          this.saveEventData.compiledModelToPutUpdate.groups =
            this.insertAtSpecificPlace(
              this.saveEventData.compiledModelToPutUpdate.groups,
              objToPush,
              indexToPut,
            );
          this.saveEventData.jsonToPutUpdate.groups =
            this.insertAtSpecificPlace(
              this.saveEventData.jsonToPutUpdate.groups,
              objJson,
              indexToPut,
            );
        } else if (this.saveEventData.action === DesignerEventAction.REMOVE) {
          let indexToDel =
            this.saveEventData.compiledModelToPutUpdate.groups.findIndex(
              (el) => el.id === this.saveEventData.obj1.compiledModel.id,
            );

          this.saveEventData.jsonToPutUpdate.groups.splice(indexToDel, 1);
          this.saveEventData.compiledModelToPutUpdate.groups.splice(
            indexToDel,
            1,
          );
        }

        this.keepPageOnReload = true;
      } else if (
        this.saveEventData.type === DesignerEventType.GROUP &&
        this.saveEventData.action === DesignerEventAction.ADD_IN
      ) {
        let ctrl = this.addControl(event);

        this.saveEventData.obj1.savedJson.controls = [
          ctrl.jsonControl,
          ...this.saveEventData.obj1.savedJson.controls,
        ];
        this.saveEventData.obj1.compiledModel.controls = [
          ctrl.objToPush,
          ...this.saveEventData.obj1.compiledModel.controls,
        ];
      } else if (this.saveEventData.type === DesignerEventType.CONTROL) {
        if (this.saveEventData.action === DesignerEventAction.REMOVE) {
          let indexToDel =
            this.saveEventData.compiledModelToPutUpdate.controls.findIndex(
              (el) => el.id === this.saveEventData.obj1.compiledModel.id,
            );
          this.saveEventData.jsonToPutUpdate.controls.splice(indexToDel, 1);
          this.saveEventData.compiledModelToPutUpdate.controls.splice(
            indexToDel,
            1,
          );
        } else {
          let ctrl = this.addControl(event);

          if (this.saveEventData.action === DesignerEventAction.ADD_DOWN) {
            let indexToPut =
              this.saveEventData.compiledModelToPutUpdate.controls.findIndex(
                (el) => el.id === this.saveEventData.obj1.compiledModel.id,
              );

            this.saveEventData.compiledModelToPutUpdate.controls =
              this.insertAtSpecificPlace(
                this.saveEventData.compiledModelToPutUpdate.controls,
                ctrl.objToPush,
                indexToPut + 1,
              );
            this.saveEventData.jsonToPutUpdate.controls =
              this.insertAtSpecificPlace(
                this.saveEventData.jsonToPutUpdate.controls,
                ctrl.jsonControl,
                indexToPut + 1,
              );
          } else {
            this.saveEventData.jsonToPutUpdate.controls.push(ctrl.jsonControl);
            this.saveEventData.compiledModelToPutUpdate.controls.push(
              ctrl.objToPush,
            );
          }
        }

        this.keepPageOnReload = true;
      } else if (
        this.saveEventData.type === DesignerEventType.TAB &&
        this.saveEventData.action === DesignerEventAction.REMOVE
      ) {
        let indexToDel =
          this.saveEventData.compiledModelToPutUpdate.tabs.findIndex(
            (el) => el.id === this.saveEventData.obj1.compiledModel.id,
          );

        this.saveEventData.jsonToPutUpdate.tabs.splice(indexToDel, 1);
        this.saveEventData.compiledModelToPutUpdate.tabs.splice(indexToDel, 1);
        this.keepPageOnReload = true;
      } else if (
        this.saveEventData.type === DesignerEventType.TAB &&
        (this.saveEventData.action === DesignerEventAction.ADD_UP ||
          this.saveEventData.action === DesignerEventAction.ADD_DOWN)
      ) {
        let indexToPut =
          this.saveEventData.compiledModelToPutUpdate.tabs.findIndex(
            (el) => el.id === this.saveEventData.obj1.compiledModel.id,
          );
        if (this.saveEventData.action === DesignerEventAction.ADD_UP) {
          indexToPut++;
        }
        let objToPush: any = {};
        let objJson: any = {};
        if (event.eventData.navItemType.includes('EMPTY')) {
          if (event.eventData.navItemType === 'EMPTY_PAGE') {
            objToPush = {
              sections: [],
              label: this.translatedFieldHelperService.findValueToShow(
                event.eventData.pageName,
              ),
              id: uuidv4(),
            } as any;
            objJson = {
              sections: [],
              label: this.processTranslateField(
                'nope',
                this.savedModel.customResources,
                event.eventData.pageName,
              ),
            } as any;
          }
          if (event.eventData.navItemType === 'EMPTY_TAB') {
            objToPush = {
              tabs: [
                {
                  sections: [],
                  label: this.translatedFieldHelperService.findValueToShow(
                    event.eventData.tabName,
                  ),
                  id: uuidv4(),
                },
              ],
              label: this.translatedFieldHelperService.findValueToShow(
                event.eventData.tabName,
              ),
              id: uuidv4(),
            } as any;
            objJson = {
              tabs: [
                {
                  sections: [],
                  label: this.processTranslateField(
                    'nope',
                    this.savedModel.customResources,
                    event.eventData.tabName,
                  ),
                },
              ],
              label: this.processTranslateField(
                'nope',
                this.savedModel.customResources,
                event.eventData.tabName,
              ),
            } as any;
          }

          //objJson = JSON.parse(JSON.stringify(objToPush));
          //delete objJson['id'];
          if (event.eventData.navItemType === 'EMPTY_TAB') {
            //delete objJson.tabs[0]['id'];
          }
        } else {
          objToPush = this.savedPages.compiledResult.find(
            (el) => el.id === event.eventData.navType,
          );
          objJson = this.savedPages.jsonResult.find(
            (el) =>
              el?.control?.fieldName === objToPush.control?.fieldName &&
              el?.control?.type === objToPush?.control?.type,
          );
        }
        this.saveEventData.compiledModelToPutUpdate.tabs =
          this.insertAtSpecificPlace(
            this.saveEventData.compiledModelToPutUpdate.tabs,
            objToPush,
            indexToPut,
          );
        // this.saveEventData.compiledModelToPutUpdate.tabs = [
        //   ...this.saveEventData.compiledModelToPutUpdate.tabs.splice(0,indexToPut),
        //   objToPush,
        //   ...this.saveEventData.compiledModelToPutUpdate.tabs.splice(indexToPut)
        // ];
        this.saveEventData.jsonToPutUpdate.tabs = this.insertAtSpecificPlace(
          this.saveEventData.jsonToPutUpdate.tabs,
          objJson,
          indexToPut,
        );

        this.keepPageOnReload = true;
      }

      this.addSystemField();

      this.formGroup = await this.loader.create(this.modelState, true, true);

      this.items = this.workFormCoreService.createItems(
        this.formGroup,
        this.modelState.model,
        true,
      );

      this.onChange.emit({ model: this.savedModel });
    });
  }

  /** Remove System field */
  removeSystemField() {
    if (this.modelState?.model?.form?.layout?.pages != undefined) {
      this.modelState.model.form.layout.pages =
        this.modelState.model.form.layout.pages.filter(
          (s) => s['isSystem'] !== true,
        );
    }
    if (this.savedModel?.form?.layout?.pages != undefined) {
      this.savedModel.form.layout.pages =
        this.savedModel.form.layout.pages.filter((s) => s['isSystem'] !== true);
    }
  }

  /** Vérifie et ajout les field requis en masqué */
  addSystemField() {
    // Vérification si status ID est connu

    let searchMissing = ['statusId'];
    let addMissingControlCompiled = [];
    let addMissingControlJson = [];
    //TODO rend plus paramètrable pour d'autre type de formulaire si champs requis
    for (let fieldName of searchMissing) {
      let fieldId = FormGroupHelpers.findControlInModel(
        this.savedModel?.form?.layout?.pages,
        fieldName,
      );
      if (fieldId == undefined) {
        let fieldCompile = this.savedControls.compiledResult.find(
          (s) => s.fieldName == fieldName,
        );
        let fieldJson = this.savedControls.jsonResult.find(
          (s) => s.fieldName == fieldName,
        );
        // Création d'un groupe caché
        if (fieldCompile != undefined) {
          fieldCompile.isSystem = true;
          fieldCompile.forceLoading = true;
          addMissingControlCompiled.push(fieldCompile);
        }
        if (fieldJson != undefined) {
          fieldJson.isSystem = true;
          fieldJson.forceLoading = true;
          addMissingControlJson.push(fieldJson);
        }
      }
    }

    let pageSection: PageSection = {
      label: 'EmtpyPageSystem',
      visible: false,
      isSystem: true,
      forceLoading: true,
      name: 'EmtpyPageSystem',
      sections: [
        {
          groups: [
            {
              label: 'EmptyGroup',
              name: 'EmptyGroup',
              controls: [],
            },
          ],
        },
      ],
    };
    if (addMissingControlCompiled.length > 0) {
      pageSection.sections[0].groups[0].controls = addMissingControlCompiled;
      this.modelState.model.form.layout.pages.push(pageSection);
    }
    if (addMissingControlJson.length > 0) {
      pageSection.sections[0].groups[0].controls = addMissingControlJson;
      this.savedModel.form.layout.pages.push(pageSection);
    }
  }

  ngAfterContentChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  isInit = false;

  /** Obtient ou définit l'item du menu courant.*/
  currentItem;
  formGroup: FormGroup;
  modelState: ModelState;
  savedModel: any;
  originalModel: any;
  savedCompiledModel: ModelState;

  savedControls: { compiledResult: Array<any>; jsonResult: Array<any> };
  savedPages: { compiledResult: Array<any>; jsonResult: Array<any> };

  resetComponent = true;
  resetNavMenu = false;
  activeItem: ComponentRefDescription;
  isFormLoaded = true;
  typeForDynamicProperties = '';
  onSaved = new EventEmitter<any>();
  private readonly componentRefs: [ComponentRefDescription?] = [];
  @ViewChild(FormDesignerPopupComponent)
  popupComponent: FormDesignerPopupComponent;
  /** Obtient les hosts. */
  @ViewChildren(CoreWorkItemHostDirective)
  hosts: QueryList<CoreWorkItemHostDirective>;

  @Input() items: WorkItem[];

  onKeyDown(_) {}
  typeDynamicPropArray = [];
  ResetForm(init = false) {
    this.resetComponent = true;
    setTimeout((_) => {
      this.modelCompiler
        .compile(this.savedModel, true)
        .subscribe(async (modelState) => {
          this.modelState = modelState;
          this.modelState.sharedContext.params.set(
            'formDesignerMode',
            () => true,
          );

          this.modelState.form = this.formGroup;
          this.modelState.formComponent = this as any;
          this.formGroup = await this.loader.create(
            this.modelState,
            true,
            true,
          );
          this.items = this.workFormCoreService.createItems(
            this.formGroup,
            this.modelState.model,
            true,
          );
          this.isInit = true;
          this.resetComponent = false;

          if (init === true) {
          } else {
            this.onChange.emit({ model: this.savedModel });
          }

          this.modelState.on.subscribe((event) => {
            this.manageEvent(event);
          });
        });
    });
  }

  findObjectById(obj: object, id: string, idKey = 'id'): object {
    let authorizedKeysToGoIn = [
      'id',
      'form',
      'layout',
      'control',
      'controls',
      'sections',
      'tabs',
      'pages',
      'groups',
      'items',
    ];

    if (obj == undefined) {
      return null;
    }

    if (Array.isArray(obj)) {
      //case array
      for (let keyValueItem of obj as Array<any>) {
        let foundedOrNot = this.findObjectById(keyValueItem, id, idKey);

        if (foundedOrNot != null) {
          return foundedOrNot;
        }
      }
    } else {
      //case obj
      let keys = Object.keys(obj);

      for (let key of keys) {
        let keyValue = obj[key];

        if (key === idKey && keyValue === id) {
          return obj;
        }

        if (authorizedKeysToGoIn.includes(key) === false) {
          continue;
        }

        if (Array.isArray(keyValue) || typeof keyValue === 'object') {
          let items = Array.isArray(keyValue)
            ? (keyValue as Array<any>)
            : Object.values(keyValue);

          for (let keyValueItem of items) {
            let foundedOrNot = this.findObjectById(keyValueItem, id, idKey);

            if (foundedOrNot != null) {
              return foundedOrNot;
            }
          }
        }
      }
    }
    return null;
  }

  addItem(
    type: DesignerEventType,
    action: DesignerEventAction,
    obj1: {
      compiledModel: any;
      savedJson: any;
      actionDone: boolean;
    },
    compiledModelToPutUpdate: any,
    jsonToPutUpdate: any,
    event: DesignerEventData,
  ) {
    setTimeout(async (_) => {
      this.saveEventData = {
        action: action,
        type: type,
        obj1: obj1,
        jsonToPutUpdate: jsonToPutUpdate,
        compiledModelToPutUpdate: compiledModelToPutUpdate,
      };
      this.popupComponent.canUseStatus = this.canUseStatus;
      this.popupComponent.qualification = this.qualification;
      this.popupComponent.formDesignerMode = this.formDesignerMode;
      this.popupComponent.formDesignerTo = this.formDesignerTo;

      if (action === DesignerEventAction.EDIT) {
        let keyFieldName = this.saveEventData.obj1.savedJson?.fieldName;

        switch (type) {
          case DesignerEventType.PAGE:
            break;
          case DesignerEventType.SECTION:
            this.popupComponent.startEditPopup(type, {
              location: this.saveEventData.obj1.compiledModel.location,
              sections: this.saveEventData.compiledModelToPutUpdate.sections,
            });
            break;
          case DesignerEventType.CONTROL:
            let controlStatuses = this.statusValues();
            let data = {
              label: this.getCustomResourceKey(
                this.saveEventData.obj1.savedJson.label,
              ),

              required:
                controlStatuses.requireValidator != undefined ||
                (controlStatuses.depOnStatusIds != undefined &&
                  controlStatuses.depOnStatusIds.length > 0),
              ids: controlStatuses.depOnStatusIds,
              editStatusIds: controlStatuses.depOnEditStatusIds,
              editable:
                this.saveEventData.obj1.savedJson?.readOnly != undefined
                  ? !this.saveEventData.obj1.savedJson?.readOnly
                  : true,
              fieldName: keyFieldName,
            };

            this.popupComponent.startEditPopup(type, data);
            break;
          case DesignerEventType.NAV_GROUP:
          case DesignerEventType.GROUP:
          case DesignerEventType.NAV_ITEM:
          case DesignerEventType.TAB:
            let controlStatusesGroup = this.statusValues();

            // Problem de control sans fieldNAme (type onglet note) et avec une key en traduction pour le modele rule
            if (
              keyFieldName == undefined &&
              this.saveEventData.obj1?.compiledModel?.control?.designerOptions
                ?.fieldName != undefined
            ) {
              keyFieldName =
                this.saveEventData.obj1?.compiledModel?.control?.designerOptions
                  ?.fieldName;
            }
            // cas spécific de certain componet
            let specificComponent = [
              'MessageTicketComponent',
              'TicketEmailListComponent',
            ];
            if (
              keyFieldName == undefined &&
              this.saveEventData.obj1?.compiledModel?.control?.type !=
                undefined &&
              specificComponent.indexOf(
                this.saveEventData.obj1?.compiledModel?.control?.type,
              ) >= 0
            ) {
              keyFieldName =
                this.saveEventData.obj1?.compiledModel?.control?.type;
            }
            // Spécifique si fieldname sur le control enfant
            if (
              keyFieldName == undefined &&
              this.saveEventData.obj1.savedJson?.control?.fieldName != undefined
            ) {
              keyFieldName =
                this.saveEventData.obj1.savedJson?.control?.fieldName;
            }
            // Tenantative via le key de traduction
            if (
              keyFieldName == undefined &&
              this.saveEventData.obj1?.savedJson?.control?.label != undefined
            ) {
              keyFieldName =
                'key' + this.saveEventData.obj1?.savedJson?.control?.label;
            }

            this.popupComponent.startEditPopup(type, {
              fieldName: keyFieldName,
              label: this.getCustomResourceKey(
                this.saveEventData.obj1.savedJson.label,
              ),
              required:
                controlStatusesGroup.requireValidator != undefined ||
                (controlStatusesGroup.depOnStatusIds != undefined &&
                  controlStatusesGroup.depOnStatusIds.length > 0),
              ids: controlStatusesGroup.depOnStatusIds,
              editStatusIds: controlStatusesGroup.depOnEditStatusIds,
              editable:
                this.saveEventData.obj1.savedJson?.readOnly != undefined
                  ? !this.saveEventData.obj1.savedJson?.readOnly
                  : true,
            });
            break;
        }
      } else if (type === DesignerEventType.CONTROL) {
        if (action === DesignerEventAction.REMOVE) {
          this.popupSave({ eventKey: 'SAVE', eventData: 'NONE' });
        } else {
          this.popupComponent.startPopup(DesignerEventType.CONTROL, {
            controls: this.savedControls,
            usedControls: this.extractControls(
              this.modelState.model,
              this.savedModel,
            ),
            compiledModelToPutUpdate: compiledModelToPutUpdate,
          });
        }
      } else if (type === DesignerEventType.GROUP) {
        if (action === DesignerEventAction.ADD_IN) {
          this.popupComponent.startPopup(DesignerEventType.CONTROL, {
            controls: this.savedControls,
            usedControls: this.extractControls(
              this.modelState.model,
              this.savedModel,
            ),
          });
        } else if (
          action === DesignerEventAction.ADD ||
          action === DesignerEventAction.ADD_UP
        ) {
          this.popupComponent.startPopup(DesignerEventType.GROUP);
        } else if (action === DesignerEventAction.REMOVE) {
          this.popupSave({ eventKey: 'SAVE', eventData: 'NONE' });
        }
        this.keepPageOnReload = true;
      } else if (type === DesignerEventType.SECTION) {
        if (action === DesignerEventAction.ADD_IN) {
          this.popupComponent.startPopup(DesignerEventType.GROUP);
        } else if (action === DesignerEventAction.REMOVE) {
          // let indexToDel =
          //   this.saveEventData.compiledModelToPutUpdate.sections.findIndex(
          //     (el) => el.id === this.saveEventData.obj1.compiledModel.id,
          //   );

          // this.saveEventData.jsonToPutUpdate.sections.splice(indexToDel, 1);
          // this.saveEventData.compiledModelToPutUpdate.sections.splice(
          //   indexToDel,
          //   1,
          // );
          // this.keepPageOnReload = true;
          this.popupSave({ eventKey: 'SAVE', eventData: 'NONE' });
        } else {
          this.popupComponent.startPopup(
            DesignerEventType.SECTION,
            obj1.compiledModel.sections,
          );
        }
      } else if (type === DesignerEventType.NAV_ITEM) {
        if (
          action === DesignerEventAction.ADD_UP ||
          action === DesignerEventAction.ADD_DOWN ||
          action === DesignerEventAction.ADD
        ) {
          this.popupComponent.startPopup(DesignerEventType.NAV_ITEM, {
            controls: this.savedPages,
            usedControls: this.extractControls(
              this.modelState.model,
              this.savedModel,
              true,
            ),
            isRootItem: event.other,
          });
        } else if (action === DesignerEventAction.REMOVE) {
          this.popupSave({ eventKey: 'SAVE', eventData: 'NONE' });
        }
      } else if (type === DesignerEventType.NAV_GROUP) {
        if (action === DesignerEventAction.ADD_IN) {
          this.popupComponent.startPopup(DesignerEventType.NAV_ITEM, {
            controls: this.savedPages,
            usedControls: this.extractControls(
              this.modelState.model,
              this.savedModel,
              true,
            ),
            isRootItem: false,
          });
        } else if (
          action === DesignerEventAction.ADD_UP ||
          action === DesignerEventAction.ADD_DOWN
        ) {
          this.popupComponent.startPopup(DesignerEventType.NAV_ITEM, {
            controls: this.savedPages,
            usedControls: this.extractControls(
              this.modelState.model,
              this.savedModel,
              true,
            ),
            isRootItem: true,
          });
        } else if (action === DesignerEventAction.REMOVE) {
          this.popupSave({ eventKey: 'SAVE', eventData: 'NONE' });
        } else {
          this.popupComponent.startPopup(DesignerEventType.NAV_GROUP);
        }
      } else if (type === DesignerEventType.TAB) {
        if (
          action === DesignerEventAction.ADD_UP ||
          action === DesignerEventAction.ADD_DOWN
        ) {
          this.popupComponent.startPopup(DesignerEventType.TAB, {
            controls: this.savedPages,
            usedControls: this.extractControls(
              this.modelState.model,
              this.savedModel,
              true,
            ),
            isRootItem: false,
            compiledModelToPutUpdate: compiledModelToPutUpdate,
          });
        } else if (action === DesignerEventAction.REMOVE) {
          this.popupSave({ eventKey: 'SAVE', eventData: 'NONE' });
        }
      }
    });
  }

  /** Obtient les status conditionnel */
  statusValues() {
    let requireValidator = this.saveEventData.obj1.savedJson?.validators?.find(
      (el) => el.name === 'required' || el.name === 'notNullOrEmpty',
    );
    let dependOns = this.saveEventData.obj1.savedJson?.dependsOns; // ;?.options?.value;

    let requireIfValueIn = dependOns?.find(
      (f) => f.effect == 'RequireIfValueIn',
    );

    //retro compatibilité v1
    if (requireIfValueIn == undefined) {
      if (
        this.saveEventData.obj1.savedJson?.dependsOn?.effect ==
        'RequireIfValueIn'
      ) {
        requireIfValueIn = this.saveEventData.obj1.savedJson?.dependsOn;
      }
    }

    let editIfValueIn = dependOns?.find((f) => f.effect == 'EditIfValueIn');
    let depOnStatusIds =
      requireIfValueIn != undefined
        ? requireIfValueIn?.options?.value
        : undefined;
    let depOnEditStatusIds =
      editIfValueIn != undefined ? editIfValueIn?.options?.value : undefined;

    return {
      requireValidator: requireValidator,
      depOnStatusIds: depOnStatusIds,
      depOnEditStatusIds: depOnEditStatusIds,
    };
  }

  saveEventData: {
    type: DesignerEventType;
    action: DesignerEventAction;
    obj1: {
      compiledModel: any;
      savedJson: any;
      actionDone: boolean;
    };
    compiledModelToPutUpdate: any;
    jsonToPutUpdate: any;
  };

  addTextBoxUpHere(
    id: string,
    obj: any,
    type: DesignerEventType,
    action: DesignerEventAction,
    savedJson: string,
    eventData: any,
  ): {
    compiledModel: any;
    savedJson: any;
    actionDone: boolean;
  } {
    let authorizedKeysToGoIn = [
      'form',
      'layout',
      'control',
      'controls',
      'sections',
      'tabs',
      'pages',
      'groups',
      'items',
    ];

    if (id == undefined) {
      this.addItem(
        type,
        action,
        {
          compiledModel: obj,
          savedJson: savedJson,
          actionDone: false,
        },
        obj,
        savedJson,
        eventData,
      );
      return;
    }
    if (obj == undefined || savedJson == undefined) {
      return null;
    }

    if (Array.isArray(obj)) {
      //case array
      for (let cpt = 0; cpt < (obj as Array<any>).length; cpt++) {
        let keyValueItem = (obj as Array<any>)[cpt];

        let foundedOrNot = this.addTextBoxUpHere(
          id,
          keyValueItem,
          type,
          action,
          savedJson[cpt],
          eventData,
        );

        if (foundedOrNot?.savedJson != undefined) {
          if (foundedOrNot.actionDone === false) {
            foundedOrNot.actionDone = true;
            this.addItem(type, action, foundedOrNot, obj, savedJson, eventData);
          }
          return foundedOrNot;
        }
      }
    } else {
      //case obj
      let keys = Object.keys(obj);

      for (let key of keys) {
        let keyValue = obj[key];
        let savedJsonKeyValue = savedJson[key === 'items' ? 'pages' : key];

        if (key === 'items' && savedJsonKeyValue == undefined) {
          savedJsonKeyValue = savedJson;
        }
        if (keyValue === id && key === 'id') {
          return {
            compiledModel: obj,
            savedJson: savedJson,
            actionDone: false,
          };
        }

        if (authorizedKeysToGoIn.includes(key) === false) {
          continue;
        }

        if (Array.isArray(keyValue) || typeof keyValue === 'object') {
          let items = Array.isArray(keyValue)
            ? (keyValue as Array<any>)
            : Object.values(keyValue);
          let savedJsonItems = Array.isArray(savedJsonKeyValue)
            ? (savedJsonKeyValue as Array<any>)
            : Object.values(savedJsonKeyValue);

          for (let cpt = 0; cpt < items.length; cpt++) {
            let keyValueItem = items[cpt];

            let foundedOrNot = this.addTextBoxUpHere(
              id,
              keyValueItem,
              type,
              action,
              savedJsonItems[cpt],
              eventData,
            );

            if (foundedOrNot?.savedJson != undefined) {
              if (foundedOrNot.actionDone === false) {
                foundedOrNot.actionDone = true;
                this.addItem(
                  type,
                  action,
                  foundedOrNot,
                  obj,
                  savedJson,
                  eventData,
                );
              }
              return foundedOrNot;
            }
          }
        }
      }
    }
    return null;
  }

  manageEvent(event: { eventName: string; eventData: DesignerEventData }) {
    if (event.eventName === DesignerEventKey) {
      if (
        event.eventData.type === DesignerEventType.NAV_ITEM ||
        event.eventData.type === DesignerEventType.NAV_GROUP
      ) {
        this.addTextBoxUpHere(
          event.eventData.id,
          this.modelState.model,
          event.eventData.type,
          event.eventData.action,
          this.savedModel,
          event.eventData,
        );
      } else {
        this.addTextBoxUpHere(
          event.eventData.id,
          this.modelState.model,
          event.eventData.type,
          event.eventData.action,
          this.savedModel,
          event.eventData,
        );
      }
    }
  }

  /** Permet de précharger tout les controle est page de base */
  async getDefaultControls() {
    this.savedControls = this.extractControls(
      this.savedCompiledModel.model,
      this.originalModel,
    );

    this.savedPages = this.extractControls(
      this.savedCompiledModel.model,
      this.originalModel,
      true,
    );
  }

  /**Set les modeles de base et en cours */
  applyModels(originalModel, jsonModel) {
    this.savedModel = JSON.parse(JSON.stringify(jsonModel));
    this.originalModel = JSON.parse(JSON.stringify(originalModel));
    this.modelCompiler
      .compile(originalModel, true)
      .subscribe(async (modelState) => {
        this.savedCompiledModel = modelState;
        await this.getDefaultControls();
      });
    this.ResetForm(true);
    localStorage.removeItem('designer-tab-selected');
  }

  ngOnInit() {
    if (this.jsonModel != undefined) {
      //apply de base en edition pour préchargé les données
      this.applyModels(this.jsonModel, this.jsonModel);
    } else {
      console.error('Model not available');
    }
    if (this.popupComponent != undefined) {
      this.popupComponent.canUseStatus = this.canUseStatus;
      this.popupComponent.qualification = this.qualification;
      this.popupComponent.formDesignerMode = this.formDesignerMode;
      this.popupComponent.formDesignerTo = this.formDesignerTo;
    }
  }

  keepPageOnReload = false;

  ngAfterViewInit() {
    let changes = this.hosts.changes;
    if (this.hosts.length > 0) {
      changes = changes.pipe(startWith(this.hosts));
    }
    let unsubscribe = changes.subscribe((_) => {
      this.initializeContainers(this.items);

      // Normalement, il y a obligatoirement 1 élément.
      if (this.items.length > 0 && this.items[0].items.length > 0) {
        if (this.keepPageOnReload === true) {
          this.keepPageOnReload = false;
          let item = this.findObjectById(
            this.items,
            this.currentItem.name,
            'name',
          );
          if (item != undefined) {
            this.currentItem = item;
          } else {
            this.currentItem = this.items[0].items[0];
          }
        } else {
          this.currentItem = this.items[0].items[0];
        }
      }

      this.load(this.currentItem);

      this._changeDetectorRef.detectChanges();
    });
  }

  /** Lors de la navigation dans le menu. */
  selectionChanged(e = null) {
    this.currentItem = e;
    this.load(this.currentItem);
  }

  /** Charge un item. */
  load(item) {
    if (
      this.activeItem != undefined &&
      this.activeItem.component.instance.onDeactivated !== undefined
    ) {
      this.activeItem.component.instance.onDeactivated();
    }
    let componentRef = this.findComponentRefByItem(item);
    if (componentRef != undefined) {
      if (componentRef.component === undefined) {
        // Here
        let componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(item.content);
        componentRef.component =
          componentRef.host.viewContainerRef.createComponent(componentFactory);

        componentRef.component.instance.state = this.modelState;
        componentRef.component.instance.designerMode = true;
        componentRef.component.instance.designerId = item.id;

        if (item.data != undefined) {
          // Passe des données aux composent
          componentRef.component.instance.data = item.data;
        }
      } else {
        if (componentRef.component.instance.onActivated !== undefined) {
          componentRef.component.instance.onActivated();
        }
      }
    }
    this.activeItem = componentRef;
  }

  /** Trouve  */
  private findComponentRefByItem(item) {
    for (let componentRef of this.componentRefs) {
      if (componentRef.item === item) {
        return componentRef;
      }
    }
    return undefined;
  }

  private initializeContainers(items, index: number = 0) {
    let hosts = this.hosts.toArray();

    for (let item of items) {
      if (item.items === undefined) {
        this.componentRefs.push({
          item: item,
          index: index,
          host: hosts[index],
        });
        index++;
      } else {
        index = this.initializeContainers(item.items, index);
      }
    }
    return index;
  }
}
