<dx-toolbar class="cl-toolbar-diagram">
  <dxi-item location="before" widget="dxButton" [options]="switchOptions">
  </dxi-item>
  <dxi-item
    location="before"
    widget="dxCheckBox"
    [options]="autoSelectOptions"
    [visible]="displayMode === 'Diagram'"
  >
  </dxi-item>
</dx-toolbar>

<div class="error" *ngIf="isValid === false">
  <div>
    <p *ngIf="errors.noEntry != undefined">
      {{ 'entities/status-workflow/error/noEntry' | translate }}
    </p>
    <p *ngIf="errors.noEnding != undefined">
      {{ 'entities/status-workflow/error/noEnding' | translate }}
    </p>
    <p *ngIf="errors.noNode != undefined">
      {{ 'entities/status-workflow/error/noNode' | translate }}
    </p>
    <p *ngIf="errors.edgeNotValid != undefined">
      {{ 'entities/status-workflow/error/edgeNotValid' | translate }}
    </p>
    <p *ngIf="errors.nodeNotLinked != undefined">
      {{ 'entities/status-workflow/error/nodeNotLinked' | translate }}
    </p>
    <p *ngIf="errors.hasDuplicate != undefined">
      {{ 'entities/status-workflow/error/hasDuplicate' | translate }}
    </p>
    <p *ngIf="errors.outFromEnding != undefined">
      {{ 'entities/status-workflow/error/outFromEnding' | translate }}
    </p>
    <p *ngIf="errors.nodeBlockedState != undefined">
      {{ 'entities/status-workflow/error/nodeBlockedState' | translate }}
    </p>
    <p *ngIf="errors.withoutStatus != undefined">
      {{ 'entities/status-workflow/error/withoutStatus' | translate }}
    </p>
  </div>
</div>

<dx-diagram
  [visible]="displayMode === 'Diagram'"
  #diagram
  id="diagram"
  customShapeToolboxTemplate="customShapeToolboxTemplate"
  customShapeTemplate="customShapeTemplate"
  style="height: 92%"
  (onRequestEditOperation)="requestEditOperationHandler($event)"
  (onRequestLayoutUpdate)="requestLayoutUpdateHandler($event)"
  (onItemClick)="onItemClick($event)"
>
  <dxo-context-menu
    [enabled]="true"
    [commands]="[
      'copy',
      'paste',
      'cut',
      'delete',
      'selectAll',
      'lock',
      'unlock',
    ]"
  >
  </dxo-context-menu>
  <dxi-custom-shape
    type="status"
    baseType="rectangle"
    category="status"
    title="nouveau Status"
    [defaultWidth]="1.5"
    [defaultHeight]="1"
    [toolboxWidthToHeightRatio]="2"
    [minWidth]="1.5"
    [minHeight]="1"
    [maxWidth]="3"
    [maxHeight]="2"
    [allowEditText]="false"
  >
  </dxi-custom-shape>
  <dxo-history-toolbar [visible]="false"> </dxo-history-toolbar>
  <dxo-properties-panel visibility="disabled"></dxo-properties-panel>

  <svg
    *dxTemplate="let item of 'customShapeTemplate'"
    class="template"
    #svgElement
  >
    <text class="template-title" x="50%" y="10%">
      <tspan
        *ngFor="let tspan of generateTspans(getStatusPointValue(item.dataItem))"
        x="50%"
        dy="1em"
      >
        {{ tspan }}
      </tspan>
    </text>

    @if (getTextPointValue(item.dataItem).length > 1) {
      <text class="template-name" x="50%" y="30%">
        @for (value of getTextPointValue(item.dataItem); track $index) {
          <tspan x="50%" dy="1.2em">
            {{ value }}
          </tspan>
        }
      </text>
    } @else {
      <text class="template-name" x="50%" y="50%">
        <tspan x="50%" y="45%" dy="1.2em">
          {{ getTextPointValue(item.dataItem) }}
        </tspan>
      </text>
    }

    <text class="template-button" x="20%" y="90%" (click)="edit(item.dataItem)">
      {{ 'entities/status-workflow/edit' | translate }}
    </text>
    <text
      class="template-button right-button"
      x="72%"
      y="90%"
      (click)="remove(item?.dataItem?.objId)"
    >
      {{ 'entities/status-workflow/delete' | translate }}
    </text>
  </svg>
  <svg *dxTemplate="let item of 'customShapeToolboxTemplate'" class="template">
    <text x="50%" y="40%">Nouveau</text>
    <text x="50%" y="70%">Statut</text>
  </svg>

  <dxo-nodes [dataSource]="flowNodesDataSource" keyExpr="objId" typeExpr="type">
  </dxo-nodes>
  <dxo-toolbox visibility="disabled"></dxo-toolbox>
  <dxo-edges
    [toPointIndexExpr]="'toPointIndex'"
    [fromPointIndexExpr]="'fromPointIndex'"
    [dataSource]="flowEdgesDataSource"
    keyExpr="objId"
    fromExpr="fromId"
    toExpr="toId"
  >
  </dxo-edges>
  <dxo-context-toolbox [width]="100" [shapeIconsPerRow]="1">
  </dxo-context-toolbox>
</dx-diagram>

<dx-popup
  [width]="1000"
  [height]="'630px'"
  [showTitle]="true"
  [title]="'entities/status-workflow/edit' | translate"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [(visible)]="isPopupOpen"
>
  <dx-scroll-view width="100%" height="100%">
    <div class="dx-fieldset cl-fieldset">
      <dx-box direction="row" width="100%" [height]="'100%'">
        <dxi-item [ratio]="1">
          <clc-work-item-section-group>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/status' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-select-box
                  *ngIf="currentEdit != undefined"
                  #selectBox
                  [dataSource]="statusDatasource.datasource"
                  [valueExpr]="'id'"
                  [value]="currentEdit.statusId"
                  (onInitialized)="onComboboxInit($event)"
                  [displayExpr]="getDisplayExpr()"
                  (onValueChanged)="selectBoxValueChanged($event)"
                ></dx-select-box>
              </div>
            </div>
          </clc-work-item-section-group>
          <clc-work-item-section-group
            [title]="'entities/status-workflow/position' | translate"
          >
            <div class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/start' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit !== undefined"
                  [value]="currentEdit.isEntry"
                  (onValueChanged)="checkBoxValueChanged($event)"
                ></dx-check-box>
              </div>
            </div>
            <div
              *ngIf="logicDiagramView.isTakingCharge === true"
              class="dx-field"
            >
              <div class="dx-field-label">
                {{ 'entities/status-workflow/takeCharge' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [value]="currentEdit.isTakingCharge"
                  (onValueChanged)="checkBoxValueChangedTakeCharge($event)"
                ></dx-check-box>
              </div>
            </div>

            <div
              *ngIf="logicDiagramView.isEndTreatment === true"
              class="dx-field"
            >
              <div class="dx-field-label">
                {{ 'entities/status-workflow/endTreatment' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [value]="currentEdit.isEndTreatment"
                  (onValueChanged)="checkBoxValueChangedEndTreament($event)"
                ></dx-check-box>
              </div>
            </div>

            <div *ngIf="logicDiagramView.isEnding === true" class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/end' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [value]="currentEdit.isEnding"
                  (onValueChanged)="checkBoxValueChanged2($event)"
                ></dx-check-box>
              </div>
            </div>

            <div *ngIf="logicDiagramView.isApplySla === true" class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/isApplySla' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [value]="currentEdit.isApplySla"
                  (onValueChanged)="checkBoxValueChangedIsApplySla($event)"
                ></dx-check-box>
              </div>
            </div>
          </clc-work-item-section-group>

          <clc-work-item-section-group
            [title]="'entities/status-workflow/actions' | translate"
          >
            <div
              *ngIf="logicDiagramView.isCommentaryRequired === true"
              class="dx-field"
            >
              <div class="dx-field-label">
                {{ 'entities/status-workflow/commentaryRequired' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [value]="currentEdit.isCommentaryRequired"
                  (onValueChanged)="
                    checkBoxValueChangedCommentaryRequired($event)
                  "
                ></dx-check-box>
              </div>
            </div>
            <div
              *ngIf="logicDiagramView.allTaskFinished === true"
              class="dx-field"
            >
              <div class="dx-field-label" style="overflow: inherit !important">
                {{ 'entities/status-workflow/allTaskFinished' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [(value)]="currentEdit.rightToMove.allTaskFinished"
                >
                </dx-check-box>
              </div>
            </div>
            <div
              *ngIf="logicDiagramView.canAddSatisfaction === true"
              class="dx-field"
            >
              <div class="dx-field-label" style="overflow: inherit !important">
                {{ 'entities/status-workflow/canAddSatisfaction' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit !== undefined"
                  [(value)]="currentEdit.canAddSatisfaction"
                >
                </dx-check-box>
              </div>
            </div>
            <div
              *ngIf="logicDiagramView.isForbiddenPredecessor === true"
              class="dx-field"
            >
              <div class="dx-field-label" style="overflow: inherit !important">
                {{
                  'entities/status-workflow/forbiddenPredecessor' | translate
                }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit !== undefined"
                  [(value)]="currentEdit.isForbiddenPredecessor"
                  [disabled]="checkboxForbiddenState"
                >
                </dx-check-box>
              </div>
            </div>
          </clc-work-item-section-group>
        </dxi-item>

        <dxi-item [ratio]="1">
          <clc-work-item-section-group
            [title]="'entities/status-workflow/right' | translate"
          >
            <div *ngIf="logicDiagramView.allOperator === true" class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/allOperators' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [(value)]="currentEdit.rightToMove.allOperator"
                >
                </dx-check-box>
              </div>
            </div>

            <div
              *ngIf="logicDiagramView.referentTeam === true"
              class="dx-field"
            >
              <div class="dx-field-label">
                {{ 'entities/status-workflow/referentTeam' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [(value)]="currentEdit.rightToMove.referentTeam"
                >
                </dx-check-box>
              </div>
            </div>
            <div
              *ngIf="logicDiagramView.operatorReferent === true"
              class="dx-field"
            >
              <div class="dx-field-label">
                {{ 'entities/status-workflow/operatorReferent' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [(value)]="currentEdit.rightToMove.operatorReferent"
                >
                </dx-check-box>
              </div>
            </div>

            <div
              *ngIf="logicDiagramView.affectedTeam === true"
              class="dx-field"
            >
              <div class="dx-field-label">
                {{ 'entities/status-workflow/affectedTeam' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [(value)]="currentEdit.rightToMove.affectedTeam"
                >
                </dx-check-box>
              </div>
            </div>

            <div
              *ngIf="logicDiagramView.operatorAffected === true"
              class="dx-field"
            >
              <div class="dx-field-label">
                {{ 'entities/status-workflow/operatorAffected' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [(value)]="currentEdit.rightToMove.operatorAffected"
                >
                </dx-check-box>
              </div>
            </div>

            <div
              *ngIf="logicDiagramView.allConcerned === true"
              class="dx-field"
            >
              <div class="dx-field-label">
                {{ 'entities/status-workflow/allConcerned' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [(value)]="currentEdit.rightToMove.allConcerned"
                  (onValueChanged)="allConcernedChanged($event)"
                >
                </dx-check-box>
              </div>
            </div>
            <div *ngIf="logicDiagramView.makeRequest === true" class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/makeRequest' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [disabled]="currentEdit.rightToMove.allConcerned === true"
                  [(value)]="currentEdit.rightToMove.makeRequest"
                >
                </dx-check-box>
              </div>
            </div>
            <div *ngIf="logicDiagramView.affected === true" class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/affected' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [disabled]="currentEdit.rightToMove.allConcerned === true"
                  [(value)]="currentEdit.rightToMove.affected"
                ></dx-check-box>
              </div>
            </div>
            <div *ngIf="logicDiagramView.manager === true" class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/manager' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-check-box
                  *ngIf="currentEdit != undefined"
                  [(value)]="currentEdit.rightToMove.manager"
                ></dx-check-box>
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ 'entities/status-workflow/custom' | translate }}
              </div>
              <div class="dx-field-value">
                <dx-tag-box
                  style="background-color: var(--input) !important"
                  *ngIf="currentEdit != undefined"
                  displayExpr="name"
                  [searchMode]="'contains'"
                  [showSelectionControls]="true"
                  valueExpr="id"
                  [searchEnabled]="true"
                  [dataSource]="userDatasource.datasource"
                  [(value)]="currentEdit.rightToMove.customUserIds"
                >
                </dx-tag-box>
              </div>
            </div>
          </clc-work-item-section-group>
        </dxi-item>
      </dx-box>
    </div>
    <div class="dx-fieldset buttons cl-update-button-diagram">
      <dx-button
        [text]="'entities/status-workflow/update' | translate"
        type="default"
        (click)="this.update()"
      >
      </dx-button>
    </div>
  </dx-scroll-view>
</dx-popup>

<div
  [ngClass]="displayMode === 'Grid' ? 'grid-component' : 'grid-component-empty'"
>
  <dx-data-grid
    class="grid"
    [visible]="displayMode === 'Grid'"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
    [dataSource]="gridDataSource"
    [columnAutoWidth]="true"
    [columnMinWidth]="80"
    [allowColumnResizing]="true"
  >
    <dxo-paging [pageSize]="100000"> </dxo-paging>
    <dxo-pager
      [visible]="true"
      [allowedPageSizes]="false"
      [displayMode]="'full'"
      [showPageSizeSelector]="false"
      [showInfo]="true"
      [showNavigationButtons]="false"
    ></dxo-pager>

    <dxo-scrolling useNative="true" mode="standard"></dxo-scrolling>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-toolbar [visible]="false"></dxo-toolbar>
    <dxi-column
      dataField="status"
      [cellTemplate]="'linkTemplate'"
      [caption]="'entities/status-workflow/status' | translate"
    ></dxi-column>
    <dxi-column
      dataField="to"
      [cellTemplate]="'arrayTemplate'"
      [caption]="'entities/status-workflow/destinationStatus' | translate"
    ></dxi-column>
    <dxi-column
      dataField="positions"
      [cellTemplate]="'arrayTemplate'"
      [caption]="'entities/status-workflow/position' | translate"
    ></dxi-column>

    <dxi-column
      dataField="isOperator"
      [cellTemplate]="'booleanTemplate'"
      [caption]="'entities/status-workflow/isOperator' | translate"
    ></dxi-column>
    <dxi-column
      dataField="isUser"
      [cellTemplate]="'booleanTemplate'"
      [caption]="'entities/status-workflow/isUser' | translate"
    ></dxi-column>
    <dxi-column
      dataField="isManage"
      [cellTemplate]="'booleanTemplate'"
      [caption]="'entities/status-workflow/isManage' | translate"
    ></dxi-column>
    <dxi-column
      dataField="isUsers"
      [cellTemplate]="'booleanTemplate'"
      [caption]="'entities/status-workflow/isUsers' | translate"
    ></dxi-column>
    <div *dxTemplate="let data of 'arrayTemplate'">
      <ng-template
        *ngTemplateOutlet="
          templateService.get('arrayTemplate');
          context: { $implicit: data }
        "
      >
      </ng-template>
    </div>

    <div *dxTemplate="let data of 'linkTemplate'">
      <a href="" (click)="edit(data?.data?.edit); (false)">{{ data.value }}</a>
    </div>
    <div *dxTemplate="let data of 'booleanTemplate'">
      <i
        *ngIf="data.value"
        style="color: var(--primary)"
        class="far fa-check"
      ></i>
    </div>
  </dx-data-grid>
</div>
