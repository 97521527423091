import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetModelRulesDesignersBaseVariables, FirstModelRulesDesignersBaseVariables, CountModelRulesDesignersBaseVariables, FindModelRulesDesignersBaseVariables, SearchModelRulesDesignersBaseVariables, ExportSchemaModelRulesDesignersBaseVariables, ExportDataModelRulesDesignersBaseVariables, CustomQueryModelRulesDesignersBaseVariables, CustomQueryIdModelRulesDesignersBaseVariables, UsedModelRulesDesignersBaseVariables, FindNotOperatorsModelRulesDesignersBaseVariables, FindNotUsersModelRulesDesignersBaseVariables, FindLinkedOperatorsModelRulesDesignersBaseVariables, FindDefaultRuleModelRulesDesignersBaseVariables, ExistModelRulesDesignersBaseVariables, FindRecyclesModelRulesDesignersBaseVariables, CountRecyclesModelRulesDesignersBaseVariables, ReadOnlyModelRulesDesignersBaseVariables, FindArchivedModelRulesDesignersBaseVariables, CountAllModelRulesDesignersBaseVariables, FindDynamicPropertyFieldsModelRulesDesignersBaseVariables, FindUnassociatedOperatorsModelRulesDesignersBaseVariables, InsertModelRulesDesignersBaseVariables, UpdateModelRulesDesignersBaseVariables, ImportDataModelRulesDesignersBaseVariables, UpdateManyModelRulesDesignersBaseVariables, DeleteModelRulesDesignersBaseVariables, AddMembersCustomModelRulesDesignersBaseVariables, RemoveMembersCustomModelRulesDesignersBaseVariables, DuplicateModelRuleModelRulesDesignersBaseVariables, RestoreModelRulesDesignersBaseVariables, RecycleModelRulesDesignersBaseVariables, RestoreArchivedModelRulesDesignersBaseVariables, ArchivedModelRulesDesignersBaseVariables, AddFileDynamicFieldModelRulesDesignersBaseVariables, RemoveFileDynamicFieldModelRulesDesignersBaseVariables, AddOperatorsModelRulesDesignersBaseVariables, RemoveOperatorsModelRulesDesignersBaseVariables } from '../gqls'
import { GetModelRulesDesignersDocument, FirstModelRulesDesignersDocument, CountModelRulesDesignersDocument, FindModelRulesDesignersDocument, SearchModelRulesDesignersDocument, ExportSchemaModelRulesDesignersDocument, ExportDataModelRulesDesignersDocument, CustomQueryModelRulesDesignersDocument, CustomQueryIdModelRulesDesignersDocument, UsedModelRulesDesignersDocument, FindNotOperatorsModelRulesDesignersDocument, FindNotUsersModelRulesDesignersDocument, FindLinkedOperatorsModelRulesDesignersDocument, FindDefaultRuleModelRulesDesignersDocument, ExistModelRulesDesignersDocument, FindRecyclesModelRulesDesignersDocument, CountRecyclesModelRulesDesignersDocument, ReadOnlyModelRulesDesignersDocument, FindArchivedModelRulesDesignersDocument, CountAllModelRulesDesignersDocument, FindDynamicPropertyFieldsModelRulesDesignersDocument, FindUnassociatedOperatorsModelRulesDesignersDocument, InsertModelRulesDesignersDocument, UpdateModelRulesDesignersDocument, ImportDataModelRulesDesignersDocument, UpdateManyModelRulesDesignersDocument, DeleteModelRulesDesignersDocument, AddMembersCustomModelRulesDesignersDocument, RemoveMembersCustomModelRulesDesignersDocument, DuplicateModelRuleModelRulesDesignersDocument, RestoreModelRulesDesignersDocument, RecycleModelRulesDesignersDocument, RestoreArchivedModelRulesDesignersDocument, ArchivedModelRulesDesignersDocument, AddFileDynamicFieldModelRulesDesignersDocument, RemoveFileDynamicFieldModelRulesDesignersDocument, AddOperatorsModelRulesDesignersDocument, RemoveOperatorsModelRulesDesignersDocument } from '../gqls'
import { ServiceSingleResultOfModelRulesDesigner, QueryContextOfModelRulesDesigner, FilterOfModelRulesDesigner, ServiceSingleResultOfInt64, ServiceListResultOfModelRulesDesigner, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, ServiceListResultOfModelRule, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfModelRulesDesigner, ModelRulesDesignerInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ModelRulesDesignerBaseService {
    
public modelName = 'modelRulesDesigner';
public modelPluralName = 'modelRulesDesigners';

	private getModelRulesDesignersQuery: GetModelRulesDesignersDocument;
	private firstModelRulesDesignersQuery: FirstModelRulesDesignersDocument;
	private countModelRulesDesignersQuery: CountModelRulesDesignersDocument;
	private findModelRulesDesignersQuery: FindModelRulesDesignersDocument;
	private searchModelRulesDesignersQuery: SearchModelRulesDesignersDocument;
	private exportSchemaModelRulesDesignersQuery: ExportSchemaModelRulesDesignersDocument;
	private exportDataModelRulesDesignersQuery: ExportDataModelRulesDesignersDocument;
	private customQueryModelRulesDesignersQuery: CustomQueryModelRulesDesignersDocument;
	private customQueryIdModelRulesDesignersQuery: CustomQueryIdModelRulesDesignersDocument;
	private usedModelRulesDesignersQuery: UsedModelRulesDesignersDocument;
	private findNotOperatorsModelRulesDesignersQuery: FindNotOperatorsModelRulesDesignersDocument;
	private findNotUsersModelRulesDesignersQuery: FindNotUsersModelRulesDesignersDocument;
	private findLinkedOperatorsModelRulesDesignersQuery: FindLinkedOperatorsModelRulesDesignersDocument;
	private findDefaultRuleModelRulesDesignersQuery: FindDefaultRuleModelRulesDesignersDocument;
	private existModelRulesDesignersQuery: ExistModelRulesDesignersDocument;
	private findRecyclesModelRulesDesignersQuery: FindRecyclesModelRulesDesignersDocument;
	private countRecyclesModelRulesDesignersQuery: CountRecyclesModelRulesDesignersDocument;
	private readOnlyModelRulesDesignersQuery: ReadOnlyModelRulesDesignersDocument;
	private findArchivedModelRulesDesignersQuery: FindArchivedModelRulesDesignersDocument;
	private countAllModelRulesDesignersQuery: CountAllModelRulesDesignersDocument;
	private findDynamicPropertyFieldsModelRulesDesignersQuery: FindDynamicPropertyFieldsModelRulesDesignersDocument;
	private findUnassociatedOperatorsModelRulesDesignersQuery: FindUnassociatedOperatorsModelRulesDesignersDocument;
	private insertModelRulesDesignersMutation: InsertModelRulesDesignersDocument;
	private updateModelRulesDesignersMutation: UpdateModelRulesDesignersDocument;
	private importDataModelRulesDesignersMutation: ImportDataModelRulesDesignersDocument;
	private updateManyModelRulesDesignersMutation: UpdateManyModelRulesDesignersDocument;
	private deleteModelRulesDesignersMutation: DeleteModelRulesDesignersDocument;
	private addMembersCustomModelRulesDesignersMutation: AddMembersCustomModelRulesDesignersDocument;
	private removeMembersCustomModelRulesDesignersMutation: RemoveMembersCustomModelRulesDesignersDocument;
	private duplicateModelRuleModelRulesDesignersMutation: DuplicateModelRuleModelRulesDesignersDocument;
	private restoreModelRulesDesignersMutation: RestoreModelRulesDesignersDocument;
	private recycleModelRulesDesignersMutation: RecycleModelRulesDesignersDocument;
	private restoreArchivedModelRulesDesignersMutation: RestoreArchivedModelRulesDesignersDocument;
	private archivedModelRulesDesignersMutation: ArchivedModelRulesDesignersDocument;
	private addFileDynamicFieldModelRulesDesignersMutation: AddFileDynamicFieldModelRulesDesignersDocument;
	private removeFileDynamicFieldModelRulesDesignersMutation: RemoveFileDynamicFieldModelRulesDesignersDocument;
	private addOperatorsModelRulesDesignersMutation: AddOperatorsModelRulesDesignersDocument;
	private removeOperatorsModelRulesDesignersMutation: RemoveOperatorsModelRulesDesignersDocument;

	constructor(private injector: Injector) {
		this.getModelRulesDesignersQuery = this.injector.get(GetModelRulesDesignersDocument);
		this.firstModelRulesDesignersQuery = this.injector.get(FirstModelRulesDesignersDocument);
		this.countModelRulesDesignersQuery = this.injector.get(CountModelRulesDesignersDocument);
		this.findModelRulesDesignersQuery = this.injector.get(FindModelRulesDesignersDocument);
		this.searchModelRulesDesignersQuery = this.injector.get(SearchModelRulesDesignersDocument);
		this.exportSchemaModelRulesDesignersQuery = this.injector.get(ExportSchemaModelRulesDesignersDocument);
		this.exportDataModelRulesDesignersQuery = this.injector.get(ExportDataModelRulesDesignersDocument);
		this.customQueryModelRulesDesignersQuery = this.injector.get(CustomQueryModelRulesDesignersDocument);
		this.customQueryIdModelRulesDesignersQuery = this.injector.get(CustomQueryIdModelRulesDesignersDocument);
		this.usedModelRulesDesignersQuery = this.injector.get(UsedModelRulesDesignersDocument);
		this.findNotOperatorsModelRulesDesignersQuery = this.injector.get(FindNotOperatorsModelRulesDesignersDocument);
		this.findNotUsersModelRulesDesignersQuery = this.injector.get(FindNotUsersModelRulesDesignersDocument);
		this.findLinkedOperatorsModelRulesDesignersQuery = this.injector.get(FindLinkedOperatorsModelRulesDesignersDocument);
		this.findDefaultRuleModelRulesDesignersQuery = this.injector.get(FindDefaultRuleModelRulesDesignersDocument);
		this.existModelRulesDesignersQuery = this.injector.get(ExistModelRulesDesignersDocument);
		this.findRecyclesModelRulesDesignersQuery = this.injector.get(FindRecyclesModelRulesDesignersDocument);
		this.countRecyclesModelRulesDesignersQuery = this.injector.get(CountRecyclesModelRulesDesignersDocument);
		this.readOnlyModelRulesDesignersQuery = this.injector.get(ReadOnlyModelRulesDesignersDocument);
		this.findArchivedModelRulesDesignersQuery = this.injector.get(FindArchivedModelRulesDesignersDocument);
		this.countAllModelRulesDesignersQuery = this.injector.get(CountAllModelRulesDesignersDocument);
		this.findDynamicPropertyFieldsModelRulesDesignersQuery = this.injector.get(FindDynamicPropertyFieldsModelRulesDesignersDocument);
		this.findUnassociatedOperatorsModelRulesDesignersQuery = this.injector.get(FindUnassociatedOperatorsModelRulesDesignersDocument);
		this.insertModelRulesDesignersMutation = this.injector.get(InsertModelRulesDesignersDocument);
		this.updateModelRulesDesignersMutation = this.injector.get(UpdateModelRulesDesignersDocument);
		this.importDataModelRulesDesignersMutation = this.injector.get(ImportDataModelRulesDesignersDocument);
		this.updateManyModelRulesDesignersMutation = this.injector.get(UpdateManyModelRulesDesignersDocument);
		this.deleteModelRulesDesignersMutation = this.injector.get(DeleteModelRulesDesignersDocument);
		this.addMembersCustomModelRulesDesignersMutation = this.injector.get(AddMembersCustomModelRulesDesignersDocument);
		this.removeMembersCustomModelRulesDesignersMutation = this.injector.get(RemoveMembersCustomModelRulesDesignersDocument);
		this.duplicateModelRuleModelRulesDesignersMutation = this.injector.get(DuplicateModelRuleModelRulesDesignersDocument);
		this.restoreModelRulesDesignersMutation = this.injector.get(RestoreModelRulesDesignersDocument);
		this.recycleModelRulesDesignersMutation = this.injector.get(RecycleModelRulesDesignersDocument);
		this.restoreArchivedModelRulesDesignersMutation = this.injector.get(RestoreArchivedModelRulesDesignersDocument);
		this.archivedModelRulesDesignersMutation = this.injector.get(ArchivedModelRulesDesignersDocument);
		this.addFileDynamicFieldModelRulesDesignersMutation = this.injector.get(AddFileDynamicFieldModelRulesDesignersDocument);
		this.removeFileDynamicFieldModelRulesDesignersMutation = this.injector.get(RemoveFileDynamicFieldModelRulesDesignersDocument);
		this.addOperatorsModelRulesDesignersMutation = this.injector.get(AddOperatorsModelRulesDesignersDocument);
		this.removeOperatorsModelRulesDesignersMutation = this.injector.get(RemoveOperatorsModelRulesDesignersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfModelRulesDesigner> {
    
    		let variables: GetModelRulesDesignersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.get));
            }
            else{
                let result:ServiceSingleResultOfModelRulesDesigner={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelRulesDesigner,
		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfModelRulesDesigner> {
    
    		let variables: FirstModelRulesDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountModelRulesDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelRulesDesigner,
		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelRulesDesigner> {
    
    		let variables: FindModelRulesDesignersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfModelRulesDesigner,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelRulesDesigner> {
    
    		let variables: SearchModelRulesDesignersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaModelRulesDesignersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataModelRulesDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfModelRulesDesigner,
		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelRulesDesigner> {
    
    		let variables: CustomQueryModelRulesDesignersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfModelRulesDesigner,
		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelRulesDesigner> {
    
    		let variables: CustomQueryIdModelRulesDesignersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedModelRulesDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.usedModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('qualification?') qualification?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotOperatorsModelRulesDesignersBaseVariables = {
    			id: id,
			type: type,
			qualification: qualification,
			filter: filter,
			options: options
    		}
    				return this.findNotOperatorsModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.findNotOperators));
    	}

    	/**  */
    	@InjectArgs
    	public findNotUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
		@Args('type?') type?: string,
		@Args('qualification?') qualification?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotUsersModelRulesDesignersBaseVariables = {
    			id: id,
			type: type,
			qualification: qualification,
			memberOfPopulationIds: memberOfPopulationIds,
			filter: filter,
			options: options
    		}
    				return this.findNotUsersModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.findNotUsers));
    	}

    	/**  */
    	@InjectArgs
    	public findLinkedOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
		@Args('type?') type?: string,
		@Args('qualification?') qualification?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindLinkedOperatorsModelRulesDesignersBaseVariables = {
    			id: id,
			type: type,
			qualification: qualification,
			memberOfPopulationIds: memberOfPopulationIds,
			filter: filter,
			options: options
    		}
    				return this.findLinkedOperatorsModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.findLinkedOperators));
    	}

    	/**  */
    	@InjectArgs
    	public findDefaultRule(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelRule> {
    
    		let variables: FindDefaultRuleModelRulesDesignersBaseVariables = {
    			type: type,
			fieldName: fieldName
    		}
    				return this.findDefaultRuleModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.findDefaultRule));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistModelRulesDesignersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelRulesDesigner,
		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelRulesDesigner> {
    
    		let variables: FindRecyclesModelRulesDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesModelRulesDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyModelRulesDesignersBaseVariables = {
    			id: id
    		}
    				return this.readOnlyModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfModelRulesDesigner,
		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfModelRulesDesigner> {
    
    		let variables: FindArchivedModelRulesDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllModelRulesDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countAllModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsModelRulesDesignersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedOperators(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('operators', fields, null, [
			GqlSubFieldArg.create('filterOfOperators', 'filter'),
			GqlSubFieldArg.create('optionsOfOperators', 'options'),
		]),
		])
		extendedVariables['filterOfOperators'] = filter;
		extendedVariables['optionsOfOperators'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.operators));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedOperatorsModelRulesDesignersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedOperatorsModelRulesDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.findUnassociatedOperators));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ModelRulesDesignerInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfModelRulesDesigner> {
    
    		let variables: InsertModelRulesDesignersBaseVariables = {
    			entity: entity
    		}
    				return this.insertModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfModelRulesDesigner> {
    
    		let variables: UpdateModelRulesDesignersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataModelRulesDesignersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfModelRulesDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyModelRulesDesignersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteModelRulesDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addMembersCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddMembersCustomModelRulesDesignersBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addMembersCustomModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.addMembersCustom));
    	}

    	/**  */
    	@InjectArgs
    	public removeMembersCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveMembersCustomModelRulesDesignersBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeMembersCustomModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.removeMembersCustom));
    	}

    	/**  */
    	@InjectArgs
    	public duplicateModelRule(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DuplicateModelRuleModelRulesDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.duplicateModelRuleModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.duplicateModelRule));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreModelRulesDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleModelRulesDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedModelRulesDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedModelRulesDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldModelRulesDesignersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldModelRulesDesignersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorsModelRulesDesignersBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addOperatorsModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.addOperators));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorsModelRulesDesignersBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeOperatorsModelRulesDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.modelRulesDesigners.removeOperators));
    	}
    
}