import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { PrinterLabelBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

@Injectable({ providedIn: 'root' })
@Authorize('administration.label-printer')
export class PrinterLabelCoreService extends PrinterLabelBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Obtient les type d'étiquette possible */
  labels(): any[] {
    return [
      {
        id: 'brother',
        value: 'brother',
        name: TranslateService.get('entities/brotherLabel/_title/singular'),
      },
    ];
  }
  /** Obtient les mapping pour Asset */
  assetFieldMappings(): PrinterLabelFieldMapping[] {
    let result: PrinterLabelFieldMapping[] = [
      PrinterLabelFieldMapping.add('name', 'asset'),
      PrinterLabelFieldMapping.add('serialNumber', 'asset'),
      PrinterLabelFieldMapping.add('barCode', 'asset'),
      PrinterLabelFieldMapping.add('inventoryNumber', 'asset'),
      PrinterLabelFieldMapping.add('macAddress', 'asset'),
      PrinterLabelFieldMapping.add('ipAddress', 'asset'),
      {
        name: 'manufacturer.data.name',
        label: TranslateService.get('entities/asset/manufacturer'),
      },
      {
        name: 'model.data.name',
        label: TranslateService.get('entities/asset/model'),
      },
    ];

    result.sort((a, b) => {
      return a.name.localeCompare(b.label);
    });

    return result;
  }
}

@Injectable({ providedIn: 'root' })
export class PrinterLabelFieldMapping {
  label: string;
  name: string;

  /** Mapping avec récupération du libellé */
  public static add(
    name: string,
    entityName: string,
  ): PrinterLabelFieldMapping {
    return {
      name: name,
      label: TranslateService.get(`entities/${entityName}/${name}`),
    };
  }
}
