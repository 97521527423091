<div class="mobile-header-container">
  <div>
    <dx-button
      class="btn-action"
      icon="menu"
      (onClick)="toogleMenu()"
    ></dx-button>
  </div>
  <div class="mobile-header-logo" style="display: flex">
    <div
      class="two"
      [style.visibility]="actions.length > 0 ? 'hidden' : 'visible'"
    >
      <img
        class="small-ico"
        src="/static-files/logo-new/embleme-blanc-50x50.svg"
        (click)="backToHome()"
      />
    </div>
    <div>
      <img
        class="small-ico"
        src="/static-files/logo-new/embleme-blanc-50x50.svg"
      />
    </div>
  </div>

  <!--  <div class='mobile-header-title'>title</div>-->
  <div class="mobile-header-action">
    <div *ngFor="let action of listActions">
      <dx-button
        class="btn-action"
        [icon]="action.icon"
        (onClick)="manageClick(action.eventActionReturnType)"
      ></dx-button>
    </div>
    <div *ngFor="let action of actions">
      <dx-button
        class="btn-action"
        [icon]="action.icon"
        (onClick)="manageClick(action.eventActionReturnType)"
      ></dx-button>
    </div>
    <div *ngFor="let action of listSystem">
      <dx-button
        class="btn-action"
        [icon]="action.icon"
        (onClick)="manageClick(action.eventActionReturnType)"
      ></dx-button>
    </div>
    <!--    <div *ngFor='let action of customActions'>-->
    <!--      <dx-button [icon]='action.icon' (onClick)='manageClick(action.eventActionReturnType)'></dx-button>-->
    <!--    </div>-->
  </div>
</div>

<dx-drawer
  [openedStateMode]="'overlap'"
  [position]="'left'"
  [revealMode]="'expand'"
  template="template"
  [(opened)]="isMenuOpen"
  height="95vh"
>
  <div *dxTemplate="let data of 'template'" class="openMenu">
    <dx-tree-view
      class="panel-list mobile-nav-treeview"
      [dataSource]="items"
      selectionMode="single"
      [selectByClick]="true"
      [displayExpr]="'title'"
      [keyExpr]="'id'"
      [parentIdExpr]="'parentId'"
      [itemsExpr]="'children'"
      (onInitialized)="initTreeView($event)"
      [selectedExpr]="'isSelected'"
      (onItemClick)="menuSelectionChanged($event)"
    >
    </dx-tree-view>
    <!--    <dx-list-->
    <!--      class="panel-list dx-theme-accent-as-background-color"-->
    <!--      (onItemClick)='menuSelectionChanged($event)'-->
    <!--      [(selectedItems)]='selectedItems'-->

    <!--      [displayExpr]="'title'"-->
    <!--      [dataSource]="items"-->
    <!--    >-->
    <!--    </dx-list>-->
  </div>
  <!--  <div id="content" class="dx-theme-background-color" [innerHTML]="'mglksdgmldkgdf'"></div>-->
  <!--  <div id="content" class="dx-theme-background-color" [innerHTML]="text"></div>-->
</dx-drawer>

<dx-popup
  class="popUpSystem"
  id="popup"
  [(visible)]="showPopSystem"
  (onClose)="this.closePopUp()"
  [showCloseButton]="true"
  height="auto"
  width="50%"
  title="Menu"
  [hideOnOutsideClick]="true"
>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    "
  >
    <dx-button
      style="margin-top: 5%; margin-left: 5%; margin-right: 5%"
      disabled="true"
      [visible]="false"
      [text]="'globals/langMobile' | translate"
    ></dx-button>
    <dx-button
      [visible]="false"
      (onClick)="goOrga()"
      style="margin-top: 5%; margin-left: 5%; margin-right: 5%"
      [text]="'globals/myOrgaMobile' | translate"
    ></dx-button>
    <dx-button
      [visible]="isDev"
      (onClick)="goPc()"
      style="margin-top: 5%; margin-left: 5%; margin-right: 5%"
      text="Basculer en version PC"
    ></dx-button>
    <dx-button
      [visible]="viewHelpMe"
      (onClick)="switchMode()"
      [icon]="'fal fa-retweet-alt'"
      [text]="viewTitle"
      style="margin-top: 5%; margin-left: 5%; margin-right: 5%"
    >
    </dx-button>
    <dx-button
      type="danger"
      (onClick)="logout()"
      style="
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 5%;
        margin-right: 5%;
      "
      [text]="'globals/logoffMobile' | translate"
    ></dx-button>
  </div>
</dx-popup>
