import {
  Component,
  forwardRef,
  Injector,
  Input,
  Injectable,
} from '@angular/core';
import * as bpac from './bpac.js';
import {
  GqlFields,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';
import { EnvironmentService, GC, GCFactory } from '@clarilog/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import { BrotherLabelMapping } from '@clarilog/core/services2/graphql/generated-types/types.js';
import { FileManagerCoreService } from '@clarilog/core/services2/graphql/generated-types/services/file-manager.service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import notify from 'devextreme/ui/notify';

/** Représente la classe du composent clc-bpac. */
@Component({
  selector: 'clc-bpac',
  templateUrl: './bpac.component.html',
  styleUrls: ['./bpac.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrotherBpacComponent),
      multi: true,
    },
  ],
})
export class BrotherBpacComponent implements ControlValueAccessor {
  /** Obtient ou définit l'image */
  image: String;
  printMode: boolean = false;
  printerName: string;
  disabledPrintButton: boolean = false;

  /** Obtient ou définit l'id de l'image en cours */
  _value: BrotherBpacValue;

  loadingVisible: boolean = false;

  errors: { message: string; url: string }[] = [];

  bmpDefaultWidth = 200;

  _cancelPrint = false;
  gc: GC;

  /** Obtient ou définit le service. */
  @Input() service: string;
  @Input() showPrint: boolean = true;

  constructor(
    private _envService: EnvironmentService,
    private _injector: Injector,
    private _localStorageService: LocalStorageService,
    public fileManagerService: FileManagerCoreService,
    private gcFactory: GCFactory,
  ) {
    this.gc = gcFactory.create();
    this.gc.forDispose(
      this._localStorageService.ModelState.on.subscribe((s) => {
        if (s.eventName == LocalStorageService.EventConst.BrotherCancelPrint) {
          this._cancelPrint = true;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.gc.dispose();
  }

  writeValue(value: BrotherBpacValue): void {
    this._value = value;
    this.load(value?.print ?? false);
  }
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Obtient la valeur d'un champs */
  getValueData(data: any, fieldName: string): any {
    let splitName = fieldName.split('.');
    let returnValue = data[splitName[0]];
    for (let i = 1; i < splitName.length; i++) {
      if (returnValue != undefined) {
        returnValue = returnValue[splitName[i]];
      }
    }

    if (returnValue == undefined) {
      returnValue = '';
    }
    return returnValue;
  }

  /** Obtient l'ensemble des field name */
  async getFields(objDoc): Promise<BrotherLabelMapping[]> {
    let fields = [];

    let objCount = await objDoc.GetObjectsCount();
    let objs = await objDoc.GetObjects();

    for (let i = 0; i < objCount; i++) {
      let item = await objs.GetItem(i);
      if (item != undefined) {
        let name = await item.Name;
        let typeValue = await item.Type;
        let typeName = '';
        if (typeValue != undefined) {
          switch (typeValue) {
            case 0:
              typeName = 'Text';
              break;
            case 1:
              typeName = 'BarCode';
              break;
            case 2:
              typeName = 'Image';
              break;
            case 3:
              typeName = 'DateTime';
              break;
            case 4:
              typeName = 'ClipArt';
              break;
          }
        }
        // Ne remonte que les TEXT et Barcode
        if (name != undefined && typeValue < 2) {
          fields.push({
            objectName: name,
            objectType: typeName,
          });
        }
      }
    }

    return fields;
  }

  /** Impression */
  async print(e) {
    await this.load(true);
  }

  /** Initialisation des variable */
  clear() {
    this.errors = [];
    this._cancelPrint = false;
    this.disabledPrintButton = false;
  }
  /** Obtient l'url */
  async getUrl() {
    // Get info
    let fileInfo = await this.fileManagerService
      .fileInfo(
        FileManagerCoreService.FileModelFields(),
        this._value.fileId,
        {},
      )
      .toPromise();

    // Obligatoire pour connaitre la bonne route de download
    // LE Open requis un nom de fichier correct
    let labelName = 'label.lbx';
    if (
      fileInfo?.data?.name != undefined &&
      fileInfo.data.name.endsWith('.lbl')
    ) {
      labelName = 'label.lbl';
    }

    return `${this._envService.apiURL}/file/brotherLabel/${this._value.fileId}/${labelName}`;
  }

  /** Impression */
  async objPrint(objDoc): Promise<boolean> {
    let result = await objDoc.StartPrint('', 0);
    let result1 = await objDoc.PrintOut(1, 0);
    let result2 = await objDoc.EndPrint();

    return result && result1 && result2;
  }

  /** Obtient l'apercu */
  async getTemplateBmp() {
    try {
      this.clear();
      if (this._value.fileId == undefined) {
        this.image = undefined;
        return;
      }
      if (this.bpacInstalled()) {
        this.loadingVisible = !this.printMode;
        this.disabledPrintButton = true;
        const strPath = await this.getUrl();
        const objDoc = bpac.IDocument;
        const ret = await objDoc.Open(strPath);
        if (ret) {
          if (this.printMode) {
            let result = await this.objPrint(objDoc);
            if (result) {
              notify(
                TranslateService.get('entities/labelPrinter/printSuccess'),
                'success',
                5000,
              );
            } else {
              notify(
                TranslateService.get('entities/labelPrinter/printError'),
                'error',
                5000,
              );
            }
          } else {
            // Récupération des noms d'objet
            if (this._value.loadField) {
              let fields = await this.getFields(objDoc);
              this._localStorageService.ModelState.on.emit({
                eventName: LocalStorageService.EventConst.BrotherFields,
                eventData: { value: fields },
              });
            }
            this.image = await objDoc.GetImageData(4, 0, this.bmpDefaultWidth);
          }
        } else {
          // No file
          this.cantOpenFile(undefined);
        }
        await objDoc.Close();
        this.loadingVisible = false;
        this.disabledPrintButton = false;
      }
    } catch (e) {
      // Gestion de l'exception
      this.cantOpenFile(e);
    }
  }

  /** Error */
  cantOpenFile(e) {
    this.loadingVisible = false;
    if (e == undefined) {
      e = "Can't open file or sdk is not installed.";
    }
    this.errors.push({
      message: e,
      url: 'https://support.brother.com/g/s/es/dev/en/bpac/download/index.html?c=eu_ot&lang=en&navi=offall&comple=on&redirect=on',
    });

    this.endPrint();
    this.disabledPrintButton = false;
  }

  /** Accès a la page de l'extension */
  downloadExtension(url) {
    window.open(url, '_blank');
  }

  /** Vérifie si l'extension est disponible */
  bpacInstalled(): boolean {
    if (bpac.IsExtensionInstalled() == false) {
      const agent = window.navigator.userAgent.toLowerCase();
      const isedge = agent.indexOf('edg') !== -1;
      let urlExtension = undefined;
      if (isedge)
        urlExtension =
          'https://microsoftedge.microsoft.com/addons/detail/brother-bpac-extension/kmopihekhjobijiipnloimfdgjddbnhg';

      const ischrome =
        agent.indexOf('chrome') !== -1 && agent.indexOf('opr') === -1;
      if (ischrome)
        urlExtension =
          'https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb';

      this.errors.push({
        message: 'Brother b-PAC Extension not found.',
        url: urlExtension,
      });

      this.endPrint();
      return false;
    }

    return true;
  }

  /** Recherche l'objet dans l'etiquette */
  async getObject(objDoc, objectName: string) {
    let objField = await objDoc.GetObject(objectName);
    if (objField == undefined) {
      let objCount = await objDoc.GetObjectsCount();
      let objs = await objDoc.GetObjects();

      for (let i = 0; i < objCount; i++) {
        let item = await objs.GetItem(i);
        let name = await item.Name;
        if (name == objectName) {
          return item;
        }
      }
    }

    return objField;
  }

  async ngOnInit() {
    await this.getPrinterName();
  }

  /** Signale la fin d'impression */
  endPrint() {
    this._localStorageService.ModelState.on.emit({
      eventName: LocalStorageService.EventConst.BrotherEndPrint,
      eventData: {},
    });
  }

  async getPrinterName() {
    try {
      if (this.printerName == undefined) {
        const objDoc = bpac.IDocument;
        this.printerName = await objDoc.GetPrinterName();
      }
    } catch (e) {
      this.errors.push({
        message: 'Printer not found.',
        url: undefined,
      });
    }
  }
  /** Action sur l'impression */
  async load(print: boolean = false) {
    this.printMode = print;
    this.clear();
    if (!print) {
      this.image = undefined;
    }

    let installedBpac = this.bpacInstalled();
    if (!installedBpac) return;

    await this.getPrinterName();

    let currentService: any;
    // Recherche du service
    if (this.service != undefined) {
      currentService = this._injector.get(this.service);
    }

    if (this._value?.fileId == undefined) {
      // Aucun apercu
      this.image = undefined;
    } else if (
      currentService?.find == undefined &&
      this._value.fileId != undefined
    ) {
      // Template de base si pas de service
      await this.getTemplateBmp();
    } else if (
      currentService?.find != undefined &&
      this._value.fileId != undefined &&
      this._value.selectedKeys != undefined &&
      this._value.selectedKeys.length > 0
    ) {
      if (!print) {
        this.loadingVisible = true;
      }
      // Récupération des noms d'objet
      let fields = this._value.brotherLabelMappings;
      if (fields == undefined || fields.length == 0) {
        this.errors.push({
          message: 'No object field found.',
          url: undefined,
        });
        this.loadingVisible = false;
        return;
      }

      // Génération ou impression
      const strPath = await this.getUrl();
      const objDoc = bpac.IDocument;
      const ret = await objDoc.Open(strPath);

      if (ret == true) {
        // Création field GraphQL
        let dataFields = [GqlSubField.create('data')];
        fields.forEach((field) => {
          ModelFieldCompilerService.createField(
            field.entityPropertyName,
            undefined,
            dataFields[0].fields,
          );
        });

        try {
          let ids = this._value.selectedKeys;
          // Uniquement l'index 0
          if (print == false) {
            ids = [this._value.selectedKeys[0]];
          }
          //fields, options, statusFilter, extendedVariables
          currentService
            .find(dataFields, undefined, {
              id: { in: ids },
            })
            .subscribe(async (s) => {
              this.loadingVisible = false;
              if (s?.data != undefined) {
                for (
                  let indexData = 0;
                  indexData < s.data.length;
                  indexData++
                ) {
                  if (this._cancelPrint === true) {
                    break;
                  }
                  // Replace
                  let data = s.data[indexData];
                  for (
                    let indexField = 0;
                    indexField < fields.length;
                    indexField++
                  ) {
                    let field = fields[indexField];
                    let objField = await this.getObject(
                      objDoc,
                      field.objectName,
                    );
                    if (objField != undefined) {
                      objField.Text = this.getValueData(
                        data,
                        field.entityPropertyName,
                      );
                    }
                  }

                  // Set de l'image
                  if (!print) {
                    this.image = await objDoc.GetImageData(
                      4,
                      0,
                      this.bmpDefaultWidth,
                    );
                  } else {
                    this._localStorageService.ModelState.on.emit({
                      eventName:
                        LocalStorageService.EventConst.BrotherProgressPrint,
                      eventData: { value: indexData + 1 },
                    });

                    await this.objPrint(objDoc);
                  }
                }
              }

              await objDoc.Close();
              this.endPrint();
              this.loadingVisible = false;
            });
        } catch (e) {
          this._localStorageService.ModelState.on.emit({
            eventName: LocalStorageService.EventConst.BrotherEndPrint,
            eventData: { value: fields },
          });

          this.cantOpenFile(e);
          await objDoc.Close();
          // Image de base
          if (!print) {
            this.image = await objDoc.GetImageData(4, 0, 100);
          }
        }
      } else {
        this.cantOpenFile(undefined);
      }
    }
  }
}

/** Représente les informations de la valeur pour la gestion des etiquette */
@Injectable({ providedIn: 'root' })
export class BrotherBpacValue {
  fileId: string;
  loadField: boolean;
  selectedKeys: string[];
  brotherLabelMappings: BrotherLabelMapping[];
  print: boolean = false;
}
