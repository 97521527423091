import { ModelRulesDesignerBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize, Maybe } from '@clarilog/core/services/graphql';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import {
  FilterOfModelRulesDesigner,
  FilterOfUser,
  FormDesignerEditing,
  FormDesignerTo,
  QueryContextOfModelRulesDesigner,
  QueryContextOfUser,
  ServiceListResultOfModelRulesDesigner,
  ServiceListResultOfUser,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { FindModelRulesDesignersBaseVariables } from '../gqls/model-rules-designer.gqls';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { map } from 'rxjs/internal/operators/map';
import { UserCoreService } from '.';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import notify from 'devextreme/ui/notify';
import { FormGroupHelpers } from '@clarilog/shared2/components/form/work-form/form-group-helpers';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { CoreWorkFormComponent } from '@clarilog/shared2/components/form/work-form/work-form.component';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-model-rules')
export class ModelRulesDesignerCoreService extends ModelRulesDesignerBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private userCoreService: UserCoreService,
    private serializer: UrlSerializer,
  ) {
    super(injector);
  }
  public static getRuleFields(): GqlFields {
    return [
      GqlField.create('editable'),
      GqlField.create('editableStatusIds'),
      GqlField.create('fieldName'),
      GqlSubField.create(
        'translatedTitle',
        TranslatedFieldHelperService.getStaticTranslatedFields(),
      ),
      GqlField.create('required'),
      GqlField.create('statusIds'),
      GqlField.create('title'),
      GqlField.create('visible'),
      GqlSubField.create(
        'option',
        ModelRulesDesignerCoreService.getRuleOptionFields(),
      ),
    ];
  }

  public static getRuleOptionFields(): GqlFields {
    return [
      GqlField.create('allowEdit'),
      GqlField.create('allowRequire'),
      GqlField.create('allowStatus'),
      GqlField.create('allowEditStatus'),
    ];
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findOperatorIncident(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfModelRulesDesigner,
    @Args('filter?') filter?: FilterOfModelRulesDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfModelRulesDesigner> {
    let newFilter: FilterOfModelRulesDesigner = {
      type: { eq: 'operator-incident' },
      qualification: { elemMatch: { in: ['incident'] } },
    };

    if (filter == undefined) {
      filter = newFilter;
    } else {
      filter = {
        and: [newFilter, filter],
      };
    }

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findOperatorRequest(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfModelRulesDesigner,
    @Args('filter?') filter?: FilterOfModelRulesDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfModelRulesDesigner> {
    let newFilter: FilterOfModelRulesDesigner = {
      type: { eq: 'operator-request' },
      qualification: { elemMatch: { in: ['request'] } },
    };

    if (filter == undefined) {
      filter = newFilter;
    } else {
      filter = {
        and: [newFilter, filter],
      };
    }

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findOperatorProblem(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfModelRulesDesigner,
    @Args('filter?') filter?: FilterOfModelRulesDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfModelRulesDesigner> {
    let newFilter: FilterOfModelRulesDesigner = {
      type: { eq: 'operator-problem' },
      qualification: { elemMatch: { in: ['problem'] } },
    };

    if (filter == undefined) {
      filter = newFilter;
    } else {
      filter = {
        and: [newFilter, filter],
      };
    }

    return this.find(fields, options, filter, extendedVariables);
  }

  /**Methode personnalisée pour filtrer sur l'equipe operateurs */
  @InjectArgs
  public findOperatorsTeams(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (id != null && filter != null) {
      let filterPerso = {};
      if (
        filter.operatorTeamIds != null ||
        filter.operatorTeamIds != undefined
      ) {
        filterPerso = {
          operatorTeamIds: {
            elemMatch: {
              in: [filter.operatorTeamIds.eq],
            },
          },
        };
      } else {
        if (filter.or != null || filter.or != undefined) {
          let listFitler = [];
          filter.or.map((element) => {
            listFitler.push(element.operatorTeamIds.eq);
          });
          filterPerso = {
            operatorTeamIds: {
              elemMatch: {
                in: listFitler,
              },
            },
          };
        }
      }

      filter = filterPerso;
    }
    return this.findAssociatedOperators(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  public getFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create(
          'name',
          this.translatedFieldHelperService.translatedFields(),
        ),
        GqlField.create('operatorIds'),
        GqlField.create('type'),
        GqlField.create('qualification'),
        GqlSubField.create(
          'rules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'editRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'taskRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'taskEditRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'interventionRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'interventionEditRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'escalationRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'escalationEditRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'dynamicFieldRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        GqlSubField.create(
          'dynamicFieldEditRules',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
        // form designer custom
        GqlField.create('useNewFormDesigner'),
        GqlField.create('jsonNewFormDesigner'),

        GqlField.create('useEditFormDesigner'),
        GqlField.create('jsonEditFormDesigner'),
      ]),
    ];
  }

  @InjectArgs
  public findOperatorModelRule(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfModelRulesDesigner,
    @Args('filter?') filter?: FilterOfModelRulesDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfModelRulesDesigner> {
    let variables: FindModelRulesDesignersBaseVariables = {
      options: options,
      filter: filter,
    };

    let newFilter: FilterOfModelRulesDesigner = {
      type: {
        in: ['operator-incident', 'operator-request', 'operator-problem'],
      },
    };

    if (filter == undefined) {
      filter = newFilter;
    } else {
      filter = {
        and: [newFilter, filter],
      };
    }

    return this.find(fields, options, filter, extendedVariables);
  }
  @InjectArgs
  public findUserModelRuleIncident(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfModelRulesDesigner,
    @Args('filter?') filter?: FilterOfModelRulesDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfModelRulesDesigner> {
    let variables: FindModelRulesDesignersBaseVariables = {
      options: options,
      filter: filter,
    };

    let newFilter: FilterOfModelRulesDesigner = {
      type: { eq: 'user-incident' },
      qualification: { elemMatch: { in: ['incident'] } },
    };

    if (filter == undefined) {
      filter = newFilter;
    } else {
      filter = {
        and: [newFilter, filter],
      };
    }

    return this.find(fields, options, filter, extendedVariables);
  }
  @InjectArgs
  public findUserModelRuleRequest(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfModelRulesDesigner,
    @Args('filter?') filter?: FilterOfModelRulesDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfModelRulesDesigner> {
    let variables: FindModelRulesDesignersBaseVariables = {
      options: options,
      filter: filter,
    };

    let newFilter: FilterOfModelRulesDesigner = {
      type: { eq: 'user-request' },
      qualification: { elemMatch: { in: ['request'] } },
    };

    if (filter == undefined) {
      filter = newFilter;
    } else {
      filter = {
        and: [newFilter, filter],
      };
    }

    return this.find(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  public findUserModelRule(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfModelRulesDesigner,
    @Args('filter?') filter?: FilterOfModelRulesDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfModelRulesDesigner> {
    let variables: FindModelRulesDesignersBaseVariables = {
      options: options,
      filter: filter,
    };

    let newFilter: FilterOfModelRulesDesigner = {
      type: { in: ['user-incident', 'user-request'] },
    };

    if (filter == undefined) {
      filter = newFilter;
    } else {
      filter = {
        and: [newFilter, filter],
      };
    }

    return this.find(fields, options, filter, extendedVariables);
  }

  /**  */
  @InjectArgs
  public findLinkedOperatorsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
    @Args('type?') type?: string,
    @Args('qualification?') qualification?: Array<Maybe<string>>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    memberOfPopulationIds =
      this.userCoreService.memberOfPopulationIdsCustomFilter(filter);

    return this.findLinkedOperators(
      fields,
      memberOfPopulationIds,
      type,
      qualification,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  /**  */
  @InjectArgs
  public findNotLinkedOperators(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
    @Args('type?') type?: string,
    @Args('qualification?') qualification?: Array<Maybe<string>>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    memberOfPopulationIds =
      this.userCoreService.memberOfPopulationIdsCustomFilter(filter);

    return this.findNotUsers(
      fields,
      memberOfPopulationIds,
      type,
      qualification,
      options,
      id,
      filter,
    );
  }

  @InjectArgs
  public findRule(
    @Args('type') type: string,
    @Args('fieldName') fieldName: string,
  ) {
    return this.findDefaultRule(
      [
        GqlSubField.create(
          'data',
          ModelRulesDesignerCoreService.getRuleFields(),
        ),
      ],
      type,
      fieldName,
    )
      .pipe(
        map((r) => {
          return r.data;
        }),
      )
      .toPromise();
  }

  public getUserRequestDefault() {
    let res = this.find(
      [GqlSubField.create('data', [GqlField.create('id')])],
      null,
      {
        type: { eq: 'user-request' },
        qualification: { elemMatch: { in: ['request'] } },
        isDefault: { eq: true },
      },
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      if (res.length > 0) {
        return res[0].id;
      } else null;
    });
  }
  public getUserIncidentDefault() {
    let res = this.find(
      [GqlSubField.create('data', [GqlField.create('id')])],
      null,
      {
        type: { eq: 'user-incident' },
        qualification: { elemMatch: { in: ['incident'] } },
        isDefault: { eq: true },
      },
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      if (res.length > 0) {
        return res[0].id;
      } else null;
    });
  }
}
