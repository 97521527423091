<!--  <button-->
<!--    class="cl-button cl-button-pin"-->
<!--    *ngIf="designerMode === true"-->
<!--    tabindex="-1"-->
<!--    (click)="designerClick('REMOVE')"-->
<!--  >-->
<!--    <i-->
<!--      class="fal fa-add"-->
<!--      style="font-size: 0.8em"-->
<!--    ></i>-->
<!--  </button>-->
<dx-tab-panel
  #tabPanel
  [selectedIndex]="0"
  [height]="'100%'"
  [dataSource]="source"
  [loop]="false"
  [animationEnabled]="false"
  [swipeEnabled]="false"
  [ngClass]="{ 'cl-form-designer-tab-hover': designerMode }"
  (onSelectionChanged)="onSelectionChanged($event)"
  (onContentReady)="onContentReady($event)"
  (onInitialized)="onInitialized($event)"
>
  <div class="testBM">
    <div *dxTemplate="let title of 'title'">
      <div [title]="title.hint" *ngIf="title.item != undefined">
        <div
          style="float: left; padding-right: 4px"
          *ngIf="designerMode === true"
        >
          <span
            class="cl-control-option-designer"
            style="color: red"
            *ngIf="title?.hasOptionDesigner?.required"
          >
            <i class="fa-solid fa-asterisk"></i>
          </span>
          <span
            class="cl-control-option-designer"
            style="color: var(--primary)"
            *ngIf="title?.hasOptionDesigner?.filter"
          >
            <i class="fa-solid fa-filter"></i>
          </span>

          <span
            class="cl-control-option-designer"
            *ngIf="title?.hasOptionDesigner?.designerReadOnly === true"
          >
            <i class="fa-solid fa-lock"></i>
          </span>
        </div>

        <div style="float: left">
          {{ title.title }}
        </div>

        <i
          class="fal fa-exclamation-circle error"
          *ngIf="title.valid === false"
        ></i>
        <!--      <button-->
        <!--        class="cl-button cl-button-pin"-->
        <!--        *ngIf="designerMode === true"-->
        <!--        tabindex="-1"-->
        <!--        (click)="designerClick('REMOVE')"-->
        <!--      >-->
        <!--        <i-->
        <!--          class="fal fa-square-minus"-->
        <!--          style="font-size: 0.8em"-->
        <!--        ></i>-->
        <!--      </button>-->
      </div>
      <div class="cl-tab-designer" *ngIf="designerMode === true">
        <div
          style="float: right"
          class="cl-add-tab-form-designer"
          *ngIf="designerMode === true"
          (click)="formAction($event, getAction.ADD_DOWN, title)"
        >
          <i class="fa-solid fa-arrow-left-to-bracket"></i>
        </div>

        <div
          style="float: right"
          class="cl-add-tab-form-designer"
          *ngIf="designerMode === true"
          (click)="formAction($event, getAction.ADD_UP, title)"
        >
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
        </div>

        <div
          style="float: right"
          class="cl-edit-tab-form-designer"
          *ngIf="designerMode === true"
          (click)="formAction($event, getAction.EDIT, title)"
        >
          <i class="fa-solid fa-pen"></i>
        </div>
        <div
          style="float: right"
          class="cl-delete-tab-form-designer"
          *ngIf="designerMode === true"
          (click)="formAction($event, getAction.REMOVE, title)"
        >
          <i class="fa-solid fa-trash-xmark"></i>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div *dxTemplate="let item of 'item'">
      <div *ngIf="item.item != undefined" [formGroup]="item.item.formGroup">
        <clc-work-item-group-dynamic
          [data]="item.item"
          [state]="state"
          [designerMode]="designerMode"
          [designerId]="item?.item?.id"
          (onLoaded)="workItemLoaded($event)"
        >
        </clc-work-item-group-dynamic>
      </div>
    </div>
  </div>
</dx-tab-panel>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="!loaded"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
