import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetFormDesignersBaseVariables, FirstFormDesignersBaseVariables, CountFormDesignersBaseVariables, FindFormDesignersBaseVariables, SearchFormDesignersBaseVariables, ExportSchemaFormDesignersBaseVariables, ExportDataFormDesignersBaseVariables, CustomQueryFormDesignersBaseVariables, CustomQueryIdFormDesignersBaseVariables, UsedFormDesignersBaseVariables, ModelsFormDesignersBaseVariables, FindIncidentModelsFormDesignersBaseVariables, ExistFormDesignersBaseVariables, FindRecyclesFormDesignersBaseVariables, CountRecyclesFormDesignersBaseVariables, ReadOnlyFormDesignersBaseVariables, FindArchivedFormDesignersBaseVariables, CountAllFormDesignersBaseVariables, FindDynamicPropertyFieldsFormDesignersBaseVariables, FindUnassociatedUserEditIncidentModelsFormDesignersBaseVariables, FindUnassociatedUserNewIncidentModelsFormDesignersBaseVariables, FindUnassociatedOperatorEditIncidentModelsFormDesignersBaseVariables, FindUnassociatedOperatorNewIncidentModelsFormDesignersBaseVariables, InsertFormDesignersBaseVariables, UpdateFormDesignersBaseVariables, ImportDataFormDesignersBaseVariables, UpdateManyFormDesignersBaseVariables, DeleteFormDesignersBaseVariables, RestoreFormDesignersBaseVariables, RecycleFormDesignersBaseVariables, RestoreArchivedFormDesignersBaseVariables, ArchivedFormDesignersBaseVariables, AddFileDynamicFieldFormDesignersBaseVariables, RemoveFileDynamicFieldFormDesignersBaseVariables, AddUserEditIncidentModelsFormDesignersBaseVariables, RemoveUserEditIncidentModelsFormDesignersBaseVariables, AddUserNewIncidentModelsFormDesignersBaseVariables, RemoveUserNewIncidentModelsFormDesignersBaseVariables, AddOperatorEditIncidentModelsFormDesignersBaseVariables, RemoveOperatorEditIncidentModelsFormDesignersBaseVariables, AddOperatorNewIncidentModelsFormDesignersBaseVariables, RemoveOperatorNewIncidentModelsFormDesignersBaseVariables } from '../gqls'
import { GetFormDesignersDocument, FirstFormDesignersDocument, CountFormDesignersDocument, FindFormDesignersDocument, SearchFormDesignersDocument, ExportSchemaFormDesignersDocument, ExportDataFormDesignersDocument, CustomQueryFormDesignersDocument, CustomQueryIdFormDesignersDocument, UsedFormDesignersDocument, ModelsFormDesignersDocument, FindIncidentModelsFormDesignersDocument, ExistFormDesignersDocument, FindRecyclesFormDesignersDocument, CountRecyclesFormDesignersDocument, ReadOnlyFormDesignersDocument, FindArchivedFormDesignersDocument, CountAllFormDesignersDocument, FindDynamicPropertyFieldsFormDesignersDocument, FindUnassociatedUserEditIncidentModelsFormDesignersDocument, FindUnassociatedUserNewIncidentModelsFormDesignersDocument, FindUnassociatedOperatorEditIncidentModelsFormDesignersDocument, FindUnassociatedOperatorNewIncidentModelsFormDesignersDocument, InsertFormDesignersDocument, UpdateFormDesignersDocument, ImportDataFormDesignersDocument, UpdateManyFormDesignersDocument, DeleteFormDesignersDocument, RestoreFormDesignersDocument, RecycleFormDesignersDocument, RestoreArchivedFormDesignersDocument, ArchivedFormDesignersDocument, AddFileDynamicFieldFormDesignersDocument, RemoveFileDynamicFieldFormDesignersDocument, AddUserEditIncidentModelsFormDesignersDocument, RemoveUserEditIncidentModelsFormDesignersDocument, AddUserNewIncidentModelsFormDesignersDocument, RemoveUserNewIncidentModelsFormDesignersDocument, AddOperatorEditIncidentModelsFormDesignersDocument, RemoveOperatorEditIncidentModelsFormDesignersDocument, AddOperatorNewIncidentModelsFormDesignersDocument, RemoveOperatorNewIncidentModelsFormDesignersDocument } from '../gqls'
import { ServiceSingleResultOfFormDesigner, QueryContextOfFormDesigner, FilterOfFormDesigner, ServiceSingleResultOfInt64, ServiceListResultOfFormDesigner, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfObjectLinkedFormResult, ServiceListResultOfIncidentModel, QueryContextOfIncidentModel, FilterOfIncidentModel, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfFormDesigner, FormDesignerInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class FormDesignerBaseService {
    
public modelName = 'formDesigner';
public modelPluralName = 'formDesigners';

	private getFormDesignersQuery: GetFormDesignersDocument;
	private firstFormDesignersQuery: FirstFormDesignersDocument;
	private countFormDesignersQuery: CountFormDesignersDocument;
	private findFormDesignersQuery: FindFormDesignersDocument;
	private searchFormDesignersQuery: SearchFormDesignersDocument;
	private exportSchemaFormDesignersQuery: ExportSchemaFormDesignersDocument;
	private exportDataFormDesignersQuery: ExportDataFormDesignersDocument;
	private customQueryFormDesignersQuery: CustomQueryFormDesignersDocument;
	private customQueryIdFormDesignersQuery: CustomQueryIdFormDesignersDocument;
	private usedFormDesignersQuery: UsedFormDesignersDocument;
	private modelsFormDesignersQuery: ModelsFormDesignersDocument;
	private findIncidentModelsFormDesignersQuery: FindIncidentModelsFormDesignersDocument;
	private existFormDesignersQuery: ExistFormDesignersDocument;
	private findRecyclesFormDesignersQuery: FindRecyclesFormDesignersDocument;
	private countRecyclesFormDesignersQuery: CountRecyclesFormDesignersDocument;
	private readOnlyFormDesignersQuery: ReadOnlyFormDesignersDocument;
	private findArchivedFormDesignersQuery: FindArchivedFormDesignersDocument;
	private countAllFormDesignersQuery: CountAllFormDesignersDocument;
	private findDynamicPropertyFieldsFormDesignersQuery: FindDynamicPropertyFieldsFormDesignersDocument;
	private findUnassociatedUserEditIncidentModelsFormDesignersQuery: FindUnassociatedUserEditIncidentModelsFormDesignersDocument;
	private findUnassociatedUserNewIncidentModelsFormDesignersQuery: FindUnassociatedUserNewIncidentModelsFormDesignersDocument;
	private findUnassociatedOperatorEditIncidentModelsFormDesignersQuery: FindUnassociatedOperatorEditIncidentModelsFormDesignersDocument;
	private findUnassociatedOperatorNewIncidentModelsFormDesignersQuery: FindUnassociatedOperatorNewIncidentModelsFormDesignersDocument;
	private insertFormDesignersMutation: InsertFormDesignersDocument;
	private updateFormDesignersMutation: UpdateFormDesignersDocument;
	private importDataFormDesignersMutation: ImportDataFormDesignersDocument;
	private updateManyFormDesignersMutation: UpdateManyFormDesignersDocument;
	private deleteFormDesignersMutation: DeleteFormDesignersDocument;
	private restoreFormDesignersMutation: RestoreFormDesignersDocument;
	private recycleFormDesignersMutation: RecycleFormDesignersDocument;
	private restoreArchivedFormDesignersMutation: RestoreArchivedFormDesignersDocument;
	private archivedFormDesignersMutation: ArchivedFormDesignersDocument;
	private addFileDynamicFieldFormDesignersMutation: AddFileDynamicFieldFormDesignersDocument;
	private removeFileDynamicFieldFormDesignersMutation: RemoveFileDynamicFieldFormDesignersDocument;
	private addUserEditIncidentModelsFormDesignersMutation: AddUserEditIncidentModelsFormDesignersDocument;
	private removeUserEditIncidentModelsFormDesignersMutation: RemoveUserEditIncidentModelsFormDesignersDocument;
	private addUserNewIncidentModelsFormDesignersMutation: AddUserNewIncidentModelsFormDesignersDocument;
	private removeUserNewIncidentModelsFormDesignersMutation: RemoveUserNewIncidentModelsFormDesignersDocument;
	private addOperatorEditIncidentModelsFormDesignersMutation: AddOperatorEditIncidentModelsFormDesignersDocument;
	private removeOperatorEditIncidentModelsFormDesignersMutation: RemoveOperatorEditIncidentModelsFormDesignersDocument;
	private addOperatorNewIncidentModelsFormDesignersMutation: AddOperatorNewIncidentModelsFormDesignersDocument;
	private removeOperatorNewIncidentModelsFormDesignersMutation: RemoveOperatorNewIncidentModelsFormDesignersDocument;

	constructor(private injector: Injector) {
		this.getFormDesignersQuery = this.injector.get(GetFormDesignersDocument);
		this.firstFormDesignersQuery = this.injector.get(FirstFormDesignersDocument);
		this.countFormDesignersQuery = this.injector.get(CountFormDesignersDocument);
		this.findFormDesignersQuery = this.injector.get(FindFormDesignersDocument);
		this.searchFormDesignersQuery = this.injector.get(SearchFormDesignersDocument);
		this.exportSchemaFormDesignersQuery = this.injector.get(ExportSchemaFormDesignersDocument);
		this.exportDataFormDesignersQuery = this.injector.get(ExportDataFormDesignersDocument);
		this.customQueryFormDesignersQuery = this.injector.get(CustomQueryFormDesignersDocument);
		this.customQueryIdFormDesignersQuery = this.injector.get(CustomQueryIdFormDesignersDocument);
		this.usedFormDesignersQuery = this.injector.get(UsedFormDesignersDocument);
		this.modelsFormDesignersQuery = this.injector.get(ModelsFormDesignersDocument);
		this.findIncidentModelsFormDesignersQuery = this.injector.get(FindIncidentModelsFormDesignersDocument);
		this.existFormDesignersQuery = this.injector.get(ExistFormDesignersDocument);
		this.findRecyclesFormDesignersQuery = this.injector.get(FindRecyclesFormDesignersDocument);
		this.countRecyclesFormDesignersQuery = this.injector.get(CountRecyclesFormDesignersDocument);
		this.readOnlyFormDesignersQuery = this.injector.get(ReadOnlyFormDesignersDocument);
		this.findArchivedFormDesignersQuery = this.injector.get(FindArchivedFormDesignersDocument);
		this.countAllFormDesignersQuery = this.injector.get(CountAllFormDesignersDocument);
		this.findDynamicPropertyFieldsFormDesignersQuery = this.injector.get(FindDynamicPropertyFieldsFormDesignersDocument);
		this.findUnassociatedUserEditIncidentModelsFormDesignersQuery = this.injector.get(FindUnassociatedUserEditIncidentModelsFormDesignersDocument);
		this.findUnassociatedUserNewIncidentModelsFormDesignersQuery = this.injector.get(FindUnassociatedUserNewIncidentModelsFormDesignersDocument);
		this.findUnassociatedOperatorEditIncidentModelsFormDesignersQuery = this.injector.get(FindUnassociatedOperatorEditIncidentModelsFormDesignersDocument);
		this.findUnassociatedOperatorNewIncidentModelsFormDesignersQuery = this.injector.get(FindUnassociatedOperatorNewIncidentModelsFormDesignersDocument);
		this.insertFormDesignersMutation = this.injector.get(InsertFormDesignersDocument);
		this.updateFormDesignersMutation = this.injector.get(UpdateFormDesignersDocument);
		this.importDataFormDesignersMutation = this.injector.get(ImportDataFormDesignersDocument);
		this.updateManyFormDesignersMutation = this.injector.get(UpdateManyFormDesignersDocument);
		this.deleteFormDesignersMutation = this.injector.get(DeleteFormDesignersDocument);
		this.restoreFormDesignersMutation = this.injector.get(RestoreFormDesignersDocument);
		this.recycleFormDesignersMutation = this.injector.get(RecycleFormDesignersDocument);
		this.restoreArchivedFormDesignersMutation = this.injector.get(RestoreArchivedFormDesignersDocument);
		this.archivedFormDesignersMutation = this.injector.get(ArchivedFormDesignersDocument);
		this.addFileDynamicFieldFormDesignersMutation = this.injector.get(AddFileDynamicFieldFormDesignersDocument);
		this.removeFileDynamicFieldFormDesignersMutation = this.injector.get(RemoveFileDynamicFieldFormDesignersDocument);
		this.addUserEditIncidentModelsFormDesignersMutation = this.injector.get(AddUserEditIncidentModelsFormDesignersDocument);
		this.removeUserEditIncidentModelsFormDesignersMutation = this.injector.get(RemoveUserEditIncidentModelsFormDesignersDocument);
		this.addUserNewIncidentModelsFormDesignersMutation = this.injector.get(AddUserNewIncidentModelsFormDesignersDocument);
		this.removeUserNewIncidentModelsFormDesignersMutation = this.injector.get(RemoveUserNewIncidentModelsFormDesignersDocument);
		this.addOperatorEditIncidentModelsFormDesignersMutation = this.injector.get(AddOperatorEditIncidentModelsFormDesignersDocument);
		this.removeOperatorEditIncidentModelsFormDesignersMutation = this.injector.get(RemoveOperatorEditIncidentModelsFormDesignersDocument);
		this.addOperatorNewIncidentModelsFormDesignersMutation = this.injector.get(AddOperatorNewIncidentModelsFormDesignersDocument);
		this.removeOperatorNewIncidentModelsFormDesignersMutation = this.injector.get(RemoveOperatorNewIncidentModelsFormDesignersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFormDesigner> {
    
    		let variables: GetFormDesignersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.get));
            }
            else{
                let result:ServiceSingleResultOfFormDesigner={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFormDesigner,
		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFormDesigner> {
    
    		let variables: FirstFormDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountFormDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFormDesigner,
		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFormDesigner> {
    
    		let variables: FindFormDesignersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfFormDesigner,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFormDesigner> {
    
    		let variables: SearchFormDesignersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaFormDesignersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataFormDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfFormDesigner,
		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFormDesigner> {
    
    		let variables: CustomQueryFormDesignersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfFormDesigner,
		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFormDesigner> {
    
    		let variables: CustomQueryIdFormDesignersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedFormDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.usedFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.used));
    	}

    	/**  */
    	@InjectArgs
    	public models(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('objectId?') objectId?: string,
		@Args('modelId?') modelId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfObjectLinkedFormResult> {
    
    		let variables: ModelsFormDesignersBaseVariables = {
    			type: type,
			modelId: modelId,
			objectId: objectId
    		}
    				return this.modelsFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.models));
    	}

    	/**  */
    	@InjectArgs
    	public findIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			options: options,
			filter: filter
    		}
    				return this.findIncidentModelsFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.findIncidentModels));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistFormDesignersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFormDesigner,
		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFormDesigner> {
    
    		let variables: FindRecyclesFormDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesFormDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyFormDesignersBaseVariables = {
    			id: id
    		}
    				return this.readOnlyFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFormDesigner,
		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFormDesigner> {
    
    		let variables: FindArchivedFormDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllFormDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countAllFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsFormDesignersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedUserEditIncidentModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfIncidentModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('userEditIncidentModels', fields, null, [
			GqlSubFieldArg.create('filterOfUserEditIncidentModels', 'filter'),
			GqlSubFieldArg.create('optionsOfUserEditIncidentModels', 'options'),
		]),
		])
		extendedVariables['filterOfUserEditIncidentModels'] = filter;
		extendedVariables['optionsOfUserEditIncidentModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.userEditIncidentModels));
            }
            else{
                let result: ServiceListResultOfIncidentModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUserEditIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindUnassociatedUserEditIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUserEditIncidentModelsFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.findUnassociatedUserEditIncidentModels));
    	}

	@InjectArgs
	public findAssociatedUserNewIncidentModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfIncidentModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('userNewIncidentModels', fields, null, [
			GqlSubFieldArg.create('filterOfUserNewIncidentModels', 'filter'),
			GqlSubFieldArg.create('optionsOfUserNewIncidentModels', 'options'),
		]),
		])
		extendedVariables['filterOfUserNewIncidentModels'] = filter;
		extendedVariables['optionsOfUserNewIncidentModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.userNewIncidentModels));
            }
            else{
                let result: ServiceListResultOfIncidentModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUserNewIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindUnassociatedUserNewIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUserNewIncidentModelsFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.findUnassociatedUserNewIncidentModels));
    	}

	@InjectArgs
	public findAssociatedOperatorEditIncidentModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfIncidentModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('operatorEditIncidentModels', fields, null, [
			GqlSubFieldArg.create('filterOfOperatorEditIncidentModels', 'filter'),
			GqlSubFieldArg.create('optionsOfOperatorEditIncidentModels', 'options'),
		]),
		])
		extendedVariables['filterOfOperatorEditIncidentModels'] = filter;
		extendedVariables['optionsOfOperatorEditIncidentModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.operatorEditIncidentModels));
            }
            else{
                let result: ServiceListResultOfIncidentModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedOperatorEditIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindUnassociatedOperatorEditIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedOperatorEditIncidentModelsFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.findUnassociatedOperatorEditIncidentModels));
    	}

	@InjectArgs
	public findAssociatedOperatorNewIncidentModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfIncidentModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('operatorNewIncidentModels', fields, null, [
			GqlSubFieldArg.create('filterOfOperatorNewIncidentModels', 'filter'),
			GqlSubFieldArg.create('optionsOfOperatorNewIncidentModels', 'options'),
		]),
		])
		extendedVariables['filterOfOperatorNewIncidentModels'] = filter;
		extendedVariables['optionsOfOperatorNewIncidentModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.operatorNewIncidentModels));
            }
            else{
                let result: ServiceListResultOfIncidentModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedOperatorNewIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindUnassociatedOperatorNewIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedOperatorNewIncidentModelsFormDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.findUnassociatedOperatorNewIncidentModels));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: FormDesignerInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFormDesigner> {
    
    		let variables: InsertFormDesignersBaseVariables = {
    			entity: entity
    		}
    				return this.insertFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFormDesigner> {
    
    		let variables: UpdateFormDesignersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataFormDesignersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfFormDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyFormDesignersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteFormDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreFormDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleFormDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedFormDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedFormDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldFormDesignersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldFormDesignersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addUserEditIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUserEditIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			incidentModelIds: incidentModelIds
    		}
    				return this.addUserEditIncidentModelsFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.addUserEditIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeUserEditIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUserEditIncidentModelsFormDesignersBaseVariables = {
    			incidentModelIds: incidentModelIds
    		}
    				return this.removeUserEditIncidentModelsFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.removeUserEditIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public addUserNewIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUserNewIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			incidentModelIds: incidentModelIds
    		}
    				return this.addUserNewIncidentModelsFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.addUserNewIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeUserNewIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUserNewIncidentModelsFormDesignersBaseVariables = {
    			incidentModelIds: incidentModelIds
    		}
    				return this.removeUserNewIncidentModelsFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.removeUserNewIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorEditIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorEditIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			incidentModelIds: incidentModelIds
    		}
    				return this.addOperatorEditIncidentModelsFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.addOperatorEditIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorEditIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorEditIncidentModelsFormDesignersBaseVariables = {
    			incidentModelIds: incidentModelIds
    		}
    				return this.removeOperatorEditIncidentModelsFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.removeOperatorEditIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorNewIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorNewIncidentModelsFormDesignersBaseVariables = {
    			id: id,
			incidentModelIds: incidentModelIds
    		}
    				return this.addOperatorNewIncidentModelsFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.addOperatorNewIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorNewIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorNewIncidentModelsFormDesignersBaseVariables = {
    			incidentModelIds: incidentModelIds
    		}
    				return this.removeOperatorNewIncidentModelsFormDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.formDesigners.removeOperatorNewIncidentModels));
    	}
    
}