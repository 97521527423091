import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfFormDesigner, FilterOfFormDesigner, QueryContextOfFormDesigner, ServiceSingleResultOfInt64, ServiceListResultOfFormDesigner, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfObjectLinkedFormResult, ServiceListResultOfIncidentModel, QueryContextOfIncidentModel, FilterOfIncidentModel, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfFormDesigner, FormDesignerInput } from '../types'

export type GetFormDesignersResultType = {
    formDesigners: {
        get?: Maybe<ServiceSingleResultOfFormDesigner>
    }
}

export type GetFormDesignersBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getFormDesigners ${args} {
    formDesigners {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstFormDesignersResultType = {
    formDesigners: {
        first?: Maybe<ServiceSingleResultOfFormDesigner>
    }
}

export type FirstFormDesignersBaseVariables = {
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFormDesigner>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfFormDesigner = null, $options: QueryContextOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstFormDesigners ${args} {
    formDesigners {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountFormDesignersResultType = {
    formDesigners: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountFormDesignersBaseVariables = {
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countFormDesigners ${args} {
    formDesigners {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindFormDesignersResultType = {
    formDesigners: {
        find?: Maybe<ServiceListResultOfFormDesigner>
    }
}

export type FindFormDesignersBaseVariables = {
	options: Maybe<QueryContextOfFormDesigner>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfFormDesigner = null, $filter: FilterOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findFormDesigners ${args} {
    formDesigners {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchFormDesignersResultType = {
    formDesigners: {
        search?: Maybe<ServiceListResultOfFormDesigner>
    }
}

export type SearchFormDesignersBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfFormDesigner>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchFormDesigners ${args} {
    formDesigners {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaFormDesignersResultType = {
    formDesigners: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaFormDesignersBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaFormDesigners ${args} {
    formDesigners {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataFormDesignersResultType = {
    formDesigners: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataFormDesignersBaseVariables = {
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataFormDesigners ${args} {
    formDesigners {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryFormDesignersResultType = {
    formDesigners: {
        customQuery?: Maybe<ServiceListResultOfFormDesigner>
    }
}

export type CustomQueryFormDesignersBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFormDesigner>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfFormDesigner = null, $options: QueryContextOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryFormDesigners ${args} {
    formDesigners {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdFormDesignersResultType = {
    formDesigners: {
        customQueryId?: Maybe<ServiceListResultOfFormDesigner>
    }
}

export type CustomQueryIdFormDesignersBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFormDesigner>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfFormDesigner = null, $options: QueryContextOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdFormDesigners ${args} {
    formDesigners {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedFormDesignersResultType = {
    formDesigners: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedFormDesignersBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedFormDesigners ${args} {
    formDesigners {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ModelsFormDesignersResultType = {
    formDesigners: {
        models?: Maybe<ServiceSingleResultOfObjectLinkedFormResult>
    }
}

export type ModelsFormDesignersBaseVariables = {
	type: Maybe<string>, /**  */
	modelId: Maybe<string>, /**  */
	objectId: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $modelId: Uuid = null, $objectId: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query modelsFormDesigners ${args} {
    formDesigners {
        models(type: $type, modelId: $modelId, objectId: $objectId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfObjectLinkedFormResult", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindIncidentModelsFormDesignersResultType = {
    formDesigners: {
        findIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindIncidentModelsFormDesignersBaseVariables = {
	id: Maybe<string>, /**  */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $options: QueryContextOfIncidentModel = null, $filter: FilterOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findIncidentModelsFormDesigners ${args} {
    formDesigners {
        findIncidentModels(id: $id, options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistFormDesignersResultType = {
    formDesigners: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistFormDesignersBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existFormDesigners ${args} {
    formDesigners {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesFormDesignersResultType = {
    formDesigners: {
        findRecycles?: Maybe<ServiceListResultOfFormDesigner>
    }
}

export type FindRecyclesFormDesignersBaseVariables = {
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFormDesigner>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfFormDesigner = null, $options: QueryContextOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesFormDesigners ${args} {
    formDesigners {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesFormDesignersResultType = {
    formDesigners: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesFormDesignersBaseVariables = {
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesFormDesigners ${args} {
    formDesigners {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyFormDesignersResultType = {
    formDesigners: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyFormDesignersBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyFormDesigners ${args} {
    formDesigners {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedFormDesignersResultType = {
    formDesigners: {
        findArchived?: Maybe<ServiceListResultOfFormDesigner>
    }
}

export type FindArchivedFormDesignersBaseVariables = {
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFormDesigner>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfFormDesigner = null, $options: QueryContextOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedFormDesigners ${args} {
    formDesigners {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllFormDesignersResultType = {
    formDesigners: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllFormDesignersBaseVariables = {
	filter: Maybe<FilterOfFormDesigner>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllFormDesigners ${args} {
    formDesigners {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsFormDesignersResultType = {
    formDesigners: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsFormDesignersBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfFormDesigner>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsFormDesigners ${args} {
    formDesigners {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedUserEditIncidentModelsFormDesignersResultType = {
    formDesigners: {
        findUnassociatedUserEditIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindUnassociatedUserEditIncidentModelsFormDesignersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedUserEditIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfIncidentModel = null, $options: QueryContextOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedUserEditIncidentModelsFormDesigners ${args} {
    formDesigners {
        findUnassociatedUserEditIncidentModels(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedUserEditIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedUserEditIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedUserNewIncidentModelsFormDesignersResultType = {
    formDesigners: {
        findUnassociatedUserNewIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindUnassociatedUserNewIncidentModelsFormDesignersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedUserNewIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfIncidentModel = null, $options: QueryContextOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedUserNewIncidentModelsFormDesigners ${args} {
    formDesigners {
        findUnassociatedUserNewIncidentModels(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedUserNewIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedUserNewIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedOperatorEditIncidentModelsFormDesignersResultType = {
    formDesigners: {
        findUnassociatedOperatorEditIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindUnassociatedOperatorEditIncidentModelsFormDesignersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedOperatorEditIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfIncidentModel = null, $options: QueryContextOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedOperatorEditIncidentModelsFormDesigners ${args} {
    formDesigners {
        findUnassociatedOperatorEditIncidentModels(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedOperatorEditIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedOperatorEditIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedOperatorNewIncidentModelsFormDesignersResultType = {
    formDesigners: {
        findUnassociatedOperatorNewIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindUnassociatedOperatorNewIncidentModelsFormDesignersBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedOperatorNewIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfIncidentModel = null, $options: QueryContextOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedOperatorNewIncidentModelsFormDesigners ${args} {
    formDesigners {
        findUnassociatedOperatorNewIncidentModels(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedOperatorNewIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedOperatorNewIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertFormDesignersResultType = {
    formDesigners: {
        insert?: Maybe<ServiceSingleResultOfFormDesigner>
    }
}

export type InsertFormDesignersBaseVariables = {
	entity: FormDesignerInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: FormDesignerInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertFormDesigners ${args} {
    formDesigners {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateFormDesignersResultType = {
    formDesigners: {
        update?: Maybe<ServiceSingleResultOfFormDesigner>
    }
}

export type UpdateFormDesignersBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfFormDesigner>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateFormDesigners ${args} {
    formDesigners {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfFormDesigner", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataFormDesignersResultType = {
    formDesigners: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataFormDesignersBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataFormDesigners ${args} {
    formDesigners {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyFormDesignersResultType = {
    formDesigners: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyFormDesignersBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfFormDesigner>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfFormDesigner = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyFormDesigners ${args} {
    formDesigners {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteFormDesignersResultType = {
    formDesigners: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteFormDesignersBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteFormDesigners ${args} {
    formDesigners {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreFormDesignersResultType = {
    formDesigners: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreFormDesignersBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreFormDesigners ${args} {
    formDesigners {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleFormDesignersResultType = {
    formDesigners: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleFormDesignersBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleFormDesigners ${args} {
    formDesigners {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedFormDesignersResultType = {
    formDesigners: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedFormDesignersBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedFormDesigners ${args} {
    formDesigners {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedFormDesignersResultType = {
    formDesigners: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedFormDesignersBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedFormDesigners ${args} {
    formDesigners {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldFormDesignersResultType = {
    formDesigners: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldFormDesignersBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldFormDesigners ${args} {
    formDesigners {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldFormDesignersResultType = {
    formDesigners: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldFormDesignersBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldFormDesigners ${args} {
    formDesigners {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddUserEditIncidentModelsFormDesignersResultType = {
    formDesigners: {
        addUserEditIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddUserEditIncidentModelsFormDesignersBaseVariables = {
	id: string, /**  */
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddUserEditIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addUserEditIncidentModelsFormDesigners ${args} {
    formDesigners {
        addUserEditIncidentModels(id: $id, incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddUserEditIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddUserEditIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveUserEditIncidentModelsFormDesignersResultType = {
    formDesigners: {
        removeUserEditIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveUserEditIncidentModelsFormDesignersBaseVariables = {
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveUserEditIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeUserEditIncidentModelsFormDesigners ${args} {
    formDesigners {
        removeUserEditIncidentModels(incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveUserEditIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveUserEditIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddUserNewIncidentModelsFormDesignersResultType = {
    formDesigners: {
        addUserNewIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddUserNewIncidentModelsFormDesignersBaseVariables = {
	id: string, /**  */
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddUserNewIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addUserNewIncidentModelsFormDesigners ${args} {
    formDesigners {
        addUserNewIncidentModels(id: $id, incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddUserNewIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddUserNewIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveUserNewIncidentModelsFormDesignersResultType = {
    formDesigners: {
        removeUserNewIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveUserNewIncidentModelsFormDesignersBaseVariables = {
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveUserNewIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeUserNewIncidentModelsFormDesigners ${args} {
    formDesigners {
        removeUserNewIncidentModels(incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveUserNewIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveUserNewIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddOperatorEditIncidentModelsFormDesignersResultType = {
    formDesigners: {
        addOperatorEditIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddOperatorEditIncidentModelsFormDesignersBaseVariables = {
	id: string, /**  */
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddOperatorEditIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addOperatorEditIncidentModelsFormDesigners ${args} {
    formDesigners {
        addOperatorEditIncidentModels(id: $id, incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddOperatorEditIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddOperatorEditIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveOperatorEditIncidentModelsFormDesignersResultType = {
    formDesigners: {
        removeOperatorEditIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveOperatorEditIncidentModelsFormDesignersBaseVariables = {
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveOperatorEditIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeOperatorEditIncidentModelsFormDesigners ${args} {
    formDesigners {
        removeOperatorEditIncidentModels(incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveOperatorEditIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveOperatorEditIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddOperatorNewIncidentModelsFormDesignersResultType = {
    formDesigners: {
        addOperatorNewIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddOperatorNewIncidentModelsFormDesignersBaseVariables = {
	id: string, /**  */
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddOperatorNewIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addOperatorNewIncidentModelsFormDesigners ${args} {
    formDesigners {
        addOperatorNewIncidentModels(id: $id, incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddOperatorNewIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddOperatorNewIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveOperatorNewIncidentModelsFormDesignersResultType = {
    formDesigners: {
        removeOperatorNewIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveOperatorNewIncidentModelsFormDesignersBaseVariables = {
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveOperatorNewIncidentModelsFormDesignersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeOperatorNewIncidentModelsFormDesigners ${args} {
    formDesigners {
        removeOperatorNewIncidentModels(incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveOperatorNewIncidentModelsFormDesignersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveOperatorNewIncidentModelsFormDesignersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
