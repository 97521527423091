<div
  *ngIf="isActionProgress && messageActionProgress != undefined"
  class="cl-action"
>
  <dx-load-indicator
    id="small-indicator"
    height="20"
    width="20"
  ></dx-load-indicator>
  <span>{{ messageActionProgress }}</span>
</div>

<dx-toolbar
  class="actions"
  [items]="items"
  [visible]="!isActionProgress || messageActionProgress === undefined"
>
  <!-- Application des différents templates -->

  <ng-container
    *ngFor="let item of items | filter: 'options.template' : undefined : true"
  >
    <div *dxTemplate="let data of item.options.template">
      <ng-template
        *ngTemplateOutlet="
          templateService.get(item.options.template);
          context: { $implicit: item }
        "
      >
      </ng-template>
    </div>
  </ng-container>
</dx-toolbar>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="loadPanel"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
