import { Injectable } from '@angular/core';
import { CorePolicyValidator, PolicyOperator } from '@clarilog/core/services2';

/** Permet d'obtenir la bonne url de redirection lorsqu'il y a une route fille par défaut sans bloquer l'ensemble du menu */
@Injectable({
  providedIn: 'root',
})
export class CoreDefaultChildRouteRedirection {
  constructor(private policyValidator: CorePolicyValidator) {}

  /**
   * Obtient la bonne url de redirection lorsqu'il y a une route fille par défaut (en testant les permissions)
   * @param defaultChildPath L'url fille par défaut
   * @param permissions La permission [ou les permissions] à vérifier
   * @param policyValidatorOperation L'operateur de validation de permissions à appliquer si plusieurs permissions sont à vérifier
   * @returns L'url fille par défaut ou rien
   */
  getRedirectToPath(
    defaultChildPath: string,
    permissions: string | string[],
    policyValidatorOperation: PolicyOperator = 'and',
  ): string {
    let accessDefaultPath = this.policyValidator.validate(
      permissions,
      policyValidatorOperation,
    );
    let redirectPath = defaultChildPath;

    if (!accessDefaultPath) {
      redirectPath = '';
    }

    return redirectPath;
  }
}
