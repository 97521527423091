import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfOutStockMovement, FilterOfOutStockMovement, QueryContextOfOutStockMovement, ServiceSingleResultOfInt64, ServiceListResultOfOutStockMovement, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, FilterOfFileModel, QueryContextOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfAsset, FilterOfAsset, QueryContextOfAsset, ServiceListResultOfOutStock, FilterOfOutStock, QueryContextOfOutStock, ServiceListResultOfOutCart, FilterOfOutCart, QueryContextOfOutCart, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfOutStockMovement, OutStockMovementInput, ServiceSingleResultOfGuid } from '../types'

export type GetOutStockMovementsResultType = {
    outStockMovements: {
        get?: Maybe<ServiceSingleResultOfOutStockMovement>
    }
}

export type GetOutStockMovementsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getOutStockMovements ${args} {
    outStockMovements {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstOutStockMovementsResultType = {
    outStockMovements: {
        first?: Maybe<ServiceSingleResultOfOutStockMovement>
    }
}

export type FirstOutStockMovementsBaseVariables = {
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOutStockMovement>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOutStockMovement = null, $options: QueryContextOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstOutStockMovements ${args} {
    outStockMovements {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountOutStockMovementsResultType = {
    outStockMovements: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountOutStockMovementsBaseVariables = {
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countOutStockMovements ${args} {
    outStockMovements {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOutStockMovementsResultType = {
    outStockMovements: {
        find?: Maybe<ServiceListResultOfOutStockMovement>
    }
}

export type FindOutStockMovementsBaseVariables = {
	options: Maybe<QueryContextOfOutStockMovement>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfOutStockMovement = null, $filter: FilterOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOutStockMovements ${args} {
    outStockMovements {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchOutStockMovementsResultType = {
    outStockMovements: {
        search?: Maybe<ServiceListResultOfOutStockMovement>
    }
}

export type SearchOutStockMovementsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfOutStockMovement>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchOutStockMovements ${args} {
    outStockMovements {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaOutStockMovementsResultType = {
    outStockMovements: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaOutStockMovementsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaOutStockMovements ${args} {
    outStockMovements {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataOutStockMovementsResultType = {
    outStockMovements: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataOutStockMovementsBaseVariables = {
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataOutStockMovements ${args} {
    outStockMovements {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryOutStockMovementsResultType = {
    outStockMovements: {
        customQuery?: Maybe<ServiceListResultOfOutStockMovement>
    }
}

export type CustomQueryOutStockMovementsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOutStockMovement>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfOutStockMovement = null, $options: QueryContextOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryOutStockMovements ${args} {
    outStockMovements {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdOutStockMovementsResultType = {
    outStockMovements: {
        customQueryId?: Maybe<ServiceListResultOfOutStockMovement>
    }
}

export type CustomQueryIdOutStockMovementsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOutStockMovement>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfOutStockMovement = null, $options: QueryContextOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdOutStockMovements ${args} {
    outStockMovements {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindFilesOutStockMovementsResultType = {
    outStockMovements: {
        findFiles?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindFilesOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les fichiers liés. */
@Injectable({providedIn: 'root'})
export class FindFilesOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findFilesOutStockMovements ${args} {
    outStockMovements {
        findFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindFilesOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindFilesOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAttachmentFilesOutStockMovementsResultType = {
    outStockMovements: {
        findAttachmentFiles?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindAttachmentFilesOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les fichiers attachés liés. */
@Injectable({providedIn: 'root'})
export class FindAttachmentFilesOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAttachmentFilesOutStockMovements ${args} {
    outStockMovements {
        findAttachmentFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAttachmentFilesOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAttachmentFilesOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindHelpDeskUserAttachmentFilesOutStockMovementsResultType = {
    outStockMovements: {
        findHelpDeskUserAttachmentFiles?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindHelpDeskUserAttachmentFilesOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les fichiers attachés liés visible pour les users help desk. */
@Injectable({providedIn: 'root'})
export class FindHelpDeskUserAttachmentFilesOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findHelpDeskUserAttachmentFilesOutStockMovements ${args} {
    outStockMovements {
        findHelpDeskUserAttachmentFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindHelpDeskUserAttachmentFilesOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindHelpDeskUserAttachmentFilesOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedOutStockMovementsResultType = {
    outStockMovements: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedOutStockMovementsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedOutStockMovements ${args} {
    outStockMovements {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssetsOutStockMovementsResultType = {
    outStockMovements: {
        findAssets?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindAssetsOutStockMovementsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssetsOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssetsOutStockMovements ${args} {
    outStockMovements {
        findAssets(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssetsOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssetsOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssetsCommissionningOutStockMovementsResultType = {
    outStockMovements: {
        findAssetsCommissionning?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindAssetsCommissionningOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssetsCommissionningOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssetsCommissionningOutStockMovements ${args} {
    outStockMovements {
        findAssetsCommissionning(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssetsCommissionningOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssetsCommissionningOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNotAssetsCommissionningOutStockMovementsResultType = {
    outStockMovements: {
        findNotAssetsCommissionning?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindNotAssetsCommissionningOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	storageUnitIds: Array<string>, /**  */
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNotAssetsCommissionningOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $storageUnitIds: [Uuid!], $filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNotAssetsCommissionningOutStockMovements ${args} {
    outStockMovements {
        findNotAssetsCommissionning(id: $id, storageUnitIds: $storageUnitIds, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNotAssetsCommissionningOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNotAssetsCommissionningOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindStockOutForUserOutStockMovementsResultType = {
    outStockMovements: {
        findStockOutForUser?: Maybe<ServiceListResultOfOutStock>
    }
}

export type FindStockOutForUserOutStockMovementsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfOutStock>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOutStock>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindStockOutForUserOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfOutStock = null, $options: QueryContextOfOutStock = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findStockOutForUserOutStockMovements ${args} {
    outStockMovements {
        findStockOutForUser(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindStockOutForUserOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindStockOutForUserOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutStock", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindStorageOutConsumableItemsOutStockMovementsResultType = {
    outStockMovements: {
        findStorageOutConsumableItems?: Maybe<ServiceListResultOfOutCart>
    }
}

export type FindStorageOutConsumableItemsOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	storageUnitIds: Array<string>, /**  */
	filter: Maybe<FilterOfOutCart>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOutCart>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindStorageOutConsumableItemsOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $storageUnitIds: [Uuid!], $filter: FilterOfOutCart = null, $options: QueryContextOfOutCart = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findStorageOutConsumableItemsOutStockMovements ${args} {
    outStockMovements {
        findStorageOutConsumableItems(id: $id, storageUnitIds: $storageUnitIds, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindStorageOutConsumableItemsOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindStorageOutConsumableItemsOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutCart", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindStockOutForAssetOutStockMovementsResultType = {
    outStockMovements: {
        findStockOutForAsset?: Maybe<ServiceListResultOfOutStock>
    }
}

export type FindStockOutForAssetOutStockMovementsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfOutStock>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOutStock>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindStockOutForAssetOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfOutStock = null, $options: QueryContextOfOutStock = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findStockOutForAssetOutStockMovements ${args} {
    outStockMovements {
        findStockOutForAsset(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindStockOutForAssetOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindStockOutForAssetOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutStock", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistOutStockMovementsResultType = {
    outStockMovements: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistOutStockMovementsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existOutStockMovements ${args} {
    outStockMovements {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesOutStockMovementsResultType = {
    outStockMovements: {
        findRecycles?: Maybe<ServiceListResultOfOutStockMovement>
    }
}

export type FindRecyclesOutStockMovementsBaseVariables = {
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOutStockMovement>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOutStockMovement = null, $options: QueryContextOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesOutStockMovements ${args} {
    outStockMovements {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesOutStockMovementsResultType = {
    outStockMovements: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesOutStockMovementsBaseVariables = {
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesOutStockMovements ${args} {
    outStockMovements {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyOutStockMovementsResultType = {
    outStockMovements: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyOutStockMovementsBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyOutStockMovements ${args} {
    outStockMovements {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedOutStockMovementsResultType = {
    outStockMovements: {
        findArchived?: Maybe<ServiceListResultOfOutStockMovement>
    }
}

export type FindArchivedOutStockMovementsBaseVariables = {
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOutStockMovement>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOutStockMovement = null, $options: QueryContextOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedOutStockMovements ${args} {
    outStockMovements {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllOutStockMovementsResultType = {
    outStockMovements: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllOutStockMovementsBaseVariables = {
	filter: Maybe<FilterOfOutStockMovement>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllOutStockMovements ${args} {
    outStockMovements {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsOutStockMovementsResultType = {
    outStockMovements: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfOutStockMovement>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsOutStockMovements ${args} {
    outStockMovements {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAssetsOutStockMovementsResultType = {
    outStockMovements: {
        findUnassociatedAssets?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindUnassociatedAssetsOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAssetsOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAssetsOutStockMovements ${args} {
    outStockMovements {
        findUnassociatedAssets(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAssetsOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAssetsOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertOutStockMovementsResultType = {
    outStockMovements: {
        insert?: Maybe<ServiceSingleResultOfOutStockMovement>
    }
}

export type InsertOutStockMovementsBaseVariables = {
	entity: OutStockMovementInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: OutStockMovementInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertOutStockMovements ${args} {
    outStockMovements {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateOutStockMovementsResultType = {
    outStockMovements: {
        update?: Maybe<ServiceSingleResultOfOutStockMovement>
    }
}

export type UpdateOutStockMovementsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfOutStockMovement>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateOutStockMovements ${args} {
    outStockMovements {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOutStockMovement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataOutStockMovementsResultType = {
    outStockMovements: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataOutStockMovementsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataOutStockMovements ${args} {
    outStockMovements {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyOutStockMovementsResultType = {
    outStockMovements: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyOutStockMovementsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfOutStockMovement>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfOutStockMovement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyOutStockMovements ${args} {
    outStockMovements {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteOutStockMovementsResultType = {
    outStockMovements: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteOutStockMovementsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteOutStockMovements ${args} {
    outStockMovements {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFilesOutStockMovementsResultType = {
    outStockMovements: {
        addFiles?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFilesOutStockMovementsBaseVariables = {
	id: string, /**  */
	fileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFilesOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFilesOutStockMovements ${args} {
    outStockMovements {
        addFiles(id: $id, fileIds: $fileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFilesOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFilesOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFilesOutStockMovementsResultType = {
    outStockMovements: {
        removeFiles?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFilesOutStockMovementsBaseVariables = {
	id: string, /**  */
	fileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFilesOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFilesOutStockMovements ${args} {
    outStockMovements {
        removeFiles(id: $id, fileIds: $fileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFilesOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFilesOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UploadChunkFileOutStockMovementsResultType = {
    outStockMovements: {
        uploadChunkFile?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type UploadChunkFileOutStockMovementsBaseVariables = {
	name: Maybe<string>, /**  */
	size: number, /**  */
	type: Maybe<string>, /**  */
	chunks: Maybe<string>, /**  */
	indexChunk: number, /**  */
	lastChunk: boolean, /**  */
	fileId: Maybe<string>, /**  */
	entityId: Maybe<string>, /**  */
	visibleByHelpDeskUser: boolean, /**  */
	outgoingEmails: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UploadChunkFileOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$name: String = null, $size: Int!, $type: String = null, $chunks: String = null, $indexChunk: Int!, $lastChunk: Boolean!, $fileId: Uuid = null, $entityId: Uuid = null, $visibleByHelpDeskUser: Boolean!, $outgoingEmails: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation uploadChunkFileOutStockMovements ${args} {
    outStockMovements {
        uploadChunkFile(name: $name, size: $size, type: $type, chunks: $chunks, indexChunk: $indexChunk, lastChunk: $lastChunk, fileId: $fileId, entityId: $entityId, visibleByHelpDeskUser: $visibleByHelpDeskUser, outgoingEmails: $outgoingEmails) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UploadChunkFileOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UploadChunkFileOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAssetsCommissionningOutStockMovementsResultType = {
    outStockMovements: {
        addAssetsCommissionning?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAssetsCommissionningOutStockMovementsBaseVariables = {
	id: string, /**  */
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAssetsCommissionningOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAssetsCommissionningOutStockMovements ${args} {
    outStockMovements {
        addAssetsCommissionning(id: $id, assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAssetsCommissionningOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAssetsCommissionningOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteConsumableItemsOutStockMovementsResultType = {
    outStockMovements: {
        deleteConsumableItems?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteConsumableItemsOutStockMovementsBaseVariables = {
	id: string, /**  */
	entryId: string, /**  */
	storageUnitId: string, /**  */
	updateHasConsumableProperty: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class DeleteConsumableItemsOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entryId: Uuid!, $storageUnitId: Uuid!, $updateHasConsumableProperty: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteConsumableItemsOutStockMovements ${args} {
    outStockMovements {
        deleteConsumableItems(id: $id, entryId: $entryId, storageUnitId: $storageUnitId, updateHasConsumableProperty: $updateHasConsumableProperty) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteConsumableItemsOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteConsumableItemsOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreOutStockMovementsResultType = {
    outStockMovements: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreOutStockMovementsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreOutStockMovements ${args} {
    outStockMovements {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleOutStockMovementsResultType = {
    outStockMovements: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleOutStockMovementsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleOutStockMovements ${args} {
    outStockMovements {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedOutStockMovementsResultType = {
    outStockMovements: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedOutStockMovementsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedOutStockMovements ${args} {
    outStockMovements {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedOutStockMovementsResultType = {
    outStockMovements: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedOutStockMovementsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedOutStockMovements ${args} {
    outStockMovements {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldOutStockMovementsResultType = {
    outStockMovements: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldOutStockMovements ${args} {
    outStockMovements {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldOutStockMovementsResultType = {
    outStockMovements: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldOutStockMovementsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldOutStockMovements ${args} {
    outStockMovements {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAssetsOutStockMovementsResultType = {
    outStockMovements: {
        addAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAssetsOutStockMovementsBaseVariables = {
	id: string, /**  */
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAssetsOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAssetsOutStockMovements ${args} {
    outStockMovements {
        addAssets(id: $id, assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAssetsOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAssetsOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAssetsOutStockMovementsResultType = {
    outStockMovements: {
        removeAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAssetsOutStockMovementsBaseVariables = {
	id: string, /**  */
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAssetsOutStockMovementsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAssetsOutStockMovements ${args} {
    outStockMovements {
        removeAssets(id: $id, assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAssetsOutStockMovementsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAssetsOutStockMovementsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
