import { Component, Input, OnInit, ViewChild } from '@angular/core';

import {
  IncidentCoreService,
  RequestCoreService,
  TicketCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { CoreTicketListComponent } from '@clarilog/shared2/components/list/ticket-list/ticket-list.component';
import { CoreModelCompilerService } from '@clarilog/shared2/services/compiler/model-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import model from './model.json';
import typedJsonmodel from './typed.model.json';

import { Router } from '@angular/router';

/**
 * Liste relatif aux tickets/incidents/demandes archivés.
 *
 */
@Component({
  selector: 'clc-ticket-archived-list',
  templateUrl: './ticket-archived-list.component.html',
  styleUrls: ['./ticket-archived-list.component.scss'],
})
export class CoreTicketArchivedListComponent implements OnInit {
  @ViewChild(CoreTicketListComponent, { static: true })
  manageList: CoreTicketListComponent;

  /** Obtient ou définit le service. */
  @Input() service:
    | TicketCoreService
    | IncidentCoreService
    | RequestCoreService;

  @Input() qualification: string;
  @Input() title: string;
  @Input() source: any;
  @Input() isFavorite: boolean = false;

  layoutKey: string = 'ticket_archived';

  selectedKeys: string[] = [];
  model: ModelState;

  constructor(
    private modelCompilerService: CoreModelCompilerService,
    private router: Router,
    private incidentService: IncidentCoreService,
    private ticketService: TicketCoreService,
    private requestService: RequestCoreService,
  ) {
    if (this.qualification == undefined) {
      if (this.router.url.indexOf('/archived') > 0) {
        let ticketType = this.router.url.substring(
          1,
          this.router.url.indexOf('/archived'),
        );

        this.qualification = ticketType
          .substring(0, ticketType.length - 1)
          .toLowerCase();
      }
    }

    this.qualification = this.qualification.toLowerCase();

    if (!this.qualification.includes('ticket')) {
      this.layoutKey = undefined;
    }

    if (this.service == undefined) {
      if (this.qualification.includes('incident')) {
        this.service = incidentService;
      } else if (this.qualification.includes('request')) {
        this.service = requestService;
      } else {
        this.service = ticketService;
      }
    }
  }

  ngOnInit(): void {
    let updatedModel: any = JSON.parse(JSON.stringify(model));

    if (!this.qualification.includes('ticket')) {
      updatedModel = JSON.parse(JSON.stringify(typedJsonmodel));

      updatedModel.grid.layout.filters[0].items[0].list.source =
        updatedModel.grid.layout.filters[0].items[0].list.source.replace(
          'IncidentCoreService',
          `${this.qualification.toPascalCase()}CoreService`,
        );

      updatedModel.grid.layout.delete = updatedModel.grid.layout.delete.replace(
        'IncidentCoreService',
        `${this.qualification.toPascalCase()}CoreService`,
      );
    }

    this.modelCompilerService.compile(updatedModel).subscribe((model) => {
      this.model = model;

      if (
        this.qualification != undefined &&
        !this.qualification.includes('ticket')
      ) {
        this.model.sharedContext.params.set(
          'qualification',
          () => this.qualification,
        );
      }

      this.model.sharedContext.params.set('type', () => 'archived');
    });
  }

  refresh() {
    this.manageList.refresh();
  }
}
