<div *ngIf="message != undefined && message != null && alert == true">
  <cl-validation-error [message]="message"></cl-validation-error>
</div>
<dx-file-manager
  height="80vh"
  activeStateEnabled="false"
  [fileSystemProvider]="fileProvider"
  [allowedFileExtensions]="allowedFileExtensions"
  (onInitialized)="onInitialized($event)"
  (onContentReady)="contentReady($event)"
  [customizeDetailColumns]="customizeDetailColumns"
>
  <dxo-upload [chunkSize]="1000000" [maxFileSize]="maxSize"></dxo-upload>
  <dxo-scrolling useNative="true" mode="standard"> </dxo-scrolling>
  <dxo-permissions
    [upload]="readOnly == undefined || readOnly == false"
    download="true"
    [delete]="
      (canRemove == undefined || canRemove === true) &&
      (readOnly == undefined || readOnly == false)
    "
  >
  </dxo-permissions>

  <dxo-item-view [showParentFolder]="false">
    <dxo-details>
      <dxi-column
        hidingPriority="6"
        dataField="thumbnail"
        width="35px"
      ></dxi-column>
      <dxi-column dataField="name"></dxi-column>
      <dxi-column
        hidingPriority="5"
        dataField="uploaded"
        [caption]="'entities/files/uploaded' | translate"
        dataType="date"
        width="100px"
      ></dxi-column>
      <dxi-column
        hidingPriority="4"
        dataField="size"
        width="100px"
      ></dxi-column>
      <dxi-column
        hidingPriority="3"
        *ngIf="canCommentary"
        dataField="metaData.commentary"
        [caption]="'entities/files/commentary' | translate"
        width="250px"
      ></dxi-column>
      <dxi-column
        hidingPriority="2"
        *ngIf="canVisibleHelpDesk"
        dataField="metaData.visibleByHelpDeskUser"
        [caption]="'entities/files/visibleHelpDesk' | translate"
        width="150px"
      ></dxi-column>
      <dxi-column
        hidingPriority="1"
        *ngIf="canVisibleHelpDesk"
        dataField="metaData.outgoingEmails"
        [caption]="'entities/files/outgoingEmails' | translate"
        width="150px"
      ></dxi-column>
    </dxo-details>
  </dxo-item-view>

  <dxo-toolbar>
    <dxi-item
      name="upload"
      [disabled]="disabledEmailfunction"
      *ngIf="readOnly === undefined || readOnly === false"
    ></dxi-item>
    <dxi-item
      name="delete"
      *ngIf="
        (canRemove === undefined || canRemove === true) &&
        (readOnly === undefined || readOnly === false)
      "
    ></dxi-item>
    <dxi-item name="refresh"></dxi-item>

    <dxi-file-selection-item name="rename"></dxi-file-selection-item>
    <dxi-file-selection-item name="separator"></dxi-file-selection-item>
    <dxi-file-selection-item name="delete"></dxi-file-selection-item>
    <dxi-file-selection-item name="separator"></dxi-file-selection-item>
    <dxi-file-selection-item
      *ngIf="
        canCommentary === true &&
        isViewHelpMe === false &&
        (readOnly === undefined || readOnly === false)
      "
      widget="dxMenu"
      location="before"
      [options]="commentaryBtnOptions"
    >
    </dxi-file-selection-item>
    <dxi-file-selection-item
      *ngIf="
        canVisibleHelpDesk === true &&
        isViewHelpMe === false &&
        (readOnly === undefined || readOnly === false)
      "
      widget="dxMenu"
      location="before"
      [options]="visibleHelpDeskUserBtnOptions"
    >
    </dxi-file-selection-item>
    <dxi-file-selection-item
      *ngIf="
        canVisibleHelpDesk === true &&
        isViewHelpMe === false &&
        (readOnly === undefined || readOnly === false)
      "
      widget="dxMenu"
      location="before"
      [options]="outgoingEmailsBtnOptions"
    >
    </dxi-file-selection-item>
    <dxi-file-selection-item
      *ngIf="
        isViewHelpMe === false && (readOnly === undefined || readOnly === false)
      "
      widget="dxMenu"
      location="before"
      [options]="downloadBtnOptions"
    >
    </dxi-file-selection-item>
    <dxi-file-selection-item name="refresh"></dxi-file-selection-item>
    <dxi-file-selection-item name="clearSelection"></dxi-file-selection-item>
  </dxo-toolbar>
  <dxo-context-menu>
    <dxi-item
      *ngIf="readOnly === undefined || readOnly === false"
      name="upload"
    ></dxi-item>
    <dxi-item name="refresh"></dxi-item>
    <dxi-item name="download"></dxi-item>
  </dxo-context-menu>

  <div *dxTemplate="let f of 'fileOpenCellTemplate'">
    <a
      href="javascript:void(0)"
      color="#000"
      (click)="onSelectedFileOpened(f)"
      >{{ f.text }}</a
    >
  </div>
  <div *dxTemplate="let f of 'sizeTemplate'">
    {{ convertKBToKO(f?.text) }}
  </div>
  <div *dxTemplate="let f of 'fileCheckTemplate'">
    <i
      *ngIf="f?.value != false"
      style="color: var(--primary)"
      class="far fa-check"
    ></i>
  </div>
</dx-file-manager>

<clc-file-manager-viewer
  [fileViewerInfo]="fileViewerInfo"
  [closable]="true"
></clc-file-manager-viewer>

<clc-dynamic-popup
  [height]="250"
  [width]="700"
  [title]="'entities/files/commentary' | translate"
  [titleBtn]="'save' | translate"
  [(isVisible)]="isCommentaryVisible"
  [inputModel]="commentaryModel"
  [service]="fileManagerService"
  (onFormLoaded)="onFormLoaded($event)"
  (onSaved)="savedCommentary($event)"
>
</clc-dynamic-popup>
