import {
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService, FileModelType } from '@clarilog/core';
import { GqlField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { FileManagerCoreService } from '@clarilog/core/services2/graphql/generated-types/services/file-manager.service';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate';
import { DxFileUploaderComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { FileModel } from '@clarilog/core/services2/graphql/generated-types/types';

/** Représente la classe du composent cl-file-single-manager. */
@Component({
  selector: 'clc-file-single-manager',
  templateUrl: './file-single-manager.component.html',
  styleUrls: ['./file-single-manager.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFileSingleManagerComponent),
      multi: true,
    },
  ],
})
export class CoreFileSingleManagerComponent
  implements ControlValueAccessor, OnInit
{
  @Input() modelState: ModelState;
  @Input() addItems: ModelFnContext;
  @Input() removeItems: ModelFnContext;
  @Input() readOnly: boolean = false;
  @Input() allowedFileExtensions: string[] = [];
  @ViewChild('fileUploader') uploader: DxFileUploaderComponent;
  /** Représente la valeur. */
  _value: string;
  _filesItem: FileModelType[] = [];
  _tempFileId: any;

  urlImage: string;

  /** Définit la résolution max pour une image basé sur blob*/
  maxChunkSize: number = 2000000;

  /** Obtient ou définit la source de données. */
  @Input() source: ModelDataSourceContext;
  // /** Obtient ou définit les éléments ajoutés. */
  @Input() itemsAdded: any;
  savedSubscriber: Subscription;
  _filesItem_Added: FileModelType[] = [];
  _filesItem_Removed: FileModelType[] = [];
  isNewValue: boolean = false;

  /** Obtient les informaiton du fichier */
  fileModel: FileModel;
  /** Obtient ou définit l'état  */
  disabled = false;

  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  constructor(
    public fileManagerService: FileManagerCoreService,
    private _envService: EnvironmentService,
    private injector: Injector,
    private authorizationService: AuthorizationCoreService,
  ) {
    this.uploadFileChunk = this.uploadFileChunk.bind(this);
  }

  async ngOnInit() {
    this.savedSubscriber = this.modelState.formComponent.onSaved.subscribe(
      (res) => {
        if (this.isNewValue) {
          this.addItems.fnCall().subscribe((value) => {});
        }
        this.isNewValue = false;
      },
    );
  }

  writeValue(value: any): void {
    if (value != undefined) {
      this.value = value;
      this.urlImage = `${this._envService.apiURL}/file/get/${value}`;
      this.loadDateFileInfo();
    }
  }
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Charge les données */
  loadDateFileInfo() {
    this.fileModel = undefined;
    if (this.value != undefined) {
      this.fileManagerService
        .fileInfo(FileManagerCoreService.FileModelFields(), this.value, {})
        .subscribe((fileInfo) => {
          //var fileInfo = file.data[0];
          this.fileModel = fileInfo?.data;
        });
    }
  }

  /** Permet de modifier les items de add. */
  private setItemsAdded(items: any) {
    if (items != null) {
      this.urlImage = `${this._envService.apiURL}/file/get/${items}`;
    }
    this._value = items;
    this.onChange(this._value);
    this.onTouched();
    this.isNewValue = true;
    this.loadDateFileInfo();
  }

  /** Permet de lire le fichier pour l'envoyer. */
  async readFileAsync(chunksInfo): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (file) => {
        let result = btoa(reader.result.toString());
        resolve(result);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(chunksInfo.chunkBlob);
    });
  }

  async uploadFileChunk(fileData, chunksInfo, destinationDir) {
    let organisationId = this.authorizationService.user.getTenantId();

    if (this.maxChunkSize >= fileData.size) {
      if (chunksInfo.bytesUploaded == 0) fileData['_tempFileId'] = null;
      let chunks = await this.readFileAsync(chunksInfo);
      let reader = new FileReader();
      reader.onload = async (file) => {
        let result = btoa(reader.result.toString());
        //resolve(result);
        let lastChunk = chunksInfo.chunkIndex == chunksInfo.chunkCount - 1;
        let fields = [GqlField.create('data')];

        // TODO check
        fileData['_tempFileId'] = await this.injector
          .get(this.source.serviceName)
          ['uploadChunkFile'](
            fields,
            true,
            fileData.size,
            true,
            lastChunk,
            chunksInfo.chunkIndex,
            fileData.type,
            fileData.name,
            fileData['_tempFileId']?.data,
            organisationId,
            result,
          )
          .toPromise()
          .then((value: any) => {
            if (chunksInfo.chunkCount == chunksInfo.chunkIndex + 1) {
              (<any>fileData).fileId = value?.data;
              this._filesItem_Added.push(fileData);
              this.setItemsAdded(value?.data);
            }
          });
      };
      reader.readAsBinaryString(chunksInfo.chunkBlob);
    } else {
      notify(
        TranslateService.get('entities/fileUploader/errorMessageChunk'),
        'error',
        10000,
      );
      this.uploader.instance.reset();
    }
  }

  /**
   * Permet de demander la validation avant de supprimer le logo
   */
  async click($event) {
    confirm(
      TranslateService.get('entities/fileUploader/deleteElementConfirm'),
      TranslateService.get('confirm-title'),
    ).then((result) => {
      if (result) {
        this.removeItems.fnCall().subscribe((value) => {
          if (value) {
            this.urlImage = null;
            this.fileModel = undefined;
            this.value = undefined;
          }
        });
      }
    });
  }

  /** download du fichier */
  download() {
    window.open(this.urlImage, '_blank');
  }
}
