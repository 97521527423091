import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Pooling } from '@clarilog/core';
import { CoreGraphQLSource, CriteriaHelpers } from '@clarilog/core/services2';

import DataSource from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { TranslateService } from '../translate/translate.service';
import { CompilerHelpers } from './compiler.helpers';
import {
  AbstractModelContext,
  ModelDataSourceContext,
  ModelDataSourceContextCtorArgs,
  ModelFnContext,
  ModelState,
  ModelEventContext,
} from './model-state';
import CustomStore from 'devextreme/data/custom_store';
import { TranslatedFieldHelperService } from '@clarilog/shared2';
import { OrganizationStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/organization-storage-service';

export type CallbackFunction<T> = () => Observable<T>;
/** Représente la classe permettant de fournir un context d'execution. */
@Injectable({
  providedIn: 'root',
})
export class ModelCompilerContextService {
  constructor(
    private _injector: Injector,
    private _pooling: Pooling,
    private OrganizationStorageService: OrganizationStorageService,
  ) {}
  public regexp(value: string): Observable<RegExp> {
    return of(new RegExp(value));
  }
  public confirm(
    message: string,
    fn: CallbackFunction<boolean>,
  ): Observable<() => Observable<boolean>> {
    return of(() => {
      let result = confirm(message);
      if (result === true) {
        return fn();
      }
      return of(false);
    });
  }
  public date(value: string): Observable<Date> {
    if (value == undefined) {
      return of(new Date());
    } else {
      of(new Date(value));
    }
  }

  private checkMethodExit(name, service, serviceName) {
    let fn = service[name];

    if (fn === undefined) {
      // console.log(
      //   `[call] function not found for service : ${serviceName} and function : ${name}`,
      // );
      throw new Error(
        `[call] function not found for service : ${serviceName} and function : ${name}`,
      );
    } else {
      //console.log(`[call] function found for service : ${serviceName} and function : ${name}`)
    }
  }

  public call(name: string, method: string): Observable<ModelFnContext> {
    return this.coreCall(name, method);
  }

  public callService(
    service: any,
    method: string,
    state: ModelState = null,
  ): ModelFnContext {
    let name = service.modelName;

    if (service === undefined) {
      throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    }
    let fnContext = new ModelFnContext(service.modelName, method);

    if (state != undefined) {
      fnContext.rootState = state;
    }
    // TODO remove is for testing
    this.checkMethodExit(method, service, name);
    // END
    fnContext.fnCall = () => {
      let fn = service[method];

      if (fn === undefined) {
        throw new Error(
          `[call] function not found for service : ${name} and function : ${method}`,
        );
      }
      let context = this.coreGetExecuteContext(fn, fnContext);
      let observable = fn.apply(service, [...context]) as Observable<any>;
      return observable;
    };
    return fnContext;
  }

  /// used to pass the component as method provider
  public customCall(method: string): Observable<ModelFnContext> {
    // TODO check

    let fnContext = new ModelFnContext('methodProvider', method);
    // TODO remove is for testing
    // END
    fnContext.fnCall = () => {
      this.checkMethodExit(
        method,
        fnContext.rootState.component,
        'methodProvider',
      );
      let fn = fnContext.rootState.component[method];

      if (fn === undefined) {
        throw new Error(
          `[call] function not found for service : ${'methodProvider'} and function : ${method}`,
        );
      }
      let context = this.coreGetExecuteContext(fn, fnContext);
      let observable = fn.apply(fnContext.rootState.component, [
        ...context,
      ]) as Observable<any>;
      return observable;
    };
    return of(fnContext);
  }

  public coreCall(name: string, method: string): Observable<ModelFnContext> {
    // TODO check
    let service = this._injector.get(name);

    if (service === undefined) {
      throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    }
    let fnContext = new ModelFnContext(name, method);
    // TODO remove is for testing
    this.checkMethodExit(method, service, name);
    // END
    fnContext.fnCall = () => {
      let fn = service[method];

      if (fn === undefined) {
        throw new Error(
          `[call] function not found for service : ${name} and function : ${method}`,
        );
      }
      let context = this.coreGetExecuteContext(fn, fnContext);
      let observable = fn.apply(service, [...context]) as Observable<any>;
      return observable;
    };
    return of(fnContext);
  }

  public source(
    name: string,
    method: string,
  ): Observable<ModelDataSourceContext> {
    return this.coreSource(name, method);
  }

  public coreSource(
    name: string,
    method: string,
  ): Observable<ModelDataSourceContext> {
    let fncontextCtorArgs: ModelDataSourceContextCtorArgs = {
      serviceName: name,
      methodName: method,
    };

    let fnContext = new ModelDataSourceContext(fncontextCtorArgs);

    // TODO remove is for testing
    // TODO check
    let service = this._injector.get(name);
    this.checkMethodExit(method, service, name);
    // END

    fnContext.datasource = CoreGraphQLSource.create(
      {
        context: fnContext,
        query: (filter, options) => {
          // TODO check
          let service = this._injector.get(name);

          if (service == undefined) {
            throw new Error(`Le service "${name}" n'a pas été trouvé.`);
          }
          if (service[method] == undefined) {
            throw new Error(
              `La méthode "${method}" n'a pas été trouvé sur le service "${name}".`,
            );
          }

          let fn = service[method];
          let context = this.coreGetExecuteContext(fn, fnContext);

          let opts = [...context];
          return service[method].apply(service, opts);
        },
        poolingOptions: {
          query: (id) => {
            // TODO check
            let service = this._injector.get(name);

            if (service == undefined) {
              throw new Error(`Le service "${name}" n'a pas été trouvé.`);
            }

            if (service[method] == undefined) {
              throw new Error(
                `La méthode "${method}" n'a pas été trouvé sur le service "${name}".`,
              );
            }
            // id
            let filter = CriteriaHelpers.compile(['id', '=', id]);
            let fn = service[method];
            let context = this.coreGetExecuteContext(fn, fnContext);
            let opts = [...context, filter];
            return service[method].apply(service, opts);
          },
        },
      },
      this._pooling,
    );

    return of(fnContext);
  }

  /** Créer les événements */
  public event(
    name: string,
    method: string,
    fnParameter: () => [] = undefined,
  ): Observable<ModelEventContext> {
    // TODO check
    let service = this._injector.get(name);

    if (service === undefined) {
      throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    }
    let fnContext = new ModelEventContext(name, method);
    // TODO remove is for testing
    this.checkMethodExit(method, service, name);
    // END
    fnContext.eventEmitter = () => {
      let fn = service[method];

      if (fn === undefined) {
        throw new Error(
          `[event] function not found for service : ${name} and function : ${method}`,
        );
      }
      let context = this.coreGetExecuteContext(fn, fnContext);
      let observable = fn.apply(service, context) as EventEmitter<void>;
      return observable;
    };
    return of(fnContext);
  }

  private coreGetExecuteContext(
    fn: () => any,
    context: AbstractModelContext,
  ): any[] {
    let params = CompilerHelpers.getParams(fn);
    let argsValues = context.getArgs(params);
    return argsValues;
  }

  // private getExecuteContext(
  // 	fn: () => any,
  // 	fnParameter: () => [] = undefined,
  // 	contexts: ModelState = null
  // ): any[] {
  // 	let context = undefined;
  // 	if (fnParameter != undefined) {
  // 		context = fnParameter();
  // 	} else {
  // 		let params = CompilerHelpers.getParams(fn);
  // 		context = this.parameters(...params);
  // 	}
  // 	return context;
  // }

  /** Obtient la traduction de la clé. */
  public resource(key: string): Observable<string> {
    let translate = TranslateService.get(`custom/${key}`);

    if (translate.startsWith('[custom/')) {
      translate = TranslateService.get(`${key}`);
    }

    return of(translate);
  }

  /** Obtient la traduction de la clé. */
  public DynamicFieldResource(key: string): Observable<string> {
    let allDynamicFields =
      this.OrganizationStorageService?.getDynamicPropertyFields();
    let translateFieldService = this._injector.get(
      'TranslatedFieldHelperService',
    ) as TranslatedFieldHelperService;

    let dynField = allDynamicFields.find((el) => el.propertyName === key);

    return of(translateFieldService?.findValueToShow(dynField.name));
  }

  public customResource(rootObj: any, key: string): Observable<string> {
    let customResource = rootObj?.customResources;
    let translateFieldService = this._injector.get(
      'TranslatedFieldHelperService',
    ) as TranslatedFieldHelperService;

    if (customResource != undefined) {
      let field = customResource[key];
      if (field != undefined) {
        let value = translateFieldService?.findValueToShow(field);

        if (value != undefined) {
          return of(value);
        }
      }
    }

    let translate = TranslateService.get(`custom/${key}`);

    if (translate.startsWith('[custom/')) {
      translate = TranslateService.get(`${key}`);
    }

    return of(translate);
  }

  public staticArraySource(
    name: string,
    method: string,
    fnParameter: () => [],
  ): Observable<ModelDataSourceContext> {
    // TODO check
    let service = this._injector.get(name);

    if (service === undefined) {
      throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    }
    let fnContext = new ModelDataSourceContext({
      methodName: method,
      serviceName: name,
    });
    // TODO remove is for testing
    this.checkMethodExit(method, service, name);

    fnContext.datasource = new DataSource({
      load: (loadOptions) => {
        let fn = service[method];

        if (fn === undefined) {
          throw new Error(
            `[call] function not found for service : ${name} and function : ${method}`,
          );
        }
        let context = this.coreGetExecuteContext(fn, fnContext);
        let prom = fn.apply(service, [...context]) as Observable<any>;

        if (prom.toPromise != undefined) {
          return prom.toPromise();
        }
        return prom as any;
      },
      byKey: (key) => {
        let fn = service['get'];
        fnContext.context.params.set('id', () => key);

        if (fn === undefined) {
          throw new Error(
            `[call] function not found for service : ${name} and function : ${method}`,
          );
        }
        let context = this.coreGetExecuteContext(fn, fnContext);
        let prom = fn.apply(service, [...context]) as Observable<any>;
        return prom.toPromise();
      },
    });
    // END

    return of(fnContext);
  }

  public customStaticArraySource(
    method: string,
    fnParameter: () => [],
  ): Observable<ModelDataSourceContext> {
    let fnContext = new ModelDataSourceContext({
      methodName: method,
      serviceName: 'MethodProvider',
    });
    // TODO remove is for testing

    fnContext.datasource = new DataSource({
      load: async (loadOptions) => {
        let service = fnContext.rootState.component;
        this.checkMethodExit(method, service, 'MethodProvider');
        let fn = service[method];

        if (fn === undefined) {
          throw new Error(
            `[call] function not found for service : ${'MethodProvider'} and function : ${method}`,
          );
        }
        fnContext.context.params.set('loadOptions', () => loadOptions);
        let context = this.coreGetExecuteContext(fn, fnContext);
        let prom = fn.apply(service, [...context]) as Observable<any>;

        if (prom.toPromise != undefined) {
          let result = await prom.toPromise();

          if (loadOptions.sort != undefined && (loadOptions.sort as any[])) {
            let sortBy = loadOptions.sort as any[];
            if (sortBy.length > 0) {
              result.sort((a, b) =>
                a[sortBy[0]['selector']].toLocaleLowerCase() >
                b[sortBy[0]['selector']].toLocaleLowerCase()
                  ? 1
                  : -1,
              );
              if (sortBy[0]['desc'] === true) {
                result = result.reverse();
              }
            }
          }
          //pagination / filter
          if (
            loadOptions.filter != undefined &&
            loadOptions.filter.length > 0 &&
            loadOptions.filter[0].length >= 2
          ) {
            let containsValue = loadOptions.filter[0][2];
            let containsField = loadOptions.filter[0][0];
            if (containsValue != undefined) {
              // on considere ici une recherche par contains
              result = result.filter(
                (s) =>
                  s[containsField]
                    .toLocaleLowerCase()
                    .indexOf(containsValue.toLocaleLowerCase()) >= 0,
              );
            }
          }
          return result;
        }
        return prom as any;
      },
      byKey: (key) => {
        let service = fnContext.rootState.component;

        let fn = service['get'];
        fnContext.context.params.set('id', () => key);

        if (fn === undefined) {
          throw new Error(
            `[call] function not found for service : ${'MethodProvider'} and function : ${method}`,
          );
        }
        let context = this.coreGetExecuteContext(fn, fnContext);
        let prom = fn.apply(service, [...context]) as Observable<any>;
        return prom.toPromise();
      },
    });
    // END
    //set par defaut une grande plage
    fnContext.datasource.pageSize(500);
    return of(fnContext);
  }
  // public customStaticArray(
  //   method: string,
  // ): any{
  //   console.log(this)
  //   let fnContext = new ModelDataSourceContext({
  //     methodName: method,
  //     serviceName: "MethodProvider",
  //   });
  //   let service = fnContext.rootState.component
  //
  //
  //   if (service[method] === undefined) {
  //     throw new Error(
  //       `La méthode "${method}" du service MethodProvider n'a pas été trouvé.`,
  //     );
  //   }
  //
  //   // TODO remove is for testing
  //
  //   this.checkMethodExit(method, service, "MethodProvider");
  //
  //   let fn = service[method];
  //
  //   if (fn === undefined) {
  //     throw new Error(
  //       `[staticArray] function not found for service : MethodProvider and function : ${method}`,
  //     );
  //   }
  //
  //   let context = this.coreGetExecuteContext(fn, fnContext);
  //
  //   return fn.apply(service, [...context]) as Array<any>;
  // }
  public customStaticArrayAsync(
    method: string,
    fnParameter: () => [],
  ): Observable<ModelFnContext> {
    let fnContext = new ModelFnContext('MethodProvider', method);
    // TODO remove is for testing
    // END
    fnContext.fnCall = () => {
      let service = fnContext.rootState.component;

      this.checkMethodExit(method, service, 'MethodProvider');

      if (service === undefined) {
        throw new Error(`Le service MethodProvider n'a pas été trouvé.`);
      }

      let fn = service[method];

      if (fn === undefined) {
        throw new Error(
          `[call] function not found for service : MethodProvider and function : ${method}`,
        );
      }
      let context = this.coreGetExecuteContext(fn, fnContext);
      let observable = fn.apply(service, [...context]) as Observable<any>;
      return observable;
    };
    return of(fnContext);
  }

  public staticArrayAsync(
    name: string,
    method: string,
    fnParameter: () => [],
  ): Observable<ModelFnContext> {
    // throw new Error('staticArrayAsync not implem');

    // return of(() => {
    // TODO check
    // 	let service = this._injector.get(name);
    // 	if (service == undefined) {
    // 		throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    // 	}
    // 	if (service[method] == undefined) {
    // 		throw new Error(
    // 			`La méthode "${method}" du service "${name}" n'a pas été trouvé.`,
    // 		);
    // 	}
    // 	let fn = service[method];
    // 	let context = this.getExecuteContext(fn, fnParameter);
    // 	return service[method].apply(service, context);
    // });

    // TODO check
    let service = this._injector.get(name);

    if (service === undefined) {
      throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    }
    let fnContext = new ModelFnContext(name, method);
    // TODO remove is for testing
    this.checkMethodExit(method, service, name);
    // END
    fnContext.fnCall = () => {
      let fn = service[method];

      if (fn === undefined) {
        throw new Error(
          `[call] function not found for service : ${name} and function : ${method}`,
        );
      }
      let context = this.coreGetExecuteContext(fn, fnContext);
      let observable = fn.apply(service, [...context]) as Observable<any>;
      return observable;
    };
    return of(fnContext);
  }
  public observable(
    name: string,
    method: string,
    fnParameter: () => [],
  ): Observable<ModelFnContext> {
    // // throw new Error('observable not implem');
    // return of(() => {
    // TODO check
    // 	let service = this._injector.get(name);

    // 	if (service == undefined) {
    // 		throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    // 	}
    // 	if (service[method] == undefined) {
    // 		throw new Error(
    // 			`La méthode "${method}" n'a pas été trouvé sur le service "${name}".`,
    // 		);
    // 	}
    // 	let fn = service[method];
    // 	let context = this.getExecuteContext(fn, fnParameter);
    // 	let opts = [...context];
    // 	return service[method].apply(service, opts);
    // });

    // TODO check
    let service = this._injector.get(name);

    if (service === undefined) {
      throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    }
    let fnContext = new ModelFnContext(name, method);
    // TODO remove is for testing
    this.checkMethodExit(method, service, name);
    // END
    fnContext.fnCall = () => {
      let fn = service[method];

      if (fn === undefined) {
        throw new Error(
          `[call] function not found for service : ${name} and function : ${method}`,
        );
      }
      let context = this.coreGetExecuteContext(fn, fnContext);
      let observable = fn.apply(service, [...context]) as Observable<any>;
      return observable;
    };
    return of(fnContext);
  }

  public staticArray(
    name: string,
    method: string,
  ): Observable<DataSource | any[]> {
    // TODO check
    let service = this._injector.get(name);
    let fncontextCtorArgs: ModelDataSourceContextCtorArgs = {
      serviceName: name,
      methodName: method,
    };

    if (service === undefined) {
      throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    }
    if (service[method] === undefined) {
      throw new Error(
        `La méthode "${method}" du service "${name}" n'a pas été trouvé.`,
      );
    }

    this.checkMethodExit(method, service, name);

    let fn = service[method];

    if (fn === undefined) {
      throw new Error(
        `[staticArray] function not found for service : ${name} and function : ${method}`,
      );
    }
    return of(service[method]());
  }

  /** Datasource de type lookup */
  public lookupArray(name: string, method: string, state: ModelState = null) {
    let fncontextCtorArgs: ModelDataSourceContextCtorArgs = {
      serviceName: name,
      methodName: method,
    };

    let fnContext = new ModelDataSourceContext(fncontextCtorArgs);

    let service = this._injector.get(name);

    if (service == undefined) {
      throw new Error(`Le service "${name}" n'a pas été trouvé.`);
    }
    if (service[method] == undefined) {
      throw new Error(
        `La méthode "${method}" n'a pas été trouvé sur le service "${name}".`,
      );
    }

    if (state != undefined) {
      fnContext.rootState = state;
    }

    let cs = new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let fn = service[method];

        if (loadOptions.searchExpr != undefined) {
          let filter = [
            [
              [
                loadOptions.searchExpr,
                loadOptions.searchOperation,
                loadOptions.searchValue,
              ],
            ],
          ];
          filter = CriteriaHelpers.convertDxFilter(filter);
          fnContext.context.params.set('filter', () => filter);
        }

        let context = this.coreGetExecuteContext(fn, fnContext);

        let opts = [...context];
        let result = await service[method].apply(service, opts).toPromise();
        return result.data;
      },
      byKey: async (key) => {
        let fn = service[method];
        let context = this.coreGetExecuteContext(fn, fnContext);

        let filter = {};
        if (key != undefined) {
          filter = {
            id: { eq: key },
          };
        }

        let opts = [...context];
        let result = await service[method].apply(service, opts).toPromise();
        if (result.data != undefined && result.data.length > 1) {
          return result.data[0];
        }
        return undefined;
      },
    });

    fnContext['lookup'] = true;
    fnContext.datasource = new DataSource({
      store: cs,
    });
    return of(fnContext);
  }

  public staticArrayForLookup(name: string, method: string) {
    // TODO check
    let service = this._injector.get(name);
    return service[method]();
  }
}
