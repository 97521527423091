import { Authorize } from '@clarilog/core/services/graphql';
import { WorkflowInterventionBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfInterventionModel,
  QueryContextOfInterventionModel,
  ServiceListResultOfInterventionModel,
} from '../types';
import { Observable, of } from 'rxjs';
import { TicketCoreService } from './ticket.service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-workflow')
export class WorkflowInterventionCoreService extends WorkflowInterventionBaseService {
  constructor(
    injector: Injector,
    private ticketCoreService: TicketCoreService,
  ) {
    super(injector);
  }

  @InjectArgs
  public findInterventionModel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfInterventionModel,
    @Args('filter?') filter?: FilterOfInterventionModel,
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('type') type?: string,
  ): Observable<ServiceListResultOfInterventionModel> {
    let qualificationList = ['incident', 'problem', 'request'];
    if (!qualificationList.includes(type.toLowerCase())) {
      return of({ totalCount: 0, data: [] });
    }
    return this.ticketCoreService.findInterventionModel(
      fields,
      type,
      options,
      filter,
      extendedVariables,
    );
  }
}
