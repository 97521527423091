import { FormDesignerBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Args, Authorize, InjectArgs } from '@clarilog/core';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  FilterOfFormDesigner,
  FormDesignerEditing,
  FormDesignerTo,
  QueryContextOfFormDesigner,
  ServiceListResultOfFormDesigner,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { Observable } from 'rxjs';
import { TranslateService } from '@clarilog/shared2';
import { resultSearch } from '@clarilog/layouts2/content-layout/global-search.service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.help-me')
export class FormDesignerCoreService extends FormDesignerBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findOperatorEditFormDesigner(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfFormDesigner,
    @Args('filter?') filter?: FilterOfFormDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('qualification?') qualification?: string | string[],
  ): Observable<ServiceListResultOfFormDesigner> {
    return this.findFormDesigner(
      FormDesignerEditing.Edition,
      fields,
      options,
      filter,
      extendedVariables,
      qualification,
      FormDesignerTo.Operator,
    );
  }

  @InjectArgs
  public findOperatorNewFormDesigner(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfFormDesigner,
    @Args('filter?') filter?: FilterOfFormDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('qualification?') qualification?: string | string[],
  ): Observable<ServiceListResultOfFormDesigner> {
    return this.findFormDesigner(
      FormDesignerEditing.Creation,
      fields,
      options,
      filter,
      extendedVariables,
      qualification,
      FormDesignerTo.Operator,
    );
  }

  @InjectArgs
  public findEditFormDesigner(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfFormDesigner,
    @Args('filter?') filter?: FilterOfFormDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('qualification?') qualification?: string | string[],
  ): Observable<ServiceListResultOfFormDesigner> {
    return this.findFormDesigner(
      FormDesignerEditing.Edition,
      fields,
      options,
      filter,
      extendedVariables,
      qualification,
      FormDesignerTo.HelpMe,
    );
  }

  @InjectArgs
  public findNewFormDesigner(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfFormDesigner,
    @Args('filter?') filter?: FilterOfFormDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('qualification?') qualification?: string | string[],
  ): Observable<ServiceListResultOfFormDesigner> {
    return this.findFormDesigner(
      FormDesignerEditing.Creation,
      fields,
      options,
      filter,
      extendedVariables,
      qualification,
      FormDesignerTo.HelpMe,
    );
  }

  @InjectArgs
  public findFormDesigner(
    @Args('editing') editing: FormDesignerEditing,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfFormDesigner,
    @Args('filter?') filter?: FilterOfFormDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('qualification?') qualification?: string | string[],
    @Args('formDesignerTo?') formDesignerTo?: FormDesignerTo,
  ): Observable<ServiceListResultOfFormDesigner> {
    let myQualification: string = '';
    if (
      qualification != undefined &&
      Array.isArray(qualification) &&
      qualification.length > 0
    ) {
      // si array on ne prend que la 1ere
      myQualification = qualification[0];
    } else {
      myQualification = qualification as string;
    }
    let filterExpr: FilterOfFormDesigner = {
      and: [
        {
          formDesignerTo: { eq: formDesignerTo },
        },

        {
          or: [
            { editing: { eq: editing } },
            { editing: { eq: FormDesignerEditing.Both } },
          ],
        },
      ],
    };

    if (qualification != undefined) {
      let filterQualificationExpr: FilterOfFormDesigner = {
        qualification: { eq: myQualification },
      };
      filterExpr = { and: [filterExpr, filterQualificationExpr] };
    }

    if (filter != undefined) {
      filterExpr = { and: [filterExpr, filter] };
    }

    return this.find(fields, options, filterExpr, extendedVariables);
  }

  @InjectArgs
  public findModelTypes(): any[] {
    return [
      {
        id: 'help-desk-user',
        name: TranslateService.get('entities/formDesigner/user'),
      },
      {
        id: 'help-desk-operator',
        name: TranslateService.get('entities/formDesigner/operator'),
      },
    ];
  }

  /** Obtient les info d'affichage de designer */
  hasOptionDesigner(control, required): OptionDesigner {
    let result = {
      filter: this.hasFilter(control),
      required: this.hasRequired(control, required),
      designerReadOnly: control?.designerReadOnly === true,
    };

    // Controle
    if (result?.designerReadOnly === true) {
      result.filter = false;
      result.required = false;
    }
    return result;
  }

  /** Obtient les info de filtre par status */
  hasFilter(control) {
    let requiredMode =
      control?.dependsOn?.controlName === 'statusId' ||
      control?.dependsOns?.find(
        (s) => s.effect == 'RequireIfValueIn' || s.effect == 'EditIfValueIn',
      ) != undefined;

    return requiredMode;
  }

  /** Obtient les info d'obligation */
  hasRequired(control, required) {
    if (control?.designerReadOnly === true) {
      return false;
    }
    if (required) {
      return true;
    }
    let requiredMode =
      control?.dependsOn?.controlName === 'statusId' ||
      control?.dependsOns?.find((s) => s.effect == 'RequireIfValueIn') !=
        undefined;
    if (requiredMode === true) {
      // cas spécifique du status id
      return true;
    }

    requiredMode = control?.validators?.find(
      (s) => s.name == 'notNullOrEmpty' || s.name == 'required',
    );

    return requiredMode != undefined;
  }

  // public findModelTypes(): any[] {
  //   return [
  //     {
  //       id: 'help-desk-user-incident-new',
  //       name: TranslateService.get(
  //         'entities/formDesigner/modelTypeHelpDeskUserIncidentNew',
  //       ),
  //       edit: false,
  //       qualification: 'incident',
  //       helpMe: true,
  //     },
  //     {
  //       id: 'help-desk-user-incident-edit',
  //       name: TranslateService.get(
  //         'entities/formDesigner/modelTypeHelpDeskUserIncidentEdit',
  //       ),
  //       edit: true,
  //       qualification: 'incident',
  //       helpMe: true,
  //     },
  //     {
  //       id: 'help-desk-operator-incident-new',
  //       name: TranslateService.get(
  //         'entities/formDesigner/modelTypeHelpDeskOperatorIncidentNew',
  //       ),
  //       edit: true,
  //       qualification: 'incident',
  //       helpMe: false,
  //     },
  //     {
  //       id: 'help-desk-operator-request-new',
  //       name: TranslateService.get(
  //         'entities/formDesigner/modelTypeHelpDeskOperatorRequestNew',
  //       ),
  //       edit: true,
  //       qualification: 'request',
  //       helpMe: false,
  //     },
  //   ];
  // }
}

/** Représente les info pour le mode designer de formulaire */
export class OptionDesigner {
  filter;
  required;
  designerReadOnly;
}
