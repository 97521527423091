import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowTicketTasksBaseVariables, FirstWorkflowTicketTasksBaseVariables, CountWorkflowTicketTasksBaseVariables, FindWorkflowTicketTasksBaseVariables, SearchWorkflowTicketTasksBaseVariables, ExportSchemaWorkflowTicketTasksBaseVariables, ExportDataWorkflowTicketTasksBaseVariables, CustomQueryWorkflowTicketTasksBaseVariables, CustomQueryIdWorkflowTicketTasksBaseVariables, UsedWorkflowTicketTasksBaseVariables, ExistWorkflowTicketTasksBaseVariables, InsertWorkflowTicketTasksBaseVariables, UpdateWorkflowTicketTasksBaseVariables, ImportDataWorkflowTicketTasksBaseVariables, UpdateManyWorkflowTicketTasksBaseVariables, DeleteWorkflowTicketTasksBaseVariables } from '../gqls'
import { GetWorkflowTicketTasksDocument, FirstWorkflowTicketTasksDocument, CountWorkflowTicketTasksDocument, FindWorkflowTicketTasksDocument, SearchWorkflowTicketTasksDocument, ExportSchemaWorkflowTicketTasksDocument, ExportDataWorkflowTicketTasksDocument, CustomQueryWorkflowTicketTasksDocument, CustomQueryIdWorkflowTicketTasksDocument, UsedWorkflowTicketTasksDocument, ExistWorkflowTicketTasksDocument, InsertWorkflowTicketTasksDocument, UpdateWorkflowTicketTasksDocument, ImportDataWorkflowTicketTasksDocument, UpdateManyWorkflowTicketTasksDocument, DeleteWorkflowTicketTasksDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowTicketTask, QueryContextOfWorkflowTicketTask, FilterOfWorkflowTicketTask, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowTicketTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowTicketTaskInput, FieldUpdateOperatorOfWorkflowTicketTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowTicketTaskBaseService {
    
public modelName = 'workflowTicketTask';
public modelPluralName = 'workflowTicketTasks';

	private getWorkflowTicketTasksQuery: GetWorkflowTicketTasksDocument;
	private firstWorkflowTicketTasksQuery: FirstWorkflowTicketTasksDocument;
	private countWorkflowTicketTasksQuery: CountWorkflowTicketTasksDocument;
	private findWorkflowTicketTasksQuery: FindWorkflowTicketTasksDocument;
	private searchWorkflowTicketTasksQuery: SearchWorkflowTicketTasksDocument;
	private exportSchemaWorkflowTicketTasksQuery: ExportSchemaWorkflowTicketTasksDocument;
	private exportDataWorkflowTicketTasksQuery: ExportDataWorkflowTicketTasksDocument;
	private customQueryWorkflowTicketTasksQuery: CustomQueryWorkflowTicketTasksDocument;
	private customQueryIdWorkflowTicketTasksQuery: CustomQueryIdWorkflowTicketTasksDocument;
	private usedWorkflowTicketTasksQuery: UsedWorkflowTicketTasksDocument;
	private existWorkflowTicketTasksQuery: ExistWorkflowTicketTasksDocument;
	private insertWorkflowTicketTasksMutation: InsertWorkflowTicketTasksDocument;
	private updateWorkflowTicketTasksMutation: UpdateWorkflowTicketTasksDocument;
	private importDataWorkflowTicketTasksMutation: ImportDataWorkflowTicketTasksDocument;
	private updateManyWorkflowTicketTasksMutation: UpdateManyWorkflowTicketTasksDocument;
	private deleteWorkflowTicketTasksMutation: DeleteWorkflowTicketTasksDocument;

	constructor(private injector: Injector) {
		this.getWorkflowTicketTasksQuery = this.injector.get(GetWorkflowTicketTasksDocument);
		this.firstWorkflowTicketTasksQuery = this.injector.get(FirstWorkflowTicketTasksDocument);
		this.countWorkflowTicketTasksQuery = this.injector.get(CountWorkflowTicketTasksDocument);
		this.findWorkflowTicketTasksQuery = this.injector.get(FindWorkflowTicketTasksDocument);
		this.searchWorkflowTicketTasksQuery = this.injector.get(SearchWorkflowTicketTasksDocument);
		this.exportSchemaWorkflowTicketTasksQuery = this.injector.get(ExportSchemaWorkflowTicketTasksDocument);
		this.exportDataWorkflowTicketTasksQuery = this.injector.get(ExportDataWorkflowTicketTasksDocument);
		this.customQueryWorkflowTicketTasksQuery = this.injector.get(CustomQueryWorkflowTicketTasksDocument);
		this.customQueryIdWorkflowTicketTasksQuery = this.injector.get(CustomQueryIdWorkflowTicketTasksDocument);
		this.usedWorkflowTicketTasksQuery = this.injector.get(UsedWorkflowTicketTasksDocument);
		this.existWorkflowTicketTasksQuery = this.injector.get(ExistWorkflowTicketTasksDocument);
		this.insertWorkflowTicketTasksMutation = this.injector.get(InsertWorkflowTicketTasksDocument);
		this.updateWorkflowTicketTasksMutation = this.injector.get(UpdateWorkflowTicketTasksDocument);
		this.importDataWorkflowTicketTasksMutation = this.injector.get(ImportDataWorkflowTicketTasksDocument);
		this.updateManyWorkflowTicketTasksMutation = this.injector.get(UpdateManyWorkflowTicketTasksDocument);
		this.deleteWorkflowTicketTasksMutation = this.injector.get(DeleteWorkflowTicketTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowTicketTask> {
    
    		let variables: GetWorkflowTicketTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowTicketTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowTicketTask,
		@Args('filter?') filter?: FilterOfWorkflowTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowTicketTask> {
    
    		let variables: FirstWorkflowTicketTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowTicketTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowTicketTask,
		@Args('filter?') filter?: FilterOfWorkflowTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowTicketTask> {
    
    		let variables: FindWorkflowTicketTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowTicketTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowTicketTask> {
    
    		let variables: SearchWorkflowTicketTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowTicketTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowTicketTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowTicketTask,
		@Args('filter?') filter?: FilterOfWorkflowTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowTicketTask> {
    
    		let variables: CustomQueryWorkflowTicketTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowTicketTask,
		@Args('filter?') filter?: FilterOfWorkflowTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowTicketTask> {
    
    		let variables: CustomQueryIdWorkflowTicketTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowTicketTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowTicketTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowTicketTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowTicketTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowTicketTask> {
    
    		let variables: InsertWorkflowTicketTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowTicketTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowTicketTask> {
    
    		let variables: UpdateWorkflowTicketTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowTicketTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowTicketTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowTicketTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowTicketTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowTicketTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowTicketTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowTicketTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowTicketTasks.delete));
    	}
    
}