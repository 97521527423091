import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import {
  DesignerEventAction,
  DesignerEventData,
  DesignerEventKey,
  DesignerEventType,
  FormDesignerPopupComponent,
  TranslateService,
} from '@clarilog/shared2';

/** Représente les groupes dans les sections. */
@Component({
  selector: 'clc-work-item-section-group',
  templateUrl: './work-item-section-group.component.html',
  styleUrls: ['./work-item-section-group.component.scss'],
})
export class CoreWorkItemSectionGroupComponent implements OnChanges {
  /** Obtient ou définit le titre du groupe. */
  @Input() title: string = '';
  /** Obtient ou définit la visibilité du groupe. */
  @Input() visibled: boolean = true;
  /** Obtient ou définit si le groupe est fermé. */
  @Input() expanded: boolean = true;
  /** Host le style display. */
  @Output() onVisibledChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /** Permet de binder le style display. */
  @HostBinding('style.display')
  get display(): string {
    return this.visibled ? 'block' : 'none';
  }

  getAction = DesignerEventAction;

  /** USed for designer**/
  @Input() designerMode = false;
  @Input() designerId: string;
  @Input() state: ModelState;

  // contextMenu(e: MouseEvent) {
  //   if (this.designerMode === true) {
  //     FormDesignerPopupComponent.actionDialog({
  //       title: TranslateService.get('entities/formDesigner/popupTitleSection'),
  //       message:
  //         '<b>' +
  //         TranslateService.get('entities/formDesigner/popupDescrSection') +
  //         ' :</b><br>' +
  //         this.title,
  //       hasIn: true,
  //       hasUp: true,
  //       hasEdit: true,
  //       hasRemove: true,
  //     })
  //       .show()
  //       .then((dialogResult) => {
  //         let action = DesignerEventAction.ADD_UP;
  //         if (dialogResult.buttonText === 'addIn') {
  //           action = DesignerEventAction.ADD_IN;
  //         }
  //         if (dialogResult.buttonText === 'remove') {
  //           action = DesignerEventAction.REMOVE;
  //         }
  //         if (dialogResult.buttonText === 'edit') {
  //           action = DesignerEventAction.EDIT;
  //         }
  //         if (dialogResult.buttonText === 'cancel') {
  //           return false;
  //         }
  //         this.state.on.emit({
  //           eventName: DesignerEventKey,
  //           eventData: new DesignerEventData(
  //             DesignerEventType.GROUP,
  //             action,
  //             this.designerId,
  //           ),
  //         });
  //       });

  //     return false;
  //   }
  //   return true;
  // }

  /** end section **/
  constructor() {}

  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visibled !== undefined) {
      this.onVisibledChanged.emit(this.visibled);
    }
  }

  formAction(e: any, action: DesignerEventAction) {
    e.preventDefault();
    e.stopPropagation();

    this.state.on.emit({
      eventName: DesignerEventKey,
      eventData: new DesignerEventData(
        DesignerEventType.GROUP,
        action,
        this.designerId,
      ),
    });
    e.prevent;
  }
}
