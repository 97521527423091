import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import {
  EntityAttributesType,
  GC,
  GCFactory,
  ServicePermission,
  ServiceResult,
  ServiceSingleResult,
  ValidationErrorType,
} from '@clarilog/core';
import { TranslateService } from '@clarilog/shared2/services';
import notify from 'devextreme/ui/notify';
import { Observable, of } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { CoreWorkItemFormComponent, WorkItemFormMode } from '../work-item-form';
import { WorkItem } from '../work-item-form/work-item';
import { FormGroupHelpers } from './form-group-helpers';
import { WorkFormCoreService } from './work-form.service';
import { alert, custom } from 'devextreme/ui/dialog';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CoreFormLoader } from './form-loader';
import { CoreFormRecorder } from './form-recorder';
import { dxButtonOptions } from 'devextreme/ui/button';
import {
  EntityAttribute,
  ValidationError,
  ValidationErrorCategory,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { ToolbarItemsComponent } from '../../toolbar/toolbar-items/toolbar-items.component';
import { TranslatedFieldHelperService } from '../../translate-field';
import { CorePolicyValidator } from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import { ReadOnlyAttribute } from '@clarilog/shared2/models/read-only-attribute';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

@Component({
  selector: 'clc-work-form',
  templateUrl: './work-form.component.html',
  //changeDetection:ChangeDetectionStrategy.OnPush
})
export class CoreWorkFormComponent implements OnChanges, OnDestroy, OnInit {
  @Input() backUrl: string;
  @Input() showSave: boolean = true;
  /** Obtient ou définit si le bouton de retour est affiché. */
  @Input() showBack: boolean = true;
  /** Obtient l'id de l'entité */
  private _id: string;
  private _form: UntypedFormGroup = new UntypedFormGroup({});
  private _gc: GC;
  savePolicies: any;
  /** Obtient ou définit si le composant doit s'affciher en mode mobile. */
  @Input() enableMobileMode: boolean = false;
  /** Obtient ou définit la traduction du bouton save. */
  @Input() saveTilte: string;
  /** Obtient ou définit si on doit changer la navigation apres le save. */
  @Input() navigateAfterSave: boolean = true;
  /** Obtient ou définit si le message de validation est affiché. */
  @Input() successMessage: string;
  /** Obtient ou définit si le menu deroulant du bouton save est visible. */
  @Input() saveSplitButton: boolean = true;
  @Input() forceSaveBtn: boolean = false;
  @Input() disableSaveNotification = false;

  /** Obtient bouton save seulement. */
  @Input() onlySaveButton: boolean = false;
  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() readOnly: boolean = false;
  @Input() displaySaveIcon: boolean = true;
  userName;

  /** Obtient ou définit si on affiche les info de lecture seule */
  @Input() displayReadOnlyMsg: boolean = true;

  @ViewChild(CoreWorkItemFormComponent)
  workItemForm: CoreWorkItemFormComponent;

  readOnlyAttribute: ReadOnlyAttribute;

  @Input() backMode: boolean = false;
  @Input() isExternalForm: boolean = false;
  /** Obtient ou définit si l'élément peut être édité. */
  canEdit: boolean = true;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private _location: Location,
    private _loader: CoreFormLoader,
    private _recorder: CoreFormRecorder,
    private _workFormService: WorkFormCoreService,
    private servicePermission: ServicePermission,
    public translateFieldHelperService: TranslatedFieldHelperService,
    private policyValidator: CorePolicyValidator,
    private localStorageService: LocalStorageService,
    gcFactory: GCFactory,
  ) {
    this._gc = gcFactory.create();
    this.selectedLanguage = this.translateFieldHelperService.getTranslateKey();
    this.updateLanguageTitle();
    this.translateDataSource =
      this.translateFieldHelperService.getLanguageDatasource();
  }

  ngOnInit(): void {}

  get id(): string {
    return this._id;
  }

  /** Obtient le FormGroup (lecture seule) */
  get form(): UntypedFormGroup {
    return this._form;
  }

  translateChooseFieldTitle: string;
  selectedLanguage: string;
  isTranslatePopupVisible: boolean = false;
  translateDataSource: any[];
  @Input() hasTranslationModule: boolean = false;

  updateLanguageTitle() {
    this.translateChooseFieldTitle =
      TranslateService.get('entities/translatedField/chooseLanguage') +
      ' : ' +
      this.translateFieldHelperService.getLanguageValueByKey(
        this.selectedLanguage,
      );
  }

  onSelectLanguageValueChanged(e) {
    this.selectedLanguage = e.value;
  }

  changeLanguageTranslation() {
    this.model.sharedContext.params.set(
      TranslatedFieldHelperService.ModelStateLanguageKey,
      () => this.selectedLanguage,
    );
    this.model.on.emit({
      eventName: TranslatedFieldHelperService.ModelStateLanguageKey,
      eventData: this.selectedLanguage,
    });
    this.updateLanguageTitle();
    this.isTranslatePopupVisible = false;
  }

  valueChanges: EventEmitter<any> = new EventEmitter();

  formReady: EventEmitter<any> = new EventEmitter();

  mode: WorkItemFormMode = 'Wait';

  _model: ModelState;
  /** Obtient ou définit le model compilé. */

  @Input() set model(value: ModelState) {
    this._model = value;
    if (this._model?.sharedContext != undefined) {
      this._model.sharedContext.params.set(
        TranslatedFieldHelperService.ModelStateLanguageKey,
        () => this.selectedLanguage,
      );
    }
  }

  get model(): ModelState {
    return this._model;
  }

  /** Obtient ou définit le titre du formulaire. S'il n'est pas précisé, alors le titre sera repris dans le model. */
  @Input() title: string;
  /** Se déclenche lors de la validation du formulaire. */
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  /** Emit lorsque le save */
  @Output() onSaved: EventEmitter<any> = new EventEmitter();
  /** Obtient ou définit le service de l'identité. */

  @Output() onLoaded: EventEmitter<any> = new EventEmitter();
  /** Obtient ou définit le service de l'identité. */
  @Input()
  service: {
    get: (
      fields: Array<GqlSubField | GqlField>,
      id: string,
    ) => Observable<ServiceSingleResult<any>>;
    update: (
      fields: Array<GqlSubField | GqlField>,
      id: string,
      entry: any,
    ) => Observable<ServiceResult<any>>;
    insert: (
      fields: Array<GqlSubField | GqlField>,
      entry: any,
    ) => Observable<ServiceResult<any>>;
    readOnly: (
      fields: Array<GqlSubField | GqlField>,
      id: any,
    ) => Observable<ServiceSingleResult<EntityAttribute>>;
    readonlyTemplate: (errors?: ValidationErrorType[]) => ReadOnlyAttribute;
  };
  /** Obtient ou définit ou définit les actions. */
  @ContentChild(ToolbarItemsComponent, { static: true })
  toolbar: ToolbarItemsComponent;
  @Input() errors: ValidationError[];
  /** Permet de créer le menu. */
  items: WorkItem[];
  loaded: boolean = false;
  attributesInfo: EntityAttributesType = undefined;

  ngOnChanges(changes: SimpleChanges) {
    // A chaque changement du model.
    if (changes.model != undefined && changes.model.currentValue != undefined) {
      this.initialize();
    }
  }

  /** @inheritdoc */
  ngOnDestroy(): void {
    if (this.saveSubscription != undefined) {
      this.saveSubscription.unsubscribe();
    }

    this._gc.dispose();
    this._loader.dispose();
  }

  /** Permet de revenir sur la liste. */
  close(e?) {
    if (this.navigateAfterSave == true && this.isExternalForm === false) {
      this.navigateToLastList();
    }
  }

  /** Navigue sur la dernière liste. */
  private navigateToLastList() {
    this.loaded = true;
    if (this.backUrl != undefined) {
      if (this.backUrl.startsWith('/')) {
        this.backUrl = this.backUrl.substring(1);
      }
      let index = this.backUrl.indexOf('?');
      if (index > 0) {
        this.backUrl = this.backUrl.substring(0, index);
      }
      this._router.navigate([`/${this.backUrl}`]);
      return;
    }
    let segments: UrlSegment[] = [];
    let parent: ActivatedRoute = this._route.parent;

    let parentCount = 0;

    while (parent != undefined) {
      segments = segments.concat(parent.snapshot.url);
      if (parent.snapshot.url.length > 0) {
        parentCount++;
      }
      parent = parent.parent;
    }
    let path = segments
      .reverse()
      .map((result) => result.path)
      .join('/');

    this._router
      .navigate([`/${path}`], {
        // Tant que l'on est dans un sous-système, on préserve les paramètres
        queryParams:
          parentCount == 1
            ? undefined
            : this._route.parent.snapshot.queryParams,
        relativeTo: this._route,
      })
      .then(() => {
        this.loaded = false;
      });
  }

  /**Lance le refresh des tabs */

  private refreshTab() {
    let badgeSource = this.model.model.form?.layout
      ?.badgeSource as ModelFnContext;
    if (this._id != undefined && badgeSource != undefined) {
      badgeSource.context.params.set('id', () => this._id);
      badgeSource.context.params.set('fields', () => [
        GqlSubField.create('data', [
          GqlField.create('key'),
          GqlField.create('value'),
          GqlField.create('type'),
          GqlField.create('hasValue'),
        ]),
      ]);
      badgeSource.fnCall().subscribe((b) => {
        this.model.sharedContext.badges = b?.data;
        this.localStorageService.ModelState.on.emit({
          eventName: 'refresh-tabs',
          eventData: undefined,
        });
        this._changeDetectorRef.detectChanges();
      });
    }
  }

  /** Permet d'initialiser le composant. */
  saveSubscription;

  private async initialize() {
    // Récupère l'id dans l'url.
    if (this.isExternalForm === false) {
      this._id = this._route.snapshot.paramMap.get('id');
    } else {
      this._id = this.model.id;
    }
    // Met dans le context d'execution l'id courant.

    this.model.sharedContext.params.set('id', () => this._id);

    // Si le titre n'est pas définit
    // Alors, le titre est celui du model.
    // TODO voir pour l'enlever.
    if (this.title == undefined) {
      this.title = this.model.model.title;
    }

    if (this.model?.isSubForm !== true) {
      document.title = this.title + ' - One by Clarilog';
    }

    // Créé les éléments du menu
    if (this._id != undefined) {
      if (
        this.model.model.form?.layout?.readOnly != undefined &&
        this.model.model.form.layout.readOnly == true
      ) {
        this.readOnly = true;
      } else {
        if (
          this.model.model.form?.layout?.checkReadOnly != undefined &&
          this.model.model.form.layout.checkReadOnly == true
        ) {
          await this.isReadOnly();
        }
        this.getPermission();
        if (this.canEdit == false && !this.readOnly) {
          this.readOnly = true;
        }
      }
      if (this.model.model.form?.layout?.displayReadOnlyMsg != undefined) {
        this.displayReadOnlyMsg =
          this.model.model.form?.layout?.displayReadOnlyMsg;
      }
    }
    // Badge
    this.refreshTab();

    // Initialisation du form group.
    await this.initializeFormGroup();

    this.items = this._workFormService.createItems(this.form, this.model.model);
    setTimeout((_) => {
      this._changeDetectorRef.detectChanges();
    });
    this.load();
  }

  private async isReadOnly() {
    if (!this.readOnly && this.service.readOnly != undefined) {
      let fields: Array<GqlSubField | GqlField> = [
        GqlSubField.create('data', [
          GqlField.create('state'),
          GqlField.create('username'),
        ]),
        GqlSubField.create('errors', [
          GqlField.create('messageError'),
          GqlField.create('property'),
        ]),
      ];

      let result = await this.service.readOnly(fields, this._id).toPromise();
      this.readOnlyAttribute = undefined;
      if (result != undefined && result.data != undefined) {
        this.readOnly = result.data.state;
        if (this.readOnly == true) {
          if (result.errors != undefined && result.errors.length > 0) {
            if (this.service.readonlyTemplate != undefined) {
              this.readOnlyAttribute = this.service.readonlyTemplate(
                result.errors,
              );
            }
          } else {
            this.userName = result.data.username;
          }
        }
        if (
          this.readOnly &&
          result.errors != undefined &&
          result.errors.length > 0 &&
          this.readOnlyAttribute == undefined
        ) {
          alert(
            result.errors[0].messageError,
            TranslateService.get('globals/warning'),
          );
        }
      }
    }
  }

  /** Charge l'entité si l'id est définit. */
  private load() {
    if (this._id != undefined) {
      this.updatePolicies(false);
      this.mode = 'Edit';

      let unsubscribe = this.service
        .get(this.model.formFields, this._id)
        .pipe(
          finalize(() => {
            // Petite attente pour le dirty.
            this.form.markAsLoaded();
            if (this.model.model.form.layout.attributesInfo != undefined) {
              (
                (<ModelFnContext>(
                  (this.model.model.form.layout.attributesInfo as any)
                )).fnCall as Function
              )()
                .pipe(first())
                .subscribe((attributesInfo) => {
                  this.attributesInfo = attributesInfo;
                });
            }
          }),
        )
        .subscribe((result) => {
          /** Enregistre l'etat de base du chargement de l'entité */
          this.model.sharedContext.entity = result?.data;

          if (result.data == null) {
            let unauthorized = true;
            if (
              this.model.model.form.layout.redirect != undefined &&
              (this.model.model.form.layout.redirect as ModelFnContext)
            ) {
              let method = this.model.model.form.layout
                .redirect as ModelFnContext;

              let redirect = this._route.snapshot?.queryParams?.redirectTicket;

              if (method.fnCall != undefined && redirect !== 'true') {
                method.context.params.set('id', () => this._id);
                unauthorized = false;
                method.fnCall().subscribe((r) => {
                  if (!r) {
                    this._router.navigate([`/unauthorized`]);
                  }
                });
              }
            }

            if (unauthorized) {
              this._router.navigate([`/unauthorized`]);
            }
            return;
          }

          if (result.data != undefined && result.data.attributes != undefined) {
            this.attributesInfo = result.data.attributes;
          }

          if (
            result != undefined &&
            result.data != undefined &&
            result.data['key'] != undefined &&
            result.data['defaultName'] != undefined
          ) {
            // Readonly du champs Name si System
            let controleName = FormGroupHelpers.formControlByName(
              this.form,
              'name',
            );
            if (controleName != undefined) {
              this.model.sharedContext.params.set(
                'defaultSystemValue',
                () => result.data['defaultName'],
              );
              // TODO : Demande de laisser la possibilité de modifié le name avec un retour de la valeur par defaut
              // controleName.disable({ emitEvent: false });
            }
          }

          this._loader.load(this.form, {
            ...this.model.sharedContext.entry.materialize(),
            ...result.data,
          });
        });
      this._gc.forDispose(unsubscribe);
    } else {
      this.updatePolicies(true);
      this.mode = 'New';
      this.form.markAsLoaded();

      this._loader.load(
        this.form,
        this.model.sharedContext.entry.materialize(),
        false,
        true,
      );
    }
  }

  private updatePolicies(isAdded: boolean) {
    let policies = this.servicePermission.getAuthorizations(
      this.service as any,
    );

    if (policies != undefined) {
      if (policies['operator'] != undefined) {
        this.savePolicies = policies;
      } else {
        if (policies['policies'] != undefined) {
          policies = policies['policies'];
        }

        for (let policy of policies) {
          if (isAdded === true) {
            this.savePolicies = [
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
            ];
          } else {
            this.savePolicies = [
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
            ];
          }
        }
      }
    }
  }

  validateForm(control: AbstractControl) {
    if (control['controls'] != undefined) {
      for (let inner in control['controls']) {
        let c = control.get(inner);
        if (c != undefined) {
          if (c.asyncValidator != undefined) {
            c?.updateValueAndValidity();
          }
          this.validateForm(c);
        }
      }
    }
  }

  /** Permet d'initialiser le FormGroup du composant. */
  isFormLoaded = false;
  private async initializeFormGroup() {
    // let actualId = this._route?.snapshot?.paramMap?.get('id');
    // if (this.model.id == undefined && actualId != undefined) {
    //   this.model.id = actualId;
    // }

    this._form = await this._loader.create(this.model, this.readOnly);
    this.model.formComponent = this;

    this._gc.forDispose(
      this.model.formComponent.onSaved.subscribe((res) => {
        this.localStorageService.ModelState.on.emit({
          eventName: 'reload-tabs',
          eventData: undefined,
        });
      }),
    );

    this._gc.forDispose(
      this.localStorageService.ModelState.on.subscribe((f) => {
        if (f.eventName == 'reload-tabs') {
          this.validateForm(this.model.formComponent.form);

          this.refreshTab();
        }
      }),
    );

    this._changeDetectorRef.detectChanges();

    let unsubscribe = this._form.onLoadedChanged.subscribe((value) => {
      if (value === true) {
        //console.timeEnd('loading');
        this.onLoaded.emit();

        this.isFormLoaded = true;
        this.formReady.emit(true);
        if (this._tasks.length > 0) {
          for (let task of this._tasks) {
            task.execute();
          }
          this._tasks.clear();
        }
      }
    });
    this._gc.forDispose(unsubscribe);

    unsubscribe = this._form.valueChanges.subscribe((_) => {
      this.valueChanges.emit(_);
      if (this._form.loaded === true) {
        this._changeDetectorRef.detectChanges();
      }
    });
    this._gc.forDispose(unsubscribe);
  }

  @Input() beforeSave: (e: any) => Promise<boolean> = (e) => {
    return of(true).toPromise();
  };
  @Input() afterSave: (data: { result: any; e: any }) => void = () => {};

  private makeFormReadOnly() {
    if (this.readOnly === true) {
      let controls = FormGroupHelpers.formControls(this.form);
      controls.forEach((el) => {
        if (!(el.value['disableReadOnlyMode'] === true)) {
          el.value.disable();
        }
      });
    }
  }

  /** Se déclenche à la sauvegarde. */
  async save(e) {
    if ((await this.beforeSave(e)) === false) {
      return;
    }
    this._gc.release();
    try {
      let mode = this.mode;

      this.errors = [];
      this.form.markAsUnloaded();
      let observable = this._recorder.save(
        this._id,
        this._form,
        this.service as any,
        this.model,
      );

      let unsubscribe = observable.subscribe(
        async (result) => {
          this.model.sharedContext.params.set('force', () => false);
          await this.afterSave({ result: result, e: e });
          this._tasks.clear();
          if (result?.errors != undefined && result.errors.length > 0) {
            // Afficher toutes les erreurs
            this.errors = result.errors;
            // Permet d'afficher les erreurs sous les propriétés concernées.
            let errors = result.errors.filter((e) => e.property != undefined);
            if (errors.length > 0) {
              let controls = FormGroupHelpers.formControls(this.form);
              let groupedErrors = errors.reduce((r, a) => {
                let value = {};
                value[a.messageError] = undefined;
                r[a.property] = {
                  ...r[a.property],
                  ...value,
                };
                return r;
              }, Object.create(null));

              for (let member in groupedErrors) {
                let findControls = controls.filter((c) => c.key === member);
                if (findControls.length > 0) {
                  let control = findControls[0].value;
                  control.setErrors(groupedErrors[member]);
                }
              }
            }
            // Permet d'afficher les avertissements
            let errorType: ValidationErrorCategory =
              result.errors[0].validationCategory;

            if (errorType == undefined)
              errorType = ValidationErrorCategory.Error;

            if (errorType == 'WARNING' || errorType == 'SIMPLEWARNING') {
              // Message d'avertissement récupèrer côté server
              let warningMessage =
                result.errors != undefined && result.errors.length > 0
                  ? result.errors[0].messageError
                  : TranslateService.get('globals/warning');
              if (errorType == 'WARNING') {
                this.confirmSaveDialog(
                  TranslateService.get('globals/warning'),
                  warningMessage,
                  TranslateService.get('yes'),
                  TranslateService.get('no'),
                  TranslateService.get('cancel'),
                ).then((res) => {
                  if (res === true) {
                    // On fixe la valeur 'force' à true afin de forcer l'enregistrement
                    this.model.sharedContext.params.set('force', () => true);
                    // Rappel de la fonctione d'enregistrement
                    this.save(e);
                  }
                });
              } else {
                this.confirmSaveDialog(
                  TranslateService.get('globals/warning'),
                  warningMessage,
                  TranslateService.get('validate'),
                  '',
                  TranslateService.get('cancel'),
                ).then((res) => {
                  if (res === true) {
                    // On fixe la valeur 'force' à true afin de forcer l'enregistrement
                    this.model.sharedContext.params.set('force', () => true);
                    // Rappel de la fonctione d'enregistrement
                    this.save(e);
                  }
                });
              }
            } else {
              let errorMessage =
                errors != undefined && errors.length > 0
                  ? errors[0].messageError
                  : TranslateService.get('saveError');
              notify(errorMessage, errorType, 5000);
            }

            this.form.markAsLoaded();
            if (
              mode == 'New' &&
              result.data != undefined &&
              result.data.id != undefined &&
              result.data.id !== '00000000-0000-0000-0000-000000000000'
            ) {
              this._id = result.data.id;

              this.model.id = this._id;
              mode = 'Edit';
              if (this.isExternalForm === false) {
                // Passe le form en edit
                let segments = this._router.parseUrl(this._router.url).root
                  .children.primary.segments;
                let root: string = '';
                for (let i = 0; i < segments.length - 1; i++) {
                  root = `${root}${
                    this._router.parseUrl(this._router.url).root.children
                      .primary.segments[i].path
                  }/`;
                }
                if (
                  this.navigateAfterSave == true &&
                  result?.data?.id != undefined
                ) {
                  this._location.replaceState(`${root}edit/${result.data.id}`);
                }
              }
            }
          } else {
            // Mise à jour de l'id courant
            if (this._id == undefined) {
              if (result.data == undefined || result.data.id == undefined) {
                if (this.disableSaveNotification === false) {
                  notify(TranslateService.get('saveError'), 'error', 5000);
                }
              } else {
                this._id = result.data.id;
                this.model.id = this._id;
                mode = 'Edit';
              }
              if (this.isExternalForm === false) {
                // Passe le form en edit
                let segments = this._router.parseUrl(this._router.url).root
                  .children.primary.segments;
                let root: string = '';
                for (let i = 0; i < segments.length - 1; i++) {
                  root = `${root}${
                    this._router.parseUrl(this._router.url).root.children
                      .primary.segments[i].path
                  }/`;
                }
                if (
                  this.navigateAfterSave == true &&
                  result?.data?.id != undefined
                ) {
                  this._location.replaceState(`${root}edit/${result.data.id}`);
                }
              }
            }
            this.mode = 'Wait';
            this._changeDetectorRef.detectChanges();
            // this._tasks.clear();

            // Événement de sauvegarde
            e.id = this._id;
            this.onSubmit.emit(e);

            if (e.close === true) {
              if (this.isExternalForm === true) {
                this.onSaved.emit(this._id);
              }
              this._tasks.push({
                execute: () => {
                  let msg = TranslateService.get('saveSuccess');
                  if (this.successMessage != undefined) {
                    msg = TranslateService.get(this.successMessage);
                  }
                  if (this.disableSaveNotification === false) {
                    notify(msg, 'success', 5000);
                  }
                  this.close();
                },
              });
            } else {
              this.loadData(mode, e);

              //managed readonly after save
              if (
                this._id != undefined &&
                this.model.model.form?.layout?.checkReadOnly != undefined &&
                this.model.model.form.layout.checkReadOnly == true
              ) {
                await this.isReadOnly();
                this.makeFormReadOnly();
              }
              //managed readonly end save
            }
            try {
              // Déclenche le callback de l'appelant.
              if (window.opener && window.opener.callback != undefined) {
                window.opener.callback();
              }
            } catch (exWin) {
              // pas de gestion d'expetion
            }
            this.form.markAsLoaded();
            // if (e.close == false) {
            //   this.form.markAsUntouched();
            //   this.onSaved.emit(this._id);
            // }
          }
        },
        (err) => {
          if (err.graphQLErrors === undefined) {
            this.errors = [
              {
                messageError: err,
                validationCategory: ValidationErrorCategory.Error,
              },
            ];
          } else if (err.graphQLErrors.length > 0) {
            let lastErrorMessage = err.graphQLErrors[0].message.replace(
              'Clarilog.One.GraphQL.Extensions.LicenseError: ',
              '',
            );
            this.errors = [
              {
                messageError: lastErrorMessage,
                validationCategory: ValidationErrorCategory.Error,
              },
            ];
          }
          this.form.markAsLoaded();
        },
      );
      this._gc.forRelease(unsubscribe);
    } catch (e) {
      notify(TranslateService.get('saveError'), 'error', 5000);
      console.error(e);
    }
  }

  public async loadData(mode: WorkItemFormMode = 'Edit', e = null) {
    let unsubscribe = await this.service
      .get(this.model.formFields, this._id)
      .pipe(first())
      .subscribe((result) => {
        /** Enregistre l'etat de base du chargement de l'entité */
        this.model.sharedContext.entity = result?.data;

        // Mise en fin de vide d'un bien <==> result = null
        if (result.data != null && result.data != undefined) {
          if (result.data.attributes != null) {
            this.attributesInfo = result.data.attributes;
            // Chargement des données issues du serveur.
          }

          this._loader.load(this.form, {
            ...this.model.sharedContext.entry.materialize(),
            ...result.data,
          });
        }

        this.onSaved.emit(this._id);
        this._tasks.push({
          execute: () => {
            this.mode = mode;
            this._changeDetectorRef.detectChanges();
            if (e.notification == undefined || e.notification === true) {
              let msg = TranslateService.get('saveSuccess');
              if (this.successMessage != undefined) {
                msg = TranslateService.get(this.successMessage);
              }
              if (this.disableSaveNotification === false) {
                notify(msg, 'success', 5000);
              }
            }
          },
        });
        // }
      });
    this._gc.forRelease(unsubscribe);
  }

  async confirmSaveDialog(
    title: string,
    message: string,
    saveButtonMessage: string,
    discardButtonMessage: string,
    cancelButtonMessage: string,
  ): Promise<boolean> {
    let buttons: dxButtonOptions[] = [];
    if (saveButtonMessage !== '' && saveButtonMessage !== undefined) {
      buttons.push({
        type: 'default',
        text: saveButtonMessage,
        onClick: (e) => {
          return true;
        },
      });
    }
    if (discardButtonMessage !== '' && discardButtonMessage !== undefined) {
      buttons.push({
        text: discardButtonMessage,
        onClick: (e) => {
          return false;
        },
      });
    }
    if (cancelButtonMessage !== '' && cancelButtonMessage !== undefined) {
      buttons.push({
        text: cancelButtonMessage,
        onClick: (e) => {
          return false;
        },
      });
    }
    return await custom({
      title: title,
      messageHtml: message,
      buttons: buttons,
    }).show();
  }

  navigateToByTitle(title: string) {
    let itemToActivate = null;
    this.workItemForm.items.forEach((item) => {
      item.items.forEach((element) => {
        if (element.title == title) {
          itemToActivate = element;

          if (item.visible != undefined) itemToActivate.visible = item.visible;
          else itemToActivate.visible = element.data.visible;
        }
      }, this);
    }, this);

    if (itemToActivate != null && itemToActivate.visible) {
      this.workItemForm.selectionChanged(itemToActivate);
    }
  }

  test() {
    console.log(this.form);
  }

  // En test
  private _tasks: { execute: Function }[] = [];

  getPermission() {
    let policies = this.servicePermission.getAuthorizations(
      this.service as any,
    );
    if (policies != undefined) {
      let operator = 'and';
      if (policies['formPolicies'] != undefined) {
        policies = policies['formPolicies'];
      } else {
        if (policies['operator'] != undefined) {
          operator = policies['operator'];
          policies = policies['policies'];
        }
      }
      let canAddTmp = false;
      let canDeleteTmp = false;
      let canEditTmp = false;
      let canImportTmp = false;
      for (let policy of policies) {
        if (operator == 'or') {
          if (canEditTmp == false) {
            canEditTmp =
              this.canEdit &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
              );
          }
        } else {
          this.canEdit =
            this.canEdit &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
            );
        }
      }

      if (operator == 'or') {
        this.canEdit = canEditTmp;
      }
    }
  }
}
