import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfTicketCategory, FilterOfTicketCategory, QueryContextOfTicketCategory, ServiceSingleResultOfInt64, ServiceListResultOfTicketCategory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, FilterOfFileModel, QueryContextOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfPopulation, FilterOfPopulation, QueryContextOfPopulation, ServiceListResultOfOperatorTeam, FilterOfOperatorTeam, QueryContextOfOperatorTeam, ServiceSingleResultOfProcessActionEntity, FilterOfProcessActionEntity, QueryContextOfProcessActionEntity, ServiceListResultOfServiceLevelAgreement, FilterOfServiceLevelAgreement, QueryContextOfServiceLevelAgreement, ServiceListResultOfTicket, FilterOfTicket, QueryContextOfTicket, ServiceListResultOfIncidentModel, FilterOfIncidentModel, QueryContextOfIncidentModel, ServiceListResultOfArticleKnowledge, FilterOfArticleKnowledge, QueryContextOfArticleKnowledge, ServiceListResultOfEmailConnector, FilterOfEmailConnector, QueryContextOfEmailConnector, TicketCategoryInput, FieldUpdateOperatorOfTicketCategory, ServiceSingleResultOfGuid, AllowedEntityInput } from '../types'

export type GetTicketCategoriesResultType = {
    ticketCategories: {
        get?: Maybe<ServiceSingleResultOfTicketCategory>
    }
}

export type GetTicketCategoriesBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getTicketCategories ${args} {
    ticketCategories {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstTicketCategoriesResultType = {
    ticketCategories: {
        first?: Maybe<ServiceSingleResultOfTicketCategory>
    }
}

export type FirstTicketCategoriesBaseVariables = {
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstTicketCategories ${args} {
    ticketCategories {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountTicketCategoriesResultType = {
    ticketCategories: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountTicketCategoriesBaseVariables = {
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countTicketCategories ${args} {
    ticketCategories {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketCategoriesResultType = {
    ticketCategories: {
        find?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type FindTicketCategoriesBaseVariables = {
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfTicketCategory = null, $filter: FilterOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketCategories ${args} {
    ticketCategories {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchTicketCategoriesResultType = {
    ticketCategories: {
        search?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type SearchTicketCategoriesBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchTicketCategories ${args} {
    ticketCategories {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaTicketCategoriesResultType = {
    ticketCategories: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaTicketCategoriesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaTicketCategories ${args} {
    ticketCategories {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataTicketCategoriesResultType = {
    ticketCategories: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataTicketCategoriesBaseVariables = {
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataTicketCategories ${args} {
    ticketCategories {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryTicketCategoriesResultType = {
    ticketCategories: {
        customQuery?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type CustomQueryTicketCategoriesBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryTicketCategories ${args} {
    ticketCategories {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdTicketCategoriesResultType = {
    ticketCategories: {
        customQueryId?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type CustomQueryIdTicketCategoriesBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdTicketCategories ${args} {
    ticketCategories {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindFilesTicketCategoriesResultType = {
    ticketCategories: {
        findFiles?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindFilesTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les fichiers liés. */
@Injectable({providedIn: 'root'})
export class FindFilesTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findFilesTicketCategories ${args} {
    ticketCategories {
        findFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindFilesTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindFilesTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedTicketCategoriesResultType = {
    ticketCategories: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedTicketCategoriesBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedTicketCategories ${args} {
    ticketCategories {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketCategoryByPopulationTicketCategoriesResultType = {
    ticketCategories: {
        findTicketCategoryByPopulation?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type FindTicketCategoryByPopulationTicketCategoriesBaseVariables = {
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindTicketCategoryByPopulationTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketCategoryByPopulationTicketCategories ${args} {
    ticketCategories {
        findTicketCategoryByPopulation(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketCategoryByPopulationTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketCategoryByPopulationTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindListViewTicketCategoriesResultType = {
    ticketCategories: {
        findListView?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type FindListViewTicketCategoriesBaseVariables = {
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindListViewTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findListViewTicketCategories ${args} {
    ticketCategories {
        findListView(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindListViewTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindListViewTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketCategoryByTypeTicketCategoriesResultType = {
    ticketCategories: {
        findTicketCategoryByType?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type FindTicketCategoryByTypeTicketCategoriesBaseVariables = {
	qualification: Array<Maybe<string>>, /**  */
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindTicketCategoryByTypeTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$qualification: [String] = null, $filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketCategoryByTypeTicketCategories ${args} {
    ticketCategories {
        findTicketCategoryByType(qualification: $qualification, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketCategoryByTypeTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketCategoryByTypeTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedPopulationTicketCategoriesResultType = {
    ticketCategories: {
        findAssociatedPopulation?: Maybe<ServiceListResultOfPopulation>
    }
}

export type FindAssociatedPopulationTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfPopulation>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPopulation>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssociatedPopulationTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfPopulation = null, $options: QueryContextOfPopulation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedPopulationTicketCategories ${args} {
    ticketCategories {
        findAssociatedPopulation(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedPopulationTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedPopulationTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedOperatorTeamTicketCategoriesResultType = {
    ticketCategories: {
        findAssociatedOperatorTeam?: Maybe<ServiceListResultOfOperatorTeam>
    }
}

export type FindAssociatedOperatorTeamTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfOperatorTeam>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOperatorTeam>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssociatedOperatorTeamTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfOperatorTeam = null, $options: QueryContextOfOperatorTeam = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedOperatorTeamTicketCategories ${args} {
    ticketCategories {
        findAssociatedOperatorTeam(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedOperatorTeamTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedOperatorTeamTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOperatorTeam", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketCategoryByPopulationWithModelTicketCategoriesResultType = {
    ticketCategories: {
        findTicketCategoryByPopulationWithModel?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type FindTicketCategoryByPopulationWithModelTicketCategoriesBaseVariables = {
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindTicketCategoryByPopulationWithModelTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketCategoryByPopulationWithModelTicketCategories ${args} {
    ticketCategories {
        findTicketCategoryByPopulationWithModel(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketCategoryByPopulationWithModelTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketCategoryByPopulationWithModelTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistTicketCategoriesResultType = {
    ticketCategories: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistTicketCategoriesBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existTicketCategories ${args} {
    ticketCategories {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindWithOrphelinTicketCategoriesResultType = {
    ticketCategories: {
        findWithOrphelin?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type FindWithOrphelinTicketCategoriesBaseVariables = {
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/** Récupère toutes les entités avec noeud orphelin. */
@Injectable({providedIn: 'root'})
export class FindWithOrphelinTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findWithOrphelinTicketCategories ${args} {
    ticketCategories {
        findWithOrphelin(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindWithOrphelinTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindWithOrphelinTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type HasBlockedTicketCategoriesResultType = {
    ticketCategories: {
        hasBlocked?: Maybe<ServiceSingleResultOfProcessActionEntity>
    }
}

export type HasBlockedTicketCategoriesBaseVariables = {
	filter: Maybe<FilterOfProcessActionEntity>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfProcessActionEntity>, /** Les options de requêtage à appliquer. */
}

/** Obtient si le type est actuellement en process. */
@Injectable({providedIn: 'root'})
export class HasBlockedTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfProcessActionEntity = null, $options: QueryContextOfProcessActionEntity = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query hasBlockedTicketCategories ${args} {
    ticketCategories {
        hasBlocked(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: HasBlockedTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<HasBlockedTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfProcessActionEntity", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedServiceLevelAgreementsTicketCategoriesResultType = {
    ticketCategories: {
        findUnassociatedServiceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement>
    }
}

export type FindUnassociatedServiceLevelAgreementsTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfServiceLevelAgreement>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfServiceLevelAgreement>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedServiceLevelAgreementsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfServiceLevelAgreement = null, $options: QueryContextOfServiceLevelAgreement = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedServiceLevelAgreementsTicketCategories ${args} {
    ticketCategories {
        findUnassociatedServiceLevelAgreements(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedServiceLevelAgreementsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedServiceLevelAgreementsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfServiceLevelAgreement", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedTicketsTicketCategoriesResultType = {
    ticketCategories: {
        findUnassociatedTickets?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindUnassociatedTicketsTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedTicketsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedTicketsTicketCategories ${args} {
    ticketCategories {
        findUnassociatedTickets(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedTicketsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedTicketsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedIncidentModelsTicketCategoriesResultType = {
    ticketCategories: {
        findUnassociatedIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindUnassociatedIncidentModelsTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedIncidentModelsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfIncidentModel = null, $options: QueryContextOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedIncidentModelsTicketCategories ${args} {
    ticketCategories {
        findUnassociatedIncidentModels(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedIncidentModelsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedIncidentModelsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedArticlesKnowledgesTicketCategoriesResultType = {
    ticketCategories: {
        findUnassociatedArticlesKnowledges?: Maybe<ServiceListResultOfArticleKnowledge>
    }
}

export type FindUnassociatedArticlesKnowledgesTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfArticleKnowledge>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfArticleKnowledge>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedArticlesKnowledgesTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfArticleKnowledge = null, $options: QueryContextOfArticleKnowledge = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedArticlesKnowledgesTicketCategories ${args} {
    ticketCategories {
        findUnassociatedArticlesKnowledges(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedArticlesKnowledgesTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedArticlesKnowledgesTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfArticleKnowledge", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedEmailConnectorsTicketCategoriesResultType = {
    ticketCategories: {
        findUnassociatedEmailConnectors?: Maybe<ServiceListResultOfEmailConnector>
    }
}

export type FindUnassociatedEmailConnectorsTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfEmailConnector>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailConnector>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedEmailConnectorsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfEmailConnector = null, $options: QueryContextOfEmailConnector = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedEmailConnectorsTicketCategories ${args} {
    ticketCategories {
        findUnassociatedEmailConnectors(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedEmailConnectorsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedEmailConnectorsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailConnector", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertTicketCategoriesResultType = {
    ticketCategories: {
        insert?: Maybe<ServiceSingleResultOfTicketCategory>
    }
}

export type InsertTicketCategoriesBaseVariables = {
	entity: TicketCategoryInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: TicketCategoryInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertTicketCategories ${args} {
    ticketCategories {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateTicketCategoriesResultType = {
    ticketCategories: {
        update?: Maybe<ServiceSingleResultOfTicketCategory>
    }
}

export type UpdateTicketCategoriesBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfTicketCategory>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateTicketCategories ${args} {
    ticketCategories {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataTicketCategoriesResultType = {
    ticketCategories: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataTicketCategoriesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataTicketCategories ${args} {
    ticketCategories {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyTicketCategoriesResultType = {
    ticketCategories: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyTicketCategoriesBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfTicketCategory>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyTicketCategories ${args} {
    ticketCategories {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteTicketCategoriesResultType = {
    ticketCategories: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteTicketCategoriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteTicketCategories ${args} {
    ticketCategories {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DragTicketCategoriesResultType = {
    ticketCategories: {
        drag?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DragTicketCategoriesBaseVariables = {
	fromId: string, /** L'identifiant à ordonner. */
	toId: Maybe<string>, /** L'identifiant de l'élément vers qui l'ordonner. */
}

/** Permet d'ordonner l'entité. */
@Injectable({providedIn: 'root'})
export class DragTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fromId: Uuid!, $toId: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation dragTicketCategories ${args} {
    ticketCategories {
        drag(fromId: $fromId, toId: $toId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DragTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DragTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DragIdsTicketCategoriesResultType = {
    ticketCategories: {
        dragIds?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DragIdsTicketCategoriesBaseVariables = {
	fromIds: Array<string>, /** Les identifiants à ordonner. */
	toId: Maybe<string>, /** L'identifiant de l'élément vers qui l'ordonner. */
}

/** Permet d'ordonner l'entité. */
@Injectable({providedIn: 'root'})
export class DragIdsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fromIds: [Uuid!], $toId: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation dragIdsTicketCategories ${args} {
    ticketCategories {
        dragIds(fromIds: $fromIds, toId: $toId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DragIdsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DragIdsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UploadChunkFileTicketCategoriesResultType = {
    ticketCategories: {
        uploadChunkFile?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type UploadChunkFileTicketCategoriesBaseVariables = {
	name: Maybe<string>, /**  */
	size: number, /**  */
	type: Maybe<string>, /**  */
	chunks: Maybe<string>, /**  */
	indexChunk: number, /**  */
	lastChunk: boolean, /**  */
	fileId: Maybe<string>, /**  */
	entityId: Maybe<string>, /**  */
	visibleByHelpDeskUser: boolean, /**  */
	outgoingEmails: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UploadChunkFileTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$name: String = null, $size: Int!, $type: String = null, $chunks: String = null, $indexChunk: Int!, $lastChunk: Boolean!, $fileId: Uuid = null, $entityId: Uuid = null, $visibleByHelpDeskUser: Boolean!, $outgoingEmails: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation uploadChunkFileTicketCategories ${args} {
    ticketCategories {
        uploadChunkFile(name: $name, size: $size, type: $type, chunks: $chunks, indexChunk: $indexChunk, lastChunk: $lastChunk, fileId: $fileId, entityId: $entityId, visibleByHelpDeskUser: $visibleByHelpDeskUser, outgoingEmails: $outgoingEmails) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UploadChunkFileTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UploadChunkFileTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdatePopulationsTicketCategoriesResultType = {
    ticketCategories: {
        updatePopulations?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdatePopulationsTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	populations: Array<Maybe<AllowedEntityInput>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdatePopulationsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $populations: [AllowedEntityInput] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updatePopulationsTicketCategories ${args} {
    ticketCategories {
        updatePopulations(id: $id, populations: $populations) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdatePopulationsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdatePopulationsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateOperatorTeamsTicketCategoriesResultType = {
    ticketCategories: {
        updateOperatorTeams?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateOperatorTeamsTicketCategoriesBaseVariables = {
	id: Maybe<string>, /**  */
	operatorTeams: Array<Maybe<AllowedEntityInput>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateOperatorTeamsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $operatorTeams: [AllowedEntityInput] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateOperatorTeamsTicketCategories ${args} {
    ticketCategories {
        updateOperatorTeams(id: $id, operatorTeams: $operatorTeams) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateOperatorTeamsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateOperatorTeamsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileLinkTicketCategoriesResultType = {
    ticketCategories: {
        addFileLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileLinkTicketCategoriesBaseVariables = {
	id: string, /**  */
	fileId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileLinkTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileLinkTicketCategories ${args} {
    ticketCategories {
        addFileLink(id: $id, fileId: $fileId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileLinkTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileLinkTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileLinkTicketCategoriesResultType = {
    ticketCategories: {
        removeFileLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileLinkTicketCategoriesBaseVariables = {
	id: string, /**  */
	fileId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileLinkTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileLinkTicketCategories ${args} {
    ticketCategories {
        removeFileLink(id: $id, fileId: $fileId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileLinkTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileLinkTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddServiceLevelAgreementsTicketCategoriesResultType = {
    ticketCategories: {
        addServiceLevelAgreements?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddServiceLevelAgreementsTicketCategoriesBaseVariables = {
	id: string, /**  */
	serviceLevelAgreementIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddServiceLevelAgreementsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $serviceLevelAgreementIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addServiceLevelAgreementsTicketCategories ${args} {
    ticketCategories {
        addServiceLevelAgreements(id: $id, serviceLevelAgreementIds: $serviceLevelAgreementIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddServiceLevelAgreementsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddServiceLevelAgreementsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveServiceLevelAgreementsTicketCategoriesResultType = {
    ticketCategories: {
        removeServiceLevelAgreements?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveServiceLevelAgreementsTicketCategoriesBaseVariables = {
	id: string, /**  */
	serviceLevelAgreementIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveServiceLevelAgreementsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $serviceLevelAgreementIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeServiceLevelAgreementsTicketCategories ${args} {
    ticketCategories {
        removeServiceLevelAgreements(id: $id, serviceLevelAgreementIds: $serviceLevelAgreementIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveServiceLevelAgreementsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveServiceLevelAgreementsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddArticlesKnowledgesTicketCategoriesResultType = {
    ticketCategories: {
        addArticlesKnowledges?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddArticlesKnowledgesTicketCategoriesBaseVariables = {
	id: string, /**  */
	articleKnowledgeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddArticlesKnowledgesTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $articleKnowledgeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addArticlesKnowledgesTicketCategories ${args} {
    ticketCategories {
        addArticlesKnowledges(id: $id, articleKnowledgeIds: $articleKnowledgeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddArticlesKnowledgesTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddArticlesKnowledgesTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveArticlesKnowledgesTicketCategoriesResultType = {
    ticketCategories: {
        removeArticlesKnowledges?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveArticlesKnowledgesTicketCategoriesBaseVariables = {
	articleKnowledgeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveArticlesKnowledgesTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$articleKnowledgeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeArticlesKnowledgesTicketCategories ${args} {
    ticketCategories {
        removeArticlesKnowledges(articleKnowledgeIds: $articleKnowledgeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveArticlesKnowledgesTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveArticlesKnowledgesTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddEmailConnectorsTicketCategoriesResultType = {
    ticketCategories: {
        addEmailConnectors?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddEmailConnectorsTicketCategoriesBaseVariables = {
	id: string, /**  */
	emailConnectorIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddEmailConnectorsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $emailConnectorIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addEmailConnectorsTicketCategories ${args} {
    ticketCategories {
        addEmailConnectors(id: $id, emailConnectorIds: $emailConnectorIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddEmailConnectorsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddEmailConnectorsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveEmailConnectorsTicketCategoriesResultType = {
    ticketCategories: {
        removeEmailConnectors?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveEmailConnectorsTicketCategoriesBaseVariables = {
	emailConnectorIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveEmailConnectorsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$emailConnectorIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeEmailConnectorsTicketCategories ${args} {
    ticketCategories {
        removeEmailConnectors(emailConnectorIds: $emailConnectorIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveEmailConnectorsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveEmailConnectorsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddIncidentModelsTicketCategoriesResultType = {
    ticketCategories: {
        addIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddIncidentModelsTicketCategoriesBaseVariables = {
	id: string, /**  */
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddIncidentModelsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addIncidentModelsTicketCategories ${args} {
    ticketCategories {
        addIncidentModels(id: $id, incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddIncidentModelsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddIncidentModelsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveIncidentModelsTicketCategoriesResultType = {
    ticketCategories: {
        removeIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveIncidentModelsTicketCategoriesBaseVariables = {
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveIncidentModelsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeIncidentModelsTicketCategories ${args} {
    ticketCategories {
        removeIncidentModels(incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveIncidentModelsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveIncidentModelsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddTicketsTicketCategoriesResultType = {
    ticketCategories: {
        addTickets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddTicketsTicketCategoriesBaseVariables = {
	id: string, /**  */
	ticketIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddTicketsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addTicketsTicketCategories ${args} {
    ticketCategories {
        addTickets(id: $id, ticketIds: $ticketIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddTicketsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddTicketsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveTicketsTicketCategoriesResultType = {
    ticketCategories: {
        removeTickets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveTicketsTicketCategoriesBaseVariables = {
	ticketIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveTicketsTicketCategoriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeTicketsTicketCategories ${args} {
    ticketCategories {
        removeTickets(ticketIds: $ticketIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveTicketsTicketCategoriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveTicketsTicketCategoriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
