import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetImportPackageLogsBaseVariables, FirstImportPackageLogsBaseVariables, CountImportPackageLogsBaseVariables, FindImportPackageLogsBaseVariables, SearchImportPackageLogsBaseVariables, ExportSchemaImportPackageLogsBaseVariables, ExportDataImportPackageLogsBaseVariables, CustomQueryImportPackageLogsBaseVariables, CustomQueryIdImportPackageLogsBaseVariables, UsedImportPackageLogsBaseVariables, ExistImportPackageLogsBaseVariables, InsertImportPackageLogsBaseVariables, UpdateImportPackageLogsBaseVariables, ImportDataImportPackageLogsBaseVariables, UpdateManyImportPackageLogsBaseVariables, DeleteImportPackageLogsBaseVariables, RunImportPackageLogsBaseVariables } from '../gqls'
import { GetImportPackageLogsDocument, FirstImportPackageLogsDocument, CountImportPackageLogsDocument, FindImportPackageLogsDocument, SearchImportPackageLogsDocument, ExportSchemaImportPackageLogsDocument, ExportDataImportPackageLogsDocument, CustomQueryImportPackageLogsDocument, CustomQueryIdImportPackageLogsDocument, UsedImportPackageLogsDocument, ExistImportPackageLogsDocument, InsertImportPackageLogsDocument, UpdateImportPackageLogsDocument, ImportDataImportPackageLogsDocument, UpdateManyImportPackageLogsDocument, DeleteImportPackageLogsDocument, RunImportPackageLogsDocument } from '../gqls'
import { ServiceSingleResultOfImportPackageLog, QueryContextOfImportPackageLog, FilterOfImportPackageLog, ServiceSingleResultOfInt64, ServiceListResultOfImportPackageLog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ImportPackageLogInput, FieldUpdateOperatorOfImportPackageLog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ImportPackageLogBaseService {
    
public modelName = 'importPackageLog';
public modelPluralName = 'importPackageLogs';

	private getImportPackageLogsQuery: GetImportPackageLogsDocument;
	private firstImportPackageLogsQuery: FirstImportPackageLogsDocument;
	private countImportPackageLogsQuery: CountImportPackageLogsDocument;
	private findImportPackageLogsQuery: FindImportPackageLogsDocument;
	private searchImportPackageLogsQuery: SearchImportPackageLogsDocument;
	private exportSchemaImportPackageLogsQuery: ExportSchemaImportPackageLogsDocument;
	private exportDataImportPackageLogsQuery: ExportDataImportPackageLogsDocument;
	private customQueryImportPackageLogsQuery: CustomQueryImportPackageLogsDocument;
	private customQueryIdImportPackageLogsQuery: CustomQueryIdImportPackageLogsDocument;
	private usedImportPackageLogsQuery: UsedImportPackageLogsDocument;
	private existImportPackageLogsQuery: ExistImportPackageLogsDocument;
	private insertImportPackageLogsMutation: InsertImportPackageLogsDocument;
	private updateImportPackageLogsMutation: UpdateImportPackageLogsDocument;
	private importDataImportPackageLogsMutation: ImportDataImportPackageLogsDocument;
	private updateManyImportPackageLogsMutation: UpdateManyImportPackageLogsDocument;
	private deleteImportPackageLogsMutation: DeleteImportPackageLogsDocument;
	private runImportPackageLogsMutation: RunImportPackageLogsDocument;

	constructor(private injector: Injector) {
		this.getImportPackageLogsQuery = this.injector.get(GetImportPackageLogsDocument);
		this.firstImportPackageLogsQuery = this.injector.get(FirstImportPackageLogsDocument);
		this.countImportPackageLogsQuery = this.injector.get(CountImportPackageLogsDocument);
		this.findImportPackageLogsQuery = this.injector.get(FindImportPackageLogsDocument);
		this.searchImportPackageLogsQuery = this.injector.get(SearchImportPackageLogsDocument);
		this.exportSchemaImportPackageLogsQuery = this.injector.get(ExportSchemaImportPackageLogsDocument);
		this.exportDataImportPackageLogsQuery = this.injector.get(ExportDataImportPackageLogsDocument);
		this.customQueryImportPackageLogsQuery = this.injector.get(CustomQueryImportPackageLogsDocument);
		this.customQueryIdImportPackageLogsQuery = this.injector.get(CustomQueryIdImportPackageLogsDocument);
		this.usedImportPackageLogsQuery = this.injector.get(UsedImportPackageLogsDocument);
		this.existImportPackageLogsQuery = this.injector.get(ExistImportPackageLogsDocument);
		this.insertImportPackageLogsMutation = this.injector.get(InsertImportPackageLogsDocument);
		this.updateImportPackageLogsMutation = this.injector.get(UpdateImportPackageLogsDocument);
		this.importDataImportPackageLogsMutation = this.injector.get(ImportDataImportPackageLogsDocument);
		this.updateManyImportPackageLogsMutation = this.injector.get(UpdateManyImportPackageLogsDocument);
		this.deleteImportPackageLogsMutation = this.injector.get(DeleteImportPackageLogsDocument);
		this.runImportPackageLogsMutation = this.injector.get(RunImportPackageLogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfImportPackageLog> {
    
    		let variables: GetImportPackageLogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.get));
            }
            else{
                let result:ServiceSingleResultOfImportPackageLog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfImportPackageLog,
		@Args('filter?') filter?: FilterOfImportPackageLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfImportPackageLog> {
    
    		let variables: FirstImportPackageLogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfImportPackageLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountImportPackageLogsBaseVariables = {
    			filter: filter
    		}
    				return this.countImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfImportPackageLog,
		@Args('filter?') filter?: FilterOfImportPackageLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImportPackageLog> {
    
    		let variables: FindImportPackageLogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfImportPackageLog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImportPackageLog> {
    
    		let variables: SearchImportPackageLogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaImportPackageLogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfImportPackageLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataImportPackageLogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfImportPackageLog,
		@Args('filter?') filter?: FilterOfImportPackageLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImportPackageLog> {
    
    		let variables: CustomQueryImportPackageLogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfImportPackageLog,
		@Args('filter?') filter?: FilterOfImportPackageLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImportPackageLog> {
    
    		let variables: CustomQueryIdImportPackageLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedImportPackageLogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistImportPackageLogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existImportPackageLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ImportPackageLogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfImportPackageLog> {
    
    		let variables: InsertImportPackageLogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertImportPackageLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfImportPackageLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfImportPackageLog> {
    
    		let variables: UpdateImportPackageLogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateImportPackageLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataImportPackageLogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataImportPackageLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfImportPackageLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyImportPackageLogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyImportPackageLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteImportPackageLogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteImportPackageLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.delete));
    	}

    	/**  */
    	@InjectArgs
    	public run(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RunImportPackageLogsBaseVariables = {
    
    		}
    				return this.runImportPackageLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.importPackageLogs.run));
    	}
    
}