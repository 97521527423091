import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { BrotherLabelBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('administration.label-printer')
export class BrotherLabelCoreService extends BrotherLabelBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
