import {
  Component,
  EventEmitter,
  Injectable,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CoreFormRecorder,
  CoreWorkSubFormComponent,
  DesignerEventType,
  FormGroupHelpers,
  TranslatedFieldHelperService,
  TranslateService,
} from '@clarilog/shared2';
import modelPage from './model/model-page.json';
import modelNavItem from './model/model-nav-item.json';
import modelSection from './model/model-section.json';
import modelGroup from './model/model-group.json';
import modelControl from './model/model-control.json';
import modelControlEdit from './model/model-control-edit.json';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { Observable, of, Subscription } from 'rxjs';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  FormDesignerEditing,
  FormDesignerTo,
  ModelRule,
  ModelRulesDesigner,
  ServiceSingleResult,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { custom } from 'devextreme/ui/dialog';
import { dxButtonOptions } from 'devextreme/ui/button';
import { Args, InjectArgs } from '@clarilog/core';
import { UntypedFormControl } from '@angular/forms';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

export type FormDesignerPopupSaveEvent = {
  eventKey: string;
  eventData: any;
};

export class FakeDesignerCoreService {
  constructor(fakeGet: any, fakeUpdate: any, fakeInsert: any) {
    this._fakeInsert = fakeInsert;
    this._fakeUpdate = fakeUpdate;
    this._fakeGet = fakeGet;
  }

  public _fakeGet;
  public _fakeUpdate;
  public _fakeInsert;

  public get(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
  ): Observable<ServiceSingleResult<any>> {
    return this._fakeGet(fields, id);
  }

  public insert(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('entity') entity: any,
  ): Observable<ServiceSingleResult<any>> {
    return this._fakeInsert(fields, entity);
  }

  @InjectArgs
  public update(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('entry?') entry?: any,
  ): Observable<ServiceSingleResult<any>> {
    return this._fakeUpdate(fields, id, entry);
  }
}

@Component({
  selector: 'cl-form-designer-popup',
  templateUrl: './form-designer-popup.component.html',
  styleUrl: './form-designer-popup.component.scss',
})
export class FormDesignerPopupComponent {
  model: any;

  @Input() parentState: ModelState;
  @ViewChild(CoreWorkSubFormComponent)
  subformComponent: CoreWorkSubFormComponent;
  @Output() saveEvent = new EventEmitter<FormDesignerPopupSaveEvent>();
  @Input() canUseStatus: boolean = true;
  @Input() qualification: string;
  @Input() formDesignerMode: FormDesignerEditing;
  @Input() formDesignerTo: FormDesignerTo;

  fakeService: any = {
    insert: (
      fields: Array<GqlField | GqlSubField>,
      entity: any,
    ): Observable<ServiceSingleResult<any>> => {
      return this.serviceFunc(entity);
    },
    update: (
      fields: Array<GqlSubField | GqlField>,
      id: string,
      entry: any,
    ): Observable<ServiceSingleResult<any>> => {
      let entity = this.recorder.materialize(this.subModelState.form);

      return this.serviceFunc(entity);
    },
    get: (
      fields: Array<GqlSubField | GqlField>,
      id: string,
    ): Observable<ServiceSingleResult<any>> => {
      return this.serviceGetFunc(id);
    },
  };

  isPopupOpen: boolean = false;
  isEdit: boolean = false;
  type: DesignerEventType = DesignerEventType.TEST;
  popupData: any;
  popupHeight = '40%';
  subModelState: ModelState;
  fakeDesignerCoreService: FakeDesignerCoreService;

  constructor(
    private injector: Injector,
    private recorder: CoreFormRecorder,
    private localService: LocalStorageService,
  ) {
    this.model = modelPage;
    this.parentState = new ModelState(injector);
    this.parentState.subFormReady.subscribe((res) => {
      this.subModelState = res.subModelState;
    });

    this.fakeDesignerCoreService = new FakeDesignerCoreService(
      this.fakeService.get.bind(this),
      this.fakeService.update.bind(this),
      this.fakeService.insert.bind(this),
    );
  }

  navITemPopupSub: Subscription;
  unSub;

  startEditPopup(type: DesignerEventType, data) {
    this.isEdit = true;
    this.subformComponent.editId = 'true';
    this.popupHeight = '50%';
    if (this.navITemPopupSub != undefined) {
      this.navITemPopupSub.unsubscribe();
      this.navITemPopupSub = undefined;
    }
    if (type === DesignerEventType.NAV_ITEM || type === DesignerEventType.TAB) {
      this.type = type;
      this.model = modelPage;
      this.popupHeight = '540px';
    } else if (type === DesignerEventType.NAV_GROUP) {
      this.type = type;
      this.model = modelPage;
      this.popupHeight = '250px';
    } else if (type === DesignerEventType.GROUP) {
      this.type = type;
      this.model = modelGroup;
      this.popupHeight = '250px';
    } else if (type === DesignerEventType.SECTION) {
      this.type = type;
      this.model = modelSection;
      this.popupHeight = '250px';
    } else if (type === DesignerEventType.CONTROL) {
      this.popupHeight = '540px';
      this.type = type;
      this.model = modelControlEdit;
    }
    this.popupData = data;

    let sub = this.parentState.subFormReady.subscribe((res) => {
      sub.unsubscribe();

      setTimeout((_) => {
        res.subModelState.form.markAsDirty();
        res.subModelState.form.markAllAsTouched();
        switch (type) {
          case DesignerEventType.CONTROL:
          case DesignerEventType.GROUP:
          case DesignerEventType.NAV_GROUP:
          case DesignerEventType.NAV_ITEM:
          case DesignerEventType.TAB:
            this.controlReadOnly(res, data?.fieldName);

            let changeTradCtrl = FormGroupHelpers.formControlByName(
              res.subModelState.form,
              'changeTranslation',
            );
            let nameCtrl = FormGroupHelpers.formControlByName(
              res.subModelState.form,
              'name',
            );
            if (changeTradCtrl != undefined && nameCtrl != undefined) {
              res.subModelState.gc.forDispose(
                changeTradCtrl.valueChanges.subscribe((val) => {
                  if (val === false) {
                    nameCtrl.disable();
                  } else {
                    nameCtrl.enable();
                  }
                }),
              );
              changeTradCtrl.setValue(false, { emitEvent: true });
            }

            let ctrlControl = FormGroupHelpers.formControlByName(
              res.subModelState.form,
              'name',
            );
            if (ctrlControl != undefined) {
              if (data.label != undefined) {
                if (data.label.fr != undefined) {
                  ctrlControl.setValue(data.label);
                } else {
                  ctrlControl.setValue({ fr: data.label });
                }
                ctrlControl.markAsDirty();
                //ctrlControl.setValue(data);
              } else {
                ctrlControl.invisible();
              }
            }
            let ctrlControlRequire = FormGroupHelpers.formControlByName(
              res.subModelState.form,
              'required',
            );
            if (ctrlControlRequire != undefined) {
              ctrlControlRequire.setValue(data.required);
              ctrlControlRequire.markAsDirty();
            }
            let ctrlControlRequireForIds = FormGroupHelpers.formControlByName(
              res.subModelState.form,
              'statusIds',
            );
            if (ctrlControlRequireForIds != undefined) {
              ctrlControlRequireForIds.setValue(data.ids);
              ctrlControlRequireForIds.markAsDirty();
            }

            let ctrlControlEditable = FormGroupHelpers.formControlByName(
              res.subModelState.form,
              'editable',
            );
            if (ctrlControlEditable != undefined) {
              ctrlControlEditable.setValue(data.editable);
              ctrlControlEditable.markAsDirty();
            }
            let ctrlControlEditForIds = FormGroupHelpers.formControlByName(
              res.subModelState.form,
              'editStatusIds',
            );
            if (ctrlControlEditForIds != undefined) {
              ctrlControlEditForIds.setValue(data.editStatusIds);
              ctrlControlEditForIds.markAsDirty();
            }

            break;

          case DesignerEventType.PAGE:
            break;
          case DesignerEventType.SECTION:
            let ctrlSection = FormGroupHelpers.formControlByName(
              res.subModelState.form,
              'sectionType',
            );

            ctrlSection.setValue(this.popupData.location ?? 'center');
            break;
        }
      }, 100);
    });
    if (this.isPopupOpen === true) {
      this.isPopupOpen = false;
      setTimeout((_) => {
        this.isPopupOpen = true;
      });
    } else {
      this.isPopupOpen = true;
    }
  }

  serviceGetFunc(id) {
    let res = {};
    return of({
      data: res,
      errors: [],
      totalCount: 0,
    } as ServiceSingleResult<any>);
  }

  serviceFunc(entity) {
    setTimeout((_) => {
      this.isPopupOpen = false;
    });

    if (this.isEdit === true) {
      switch (this.type) {
        case DesignerEventType.CONTROL:
          this.saveEvent.emit({
            eventKey: 'SAVE',
            eventData: {
              changeTranslation: entity?.changeTranslation,
              label: entity?.name,
              required: entity.required == undefined ? false : entity.required,
              editable: entity.editable == undefined ? true : entity.editable,
              statusIds: entity.statusIds,
              editStatusIds: entity.editStatusIds,
            },
          });
          break;
        case DesignerEventType.SECTION:
          this.saveEvent.emit({
            eventKey: 'SAVE',
            eventData: entity.sectionType,
          });
          break;
        case DesignerEventType.TAB:
        case DesignerEventType.GROUP:
        case DesignerEventType.NAV_ITEM:
        case DesignerEventType.NAV_GROUP:
          this.saveEvent.emit({
            eventKey: 'SAVE',
            eventData: {
              label: entity?.name,
              required: entity?.required == undefined ? false : entity.required,
              editable: entity?.editable == undefined ? true : entity.editable,
              statusIds: entity?.statusIds,
              editStatusIds: entity?.editStatusIds,
            },
          });
          break;
      }
    } else if (this.type === DesignerEventType.NAV_GROUP) {
      this.saveEvent.emit({
        eventKey: 'SAVE',
        eventData: {
          label: entity?.name,
          editable: entity?.editable == undefined ? true : entity?.editable,
        },
      });
    } else if (
      this.type === DesignerEventType.NAV_ITEM ||
      this.type === DesignerEventType.TAB
    ) {
      this.saveEvent.emit({
        eventKey: 'SAVE',
        eventData: {
          pageName: entity.pageName,
          tabName: entity.tabName,
          navItemType: entity.navItemType,
          navType: entity.navType,
          groupName: entity.groupName,
        },
      });
    } else if (this.type === DesignerEventType.SECTION) {
      this.saveEvent.emit({
        eventKey: 'SAVE',
        eventData: { sectionType: entity.sectionType },
      });
    } else if (this.type === DesignerEventType.GROUP) {
      this.saveEvent.emit({
        eventKey: 'SAVE',
        eventData: { label: entity.name },
      });
    } else if (this.type === DesignerEventType.CONTROL) {
      this.saveEvent.emit({
        eventKey: 'SAVE',
        eventData: {
          ctrlId: entity.ctrlId,
          required: entity.required == undefined ? false : entity.required,
          editable: entity.editable == undefined ? false : entity.editable,
        },
      });
    }
    return of({
      data: true,
      errors: [],
      totalCount: 0,
    } as ServiceSingleResult<any>);
  }

  startPopup(type: DesignerEventType, data = null) {
    this.popupHeight = '50%';
    this.isEdit = false;

    if (this.navITemPopupSub != undefined) {
      this.navITemPopupSub.unsubscribe();
      this.navITemPopupSub = undefined;
    }
    if (type === DesignerEventType.NAV_GROUP) {
      this.type = type;
      this.model = modelPage;
      this.popupHeight = '250px';
    } else if (
      type === DesignerEventType.NAV_ITEM ||
      type === DesignerEventType.TAB
    ) {
      this.type = type;
      this.model = modelNavItem;
      if (type === DesignerEventType.TAB) {
        this.model.title = "[resource('entities/formDesigner/customTab')]";
        let navItemType = FormGroupHelpers.findControlInModel(
          this.model?.form?.layout?.pages,
          'navItemType',
        );

        if (navItemType != undefined) {
          navItemType.label = TranslateService.get(
            'entities/formDesigner/locationTab',
          );
        }

        let navType = FormGroupHelpers.findControlInModel(
          this.model?.form?.layout?.pages,
          'navType',
        );
        if (navType != undefined) {
          navType.label = TranslateService.get(
            'entities/formDesigner/tabSelected',
          );
        }
      }
      if (data?.compiledModelToPutUpdate?.label != undefined) {
        this.model.title =
          TranslateService.get('entities/formDesigner/customTab') +
          ' : ' +
          data?.compiledModelToPutUpdate?.label;
      }

      this.popupHeight = '300px';
    } else if (type === DesignerEventType.SECTION) {
      this.popupHeight = '250px';
      this.type = type;
      this.model = modelSection;
    } else if (type === DesignerEventType.GROUP) {
      this.popupHeight = '250px';
      this.type = type;
      this.model = modelGroup;
    } else if (type === DesignerEventType.CONTROL) {
      this.popupHeight = '540px';
      this.type = type;
      this.model = modelControl;

      if (data?.compiledModelToPutUpdate?.label != undefined) {
        this.model.title =
          TranslateService.get('entities/formDesigner/customControl') +
          ' : ' +
          data?.compiledModelToPutUpdate?.label;
      }

      let sub = this.parentState.subFormReady.subscribe((subModel) => {
        sub.unsubscribe();

        let ctrlId = FormGroupHelpers.formControlByName(
          subModel.subModelState.form,
          'ctrlId',
        );
        this.controlReadOnly(subModel, undefined);
        subModel.subModelState.gc.forDispose(
          ctrlId.valueChanges.subscribe((val) => {
            let item = this.popupData.controls.compiledResult.find(
              (s) => s.id == val,
            );

            this.controlReadOnly(subModel, item?.fieldName);
          }),
        );
      });
    }
    this.popupData = data;
    if (this.isPopupOpen === true) {
      this.isPopupOpen = false;
      setTimeout((_) => {
        this.isPopupOpen = true;
      });
    } else {
      this.isPopupOpen = true;
    }
  }

  editUnSubscribe;
  requiredUnSubscribe;
  controlReadOnly(subModel, fieldName) {
    let editable = FormGroupHelpers.formControlByName(
      subModel.subModelState.form,
      'editable',
    );
    let required = FormGroupHelpers.formControlByName(
      subModel.subModelState.form,
      'required',
    );
    let editStatusIds = FormGroupHelpers.formControlByName(
      subModel.subModelState.form,
      'editStatusIds',
    );

    let statusIds = FormGroupHelpers.formControlByName(
      subModel.subModelState.form,
      'statusIds',
    );

    if (this.editUnSubscribe != undefined) {
      this.editUnSubscribe.unsubscribe();
      this.editUnSubscribe = undefined;
    }
    if (this.requiredUnSubscribe != undefined) {
      this.requiredUnSubscribe.unsubscribe();
      this.requiredUnSubscribe = undefined;
    }

    // Non visible par défaut
    required?.invisible();
    statusIds?.invisible();
    editStatusIds?.invisible();
    editable?.invisible();

    // TODO opti list des champs readonly
    let readOnlyFields = [
      'ticketNumber',
      'statusId',
      'affectedOperatorCalendar',
    ];
    this.localService.user
      .getModelRule(
        this.qualification,
        this.formDesignerTo == FormDesignerTo.HelpMe,
      )
      .subscribe((rule) => {
        let ruleFieldName: ModelRule;

        if (
          fieldName != undefined &&
          rule?.data != undefined &&
          rule.data.length > 0
        ) {
          let ruleData = undefined;
          switch (this.formDesignerMode) {
            case FormDesignerEditing.Creation:
              ruleData = rule.data[0].rules;
              break;
            default:
              ruleData = rule.data[0].editRules;
              break;
          }

          if (ruleData != undefined) {
            ruleFieldName = ruleData.find((s) => s.fieldName == fieldName);
            if (ruleFieldName?.option != undefined) {
              if (ruleFieldName.option.allowEdit == false) {
                // Force le mode readOnly
                readOnlyFields.push(fieldName);
              }
            }
            // Cas champs inconnu dans le model rule
            if (ruleFieldName == undefined) {
              readOnlyFields.push(fieldName);
            }
          }
        }

        let readOnly = readOnlyFields.indexOf(fieldName) >= 0;

        if (readOnly || fieldName == undefined) {
          // Aucune action
          return;
        }

        let canRequire = ruleFieldName?.option?.allowRequire ?? false;

        this.editUnSubscribe = editable?.valueChanges?.subscribe((f) => {
          if (f === true) {
            if (canRequire) {
              // SI peu etre requis
              required?.enable();
              required?.visible();
            }
            this.canVisible(editStatusIds, editable, true);
          } else if (f === false) {
            required?.setValue(false);
            required?.invisible();
            statusIds?.setValue([]);
            statusIds?.invisible();

            editStatusIds?.setValue([]);
            editStatusIds?.invisible();
          }
        });

        this.requiredUnSubscribe = required?.valueChanges.subscribe((f) => {
          if (f === false) {
            statusIds?.setValue([]);
            statusIds?.invisible();
          } else {
            this.canVisible(statusIds, required, canRequire);
          }
        });

        // Dans ce cas toujours visible
        editable?.visible();
        this.canVisible(editStatusIds, editable, true);

        // Si editable
        if (canRequire && editable?.value == true) {
          required?.visible();
          this.canVisible(statusIds, required, canRequire);
        }
      });
  }

  /** Control la visibilité du champs status en fonction de son lien parent */
  canVisible(
    control: UntypedFormControl,
    controlLink: UntypedFormControl,
    ruleAllowValue: boolean,
  ) {
    // Force le visible du status en fonctin
    if (this.canUseStatus == undefined) {
      this.canUseStatus = true;
    }

    if (!this.canUseStatus || ruleAllowValue == false) {
      control?.invisible();
    } else {
      if (controlLink == undefined || controlLink.value !== true) {
        control?.invisible();
      } else {
        control?.visible();
      }
    }
  }

  navItemTypeEnum() {
    if (this.popupData.isRootItem !== false) {
      return [
        {
          name: TranslateService.get('entities/formDesigner/emptyPage'),
          id: 'EMPTY_PAGE',
        },
        {
          name: TranslateService.get('entities/formDesigner/emptyTab'),
          id: 'EMPTY_TAB',
        },
        {
          name: TranslateService.get('entities/formDesigner/existPage'),
          id: 'PAGE',
        },
        {
          name: TranslateService.get('entities/formDesigner/emptyGroup'),
          id: 'EMPTY_GROUP',
        },
      ];
    } else {
      return [
        {
          name: TranslateService.get('entities/formDesigner/emptyTabPage'),
          id: 'EMPTY_PAGE',
        },
        {
          name: TranslateService.get('entities/formDesigner/emptyTabWithTab'),
          id: 'EMPTY_TAB',
        },
        {
          name: TranslateService.get('entities/formDesigner/existTab'),
          id: 'PAGE',
        },
      ];
    }
  }

  navItemSource(currentContext: ModelState) {
    let data: {
      controls: { compiledResult: Array<any>; jsonResult: Array<any> };
      usedControls: { compiledResult: Array<any>; jsonResult: Array<any> };
      isRootItem: boolean;
    } = this.popupData;

    let source = [];

    for (let item of data.controls.compiledResult) {
      if (
        data.usedControls.compiledResult.findIndex(
          (el) =>
            el.pages === undefined &&
            el?.control?.fieldName === item?.control?.fieldName &&
            el?.control?.type === item?.control?.type,
        ) == -1
      ) {
        source.push({
          name: item.label + ' (Page)',
          id: item.id,
        });
      }
    }

    for (let i = 0; i < data.controls.jsonResult.length; i++) {
      let item = data.controls.jsonResult[i];
      if (item.tabs !== undefined) {
        if (
          data.usedControls.jsonResult.findIndex(
            (el) => el.tabs !== undefined && el?.label === item?.label,
          ) == -1
        ) {
          source.push({
            name: data.controls.compiledResult[i].label + ' (Tab)',
            id: data.controls.compiledResult[i].id,
          });
        }
      }
    }

    return of(source);
  }

  sectionSource(currentContext: ModelState) {
    let source = [];
    let hasFull = false;
    let hasBefore = false;
    let hasAfter = false;
    let hasStandard = false;

    let transformLocation = this.popupData.location;
    let sectionsData =
      this.isEdit === true ? this.popupData.sections : this.popupData;
    if (this.isEdit === true) {
      //   sectionsData = this.popupData.sections.filter(
      //     (el) => el.location !== this.popupData.location,
      //   );
      if (this.popupData.location == 'standard') {
        transformLocation = 'center';
      }
      if (this.popupData.location == 'left') {
        transformLocation = 'before';
      }
      if (this.popupData.location == 'right') {
        transformLocation = 'after';
      }
    }

    if (this.popupData.location == undefined) {
    }

    if (sectionsData.length != undefined) {
      let sections: Array<{ location: string }> = sectionsData;
      if (sections.findIndex((el) => el.location === 'full') != -1) {
        hasFull = true;
      }
      if (
        sections.findIndex(
          (el) => el.location === 'before' || el.location === 'left',
        ) != -1
      ) {
        hasBefore = true;
      }
      if (
        sections.findIndex(
          (el) => el.location === 'after' || el.location === 'right',
        ) != -1
      ) {
        hasAfter = true;
      }
      if (
        sections.findIndex(
          (el) =>
            el.location == undefined ||
            el.location == 'standard' ||
            el.location == 'center',
        ) != -1
      ) {
        hasStandard = true;
      }
    }

    if (!this.isEdit && (hasFull || sectionsData?.length === 3)) {
      return of([]);
    }

    if (hasAfter === false || (this.isEdit && transformLocation === 'after')) {
      source.push({
        name: TranslateService.get('entities/formDesigner/sectionAfter'),
        id: 'after',
      });
    }
    if (
      hasBefore === false ||
      (this.isEdit && transformLocation === 'before')
    ) {
      source.push({
        name: TranslateService.get('entities/formDesigner/sectionBefore'),
        id: 'before',
      });
    }
    if (
      hasStandard === false ||
      (this.isEdit && transformLocation === 'center')
    ) {
      source.push({
        name: TranslateService.get('entities/formDesigner/sectionDefault'),
        id: 'center',
      });
    }

    if (
      (hasAfter === false && hasStandard === false && hasBefore === false) ||
      (this.isEdit && transformLocation === 'full')
    ) {
      source.push({
        name: TranslateService.get('entities/formDesigner/sectionFull'),
        id: 'full',
      });
    }

    return of(source);
  }

  controlSource(currentContext, loadOptions) {
    let data: {
      controls: { compiledResult: Array<any>; jsonResult: Array<any> };
      usedControls: { compiledResult: Array<any>; jsonResult: Array<any> };
    } = this.popupData;
    let notUsedControl = [];
    for (let item of data.controls.compiledResult) {
      let findItem = data.usedControls.compiledResult.find(
        (el) =>
          el.fieldName === item.fieldName &&
          item.type === el.type &&
          item.isSystem !== true &&
          el.isSystem !== true,
      );
      if (
        findItem == undefined &&
        (item.label != undefined || item.labelForDesigner != undefined)
      ) {
        notUsedControl.push({
          name:
            item.labelForDesigner != undefined
              ? item.labelForDesigner
              : item.label,
          id: item.id,
        });
      }
    }

    return of(notUsedControl);
  }

  designerClick(_) {
    this.isPopupOpen = true;
  }

  saved(e) {}

  public static actionDialog(options: {
    title: string;
    message: string;
    hasAdd?: boolean;
    hasLeft?: boolean;
    hasRight?: boolean;
    hasUp?: boolean;
    hasDown?: boolean;
    hasIn?: boolean;
    hasRemove?: boolean;
    hasEdit?: boolean;
  }) {
    let buttons: dxButtonOptions[] = [];

    if (options.hasAdd) {
      buttons.push({
        text: TranslateService.get('entities/formDesigner/popupAdd'),
        accessKey: 'add',
        onClick: (e) => {
          return { buttonText: e.component.option('accessKey') };
        },
      });
    }
    if (options.hasLeft) {
      buttons.push({
        text: TranslateService.get('entities/formDesigner/popupAddLeft'),
        accessKey: 'addLeft',
        onClick: (e) => {
          return { buttonText: e.component.option('accessKey') };
        },
      });
    }
    if (options.hasRight) {
      buttons.push({
        text: TranslateService.get('entities/formDesigner/popupAddRight'),
        accessKey: 'addRight',
        onClick: (e) => {
          return { buttonText: e.component.option('accessKey') };
        },
      });
    }
    if (options.hasIn) {
      buttons.push({
        text: TranslateService.get('entities/formDesigner/popupAddIn'),
        accessKey: 'addIn',
        onClick: (e) => {
          return { buttonText: e.component.option('accessKey') };
        },
      });
    }
    if (options.hasUp) {
      buttons.push({
        text: TranslateService.get('entities/formDesigner/popupAddUp'),
        accessKey: 'addUp',
        onClick: (e) => {
          return { buttonText: e.component.option('accessKey') };
        },
      });
    }
    if (options.hasDown) {
      buttons.push({
        text: TranslateService.get('entities/formDesigner/popupAddDown'),
        accessKey: 'addDown',
        onClick: (e) => {
          return { buttonText: e.component.option('accessKey') };
        },
      });
    }
    if (options.hasEdit) {
      buttons.push({
        text: TranslateService.get('entities/formDesigner/popupEdit'),
        accessKey: 'edit',
        onClick: (e) => {
          return { buttonText: e.component.option('accessKey') };
        },
      });
    }
    if (options.hasRemove) {
      buttons.push({
        text: TranslateService.get('entities/formDesigner/popupRemove'),
        accessKey: 'remove',
        onClick: (e) => {
          return { buttonText: e.component.option('accessKey') };
        },
      });
    }

    buttons.push({
      text: TranslateService.get('entities/formDesigner/popupCancel'),
      accessKey: 'cancel',
      onClick: (e) => {
        return { buttonText: e.component.option('accessKey') };
      },
    });

    return custom({
      title: options.title,
      messageHtml: options.message,
      buttons: buttons,
    });
    // return myDialog;
    // myDialog.show().then((dialogResult) => {
    //   if (dialogResult.buttonText === 'addLeft') {
    //     this.state.on.emit({
    //       eventName: DesignerEventKey,
    //       eventData: new DesignerEventData(DesignerEventType.TAB, DesignerEventAction.ADD_DOWN, item.item.id),
    //     });
    //   } else if (dialogResult.buttonText === 'addRight') {
    //     this.state.on.emit({
    //       eventName: DesignerEventKey,
    //       eventData: new DesignerEventData(DesignerEventType.TAB, DesignerEventAction.ADD_UP, item.item.id),
    //     });
    //   } else if (dialogResult.buttonText === 'remove') {
    //     this.state.on.emit({
    //       eventName: DesignerEventKey,
    //       eventData: new DesignerEventData(DesignerEventType.TAB, DesignerEventAction.REMOVE, item.item.id),
    //     });
    //   } else if (dialogResult.buttonText === 'cancel') {
    //     //nada
    //   }
    // });
  }
}
