import { Injectable, Injector } from '@angular/core';
import { InjectArgs } from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { map } from 'rxjs/operators';
import { GqlField, GqlSubField } from '../helpers';
import { HtmlFieldsSettingBaseService } from '../service-bases';
import { FilterOfHtmlFieldsSetting } from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('administration.html-fields-setting')
export class HtmlFieldsSettingCoreService extends HtmlFieldsSettingBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public getDefaultHtmlFieldsSetting() {
    let filter: FilterOfHtmlFieldsSetting = {
      isDefault: { eq: true },
    };

    let defaultSettingId = this.find(
      [GqlSubField.create('data', [GqlField.create('id')])],
      null,
      filter,
      null,
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return defaultSettingId.then((result) => {
      if (result.length > 0) {
        return result[0].id;
      }

      return null;
    });
  }
}
